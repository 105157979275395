import { useState } from "react";
import { Box, Typography } from "@mui/material";
import { COLORS } from "components/v3/Theme/colors";
import VideoModal from "components/v3/sharedComponents/modals/VideoModal";

const HelpfulResourcesSection = () => {
  const [
    precisionHealthVideoModalOpen,
    setPrecisionHealthVideoModalOpen
  ] = useState(false);
  const [
    dnaScreeningsVideoModalOpen,
    setDnaScreeningsVideoModalOpen
  ] = useState(false);
  const [
    cancerNavigationVideoModalOpen,
    setCancerNavigationVideoModalOpen
  ] = useState(false);

  return (
    <>
      <Box
        sx={{
          boxSizing: "border-box",
          display: "flex",
          flexDirection: "column",
          gap: {
            xs: "15px",
            md: "30px"
          },
          paddingX: {
            xs: "20px",
            md: "60px"
          },
          paddingY: {
            xs: "30px",
            md: 0
          },
          position: "relative"
        }}
      >
        <Box
          sx={{
            display: "flex",
            flex: 1,
            flexDirection: "column",
            gap: "25px"
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "15px"
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "5px"
              }}
            >
              <Typography
                sx={{
                  background: `linear-gradient(to right, ${COLORS.ACCENT3}, ${COLORS.PRIMARY})`,
                  typography: {
                    xs: "body_xs_serif",
                    md: "h6_serif"
                  },
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent"
                }}
              >
                Helpful Resources
              </Typography>
              <Typography
                id="learn_more"
                sx={{ typography: { xs: "h5_black", md: "h4_black" } }}
              >
                Want to learn more?
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "15px"
            }}
          >
            <Box
              onClick={() => setPrecisionHealthVideoModalOpen(true)}
              sx={{
                background: COLORS.PRIMARY_LIGHTEST,
                borderRadius: 2,
                boxShadow: `0px 0px 0px 0px ${COLORS.PRIMARY_LIGHTER}`,
                cursor: "pointer",
                display: "flex",
                flex: 1,
                flexDirection: "column",
                gap: "7px",
                paddingX: 3,
                paddingY: 4,
                transition: "box-shadow 100ms linear",
                "&:hover": {
                  // outline: `2px solid ${COLORS.PRIMARY_LIGHTER}`
                  boxShadow: `0px 0px 0px 2px ${COLORS.PRIMARY_LIGHTER}`
                }
              }}
            >
              <Typography variant="body_strong_black">
                {"The value of Precision Health Guidance"}
              </Typography>
              <Typography variant="body_xs">
                {
                  "Learn how Precision Health Guidance can help you optimize your health, now and in the future."
                }
              </Typography>
            </Box>
            <Box
              onClick={() => setDnaScreeningsVideoModalOpen(true)}
              sx={{
                background: COLORS.PRIMARY_LIGHTEST,
                borderRadius: 2,
                boxShadow: `0px 0px 0px 0px ${COLORS.PRIMARY_LIGHTER}`,
                cursor: "pointer",
                display: "flex",
                flex: 1,
                flexDirection: "column",
                gap: "7px",
                paddingX: 3,
                paddingY: 4,
                transition: "box-shadow 100ms linear",
                "&:hover": {
                  // outline: `2px solid ${COLORS.PRIMARY_LIGHTER}`
                  boxShadow: `0px 0px 0px 2px ${COLORS.PRIMARY_LIGHTER}`
                }
              }}
            >
              <Typography variant="body_strong_black">
                {"Introduction to DNA Screenings"}
              </Typography>
              <Typography variant="body_xs">
                {
                  "Understand the basics of how changes in your DNA can impact your health."
                }
              </Typography>
            </Box>
            <Box
              onClick={() => setCancerNavigationVideoModalOpen(true)}
              sx={{
                background: COLORS.PRIMARY_LIGHTEST,
                borderRadius: 2,
                boxShadow: `0px 0px 0px 0px ${COLORS.PRIMARY_LIGHTER}`,
                cursor: "pointer",
                display: "flex",
                flex: 1,
                flexDirection: "column",
                gap: "7px",
                paddingX: 3,
                paddingY: 4,
                transition: "box-shadow 100ms linear",
                "&:hover": {
                  // outline: `2px solid ${COLORS.PRIMARY_LIGHTER}`
                  boxShadow: `0px 0px 0px 2px ${COLORS.PRIMARY_LIGHTER}`
                }
              }}
            >
              <Typography variant="body_strong_black">
                {"What is Cancer Navigation?"}
              </Typography>
              <Typography variant="body_xs">
                {
                  "Discover how expert cancer navigators and advanced cancer resources can help if you’re diagnosed with cancer, are a cancer survivor, or have a significant family history of cancer."
                }
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      <VideoModal
        onCloseModal={() => setPrecisionHealthVideoModalOpen(false)}
        open={precisionHealthVideoModalOpen}
        url="https://vimeo.com/1024099340"
      />
      <VideoModal
        onCloseModal={() => setDnaScreeningsVideoModalOpen(false)}
        open={dnaScreeningsVideoModalOpen}
        url="https://vimeo.com/1024099309"
      />
      <VideoModal
        onCloseModal={() => setCancerNavigationVideoModalOpen(false)}
        open={cancerNavigationVideoModalOpen}
        url="https://vimeo.com/1024099325"
      />
    </>
  );
};

export default HelpfulResourcesSection;
