import React from "react";
import { Box, keyframes } from "@mui/material";
import SpinnerIcon from "../../../../assets/icons/buttonSpinner.svg";

const rotateInSteps = keyframes`
  0% { transform: rotate(0deg); }
  12.5% { transform: rotate(45deg); }
  25% { transform: rotate(90deg); }
  37.5% { transform: rotate(135deg); }
  50% { transform: rotate(180deg); }
  62.5% { transform: rotate(225deg); }
  75% { transform: rotate(270deg); }
  87.5% { transform: rotate(315deg); }
  100% { transform: rotate(360deg); }
`;

const Spinner: React.FC = () => {
  return (
    <Box
      component="img"
      src={SpinnerIcon}
      alt="Loading spinner"
      sx={{
        width: "25px",
        height: "25px",
        animation: `${rotateInSteps} 1s step-end infinite`,
        transformOrigin: "52% 52%"
      }}
    />
  );
};

export default Spinner;
