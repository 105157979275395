import React from "react";
import { Grid, Typography } from "@mui/material";
import LinkBoxGridStyles from "./LinkBoxGrid.styles";
import ContentResolver from "../ContentResolver";

const LinkBoxGrid = (props) => {
  const { linkBoxes } = props;
  const classes = LinkBoxGridStyles();

  const renderCards = () => {
    return linkBoxes.map((linkBox, index) => {
      const links = linkBox.links.map((link) => {
        return (
          <a href={link.href.route} className={classes.link}>
            <Typography variant="deprecated_h5">
              {link.href.title.en}
            </Typography>
          </a>
        );
      });
      return (
        <Grid
          item
          xs={12}
          md={6}
          key={index}
          className={classes.linkBoxContainer}
        >
          <div className={classes.linkBox}>
            <Typography variant="deprecated_h5" className={classes.header}>
              <ContentResolver content={linkBox.title.href.title.en} />
            </Typography>
            {links}
          </div>
        </Grid>
      );
    });
  };

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12}>
        <Grid container spacing={3}>
          {renderCards()}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default LinkBoxGrid;
