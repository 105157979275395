import React from "react";
import { Grid, Typography, Link, useMediaQuery, useTheme } from "@mui/material";
import FooterStyles from "./Footer.styles";
import whiteLogo from "../../../../assets/images/LogoWhite.svg";
import MailOutlineIcon from "@mui/icons-material/MailOutline";

const Footer = () => {
  const classes = FooterStyles();
  const theme = useTheme();
  const screenWidthIsMdOrSmaller = useMediaQuery(theme.breakpoints.down("lg"));

  const wideFooter = (
    <Grid container className={classes.container}>
      <Grid
        container
        justifyContent={"space-between"}
        className={classes.bigStuff}
      >
        <Grid item>
          <img
            src={whiteLogo}
            alt={"genomic-life-logo"}
            // className={classes.logo}
          />
        </Grid>
        <Grid item>
          <Grid container direction={"column"}>
            <Typography
              variant="body_strong_white"
              className={classes.contactUs}
            >
              Contact Us
            </Typography>
            <Typography variant="body_xs_gray">
              Genomic Life has a team of Genetic Health Navigators available to
              support you.{" "}
            </Typography>
            <Typography
              variant="body_strong_xs_white"
              className={classes.emailLink}
            >
              <Link
                href={`mailto: navigation@genomiclife.com`}
                sx={{
                  alignItems: "flex-start",
                  display: "flex"
                }}
                underline="hover"
              >
                <MailOutlineIcon />
                <Typography
                  variant="body_strong_sm_white"
                  className={classes.emailLink}
                >
                  navigation@genomiclife.com
                </Typography>
              </Link>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        justifyContent={"space-between"}
        alignContent={"end"}
        // className={classes.smallStuff}
      >
        <Grid item>
          <Typography variant="body_sm_gray">
            © 2024 Genomic Life. All rights reserved.
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body_sm_gray">
            3344 N Torrey Pines Ct, Suite 100, La Jolla, CA 92037
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );

  const narrowFooter = (
    <Grid
      container
      className={`${classes.container} ${classes.narrowContainer}`}
      direction={"column"}
      textAlign={"center"}
    >
      <Grid item>
        <img
          src={whiteLogo}
          alt={"genomic-life-logo"}
          // className={classes.logo}
        />
      </Grid>
      <Grid item>
        <Typography
          variant="body_strong_lg_white"
          className={classes.contactUs}
        >
          Contact Us
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="body_sm_gray">
          Genomic Life has a team of Genetic Health Navigators available to
          support you.{" "}
        </Typography>
      </Grid>
      <Grid item className={classes.spaced}>
        <Typography
          variant="body_strong_xs_white"
          className={classes.emailLink}
        >
          <Link href={`mailto: navigation@genomiclife.com`} underline="hover">
            <MailOutlineIcon />
            navigation@genomiclife.com
          </Link>
        </Typography>
      </Grid>
      <Grid item className={classes.spaced}>
        <Typography variant="body_sm_gray">
          © 2024 Genomic Life. All rights reserved.
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="body_sm_gray">
          3344 N Torrey Pines Ct, Suite 100
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="body_sm_gray">La Jolla, CA 92037</Typography>
      </Grid>
    </Grid>
  );

  return screenWidthIsMdOrSmaller ? narrowFooter : wideFooter;
};

export default Footer;
