import React from "react";
import { Grid, Typography } from "@mui/material";
import ImageWithFooterCardStyles from "./ImageWithFooterCard.styles";

const ImageWithFooterCard = (props) => {
  const { image, footer, href } = props;
  const classes = ImageWithFooterCardStyles();

  return (
    <a href={href.route} className={classes.linkContainer}>
      <Grid container className={classes.container}>
        <Grid item className={classes.imageContainer}>
          <img {...image} />
        </Grid>
        <Grid item xs={12} className={classes.footer}>
          <Typography variant="deprecated_subtitle1">{footer}</Typography>
        </Grid>
      </Grid>
    </a>
  );
};

export default ImageWithFooterCard;
