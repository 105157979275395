import { makeStyles } from "@mui/styles";
import { COLOR_CONSTANTS } from "../../../../../constants/StyleConstants";

const LinkBoxGridStyles = makeStyles((theme) => ({
  container: {
    padding: "0 55px 50px 55px",
    margin: "0px 0 40px"
  },
  linkBoxContainer: {
    display: "flex"
  },

  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "1.25rem",
    "@media (max-width: 700px)": {
      display: "block",
      justifyContent: "initial"
    }
  },
  linkBox: {
    width: "95%",
    paddingBottom: "1rem",
    marginBottom: "1rem",
    lineHeight: "1.5rem"
  },
  header: {
    marginBottom: 15,
    fontWeight: 700,
    fontSize: "1.25rem"
  },
  linkHeader: {
    fontWeight: 700
  },
  link: {
    color: COLOR_CONSTANTS.COLOR_BLACK_75,
    fontSize: "1.15rem",
    fontWeight: 700,
    marginBottom: ".25rem"
  }
}));

export default LinkBoxGridStyles;
