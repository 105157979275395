import React from "react";
import { Grid } from "@mui/material";
import LinkBoxGridStyles from "./LinkChildren.styles";
import ContentResolver from "../ContentResolver";
import { ArrowUpward } from "@mui/icons-material";
import GutterCard from "../GutterCard";

const Questions = (props) => {
  const { groups } = props.material;
  const { gutters, pages } = props;
  const classes = LinkBoxGridStyles();

  const renderPageNav = () => {
    return groups?.map((child, index) => {
      return (
        <Grid
          item
          xs={12}
          md={12}
          key={index}
          className={classes.linkBoxContainer}
        >
          <div>
            <a href={"#" + child.ref} className={classes.link}>
              <div className={classes.navGroupHeading}>{child.title}</div>
            </a>
            <ul
              className={classes.navGroup}
              id={child.ref}
              style={{ marginBottom: "1rem", paddingBottom: "1rem" }}
            >
              {renderPageNavChildren(child.childRefs)}
            </ul>
          </div>
        </Grid>
      );
    });
  };

  function renderPageNavMatchingRefs(refsToFind) {
    return (
      <li className={classes.navGroupLink}>
        <a href={"#" + pages[refsToFind].ref} className={classes.linkHover}>
          <ContentResolver content={pages[refsToFind].title} />
        </a>
      </li>
    );
  }

  const renderPageNavChildren = (data) => {
    return Object.entries(data).map(([index, child]) => {
      return <div>{renderPageNavMatchingRefs(child)}</div>;
    });
  };

  function findMatchingRefs(refsToFind) {
    return (
      <div
        style={{
          paddingBottom: "3rem"
        }}
      >
        <h3 className={classes.question} id={pages[refsToFind].ref}>
          <ContentResolver content={pages[refsToFind].title} />
        </h3>
        <div className={classes.answer}>
          <ContentResolver style={{}} content={pages[refsToFind].content} />
          <div style={{ textAlign: "right", fontSize: "1rem" }}>
            <a href="#faq_top" className={classes.linkHover}>
              <ArrowUpward style={{ height: ".75rem" }} />
              Back to top
            </a>
          </div>
        </div>
      </div>
    );
  }

  const renderChildren = (data) => {
    return Object.entries(data).map(([index, child]) => {
      return <div>{findMatchingRefs(child)}</div>;
    });
  };

  const renderGroups = () => {
    return groups?.map((child, index) => {
      return (
        <Grid
          item
          xs={12}
          md={12}
          key={index}
          className={classes.linkBoxContainer}
        >
          <div className={classes.linkBox} id={child.ref}>
            <h2 className={classes.groupdHeading}>{child.title}</h2>
            {renderChildren(child.childRefs)}
          </div>
        </Grid>
      );
    });
  };

  return (
    <Grid
      container
      className={classes.container}
      justifyContent="center"
      id="faq_top"
    >
      <Grid item sm={12} md={8} lg={9}>
        <Grid item xs={12}>
          {renderPageNav()}
        </Grid>
        <Grid item xs={12}>
          {renderGroups()}
        </Grid>
      </Grid>
      <Grid item sm={12} md={4} lg={3}>
        <GutterCard material={gutters} pages={pages} />
      </Grid>
    </Grid>
  );
};

export default Questions;
