import React, { useState } from "react";
import { Grid, Typography, Button, Box, Checkbox, Chip } from "@mui/material";
import { Link, useHistory } from "react-router-dom";
import Layout from "../../../layout/Layout";
import SecurityMessage from "../../../sharedComponents/SecurityMessage";
import Translate from "../../../../translate/Translate";
//Redux
import { useSelector, useDispatch } from "react-redux";
import {
  selectUserPersonalInformation,
  selectUserSettings,
  setNotificationSettings
} from "../../../../store/reducers/user/UserAccountSlice";

//Constants
import { PATHS } from "../../../../constants/pathConstants";
//Styles
import SetNotificationPageStyles from "./SetNotificationPage.styles";
import PageTitle from "../../../../utils/PageTitle";
import ChipRowInfoCard from "../../../v2/sharedComponents/ChipRowInfoCard";
import { saveNotificationSettings } from "../../../../apis/membersApi";
import InactiveMembership from "../../../LandingPage/InactiveMembership";

/**
 * SetNotificationPage
 * @param {object} props properties brought in the component declaration
 *  @param {String} backTo  the parameter that will tell the router where to go back to
 * @returns {JSX} the notification settings page where the user can check notification information
 */
const SetNotificationPage = (props) => {
  const { backTo } = props;
  const dispatch = useDispatch();
  const userInfo = useSelector(selectUserPersonalInformation);
  const notificationsData = useSelector(selectUserSettings);
  const {
    emailNotifications,
    phoneNotifications,
    desktopNotifications
  } = notificationsData;

  const history = useHistory();
  const classes = SetNotificationPageStyles();

  const [emailCheck, setEmailCheck] = useState(emailNotifications);
  const [phoneCheck, setPhoneCheck] = useState(phoneNotifications);
  const [desktopCheck, setDesktopCheck] = useState(desktopNotifications);

  const hasInfoChanged =
    emailCheck !== emailNotifications ||
    phoneCheck !== phoneNotifications ||
    desktopCheck !== desktopNotifications;

  const emailCheckHandler = () => {
    setEmailCheck(!emailCheck);
  };

  const phoneCheckHandler = () => {
    setPhoneCheck(!phoneCheck);
  };

  const desktopCheckHandler = () => {
    setDesktopCheck(!desktopCheck);
  };

  const chipComponent = (text) => {
    return (
      <Chip
        label={
          <Typography
            variant="deprecated_subtitle2"
            className={classes.boldChip}
          >
            <Translate text={text} />
          </Typography>
        }
        className={classes.chipEmail}
      />
    );
  };

  const handleTextLink = (type) => {
    switch (type) {
      case "email":
        return (
          <Typography className={classes.cardDescription}>
            <Translate text={"Head to "} />
            <Link
              className={classes.link}
              to={PATHS.ACCOUNT_MANAGEMENT_MEMBER_INFORMATION}
            >
              <Translate text={"Member Information"} />
            </Link>
            <Translate text={" to make edits to your email on file."} />
          </Typography>
        );
      case "phone":
        return (
          <Typography className={classes.cardDescription}>
            <Translate text={"Head to "} />
            <Link
              className={classes.link}
              to={PATHS.ACCOUNT_MANAGEMENT_MEMBER_INFORMATION}
            >
              <Translate text={"Member Information"} />
            </Link>
            <Translate
              text={
                " to make edits to your phone number on file. Standard message and data rates may apply."
              }
            />
          </Typography>
        );
      default:
        return null;
    }
  };
  const handleSubmit = () => {
    const payloadData = {
      emailNotifications: emailCheck,
      phoneNotifications: phoneCheck,
      desktopNotifications: desktopCheck
    };
    const payload = {
      data: {
        ...payloadData
      }
    };
    saveNotificationSettings(payload)
      .then(() => {
        dispatch(setNotificationSettings(payloadData));
        history.push(PATHS.ACCOUNT_MANAGEMENT);
      })
      .catch(() => {
        console.error("Something wrong while save notification settings");
      });
  };

  PageTitle("Notification Settings");

  const renderCheckboxContent = (
    id,
    checkboxValue,
    handleChange,
    titleText
  ) => {
    return (
      <Box display="flex" flexDirection="row">
        <Checkbox
          size="small"
          color="secondary"
          id={id}
          checked={checkboxValue}
          value={checkboxValue}
          onChange={handleChange}
          className={classes.checkboxEmail}
        />
        <Typography
          variant="deprecated_h5"
          className={classes.cardTitleCheckBox}
        >
          <Translate text={titleText} />
        </Typography>
      </Box>
    );
  };

  return (
    <div>
      <Layout
        headerType="integrated"
        headerText="Notification Settings"
        goBackPath={backTo}
        goBackTitle={"Account Management"}
      >
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid className={classes.body} item xs={11} sm={8} md={6}>
            <Typography className={classes.subtitle} variant="deprecated_h6">
              <Translate text="Let us know how we can reach you. No spam, we promise." />
            </Typography>
            {userInfo.isMemberActive === false && <InactiveMembership />}
            <Grid item xs={12}>
              <div className={classes.cardContainer}>
                <ChipRowInfoCard
                  id="emailNotificationBox"
                  chipRowElements={chipComponent(userInfo.email)}
                  chipRowDescription={handleTextLink("email")}
                  leftThirdContent={renderCheckboxContent(
                    "emailCheckbox",
                    emailCheck,
                    emailCheckHandler,
                    "Email"
                  )}
                />
              </div>
              <div className={classes.cardContainer}>
                <ChipRowInfoCard
                  id="phoneNotificationBox"
                  chipRowElements={chipComponent(userInfo.phone)}
                  chipRowDescription={handleTextLink("phone")}
                  leftThirdContent={renderCheckboxContent(
                    "phoneCheckbox",
                    phoneCheck,
                    phoneCheckHandler,
                    "SMS text message"
                  )}
                />
              </div>
              <div className={classes.cardContainer}>
                <ChipRowInfoCard
                  id="desktopNotificationBox"
                  leftThirdContent={renderCheckboxContent(
                    "desktopNotificationCheckbox",
                    desktopCheck,
                    desktopCheckHandler,
                    "Desktop push notification"
                  )}
                  hasNotContent={true}
                />
              </div>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Button
              disabled={!hasInfoChanged}
              color="primary"
              variant="contained"
              onClick={handleSubmit}
            >
              <Translate text="Save" />
            </Button>
          </Grid>
          <Grid item xs={12}>
            <SecurityMessage
              title="Your information is safe with us"
              desc="Genomic Life uses SSL encryption to protect your information."
            />
          </Grid>
        </Grid>
      </Layout>
    </div>
  );
};
export default SetNotificationPage;
