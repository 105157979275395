import React from "react";
import { useHistory } from "react-router-dom";
import { PATHS } from "../../../../constants/pathConstants";
import PageTitle from "../../../../utils/PageTitle";
import Layout from "../../../layout/Layout";
import { Grid } from "@mui/material";
import AccountManagementMemberInfoPageStyles from "./AccountManagementMemberInfoPage.styles";
import BackTo from "../../../sharedComponents/BackTo";
import ProfileForm from "components/v3/forms/ProfileForm";

const AccountManagementMemberInfoPage = (props) => {
  const history = useHistory();
  const classes = AccountManagementMemberInfoPageStyles();

  PageTitle("Member Information");
  const handleSubmitted = () => {
    history.push(PATHS.ACCOUNT_MANAGEMENT);
  };

  return (
    <Layout headerType="primary">
      <Grid container justifyContent="center" alignItems="flex-start">
        <Grid item xs={12} sm={10} md={8}>
          <Grid
            container
            item
            xs={12}
            justifyContent="flex-start"
            alignItems="center"
          >
            <div className={classes.backToContainer}>
              <BackTo
                text="Account Management"
                backTo={PATHS.ACCOUNT_MANAGEMENT}
              />
            </div>
          </Grid>
          <ProfileForm onSubmitted={handleSubmitted} />
        </Grid>
      </Grid>
    </Layout>
  );
};

export default AccountManagementMemberInfoPage;
