export const COLORS = {
  // Neutrals
  WHITE: "#FFFFFF",
  BLACK: "#222222",
  GRAY: "#CCCCCC",
  GREEN_2: "#83A098",
  DARK_GRAY: "#6B7280",
  DARK_GRAY_2: "#111827",

  //System
  ERROR: "#800000",
  WARNING: "#E46739",
  SUCCESS: "#4CAF7A",

  // Primary (Ocean)
  PRIMARY: "#284993",
  PRIMARY_LIGHTEST: "#E9EDF4",
  PRIMARY_LIGHTER: "#D4D8E9",
  PRIMARY_LIGHT: "#A9B6D4",
  PRIMARY_DARK: "#203A76",
  PRIMARY_DARKER: "#182C58",
  PRIMARY_DARKEST: "#101D3B",

  // Secondary (Forest)
  SECONDARY: "#306153",
  SECONDARY_LIGHTEST: "#EAEFED",
  SECONDARY_LIGHTER: "#D6DFDD",
  SECONDARY_LIGHT: "#ACC0BA",
  SECONDARY_DARK: "#264E42",
  SECONDARY_DARKER: "#1D3A32",
  SECONDARY_DARKEST: "#132721",

  // Accent1 (Vista)
  ACCENT1: "#5DA5D9",
  ACCENT1_LIGHTEST: "#EEF6FB",
  ACCENT1_LIGHTER: "#DFEDF7",
  ACCENT1_LIGHT: "#BEDBF0",
  ACCENT1_DARK: "#4A84AE",
  ACCENT1_DARKER: "#386382",
  ACCENT1_DARKEST: "#254257",

  // Accent2 (Aqua)
  ACCENT2: "#007580",
  ACCENT2_LIGHTEST: "#E5F1F2",
  ACCENT2_LIGHTER: "#CCE3E6",
  ACCENT2_LIGHT: "#99CBCC",
  ACCENT2_DARK: "#005666",
  ACCENT2_DARKER: "#00464D",
  ACCENT2_DARKEST: "#002F33",

  // Accent3 (Mint)
  ACCENT3: "#60C2A5",
  ACCENT3_LIGHTEST: "#EFF9F6",
  ACCENT3_LIGHTER: "#DFF3ED",
  ACCENT3_LIGHT: "#BFE7DB",
  ACCENT3_DARK: "#4D9B84",
  ACCENT3_DARKER: "#3A7463",
  ACCENT3_DARKEST: "#264E42",

  // Accent4 (Coral)
  ACCENT4: "#FF7E2D",
  ACCENT4_LIGHTEST: "#FFF2ED",
  ACCENT4_LIGHTER: "#FFEDDC",
  ACCENT4_LIGHT: "#FFCCB9",
  ACCENT4_DARK: "#CC6640",
  ACCENT4_DARKER: "#994C30",
  ACCENT4_DARKEST: "#663320"
};
