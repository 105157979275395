import Translate from "../../../../../translate/Translate";
import { Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import LargeHelloHeaderStyles from "./LargeHelloHeader.styles";
import { useSelector } from "react-redux";
import { selectUserPersonalInformation } from "../../../../../store/reducers/user/UserAccountSlice";
import { selectAllPreviousSurveyAnswers } from "../../../../../store/reducers/survey/SurveySlice";
import InviteSpouseToDoCard from "../ToDoList/ToDoListCards/InviteSpouseToDoCard";
import GetStartedToDoCard from "../ToDoList/ToDoListCards/GetStartedToDoCard";
import { hasUserCompletedEnoughOfGettingStartedSurvey } from "../../../Surveys/Utilities/util";
import { Link } from "react-router-dom";
import { PATHS } from "../../../../../constants/pathConstants";

const HelloHeader = () => {
  const theme = useTheme();
  const smWidthMode = useMediaQuery(theme.breakpoints.down("md"));
  const classes = LargeHelloHeaderStyles();
  const userInformation = useSelector(selectUserPersonalInformation);
  const previousSurveyAnswers = useSelector(selectAllPreviousSurveyAnswers);

  const hasDoneGettingStartedSurvey = hasUserCompletedEnoughOfGettingStartedSurvey(
    previousSurveyAnswers
  );

  const nameString =
    userInformation.preferredName && userInformation.lastName[0]
      ? ` ${userInformation.preferredName} ${userInformation.lastName[0]}`
      : "";

  const renderedBodyForVersionWithoutCards = (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      spacing={4}
      className={classes.innerContainer}
    >
      <Typography
        variant={"deprecated_h2"}
        className={classes.header}
        style={{ textAlign: `${smWidthMode ? "center" : ""}` }}
      >
        <Translate text={`Hello${nameString}.`} />
      </Typography>
    </Grid>
  );

  const renderedBodyForVersionWithCards = (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      spacing={4}
      className={classes.innerContainer}
    >
      <Grid item xs={11} sm={11} md={4}>
        <Typography
          variant={"deprecated_h2"}
          className={classes.header}
          style={{ textAlign: `${smWidthMode ? "center" : ""}` }}
        >
          <Translate
            text={`Hello${
              userInformation.preferredName
                ? " " + userInformation.preferredName
                : userInformation.firstName
                ? " " + userInformation.firstName
                : ""
            }`}
          />
          !
        </Typography>
        <Typography
          className={classes.subheader}
          style={{ textAlign: `${smWidthMode ? "center" : ""}` }}
        >
          <Translate
            text="Your membership provides you access to genomic health navigation
              and genetic screenings to support your health goals."
          />
        </Typography>
      </Grid>
      <Grid item xs={10} sm={10} md={5}>
        <Grid container direction="column" spacing={2}>
          <GetStartedToDoCard />
          <InviteSpouseToDoCard />
        </Grid>
      </Grid>
    </Grid>
  );

  const subsectionIfCards = () => {
    if (hasDoneGettingStartedSurvey)
      return (
        <Grid container justifyContent="center">
          <Grid item xs={10} sm={10} md={6} className={classes.subsectionPart}>
            <Typography variant={"deprecated_h2"}>
              <Translate text="Personalized next steps..." />
            </Typography>
            <Typography variant="deprecated_body1">
              <Translate text="We appreciate you sharing because it allows us to help you best. When you have changes in your health, you can always make updates " />
              <Link className={classes.link} to={PATHS.GETTING_STARTED_SURVEY}>
                <Translate text={"here"} />
              </Link>
              .
            </Typography>
          </Grid>
        </Grid>
      );
  };

  return (
    <Grid container className={classes.mainContainer}>
      <Grid container className={classes.blueBackground}>
        <Grid container className={classes.blueRectangle}>
          {hasDoneGettingStartedSurvey
            ? renderedBodyForVersionWithoutCards
            : renderedBodyForVersionWithCards}
        </Grid>
        <Grid container className={classes.blueCurveMask}>
          <Grid container className={classes.blueConcaveCurve} />
        </Grid>
      </Grid>
      {subsectionIfCards()}
    </Grid>
  );
};

export default HelloHeader;
