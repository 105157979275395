import React from "react";
import { Avatar } from "@mui/material";
import { Check } from "@mui/icons-material";
import { COLORS } from "components/v3/Theme/colors";

export enum SuperStepState {
  Complete = "complete",
  Active = "self_is_active",
  ChildIsActive = "child_is_active",
  Inactive = "inactive"
}

interface SuperStepDotProps {
  state: SuperStepState;
  index: number;
}

const SuperStepDot: React.FC<SuperStepDotProps> = ({ state, index }) => {
  const isComplete = state === SuperStepState.Complete;
  const isActive = state === SuperStepState.Active;
  const dotColor = isComplete
    ? COLORS.PRIMARY
    : [SuperStepState.Active, SuperStepState.ChildIsActive].includes(state)
    ? COLORS.ACCENT2
    : COLORS.DARK_GRAY;

  return (
    <Avatar
      sx={{
        bgcolor: isActive ? COLORS.ACCENT2_LIGHTEST : "transparent",
        width: 30,
        height: 30,
        fontSize: 15,
        overflow: "visible",
        animation: isActive ? "pulse 3s infinite ease-in-out" : "none",
        "@keyframes pulse": {
          "0%, 100%": {
            boxShadow: "0 0 0 0"
          },
          "50%": {
            boxShadow: `0 0 0 5px ${COLORS.ACCENT2_LIGHTEST}`
          }
        }
      }}
    >
      <Avatar
        sx={{
          bgcolor: dotColor,
          width: "20px",
          height: "20px",
          fontSize: 14,
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        {isComplete ? (
          <Check sx={{ fontSize: 13, position: "relative", top: -1 }} />
        ) : (
          index
        )}
      </Avatar>
    </Avatar>
  );
};

export default SuperStepDot;
