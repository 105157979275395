import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Typography, Divider, Grid } from "@mui/material";
import Translate from "../../../../../translate/Translate";
import SectionHeaderStyles from "./SectionHeader.styles";

const SectionHeader = (props) => {
  const { header, subheader } = props;
  const classes = SectionHeaderStyles();

  const maybeSubheader = subheader ? (
    <Typography variant="deprecated_body1" gutterBottom>
      <Translate text={subheader} />
    </Typography>
  ) : null;

  return (
    <Fragment>
      <Grid container className={classes.header}>
        <Typography variant="deprecated_h6" gutterBottom>
          <Translate text={header} />
        </Typography>
      </Grid>
      {maybeSubheader}
      <Grid container>
        <Divider variant="fullWidth" className={classes.divider} />
      </Grid>
    </Fragment>
  );
};

export default SectionHeader;

SectionHeader.propTypes = {
  header: PropTypes.string,
  subheader: PropTypes.string
};
