import React, { useState, useEffect } from "react";
import CornerOneFrame1 from "assets/images/CornerOneFrame1.svg";
import CornerOneFrame2 from "assets/images/CornerOneFrame2.svg";
import CornerOneFrame3 from "assets/images/CornerOneFrame3.svg";
import CornerOneFrame4 from "assets/images/CornerOneFrame4.svg";
import CornerTwoFrame1 from "assets/images/CornerTwoFrame1.svg";
import CornerTwoFrame2 from "assets/images/CornerTwoFrame2.svg";
import CornerTwoFrame3 from "assets/images/CornerTwoFrame3.svg";
import CornerTwoFrame4 from "assets/images/CornerTwoFrame4.svg";

const svgComponentsCorner1 = [
  <img src={CornerOneFrame1} alt="background-art" />,
  <img src={CornerOneFrame2} alt="background-art" />,
  <img src={CornerOneFrame3} alt="background-art" />,
  <img src={CornerOneFrame4} alt="background-art" />
];

const svgComponentsCorner2 = [
  <img src={CornerTwoFrame1} alt="background-art" />,
  <img src={CornerTwoFrame2} alt="background-art" />,
  <img src={CornerTwoFrame3} alt="background-art" />,
  <img src={CornerTwoFrame4} alt="background-art" />
];

const Corners: React.FC = () => {
  const [currentSvgIndexCornerOne, setCurrentSvgIndexCornerOne] = useState(0);
  const [currentSvgIndexCornerTwo, setCurrentSvgIndexCornerTwo] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSvgIndexCornerOne(
        (prevIndex) => (prevIndex + 1) % svgComponentsCorner1.length
      );
    }, 1500);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSvgIndexCornerTwo(
        (prevIndex) => (prevIndex + 1) % svgComponentsCorner1.length
      );
    }, 1300); // Mismatch these to make them move out of sync

    return () => clearInterval(interval);
  }, []);

  return (
    <div
      style={{
        width: "100px",
        height: "100px",
        position: "absolute",
        top: 0,
        left: 0
      }}
    >
      {svgComponentsCorner1.map((svg, index) => (
        <div
          key={index}
          style={{
            opacity: index === currentSvgIndexCornerOne ? 1 : 0,
            transition: "opacity 1.25s ease",
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%"
          }}
        >
          {svg}
        </div>
      ))}
      {svgComponentsCorner2.map((svg, index) => (
        <div
          key={index}
          style={{
            opacity: index === currentSvgIndexCornerTwo ? 1 : 0,
            transition: "opacity 1s ease",
            position: "fixed",
            bottom: "185px",
            right: "299px",
            width: "100px",
            height: "100px"
          }}
        >
          {svg}
        </div>
      ))}
    </div>
  );
};

export default Corners;
