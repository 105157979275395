import React from "react";
import { Box } from "@mui/material";
import Footer from "../Footer";
import { COLORS } from "../../Theme/colors";

interface HeaderlessGradientLogoFramedLayoutProps {
  children: React.ReactNode;
}

const HeaderlessGradientLogoFramedLayout: React.FC<HeaderlessGradientLogoFramedLayoutProps> = ({
  children
}) => {
  return (
    <Box
      sx={{
        alignItems: "center",
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "column",
        height: "100%",
        background: `linear-gradient(to bottom, #F2F7FB, ${COLORS.WHITE})`,
        minHeight: "100vh",
        position: "relative",
        width: "100%",
        fontFamily: "Inter, sans-serif",
        "& .MuiInputBase-root": {
          fontFamily: "Inter, sans-serif",
          color: COLORS.DARK_GRAY,
          "& .MuiInputBase-input": {
            fontFamily: "Inter, sans-serif",
            color: COLORS.DARK_GRAY
          }
        },
        "& .MuiFormHelperText-root, .MuiFormLabel-root": {
          fontFamily: "Inter, sans-serif"
        }
      }}
    >
      {children}
      <Footer />
    </Box>
  );
};

export default HeaderlessGradientLogoFramedLayout;
