import { makeStyles } from "@mui/styles";
import { COLOR_CONSTANTS } from "../../../../../constants/StyleConstants";

const ArticleStyles = makeStyles((theme) => ({
  container: {},
  articleContainer: {
    padding: "0px 20px 100px",
    paddingRight: "6rem",
    [theme.breakpoints.down("lg")]: {
      paddingRight: "2rem"
    },
    color: "black",
    fontSize: "1.15rem",
    lineHeight: "1.65rem",
    "& ul": { "padding-inline-start": "30px" },
    "& li": {
      paddingBottom: 15,
      "&::marker": { fontSize: 26 },
      "& a": {
        color: COLOR_CONSTANTS.GENOMIC_LIFE_FONT_BLUE,
        position: "relative",
        top: -2
      }
    },
    "& img": { width: "100%", height: "auto" }
  }
}));

export default ArticleStyles;
