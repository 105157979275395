import { AxiosRequestConfig, AxiosResponse } from "axios";
import { doApiCall } from "../util";
import { convertToDateFormat } from "./utils";
import { UserPersonalInformation } from "store/reducers/user/UserAccountSlice";
const { REACT_APP_MEMBER_PORTAL_ENDPOINT_URL } = process.env;

type MemberApiAddress = {
  addressLine1: string;
  addressLine2?: string;
  city: string;
  state: string;
  postalCode: string;
  country: string;
};

export type MemberApiGETProfileResponse = {
  firstName?: string;
  lastName?: string;
  dob?: string;
  preferredName?: string;
  sexAssignedAtBirth?: string;
  gender?: string;
  pronouns?: string;
  email?: string;
  phone?: string;
  address?: MemberApiAddress;
  preferredContactMechanism?: "phone" | "email" | "either";
  preferredContactTime?: "morning" | "afternoon" | "either";
  isMemberActive?: boolean;
};

type MemberApiPATCHPRofilePayload = Omit<
  UserPersonalInformation,
  "isMemberActive" | "firstName" | "lastName" | "dob"
>;

export const patchMemberProfile = (
  memberInformation: MemberApiPATCHPRofilePayload
) => {
  const {
    addressLine1,
    addressLine2,
    city,
    state,
    postalCode,
    country,
    ...payloadWithoutAddressInRoot
  } = memberInformation;
  const payloadWithAddressNested = {
    ...payloadWithoutAddressInRoot,
    address: {
      addressLine1,
      addressLine2,
      city,
      state,
      postalCode,
      country: "United States of America"
    }
  };
  const request: AxiosRequestConfig = {
    method: "PATCH",
    url: `https://${REACT_APP_MEMBER_PORTAL_ENDPOINT_URL}/member`,
    data: payloadWithAddressNested
  };

  return doApiCall(request);
};

export const getMemberProfile = (): Promise<AxiosResponse<
  MemberApiGETProfileResponse
>> => {
  const request: AxiosRequestConfig = {
    method: "GET",
    url: `https://${process.env.REACT_APP_MEMBER_PORTAL_ENDPOINT_URL}/member`
  };

  return doApiCall(request).then(
    (response: AxiosResponse<MemberApiGETProfileResponse>) => {
      response.data.dob = response.data.dob
        ? convertToDateFormat(response.data.dob)
        : "";
      return response;
    }
  );
};
