import React, { ReactNode } from "react";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import { COLORS } from "components/v3/Theme/colors";
import SuperStepDot, { SuperStepState } from "./SuperStepDot";
import { SubStepType } from "./SubStep";
import { calculateStepState } from "./utils";
import VerticalStepItem from "./VerticalStepItem";

export interface Step {
  stepIndex: number;
  shortName: string;
  longName: string;
  subSteps: SubStepType[];
}

export interface StepperProps {
  activeStepIndex: number;
  greeting?: ReactNode;
  steps: Step[];
}

export interface StepItemProps {
  menuItemIndex: number;
  stepIndex: number;
  label: string;
  activeStepIndex: number;
  subSteps?: SubStepType[];
}

const Stepper: React.FC<StepperProps> = ({
  activeStepIndex,
  greeting,
  steps = []
}) => {
  const theme = useTheme();
  const screenWidthIsMdOrSmaller = useMediaQuery(theme.breakpoints.down("lg"));

  const renderVerticalSteps = () => {
    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        position="relative"
      >
        <Box
          sx={{
            position: "absolute",
            top: 30,
            bottom: 60,
            left: "19px",
            borderLeft: `2px dashed ${COLORS.ACCENT2}`
          }}
        />
        {steps.map((step, index) => {
          return (
            <VerticalStepItem
              menuItemIndex={index}
              activeStepIndex={activeStepIndex}
              key={step.stepIndex}
              step={step}
            />
          );
        })}
      </Box>
    );
  };

  const renderFullVerticalStepper = () => {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "30px",
          paddingLeft: "50px"
        }}
      >
        {!!greeting && greeting}
        {renderVerticalSteps()}
      </Box>
    );
  };

  const renderHorizontalStepper = () => {
    return (
      <Box
        sx={{
          alignItems: "center",
          backgroundColor: COLORS.WHITE,
          boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
          boxSizing: "border-box",
          display: "flex",
          flexGrow: 1,
          gap: "15px",
          height: "50px",
          left: 0,
          padding: "15px 15px 15px 10px",
          position: "fixed",
          top: 0,
          width: "100%",
          zIndex: 1000
        }}
      >
        {steps.map((step, index) => {
          const { stepState, superStepColoration } = calculateStepState(
            step,
            activeStepIndex
          );
          const overriddenStepState = [
            SuperStepState.ChildIsActive,
            SuperStepState.Active
          ].includes(stepState)
            ? SuperStepState.Active
            : stepState;
          return (
            <Box
              key={step.stepIndex}
              sx={{
                alignItems: "center",
                display: "flex",
                flexBasis: "100%",
                flexDirection: "row",
                position: "relative",
                width: 0
              }}
            >
              <Box
                sx={{
                  alignItems: "center",
                  display: "flex",
                  gap: "10px",
                  justifyContent: "center"
                }}
              >
                <SuperStepDot state={overriddenStepState} index={index + 1} />
                <Typography
                  sx={{
                    typography: {
                      xs: `body_strong_sm_${superStepColoration}`,
                      md: "body_strong"
                    }
                  }}
                >
                  {step.shortName}
                </Typography>
              </Box>
            </Box>
          );
        })}
      </Box>
    );
  };

  return screenWidthIsMdOrSmaller
    ? renderHorizontalStepper()
    : renderFullVerticalStepper();
};

export default Stepper;
