import React from "react";
import { Grid } from "@mui/material";
import LinkArticlesStyles from "./LinkArticles.styles";
import ContentResolver from "../ContentResolver";
import GutterCard from "../GutterCard";
import ResourceLink from "../ResourceLink";

const LinkArticles = (props) => {
  const { children } = props.material;
  const { gutters, pages } = props;
  const classes = LinkArticlesStyles();

  const renderCards = () => {
    return Object.entries(children || {}).map(([index, child]) => {
      return (
        <Grid
          item
          xs={12}
          md={12}
          key={index}
          className={classes.linkBoxContainer}
        >
          <div className={classes.linkBox}>
            <ResourceLink link={pages[child.ref]} />
            <div>
              <ContentResolver content={pages[child.ref].summary} />
            </div>
          </div>
        </Grid>
      );
    });
  };

  return (
    <Grid container className={classes.container}>
      <Grid item sm={12} md={8} lg={9}>
        {renderCards()}
      </Grid>

      <Grid item sm={12} md={4} lg={3}>
        <GutterCard material={gutters} pages={pages} />
      </Grid>
    </Grid>
  );
};

export default LinkArticles;
