import { makeStyles } from "@mui/styles";

const ImageWithFooterCardStyles = makeStyles((theme) => ({
  linkContainer: { textDecoration: "none" },
  container: {
    backgroundColor: "lightGray",
    padding: 0,
    margin: "0",
    boxShadow: "0px 2px 10px 0px #4E81C933",
    borderRadius: "10px"
  },
  imageContainer: {
    "& img": {
      width: "100%",
      height: "100%"
    }
  },
  footer: {
    color: theme.palette.primary.font_blue,
    textAlign: "left",
    padding: "10px",
    backgroundColor: "white",
    borderRadius: "0 0 10px 10px"
  }
}));

export default ImageWithFooterCardStyles;
