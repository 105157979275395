import React, { useState } from "react";
import { Grid, Typography } from "@mui/material";
import { Person } from "@mui/icons-material";
import Translate from "../../../../../translate/Translate";
import OptionCard from "../../../../sharedComponents/OptionCard";
import ConnectionManagmentStepStyles from "./ConnectionManagmentStep.styles";
import ConnectionForm from "../../../../v2/Forms/ConnectionForm";
import { useDispatch } from "react-redux";
import { openGLSnackbar } from "../../../../../store/reducers/snackbar/openGLSnackbarSlice";

const ConnectionManagmentStep = (props) => {
  const {
    careProviderConnections,
    transmissionModes,
    setTransmissionModes
  } = props;
  const classes = ConnectionManagmentStepStyles();
  const dispatch = useDispatch();
  const [
    idOfConnectionCurrentlyBeingEdited,
    setIdOfConnectionCurrentlyBeingEdited
  ] = useState(null);
  const [
    connectionIsCurrentlyBeingEdited,
    setConnectionIsCurrentlyBeingEdited
  ] = useState(false);

  const modifyTransmissionModes = (id, channel, action) => {
    const recipientObject = {
      connectionId: id,
      channels: channel
    };
    switch (action) {
      case "ADD":
        setTransmissionModes([...transmissionModes, recipientObject]);
        break;
      case "REMOVE":
        const modifiedArray = transmissionModes.filter((mode) => {
          return mode.connectionId !== recipientObject.connectionId;
        });
        setTransmissionModes(modifiedArray);
        break;
      default:
        break;
    }
  };

  const handleSaveOrUpdateConnection = () => {
    idOfConnectionCurrentlyBeingEdited
      ? dispatch(
          openGLSnackbar({
            variant: "success",
            header: false,
            subText: "Changes to this connection have been successfully saved."
          })
        )
      : dispatch(
          openGLSnackbar({
            variant: "success",
            header: false,
            subText: "We’ve added a new connection to your profile!"
          })
        );

    setIdOfConnectionCurrentlyBeingEdited(null);
    setConnectionIsCurrentlyBeingEdited(false);
  };

  const handleDeleteConnection = () => {
    modifyTransmissionModes(
      idOfConnectionCurrentlyBeingEdited,
      "FAX",
      "REMOVE"
    );
    dispatch(
      openGLSnackbar({
        variant: "info",
        header: false,
        subText: "You’ve removed a connection from your community."
      })
    );
    setIdOfConnectionCurrentlyBeingEdited(null);
    setConnectionIsCurrentlyBeingEdited(false);
  };

  const findModeInTransmissions = (connectionID, channel) => {
    if (connectionID) {
      return transmissionModes.find((mode) => {
        return (
          connectionID === mode.connectionId && mode.channels.includes(channel)
        );
      });
    }
    return false;
  };

  const renderConnectionList = () => {
    return (
      <Grid container alignContent="center" justifyContent="center">
        {careProviderConnections
          .sort((a, b) => {
            return a.firstName.toUpperCase() < b.firstName.toUpperCase()
              ? -1
              : 1;
          })
          .map((connection) => (
            <Grid
              item
              xs={12}
              className={classes.contactInformationCard}
              key={connection.connectionId}
            >
              <OptionCard
                text={`${connection.firstName} ${connection.lastName}`}
                nameInitial={`${connection.firstName.charAt(
                  0
                )}${connection.lastName.charAt(0)}`}
                subText={connection.relationship}
                avatar
                bigCard
                secondaryFunction={() => {
                  setIdOfConnectionCurrentlyBeingEdited(
                    connection.connectionId
                  );
                  setConnectionIsCurrentlyBeingEdited(true);
                }}
                isTransmitCard={true}
                contactInformation={connection}
                isFaxInModes={findModeInTransmissions(
                  connection.connectionId,
                  "FAX"
                )}
                isEmailInModes={findModeInTransmissions(
                  connection.connectionId,
                  "EMAIL"
                )}
                parentHandler={modifyTransmissionModes}
              />
            </Grid>
          ))}
        <div className={classes.addConCard}>
          <OptionCard
            text={"Add a connection"}
            icon={
              <Person
                style={{ color: "#E2ECF8" }}
                className={classes.iconFontSize}
              />
            }
            redirectTo={() => {
              setIdOfConnectionCurrentlyBeingEdited(null);
              setConnectionIsCurrentlyBeingEdited(true);
            }}
            add
            bigCard
          />
          <Grid container className={classes.counterContainer}>
            <Typography variant="deprecated_body1">
              {transmissionModes.length}{" "}
              <Translate
                text={`mode${
                  transmissionModes.length > 1 || transmissionModes.length === 0
                    ? "s"
                    : ""
                } of transmission ha${
                  transmissionModes.length > 1 || transmissionModes.length === 0
                    ? "ve"
                    : "s"
                } been selected`}
              />
            </Typography>
          </Grid>
        </div>
      </Grid>
    );
  };

  const renderConnectionFormIfOpen = () => {
    return connectionIsCurrentlyBeingEdited ? (
      <ConnectionForm
        connectionId={idOfConnectionCurrentlyBeingEdited}
        onSaved={handleSaveOrUpdateConnection}
        onDeleted={handleDeleteConnection}
        onCancelled={() => {
          setIdOfConnectionCurrentlyBeingEdited(null);
          setConnectionIsCurrentlyBeingEdited(false);
        }}
      />
    ) : null;
  };

  return (
    <Grid item xs={12}>
      <Grid item xs={12}>
        <Grid
          container
          alignContent="center"
          justifyContent="center"
          className={classes.connectionContainer}
        >
          <Grid item xs={12}>
            <Grid container alignContent="center" justifyContent="center">
              <Typography variant="deprecated_h5" className={classes.titleText}>
                <Translate text="Who will you be sharing your results with?" />
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} className={classes.connectionContainer}>
            <Grid container alignContent="center" justifyContent="center">
              <Grid item xs={12}>
                {renderConnectionList()}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {renderConnectionFormIfOpen()}
    </Grid>
  );
};
export default ConnectionManagmentStep;
