import { Box } from "@mui/material";
import { SxProps } from "@mui/system";
import { ArrowForward } from "@mui/icons-material";
// import { useTranslation } from "react-i18next";
import GLButton from "components/v3/sharedComponents/GLButton";
import { useHistory } from "react-router-dom";
import { PATHS } from "constants/pathConstants";

type NextStepSectionProps = {
  buttonSx?: SxProps;
  sx?: SxProps;
};

const NextStepSection: React.FC<NextStepSectionProps> = ({
  buttonSx = {},
  sx = {}
}) => {
  const history = useHistory();
  // const { t } = useTranslation();

  return (
    <Box
      component="section"
      sx={{
        boxSizing: "border-box",
        display: "flex",
        gap: {
          xs: "15px",
          md: "30px"
        },
        paddingX: {
          xs: "30px 20px",
          md: "60px"
        },
        justifyContent: "flex-end",
        width: "100%",
        ...sx
      }}
    >
      <GLButton
        buttonType="primary"
        onClick={() => {
          history.push(PATHS.ONBOARDING_TERMS);
        }}
        rightIcon={<ArrowForward />}
        sx={buttonSx}
        text="Next Step"
      ></GLButton>
    </Box>
  );
};

export default NextStepSection;
