import { makeStyles } from "@mui/styles";
import { COLOR_CONSTANTS } from "../../../../../constants/StyleConstants";

const LinkBoxGridStyles = makeStyles((theme) => ({
  container: {
    padding: "10px 20px 1rem",
    color: "black",
    fontSize: "1.15rem"
  },
  linkBoxContainer: {
    display: "flex"
  },

  childrenList: {
    lineHeight: "1.25rem",
    fontWeight: "normal",
    paddingLeft: "1rem",
    fontSize: "1.15rem",
    marginTop: ".25rem"
  },
  gridSpacing: {
    marginTop: "1rem"
  },
  subheading: {
    width: "95%",
    display: "flex",
    marginTop: ".5rem",
    marginBottom: ".75rem",
    paddingBottom: ".5rem",
    fontWeight: "700",
    borderBottom: "1px solid #C4C4C4",
    fontSize: "2.0rem"
  },
  linkBox: {
    //borderBottom: `1px dotted ${COLOR_CONSTANTS.COLOR_GRAY_30}`,
    width: "95%",
    paddingBottom: "1rem",
    fontWeight: 700,
    fontSize: "1.25rem",
    lineHeight: "1rem"
  },
  link: {
    color: COLOR_CONSTANTS.COLOR_BLACK_75,
    marginBottom: ".25rem",
    cursor: "pointer",
    display: "block",
    lineHeight: "normal",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline"
    }
  },
  summary: {
    color: COLOR_CONSTANTS.COLOR_GRAY,
    marginBottom: ".5rem",
    display: "block",
    fontSize: ".9rem"
  }
}));

export default LinkBoxGridStyles;
