import React from "react";
import { Typography, TypographyProps, Box } from "@mui/material";
import { motion, AnimatePresence } from "framer-motion";

interface FadingTypographyProps extends TypographyProps {
  fadeTime?: number;
}

const FadingTypography: React.FC<FadingTypographyProps> = ({
  fadeTime = 0.3,
  children,
  ...typographyProps
}) => {
  return (
    <Box sx={{ position: "relative", display: "inline-block" }}>
      {/* @ts-ignore: Ignore TypeScript errors related to AnimatePresence */}
      <AnimatePresence>
        <motion.div
          key={children?.toString()}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: fadeTime }}
          style={{ position: "absolute", width: "100%" }}
        >
          <Typography {...typographyProps}>{children}</Typography>
        </motion.div>
      </AnimatePresence>
    </Box>
  );
};

export default FadingTypography;
