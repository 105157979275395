import { Box } from "@mui/material";
import AccordionSection from "./AccordionSection";
import CtaSection from "./CtaSection";
import HeaderlessGradientLogoFramedLayout from "components/v3/layouts/HeaderlessGradientLogoFramedLayout";
import OverviewSection from "./OverviewSection";
import TitleSection from "./TitleSection";
import VideoSection from "./VideoSection";

const WelcomePage = () => {
  return (
    <HeaderlessGradientLogoFramedLayout>
      <Box
        sx={{
          boxSizing: "border-box",
          display: "flex",
          flexDirection: "column",
          gap: {
            xs: "10px",
            md: 8
          },
          maxWidth: "1280px"
        }}
      >
        <TitleSection />
        <OverviewSection />
        <VideoSection />
        <AccordionSection />
        <CtaSection />
      </Box>
    </HeaderlessGradientLogoFramedLayout>
  );
};

export default WelcomePage;
