import { makeStyles } from "@mui/styles";

const RequestPasswordResetEmailFailureStyles = makeStyles((theme) => ({
  img: {
    marginTop: 50,
    height: `80px`
  },
  notFound: {
    fontFamily: "DomaineDisplaySemibold",
    fontStyle: "normal",
    fontWeight: `600`,
    marginTop: 90,
    fontSize: 23,
    color: theme.palette.primary.font_blue
  },
  message: {
    fontFamily: "AvenirLTProMedium",
    fontSize: `14px`,
    marginTop: 60,
    paddingLeft: 30,
    paddingRight: 30,
    textAlign: `center`,
    color: theme.palette.primary.font_blue
  },
  assistance: {
    color: theme.palette.primary.font_blue,
    fontFamily: "AvenirLTProMedium",
    marginTop: 40,
    fontSize: 20
  },
  phone: {
    fontFamily: "AvenirLTProMedium",
    marginTop: 20,
    color: `red`,
    fontWeight: `bold`,
    fontSize: 22
  },
  number: {
    fontFamily: "AvenirLTProMedium",
    marginTop: 10,
    fontSize: 22
  },
  RoundedButton: {
    borderRadius: `100px`,
    marginTop: 50,
    height: `45px`,
    width: `200px`,
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.main
    },
    color: theme.palette.primary.white
  },
  LinkToFG: {
    fontFamily: "AvenirLTProMedium",
    textDecoration: "none",
    color: theme.palette.primary.white,
    fontWeight: `800`
  }
}));

export default RequestPasswordResetEmailFailureStyles;
