import React from "react";
import CMSDrivenPageTemplate from "../../CMSDrivenPageTemplate";
import { useParams } from "react-router-dom";
import contentJson from "../../Content/gl-cms-help-center-2024-12-16T20_24_09_00_00.json";

const HelpCenterPage = (props) => {
  // The useParam is needed to ensure re-load on root page
  const {} = useParams();

  // Check if / at the end of a URL, and if not adds it.
  // This is helpful to ensure exact match is made without much thought later
  function ensureTrailingSlash(data) {
    return data + (data.endsWith("/") ? "" : "/");
  }

  // Look through the contentJson to find the route that was provided.
  function findPageByRouteMap(pages, routeParam) {
    for (const key in pages) {
      if (pages.hasOwnProperty(key) && pages[key].route === routeParam) {
        return pages[key];
      }
    }
    return null;
  }

  const pagePathname = ensureTrailingSlash(window.location.pathname);

  return (
    <CMSDrivenPageTemplate
      gutters={contentJson.gutters}
      pages={contentJson.pages}
      page={findPageByRouteMap(contentJson.pages, pagePathname)}
    />
  );
};

export default HelpCenterPage;
