import React from "react";
import { SxProps } from "@mui/system";
import { Typography, Button, Box } from "@mui/material";
import { COLORS } from "../../Theme/colors";
import Spinner from "./Spinner";

type GLButtonProps = {
  buttonType?: "primary" | "secondary" | "secondary-transparent";
  loading?: boolean;
  onClick: () => void;
  text?: string;
  disabled?: boolean;
  leftIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;
  sx?: SxProps;
};
type StylesObject = SxProps;

const baseStyles: StylesObject = {
  alignItems: "center",
  borderRadius: "8px",
  cursor: "pointer",
  display: "flex",
  gap: "8px",
  justifyContent: "center",
  padding: "8px 24px",
  transition: "background-color 0.3s ease, border-radius 0.3s ease",
  textTransform: "none",
  width: "fit-content"
};

const variantStylesMap: Record<string, StylesObject> = {
  primary: {
    backgroundColor: COLORS.PRIMARY,
    margin: 0,
    typography: { xs: "body_strong_white", md: "body_strong_lg_white" },
    "&:hover": {
      backgroundColor: COLORS.PRIMARY_DARK,
      borderRadius: "16px 8px 16px 16px"
    },
    "&.Mui-disabled": {
      backgroundColor: COLORS.GRAY,
      color: COLORS.DARK_GRAY
    }
  },
  secondary: {
    backgroundColor: "white",
    border: `1px solid ${COLORS.PRIMARY}`,
    margin: 0,
    typography: { xs: "body_strong_primary", md: "body_strong_lg_primary" },
    "&:hover": {
      backgroundColor: COLORS.PRIMARY_LIGHTEST,
      borderRadius: "16px 8px 16px 16px"
    }
  },
  "secondary-transparent": {
    backgroundColor: "transparent",
    border: `1px solid ${COLORS.PRIMARY}`,
    margin: 0,
    typography: { xs: "body_strong_primary", md: "body_strong_lg_primary" },
    "&:hover": {
      backgroundColor: COLORS.PRIMARY_LIGHTEST,
      borderRadius: "16px 8px 16px 16px"
    }
  }
};

const GLButton: React.FC<React.PropsWithChildren<GLButtonProps>> = ({
  buttonType = "primary",
  children,
  loading,
  onClick,
  disabled,
  text,
  leftIcon,
  rightIcon,
  sx
}) => {
  const buttonStyles: Record<string, any> = {
    ...baseStyles,
    ...variantStylesMap[buttonType]
  };

  const buttonText = children ?? (
    <Typography
      sx={{
        typography: buttonStyles?.typography
      }}
    >
      {text}
    </Typography>
  );

  return (
    <Button
      component="button"
      disabled={loading || disabled}
      onClick={onClick}
      sx={{
        ...buttonStyles,
        ...sx
      }}
    >
      {leftIcon && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            marginRight: "8px"
          }}
        >
          {leftIcon}
        </Box>
      )}
      {buttonText}
      {loading ? (
        <Spinner />
      ) : (
        rightIcon && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              marginLeft: "8px"
            }}
          >
            {rightIcon}
          </Box>
        )
      )}
    </Button>
  );
};

export default GLButton;
