import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import CancerNavigationImg from "assets/images/CancerNavigation.svg";
import DNAScreeningsImg from "assets/images/DNAScreenings.svg";
import PrecisionHealthGuidanceImg from "assets/images/PrecisionHealthGuidance.svg";

const OverviewSection = () => {
  const { t } = useTranslation();

  return (
    <Box
      component="section"
      sx={{
        display: "flex",
        flexDirection: {
          xs: "column",
          md: "row"
        },
        gap: {
          xs: "15px",
          md: 4
        },
        paddingBottom: "10px",
        paddingTop: {
          xs: 0,
          md: 10
        },
        paddingX: {
          xs: "20px",
          md: 8
        }
      }}
    >
      <Box
        sx={{
          display: "flex",
          flex: 1,
          flexDirection: {
            xs: "row",
            md: "column"
          },
          gap: {
            xs: "15px",
            md: 4
          }
        }}
      >
        <>
          <Box
            component="img"
            sx={{
              height: {
                xs: "auto",
                md: "160px"
              },
              objectFit: "contain",
              width: {
                xs: "65px",
                md: "175px"
              }
            }}
            alt=""
            src={PrecisionHealthGuidanceImg}
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: {
                xs: "15px",
                md: 2
              }
            }}
          >
            <Typography variant="body_strong_black">
              {t("PRECISION_HEALTH_GUIDANCE")}
            </Typography>
            <Typography variant="body_sm_dark_gray">
              {t("PRECISION_HEALTH_GUIDANCE_DESCRIPTION")}
            </Typography>
          </Box>
        </>
      </Box>
      <Box
        sx={{
          display: "flex",
          flex: 1,
          flexDirection: {
            xs: "row",
            md: "column"
          },
          gap: {
            xs: "15px",
            md: 4
          },
          padding: {
            xs: "10px 0",
            md: 0
          }
        }}
      >
        <>
          <Box
            component="img"
            sx={{
              height: {
                xs: "auto",
                md: "160px"
              },
              objectFit: "contain",
              width: {
                xs: "65px",
                md: "175px"
              }
            }}
            alt=""
            src={DNAScreeningsImg}
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: {
                xs: "15px",
                md: 2
              }
            }}
          >
            <Typography variant="body_strong_black">
              {t("DNA_SCREENINGS")}
            </Typography>
            <Typography variant="body_sm_dark_gray">
              {t("DNA_SCREENINGS_DESCRIPTION")}
            </Typography>
          </Box>
        </>
      </Box>
      <Box
        sx={{
          display: "flex",
          flex: 1,
          flexDirection: {
            xs: "row",
            md: "column"
          },
          gap: {
            xs: "15px",
            md: 4
          },
          padding: {
            xs: "10px 0",
            md: 0
          }
        }}
      >
        <Box
          component="img"
          sx={{
            height: {
              xs: "auto",
              md: "160px"
            },
            objectFit: "contain",
            width: {
              xs: "65px",
              md: "175px"
            }
          }}
          alt=""
          src={CancerNavigationImg}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: {
              xs: "15px",
              md: 2
            },
            padding: {
              xs: "10px 0",
              md: 0
            }
          }}
        >
          <>
            <Typography variant="body_strong_black">
              {t("CANCER_NAVIGATION")}
            </Typography>
            <Typography variant="body_sm_dark_gray">
              {t("CANCER_NAVIGATION_DESCRIPTION")}
            </Typography>
          </>
        </Box>
      </Box>
    </Box>
  );
};

export default OverviewSection;
