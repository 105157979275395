import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { getCookie, setCookie } from "../../../../utils/CookieUtil";
import { doGreatInitialApiScrape } from "../../../../apis/authApi";
import {
  setUserAgreementHtmlFromApiReponse,
  setUserAgreementVersionAcceptanceStates
} from "../../../../store/reducers/termsOfService/TermsOfServiceSlice";
import {
  setNotificationSettings,
  setSpouseInformation,
  setGreatInitialApiScrapeIsHappening,
  updateFieldsInUserPersonalInformation,
  setGreatInitialApiScrapeIsComplete,
  setHasActiveCancerDiagnosis
} from "../../../../store/reducers/user/UserAccountSlice";
import {
  setAvailableKitsToOrder,
  setExistingOrders
} from "../../../../store/reducers/order/OrderInformationSlice";
import { updateSurveyAnswersForAllPreviousSurveys } from "../../../../store/reducers/survey/SurveySlice";
import Mixpanel from "../../../../utils/mixpanel";
import { PATHS } from "../../../../constants/pathConstants";
import { openGLSnackbar } from "../../../../store/reducers/snackbar/openGLSnackbarSlice";
import LoadingPage from "components/v3/pages/LoadingPage";
import { ACTIVE_STATUSES } from "constants/generalConstants";
const pgx_uuid = process.env.REACT_APP_PGX_TEST_UUID;
const ghs_uuid = process.env.REACT_APP_GHS_TEST_UUID;
const carrier_uuid = process.env.REACT_APP_CARRIER_TEST_UUID;

const ScrapeApiPage = () => {
  const {
    isAuthenticated,
    getAccessTokenSilently,
    getIdTokenClaims
  } = useAuth0();
  const dispatch = useDispatch();
  const history = useHistory();

  //This feels gross, but there are a few reasons we need the useEffect loop to
  //  kick over again in during the back and forth redirects required by the auth0 flow
  //  this lets us tell the effect to run agian on the next page load.
  const [refreshLoopCount, setRefreshLoopCount] = useState(0);

  const [scraping, setScraping] = useState(false);

  // Trash this as soon as the glid gets moved to app_metadata
  //  instead of user_metadata
  function findValueByKey(obj, key) {
    let foundValue = null;
    const search = (obj, key) => {
      Object.entries(obj).find(([prop, value]) => {
        if (prop === key) {
          foundValue = value;
          return true;
        } else if (typeof value === "object") {
          search(value, key);
        }
      });
    };
    search(obj, key);
    return foundValue;
  }

  useEffect(() => {
    const getToken = async () => {
      await getAccessTokenSilently({ cacheMode: "off" })
        .then((token) => {
          getIdTokenClaims().then(async (userDataFromAuth0Token) => {
            const userGlid = findValueByKey(
              userDataFromAuth0Token,
              "glMemberId"
            );
            const glProgramId = findValueByKey(
              userDataFromAuth0Token,
              "glProgramId"
            );
            const glMemberStatus = findValueByKey(
              userDataFromAuth0Token,
              "glMemberStatus"
            );
            dispatch(
              updateFieldsInUserPersonalInformation({
                glMemberId: userGlid,
                email: userDataFromAuth0Token.email,
                glProgramId: glProgramId,
                isMemberActive: ACTIVE_STATUSES.includes(
                  glMemberStatus?.replace(/[,\/\-\s']/g, "").toUpperCase()
                )
              })
            );
            Mixpanel.identify(userGlid);
            setCookie("accessToken", token);
            setRefreshLoopCount(refreshLoopCount + 1);
          });
        })
        .catch((error) => {
          if (
            [
              "invalid_grant",
              "missing_refresh_token",
              "consent_required"
            ].includes(error.error)
          ) {
            //access token reported not got, but that's bunk. Ignoring
            setRefreshLoopCount(refreshLoopCount + 1);
            //loginWithRedirect();
          } else {
            Mixpanel.track(
              "Login callback page was unable to get access token"
            );
            throw error;
          }

          //history.push(PATHS.LOG_OUT);
        });
    };

    const scrapeApi = async () => {
      if (!scraping) {
        setScraping(true);

        await dispatch(setGreatInitialApiScrapeIsHappening(true));

        try {
          // I have consolidated a lot of calls from around the app that caused slow page loads
          //  and inconsistent state in the store based on which pages you visited first. There had
          //  not been enough discipline in having pages get what they need and store what they changed.
          // This makes the login spinner run a little longer, and might get a few things some member
          //  sessions won't need, but it's not a lot of stuff, and this will at least be consisten.
          // This should all be audited and paired down / distributed again thoughtfully.
          doGreatInitialApiScrape()
            .then((responses) => {
              const [
                memberDataResponse,
                userAgreementVersionAcceptanceReponse,
                userAgreementHtmlReponse,
                spouseResponse,
                existingKitOrdersResponse,
                cancerDiagnosisResponse,
                gettingStartedSurveyResponse,
                allQuestionsFlatSurveyResponse
              ] = responses;

              // Remove this when the member endpoint stops serving this key
              const {
                isMemberActive,
                ...memberDateWithoutStatus
              } = memberDataResponse.data;
              dispatch(
                updateFieldsInUserPersonalInformation(memberDateWithoutStatus)
              );
              dispatch(
                setUserAgreementVersionAcceptanceStates(
                  userAgreementVersionAcceptanceReponse
                )
              );

              dispatch(
                setUserAgreementHtmlFromApiReponse(userAgreementHtmlReponse)
              );

              dispatch(
                setNotificationSettings(userAgreementVersionAcceptanceReponse)
              );

              dispatch(setSpouseInformation(spouseResponse.data.data));

              // This is a hack to stop getting this from the back end on the new v2 endpoint for now
              //  As soon as the new partner management system is up, replace this with a real call to that.
              const items = [
                {
                  test_uuid: ghs_uuid,
                  name: "Genetic Health Screen - Comprehensive",
                  description:
                    "This medical-grade test can reveal your risk for developing certain kinds of cancer, heart disease, and other actionable conditions.",
                  header: "What is a genetic health screen?",
                  test_name: "Genetic Health Screen",
                  purpose:
                    "Offers insight into risks for hereditary cancers, specific heart diseases, and other actionable conditions.",
                  lab: "Invitae",
                  order: 3
                },
                {
                  test_uuid: pgx_uuid,
                  name: "Pharmacogenomics Panel",
                  chipInformation: "Taking medications?",
                  description:
                    "This test provides information that can help you and your provider choose the medication and dosage that’s right for you.",
                  header: "Have you heard of PGx?",
                  test_name: "Pharmacogenomics (PGx)",
                  purpose:
                    "Supports understanding how your genetics affect your response to medications.",
                  lab: "OneOme",
                  order: 1
                },
                {
                  test_uuid: carrier_uuid,
                  name: "Comprehensive Carrier Screen",
                  chipInformation: "Planning on having a child?",
                  description:
                    "This test helps with family planning for you and your partner to understand changes in your genes that don’t affect you but may affect your child.",
                  header: "Are you and your reproductive partner carriers?",
                  test_name: "Carrier Screening",
                  purpose:
                    "Supports informed family planning by identifying genetic conditions that could be passed to your child.",
                  lab: "Myriad",
                  order: 2
                }
              ];
              dispatch(setAvailableKitsToOrder(items));
              dispatch(setExistingOrders(existingKitOrdersResponse));

              if (cancerDiagnosisResponse) {
                dispatch(
                  setHasActiveCancerDiagnosis(
                    cancerDiagnosisResponse.cancerStatus
                  )
                );
              }

              dispatch(
                updateSurveyAnswersForAllPreviousSurveys(
                  gettingStartedSurveyResponse
                )
              );
              // We're pulling both of these surveys because the back end
              // is not yet able to get answers for surveys with nested panels.
              // This flat survey is the same as the survey above, but with all
              // answers on one page. This way we get all the answers we need
              // to pre-populate the survey and set the card states. Pull this second
              // call as soon as the back end is able to traverse nested elements for
              // when scanning the survey for which answers to return, or better yet,
              // when we build an endpoint to just return all answers for a user.
              dispatch(
                updateSurveyAnswersForAllPreviousSurveys(
                  allQuestionsFlatSurveyResponse
                )
              );

              dispatch(setGreatInitialApiScrapeIsHappening(false));
              dispatch(setGreatInitialApiScrapeIsComplete(true));
              setScraping(false);

              // This is a gross stopgap
              setTimeout(() => {
                Mixpanel.track("Login successful");
                history.push(PATHS.LANDING_PAGE);
              }, 5000);
            })
            .catch((err) => {
              Mixpanel.track("Storing data from login api calls crashed");
              dispatch(setGreatInitialApiScrapeIsHappening(false));
              dispatch(setGreatInitialApiScrapeIsComplete(true));
              dispatch(
                openGLSnackbar({
                  variant: "error",
                  header: "Error logging in",
                  subText:
                    "Sorry, something went wrong when we tried to log you in. Please try again."
                })
              );
              window.location.href = `${PATHS.LOG_OUT}`;
            });
        } catch {
          Mixpanel.track("Login api calls crashed");
          dispatch(setGreatInitialApiScrapeIsHappening(false));
          dispatch(setGreatInitialApiScrapeIsComplete(true));
          dispatch(
            openGLSnackbar({
              variant: "error",
              header: "Error logging in",
              subText:
                "Sorry, something went wrong when we tried to log you in. Please try again."
            })
          );
          window.location.href = `${PATHS.LOG_OUT}`;
        }
      }
    };

    if (!getCookie("accessToken")) {
      getToken();
    } else {
      getIdTokenClaims().then(async (userDataFromAuth0Token) => {
        const userGlid = findValueByKey(userDataFromAuth0Token, "glMemberId");
        if (userGlid) {
          scrapeApi();
        } else {
          getToken();
        }
      });
    }
  }, [isAuthenticated, refreshLoopCount]);

  return (
    <div>
      <LoadingPage message={"Retrieving your account..."} />
    </div>
  );
};

export default ScrapeApiPage;
