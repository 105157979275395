import React from "react";

import HeaderLogo from "../../../../../assets/images/LogoIconDefault.svg";
import { Grid, Typography } from "@mui/material";
import NoButtonsInfoDialog from "components/v3/sharedComponents/modals/NoButtonsInfoDialog";
import GLButton from "../../GLButton";

interface GhsOnlyModalProps {
  isOpen: boolean;
  memberName: string;
  onClose: () => void;
}

const GhsOnlyModal: React.FC<GhsOnlyModalProps> = ({
  isOpen,
  memberName,
  onClose
}) => {
  return (
    <NoButtonsInfoDialog isOpen={isOpen} onClose={onClose}>
      <Grid item xs={12} pt={3} pb={3}>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          sx={{
            textAlign: "center",
            "& p": { marginBottom: "15px" },
            "& h4": { marginBottom: "20px" }
          }}
        >
          <Grid
            item
            xs={12}
            sx={{
              textAlign: "center",
              marginTop: "-30px",
              marginBottom: "10px"
            }}
          >
            <img src={HeaderLogo} alt="Logo" style={{ maxWidth: "100%" }} />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h4_primary">Hello {memberName}!</Typography>
          </Grid>
          <Grid item xs={9} mb={3}>
            <Typography>Welcome to Genomic Life!</Typography>
            <Typography>
              Your membership gives you access to{" "}
              <strong>Precision Health Guidance</strong> and{" "}
              <strong>Genetic Health Screen (GHS) DNA screening.</strong> These
              services are designed to optimize your health now, and in the
              future.
            </Typography>
            <Typography>
              Genomic Life offers other DNA screenings and services that are not
              currently part of your benefit plan. You may see these services
              mentioned throughout our portal.
            </Typography>
            <Typography variant="body_dark_gray">
              <i>
                If you would like to learn more about other services we offer or
                have questions, please send us an email at{" "}
                <a href="mailto:navigation@genomiclife.com">
                  navigation@genomiclife.com
                </a>
              </i>
            </Typography>
          </Grid>
          <GLButton onClick={onClose}>Ok, Continue</GLButton>
        </Grid>
      </Grid>
    </NoButtonsInfoDialog>
  );
};

export default GhsOnlyModal;
