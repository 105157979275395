import React, { useEffect, useState } from "react";
import parsePhoneNumberFromString from "libphonenumber-js";

export type FormFieldContents = string | boolean | number;

interface FormState<T> {
  formFields: T;
  formStatus: {
    invalidFields: string[];
  };
}

export interface UseFormFieldStateProps<T> {
  initialFormState: T;
  onFormFieldsUpdated: (updatedFields: T) => void;
}

/*
 This hook is just form state management encapsulated. It can store
  and update values for whatever inputs you have, as well as an aggregated
  'valid' state for all the fields on the form which can be used to
  disable the submit button.
*/
export const useFormFieldState = <T extends Record<string, FormFieldContents>>(
  initialFormState: T,
  onFormFieldsUpdated?: (updatedFields: T) => void
) => {
  const [formState, setFormState] = useState<FormState<T>>({
    formFields: initialFormState,
    formStatus: {
      invalidFields: []
    }
  });

  const handleInputChange = ({
    e,
    isInvalid
  }: {
    e: React.ChangeEvent<HTMLInputElement>;
    isInvalid: boolean;
  }) => {
    const { name, value } = e.target;
    setFormState((prevState) => {
      const updatedFormFields = {
        ...prevState.formFields,
        [name]: value as FormFieldContents
      };

      const updatedInvalidFields = isInvalid
        ? Array.from(new Set([...prevState.formStatus.invalidFields, name]))
        : prevState.formStatus.invalidFields.filter((field) => field !== name);

      return {
        formFields: updatedFormFields,
        formStatus: {
          invalidFields: updatedInvalidFields
        }
      };
    });
  };

  useEffect(() => {
    if (onFormFieldsUpdated) {
      onFormFieldsUpdated(formState.formFields);
    }
  }, [formState.formFields, onFormFieldsUpdated]);

  return {
    formFields: formState.formFields,
    formStatus: formState.formStatus,
    handleInputChange
  };
};

export const validateZipCode = (valueToTest?: string): string | null => {
  if (!valueToTest) {
    return "Valid zip code required";
  }
  const regex = new RegExp("(^\\d{5}$)|(^\\d{5}-\\d{4}$)");
  if (!regex.test(valueToTest)) {
    return "Valid zip code required";
  }
  return null;
};

export const validateUSPhoneNumber = (phone: string): string | null => {
  const usPhoneNumber = `+1${phone}`;
  const parsedPhoneNumber = parsePhoneNumberFromString(usPhoneNumber, "US");
  if (parsedPhoneNumber?.isValid()) {
    return null;
  } else {
    return "Valid U.S. phone number with area code required";
  }
};

export const pickSubsetOfAttributes = <T extends object, K extends keyof T>(
  obj: T,
  keys: K[]
): Partial<T> => {
  const result: Partial<T> = {};
  keys.forEach((key) => {
    if (key in obj) {
      result[key] = obj[key];
    }
  });
  return result;
};
