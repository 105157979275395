import React from "react";
import { Box, Typography } from "@mui/material";
import SubStep, { SubStepType } from "./SubStep";
import SuperStepDot, { SuperStepState } from "./SuperStepDot";
import { calculateStepState } from "./utils";

export interface Step {
  stepIndex: number;
  shortName: string;
  longName: string;
  subSteps: SubStepType[];
}

interface VerticalStepItemProps {
  step: Step;
  activeStepIndex: number;
  menuItemIndex: number;
}

const VerticalStepItem: React.FC<VerticalStepItemProps> = ({
  step,
  activeStepIndex,
  menuItemIndex
}) => {
  const { shortName, subSteps } = step;
  const { stepState, superStepColoration } = calculateStepState(
    step,
    activeStepIndex
  );

  const renderedSubSteps = [
    SuperStepState.ChildIsActive,
    SuperStepState.Active
  ].includes(stepState) &&
    subSteps.length > 0 && (
      <Box ml={2}>
        {subSteps.map((subStep) => (
          <SubStep
            key={subStep.stepIndex}
            subStep={subStep}
            activeStepIndex={activeStepIndex}
          />
        ))}
      </Box>
    );

  return (
    <Box display="flex" flexDirection="row" alignItems="flex-start" mb={3}>
      <Box display="flex" flexDirection="column" alignItems="center">
        <Box
          sx={{
            width: "40px",
            height: "40px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <SuperStepDot state={stepState} index={menuItemIndex + 1} />
        </Box>
      </Box>
      <Box ml={2}>
        <Typography
          mt={0.9}
          variant="body_strong_lg"
          color={superStepColoration}
        >
          {shortName}
        </Typography>
        {renderedSubSteps}
      </Box>
    </Box>
  );
};

export default VerticalStepItem;
