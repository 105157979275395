import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import { KeyboardArrowLeft } from "@mui/icons-material";
import React from "react";
import { Link } from "react-router-dom";
import BreadcrumbStyles from "./Breadcrumb.styles";
import Translate from "components/v3/sharedComponents/Translate";

/**
 * @description This is a reusable component able to redirect
 * to another page.
 * Props:
 * text: this is the text showed on the Link item
 * redirectTo: the route to redirect
 * @param {text, redirectTo} props
 * @returns redirect to another url
 */
const Breadcrumb = (props) => {
  const { page, pages } = props;
  const classes = BreadcrumbStyles();
  const theme = useTheme();
  const changeVariant = useMediaQuery(theme.breakpoints.up("sm"));

  const renderCrumbs = () => {
    return Object.entries(page?.parents || {}).map(([index, child]) => {
      return (
        <Link
          to={pages[child.ref].route}
          className={classes.breadcrumbLink}
          name={pages[child.ref].title}
        >
          {index !== 0 && (
            <KeyboardArrowLeft className={classes.breadcrumbIcon} />
          )}
          <Typography
            variant={
              changeVariant ? "deprecated_subtitle1" : "deprecated_body1"
            }
            className={classes.breadcrumbText}
          >
            <Translate text={pages[child.ref].title} />
          </Typography>
        </Link>
      );
    });
  };

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="flex-start"
        className={classes.breadcrumbContainer}
        name="breadcrumb"
      >
        {renderCrumbs()}
      </Box>
    </>
  );
};

export default Breadcrumb;
