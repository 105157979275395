import React from "react";
import { Grid, Typography } from "@mui/material";
import ArticleStyles from "./ContentHeading.styles";
import ContentResolver from "../ContentResolver";

const ContentHeading = (props) => {
  const { title } = props.page;
  const classes = ArticleStyles();

  return (
    <Grid container className={classes.container}>
      <Grid item xs={11}>
        <Typography variant="deprecated_h1" className={classes.header}>
          <ContentResolver content={title} />
        </Typography>
      </Grid>
    </Grid>
  );
};

export default ContentHeading;
