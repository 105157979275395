import React from "react";
import ResourceLinkStyles from "./ResourceLink.styles";
import ContentResolver from "../ContentResolver";
import LaunchIcon from "@mui/icons-material/Launch";

const ResourceLink = (props) => {
  const classes = ResourceLinkStyles();

  return (
    <>
      <a
        href={props.link.route}
        target={props.link.target}
        className={classes.link}
      >
        <ContentResolver content={props.link.title} />
        {props.link.target == "_blank" && (
          <>
            {" "}
            <LaunchIcon style={{ fontSize: "85%", opacity: 0.5 }} />
          </>
        )}
      </a>
    </>
  );
};

export default ResourceLink;
