import { doApiCall } from "./util";
import Mixpanel from "../utils/mixpanel";
const {
  REACT_APP_MEMBER_PORTAL_ENDPOINT_URL,
  REACT_APP_REGISTRATION_API_URL
} = process.env;

/**
 * This endpoint confirms the spouse information, to send the invite
 * @returns {json} the data required from response
 */
export const postSpouseInvitation = (payload) => {
  const request = {
    host: `${REACT_APP_MEMBER_PORTAL_ENDPOINT_URL}`,
    method: "POST",
    url: `https://${REACT_APP_MEMBER_PORTAL_ENDPOINT_URL}/member/spouse`,
    data: payload,
    body: JSON.stringify(payload)
  };
  Mixpanel.track(`Spouse invited`);

  return doApiCall(request);
};

/**
 * This endpoint allows to get the spouse information before the invite
 * @returns {json} the data required from response
 */
export const getSpouseInformation = () => {
  const request = {
    host: `${REACT_APP_MEMBER_PORTAL_ENDPOINT_URL}`,
    method: "GET",
    url: `https://${REACT_APP_MEMBER_PORTAL_ENDPOINT_URL}/member/spouse`,
    responseType: "json"
  };

  return doApiCall(request);
};

export const getSpouseEligibility = () => {
  const request = {
    host: `${REACT_APP_MEMBER_PORTAL_ENDPOINT_URL}`,
    method: "GET",
    url: `https://${REACT_APP_REGISTRATION_API_URL}/user/spouse`,
    responseType: "json"
  };

  return doApiCall(request);
};
