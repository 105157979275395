import React from "react";
import { Grid, Typography } from "@mui/material";
import CardGridStyles from "./CardGrid.styles";
import ContentResolver from "../ContentResolver";

const CardGrid = (props) => {
  const {
    CardToUse,
    columnsAtMaxWidth,
    backgroundColor,
    cardSpacing,
    header,
    cards
  } = props;
  const classes = CardGridStyles();

  const renderCards = () => {
    return cards.map((card, index) => {
      return (
        <Grid item xs={12} sm={12 / columnsAtMaxWidth} key={index}>
          <CardToUse {...card} />
        </Grid>
      );
    });
  };

  const maybeHeader = header ? (
    <Typography variant="deprecated_h2" className={classes.header}>
      <ContentResolver content={header.en} />
    </Typography>
  ) : null;

  return (
    <Grid
      container
      className={classes.container}
      justifyContent="center"
      style={{ backgroundColor: backgroundColor }}
    >
      <Grid item xs={11}>
        {maybeHeader}
        <Grid container spacing={cardSpacing}>
          {renderCards()}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CardGrid;
