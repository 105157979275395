import { makeStyles } from "@mui/styles";
import { COLOR_CONSTANTS } from "../../../../../constants/StyleConstants";

const LinkBoxGridStyles = makeStyles((theme) => ({
  container: {
    padding: "10px 20px 1rem"
  },
  linkBoxContainer: {
    display: "flex"
  },
  linkBox: {
    borderBottom: `1px solid ${COLOR_CONSTANTS.COLOR_GRAY_30}`,
    width: "95%",
    paddingBottom: "1rem",
    marginBottom: "1rem",
    lineHeight: "1.5rem"
  },
  link: {
    color: COLOR_CONSTANTS.COLOR_BLACK_75,
    lineHeight: "normal",
    textDecoration: "none",
    fontWeight: "700",
    fontSize: "1.05rem",
    "&:hover": {
      textDecoration: "underline"
    }
  }
}));

export default LinkBoxGridStyles;
