import { makeStyles } from "@mui/styles";
import { COLOR_CONSTANTS } from "../../../../../constants/StyleConstants";

const ArticleStyles = makeStyles((theme) => ({
  container: {
    padding: "10px 20px 1rem",
    color: "black",
    fontSize: "1.25rem"
  },
  articleContainer: {
    paddingRight: 20,
    "& img": { width: "100%", height: "auto" }
  },
  header: {
    color: COLOR_CONSTANTS.GENOMIC_LIFE_PRIMARY,
    margin: "2rem 0 0",
    fontSize: "3rem",
    lineHeight: "3rem"
  },
  summary: { marginBottom: "1rem" }
}));

export default ArticleStyles;
