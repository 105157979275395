import { useSelector } from "react-redux";
import { Box, Typography } from "@mui/material";
import { SxProps } from "@mui/system";
import { useTranslation } from "react-i18next";
import { COLORS } from "components/v3/Theme/colors";
import { selectUserPersonalInformation } from "store/reducers/user/UserAccountSlice";
import GLLogo from "assets/images/GL-Logo.svg";

type IntroductionSectionProps = {
  sx?: SxProps;
};

const IntroductionSection: React.FC<IntroductionSectionProps> = ({
  sx = {}
}) => {
  const memberInformation = useSelector(selectUserPersonalInformation);
  const nameToDisplay =
    memberInformation.preferredName ?? memberInformation.firstName;
  const { t } = useTranslation();

  return (
    <Box
      component="section"
      sx={{
        alignItems: "center",
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "column",
        gap: "30px",
        justifyContent: "center",
        width: "100%",
        ...sx
      }}
    >
      <Box
        component="img"
        sx={{
          height: 44,
          width: 185
        }}
        alt={t("LOGO_ALT_TEXT")}
        src={GLLogo}
      />
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          gap: "5px",
          width: "100%"
        }}
      >
        <Box sx={{ display: "flex", gap: "5px" }}>
          <Typography variant="body_xl_black">Hi</Typography>
          <Typography variant="body_xl_black">{nameToDisplay},</Typography>
        </Box>
        <Typography
          variant="body_serif_black"
          sx={{
            background: `linear-gradient(to right, ${COLORS.ACCENT3}, ${COLORS.PRIMARY})`,
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent"
          }}
        >
          We're so glad you're here!
        </Typography>
      </Box>
    </Box>
  );
};

export default IntroductionSection;
