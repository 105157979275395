import React from "react";
import { Grid, Typography } from "@mui/material";
import OptionWithIconCardStyles from "./OptionWithIconCard.styles";

const OptionWithIconCard = (props) => {
  const { image, header, href } = props;
  const classes = OptionWithIconCardStyles();

  const renderedCardContents = (
    <Grid container className={classes.container}>
      <Grid item xs={3}>
        <img className={classes.icon} {...image} />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="deprecated_h6" className={classes.header}>
          {header.en}
        </Typography>
      </Grid>
      <Grid item xs={12} className={classes.text}>
        <Typography variant="deprecated_subtitle1">{href.title.en}</Typography>
      </Grid>
    </Grid>
  );

  return !!href ? (
    <a href={href.path} className={classes.linkContainer}>
      {renderedCardContents}
    </a>
  ) : (
    { renderedCardContents }
  );
};

export default OptionWithIconCard;
