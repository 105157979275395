import React from "react";
import { Typography, Card, CardActions, Grid, Chip } from "@mui/material";
import ProductCardStyles from "./ProductCard.styles";
import Translate from "../../../translate/Translate";
import CheckIcon from "../../../assets/icons/checkIcon.svg";
const ProductCard = (props) => {
  const {
    buttonText,
    buttonAction,
    buttonSecondaryAction,
    isCardRecommended,
    cardTitle,
    cardImage,
    altImg,
    cardDescription,
    productIdentifier,
    isCardSelected,
    onSelect,
    keyId,
    kitDetails,
    userHasOrder,
    chipInformation,
    hasFilesReadyToDownload
  } = props;
  const classes = ProductCardStyles(props);

  const stateBasedAttributeGenerator = () => {
    if (hasFilesReadyToDownload && buttonSecondaryAction && userHasOrder) {
      return {
        text: "Download PDF",
        styles: `${classes.bottomCardContainer} ${classes.enabled}`,
        function: null
      };
    }
    if (!kitDetails && !hasFilesReadyToDownload && isCardSelected) {
      return {
        text: "Added to request",
        styles: `${classes.bottomCardContainer} ${classes.enabled}`,
        function: () => onSelect(productIdentifier),
        chip: true
      };
    }
    if (kitDetails && !hasFilesReadyToDownload) {
      return {
        text: "Kit requested",
        styles: `${classes.bottomCardContainer} ${classes.disabled}`,
        function: null
      };
    }
    if (!kitDetails && !hasFilesReadyToDownload && isCardRecommended) {
      return {
        text: "Add to request",
        styles: `${classes.bottomCardContainer} ${classes.enabled}`,
        function: () => onSelect(productIdentifier),
        chip: true
      };
    }
    if (!kitDetails && !hasFilesReadyToDownload && !isCardRecommended) {
      return {
        text: "Add to request",
        styles: `${classes.bottomCardContainer} ${classes.disabled}`,
        function: () => {},
        chip: true
      };
    }
    return {
      text: buttonText ? buttonText : "",
      styles: `${classes.bottomCardContainer} ${classes.enabled}`,
      function: null
    };
  };

  const selectedCheckRender = () => {
    return (
      isCardSelected && (
        <img src={CheckIcon} className={classes.checkIcon} alt="check" />
      )
    );
  };

  const chipInformationRender = () => {
    const showChip = stateBasedAttributeGenerator().chip;
    const text = isCardRecommended ? "Recommended" : "Not recommended";

    return showChip ? (
      <div
        className={
          isCardRecommended
            ? classes.recommendedChip
            : classes.notRecommendedChip
        }
      >
        <Chip
          label={
            <Typography
              variant="deprecated_subtitle2"
              className={classes.boldChip}
            >
              <Translate text={text} />
            </Typography>
          }
        />
      </div>
    ) : null;
  };

  const renderDescriptionHelper = () => {
    if (cardDescription) {
      return (
        <Grid
          className={classes.descriptionTextContainer}
          container
          alignItems="flex-start"
          justifyContent="center"
        >
          <Grid item xs={11} md={10}>
            <Typography className={classes.descriptionText}>
              <Translate text={cardDescription} />
            </Typography>
          </Grid>
        </Grid>
      );
    }
    return null;
  };

  const renderButtonHelper = () => {
    const buttonContent = stateBasedAttributeGenerator();
    if (buttonAction) {
      return (
        <Grid
          item
          xs={12}
          className={`screening-card-link ${
            buttonContent.function ? classes.buttonActionContainer : null
          }`}
          data-cy={productIdentifier}
          id={`card-button-${productIdentifier}`}
          onClick={
            hasFilesReadyToDownload && buttonSecondaryAction
              ? () => buttonSecondaryAction(productIdentifier)
              : buttonContent.function
          }
        >
          <Grid container alignItems="center" justifyContent="center">
            <CardActions className={buttonContent.styles}>
              <Typography
                variant="deprecated_subtitle1"
                align="center"
                className={classes.bottomText}
              >
                {<Translate text={buttonContent.text} />}
              </Typography>
            </CardActions>
          </Grid>
        </Grid>
      );
    }
    return null;
  };

  const cardContainerStyle = `${classes.cardBody} ${
    isCardSelected ? classes.cardSelectedBody : null
  }`;

  const paddingPerMarkersHelper = () => {
    if (chipInformation) {
      return -30;
    }
    return null;
  };

  return (
    <Grid
      item
      xs={12}
      style={{ marginTop: paddingPerMarkersHelper() }}
      key={keyId}
    >
      <Grid container alignContent="center" justifyContent="center">
        {chipInformationRender()}
      </Grid>
      <Card className={cardContainerStyle}>
        {selectedCheckRender()}
        <Grid
          container
          direction="column"
          alignContent="center"
          justifyContent="center"
        >
          <img src={cardImage} alt={altImg} className={classes.image} />
          <Grid
            container
            alignItems="center"
            justifyContent="center"
            alignContent="center"
          >
            <Typography
              className={`${classes.textCentered} card-name-header`}
              variant="deprecated_h6"
              align="center"
            >
              <Translate text={cardTitle} />
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {renderDescriptionHelper()}
          </Grid>
        </Grid>
        {renderButtonHelper()}
      </Card>
    </Grid>
  );
};
export default ProductCard;
