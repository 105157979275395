import React from "react";
import { Grid } from "@mui/material";
import GutterCardStyles from "./GutterCard.styles";
import ContentResolver from "../ContentResolver";

const GutterCard = (props) => {
  const { material } = props;
  const classes = GutterCardStyles();

  const renderChildren = (objects) => {
    return Object.entries(objects || {}).map(([index, child]) => {
      return (
        <Grid item xs={12} key={index} className={classes.linkBoxContainer}>
          <div className={classes.linkBox}>
            <a
              href={child.route}
              className={`${classes.link} ${classes.parentLink}`}
            >
              <ContentResolver content={child.title} />
            </a>
            {renderChildChildren(child?.branch)}
          </div>
        </Grid>
      );
    });
  };

  const renderChildChildren = (objects) => {
    let renderReturn = Object.entries(objects || {}).map(([index, child]) => {
      return (
        <li>
          <a href={child.route} target={child.target} className={classes.link}>
            <ContentResolver content={child.title} />
          </a>
        </li>
      );
    });
    if (renderReturn)
      return <ul className={classes.childrenList}>{renderReturn}</ul>;
  };

  return (
    <div className={classes.container}>
      <div
        style={{
          marginTop: ".5rem",
          marginBottom: ".75rem",
          paddingBottom: ".5rem",
          fontWeight: "700",
          borderBottom: "1px solid #C4C4C4",
          fontSize: "1.25rem"
        }}
      >
        Topics
      </div>
      {renderChildren(material.navTreeTopics.navTree)}
    </div>
  );
};

export default GutterCard;
