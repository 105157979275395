import { Box, Typography } from "@mui/material";
import { COLORS } from "components/v3/Theme/colors";
import ArrowForward from "@mui/icons-material/ArrowForward";
import { useHistory } from "react-router-dom";
import { PATHS } from "constants/pathConstants";

const CtaSection = () => {
  const history = useHistory();

  return (
    <Box
      component="section"
      sx={{
        alignItems: {
          xs: "flex-start",
          md: "center"
        },
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "column",
        paddingBottom: {
          xs: "50px",
          md: 8
        },
        paddingTop: {
          xs: "50px",
          md: 4
        },
        paddingX: {
          xs: "20px",
          md: 8
        },
        width: "100%"
      }}
    >
      <Box
        sx={{
          alignItems: "center",
          background: "linear-gradient(89deg, #1C2659 0.38%, #284A95 99.51%)",
          borderRadius: "50px 0px 50px 50px",
          boxShadow:
            "0px 4px 6px -1px rgba(0, 0, 0, 0.10), 0px 2px 4px -1px rgba(0, 0, 0, 0.06)",
          boxSizing: "border-box",
          display: "flex",
          flexDirection: "column",
          gap: {
            xs: "10px",
            md: "37px"
          },
          padding: {
            xs: "50px 10px",
            md: "30px 80px"
          },
          width: "100%"
        }}
      >
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            gap: "20px",
            textAlign: "center"
          }}
        >
          <Typography
            sx={{
              typography: {
                xs: "body_strong_lg_white",
                md: "body_strong_xl_white"
              }
            }}
          >
            Are you ready to take the next step?
          </Typography>
          <Box
            onClick={() =>
              history.push(PATHS.ONBOARDING_REGISTRATION_INSTRUCTIONS)
            }
            sx={{
              background: "transparent",
              border: 0,
              cursor: "pointer"
            }}
          >
            <Typography
              sx={{
                alignItems: "center",
                display: "flex",
                gap: 1,
                opacity: 0.5,
                transition: "opacity 100ms linear",
                whiteSpace: "nowrap",
                "&:hover": {
                  opacity: 1
                }
              }}
              variant="body_strong_white"
            >
              Activate Your Account Today!
              <ArrowForward sx={{ color: COLORS.WHITE }} />
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default CtaSection;
