import { Step } from "./index";
import { SuperStepState } from "./SuperStepDot";

export function calculateStepState(
  step: Step,
  activeStepIndex: number
): { stepState: SuperStepState; superStepColoration: string } {
  const selfIsActive = step.stepIndex === activeStepIndex;
  const childIsActive = step.subSteps?.some(
    (subStep) => subStep.stepIndex === activeStepIndex
  );
  const hasSubSteps = step.subSteps && step.subSteps.length > 0;
  const isComplete =
    hasSubSteps &&
    step.subSteps.every((subStep) => subStep.stepIndex < activeStepIndex);

  const superStepColoration = isComplete
    ? "primary"
    : selfIsActive || childIsActive
    ? "accent2"
    : "dark_gray";

  const stepState = selfIsActive
    ? SuperStepState.Active
    : childIsActive
    ? SuperStepState.ChildIsActive
    : isComplete
    ? SuperStepState.Complete
    : SuperStepState.Inactive;

  return { stepState, superStepColoration };
}
