import React, { useEffect, useState } from "react";
import {
  Breadcrumbs,
  Button,
  Grid,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import { PATHS } from "../../../../constants/pathConstants";
import Layout from "../../../layout/Layout";
import PageTitle from "../../../../utils/PageTitle";
import TestResultDocumentPageStyles from "./TestResultDocumentPage.styles";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectExistingOrders } from "../../../../store/reducers/order/OrderInformationSlice";
import { pdfjs, Document, Page } from "react-pdf";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import LoadingSection from "../../../sharedComponents/LoadingSection";
import { downloadOrderDocument } from "../../../../apis/ordersApi";
import "react-pdf/dist/Page/TextLayer.css";
import "react-pdf/dist/Page/AnnotationLayer.css";

const ViewTestDocumentPage = (props) => {
  PageTitle("Test result document");
  const classes = TestResultDocumentPageStyles();
  const { glOrderId, documentId } = useParams();
  const order = useSelector(selectExistingOrders).find(
    (order) => order.glOrderId === glOrderId
  );
  const [numPages, setNumPages] = useState();
  const [pageNumber, setPageNumber] = useState(1);
  const [pdfFile, setPdfFile] = useState(null);
  const theme = useTheme();

  const pdfjsWorker = process.env.PUBLIC_URL + "/pdf.worker.min.js";
  pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

  useEffect(() => {
    const downloadDocument = async () => {
      downloadOrderDocument(glOrderId, documentId).then((response) => {
        setPdfFile(response.data);
      });
    };
    downloadDocument();
  }, []);

  const screenWidthIsXl = useMediaQuery(theme.breakpoints.up("xl"));
  const screenWidthIsLg = useMediaQuery(theme.breakpoints.up("lg"));
  const screenWidthIsMd = useMediaQuery(theme.breakpoints.up("md"));
  const screenWidthIsSm = useMediaQuery(theme.breakpoints.up("sm"));

  const docWidth = screenWidthIsXl
    ? 1580
    : screenWidthIsLg
    ? 910
    : screenWidthIsMd
    ? 730
    : screenWidthIsSm
    ? 640
    : 340;

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const handleNextPageClick = () => {
    if (pageNumber < numPages) {
      setPageNumber(pageNumber + 1);
    }
  };

  const handleBackPageClick = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  };

  const renderDocument = () => {
    if (pdfFile) {
      return (
        <Document
          file={`
            data:application/pdf;base64,${pdfFile}`}
          onLoadSuccess={onDocumentLoadSuccess}
        >
          <Page width={docWidth} pageNumber={pageNumber} />
        </Document>
      );
    } else {
      return (
        <div className={classes.loading}>
          {" "}
          <LoadingSection />
        </div>
      );
    }
  };

  const renderPageButtons = () => {
    if (numPages) {
      return (
        <Grid
          item
          container
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Grid item>
            <Button
              disabled={pageNumber <= 1}
              className={classes.pageButton}
              onClick={handleBackPageClick}
            >
              <ArrowBackIcon className={classes.buttonIconBack} /> Back
            </Button>
          </Grid>
          <Grid item>
            {pageNumber}/{numPages}
          </Grid>
          <Grid item>
            <Button
              disabled={pageNumber >= numPages}
              className={classes.pageButton}
              onClick={handleNextPageClick}
            >
              Next <ArrowForwardIcon className={classes.buttonIconForward} />
            </Button>
          </Grid>
        </Grid>
      );
    }
  };

  return (
    <div>
      <Layout
        headerType="primary"
        headerText={"The genomics that matter to you"}
        goBackPath={PATHS.LANDING_PAGE}
        goBackTitle="Dashboard"
        hasNotHeader={true}
        footerType="v2"
      >
        <Grid container className={classes.container}>
          <Grid container item md={12} justifyContent={"center"}>
            <Grid item xs={11} sm={11}>
              <Grid>
                <Typography variant="h2_primary">
                  {order.description.name}
                </Typography>

                <Breadcrumbs
                  className={classes.breadcrumbs}
                  aria-label="breadcrumb"
                  separator={<NavigateNextIcon fontSize="small" />}
                >
                  <a href={PATHS.LANDING_PAGE}>
                    <Typography variant="body_dark_gray">Dashboard</Typography>
                  </a>
                  <Typography variant="body_dark_gray">
                    {order.description.name}
                  </Typography>
                </Breadcrumbs>
              </Grid>
            </Grid>
            <Grid item className={classes.docContainer}>
              {renderDocument()}
              {renderPageButtons()}
            </Grid>
          </Grid>
        </Grid>
      </Layout>
    </div>
  );
};

export default ViewTestDocumentPage;
