import React from "react";
import { Grid, Typography } from "@mui/material";
import HeroHeaderStyles from "./HeroHeader.styles";
import ContentResolver from "../ContentResolver";

const HeroHeader = (props) => {
  const { header, content } = props.material;
  const classes = HeroHeaderStyles();

  return (
    <Grid container className={classes.container} justifyContent="center">
      <Grid item xs={7} className={classes.textContainer}>
        <Typography variant="deprecated_h2" className={classes.heading}>
          <ContentResolver content={header} />
        </Typography>
        <Typography variant="deprecated_subtitle1" className={classes.content}>
          <div dangerouslySetInnerHTML={{ __html: content }} />
        </Typography>
      </Grid>
    </Grid>
  );
};

export default HeroHeader;
