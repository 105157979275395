import React, { useState, useEffect, useRef } from "react";
import Frame1 from "assets/images/logo-2.svg";
import Frame2 from "assets/images/logo-3.svg";
import Frame3 from "assets/images/logo-4.svg";
import Frame4 from "assets/images/logo-1.svg";

const svgComponents = [
  <img src={Frame1} alt="logo" />,
  <img src={Frame2} alt="logo" />,
  <img src={Frame3} alt="logo" />,
  <img src={Frame4} alt="logo" />
];

const LoadingAnimation: React.FC = () => {
  const [visibleIndexes, setVisibleIndexes] = useState<number[]>([]);
  const totalFrames = svgComponents.length;

  const initialDelay = 300; // Time before any frames appear
  const frameDelay = 300; // Delay between each frame starting to fade in
  const waitTime = 300; // Time to wait after all frames are visible before fading out
  const fadeOutDuration = 300; // Duration of the fade-out transition

  const totalDuration =
    initialDelay + totalFrames * frameDelay + waitTime + fadeOutDuration;

  const timeouts = useRef<number[]>([]);

  useEffect(() => {
    const animationSequence = () => {
      timeouts.current.forEach((timeout) => clearTimeout(timeout));
      timeouts.current = [];

      setVisibleIndexes([]);

      // Schedule fade-ins for each frame
      for (let i = 0; i < totalFrames; i++) {
        const timeout = window.setTimeout(() => {
          setVisibleIndexes((prev) => [...prev, i]);
        }, initialDelay + i * frameDelay);
        timeouts.current.push(timeout);
      }

      // Schedule fade-out after all frames are visible and wait time has passed
      const fadeOutTimeout = window.setTimeout(() => {
        setVisibleIndexes([]);
      }, initialDelay + totalFrames * frameDelay + waitTime);
      timeouts.current.push(fadeOutTimeout);
    };

    animationSequence();

    const interval = window.setInterval(animationSequence, totalDuration);

    return () => {
      timeouts.current.forEach((timeout) => clearTimeout(timeout));
      clearInterval(interval);
    };
  }, []);

  return (
    <div
      style={{
        width: "100px",
        height: "100px",
        position: "relative"
      }}
    >
      {svgComponents.map((svg, index) => (
        <div
          key={index}
          style={{
            opacity: visibleIndexes.includes(index) ? 1 : 0,
            transition: "opacity 0.3s ease",
            width: "100%",
            height: "100%",
            position: "absolute",
            top: 0,
            left: 0
          }}
        >
          {svg}
        </div>
      ))}
    </div>
  );
};

export default LoadingAnimation;
