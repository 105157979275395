import React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { NavLink } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Hidden from "@mui/material/Hidden";
import { useMediaQuery, useTheme } from "@mui/material";
import HeaderLogo from "../../../assets/images/GL-Logo.svg";
import HeaderStyles from "./Header.styles";
import HamburgerNav from "./Drawer";
import { LABELS } from "../../../constants/labelConstants";
import { PATHS } from "../../../constants/pathConstants";
//Redux
import { Link } from "@mui/material";
import Translate from "../../../translate/Translate";
import { useLogout } from "../../../hooks/useLogout";
import { useAuth0 } from "@auth0/auth0-react";
import { validateToken } from "../../../utils/utils";
import { getCookie } from "../../../utils/CookieUtil";

function Header() {
  const classes = HeaderStyles();
  const logoutFunction = useLogout();
  const theme = useTheme();
  const screenWidthIsMdOrSmaller = useMediaQuery(theme.breakpoints.down("lg"));
  const screenWidthIsLgOrSmaller = useMediaQuery(theme.breakpoints.down("xl"));
  const { loginWithRedirect } = useAuth0();

  const headerItems = [
    { id: "dashboard", name: "Dashboard", route: PATHS.LANDING_PAGE },
    {
      id: "HelpCenterHeader",
      name: "Support & Resources",
      route: "/content/help-center"
    },
    {
      id: "account",
      name: "Account",
      route: PATHS.ACCOUNT_MANAGEMENT
    }
    // {
    //   id: "cancerResourceCenterHeader",
    //   name: "Health Resources",
    //   route: "/content/cancer-resource-library"
    // }
  ];

  const headerItemSpacing = screenWidthIsMdOrSmaller
    ? 1.9
    : screenWidthIsLgOrSmaller
    ? 5
    : 6;

  const sessionIsValid = validateToken(getCookie("accessToken"));

  const loginOrLogoutLink = sessionIsValid ? (
    <a
      id="logOut"
      name="Log out"
      href="#"
      className={classes.logoutButton}
      onClick={logoutFunction}
    >
      Log out
    </a>
  ) : (
    <a
      id="logOut"
      name="Log out"
      href="#"
      className={classes.logoutButton}
      onClick={loginWithRedirect}
    >
      Log in
    </a>
  );

  const renderedNavMenu = screenWidthIsMdOrSmaller ? (
    <Grid container justifyContent={"right"}>
      <Grid item xs={1} md={1}>
        <HamburgerNav />
      </Grid>
    </Grid>
  ) : (
    <Grid container justifyContent={"right"}>
      <Grid item xs={12}>
        <Grid
          container
          alignItems="center"
          spacing={headerItemSpacing}
          className={classes.menu}
        >
          {headerItems.map((item) => (
            <Grid item key={item.id}>
              <Typography>
                {item.webPage ? (
                  <Link
                    href={item.route}
                    target="_blank"
                    rel="noreferrer"
                    name={item.name}
                    id={item.id}
                    className={classes.links}
                    underline="hover"
                  >
                    <Translate text={item.name} />
                  </Link>
                ) : (
                  <NavLink
                    exact={item.name === "Dashboard" ? true : false}
                    activeClassName={classes.activeLink}
                    to={item.route}
                    name={item.name}
                    id={item.id}
                    className={classes.links}
                    onClick={item.onClick && item.onClick}
                  >
                    <Translate text={item.name} />
                  </NavLink>
                )}
              </Typography>
            </Grid>
          ))}
          <Grid item>{loginOrLogoutLink}</Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  return (
    <AppBar position="static" elevation={1} color="transparent">
      <Toolbar variant="regular">
        <Hidden lgDown>
          <Grid item lg={1} xl={2}></Grid>
        </Hidden>
        <Grid
          item
          xs={11}
          sm={11}
          md={2}
          lg={2}
          xl={2}
          className={classes.headerLogoContainer}
        >
          <NavLink to={PATHS.LANDING_PAGE}>
            <img
              src={HeaderLogo}
              className={classes.headerLogo}
              alt={`${LABELS.LABEL_CANCER_GUARDIAN_LOGO_ALT_TEXT}`}
            />
          </NavLink>
        </Grid>
        {renderedNavMenu}
      </Toolbar>
    </AppBar>
  );
}

export default Header;
