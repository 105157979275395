import React from "react";
import InactiveMembershipStyles from "./InactiveMembership.styles";
import { Grid, Typography } from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import Translate from "../../../translate/Translate";

const InactiveMembership = (props) => {
  const classes = InactiveMembershipStyles();

  return (
    <div className={classes.main}>
      <Grid container className={classes.container}>
        <Grid item xs={2} sm={1} md={1}>
          <NotificationsIcon className={classes.icon} />
        </Grid>
        <Grid item xs={10} sm={11} md={11}>
          <Grid item xs={12}>
            <Typography
              variant="deprecated_subtitle2"
              className={classes.title}
            >
              <Translate text="Your Genomic Life membership is inactive." />
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="deprecated_subtitle2" className={classes.text}>
              <Translate text="You are unable to order a kit or report a cancer diagnosis to obtain oncology support." />{" "}
              <Translate text="Please contact us at" />{" "}
              <a
                className={classes.link}
                href="mailto:navigation@genomiclife.com"
              >
                navigation@genomiclife.com{" "}
              </a>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default InactiveMembership;
