import { makeStyles } from "@mui/styles";

const TransmitConfirmationStepStyles = makeStyles((theme) => ({
  connectionContainer: {
    marginTop: theme.paddings.xxl,
    marginBottom: theme.paddings.md
  },
  requestConfirmationTitle: {
    ...theme.typography.deprecated_h5HeadlineBlack,
    color: theme.palette.primary.font_blue,
    marginBottom: 20,
    marginTop: 25
  },
  contactInformationCard: {
    marginTop: theme.paddings.sm
  },
  checkboxContainer: {
    marginTop: theme.paddings.sm
  }
}));

export default TransmitConfirmationStepStyles;
