import { Box, Checkbox, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import GLButton from "components/v3/sharedComponents/GLButton";
import { COLORS } from "components/v3/Theme/colors";
import { useDispatch, useSelector } from "react-redux";
import {
  selectUserAgreementData,
  setUserAgreementVersionAcceptanceStates
} from "store/reducers/termsOfService/TermsOfServiceSlice";
import {
  getUserAgreementVersionAcceptanceStates,
  postUserAgreement
} from "apis/termsAndConditionsApi";
import Mixpanel from "utils/mixpanel";
import { openGLSnackbar } from "store/reducers/snackbar/openGLSnackbarSlice";

interface TermsSheetProps {
  onClickContinue: () => void;
  termsDocumentName: string;
  termsDocumentKey: string;
}

const TermsSheet: React.FC<TermsSheetProps> = ({
  onClickContinue,
  termsDocumentName,
  termsDocumentKey
}) => {
  const userAgreementData = useSelector(selectUserAgreementData);
  const contentRef = useRef<HTMLDivElement>(null);
  const [agreed, setAgreed] = useState(false);
  const [buttonSpinning, setButtonSpinning] = useState(false);
  const dispatch = useDispatch();

  const usersFirstTimeAgreeingToAnyVersionOfThisDoc =
    //@ts-ignore -- will need to catch agreements up with ts, not worth it now.
    userAgreementData[termsDocumentKey]?.acceptedVersion === "0.0.0";

  useEffect(() => {
    setAgreed(false);
    window.scrollTo({ top: 0, behavior: "smooth" });

    if (contentRef.current) {
      contentRef.current.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [termsDocumentName]);

  const handleAgreeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAgreed(event.target.checked);
  };

  const handleSubmit = () => {
    setButtonSpinning(true);

    // *sigh* - Back end wants different keys in the post payload than it sends in the
    //  documents themselves. Will trash when we update this step.
    const termsDocumentKeyPostOptionMap: Record<string, string> = {
      hipaaAuthorization: "HIPAA",
      privacyPolicy: "privacyPolicy",
      termsAndConditions: "termsConditions"
    };
    const postOptionKey = termsDocumentKeyPostOptionMap[termsDocumentKey];

    postUserAgreement({
      option: postOptionKey,
      //@ts-ignore -- will need to catch agreements up with ts, not worth it now.
      version: userAgreementData[termsDocumentKey]?.latestVersion
    })
      .then(() => {
        getUserAgreementVersionAcceptanceStates()
          .then((newAgreementStates) => {
            dispatch(
              //@ts-ignore
              setUserAgreementVersionAcceptanceStates(newAgreementStates)
            );
            const changeString = usersFirstTimeAgreeingToAnyVersionOfThisDoc
              ? "agreed"
              : "updated agreement";
            Mixpanel.track(
              //@ts-ignore -- will need to catch agreements up with ts, not worth it now.
              `User ${changeString} to version ${userAgreementData[termsDocumentKey]?.latestVersion} of ${termsDocumentKey}`
            );
            setButtonSpinning(false);
            onClickContinue();
          })
          .catch((error) => {
            Mixpanel.track(
              `Getting updated user agreement failed - ${termsDocumentKey}`,
              { error: error }
            );
            dispatch(
              openGLSnackbar({
                variant: "error",
                header: "Error storing agreement",
                subText:
                  "Sorry, something went wrong when we tried to store your agreement. Please try again and reach out if you are having trouble!."
              })
            );
            setButtonSpinning(false);
          });
      })
      .catch((error) => {
        Mixpanel.track(
          `Posting new user agreement failed - ${termsDocumentKey}`,
          {
            error: error
          }
        );
        dispatch(
          openGLSnackbar({
            variant: "error",
            header: "Error storing agreement",
            subText:
              "Sorry, something went wrong when we tried to store your agreement. Please try again and reach out if you are having trouble!."
          })
        );
        setButtonSpinning(false);
      });
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        width: "100%",
        padding: { xs: "60px 20px", sm: "60px" },
        boxSizing: "border-box",
        backgroundColor: COLORS.WHITE,
        borderRadius: "30px"
      }}
    >
      <Typography
        variant="h5_serif"
        sx={{
          background: `linear-gradient(to right, ${COLORS.ACCENT3} 0%, ${COLORS.PRIMARY} 300%)`,
          WebkitBackgroundClip: "text",
          WebkitTextFillColor: "transparent"
        }}
        mb={2}
      >
        Terms of Use
      </Typography>

      <Typography variant="h4" fontWeight="bold" mb={2}>
        {termsDocumentName}
      </Typography>

      <Box
        sx={{
          width: "100%",
          padding: "16px",
          flexGrow: 1,
          marginBottom: "20px",
          textAlign: "left",
          borderRadius: "8px",
          border: `1px solid ${COLORS.GRAY}`,
          maxHeight: "100vh",
          boxSizing: "border-box",
          display: "flex",
          flexDirection: "column"
        }}
      >
        <Box
          sx={{
            flexGrow: 1,
            overflowY: "auto",
            padding: "16px"
          }}
          ref={contentRef}
        >
          <div
            dangerouslySetInnerHTML={{
              //@ts-ignore -- will need to catch agreements up with ts, not worth it now.
              __html: userAgreementData[termsDocumentKey]?.agreementHtml || ""
            }}
          />
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
          mb: 2,
          flexDirection: { xs: "column", sm: "row" },
          gap: 2
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center"
          }}
        >
          <Checkbox
            id="agree-checkbox"
            checked={agreed}
            onChange={handleAgreeChange}
            inputProps={{
              "aria-label": "agree to terms"
            }}
          />
          <Typography>I agree to the {termsDocumentName}</Typography>
        </Box>
        <GLButton
          onClick={handleSubmit}
          disabled={!agreed}
          loading={buttonSpinning}
          text={"Continue"}
        />
      </Box>
    </Box>
  );
};

export default TermsSheet;
