import React, { useState, Fragment, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Grid, Step, StepLabel, Stepper, Typography } from "@mui/material";
import CustomizedMobileStepper from "../../../sharedComponents/CustomizedMobileStepper";
import CenteredContent from "../../../sharedComponents/CenteredContent";
import Translate from "../../../../translate/Translate";
import Breadcrumb from "../../../sharedComponents/Breadcrumb";
import { useWindowSize } from "../../../../hooks/useWindowSize";
import { QontoConnector, StepperWrapperStyles } from "./StepperWrapper.styles";

const StepperWrapper = (props) => {
  const {
    stepsArray,
    currentStepIndex,
    setCurrentStepIndexHandler,
    stepToRedirectIndex,
    nextCallbackHandler,
    backCallbackHandler,
    isNextStepDisabledHandler,
    lastAvailableStep,
    hideUpperStepper,
    hideLowerStepper,
    isUpperStepperNonClickable,
    breadcrumbText,
    breadcrumbRoute
  } = props;
  const classes = StepperWrapperStyles();
  const screenDimentions = useWindowSize();
  const usableHeight = screenDimentions.height;

  const [divHeight, setDivHeight] = useState(0);
  const ref = useRef(null);
  const steps = stepsArray;

  const [redirectToStep, setRedirectToStep] = useState(stepToRedirectIndex);

  useEffect(() => {
    setDivHeight(ref.current.clientHeight);
  }, [currentStepIndex, screenDimentions, props.children]);

  useEffect(() => {
    setRedirectToStep(stepToRedirectIndex);
  }, [stepToRedirectIndex, currentStepIndex]);

  const isStickyFooterStuckAtBottom = () => {
    const HEIGHT_OF_HEADER_AND_FOOTER_WITH_LOWER_PADDING = 245;
    return (
      divHeight < usableHeight - HEIGHT_OF_HEADER_AND_FOOTER_WITH_LOWER_PADDING
    );
  };

  const isNextStepDisabled = (currentStepIndexToTest) => {
    return currentStepIndexToTest > lastAvailableStep;
  };

  const nextDissabled = isNextStepDisabledHandler
    ? isNextStepDisabledHandler(currentStepIndex)
    : false;

  const handleNext = () => {
    window.scrollTo(0, 0);
    const nextStep = currentStepIndex + 1;
    if (redirectToStep) {
      if (setCurrentStepIndexHandler) {
        setCurrentStepIndexHandler(redirectToStep);
      }
      return setRedirectToStep(null);
    }
    if (currentStepIndex < steps.length - 1) {
      if (setCurrentStepIndexHandler) {
        setCurrentStepIndexHandler(nextStep);
      }
    }
    if (nextCallbackHandler && currentStepIndex < steps.length) {
      nextCallbackHandler(nextStep);
    }
  };

  const handleBack = () => {
    window.scrollTo(0, 0);
    if (currentStepIndex !== 0) {
      const prevStep = currentStepIndex - 1;
      if (setCurrentStepIndexHandler) {
        setCurrentStepIndexHandler(prevStep);
      }
      if (backCallbackHandler) {
        backCallbackHandler(prevStep);
      }
    }
  };

  const handleStepperAction = (step) => {
    if (typeof step === "number" && step < steps.length) {
      window.scrollTo(0, 0);
      if (setCurrentStepIndexHandler) {
        setCurrentStepIndexHandler(step);
      }
    }
  };

  const upperStepperValidation = (currentIndex) => {
    if (isUpperStepperNonClickable) {
      return false;
    }
    if (isNextStepDisabled) {
      return (
        currentStepIndex >= currentIndex || !isNextStepDisabled(currentIndex)
      );
    }
  };

  const upperStepperLabelStyleHandler = (currentIndex) => {
    if (isUpperStepperNonClickable) {
      return classes.rootClassDefault;
    }
    if (
      isNextStepDisabled &&
      (currentStepIndex >= currentIndex || !isNextStepDisabled(currentIndex))
    ) {
      return classes.rootClassPointer;
    }
    return classes.rootClass;
  };

  const upperStepperRenderer = () => {
    if (!hideUpperStepper) {
      return (
        <CenteredContent className={classes.stepperCentercontainer}>
          <Stepper
            alternativeLabel
            activeStep={currentStepIndex}
            connector={<QontoConnector />}
            className={classes.stepper}
          >
            {steps.map((label, index) => (
              <Step
                className={
                  upperStepperValidation(index) ? classes.stepPointer : null
                }
                onClick={
                  upperStepperValidation(index)
                    ? () => handleStepperAction(index)
                    : null
                }
                key={`${index}-${label.slice(0, 2)}`}
              >
                <StepLabel
                  classes={{
                    root: upperStepperLabelStyleHandler(index),
                    completed: classes.done,
                    active: classes.active
                  }}
                >
                  <Typography
                    variant="deprecated_subtitle2"
                    className={classes.label}
                  >
                    <Translate text={label} />
                  </Typography>
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </CenteredContent>
      );
    }
    return null;
  };
  const lowerStepperRenderer = () => {
    if (!hideLowerStepper) {
      return (
        <Grid
          container
          alignItems="flex-end"
          justifyContent="center"
          className={
            isStickyFooterStuckAtBottom() ? classes.buttonSection : null
          }
        >
          <CustomizedMobileStepper
            variant="text"
            position="inherit"
            currentStep={currentStepIndex}
            activeStep={currentStepIndex}
            handleNext={handleNext}
            finalText={"Send my report(s)"}
            handlePrevious={handleBack}
            contentSteps={steps}
            isNextDisabled={nextDissabled}
          />
        </Grid>
      );
    }
    return null;
  };

  return (
    <Fragment>
      <Grid item xs={12}>
        <Grid container className={classes.boxStepper}>
          {breadcrumbText && breadcrumbRoute ? (
            <Breadcrumb text={breadcrumbText} redirectTo={breadcrumbRoute} />
          ) : null}
          <Grid container justifyContent="center" alignItems="center">
            <Grid item xs={12}>
              {upperStepperRenderer()}
            </Grid>
          </Grid>
        </Grid>

        <Grid ref={ref} container justifyContent="center">
          <Grid item xs={12}>
            {props.children}
          </Grid>
        </Grid>
        {lowerStepperRenderer()}
      </Grid>
    </Fragment>
  );
};
export default StepperWrapper;

StepperWrapper.propTypes = {
  stepsArray: PropTypes.arrayOf(PropTypes.any).isRequired,
  currentStepIndex: PropTypes.number.isRequired,
  setCurrentStepIndexHandler: PropTypes.func.isRequired,
  stepToRedirectIndex: PropTypes.number,
  nextCallbackHandler: PropTypes.func,
  backCallbackHandler: PropTypes.func,
  isNextStepDisabledHandler: PropTypes.func,
  lastAvailableStep: PropTypes.number,
  hideUpperStepper: PropTypes.bool,
  isUpperStepperNonClickable: PropTypes.bool,
  hideLowerStepper: PropTypes.bool,
  breadcrumbText: PropTypes.string,
  breadcrumbRoute: PropTypes.string
};
