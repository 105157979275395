import React, { useState } from "react";
import { Grid, Typography, Checkbox } from "@mui/material";
import { useWindowSize } from "../../../../hooks/useWindowSize";
import UserAgreementDetailStyles from "./UserAgreementDetail.styles";
import { useDispatch, useSelector } from "react-redux";
import {
  selectUserAgreementData,
  setUserAgreementVersionAcceptanceStates
} from "../../../../store/reducers/termsOfService/TermsOfServiceSlice";
import Translate from "../../../../translate/Translate";
import {
  getUserAgreementVersionAcceptanceStates,
  postUserAgreement
} from "../../../../apis/termsAndConditionsApi";
import Mixpanel from "../../../../utils/mixpanel";
import { openGLSnackbar } from "../../../../store/reducers/snackbar/openGLSnackbarSlice";
import GLButton from "../../../v2/sharedComponents/GLButton";

const UserAgreementDetail = (props) => {
  const { agreementKey, onSave } = props;
  const classes = UserAgreementDetailStyles();
  const windowSize = useWindowSize();
  const dispatch = useDispatch();

  const agreements = useSelector(selectUserAgreementData);
  const [agreementCheckboxChecked, setAgreementCheckboxChecked] = useState(
    agreements[agreementKey].acceptedVersion ===
      agreements[agreementKey].latestVersion
  );

  // Back end returns 0.0.0 when there is no record :/
  const usersFirstTimeAgreeingToAnyVersionOfThisDoc =
    agreements[agreementKey].acceptedVersion === "0.0.0";

  const handleSubmit = () => {
    // *sigh* - Back end wants different keys in the post payload than it sends in the
    //  docuemnts themselves.
    const postOptionKey =
      agreementKey === "privacyPolicy"
        ? "privacyPolicy"
        : agreementKey === "termsAndConditions"
        ? "termsConditions"
        : agreementKey === "hipaaAuthorization"
        ? "HIPAA"
        : null;
    postUserAgreement({
      option: postOptionKey,
      version: agreements[agreementKey].latestVersion
    })
      .then(() => {
        getUserAgreementVersionAcceptanceStates()
          .then((newAgreementStates) => {
            dispatch(
              setUserAgreementVersionAcceptanceStates(newAgreementStates)
            );
            const changeString = usersFirstTimeAgreeingToAnyVersionOfThisDoc
              ? "agreed"
              : "updated agreement";
            Mixpanel.track(
              `User ${changeString} to version ${agreements[agreementKey].latestVersion} of ${agreementKey}`
            );
            onSave();
          })
          .catch((error) => {
            Mixpanel.track(
              `Getting updated user agreement failed - ${agreementKey}`,
              { error: error }
            );
            dispatch(
              openGLSnackbar({
                variant: "error",
                header: "Error storing agreement",
                subText:
                  "Sorry, something went wrong when we tried to store your agreement. Please try again and reach out if you are having trouble!."
              })
            );
            onSave();
          });
      })
      .catch((error) => {
        Mixpanel.track(`Posting new user agreement failed - ${agreementKey}`, {
          error: error
        });
        dispatch(
          openGLSnackbar({
            variant: "error",
            header: "Error storing agreement",
            subText:
              "Sorry, something went wrong when we tried to store your agreement. Please try again and reach out if you are having trouble!."
          })
        );
        onSave();
      });
  };

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      <Typography
        className={classes.lastUpdated}
        variant="deprecated_body1"
      >{`Version: ${agreements[agreementKey].latestVersion}`}</Typography>
      <Grid item xs={10} md={8} style={{ maxWidth: "100%" }}>
        <div
          id="content"
          className={`${classes.fileContent} ${classes.fileContentIntegrated}`}
          style={{
            height: windowSize.height * 0.65,
            minWidth: windowSize.width * 0.65,
            overflowY: "auto"
          }}
        >
          <div className={classes.termsContainer}>
            <div
              dangerouslySetInnerHTML={{
                __html: agreements[agreementKey].agreementHtml
              }}
            />
          </div>
        </div>
      </Grid>

      <Grid
        container
        item
        alignItems="center"
        xs={11}
        md={8}
        wrap="nowrap"
        mt={3}
      >
        <Grid item>
          <Checkbox
            color="secondary"
            required
            id={"agreementCheckbox"}
            onChange={() => {
              setAgreementCheckboxChecked(!agreementCheckboxChecked);
            }}
            checked={agreementCheckboxChecked}
          />
        </Grid>
        <Grid item>
          <Typography
            className={classes.checkboxText}
            variant={"deprecated_body1"}
            gutterBottom
          >
            <Translate text="I have read and agree to the the terms and conditions listed above." />
          </Typography>
        </Grid>
      </Grid>
      <Grid item>
        <GLButton
          loadingSpinnerWhenClicked
          disabled={!agreementCheckboxChecked}
          id="submitButton"
          onClick={handleSubmit}
          variant="contained"
          color={"secondary"}
        >
          <Translate text="AGREE" />
        </GLButton>
      </Grid>
    </Grid>
  );
};
export default UserAgreementDetail;
