import React from "react";

import HeaderLogo from "../../../../../assets/images/LogoIconDefault.svg";
import { Grid, Typography, Box } from "@mui/material";
import NoButtonsInfoDialog from "components/v3/sharedComponents/modals/NoButtonsInfoDialog";
import { COLORS } from "components/v3/Theme/colors";

interface RegistrationHelpModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const HelpModal: React.FC<RegistrationHelpModalProps> = ({
  isOpen,
  onClose
}) => {
  return (
    <NoButtonsInfoDialog isOpen={isOpen} onClose={onClose}>
      <Grid item xs={12}>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          sx={{
            maxWidth: "450px",
            textAlign: "center",
            "& p": { marginBottom: "15px" },
            "& h4": { marginBottom: "20px" }
          }}
        >
          <Grid
            item
            xs={12}
            sx={{
              textAlign: "center",
              marginTop: "-30px",
              marginBottom: "30px"
            }}
          >
            <img src={HeaderLogo} alt="Logo" style={{ maxWidth: "100%" }} />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h4_primary">Need help? We're here!</Typography>
          </Grid>
          <Grid item xs={9}>
            <Typography>
              Having trouble with your code or don’t have one? No worries!
              Please reach out and we’ll be more than happy to assist you.
            </Typography>
          </Grid>
          <Grid
            item
            xs={9}
            sx={{
              border: `2px solid ${COLORS.SUCCESS}`,
              borderRadius: "15px",
              padding: "15px",
              width: "100%",
              marginBottom: "15px",
              "& p": {
                marginTop: "5px",
                marginBottom: "0px"
              }
            }}
          >
            <Box
              component="a"
              href="mailto:navigation@genomiclife.com"
              sx={{
                color: "black",
                textDecoration: "none",
                cursor: "pointer",
                fontWeight: "700"
              }}
            >
              navigation@genomiclife.com
            </Box>
          </Grid>
          <Grid item xs={9}>
            <Typography sx={{ fontStyle: "italic" }}>
              We may ask the same information you just gave us, this is to
              ensure your privacy and security.
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </NoButtonsInfoDialog>
  );
};

export default HelpModal;
