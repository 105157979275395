import React from "react";
import {
  Tooltip,
  tooltipClasses,
  styled,
  TooltipProps,
  Box
} from "@mui/material";
import { COLORS } from "../../Theme/colors";

const GLTooltipStyled = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: COLORS.BLACK,
    color: COLORS.WHITE,
    maxWidth: 250,
    fontSize: theme.typography.pxToRem(14),
    padding: "10px 15px",
    borderRadius: "8px"
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: COLORS.BLACK
  }
}));

const GLTooltip: React.FC<TooltipProps> = ({ children, title }) => {
  return (
    <GLTooltipStyled title={title} placement="right" arrow>
      <Box component="span">{children}</Box>
    </GLTooltipStyled>
  );
};

export default GLTooltip;
