import React from "react";
import Translate from "../../../../translate/Translate";
import { Grid, Typography } from "@mui/material";
import WorkflowConclusionTemplateStyles from "./WorkflowConclusionTemplate.styles";
import PropTypes from "prop-types";

const WorkflowConclusionTemplate = (props) => {
  const { headerImage, headerImageAlt, mainHeaderText } = props;
  const classes = WorkflowConclusionTemplateStyles();

  const renderHeadingImage = () => {
    if (headerImage) {
      return (
        <div className={classes.headerImage}>
          <img
            id="headerImage"
            src={headerImage}
            height={105}
            alt={headerImageAlt}
          />
        </div>
      );
    }
    return null;
  };

  return (
    <Grid item xs={12}>
      <Grid container justifyContent="center" alignItems="center">
        {renderHeadingImage()}
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          className={classes.titleSection}
        >
          <Typography
            id="mainHeader"
            variant="deprecated_h4"
            className={classes.title}
          >
            <Translate text={mainHeaderText} />
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        className={classes.mainContainer}
        justifyContent="center"
        alignItems="center"
      >
        {props.children}
      </Grid>
    </Grid>
  );
};

export default WorkflowConclusionTemplate;

WorkflowConclusionTemplate.propTypes = {
  headerImage: PropTypes.string.isRequired,
  headerImageAlt: PropTypes.string,
  mainHeaderText: PropTypes.string.isRequired
};
