import { Typography } from "@mui/material";
import { KeyboardArrowLeft } from "@mui/icons-material";
import BackToStyles from "./BackTo.styles";
import React from "react";
import { Link } from "react-router-dom";
import CenteredContent from "../CenteredContent";
import Translate from "../../../translate/Translate";

const BackTo = (props) => {
  const { text, backTo } = props;
  const classes = BackToStyles();
  return (
    <CenteredContent>
      <Link to={backTo} className={classes.linkContainer}>
        <KeyboardArrowLeft className={classes.backToIcon} />{" "}
        <Typography variant="deprecated_body1" className={classes.backToText}>
          <Translate text={text} />
        </Typography>
      </Link>
    </CenteredContent>
  );
};

export default BackTo;
