import { doApiCall } from "./util";
import { encryptPassword } from "../utils/utils";
import axios from "axios";
import { getSpouseEligibility } from "./spouseApi";
import { getExistingKitOrders } from "./ordersApi";
import { getSurveyJson } from "./surveyApi";
import { getMemberData } from "./membersApi";
import {
  getUserAgreementHtml,
  getUserAgreementVersionAcceptanceStates
} from "./termsAndConditionsApi";
import { getCancerDiagnosis } from "./diagnosisApi";
const {
  REACT_APP_MEMBER_PORTAL_ENDPOINT_URL,
  REACT_APP_REGISTRATION_API_URL
} = process.env;

/**
 *
 * @param {*} username
 * @param {*} password
 * @returns auth or not
 * @description This endpoint call the endpoint to verify if this user
 * and password are correct and belongs to the plattform
 */
export const authenticate = (username, password) => {
  const payload = {
    username: encryptPassword(username),
    password: encryptPassword(password)
  };

  const request = {
    host: `${REACT_APP_MEMBER_PORTAL_ENDPOINT_URL}`,
    method: "POST",
    url: `https://${REACT_APP_MEMBER_PORTAL_ENDPOINT_URL}/login`,
    data: payload,
    body: JSON.stringify(payload),
    headers: {
      "content-type": "application/json"
    }
  };

  try {
    return axios(request);
  } catch {
    console.error("error authenticating");
  }
};

/**
 *
 * @param {*} email
 * @returns true or false
 * @description This verify if this is or not a valid member email
 */
export const verifyEmail = (email) => {
  const payload = {
    email: email
  };
  const request = {
    host: `${REACT_APP_MEMBER_PORTAL_ENDPOINT_URL}`,
    method: "POST",
    url: `https://${REACT_APP_MEMBER_PORTAL_ENDPOINT_URL}/password-reset`,
    data: payload,
    body: JSON.stringify(payload)
  };

  return doApiCall(request);
};

export const testAccessCode = (accessCode, userName, auth0UserId) => {
  const payload = {
    accessCode: accessCode,
    userName: userName,
    userId: auth0UserId
  };
  const request = {
    host: `${REACT_APP_REGISTRATION_API_URL}`,
    method: "POST",
    url: `https://${REACT_APP_REGISTRATION_API_URL}/registration`,
    data: payload,
    body: JSON.stringify(payload)
  };

  return doApiCall(request);
};

/**
 *
 * @param {*} email
 * @returns
 * @decription this returns a new email into the user inbox to reset their password
 */
export const forgotPassword = (email) => {
  const payload = {
    email: email
  };

  const request = {
    host: `${REACT_APP_MEMBER_PORTAL_ENDPOINT_URL}`,
    method: "POST",
    url: `https://${REACT_APP_MEMBER_PORTAL_ENDPOINT_URL}/password-reset`,
    data: payload,
    body: JSON.stringify(payload)
  };

  return doApiCall(request);
};

/**
 *
 * @param {*} payload
 * @returns
 */
export const validateAccount = (payload) => {
  const request = {
    host: `${REACT_APP_MEMBER_PORTAL_ENDPOINT_URL}`,
    method: "POST",
    url: `https://${REACT_APP_MEMBER_PORTAL_ENDPOINT_URL}/validate-account`,
    data: payload,
    body: JSON.stringify(payload)
  };

  return doApiCall(request);
};

/**
 * This endpoint resets the password for an user
 * @param {*} currentPassword  the password thats going to be changed
 * @param {*} newPassword the password that will be changed to
 * @returns {object} the response promise
 */
export const updatePassword = (currentPassword, newPassword) => {
  const payload = {
    password: encryptPassword(newPassword),
    current: encryptPassword(currentPassword)
  };
  const request = {
    host: `${REACT_APP_MEMBER_PORTAL_ENDPOINT_URL}`,
    method: "POST",
    url: `https://${REACT_APP_MEMBER_PORTAL_ENDPOINT_URL}/member/password`,
    data: payload,
    body: JSON.stringify(payload)
  };

  return doApiCall(request);
};

/**
 * @description Get all the data in a parallel when logging in
 */
export const doGreatInitialApiScrape = () => {
  return axios
    .all([
      getMemberData(),
      getUserAgreementVersionAcceptanceStates(),
      getUserAgreementHtml(),
      getSpouseEligibility(),
      getExistingKitOrders(),
      getCancerDiagnosis(),
      getSurveyJson("getting-started-survey-with-preferred-contact"),
      getSurveyJson("all-questions-flat-survey")
    ])
    .then(
      axios.spread(
        (
          memberDataResponse,
          userAgreementVersionAcceptanceReponse,
          userAgreementHtmlReponse,
          spouseResponse,
          existingKitOrdersResponse,
          cancerDiagnosisResponse,
          gettingStartedSurveyResponse,
          allQuestionsFlatSurveyResponse
        ) => {
          return [
            memberDataResponse,
            userAgreementVersionAcceptanceReponse,
            userAgreementHtmlReponse,
            spouseResponse,
            existingKitOrdersResponse,
            cancerDiagnosisResponse,
            gettingStartedSurveyResponse,
            allQuestionsFlatSurveyResponse
          ];
        }
      )
    );
};
