import Translate from "../../../translate/Translate";
import { Box, Button, Grid, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { PATHS } from "../../../constants/pathConstants";
import logo from "../../../assets/images/GL-Logo.svg";
import RequestPasswordResetEmailFailureStyles from "./RequestPasswordResetEmailFailurePage.styles";
import PageTitle from "../../../utils/PageTitle";

const RequestPasswordResetEmailFailurePage = () => {
  const classes = RequestPasswordResetEmailFailureStyles();

  PageTitle("Forgot Password Failure");

  return (
    <Grid container>
      <Grid item xs={12}>
        <Box display="flex" justifyContent="center" alignItems="center">
          <img src={logo} alt="Genomic Life" className={classes.img} />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box display="flex" justifyContent="center" alignItems="center">
          <Grid item sm={7} md={5}>
            <Typography
              variant="deprecated_h5"
              component="h5"
              className={classes.message}
              id="emailSpelledCorrectly"
            >
              <Translate
                text="Sorry about that! Please double check that your email is spelled
              correctly or register a new account with Genomic Life."
              />
            </Typography>
          </Grid>
        </Box>
        <Box display="flex" justifyContent="center" alignItems="center">
          <Typography
            variant="deprecated_h5"
            component="h5"
            className={classes.assistance}
            id="forAssistance"
          >
            <Translate text="For assistance, contact" />
          </Typography>
        </Box>
        <Box display="flex" justifyContent="center" alignItems="center">
          <Typography
            variant="deprecated_h5"
            component="h5"
            className={classes.phone}
          >
            844-MYGENOME
          </Typography>
        </Box>
        <Box display="flex" justifyContent="center" alignItems="center">
          <Typography
            variant="deprecated_h5"
            component="h5"
            className={classes.number}
          >
            (844) 649-3666
          </Typography>
        </Box>
        <Box display="flex" justifyContent="center" alignItems="center">
          <Button
            variant="contained"
            disableElevation
            size="large"
            className={classes.RoundedButton}
          >
            <Link
              name="try-again"
              to={PATHS.REQUEST_PASSWORD_EMAIL}
              className={classes.LinkToFG}
              id="tryAgainLink"
            >
              <Translate text="Try again" />
            </Link>
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

export default RequestPasswordResetEmailFailurePage;
