import { Box, Typography } from "@mui/material";
import { COLORS } from "components/v3/Theme/colors";
import Video from "components/v3/sharedComponents/Video";

const VideoSection = () => {
  return (
    <Box
      sx={{
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "column",
        gap: {
          xs: "15px",
          md: "30px"
        },
        paddingX: {
          xs: "20px",
          md: "60px"
        },
        paddingY: {
          xs: "30px",
          md: 0
        },
        position: "relative",
        width: "100%"
      }}
    >
      <Box
        sx={{
          display: "flex",
          flex: 1,
          flexDirection: "column",
          gap: "25px"
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "15px"
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "5px"
            }}
          >
            <Typography
              sx={{
                background: `linear-gradient(to right, ${COLORS.ACCENT3}, ${COLORS.PRIMARY})`,
                typography: {
                  xs: "body_xs_serif",
                  md: "h6_serif"
                },
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent"
              }}
            >
              You are one of a kind
            </Typography>
            <Typography
              id="being_proactive"
              sx={{ typography: { xs: "h5_black", md: "h4_black" } }}
            >
              Being proactive about your health
            </Typography>
          </Box>
          <Typography variant="body">
            Your journey to understanding your health starts here. Being
            proactive informs steps that help early disease detection,
            prevention, and tailored action plans.
          </Typography>
          <Typography variant="body">
            Our goal is to help protect your health by providing resources for
            you to make confident, informed decisions.
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flex: 1,
          paddingY: "25px",
          width: "100%"
        }}
      >
        <Box
          sx={{
            borderRadius: "16px",
            boxShadow:
              "0px 10px 15px -3px rgba(0, 0, 0, 0.10), 0px 4px 6px -2px rgba(0, 0, 0, 0.05)",
            overflow: "hidden",
            width: "100%",
            zIndex: 9
          }}
        >
          <Video
            height="100%"
            style={{
              aspectRatio: "16/9",
              height: "100%",
              maxWidth: "calc(100vw - 80px)",
              width: "100%"
            }}
            width="100%"
            url="https://vimeo.com/1024099357"
          />
        </Box>
      </Box>
    </Box>
  );
};

export default VideoSection;
