import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import optionComponentStyles from "./OptionComponent.styles";

const OptionComponent = (props) => {
  const { isChecked, onClick, answer, icon, isSurveyQuestion } = props;
  const classes = optionComponentStyles();

  return (
    <Grid
      id={answer}
      xs={isSurveyQuestion ? 11 : 12}
      container
      justifyContent="center"
      alignItems="center"
      onClick={onClick}
      className={`${
        !isChecked ? classes.notCheckedButton : classes.checkedButton
      }`}
    >
      <Grid item xs={2}>
        <Box className={classes.iconContainer}>{icon}</Box>
      </Grid>
      <Grid item xs={10} className={classes.optionContainer}>
        <Grid item xs={12}>
          <Typography
            variant="deprecated_subtitle1"
            className={`${classes.textAnswer}`}
          >
            {answer}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default OptionComponent;
