import React from "react";
import { useTranslation } from "react-i18next";

interface TranslateProps {
  text: string;
}

export default function Translate(props: TranslateProps) {
  const { t } = useTranslation();

  return <span>{t(props.text)}</span>; // Wrap the string in a JSX element
}
