import { useCallback, useEffect, useState } from "react";
import { Box } from "@mui/material";
import { VimeoPlayerProps } from "react-player/vimeo";
import { COLORS } from "components/v3/Theme/colors";
import ModalWrapper from "components/v2/sharedComponents/Modals/ModalComponents/ModalWrapper";
import Video from "components/v3/sharedComponents/Video";

type VideoModalProps = {
  onCloseModal?: () => void;
  open?: boolean;
  url: string;
} & VimeoPlayerProps;

const VideoModal: React.FC<React.PropsWithChildren<VideoModalProps>> = ({
  onCloseModal,
  open = false,
  url,
  ...remainingProps
}) => {
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);
  const handleCloseVideoModal = useCallback(() => {
    onCloseModal?.();
    // setIsVideoModalOpen(false);
  }, []);

  useEffect(() => {
    setIsVideoModalOpen(open);
  }, [open]);

  return (
    <ModalWrapper
      isOpen={isVideoModalOpen}
      onClickOutsideModal={() => {
        handleCloseVideoModal();
      }}
      sx={{
        "& .MuiModal-backdrop": {
          backgroundColor: `${COLORS.PRIMARY_DARKER}80`
        }
      }}
    >
      <Box sx={{ minWidth: "50vw" }}>
        <Box
          sx={{
            borderRadius: "16px",
            boxShadow:
              "0px 10px 15px -3px rgba(0, 0, 0, 0.10), 0px 4px 6px -2px rgba(0, 0, 0, 0.05)",
            zIndex: 9
          }}
        >
          <Video
            style={{
              aspectRatio: "16/9",
              height: "100%",
              maxWidth: "calc(100vw - 80px)",
              width: "100%"
            }}
            height="auto"
            width="100%"
            url={url}
            {...remainingProps}
          />
        </Box>
      </Box>
    </ModalWrapper>
  );
};

export default VideoModal;
