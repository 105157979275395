import { makeStyles } from "@mui/styles";

const mustBeAdultModalStyles = makeStyles((theme) => ({
  modalContainer: {
    borderRadius: `10px`,
    padding: 25,
    [theme.breakpoints.down("md")]: {
      width: 345,
      height: 277
    },
    [theme.breakpoints.up("sm")]: {
      width: 512,
      height: 277
    },

    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5]
  },
  modalDescription: {
    ...theme.typography.deprecated_subtitleHeavy,
    [theme.breakpoints.down("md")]: {
      marginTop: 30
    },
    [theme.breakpoints.up("sm")]: {
      marginTop: 42
    }
  },
  backHomeButton: {
    [theme.breakpoints.down("md")]: {
      marginTop: 19
    },
    [theme.breakpoints.up("sm")]: {
      marginTop: 36
    }
  },
  closeIcon: {
    cursor: "pointer"
  }
}));

export default mustBeAdultModalStyles;
