import React from "react";
import Layout from "../../../layout/Layout";
import { Grid, Box, Typography } from "@mui/material";
import CMSDrivenPageTemplateStyles from "./CMSDrivenPageTemplate.styles";
import ImageAnchoredParagraph from "../CMSSharedComponents/ImageAnchoredParagraph";
import CardGrid from "../CMSSharedComponents/CardGrid";
import HeroCardGrid from "../CMSSharedComponents/HeroCardGrid";
import HeroHeader from "../CMSSharedComponents/HeroHeader";
import ImageWithFooterCard from "../CMSSharedComponents/ImageWithFooterCard";
import OptionWithIconCard from "../CMSSharedComponents/OptionWithIconCard";
import ResourceGrid from "../CMSSharedComponents/ResourceGrid";
import ResourceGridMatrix from "../CMSSharedComponents/ResourceGridMatrix";
import Article from "../CMSSharedComponents/Article";
import LinkBoxGrid from "../CMSSharedComponents/LinkBoxGrid";
import ContentResolver from "../CMSSharedComponents/ContentResolver";
import LinkChildren from "../CMSSharedComponents/LinkChildren";
import ContentHeading from "../CMSSharedComponents/ContentHeading";
import CmsBreadcrumb from "../CMSSharedComponents/CmsBreadcrumb";
import Questions from "../CMSSharedComponents/Questions";
import RelatedArticlesGrid from "../CMSSharedComponents/RelatedArticlesGrid";
import LinkArticles from "../CMSSharedComponents/LinkArticles";
import ArticleExternal from "../CMSSharedComponents/ArticleExternal";
import LinkTopicsAndArticles from "../CMSSharedComponents/LinkTopicsAndArticles";

const Components = {
  ImageAnchoredParagraph: ImageAnchoredParagraph,
  CardGrid: CardGrid,
  HeroCardGrid: HeroCardGrid,
  HeroHeader: HeroHeader,
  ImageWithFooterCard: ImageWithFooterCard,
  OptionWithIconCard: OptionWithIconCard,
  ResourceGrid: ResourceGrid,
  ResourceGridMatrix: ResourceGridMatrix,
  Article: Article,
  LinkBoxGrid: LinkBoxGrid,
  LinkChildren: LinkChildren,
  ContentHeading: ContentHeading,
  Questions: Questions,
  RelatedArticlesGrid: RelatedArticlesGrid,
  LinkArticles: LinkArticles,
  ArticleExternal: ArticleExternal,
  LinkTopicsAndArticles: LinkTopicsAndArticles
};

const CMSDrivenPageTemplate = (props) => {
  const { pages, page, gutters } = props;

  const classes = CMSDrivenPageTemplateStyles();

  return (
    <Layout headerType="primary">
      <Grid container justifyContent="center">
        <Box className={classes.contentWrapper}>
          <CmsBreadcrumb page={page} pages={pages} />
          <div>
            {page?.componentTypes?.map((component) => {
              const Component = Components[component.componentType];
              return Component ? (
                <Component
                  material={component}
                  pages={pages}
                  gutters={gutters}
                  page={page}
                />
              ) : null;
            })}
            {!page?.componentTypes || page?.componentTypes.length === 0 ? (
              <Typography
                variant="deprecated_h5"
                className={classes.headline}
                style={{
                  fontWeight: 700,
                  textAlign: "center",
                  fontSize: "3rem",
                  marginTop: "6rem"
                }}
              >
                <ContentResolver content="Oops..." />
                <a
                  style={{
                    display: "block",
                    fontWeight: 700,
                    textAlign: "center",
                    fontSize: "1rem",
                    marginTop: "2rem"
                  }}
                  href="../"
                >
                  « Back
                </a>
              </Typography>
            ) : null}
          </div>
        </Box>
      </Grid>
    </Layout>
  );
};

export default CMSDrivenPageTemplate;
