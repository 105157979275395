import PropTypes from "prop-types";
import { Modal, Grid } from "@mui/material";
import ModalWrapperStyles from "./ModalWrapper.styles";

const ModalWrapper = (props) => {
  const { isOpen, onClickOutsideModal, children, sx = {} } = props;
  const classes = ModalWrapperStyles();

  return (
    <Modal
      id="modal-message"
      open={isOpen}
      onClose={onClickOutsideModal}
      aria-labelledby="title-modal"
      aria-describedby="simple-modal-description"
      className={`${classes.modal} fade`}
      closeAfterTransition
      sx={sx}
    >
      <Grid
        item
        xs={11}
        sm={8}
        md={6}
        lg={4}
        className={classes.modalContainer}
      >
        <Grid container alignItems="center" justifyContent="center">
          {children}
        </Grid>
      </Grid>
    </Modal>
  );
};

export default ModalWrapper;

ModalWrapper.propTypes = {
  isOpen: PropTypes.bool,
  onClickOutsideModal: PropTypes.func,
  children: PropTypes.object,
  sx: PropTypes.object
};
