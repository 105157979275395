import { Box, useMediaQuery } from "@mui/material";
import HeaderlessGradientLogoFramedLayout from "../../../layouts/HeaderlessGradientLogoFramedLayout";
import React from "react";
import { useTheme } from "@emotion/react";

interface OnboardingStepperLayoutProps {
  onboardingStepper: React.ReactNode;
  children: React.ReactNode;
}

const OnboardingStepperLayout: React.FC<OnboardingStepperLayoutProps> = ({
  onboardingStepper,
  children
}) => {
  const theme = useTheme();
  //@ts-ignore - Don't want to fix the breakpoints on the theme right now.
  const screenWidthIsMdOrSmaller = useMediaQuery(theme.breakpoints.down("lg"));

  const sideOrTopStepper = screenWidthIsMdOrSmaller ? (
    <Box flex="0">{onboardingStepper}</Box>
  ) : (
    <Box flex="0 0 370px">{onboardingStepper}</Box>
  );
  return (
    <HeaderlessGradientLogoFramedLayout>
      <Box
        sx={{
          boxSizing: "border-box",
          display: "flex",
          flexDirection: "row",
          minHeight: "100vh",
          padding: {
            xs: "30px 0px 60px 0px",
            md: "100px 50px 50px 50px"
          },
          width: "100%"
        }}
      >
        {sideOrTopStepper}
        <Box
          sx={{
            flex: "1",
            display: "flex",
            flexDirection: "column",
            width: "100%"
          }}
        >
          {children}
        </Box>
      </Box>
    </HeaderlessGradientLogoFramedLayout>
  );
};

export default OnboardingStepperLayout;
