export const themeBreakpoints = {
  values: {
    xs: 300,
    xsm: 376,
    sm: 670,
    md: 768,
    lg: 960,
    xl: 1920
  }
};
