import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { getCookie, setCookie } from "../../../../utils/CookieUtil";
import { Box, Grid, Typography } from "@mui/material";
import HeaderLogo from "../../../../assets/images/LogoIconDefault.svg";
import LoginCallbackPageStyles from "./LoginCallbackPage.styles";

import {
  setUserPersonalInformation,
  updateFieldsInUserPersonalInformation
} from "../../../../store/reducers/user/UserAccountSlice";
import Mixpanel from "../../../../utils/mixpanel";
import { PATHS } from "../../../../constants/pathConstants";
import Translate from "../../../../translate/Translate";
import GLTextField from "../../Forms/FormComponents/GLTextField";
import { useFormFieldState } from "../../Forms/FormUtils";
import { testAccessCode } from "../../../../apis/authApi";
import ClearPageCornerArtLayout from "components/v3/layouts/ClearPageCornerArtLayout";
import { useWindowSize } from "../../../../hooks/useWindowSize";
import GLButton from "components/v3/sharedComponents/GLButton";
import HelpModal from "components/v3/pages/onboarding/RegistrationInstructions/HelpModal";
import LoadingPage from "components/v3/pages/LoadingPage";
import { ACTIVE_STATUSES } from "constants/generalConstants";

const LoginCallbackPage = () => {
  const {
    isAuthenticated,
    getAccessTokenSilently,
    getIdTokenClaims
  } = useAuth0();
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = LoginCallbackPageStyles();
  const windowSize = useWindowSize();
  const { formFields, handleInputChange } = useFormFieldState({});
  const [contactModalOpen, setContactModalOpen] = useState(false);
  const [needsAccessToken, setNeedsAccessToken] = useState(false);
  const [lastCodeWasInvalid, setLastCodeWasInvalid] = useState(false);
  const [showLoadingSpinner, setShowLoadingSpinner] = useState(false);

  //This feels gross, but there are a few reasons we need the useEffect loop to
  //  kick over again during the back and forth redirects required by the auth0 flow
  //  this lets us tell the effect to run again on the next page load.
  const [refreshLoopCount, setRefreshLoopCount] = useState(0);

  const [userInfo, setUserInfo] = useState({});
  // Trash this as soon as the glid gets moved to app_metadata
  //  instead of user_metadata
  function findValueByKey(obj, key) {
    let foundValue = null;
    const search = (obj, key) => {
      Object.entries(obj).find(([prop, value]) => {
        if (prop === key) {
          foundValue = value;
          return true;
        } else if (typeof value === "object") {
          search(value, key);
        }
      });
    };
    search(obj, key);
    return foundValue;
  }

  useEffect(() => {
    let isMounted = true; // Flag to track component mount state

    const getToken = async () => {
      try {
        const token = await getAccessTokenSilently({ cacheMode: "off" });

        if (!isMounted) return; // Exit early if the component is unmounted

        const userDataFromAuth0Token = await getIdTokenClaims();

        if (!isMounted) return; // Exit early if the component is unmounted

        const userGlid = findValueByKey(userDataFromAuth0Token, "glMemberId");
        const glProgramId = findValueByKey(
          userDataFromAuth0Token,
          "glProgramId"
        );
        const glMemberStatus = findValueByKey(
          userDataFromAuth0Token,
          "glMemberStatus"
        );
        const auth0UserName = findValueByKey(userDataFromAuth0Token, "email");
        const auth0UserId = findValueByKey(userDataFromAuth0Token, "sub");

        dispatch(
          updateFieldsInUserPersonalInformation({
            glMemberId: userGlid,
            email: userDataFromAuth0Token.email,
            glProgramId: glProgramId,
            isMemberActive: ACTIVE_STATUSES.includes(
              glMemberStatus?.replace(/[,\/\-\s']/g, "").toUpperCase()
            )
          })
        );

        if (userGlid) {
          Mixpanel.identify(userGlid);
        }

        setUserInfo({
          glid: userGlid,
          auth0UserName: auth0UserName,
          auth0UserId: auth0UserId
        });

        setCookie("accessToken", token);
        setRefreshLoopCount((prev) => prev + 1);

        if (userGlid) {
          history.push(PATHS.API_SCRAPE);
        } else {
          setNeedsAccessToken(true);
        }
      } catch (error) {
        if (
          [
            "invalid_grant",
            "missing_refresh_token",
            "consent_required"
          ].includes(error.error)
        ) {
          // Ignore specific errors
          setRefreshLoopCount((prev) => prev + 1);
        } else if (error.error === "Unknown or invalid refresh token") {
          history.push(PATHS.LOG_OUT);
        } else {
          Mixpanel.track("Login callback page was unable to get access token");
          throw error;
        }
      }
    };

    if (!getCookie("accessToken")) {
      getToken();
    }

    return () => {
      isMounted = false; // Cleanup: prevent state updates if unmounted
    };
  }, [isAuthenticated, refreshLoopCount]); // Dependencies

  const sendAccessCodeTest = () => {
    setShowLoadingSpinner(true);
    testAccessCode(
      formFields.accessCode,
      userInfo.auth0UserName,
      userInfo.auth0UserId
    )
      .then((response) => {
        if (response.status === 200) {
          const userInfoFromResponse = {
            firstName: response.data.firstName,
            lastName: response.data.lastName
          };
          setUserInfo({ ...userInfo, ...userInfoFromResponse });
          dispatch(
            setUserPersonalInformation({
              ...userInfoFromResponse,
              email: userInfo.auth0UserName
            })
          );
          if (response.data.glMemberId) {
            Mixpanel.identify(response.data.glMemberId);
            Mixpanel.track("Access code lookup successful");
          }
          history.push(PATHS.API_SCRAPE);
        } else if (
          JSON.stringify(JSON.parse(JSON.stringify(response)).status) === "400"
        ) {
          setShowLoadingSpinner(false);
          setLastCodeWasInvalid(true);
        } else {
          // Probably got a 403, but can't test for it 😡
          history.push(PATHS.LOG_OUT);
        }
      })
      .catch((error) => {
        setLastCodeWasInvalid(true);
        setShowLoadingSpinner(false);
      });
  };

  const resetInvalidCodeFlagAndUpdateInput = (input) => {
    setLastCodeWasInvalid(false);
    handleInputChange(input);
  };

  const checkIfLastCodeWasInvalid = () => {
    if (lastCodeWasInvalid) {
      return (
        <Translate text="We’re having trouble finding your code, please check and try again." />
      );
    }
  };

  const renderAccessCodeForm = () => {
    return (
      <ClearPageCornerArtLayout>
        <HelpModal
          isOpen={contactModalOpen}
          onClose={() => {
            setContactModalOpen(false);
          }}
        />
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          className={classes.pageContainer}
          style={{
            width: "100%",
            height: "100%",
            position: "relative",
            minHeight: windowSize.height
          }}
        >
          <Grid item xs={11} sm={8} md={5} className={classes.formContainer}>
            <Grid item className={classes.center}>
              <img src={HeaderLogo} className={classes.headerLogo} />
            </Grid>
            <Grid item className={classes.center}>
              <Typography variant="deprecated_h5">
                <Translate text="Please enter information below" />
              </Typography>
              <Typography variant="deprecated_subtitle2">
                <Translate text="Please enter your access code below. You can find this code in your welcome email that would have been sent to the email address provided during enrollment." />
              </Typography>
            </Grid>
            <Grid item className={`${classes.center} ${classes.input}`}>
              <GLTextField
                id="accessCode"
                name="accessCode"
                variant="outlined"
                label={"Access Code"}
                md={12}
                value={formFields.accessCode}
                onChange={resetInvalidCodeFlagAndUpdateInput}
                additionalValidation={checkIfLastCodeWasInvalid}
                required
              />
            </Grid>
            <Grid item className={classes.center}>
              <GLButton
                loading={showLoadingSpinner}
                onClick={sendAccessCodeTest}
                disabled={!formFields.accessCode}
                text={"Continue"}
                sx={{
                  width: "100%",
                  maxWidth: "100%",
                  marginBottom: "25px"
                }}
              ></GLButton>
              <Typography className={classes.lightSubheader}>
                <Translate text="Don't have your access code? " />
                <br />
                <Box
                  className={classes.link}
                  onClick={() => {
                    setContactModalOpen(true);
                  }}
                >
                  <Translate text="Give us a call." />
                </Box>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </ClearPageCornerArtLayout>
    );
  };

  if (needsAccessToken) {
    return renderAccessCodeForm();
  } else {
    return <LoadingPage message={"Retrieving your account..."} />;
  }
};

export default LoginCallbackPage;
