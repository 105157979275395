import React from "react";
import { Grid, Typography, Link } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import FormSummaryStyles from "./FormSummary.styles";
import Translate from "../../../translate/Translate";

const FormSummary = (props) => {
  const { formSections } = props;
  const classes = FormSummaryStyles();

  const subfieldRender = (subfield, variant) => {
    return (
      <Typography className={classes.bundleSubfields} variant={variant}>
        {subfield.value}
      </Typography>
    );
  };
  const renderLabel = (label) => {
    if (label) {
      return (
        <Typography className={classes.formLabel}>
          <Translate text={label} />
          {":"}
        </Typography>
      );
    }
    return null;
  };
  const mapSummarySections = (section, index) => {
    return (
      <Grid key={index} xs={12} direction={"column"}>
        <Grid
          container
          xs={12}
          direction={"column"}
          justifyContent={"center"}
          alignContent={"center"}
        >
          <Grid
            container
            xs={12}
            className={classes.titleContainer}
            style={{
              borderRadius: index === 0 ? "10px 10px 0px 0px" : null
            }}
            justifyContent={"center"}
            alignContent={"center"}
          >
            <Grid item xs={10} sm={10} md={11}>
              <Typography variant={"deprecated_h6"} className={classes.title}>
                <Translate text={section.title} />
              </Typography>
            </Grid>
            <Grid
              container
              justifyContent={"flex-end"}
              alignContent={"flex-end"}
              item
              xs={2}
              sm={2}
              md={1}
            >
              <Link
                className={classes.editButton}
                onClick={() => section.handleBackTo()}
                name={`edit-icon-${section.title
                  .toLowerCase()
                  .split(" ")
                  .join("")}`}
                underline="hover"
              >
                <EditIcon color="primary" />
              </Link>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} direction={"column"} className={classes.boxGrid}>
          {section.fields
            ? section.fields.map((field, index) => {
                if (!field.value) {
                  return null;
                }
                const variant = field.variant
                  ? field.variant
                  : "deprecated_subtitle1";
                return (
                  <Grid
                    key={index + field.value}
                    className={classes.fieldContainer}
                    item
                    xs={12}
                  >
                    <Grid container>
                      {renderLabel(field.label)}
                      <Typography style={field.style} variant={variant}>
                        {field.value ? <Translate text={field.value} /> : ""}
                      </Typography>
                    </Grid>
                    {field.subFields
                      ? field.subFields.map((subfield) =>
                          subfieldRender(subfield, variant)
                        )
                      : null}
                  </Grid>
                );
              })
            : null}
        </Grid>
      </Grid>
    );
  };

  if (formSections && formSections.length > 0) {
    return (
      <Grid
        className={classes.container}
        container
        xs={12}
        justifyContent={"center"}
        alignContent={"center"}
      >
        {formSections.map((section, index) => {
          return mapSummarySections(section, index);
        })}
      </Grid>
    );
  }
  return null;
};
export default FormSummary;
