import React from "react";
import GLTextField, { GLTextFieldProps } from "../GLTextField";
import { validateZipCode } from "../FormUtils";

const GLZipField: React.FC<GLTextFieldProps> = ({
  label,
  id,
  onChange,
  tooltip,
  value,
  defaultValue,
  required,
  disabled,
  placeholder
}) => {
  return (
    <GLTextField
      label={label}
      id={id}
      tooltip={tooltip}
      value={value}
      defaultValue={defaultValue}
      placeholder={placeholder}
      required={required}
      disabled={disabled}
      onChange={onChange}
      additionalValidation={validateZipCode}
    />
  );
};

export default GLZipField;
