import React from "react";
import { Button, Grid } from "@mui/material";
import ArticleExternalStyles from "./ArticleExternal.styles";
import ContentResolver from "../ContentResolver";
import GutterCard from "../GutterCard";
import ResourceLink from "../ResourceLink";

const ArticleExternal = (props) => {
  const { content } = props.page;
  const { related_articles } = props.page;
  const { gutters, pages } = props;
  const classes = ArticleExternalStyles();

  const renderChildren = (objects) => {
    return Object.entries(objects || {}).map(([index, child]) => {
      return (
        <Grid
          item
          xs={12}
          lg={12}
          key={index}
          className={classes.linkBoxContainer}
        >
          <div className={classes.linkBox}>
            <ResourceLink link={pages[child.ref]} />
            {pages[child.ref].summary && (
              <div className={classes.summary}>
                <ContentResolver content={pages[child.ref].summary} />
              </div>
            )}
            {pages[child.ref]?.children &&
            Object.keys(pages[child.ref]?.children).length > 0 ? (
              <>{renderChildChildren(pages[child.ref]?.children)}</>
            ) : null}
          </div>
        </Grid>
      );
    });
  };

  const renderChildChildren = (objects) => {
    let renderReturn = Object.entries(objects || {}).map(([index, child]) => {
      return (
        <li>
          <ResourceLink link={pages[child.ref]} />
        </li>
      );
    });
    if (renderReturn)
      return <ul className={classes.childrenList}>{renderReturn}</ul>;
  };

  return (
    <Grid container className={classes.container}>
      <Grid item sm={12} md={8} lg={9}>
        <Button
          style={{ marginLeft: 0 }}
          href={props.material.url}
          variant="contained"
          target="_blank"
          color="primary"
        >
          Visit website
        </Button>
        <div>
          <a
            href={props.material.url}
            target="_blank"
            style={{ textDecoration: "none" }}
          >
            <i>
              <small>{props.material.url}</small>
            </i>
          </a>
        </div>
        <ContentResolver content={content} />

        <Grid container spacing={2}>
          {related_articles && Object.keys(related_articles).length > 0 ? (
            <>
              <Grid className={classes.gridSpacing} item xs={12} lg={12}>
                <h2 className={classes.subheading}>Articles</h2>
              </Grid>
              {renderChildren(related_articles)}
            </>
          ) : null}
        </Grid>
      </Grid>
      <Grid item sm={12} md={4} lg={3}>
        <GutterCard material={gutters} pages={pages} />
      </Grid>
    </Grid>
  );
};

export default ArticleExternal;
