import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Translate from "../../../../translate/Translate";
import { Box, Button, Grid, Typography } from "@mui/material";
import Layout from "../../../layout/Layout";
import OrderKitConclusionStyles from "./OrderKitConclusionPage.styles";
//image import
import kitConfirmation from "../../../../assets/images/kitSuccessImage.svg";
import { useHistory } from "react-router-dom";
import { PATHS } from "../../../../constants/pathConstants";
import PageTitle from "../../../../utils/PageTitle";
import {
  selectUuidsOfKitsJustOrderedToBeShownOnConclusionScreen,
  setUuidsOfKitsJustOrderedToBeShownOnConclusionScreen,
  selectTestKitsGLIsCurrentlyOffering
} from "../../../../store/reducers/order/OrderInformationSlice";
import WorkflowConclusionTemplate from "../../../v2/sharedComponents/WorkflowConclusionTemplate";

const OrderKitConclusionPage = (props) => {
  const classes = OrderKitConclusionStyles();
  const history = useHistory();

  const kitsJustOrdered = useSelector(
    selectUuidsOfKitsJustOrderedToBeShownOnConclusionScreen
  );
  const testKitsGLIsCurrentlyOffering = useSelector(
    selectTestKitsGLIsCurrentlyOffering
  );
  const dispatch = useDispatch();

  const mapUuidsIntoFullTest = () => {
    if (
      kitsJustOrdered.length > 0 &&
      testKitsGLIsCurrentlyOffering.length > 0
    ) {
      return kitsJustOrdered.map((uuid) => {
        return testKitsGLIsCurrentlyOffering.find((test) => {
          return test.test_uuid === uuid;
        });
      });
    }
    return [];
  };

  const mappedOrders = mapUuidsIntoFullTest();

  useEffect(() => {
    if (kitsJustOrdered.length < 1) {
      history.push(PATHS.LANDING_PAGE);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kitsJustOrdered]);

  PageTitle("Order Confirmation");

  const renderKitCards = () => {
    return mappedOrders.map((order) => {
      return (
        <Grid key={`order_${order.test_uuid}`} item xs={12} md={6} lg={4}>
          <Box className={classes.cardContainer}>
            <Grid item className={classes.card}>
              <Typography variant="deprecated_h6">{order.test_name}</Typography>
              <p>
                <i>
                  Our laboratory partner, {order.lab}, will perform your test.
                </i>
              </p>
            </Grid>
          </Box>
        </Grid>
      );
    });
  };

  return (
    <Layout headerType="primary">
      <WorkflowConclusionTemplate
        headerImage={kitConfirmation}
        headerImageAlt="kit confirmation"
        mainHeaderText="We’ve successfully processed your kit request! What's next?"
      >
        <Grid container justifyContent="center" alignItems="center">
          <Grid item xs={10}>
            <Grid item xs={12}>
              <Typography
                variant="deprecated_h6"
                className={classes.stepNumber}
              >
                <Translate text={`Step 1`} />
              </Typography>
              <p className={classes.subheader}>
                {`You will recieve ${
                  kitsJustOrdered.length
                } sample collection kit${
                  kitsJustOrdered.length > 1 ? "s" : ""
                } in the
                mail from ${
                  kitsJustOrdered.length > 1 ? "" : "one of"
                } our laboratory partners for your genetic test.`}
              </p>
            </Grid>
            <Grid container spacing={3}>
              {renderKitCards()}
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="deprecated_h6"
                className={classes.stepNumber}
              >
                <Translate text={"Step 2"} />
              </Typography>
              <p className={classes.subheader}>
                {`Once your sample collection kit${
                  kitsJustOrdered.length > 1 ? "s" : ""
                } arrive${
                  kitsJustOrdered.length > 1 ? "" : "s"
                }, follow the directions provided.`}
              </p>
              <p className={classes.subheader}>
                1. It is critical to follow the instructions provided in the
                sample collection kit.
                <br />
                2. Send it back in the mail to the laboratory. If the steps are
                not followed properly, you may need to do another one.
                <br />
                3. Please return your sample kit promptly to the laboratory.
              </p>
            </Grid>

            <Grid item xs={12}>
              <Typography
                variant="deprecated_h6"
                className={classes.stepNumber}
              >
                <Translate text={`Step 3`} />
              </Typography>
              <p className={classes.subheader}>
                Once the laboratory receives your kit, it will take several
                weeks to complete your test. You will be notified when your
                results are ready.
              </p>
              <p className={classes.subheader}>
                You may get emails from our partners, but it is not required to
                set up additional accounts for this testing. The Genomic Life
                portal gives you access to your test results and follow-up
                materials.
              </p>
              <p className={classes.subheader}>
                Until then, let us know if you have any questions! Please feel
                free to email us at{" "}
                <a
                  className={classes.link}
                  href="mailto:navigation@genomiclife.com"
                >
                  navigation@genomiclife.com
                </a>
                .
              </p>
            </Grid>
          </Grid>
        </Grid>

        <Grid container justifyContent="center">
          <Grid item xs={10}>
            <div className={classes.BackToButton}>
              <Button
                onClick={() => {
                  dispatch(
                    setUuidsOfKitsJustOrderedToBeShownOnConclusionScreen([])
                  );
                  history.push(PATHS.LANDING_PAGE);
                }}
                color="primary"
                variant="contained"
                className={`${classes.backToButton}`}
              >
                Back to my dashboard
              </Button>
            </div>
          </Grid>
        </Grid>
      </WorkflowConclusionTemplate>
    </Layout>
  );
};

export default OrderKitConclusionPage;
