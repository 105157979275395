import { Box, Typography } from "@mui/material";
import { ArrowForward } from "@mui/icons-material";
import GLButton from "components/v3/sharedComponents/GLButton";
import Video from "components/v3/sharedComponents/Video";
import WelcomeShapesImg from "assets/images/WelcomeShapes.svg";
import { useHistory } from "react-router-dom";
import { PATHS } from "constants/pathConstants";

const VideoSection = () => {
  const history = useHistory();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: {
          xs: "column",
          lg: "row"
        },
        gap: 8,
        maxWidth: "1280px",
        paddingX: {
          xs: "20px",
          md: 8
        },
        paddingY: {
          xs: "30px",
          lg: 8
        },
        position: "relative"
      }}
    >
      <Box
        sx={{
          display: "flex",
          flex: 1,
          flexDirection: "column",
          gap: "28px",
          maxWidth: {
            xs: "auto",
            lg: "442px"
          }
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "28px"
          }}
        >
          <Typography
            fontFamily="Merriweather"
            fontStyle="italic"
            variant="body_lg_accent2_dark"
          >
            The key to better health
          </Typography>
          <Typography
            sx={{ typography: { xs: "h5_dark_gray_2", md: "h4_dark_gray_2" } }}
          >
            Genomic Life can enhance your health journey
          </Typography>
          <Typography variant="body">
            Genomic Life can help optimize your health now, and in the future,
            based on your personal health, family history, and DNA to help you
            catch disease early, inform your choices, and tailor your care so it
            works best for you.
          </Typography>
          <GLButton
            buttonType="secondary-transparent"
            text="Activate Your Account"
            onClick={() =>
              history.push(PATHS.ONBOARDING_REGISTRATION_INSTRUCTIONS)
            }
            rightIcon={<ArrowForward />}
          ></GLButton>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flex: 1,
          width: "100%"
        }}
      >
        <Box
          sx={{
            borderRadius: "16px",
            boxShadow:
              "0px 10px 15px -3px rgba(0, 0, 0, 0.10), 0px 4px 6px -2px rgba(0, 0, 0, 0.05)",
            overflow: "hidden",
            width: "100%",
            zIndex: 9
          }}
        >
          <Video
            height="100%"
            muted
            playing
            style={{
              aspectRatio: "16/9",
              height: "100%",
              width: "100%"
            }}
            width="100%"
            url="https://vimeo.com/896232421"
          />
        </Box>
      </Box>
      <Box
        sx={{
          bottom: {
            xs: "-25px",
            lg: "-45px"
          },
          position: "absolute",
          right: {
            xs: "-15px",
            lg: "calc(-1 * (100vw - 1280px)/2)"
          },
          zIndex: 0
        }}
      >
        <Box
          component="img"
          sx={{
            height: {
              xs: "100px",
              lg: "auto"
            },
            objectFit: "contain",
            width: {
              xs: "104px",
              lg: "auto"
            }
          }}
          src={WelcomeShapesImg}
          alt=""
        />
      </Box>
    </Box>
  );
};

export default VideoSection;
