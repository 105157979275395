import React from "react";
import { Grid, Typography } from "@mui/material";
import RelatedArticlesGridStyles from "./RelatedArticlesGrid.styles";
import ContentResolver from "../ContentResolver";
import ResourceLink from "../ResourceLink";

const RelatedArticlesGrid = (props) => {
  const { articles, header, href } = props.material;
  const classes = RelatedArticlesGridStyles();

  const renderCards = () => {
    return Object.entries(articles || {}).map(([index, child]) => {
      return (
        <Grid
          item
          xs={12}
          md={4}
          key={index}
          className={classes.linkBoxContainer}
        >
          <div className={classes.linkBox}>
            <div className={classes.linkHeader}>
              <ResourceLink link={child} />
            </div>
            <div>
              <ContentResolver content={child.summary} />
            </div>
          </div>
        </Grid>
      );
    });
  };

  const maybeHeader = header ? (
    <Typography variant="deprecated_h5" className={classes.headline}>
      <ContentResolver content={header} />
    </Typography>
  ) : null;

  const maybeHref = href ? (
    <Typography>
      <a href={href.route} className={classes.linkContainer}>
        <ContentResolver content={href.title} />
      </a>
    </Typography>
  ) : null;

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12}>
        {maybeHeader || maybeHref ? (
          <div className={classes.headerContainer}>
            <div>{maybeHeader}</div>
            <div>{maybeHref}</div>
          </div>
        ) : null}
        <Grid container spacing={3}>
          {renderCards()}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default RelatedArticlesGrid;
