import React from "react";
import { Grid } from "@mui/material";
import ArticleStyles from "./Article.styles";
import ContentResolver from "../ContentResolver";
import GutterCard from "../GutterCard";

const Article = (props) => {
  const { content } = props.page;
  const { gutters, pages } = props;
  const classes = ArticleStyles();

  return (
    <Grid container className={classes.container}>
      <Grid item className={classes.articleContainer} sm={12} md={12} lg={9}>
        <ContentResolver content={content} />
      </Grid>

      <Grid item sm={12} md={12} lg={3}>
        <GutterCard material={gutters} pages={pages} />
      </Grid>
    </Grid>
  );
};

export default Article;
