import { makeStyles } from "@mui/styles";
import { COLOR_CONSTANTS } from "../../../../../constants/StyleConstants";

const HeroHeaderStyles = makeStyles((theme) => ({
  container: { textAlign: "center", margin: "50px 0" },
  heading: {
    fontSize: "45px",
    lineHeight: "60px",
    color: COLOR_CONSTANTS.GENOMIC_LIFE_PRIMARY
  },
  content: {
    color: COLOR_CONSTANTS.GENOMIC_LIFE_SECONDARY,
    "& a": {
      color: COLOR_CONSTANTS.GENOMIC_LIFE_SECONDARY
    }
  }
}));

export default HeroHeaderStyles;
