import { createTheme, Theme } from "@mui/material";
import {
  COLOR_CONSTANTS,
  FONT_CONSTANTS,
  FLEX_CONSTANTS,
  GENERIC_CONSTANTS
} from "../../../constants/StyleConstants";
import { themeBreakpoints } from "../../../theme/themeBreakpoints";
import { themeOverrides } from "../../../theme/themeOverrides";
import { themePalette } from "../../../theme/themePalette";
import { themeTypography } from "../../../theme/themeTypography";
import {
  TYPOGRAPHY_VARIANTS,
  TYPOGRAPHY_VARIANT_MAPPINGS,
  TypographyCustomVariantTypes
} from "./TypographyVariants";

declare module "@mui/material/styles" {
  // This is gross. Get rid of this as soon as we retire the old stuff using it.
  interface Theme {
    paddings: any;
    margins: any;
    header: any;
    footer: any;
    card: any;
    scrollPrimaryColors: any;
  }
  interface ThemeOptions {
    paddings?: any;
    margins?: any;
    header?: any;
    footer?: any;
    card?: any;
    scrollPrimaryColors?: any;
    fontFamily?: any;
    fontWeight?: any;
    fontSize?: any;
    fontStyle?: any;
    lineHeight?: any;
    letterSpacing?: any;
  }
  interface TypographyVariants {
    deprecated_h1: React.CSSProperties;
    deprecated_h2: React.CSSProperties;
    deprecated_h3: React.CSSProperties;
    deprecated_h4: React.CSSProperties;
    deprecated_h5: React.CSSProperties;
    deprecated_h6: React.CSSProperties;
    deprecated_h5HeadlineBlack: React.CSSProperties;
    deprecated_h6HeadlineBlack: React.CSSProperties;
    deprecated_h6HeadlineHeavy: React.CSSProperties;
    deprecated_body1: React.CSSProperties;
    deprecated_body1Heavy: React.CSSProperties;
    deprecated_bodyBlack: React.CSSProperties;
    deprecated_body2: React.CSSProperties;
    deprecated_subtitleRegular: React.CSSProperties;
    deprecated_subtitleHeavy: React.CSSProperties;
    deprecated_subtitle1: React.CSSProperties;
    deprecated_subtitle2: React.CSSProperties;
    deprecated_surveyNormal: React.CSSProperties;
    deprecated_surveyHeavy: React.CSSProperties;
  }
  interface TypographyVariantsOptions {
    deprecated_h1?: React.CSSProperties;
    deprecated_h2?: React.CSSProperties;
    deprecated_h3?: React.CSSProperties;
    deprecated_h4?: React.CSSProperties;
    deprecated_h5?: React.CSSProperties;
    deprecated_h6?: React.CSSProperties;
    deprecated_h5HeadlineBlack?: React.CSSProperties;
    deprecated_h6HeadlineBlack?: React.CSSProperties;
    deprecated_h6HeadlineHeavy?: React.CSSProperties;
    deprecated_body1?: React.CSSProperties;
    deprecated_body1Heavy?: React.CSSProperties;
    deprecated_bodyBlack?: React.CSSProperties;
    deprecated_body2?: React.CSSProperties;
    deprecated_subtitleRegular?: React.CSSProperties;
    deprecated_subtitleHeavy?: React.CSSProperties;
    deprecated_subtitle1?: React.CSSProperties;
    deprecated_subtitle2?: React.CSSProperties;
    deprecated_surveyNormal?: React.CSSProperties;
    deprecated_surveyHeavy?: React.CSSProperties;
  }
}

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides
    extends TypographyCustomVariantTypes {}
}

const DEPRECATED_TYPOGRAPHY_VARIANT_MAPPINGS = {
  deprecated_h1: "h1",
  deprecated_h2: "h2",
  deprecated_h3: "h3",
  deprecated_h4: "h4",
  deprecated_h5: "h5",
  deprecated_h6: "h6",
  deprecated_body: "p"
};

let baseTheme = createTheme();

const customTheme: Theme = createTheme({
  ...baseTheme,
  breakpoints: themeBreakpoints,
  palette: themePalette,
  typography: { htmlFontSize: 16, ...themeTypography, ...TYPOGRAPHY_VARIANTS },
  components: {
    ...themeOverrides,
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          ...TYPOGRAPHY_VARIANT_MAPPINGS,
          ...DEPRECATED_TYPOGRAPHY_VARIANT_MAPPINGS
        }
      }
    }
  },
  paddings: {
    xs: 10,
    sm: 15,
    md: 20,
    lg: 25,
    xl: 30,
    xxl: 40,
    xxxl: 65
  },
  margins: {
    xs: 10,
    sm: 15,
    md: 20,
    lg: 25,
    xl: 30,
    xxl: 40,
    xxxl: 65
  },
  header: {
    display: FLEX_CONSTANTS.FLEX,
    backgroundColor: COLOR_CONSTANTS.GENOMIC_LIFE_SECONDARY,
    height: 100,
    color: COLOR_CONSTANTS.COLOR_WHITE,
    fontFamily: FONT_CONSTANTS.FONT_AVENIRLT_PRO_HEAVY,
    justifyContent: FLEX_CONSTANTS.CENTER,
    alignItems: FLEX_CONSTANTS.CENTER
  },
  footer: {
    primary: {
      container: {
        backgroundColor: COLOR_CONSTANTS.GENOMIC_LIFE_SECONDARY
      },
      h2: {
        fontFamily: FONT_CONSTANTS.FONT_FAMILY_DOMAIN_DISPLAY_SEMIBOLD,
        fontStyle: FONT_CONSTANTS.FONT_SIZE_NORMAL,
        fontWeight: 600,
        fontSize: 20,
        display: FLEX_CONSTANTS.FLEX,
        alignItems: FLEX_CONSTANTS.CENTER,
        textAlign: FLEX_CONSTANTS.CENTER,
        color: COLOR_CONSTANTS.COLOR_WHITE,
        marginTop: 30
      },
      body: {
        fontFamily: FONT_CONSTANTS.FONT_AVENIRLT_PRO_MEDIUM,
        fontWeight: 400,
        fontSize: 14,
        textAlign: FLEX_CONSTANTS.CENTER,
        color: COLOR_CONSTANTS.COLOR_WHITE,
        marginTop: 20,
        marginBottom: 20
      },
      contact: {
        fontFamily: FONT_CONSTANTS.FONT_AVENIRLT_PRO_MEDIUM,
        fontWeight: 400,
        fontSize: 16,
        textAlign: FLEX_CONSTANTS.LEFT,
        marginBottom: 15,
        color: COLOR_CONSTANTS.COLOR_WHITE,
        textDecoration: GENERIC_CONSTANTS.NONE
      }
    },
    secondary: {
      container: {
        backgroundColor: COLOR_CONSTANTS.COLOR_GRAY_60
      },
      h2: {
        fontFamily: FONT_CONSTANTS.FONT_FAMILY_DOMAIN_DISPLAY_SEMIBOLD,
        fontStyle: FONT_CONSTANTS.FONT_SIZE_NORMAL,
        fontWeight: 600,
        fontSize: 20,
        display: FLEX_CONSTANTS.FLEX,
        alignItems: FLEX_CONSTANTS.CENTER,
        textAlign: FLEX_CONSTANTS.CENTER,
        color: COLOR_CONSTANTS.GENOMIC_LIFE_SECONDARY,
        marginTop: 30
      },
      body: {
        fontFamily: FONT_CONSTANTS.FONT_AVENIRLT_PRO_MEDIUM,
        fontWeight: 400,
        fontSize: 14,
        textAlign: FLEX_CONSTANTS.CENTER,
        color: COLOR_CONSTANTS.GENOMIC_LIFE_SECONDARY,
        marginTop: 20,
        marginBottom: 20
      },
      contact: {
        fontFamily: FONT_CONSTANTS.FONT_AVENIRLT_PRO_MEDIUM,
        fontWeight: 400,
        fontSize: 16,
        textAlign: FLEX_CONSTANTS.LEFT,
        marginBottom: 15,
        color: COLOR_CONSTANTS.GENOMIC_LIFE_SECONDARY,
        textDecoration: GENERIC_CONSTANTS.NONE
      }
    }
  },
  card: {
    border: "1px solid rgba(0, 0, 0, 0.1)",
    borderDarker: "1px solid rgba(0, 0, 0, 0.25)"
  },
  scrollPrimaryColors: {
    "&::-webkit-scrollbar": {
      width: 10,
      borderRadius: 100,
      backgroundColor: COLOR_CONSTANTS.COLOR_GRAY_60
    },
    "&::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)"
    },
    "&::-webkit-scrollbar-thumb": {
      background: COLOR_CONSTANTS.GENOMIC_LIFE_LINK_COLOR,
      borderRadius: 100
    },
    "scrollbar-color": `${COLOR_CONSTANTS.GENOMIC_LIFE_LINK_COLOR} ${COLOR_CONSTANTS.COLOR_GRAY_60}`,
    "scrollbar-width": "inherit"
  }
});
export default customTheme as Theme;
