import React from "react";
import CenteredContent from "../../sharedComponents/CenteredContent";
import { Avatar, Grid, Typography, Chip } from "@mui/material";
import Layout from "../../layout/Layout";
import AccountManagementStyles from "./AccountManagement.styles";
//Redux
import { useSelector } from "react-redux";
import { selectUserPersonalInformation } from "../../../store/reducers/user/UserAccountSlice";
import OptionCards from "./OptionCards";
import Translate from "../../../translate/Translate";
import SecurityMessage from "../../sharedComponents/SecurityMessage";
import PageTitle from "../../../utils/PageTitle";

const AccountManagementPage = (props) => {
  const userInformation = useSelector(selectUserPersonalInformation);

  (function() {
    window.scrollTo(0, 0);
  })();

  const classes = AccountManagementStyles();

  PageTitle("Account Management");

  return (
    <Layout headerType="primary">
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={12}>
          <CenteredContent>
            <Typography
              variant="deprecated_h3"
              className={classes.accountManTitle}
            >
              <Translate text="Account Management" />
            </Typography>
          </CenteredContent>
        </Grid>
        <Grid item xs={12}>
          <CenteredContent>
            <Avatar className={classes.avatar}>
              <Typography align="center" className={classes.avatarFont}>
                {userInformation.preferredName.charAt(0) +
                  userInformation.lastName.charAt(0)}
              </Typography>
            </Avatar>
          </CenteredContent>
        </Grid>
        <Grid item xs={12}>
          <CenteredContent>
            <Typography
              variant="deprecated_h5"
              align="center"
              className={classes.AccountManageClientName}
            >
              {`${
                userInformation.preferredName
              } ${userInformation.lastName.charAt(0)}.`}
            </Typography>
          </CenteredContent>
        </Grid>
        <Grid item xs={12}>
          <CenteredContent>
            <Typography
              variant="deprecated_subtitle1"
              align="center"
              className={classes.AccountManageClientSince}
            ></Typography>
          </CenteredContent>
        </Grid>
        {userInformation.isMemberActive === false && (
          <Grid item xs={12}>
            <CenteredContent>
              <Chip
                label={<Translate text="Inactive" />}
                className={classes.ActiveChip}
              />
            </CenteredContent>
          </Grid>
        )}
        <Grid item xs={12} className={classes.boxOptions}>
          <CenteredContent>
            <OptionCards />
          </CenteredContent>
        </Grid>
      </Grid>
      <CenteredContent>
        <SecurityMessage
          title="Your information is safe with us"
          desc="Genomic Life uses SSL encryption to protect your information."
        />
      </CenteredContent>
    </Layout>
  );
};

export default AccountManagementPage;
