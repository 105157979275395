import Translate from "../../../../../../../translate/Translate";
import React from "react";
import {
  selectSpouseInformation,
  selectUserPersonalInformation
} from "../../../../../../../store/reducers/user/UserAccountSlice";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { PATHS } from "../../../../../../../constants/pathConstants";
import ToDoListCardContainer from "../../ToDoListCardContainer";
import GLButton from "../../../../../sharedComponents/GLButton";
import inviteSpouseIcon from "../../../../../../../assets/icons/icon-invite-spouse.svg";
import Mixpanel from "../../../../../../../utils/mixpanel";

const InviteSpouseToDoCard = () => {
  const history = useHistory();
  const spouseAccessCode = useSelector(selectSpouseInformation)
    .spouseAccessCode;
  const isMemberActive = useSelector(selectUserPersonalInformation)
    .isMemberActive;

  if (!spouseAccessCode) return null;

  const title = <Translate text={"Invite your spouse"} />;

  const renderBody = () => {
    return (
      <Translate
        text={"Good news! Your spouse is included in your membership."}
      />
    );
  };

  const renderButton = () => {
    return (
      <GLButton
        text={"Learn more"}
        size={"small"}
        disabled={!isMemberActive}
        onClick={() => {
          Mixpanel.track(`To Do Card CTA Clicked - Invite spouse`);
          history.push(PATHS.ACCOUNT_MANAGEMENT_PAYMENT_INFORMATION);
        }}
      />
    );
  };

  return (
    <ToDoListCardContainer
      icon={inviteSpouseIcon}
      title={title}
      body={renderBody()}
      buttons={renderButton()}
    ></ToDoListCardContainer>
  );
};

export default InviteSpouseToDoCard;
