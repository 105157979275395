import { Box, Grid, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { ERROR_MESSAGES } from "../../../constants/errorConstants";
import { PATHS } from "../../../constants/pathConstants";
import PageTitle from "../../../utils/PageTitle";
import { validateEmailString } from "../../../utils/utils";
import RequestPasswordResetEmailPageStyles from "./RequestPasswordResetEmailPage.styles";
import logo from "../../../assets/images/GL-Logo.svg";
import Translate from "../../../translate/Translate";
import HelpLink from "../../v2/sharedComponents/HelpLink";
import axios from "axios";
import { useDispatch } from "react-redux";
import { openGLSnackbar } from "../../../store/reducers/snackbar/openGLSnackbarSlice";
import GLButton from "../../v2/sharedComponents/GLButton";

const RequestPasswordResetEmailPage = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const classes = RequestPasswordResetEmailPageStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { REACT_APP_AUTH0_DOMAIN } = process.env;
  const { REACT_APP_AUTH0_CLIENT_ID } = process.env;
  const { REACT_APP_AUTH0_CONNECTION } = process.env;

  const checkValidEmail = (emailValue) => {
    let isValid = validateEmailString(emailValue);
    setEmail(emailValue);
    if (isValid) {
      setError(false);
      setErrorMessage("");
    } else {
      setError(true);
      setErrorMessage(ERROR_MESSAGES.ERROR_MSG_EMAIL_INVALID);
    }
  };

  const ValidateIfIsEmail = (e) => {
    e.preventDefault();
    var options = {
      method: "POST",
      url: `https://${REACT_APP_AUTH0_DOMAIN}/dbconnections/change_password`,
      headers: { "content-type": "application/json" },
      data: {
        client_id: REACT_APP_AUTH0_CLIENT_ID,
        email: email,
        connection: REACT_APP_AUTH0_CONNECTION
      }
    };
    axios
      .request(options)
      .then(function(response) {
        setError(false);
        setErrorMessage("");
        history.push(PATHS.REQUEST_PASSWORD_EMAIL_SUCCESS);
      })
      .catch(function(error) {
        dispatch(
          openGLSnackbar({
            variant: "error",
            subText:
              "Something went wrong sending this email, please try again and reach out to us if you need to!"
          })
        );
        history.push(PATHS.REQUEST_PASSWORD_EMAIL_FAILURE);
      });
  };

  PageTitle("Password email");

  return (
    <Grid container justifyContent="center" alignItems="center">
      <Grid item xs={12}>
        <Box display="flex" justifyContent="center" alignItems="center">
          <img src={logo} alt="Genomic Life" className={classes.img} />
        </Box>
      </Grid>
      <Grid item xs={11}>
        <Typography
          variant="deprecated_h3"
          component="h3"
          name="forgot-password-title"
          className={classes.title}
        >
          <Box textAlign="center">
            <Translate text="Let’s get you into the member platform" />
          </Box>
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Box display="flex" justifyContent="center">
          <Grid item xs={10} sm={5} lg={3} md={4}>
            <TextField
              name="forgot-password-input"
              label={<Translate text="Email" />}
              helperText={errorMessage}
              error={error}
              variant="outlined"
              className={`${
                !error ? classes.inputSuccess : classes.inputError
              }`}
              fullWidth={true}
              value={email}
              onChange={(e) => checkValidEmail(e.target.value)}
            />
            <Box display="flex" justifyContent="center">
              <GLButton
                loadingSpinnerWhenClicked
                variant="contained"
                disableElevation
                size="large"
                className={classes.RoundedButton}
                onClick={ValidateIfIsEmail}
                name="reset-password-btn"
                disabled={error}
              >
                <Translate text="Send Email" />
              </GLButton>
            </Box>
            <Box display="flex" justifyContent="center">
              <Link
                to={PATHS.LOGIN}
                name="back-to-login-link"
                className={classes.BackToLogin}
              >
                <Translate text="BACK TO LOGIN" />
              </Link>
            </Box>
          </Grid>
        </Box>
      </Grid>
      <Box display="flex" justifyContent="center">
        <HelpLink />
      </Box>
    </Grid>
  );
};

export default RequestPasswordResetEmailPage;
