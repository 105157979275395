import { convertToDateFormat } from "../utils/utils";
import { doApiCall } from "./util";

const { REACT_APP_ORDER_MEMBER_PORTAL_ENDPOINT_URL } = process.env;

/**
 * @description Get all the user orders
 * @returns total orders
 */
export const getExistingKitOrders = () => {
  const request = {
    host: `${REACT_APP_ORDER_MEMBER_PORTAL_ENDPOINT_URL}`,
    method: "GET",
    url: `https://${REACT_APP_ORDER_MEMBER_PORTAL_ENDPOINT_URL}/orders`,
    responseType: "json"
  };

  return doApiCall(request);
};

/**
 *
 * @param {*} uuid
 * @returns payload with all the response
 *
 */
export const requestOrder = (payload) => {
  payload.memberData.dob = convertToDateFormat(
    payload.memberData.dob,
    "YYYY-MM-DD"
  );
  const request = {
    host: `${REACT_APP_ORDER_MEMBER_PORTAL_ENDPOINT_URL}`,
    method: "POST",
    url: `https://${REACT_APP_ORDER_MEMBER_PORTAL_ENDPOINT_URL}/async/orders`,
    data: payload,
    body: JSON.stringify(payload)
  };
  return doApiCall(request);
};

/**
 * DEPRECATED *
 * This endpoint allow us to get the document data encoded in base64
 * @param {string} documentId
 * @returns {JSX}
 */
export const downloadOrderDocuments = (orderId, documentId) => {
  const request = {
    host: `${REACT_APP_ORDER_MEMBER_PORTAL_ENDPOINT_URL}`,
    method: "GET",
    url: `https://${REACT_APP_ORDER_MEMBER_PORTAL_ENDPOINT_URL}/orders/${orderId}/documents/${documentId}`
  };

  return doApiCall(request);
};

/**
 * This endpoint allow us to get the document data encoded in base64
 * @param {string} documentId
 * @returns {JSX}
 */
export const downloadOrderDocument = (orderId, documentId) => {
  const request = {
    host: `${REACT_APP_ORDER_MEMBER_PORTAL_ENDPOINT_URL}`,
    method: "GET",
    url: `https://${REACT_APP_ORDER_MEMBER_PORTAL_ENDPOINT_URL}/orders/${orderId}/documents/${documentId}`,
    responseType: "application/pdf"
  };

  return doApiCall(request);
};
