import { makeStyles } from "@mui/styles";
import { COLOR_CONSTANTS } from "../../../../../constants/StyleConstants";

const LinkBoxGridStyles = makeStyles((theme) => ({
  container: {
    padding: "0 1rem",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    alignItems: "flex-start"
  },
  linkBoxContainer: {
    flex:
      "0 1 calc(50% - 10px)" /* Adjust the width to 50% of the container, minus a little space */,
    marginBottom: "15px" /* Space between rows */,
    padding: "10px" /* Some padding for each item */,
    boxSizing:
      "border-box" /* To include padding and borders in width calculations */
  },
  linkBox: {
    borderBottom: `1px dotted ${COLOR_CONSTANTS.COLOR_GRAY_30}`,
    width: "95%",
    paddingBottom: "1rem",
    marginBottom: "1rem"
  },
  groupdHeading: {
    fontWeight: 700,
    fontSize: "2.5rem",
    paddingBottom: ".5rem",
    marginBottom: "3rem",
    borderBottom: `2px solid ${COLOR_CONSTANTS.COLOR_GRAY_30}`
  },

  link: {
    color: COLOR_CONSTANTS.COLOR_BLACK_75
  },

  navGroupHeading: {
    fontWeight: 700,
    fontSize: "1.3rem"
  },

  navGroup: {
    lineHeight: "2rem",
    marginTop: ".5rem"
  },

  question: {
    fontWeight: 700,
    fontSize: "1.5rem",
    marginBottom: ".25rem"
  },
  answer: {
    paddingBottom: "2rem",
    lineHeight: "1.65rem",
    fontSize: "1.15rem",
    borderBottom: `1px dotted ${COLOR_CONSTANTS.COLOR_GRAY_30}`
  },

  linkHover: {
    color: COLOR_CONSTANTS.COLOR_BLACK_75,
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline"
    }
  },

  link: {
    color: COLOR_CONSTANTS.COLOR_BLACK_75,
    textDecoration: "none",
    fontSize: "1.15rem",
    "&:hover": {
      textDecoration: "underline"
    }
  }
}));

export default LinkBoxGridStyles;
