import moment from "moment";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { getCookie } from "../../utils/CookieUtil";

export const convertToDateFormat = (date: string | Date): string => {
  return moment(date).format("YYYY-MM-DD");
};

export function doApiCall(
  request: AxiosRequestConfig
): Promise<AxiosResponse<any> | Error> {
  const accessToken = getCookie("accessToken");

  request.headers = {
    ...request.headers,
    "content-type": "application/json",
    authorization: accessToken
  };

  return axios(request)
    .then((data) => data)
    .catch((error) => error);
}
