/*
!!! --- DO NOT EDIT THIS FILE --- !!!

Any changes you make here may be arbitrarily overwritten

See typographyGenerator.js
*/

import { COLORS } from "./colors.js";

export const TYPOGRAPHY_VARIANTS = {
  h1_xl_serif_white: {
    color: COLORS.WHITE,
    fontFamily: "Merriweather, serif",
    fontSize: "4.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_xl_serif_black: {
    color: COLORS.BLACK,
    fontFamily: "Merriweather, serif",
    fontSize: "4.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_xl_serif_gray: {
    color: COLORS.GRAY,
    fontFamily: "Merriweather, serif",
    fontSize: "4.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_xl_serif_green_2: {
    color: COLORS.GREEN_2,
    fontFamily: "Merriweather, serif",
    fontSize: "4.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_xl_serif_dark_gray: {
    color: COLORS.DARK_GRAY,
    fontFamily: "Merriweather, serif",
    fontSize: "4.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_xl_serif_dark_gray_2: {
    color: COLORS.DARK_GRAY_2,
    fontFamily: "Merriweather, serif",
    fontSize: "4.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_xl_serif_error: {
    color: COLORS.ERROR,
    fontFamily: "Merriweather, serif",
    fontSize: "4.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_xl_serif_warning: {
    color: COLORS.WARNING,
    fontFamily: "Merriweather, serif",
    fontSize: "4.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_xl_serif_success: {
    color: COLORS.SUCCESS,
    fontFamily: "Merriweather, serif",
    fontSize: "4.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_xl_serif_primary: {
    color: COLORS.PRIMARY,
    fontFamily: "Merriweather, serif",
    fontSize: "4.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_xl_serif_primary_lightest: {
    color: COLORS.PRIMARY_LIGHTEST,
    fontFamily: "Merriweather, serif",
    fontSize: "4.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_xl_serif_primary_lighter: {
    color: COLORS.PRIMARY_LIGHTER,
    fontFamily: "Merriweather, serif",
    fontSize: "4.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_xl_serif_primary_light: {
    color: COLORS.PRIMARY_LIGHT,
    fontFamily: "Merriweather, serif",
    fontSize: "4.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_xl_serif_primary_dark: {
    color: COLORS.PRIMARY_DARK,
    fontFamily: "Merriweather, serif",
    fontSize: "4.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_xl_serif_primary_darker: {
    color: COLORS.PRIMARY_DARKER,
    fontFamily: "Merriweather, serif",
    fontSize: "4.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_xl_serif_primary_darkest: {
    color: COLORS.PRIMARY_DARKEST,
    fontFamily: "Merriweather, serif",
    fontSize: "4.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_xl_serif_secondary: {
    color: COLORS.SECONDARY,
    fontFamily: "Merriweather, serif",
    fontSize: "4.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_xl_serif_secondary_lightest: {
    color: COLORS.SECONDARY_LIGHTEST,
    fontFamily: "Merriweather, serif",
    fontSize: "4.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_xl_serif_secondary_lighter: {
    color: COLORS.SECONDARY_LIGHTER,
    fontFamily: "Merriweather, serif",
    fontSize: "4.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_xl_serif_secondary_light: {
    color: COLORS.SECONDARY_LIGHT,
    fontFamily: "Merriweather, serif",
    fontSize: "4.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_xl_serif_secondary_dark: {
    color: COLORS.SECONDARY_DARK,
    fontFamily: "Merriweather, serif",
    fontSize: "4.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_xl_serif_secondary_darker: {
    color: COLORS.SECONDARY_DARKER,
    fontFamily: "Merriweather, serif",
    fontSize: "4.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_xl_serif_secondary_darkest: {
    color: COLORS.SECONDARY_DARKEST,
    fontFamily: "Merriweather, serif",
    fontSize: "4.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_xl_serif_accent1: {
    color: COLORS.ACCENT1,
    fontFamily: "Merriweather, serif",
    fontSize: "4.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_xl_serif_accent1_lightest: {
    color: COLORS.ACCENT1_LIGHTEST,
    fontFamily: "Merriweather, serif",
    fontSize: "4.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_xl_serif_accent1_lighter: {
    color: COLORS.ACCENT1_LIGHTER,
    fontFamily: "Merriweather, serif",
    fontSize: "4.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_xl_serif_accent1_light: {
    color: COLORS.ACCENT1_LIGHT,
    fontFamily: "Merriweather, serif",
    fontSize: "4.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_xl_serif_accent1_dark: {
    color: COLORS.ACCENT1_DARK,
    fontFamily: "Merriweather, serif",
    fontSize: "4.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_xl_serif_accent1_darker: {
    color: COLORS.ACCENT1_DARKER,
    fontFamily: "Merriweather, serif",
    fontSize: "4.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_xl_serif_accent1_darkest: {
    color: COLORS.ACCENT1_DARKEST,
    fontFamily: "Merriweather, serif",
    fontSize: "4.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_xl_serif_accent2: {
    color: COLORS.ACCENT2,
    fontFamily: "Merriweather, serif",
    fontSize: "4.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_xl_serif_accent2_lightest: {
    color: COLORS.ACCENT2_LIGHTEST,
    fontFamily: "Merriweather, serif",
    fontSize: "4.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_xl_serif_accent2_lighter: {
    color: COLORS.ACCENT2_LIGHTER,
    fontFamily: "Merriweather, serif",
    fontSize: "4.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_xl_serif_accent2_light: {
    color: COLORS.ACCENT2_LIGHT,
    fontFamily: "Merriweather, serif",
    fontSize: "4.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_xl_serif_accent2_dark: {
    color: COLORS.ACCENT2_DARK,
    fontFamily: "Merriweather, serif",
    fontSize: "4.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_xl_serif_accent2_darker: {
    color: COLORS.ACCENT2_DARKER,
    fontFamily: "Merriweather, serif",
    fontSize: "4.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_xl_serif_accent2_darkest: {
    color: COLORS.ACCENT2_DARKEST,
    fontFamily: "Merriweather, serif",
    fontSize: "4.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_xl_serif_accent3: {
    color: COLORS.ACCENT3,
    fontFamily: "Merriweather, serif",
    fontSize: "4.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_xl_serif_accent3_lightest: {
    color: COLORS.ACCENT3_LIGHTEST,
    fontFamily: "Merriweather, serif",
    fontSize: "4.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_xl_serif_accent3_lighter: {
    color: COLORS.ACCENT3_LIGHTER,
    fontFamily: "Merriweather, serif",
    fontSize: "4.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_xl_serif_accent3_light: {
    color: COLORS.ACCENT3_LIGHT,
    fontFamily: "Merriweather, serif",
    fontSize: "4.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_xl_serif_accent3_dark: {
    color: COLORS.ACCENT3_DARK,
    fontFamily: "Merriweather, serif",
    fontSize: "4.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_xl_serif_accent3_darker: {
    color: COLORS.ACCENT3_DARKER,
    fontFamily: "Merriweather, serif",
    fontSize: "4.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_xl_serif_accent3_darkest: {
    color: COLORS.ACCENT3_DARKEST,
    fontFamily: "Merriweather, serif",
    fontSize: "4.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_xl_serif_accent4: {
    color: COLORS.ACCENT4,
    fontFamily: "Merriweather, serif",
    fontSize: "4.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_xl_serif_accent4_lightest: {
    color: COLORS.ACCENT4_LIGHTEST,
    fontFamily: "Merriweather, serif",
    fontSize: "4.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_xl_serif_accent4_lighter: {
    color: COLORS.ACCENT4_LIGHTER,
    fontFamily: "Merriweather, serif",
    fontSize: "4.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_xl_serif_accent4_light: {
    color: COLORS.ACCENT4_LIGHT,
    fontFamily: "Merriweather, serif",
    fontSize: "4.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_xl_serif_accent4_dark: {
    color: COLORS.ACCENT4_DARK,
    fontFamily: "Merriweather, serif",
    fontSize: "4.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_xl_serif_accent4_darker: {
    color: COLORS.ACCENT4_DARKER,
    fontFamily: "Merriweather, serif",
    fontSize: "4.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_xl_serif_accent4_darkest: {
    color: COLORS.ACCENT4_DARKEST,
    fontFamily: "Merriweather, serif",
    fontSize: "4.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_xl_serif: {
    color: COLORS.BLACK,
    fontFamily: "Merriweather, serif",
    fontSize: "4.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_xl_white: {
    color: COLORS.WHITE,
    fontFamily: "Inter, sans-serif",
    fontSize: "4.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_xl_black: {
    color: COLORS.BLACK,
    fontFamily: "Inter, sans-serif",
    fontSize: "4.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_xl_gray: {
    color: COLORS.GRAY,
    fontFamily: "Inter, sans-serif",
    fontSize: "4.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_xl_green_2: {
    color: COLORS.GREEN_2,
    fontFamily: "Inter, sans-serif",
    fontSize: "4.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_xl_dark_gray: {
    color: COLORS.DARK_GRAY,
    fontFamily: "Inter, sans-serif",
    fontSize: "4.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_xl_dark_gray_2: {
    color: COLORS.DARK_GRAY_2,
    fontFamily: "Inter, sans-serif",
    fontSize: "4.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_xl_error: {
    color: COLORS.ERROR,
    fontFamily: "Inter, sans-serif",
    fontSize: "4.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_xl_warning: {
    color: COLORS.WARNING,
    fontFamily: "Inter, sans-serif",
    fontSize: "4.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_xl_success: {
    color: COLORS.SUCCESS,
    fontFamily: "Inter, sans-serif",
    fontSize: "4.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_xl_primary: {
    color: COLORS.PRIMARY,
    fontFamily: "Inter, sans-serif",
    fontSize: "4.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_xl_primary_lightest: {
    color: COLORS.PRIMARY_LIGHTEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "4.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_xl_primary_lighter: {
    color: COLORS.PRIMARY_LIGHTER,
    fontFamily: "Inter, sans-serif",
    fontSize: "4.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_xl_primary_light: {
    color: COLORS.PRIMARY_LIGHT,
    fontFamily: "Inter, sans-serif",
    fontSize: "4.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_xl_primary_dark: {
    color: COLORS.PRIMARY_DARK,
    fontFamily: "Inter, sans-serif",
    fontSize: "4.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_xl_primary_darker: {
    color: COLORS.PRIMARY_DARKER,
    fontFamily: "Inter, sans-serif",
    fontSize: "4.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_xl_primary_darkest: {
    color: COLORS.PRIMARY_DARKEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "4.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_xl_secondary: {
    color: COLORS.SECONDARY,
    fontFamily: "Inter, sans-serif",
    fontSize: "4.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_xl_secondary_lightest: {
    color: COLORS.SECONDARY_LIGHTEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "4.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_xl_secondary_lighter: {
    color: COLORS.SECONDARY_LIGHTER,
    fontFamily: "Inter, sans-serif",
    fontSize: "4.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_xl_secondary_light: {
    color: COLORS.SECONDARY_LIGHT,
    fontFamily: "Inter, sans-serif",
    fontSize: "4.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_xl_secondary_dark: {
    color: COLORS.SECONDARY_DARK,
    fontFamily: "Inter, sans-serif",
    fontSize: "4.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_xl_secondary_darker: {
    color: COLORS.SECONDARY_DARKER,
    fontFamily: "Inter, sans-serif",
    fontSize: "4.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_xl_secondary_darkest: {
    color: COLORS.SECONDARY_DARKEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "4.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_xl_accent1: {
    color: COLORS.ACCENT1,
    fontFamily: "Inter, sans-serif",
    fontSize: "4.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_xl_accent1_lightest: {
    color: COLORS.ACCENT1_LIGHTEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "4.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_xl_accent1_lighter: {
    color: COLORS.ACCENT1_LIGHTER,
    fontFamily: "Inter, sans-serif",
    fontSize: "4.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_xl_accent1_light: {
    color: COLORS.ACCENT1_LIGHT,
    fontFamily: "Inter, sans-serif",
    fontSize: "4.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_xl_accent1_dark: {
    color: COLORS.ACCENT1_DARK,
    fontFamily: "Inter, sans-serif",
    fontSize: "4.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_xl_accent1_darker: {
    color: COLORS.ACCENT1_DARKER,
    fontFamily: "Inter, sans-serif",
    fontSize: "4.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_xl_accent1_darkest: {
    color: COLORS.ACCENT1_DARKEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "4.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_xl_accent2: {
    color: COLORS.ACCENT2,
    fontFamily: "Inter, sans-serif",
    fontSize: "4.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_xl_accent2_lightest: {
    color: COLORS.ACCENT2_LIGHTEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "4.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_xl_accent2_lighter: {
    color: COLORS.ACCENT2_LIGHTER,
    fontFamily: "Inter, sans-serif",
    fontSize: "4.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_xl_accent2_light: {
    color: COLORS.ACCENT2_LIGHT,
    fontFamily: "Inter, sans-serif",
    fontSize: "4.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_xl_accent2_dark: {
    color: COLORS.ACCENT2_DARK,
    fontFamily: "Inter, sans-serif",
    fontSize: "4.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_xl_accent2_darker: {
    color: COLORS.ACCENT2_DARKER,
    fontFamily: "Inter, sans-serif",
    fontSize: "4.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_xl_accent2_darkest: {
    color: COLORS.ACCENT2_DARKEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "4.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_xl_accent3: {
    color: COLORS.ACCENT3,
    fontFamily: "Inter, sans-serif",
    fontSize: "4.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_xl_accent3_lightest: {
    color: COLORS.ACCENT3_LIGHTEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "4.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_xl_accent3_lighter: {
    color: COLORS.ACCENT3_LIGHTER,
    fontFamily: "Inter, sans-serif",
    fontSize: "4.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_xl_accent3_light: {
    color: COLORS.ACCENT3_LIGHT,
    fontFamily: "Inter, sans-serif",
    fontSize: "4.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_xl_accent3_dark: {
    color: COLORS.ACCENT3_DARK,
    fontFamily: "Inter, sans-serif",
    fontSize: "4.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_xl_accent3_darker: {
    color: COLORS.ACCENT3_DARKER,
    fontFamily: "Inter, sans-serif",
    fontSize: "4.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_xl_accent3_darkest: {
    color: COLORS.ACCENT3_DARKEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "4.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_xl_accent4: {
    color: COLORS.ACCENT4,
    fontFamily: "Inter, sans-serif",
    fontSize: "4.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_xl_accent4_lightest: {
    color: COLORS.ACCENT4_LIGHTEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "4.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_xl_accent4_lighter: {
    color: COLORS.ACCENT4_LIGHTER,
    fontFamily: "Inter, sans-serif",
    fontSize: "4.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_xl_accent4_light: {
    color: COLORS.ACCENT4_LIGHT,
    fontFamily: "Inter, sans-serif",
    fontSize: "4.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_xl_accent4_dark: {
    color: COLORS.ACCENT4_DARK,
    fontFamily: "Inter, sans-serif",
    fontSize: "4.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_xl_accent4_darker: {
    color: COLORS.ACCENT4_DARKER,
    fontFamily: "Inter, sans-serif",
    fontSize: "4.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_xl_accent4_darkest: {
    color: COLORS.ACCENT4_DARKEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "4.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_xl: {
    color: COLORS.BLACK,
    fontFamily: "Inter, sans-serif",
    fontSize: "4.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_serif_white: {
    color: COLORS.WHITE,
    fontFamily: "Merriweather, serif",
    fontSize: "4.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_serif_black: {
    color: COLORS.BLACK,
    fontFamily: "Merriweather, serif",
    fontSize: "4.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_serif_gray: {
    color: COLORS.GRAY,
    fontFamily: "Merriweather, serif",
    fontSize: "4.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_serif_green_2: {
    color: COLORS.GREEN_2,
    fontFamily: "Merriweather, serif",
    fontSize: "4.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_serif_dark_gray: {
    color: COLORS.DARK_GRAY,
    fontFamily: "Merriweather, serif",
    fontSize: "4.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_serif_dark_gray_2: {
    color: COLORS.DARK_GRAY_2,
    fontFamily: "Merriweather, serif",
    fontSize: "4.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_serif_error: {
    color: COLORS.ERROR,
    fontFamily: "Merriweather, serif",
    fontSize: "4.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_serif_warning: {
    color: COLORS.WARNING,
    fontFamily: "Merriweather, serif",
    fontSize: "4.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_serif_success: {
    color: COLORS.SUCCESS,
    fontFamily: "Merriweather, serif",
    fontSize: "4.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_serif_primary: {
    color: COLORS.PRIMARY,
    fontFamily: "Merriweather, serif",
    fontSize: "4.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_serif_primary_lightest: {
    color: COLORS.PRIMARY_LIGHTEST,
    fontFamily: "Merriweather, serif",
    fontSize: "4.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_serif_primary_lighter: {
    color: COLORS.PRIMARY_LIGHTER,
    fontFamily: "Merriweather, serif",
    fontSize: "4.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_serif_primary_light: {
    color: COLORS.PRIMARY_LIGHT,
    fontFamily: "Merriweather, serif",
    fontSize: "4.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_serif_primary_dark: {
    color: COLORS.PRIMARY_DARK,
    fontFamily: "Merriweather, serif",
    fontSize: "4.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_serif_primary_darker: {
    color: COLORS.PRIMARY_DARKER,
    fontFamily: "Merriweather, serif",
    fontSize: "4.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_serif_primary_darkest: {
    color: COLORS.PRIMARY_DARKEST,
    fontFamily: "Merriweather, serif",
    fontSize: "4.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_serif_secondary: {
    color: COLORS.SECONDARY,
    fontFamily: "Merriweather, serif",
    fontSize: "4.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_serif_secondary_lightest: {
    color: COLORS.SECONDARY_LIGHTEST,
    fontFamily: "Merriweather, serif",
    fontSize: "4.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_serif_secondary_lighter: {
    color: COLORS.SECONDARY_LIGHTER,
    fontFamily: "Merriweather, serif",
    fontSize: "4.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_serif_secondary_light: {
    color: COLORS.SECONDARY_LIGHT,
    fontFamily: "Merriweather, serif",
    fontSize: "4.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_serif_secondary_dark: {
    color: COLORS.SECONDARY_DARK,
    fontFamily: "Merriweather, serif",
    fontSize: "4.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_serif_secondary_darker: {
    color: COLORS.SECONDARY_DARKER,
    fontFamily: "Merriweather, serif",
    fontSize: "4.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_serif_secondary_darkest: {
    color: COLORS.SECONDARY_DARKEST,
    fontFamily: "Merriweather, serif",
    fontSize: "4.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_serif_accent1: {
    color: COLORS.ACCENT1,
    fontFamily: "Merriweather, serif",
    fontSize: "4.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_serif_accent1_lightest: {
    color: COLORS.ACCENT1_LIGHTEST,
    fontFamily: "Merriweather, serif",
    fontSize: "4.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_serif_accent1_lighter: {
    color: COLORS.ACCENT1_LIGHTER,
    fontFamily: "Merriweather, serif",
    fontSize: "4.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_serif_accent1_light: {
    color: COLORS.ACCENT1_LIGHT,
    fontFamily: "Merriweather, serif",
    fontSize: "4.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_serif_accent1_dark: {
    color: COLORS.ACCENT1_DARK,
    fontFamily: "Merriweather, serif",
    fontSize: "4.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_serif_accent1_darker: {
    color: COLORS.ACCENT1_DARKER,
    fontFamily: "Merriweather, serif",
    fontSize: "4.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_serif_accent1_darkest: {
    color: COLORS.ACCENT1_DARKEST,
    fontFamily: "Merriweather, serif",
    fontSize: "4.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_serif_accent2: {
    color: COLORS.ACCENT2,
    fontFamily: "Merriweather, serif",
    fontSize: "4.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_serif_accent2_lightest: {
    color: COLORS.ACCENT2_LIGHTEST,
    fontFamily: "Merriweather, serif",
    fontSize: "4.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_serif_accent2_lighter: {
    color: COLORS.ACCENT2_LIGHTER,
    fontFamily: "Merriweather, serif",
    fontSize: "4.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_serif_accent2_light: {
    color: COLORS.ACCENT2_LIGHT,
    fontFamily: "Merriweather, serif",
    fontSize: "4.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_serif_accent2_dark: {
    color: COLORS.ACCENT2_DARK,
    fontFamily: "Merriweather, serif",
    fontSize: "4.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_serif_accent2_darker: {
    color: COLORS.ACCENT2_DARKER,
    fontFamily: "Merriweather, serif",
    fontSize: "4.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_serif_accent2_darkest: {
    color: COLORS.ACCENT2_DARKEST,
    fontFamily: "Merriweather, serif",
    fontSize: "4.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_serif_accent3: {
    color: COLORS.ACCENT3,
    fontFamily: "Merriweather, serif",
    fontSize: "4.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_serif_accent3_lightest: {
    color: COLORS.ACCENT3_LIGHTEST,
    fontFamily: "Merriweather, serif",
    fontSize: "4.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_serif_accent3_lighter: {
    color: COLORS.ACCENT3_LIGHTER,
    fontFamily: "Merriweather, serif",
    fontSize: "4.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_serif_accent3_light: {
    color: COLORS.ACCENT3_LIGHT,
    fontFamily: "Merriweather, serif",
    fontSize: "4.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_serif_accent3_dark: {
    color: COLORS.ACCENT3_DARK,
    fontFamily: "Merriweather, serif",
    fontSize: "4.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_serif_accent3_darker: {
    color: COLORS.ACCENT3_DARKER,
    fontFamily: "Merriweather, serif",
    fontSize: "4.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_serif_accent3_darkest: {
    color: COLORS.ACCENT3_DARKEST,
    fontFamily: "Merriweather, serif",
    fontSize: "4.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_serif_accent4: {
    color: COLORS.ACCENT4,
    fontFamily: "Merriweather, serif",
    fontSize: "4.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_serif_accent4_lightest: {
    color: COLORS.ACCENT4_LIGHTEST,
    fontFamily: "Merriweather, serif",
    fontSize: "4.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_serif_accent4_lighter: {
    color: COLORS.ACCENT4_LIGHTER,
    fontFamily: "Merriweather, serif",
    fontSize: "4.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_serif_accent4_light: {
    color: COLORS.ACCENT4_LIGHT,
    fontFamily: "Merriweather, serif",
    fontSize: "4.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_serif_accent4_dark: {
    color: COLORS.ACCENT4_DARK,
    fontFamily: "Merriweather, serif",
    fontSize: "4.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_serif_accent4_darker: {
    color: COLORS.ACCENT4_DARKER,
    fontFamily: "Merriweather, serif",
    fontSize: "4.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_serif_accent4_darkest: {
    color: COLORS.ACCENT4_DARKEST,
    fontFamily: "Merriweather, serif",
    fontSize: "4.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_serif: {
    color: COLORS.BLACK,
    fontFamily: "Merriweather, serif",
    fontSize: "4.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_white: {
    color: COLORS.WHITE,
    fontFamily: "Inter, sans-serif",
    fontSize: "4.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_black: {
    color: COLORS.BLACK,
    fontFamily: "Inter, sans-serif",
    fontSize: "4.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_gray: {
    color: COLORS.GRAY,
    fontFamily: "Inter, sans-serif",
    fontSize: "4.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_green_2: {
    color: COLORS.GREEN_2,
    fontFamily: "Inter, sans-serif",
    fontSize: "4.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_dark_gray: {
    color: COLORS.DARK_GRAY,
    fontFamily: "Inter, sans-serif",
    fontSize: "4.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_dark_gray_2: {
    color: COLORS.DARK_GRAY_2,
    fontFamily: "Inter, sans-serif",
    fontSize: "4.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_error: {
    color: COLORS.ERROR,
    fontFamily: "Inter, sans-serif",
    fontSize: "4.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_warning: {
    color: COLORS.WARNING,
    fontFamily: "Inter, sans-serif",
    fontSize: "4.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_success: {
    color: COLORS.SUCCESS,
    fontFamily: "Inter, sans-serif",
    fontSize: "4.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_primary: {
    color: COLORS.PRIMARY,
    fontFamily: "Inter, sans-serif",
    fontSize: "4.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_primary_lightest: {
    color: COLORS.PRIMARY_LIGHTEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "4.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_primary_lighter: {
    color: COLORS.PRIMARY_LIGHTER,
    fontFamily: "Inter, sans-serif",
    fontSize: "4.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_primary_light: {
    color: COLORS.PRIMARY_LIGHT,
    fontFamily: "Inter, sans-serif",
    fontSize: "4.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_primary_dark: {
    color: COLORS.PRIMARY_DARK,
    fontFamily: "Inter, sans-serif",
    fontSize: "4.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_primary_darker: {
    color: COLORS.PRIMARY_DARKER,
    fontFamily: "Inter, sans-serif",
    fontSize: "4.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_primary_darkest: {
    color: COLORS.PRIMARY_DARKEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "4.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_secondary: {
    color: COLORS.SECONDARY,
    fontFamily: "Inter, sans-serif",
    fontSize: "4.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_secondary_lightest: {
    color: COLORS.SECONDARY_LIGHTEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "4.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_secondary_lighter: {
    color: COLORS.SECONDARY_LIGHTER,
    fontFamily: "Inter, sans-serif",
    fontSize: "4.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_secondary_light: {
    color: COLORS.SECONDARY_LIGHT,
    fontFamily: "Inter, sans-serif",
    fontSize: "4.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_secondary_dark: {
    color: COLORS.SECONDARY_DARK,
    fontFamily: "Inter, sans-serif",
    fontSize: "4.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_secondary_darker: {
    color: COLORS.SECONDARY_DARKER,
    fontFamily: "Inter, sans-serif",
    fontSize: "4.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_secondary_darkest: {
    color: COLORS.SECONDARY_DARKEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "4.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_accent1: {
    color: COLORS.ACCENT1,
    fontFamily: "Inter, sans-serif",
    fontSize: "4.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_accent1_lightest: {
    color: COLORS.ACCENT1_LIGHTEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "4.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_accent1_lighter: {
    color: COLORS.ACCENT1_LIGHTER,
    fontFamily: "Inter, sans-serif",
    fontSize: "4.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_accent1_light: {
    color: COLORS.ACCENT1_LIGHT,
    fontFamily: "Inter, sans-serif",
    fontSize: "4.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_accent1_dark: {
    color: COLORS.ACCENT1_DARK,
    fontFamily: "Inter, sans-serif",
    fontSize: "4.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_accent1_darker: {
    color: COLORS.ACCENT1_DARKER,
    fontFamily: "Inter, sans-serif",
    fontSize: "4.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_accent1_darkest: {
    color: COLORS.ACCENT1_DARKEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "4.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_accent2: {
    color: COLORS.ACCENT2,
    fontFamily: "Inter, sans-serif",
    fontSize: "4.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_accent2_lightest: {
    color: COLORS.ACCENT2_LIGHTEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "4.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_accent2_lighter: {
    color: COLORS.ACCENT2_LIGHTER,
    fontFamily: "Inter, sans-serif",
    fontSize: "4.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_accent2_light: {
    color: COLORS.ACCENT2_LIGHT,
    fontFamily: "Inter, sans-serif",
    fontSize: "4.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_accent2_dark: {
    color: COLORS.ACCENT2_DARK,
    fontFamily: "Inter, sans-serif",
    fontSize: "4.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_accent2_darker: {
    color: COLORS.ACCENT2_DARKER,
    fontFamily: "Inter, sans-serif",
    fontSize: "4.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_accent2_darkest: {
    color: COLORS.ACCENT2_DARKEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "4.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_accent3: {
    color: COLORS.ACCENT3,
    fontFamily: "Inter, sans-serif",
    fontSize: "4.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_accent3_lightest: {
    color: COLORS.ACCENT3_LIGHTEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "4.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_accent3_lighter: {
    color: COLORS.ACCENT3_LIGHTER,
    fontFamily: "Inter, sans-serif",
    fontSize: "4.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_accent3_light: {
    color: COLORS.ACCENT3_LIGHT,
    fontFamily: "Inter, sans-serif",
    fontSize: "4.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_accent3_dark: {
    color: COLORS.ACCENT3_DARK,
    fontFamily: "Inter, sans-serif",
    fontSize: "4.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_accent3_darker: {
    color: COLORS.ACCENT3_DARKER,
    fontFamily: "Inter, sans-serif",
    fontSize: "4.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_accent3_darkest: {
    color: COLORS.ACCENT3_DARKEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "4.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_accent4: {
    color: COLORS.ACCENT4,
    fontFamily: "Inter, sans-serif",
    fontSize: "4.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_accent4_lightest: {
    color: COLORS.ACCENT4_LIGHTEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "4.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_accent4_lighter: {
    color: COLORS.ACCENT4_LIGHTER,
    fontFamily: "Inter, sans-serif",
    fontSize: "4.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_accent4_light: {
    color: COLORS.ACCENT4_LIGHT,
    fontFamily: "Inter, sans-serif",
    fontSize: "4.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_accent4_dark: {
    color: COLORS.ACCENT4_DARK,
    fontFamily: "Inter, sans-serif",
    fontSize: "4.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_accent4_darker: {
    color: COLORS.ACCENT4_DARKER,
    fontFamily: "Inter, sans-serif",
    fontSize: "4.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1_accent4_darkest: {
    color: COLORS.ACCENT4_DARKEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "4.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h1: {
    color: COLORS.BLACK,
    fontFamily: "Inter, sans-serif",
    fontSize: "4.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h2_serif_white: {
    color: COLORS.WHITE,
    fontFamily: "Merriweather, serif",
    fontSize: "3.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%",
    "@media (max-width:600px)": {
      fontSize: "2.5rem"
    }
  },
  h2_serif_black: {
    color: COLORS.BLACK,
    fontFamily: "Merriweather, serif",
    fontSize: "3.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%",
    "@media (max-width:600px)": {
      fontSize: "2.5rem"
    }
  },
  h2_serif_gray: {
    color: COLORS.GRAY,
    fontFamily: "Merriweather, serif",
    fontSize: "3.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%",
    "@media (max-width:600px)": {
      fontSize: "2.5rem"
    }
  },
  h2_serif_green_2: {
    color: COLORS.GREEN_2,
    fontFamily: "Merriweather, serif",
    fontSize: "3.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%",
    "@media (max-width:600px)": {
      fontSize: "2.5rem"
    }
  },
  h2_serif_dark_gray: {
    color: COLORS.DARK_GRAY,
    fontFamily: "Merriweather, serif",
    fontSize: "3.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%",
    "@media (max-width:600px)": {
      fontSize: "2.5rem"
    }
  },
  h2_serif_dark_gray_2: {
    color: COLORS.DARK_GRAY_2,
    fontFamily: "Merriweather, serif",
    fontSize: "3.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%",
    "@media (max-width:600px)": {
      fontSize: "2.5rem"
    }
  },
  h2_serif_error: {
    color: COLORS.ERROR,
    fontFamily: "Merriweather, serif",
    fontSize: "3.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%",
    "@media (max-width:600px)": {
      fontSize: "2.5rem"
    }
  },
  h2_serif_warning: {
    color: COLORS.WARNING,
    fontFamily: "Merriweather, serif",
    fontSize: "3.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%",
    "@media (max-width:600px)": {
      fontSize: "2.5rem"
    }
  },
  h2_serif_success: {
    color: COLORS.SUCCESS,
    fontFamily: "Merriweather, serif",
    fontSize: "3.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%",
    "@media (max-width:600px)": {
      fontSize: "2.5rem"
    }
  },
  h2_serif_primary: {
    color: COLORS.PRIMARY,
    fontFamily: "Merriweather, serif",
    fontSize: "3.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%",
    "@media (max-width:600px)": {
      fontSize: "2.5rem"
    }
  },
  h2_serif_primary_lightest: {
    color: COLORS.PRIMARY_LIGHTEST,
    fontFamily: "Merriweather, serif",
    fontSize: "3.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%",
    "@media (max-width:600px)": {
      fontSize: "2.5rem"
    }
  },
  h2_serif_primary_lighter: {
    color: COLORS.PRIMARY_LIGHTER,
    fontFamily: "Merriweather, serif",
    fontSize: "3.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%",
    "@media (max-width:600px)": {
      fontSize: "2.5rem"
    }
  },
  h2_serif_primary_light: {
    color: COLORS.PRIMARY_LIGHT,
    fontFamily: "Merriweather, serif",
    fontSize: "3.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%",
    "@media (max-width:600px)": {
      fontSize: "2.5rem"
    }
  },
  h2_serif_primary_dark: {
    color: COLORS.PRIMARY_DARK,
    fontFamily: "Merriweather, serif",
    fontSize: "3.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%",
    "@media (max-width:600px)": {
      fontSize: "2.5rem"
    }
  },
  h2_serif_primary_darker: {
    color: COLORS.PRIMARY_DARKER,
    fontFamily: "Merriweather, serif",
    fontSize: "3.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%",
    "@media (max-width:600px)": {
      fontSize: "2.5rem"
    }
  },
  h2_serif_primary_darkest: {
    color: COLORS.PRIMARY_DARKEST,
    fontFamily: "Merriweather, serif",
    fontSize: "3.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%",
    "@media (max-width:600px)": {
      fontSize: "2.5rem"
    }
  },
  h2_serif_secondary: {
    color: COLORS.SECONDARY,
    fontFamily: "Merriweather, serif",
    fontSize: "3.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%",
    "@media (max-width:600px)": {
      fontSize: "2.5rem"
    }
  },
  h2_serif_secondary_lightest: {
    color: COLORS.SECONDARY_LIGHTEST,
    fontFamily: "Merriweather, serif",
    fontSize: "3.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%",
    "@media (max-width:600px)": {
      fontSize: "2.5rem"
    }
  },
  h2_serif_secondary_lighter: {
    color: COLORS.SECONDARY_LIGHTER,
    fontFamily: "Merriweather, serif",
    fontSize: "3.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%",
    "@media (max-width:600px)": {
      fontSize: "2.5rem"
    }
  },
  h2_serif_secondary_light: {
    color: COLORS.SECONDARY_LIGHT,
    fontFamily: "Merriweather, serif",
    fontSize: "3.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%",
    "@media (max-width:600px)": {
      fontSize: "2.5rem"
    }
  },
  h2_serif_secondary_dark: {
    color: COLORS.SECONDARY_DARK,
    fontFamily: "Merriweather, serif",
    fontSize: "3.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%",
    "@media (max-width:600px)": {
      fontSize: "2.5rem"
    }
  },
  h2_serif_secondary_darker: {
    color: COLORS.SECONDARY_DARKER,
    fontFamily: "Merriweather, serif",
    fontSize: "3.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%",
    "@media (max-width:600px)": {
      fontSize: "2.5rem"
    }
  },
  h2_serif_secondary_darkest: {
    color: COLORS.SECONDARY_DARKEST,
    fontFamily: "Merriweather, serif",
    fontSize: "3.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%",
    "@media (max-width:600px)": {
      fontSize: "2.5rem"
    }
  },
  h2_serif_accent1: {
    color: COLORS.ACCENT1,
    fontFamily: "Merriweather, serif",
    fontSize: "3.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%",
    "@media (max-width:600px)": {
      fontSize: "2.5rem"
    }
  },
  h2_serif_accent1_lightest: {
    color: COLORS.ACCENT1_LIGHTEST,
    fontFamily: "Merriweather, serif",
    fontSize: "3.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%",
    "@media (max-width:600px)": {
      fontSize: "2.5rem"
    }
  },
  h2_serif_accent1_lighter: {
    color: COLORS.ACCENT1_LIGHTER,
    fontFamily: "Merriweather, serif",
    fontSize: "3.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%",
    "@media (max-width:600px)": {
      fontSize: "2.5rem"
    }
  },
  h2_serif_accent1_light: {
    color: COLORS.ACCENT1_LIGHT,
    fontFamily: "Merriweather, serif",
    fontSize: "3.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%",
    "@media (max-width:600px)": {
      fontSize: "2.5rem"
    }
  },
  h2_serif_accent1_dark: {
    color: COLORS.ACCENT1_DARK,
    fontFamily: "Merriweather, serif",
    fontSize: "3.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%",
    "@media (max-width:600px)": {
      fontSize: "2.5rem"
    }
  },
  h2_serif_accent1_darker: {
    color: COLORS.ACCENT1_DARKER,
    fontFamily: "Merriweather, serif",
    fontSize: "3.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%",
    "@media (max-width:600px)": {
      fontSize: "2.5rem"
    }
  },
  h2_serif_accent1_darkest: {
    color: COLORS.ACCENT1_DARKEST,
    fontFamily: "Merriweather, serif",
    fontSize: "3.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%",
    "@media (max-width:600px)": {
      fontSize: "2.5rem"
    }
  },
  h2_serif_accent2: {
    color: COLORS.ACCENT2,
    fontFamily: "Merriweather, serif",
    fontSize: "3.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%",
    "@media (max-width:600px)": {
      fontSize: "2.5rem"
    }
  },
  h2_serif_accent2_lightest: {
    color: COLORS.ACCENT2_LIGHTEST,
    fontFamily: "Merriweather, serif",
    fontSize: "3.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%",
    "@media (max-width:600px)": {
      fontSize: "2.5rem"
    }
  },
  h2_serif_accent2_lighter: {
    color: COLORS.ACCENT2_LIGHTER,
    fontFamily: "Merriweather, serif",
    fontSize: "3.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%",
    "@media (max-width:600px)": {
      fontSize: "2.5rem"
    }
  },
  h2_serif_accent2_light: {
    color: COLORS.ACCENT2_LIGHT,
    fontFamily: "Merriweather, serif",
    fontSize: "3.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%",
    "@media (max-width:600px)": {
      fontSize: "2.5rem"
    }
  },
  h2_serif_accent2_dark: {
    color: COLORS.ACCENT2_DARK,
    fontFamily: "Merriweather, serif",
    fontSize: "3.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%",
    "@media (max-width:600px)": {
      fontSize: "2.5rem"
    }
  },
  h2_serif_accent2_darker: {
    color: COLORS.ACCENT2_DARKER,
    fontFamily: "Merriweather, serif",
    fontSize: "3.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%",
    "@media (max-width:600px)": {
      fontSize: "2.5rem"
    }
  },
  h2_serif_accent2_darkest: {
    color: COLORS.ACCENT2_DARKEST,
    fontFamily: "Merriweather, serif",
    fontSize: "3.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%",
    "@media (max-width:600px)": {
      fontSize: "2.5rem"
    }
  },
  h2_serif_accent3: {
    color: COLORS.ACCENT3,
    fontFamily: "Merriweather, serif",
    fontSize: "3.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%",
    "@media (max-width:600px)": {
      fontSize: "2.5rem"
    }
  },
  h2_serif_accent3_lightest: {
    color: COLORS.ACCENT3_LIGHTEST,
    fontFamily: "Merriweather, serif",
    fontSize: "3.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%",
    "@media (max-width:600px)": {
      fontSize: "2.5rem"
    }
  },
  h2_serif_accent3_lighter: {
    color: COLORS.ACCENT3_LIGHTER,
    fontFamily: "Merriweather, serif",
    fontSize: "3.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%",
    "@media (max-width:600px)": {
      fontSize: "2.5rem"
    }
  },
  h2_serif_accent3_light: {
    color: COLORS.ACCENT3_LIGHT,
    fontFamily: "Merriweather, serif",
    fontSize: "3.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%",
    "@media (max-width:600px)": {
      fontSize: "2.5rem"
    }
  },
  h2_serif_accent3_dark: {
    color: COLORS.ACCENT3_DARK,
    fontFamily: "Merriweather, serif",
    fontSize: "3.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%",
    "@media (max-width:600px)": {
      fontSize: "2.5rem"
    }
  },
  h2_serif_accent3_darker: {
    color: COLORS.ACCENT3_DARKER,
    fontFamily: "Merriweather, serif",
    fontSize: "3.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%",
    "@media (max-width:600px)": {
      fontSize: "2.5rem"
    }
  },
  h2_serif_accent3_darkest: {
    color: COLORS.ACCENT3_DARKEST,
    fontFamily: "Merriweather, serif",
    fontSize: "3.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%",
    "@media (max-width:600px)": {
      fontSize: "2.5rem"
    }
  },
  h2_serif_accent4: {
    color: COLORS.ACCENT4,
    fontFamily: "Merriweather, serif",
    fontSize: "3.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%",
    "@media (max-width:600px)": {
      fontSize: "2.5rem"
    }
  },
  h2_serif_accent4_lightest: {
    color: COLORS.ACCENT4_LIGHTEST,
    fontFamily: "Merriweather, serif",
    fontSize: "3.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%",
    "@media (max-width:600px)": {
      fontSize: "2.5rem"
    }
  },
  h2_serif_accent4_lighter: {
    color: COLORS.ACCENT4_LIGHTER,
    fontFamily: "Merriweather, serif",
    fontSize: "3.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%",
    "@media (max-width:600px)": {
      fontSize: "2.5rem"
    }
  },
  h2_serif_accent4_light: {
    color: COLORS.ACCENT4_LIGHT,
    fontFamily: "Merriweather, serif",
    fontSize: "3.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%",
    "@media (max-width:600px)": {
      fontSize: "2.5rem"
    }
  },
  h2_serif_accent4_dark: {
    color: COLORS.ACCENT4_DARK,
    fontFamily: "Merriweather, serif",
    fontSize: "3.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%",
    "@media (max-width:600px)": {
      fontSize: "2.5rem"
    }
  },
  h2_serif_accent4_darker: {
    color: COLORS.ACCENT4_DARKER,
    fontFamily: "Merriweather, serif",
    fontSize: "3.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%",
    "@media (max-width:600px)": {
      fontSize: "2.5rem"
    }
  },
  h2_serif_accent4_darkest: {
    color: COLORS.ACCENT4_DARKEST,
    fontFamily: "Merriweather, serif",
    fontSize: "3.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%",
    "@media (max-width:600px)": {
      fontSize: "2.5rem"
    }
  },
  h2_serif: {
    color: COLORS.BLACK,
    fontFamily: "Merriweather, serif",
    fontSize: "3.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%",
    "@media (max-width:600px)": {
      fontSize: "2.5rem"
    }
  },
  h2_white: {
    color: COLORS.WHITE,
    fontFamily: "Inter, sans-serif",
    fontSize: "3.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%",
    "@media (max-width:600px)": {
      fontSize: "2.5rem"
    }
  },
  h2_black: {
    color: COLORS.BLACK,
    fontFamily: "Inter, sans-serif",
    fontSize: "3.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%",
    "@media (max-width:600px)": {
      fontSize: "2.5rem"
    }
  },
  h2_gray: {
    color: COLORS.GRAY,
    fontFamily: "Inter, sans-serif",
    fontSize: "3.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%",
    "@media (max-width:600px)": {
      fontSize: "2.5rem"
    }
  },
  h2_green_2: {
    color: COLORS.GREEN_2,
    fontFamily: "Inter, sans-serif",
    fontSize: "3.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%",
    "@media (max-width:600px)": {
      fontSize: "2.5rem"
    }
  },
  h2_dark_gray: {
    color: COLORS.DARK_GRAY,
    fontFamily: "Inter, sans-serif",
    fontSize: "3.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%",
    "@media (max-width:600px)": {
      fontSize: "2.5rem"
    }
  },
  h2_dark_gray_2: {
    color: COLORS.DARK_GRAY_2,
    fontFamily: "Inter, sans-serif",
    fontSize: "3.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%",
    "@media (max-width:600px)": {
      fontSize: "2.5rem"
    }
  },
  h2_error: {
    color: COLORS.ERROR,
    fontFamily: "Inter, sans-serif",
    fontSize: "3.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%",
    "@media (max-width:600px)": {
      fontSize: "2.5rem"
    }
  },
  h2_warning: {
    color: COLORS.WARNING,
    fontFamily: "Inter, sans-serif",
    fontSize: "3.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%",
    "@media (max-width:600px)": {
      fontSize: "2.5rem"
    }
  },
  h2_success: {
    color: COLORS.SUCCESS,
    fontFamily: "Inter, sans-serif",
    fontSize: "3.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%",
    "@media (max-width:600px)": {
      fontSize: "2.5rem"
    }
  },
  h2_primary: {
    color: COLORS.PRIMARY,
    fontFamily: "Inter, sans-serif",
    fontSize: "3.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%",
    "@media (max-width:600px)": {
      fontSize: "2.5rem"
    }
  },
  h2_primary_lightest: {
    color: COLORS.PRIMARY_LIGHTEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "3.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%",
    "@media (max-width:600px)": {
      fontSize: "2.5rem"
    }
  },
  h2_primary_lighter: {
    color: COLORS.PRIMARY_LIGHTER,
    fontFamily: "Inter, sans-serif",
    fontSize: "3.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%",
    "@media (max-width:600px)": {
      fontSize: "2.5rem"
    }
  },
  h2_primary_light: {
    color: COLORS.PRIMARY_LIGHT,
    fontFamily: "Inter, sans-serif",
    fontSize: "3.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%",
    "@media (max-width:600px)": {
      fontSize: "2.5rem"
    }
  },
  h2_primary_dark: {
    color: COLORS.PRIMARY_DARK,
    fontFamily: "Inter, sans-serif",
    fontSize: "3.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%",
    "@media (max-width:600px)": {
      fontSize: "2.5rem"
    }
  },
  h2_primary_darker: {
    color: COLORS.PRIMARY_DARKER,
    fontFamily: "Inter, sans-serif",
    fontSize: "3.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%",
    "@media (max-width:600px)": {
      fontSize: "2.5rem"
    }
  },
  h2_primary_darkest: {
    color: COLORS.PRIMARY_DARKEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "3.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%",
    "@media (max-width:600px)": {
      fontSize: "2.5rem"
    }
  },
  h2_secondary: {
    color: COLORS.SECONDARY,
    fontFamily: "Inter, sans-serif",
    fontSize: "3.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%",
    "@media (max-width:600px)": {
      fontSize: "2.5rem"
    }
  },
  h2_secondary_lightest: {
    color: COLORS.SECONDARY_LIGHTEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "3.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%",
    "@media (max-width:600px)": {
      fontSize: "2.5rem"
    }
  },
  h2_secondary_lighter: {
    color: COLORS.SECONDARY_LIGHTER,
    fontFamily: "Inter, sans-serif",
    fontSize: "3.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%",
    "@media (max-width:600px)": {
      fontSize: "2.5rem"
    }
  },
  h2_secondary_light: {
    color: COLORS.SECONDARY_LIGHT,
    fontFamily: "Inter, sans-serif",
    fontSize: "3.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%",
    "@media (max-width:600px)": {
      fontSize: "2.5rem"
    }
  },
  h2_secondary_dark: {
    color: COLORS.SECONDARY_DARK,
    fontFamily: "Inter, sans-serif",
    fontSize: "3.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%",
    "@media (max-width:600px)": {
      fontSize: "2.5rem"
    }
  },
  h2_secondary_darker: {
    color: COLORS.SECONDARY_DARKER,
    fontFamily: "Inter, sans-serif",
    fontSize: "3.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%",
    "@media (max-width:600px)": {
      fontSize: "2.5rem"
    }
  },
  h2_secondary_darkest: {
    color: COLORS.SECONDARY_DARKEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "3.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%",
    "@media (max-width:600px)": {
      fontSize: "2.5rem"
    }
  },
  h2_accent1: {
    color: COLORS.ACCENT1,
    fontFamily: "Inter, sans-serif",
    fontSize: "3.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%",
    "@media (max-width:600px)": {
      fontSize: "2.5rem"
    }
  },
  h2_accent1_lightest: {
    color: COLORS.ACCENT1_LIGHTEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "3.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%",
    "@media (max-width:600px)": {
      fontSize: "2.5rem"
    }
  },
  h2_accent1_lighter: {
    color: COLORS.ACCENT1_LIGHTER,
    fontFamily: "Inter, sans-serif",
    fontSize: "3.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%",
    "@media (max-width:600px)": {
      fontSize: "2.5rem"
    }
  },
  h2_accent1_light: {
    color: COLORS.ACCENT1_LIGHT,
    fontFamily: "Inter, sans-serif",
    fontSize: "3.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%",
    "@media (max-width:600px)": {
      fontSize: "2.5rem"
    }
  },
  h2_accent1_dark: {
    color: COLORS.ACCENT1_DARK,
    fontFamily: "Inter, sans-serif",
    fontSize: "3.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%",
    "@media (max-width:600px)": {
      fontSize: "2.5rem"
    }
  },
  h2_accent1_darker: {
    color: COLORS.ACCENT1_DARKER,
    fontFamily: "Inter, sans-serif",
    fontSize: "3.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%",
    "@media (max-width:600px)": {
      fontSize: "2.5rem"
    }
  },
  h2_accent1_darkest: {
    color: COLORS.ACCENT1_DARKEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "3.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%",
    "@media (max-width:600px)": {
      fontSize: "2.5rem"
    }
  },
  h2_accent2: {
    color: COLORS.ACCENT2,
    fontFamily: "Inter, sans-serif",
    fontSize: "3.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%",
    "@media (max-width:600px)": {
      fontSize: "2.5rem"
    }
  },
  h2_accent2_lightest: {
    color: COLORS.ACCENT2_LIGHTEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "3.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%",
    "@media (max-width:600px)": {
      fontSize: "2.5rem"
    }
  },
  h2_accent2_lighter: {
    color: COLORS.ACCENT2_LIGHTER,
    fontFamily: "Inter, sans-serif",
    fontSize: "3.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%",
    "@media (max-width:600px)": {
      fontSize: "2.5rem"
    }
  },
  h2_accent2_light: {
    color: COLORS.ACCENT2_LIGHT,
    fontFamily: "Inter, sans-serif",
    fontSize: "3.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%",
    "@media (max-width:600px)": {
      fontSize: "2.5rem"
    }
  },
  h2_accent2_dark: {
    color: COLORS.ACCENT2_DARK,
    fontFamily: "Inter, sans-serif",
    fontSize: "3.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%",
    "@media (max-width:600px)": {
      fontSize: "2.5rem"
    }
  },
  h2_accent2_darker: {
    color: COLORS.ACCENT2_DARKER,
    fontFamily: "Inter, sans-serif",
    fontSize: "3.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%",
    "@media (max-width:600px)": {
      fontSize: "2.5rem"
    }
  },
  h2_accent2_darkest: {
    color: COLORS.ACCENT2_DARKEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "3.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%",
    "@media (max-width:600px)": {
      fontSize: "2.5rem"
    }
  },
  h2_accent3: {
    color: COLORS.ACCENT3,
    fontFamily: "Inter, sans-serif",
    fontSize: "3.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%",
    "@media (max-width:600px)": {
      fontSize: "2.5rem"
    }
  },
  h2_accent3_lightest: {
    color: COLORS.ACCENT3_LIGHTEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "3.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%",
    "@media (max-width:600px)": {
      fontSize: "2.5rem"
    }
  },
  h2_accent3_lighter: {
    color: COLORS.ACCENT3_LIGHTER,
    fontFamily: "Inter, sans-serif",
    fontSize: "3.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%",
    "@media (max-width:600px)": {
      fontSize: "2.5rem"
    }
  },
  h2_accent3_light: {
    color: COLORS.ACCENT3_LIGHT,
    fontFamily: "Inter, sans-serif",
    fontSize: "3.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%",
    "@media (max-width:600px)": {
      fontSize: "2.5rem"
    }
  },
  h2_accent3_dark: {
    color: COLORS.ACCENT3_DARK,
    fontFamily: "Inter, sans-serif",
    fontSize: "3.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%",
    "@media (max-width:600px)": {
      fontSize: "2.5rem"
    }
  },
  h2_accent3_darker: {
    color: COLORS.ACCENT3_DARKER,
    fontFamily: "Inter, sans-serif",
    fontSize: "3.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%",
    "@media (max-width:600px)": {
      fontSize: "2.5rem"
    }
  },
  h2_accent3_darkest: {
    color: COLORS.ACCENT3_DARKEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "3.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%",
    "@media (max-width:600px)": {
      fontSize: "2.5rem"
    }
  },
  h2_accent4: {
    color: COLORS.ACCENT4,
    fontFamily: "Inter, sans-serif",
    fontSize: "3.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%",
    "@media (max-width:600px)": {
      fontSize: "2.5rem"
    }
  },
  h2_accent4_lightest: {
    color: COLORS.ACCENT4_LIGHTEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "3.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%",
    "@media (max-width:600px)": {
      fontSize: "2.5rem"
    }
  },
  h2_accent4_lighter: {
    color: COLORS.ACCENT4_LIGHTER,
    fontFamily: "Inter, sans-serif",
    fontSize: "3.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%",
    "@media (max-width:600px)": {
      fontSize: "2.5rem"
    }
  },
  h2_accent4_light: {
    color: COLORS.ACCENT4_LIGHT,
    fontFamily: "Inter, sans-serif",
    fontSize: "3.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%",
    "@media (max-width:600px)": {
      fontSize: "2.5rem"
    }
  },
  h2_accent4_dark: {
    color: COLORS.ACCENT4_DARK,
    fontFamily: "Inter, sans-serif",
    fontSize: "3.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%",
    "@media (max-width:600px)": {
      fontSize: "2.5rem"
    }
  },
  h2_accent4_darker: {
    color: COLORS.ACCENT4_DARKER,
    fontFamily: "Inter, sans-serif",
    fontSize: "3.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%",
    "@media (max-width:600px)": {
      fontSize: "2.5rem"
    }
  },
  h2_accent4_darkest: {
    color: COLORS.ACCENT4_DARKEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "3.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%",
    "@media (max-width:600px)": {
      fontSize: "2.5rem"
    }
  },
  h2: {
    color: COLORS.BLACK,
    fontFamily: "Inter, sans-serif",
    fontSize: "3.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%",
    "@media (max-width:600px)": {
      fontSize: "2.5rem"
    }
  },
  h3_serif_white: {
    color: COLORS.WHITE,
    fontFamily: "Merriweather, serif",
    fontSize: "3.0rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h3_serif_black: {
    color: COLORS.BLACK,
    fontFamily: "Merriweather, serif",
    fontSize: "3.0rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h3_serif_gray: {
    color: COLORS.GRAY,
    fontFamily: "Merriweather, serif",
    fontSize: "3.0rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h3_serif_green_2: {
    color: COLORS.GREEN_2,
    fontFamily: "Merriweather, serif",
    fontSize: "3.0rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h3_serif_dark_gray: {
    color: COLORS.DARK_GRAY,
    fontFamily: "Merriweather, serif",
    fontSize: "3.0rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h3_serif_dark_gray_2: {
    color: COLORS.DARK_GRAY_2,
    fontFamily: "Merriweather, serif",
    fontSize: "3.0rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h3_serif_error: {
    color: COLORS.ERROR,
    fontFamily: "Merriweather, serif",
    fontSize: "3.0rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h3_serif_warning: {
    color: COLORS.WARNING,
    fontFamily: "Merriweather, serif",
    fontSize: "3.0rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h3_serif_success: {
    color: COLORS.SUCCESS,
    fontFamily: "Merriweather, serif",
    fontSize: "3.0rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h3_serif_primary: {
    color: COLORS.PRIMARY,
    fontFamily: "Merriweather, serif",
    fontSize: "3.0rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h3_serif_primary_lightest: {
    color: COLORS.PRIMARY_LIGHTEST,
    fontFamily: "Merriweather, serif",
    fontSize: "3.0rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h3_serif_primary_lighter: {
    color: COLORS.PRIMARY_LIGHTER,
    fontFamily: "Merriweather, serif",
    fontSize: "3.0rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h3_serif_primary_light: {
    color: COLORS.PRIMARY_LIGHT,
    fontFamily: "Merriweather, serif",
    fontSize: "3.0rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h3_serif_primary_dark: {
    color: COLORS.PRIMARY_DARK,
    fontFamily: "Merriweather, serif",
    fontSize: "3.0rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h3_serif_primary_darker: {
    color: COLORS.PRIMARY_DARKER,
    fontFamily: "Merriweather, serif",
    fontSize: "3.0rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h3_serif_primary_darkest: {
    color: COLORS.PRIMARY_DARKEST,
    fontFamily: "Merriweather, serif",
    fontSize: "3.0rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h3_serif_secondary: {
    color: COLORS.SECONDARY,
    fontFamily: "Merriweather, serif",
    fontSize: "3.0rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h3_serif_secondary_lightest: {
    color: COLORS.SECONDARY_LIGHTEST,
    fontFamily: "Merriweather, serif",
    fontSize: "3.0rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h3_serif_secondary_lighter: {
    color: COLORS.SECONDARY_LIGHTER,
    fontFamily: "Merriweather, serif",
    fontSize: "3.0rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h3_serif_secondary_light: {
    color: COLORS.SECONDARY_LIGHT,
    fontFamily: "Merriweather, serif",
    fontSize: "3.0rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h3_serif_secondary_dark: {
    color: COLORS.SECONDARY_DARK,
    fontFamily: "Merriweather, serif",
    fontSize: "3.0rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h3_serif_secondary_darker: {
    color: COLORS.SECONDARY_DARKER,
    fontFamily: "Merriweather, serif",
    fontSize: "3.0rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h3_serif_secondary_darkest: {
    color: COLORS.SECONDARY_DARKEST,
    fontFamily: "Merriweather, serif",
    fontSize: "3.0rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h3_serif_accent1: {
    color: COLORS.ACCENT1,
    fontFamily: "Merriweather, serif",
    fontSize: "3.0rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h3_serif_accent1_lightest: {
    color: COLORS.ACCENT1_LIGHTEST,
    fontFamily: "Merriweather, serif",
    fontSize: "3.0rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h3_serif_accent1_lighter: {
    color: COLORS.ACCENT1_LIGHTER,
    fontFamily: "Merriweather, serif",
    fontSize: "3.0rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h3_serif_accent1_light: {
    color: COLORS.ACCENT1_LIGHT,
    fontFamily: "Merriweather, serif",
    fontSize: "3.0rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h3_serif_accent1_dark: {
    color: COLORS.ACCENT1_DARK,
    fontFamily: "Merriweather, serif",
    fontSize: "3.0rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h3_serif_accent1_darker: {
    color: COLORS.ACCENT1_DARKER,
    fontFamily: "Merriweather, serif",
    fontSize: "3.0rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h3_serif_accent1_darkest: {
    color: COLORS.ACCENT1_DARKEST,
    fontFamily: "Merriweather, serif",
    fontSize: "3.0rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h3_serif_accent2: {
    color: COLORS.ACCENT2,
    fontFamily: "Merriweather, serif",
    fontSize: "3.0rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h3_serif_accent2_lightest: {
    color: COLORS.ACCENT2_LIGHTEST,
    fontFamily: "Merriweather, serif",
    fontSize: "3.0rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h3_serif_accent2_lighter: {
    color: COLORS.ACCENT2_LIGHTER,
    fontFamily: "Merriweather, serif",
    fontSize: "3.0rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h3_serif_accent2_light: {
    color: COLORS.ACCENT2_LIGHT,
    fontFamily: "Merriweather, serif",
    fontSize: "3.0rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h3_serif_accent2_dark: {
    color: COLORS.ACCENT2_DARK,
    fontFamily: "Merriweather, serif",
    fontSize: "3.0rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h3_serif_accent2_darker: {
    color: COLORS.ACCENT2_DARKER,
    fontFamily: "Merriweather, serif",
    fontSize: "3.0rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h3_serif_accent2_darkest: {
    color: COLORS.ACCENT2_DARKEST,
    fontFamily: "Merriweather, serif",
    fontSize: "3.0rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h3_serif_accent3: {
    color: COLORS.ACCENT3,
    fontFamily: "Merriweather, serif",
    fontSize: "3.0rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h3_serif_accent3_lightest: {
    color: COLORS.ACCENT3_LIGHTEST,
    fontFamily: "Merriweather, serif",
    fontSize: "3.0rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h3_serif_accent3_lighter: {
    color: COLORS.ACCENT3_LIGHTER,
    fontFamily: "Merriweather, serif",
    fontSize: "3.0rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h3_serif_accent3_light: {
    color: COLORS.ACCENT3_LIGHT,
    fontFamily: "Merriweather, serif",
    fontSize: "3.0rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h3_serif_accent3_dark: {
    color: COLORS.ACCENT3_DARK,
    fontFamily: "Merriweather, serif",
    fontSize: "3.0rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h3_serif_accent3_darker: {
    color: COLORS.ACCENT3_DARKER,
    fontFamily: "Merriweather, serif",
    fontSize: "3.0rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h3_serif_accent3_darkest: {
    color: COLORS.ACCENT3_DARKEST,
    fontFamily: "Merriweather, serif",
    fontSize: "3.0rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h3_serif_accent4: {
    color: COLORS.ACCENT4,
    fontFamily: "Merriweather, serif",
    fontSize: "3.0rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h3_serif_accent4_lightest: {
    color: COLORS.ACCENT4_LIGHTEST,
    fontFamily: "Merriweather, serif",
    fontSize: "3.0rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h3_serif_accent4_lighter: {
    color: COLORS.ACCENT4_LIGHTER,
    fontFamily: "Merriweather, serif",
    fontSize: "3.0rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h3_serif_accent4_light: {
    color: COLORS.ACCENT4_LIGHT,
    fontFamily: "Merriweather, serif",
    fontSize: "3.0rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h3_serif_accent4_dark: {
    color: COLORS.ACCENT4_DARK,
    fontFamily: "Merriweather, serif",
    fontSize: "3.0rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h3_serif_accent4_darker: {
    color: COLORS.ACCENT4_DARKER,
    fontFamily: "Merriweather, serif",
    fontSize: "3.0rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h3_serif_accent4_darkest: {
    color: COLORS.ACCENT4_DARKEST,
    fontFamily: "Merriweather, serif",
    fontSize: "3.0rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h3_serif: {
    color: COLORS.BLACK,
    fontFamily: "Merriweather, serif",
    fontSize: "3.0rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h3_white: {
    color: COLORS.WHITE,
    fontFamily: "Inter, sans-serif",
    fontSize: "3.0rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h3_black: {
    color: COLORS.BLACK,
    fontFamily: "Inter, sans-serif",
    fontSize: "3.0rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h3_gray: {
    color: COLORS.GRAY,
    fontFamily: "Inter, sans-serif",
    fontSize: "3.0rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h3_green_2: {
    color: COLORS.GREEN_2,
    fontFamily: "Inter, sans-serif",
    fontSize: "3.0rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h3_dark_gray: {
    color: COLORS.DARK_GRAY,
    fontFamily: "Inter, sans-serif",
    fontSize: "3.0rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h3_dark_gray_2: {
    color: COLORS.DARK_GRAY_2,
    fontFamily: "Inter, sans-serif",
    fontSize: "3.0rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h3_error: {
    color: COLORS.ERROR,
    fontFamily: "Inter, sans-serif",
    fontSize: "3.0rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h3_warning: {
    color: COLORS.WARNING,
    fontFamily: "Inter, sans-serif",
    fontSize: "3.0rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h3_success: {
    color: COLORS.SUCCESS,
    fontFamily: "Inter, sans-serif",
    fontSize: "3.0rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h3_primary: {
    color: COLORS.PRIMARY,
    fontFamily: "Inter, sans-serif",
    fontSize: "3.0rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h3_primary_lightest: {
    color: COLORS.PRIMARY_LIGHTEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "3.0rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h3_primary_lighter: {
    color: COLORS.PRIMARY_LIGHTER,
    fontFamily: "Inter, sans-serif",
    fontSize: "3.0rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h3_primary_light: {
    color: COLORS.PRIMARY_LIGHT,
    fontFamily: "Inter, sans-serif",
    fontSize: "3.0rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h3_primary_dark: {
    color: COLORS.PRIMARY_DARK,
    fontFamily: "Inter, sans-serif",
    fontSize: "3.0rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h3_primary_darker: {
    color: COLORS.PRIMARY_DARKER,
    fontFamily: "Inter, sans-serif",
    fontSize: "3.0rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h3_primary_darkest: {
    color: COLORS.PRIMARY_DARKEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "3.0rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h3_secondary: {
    color: COLORS.SECONDARY,
    fontFamily: "Inter, sans-serif",
    fontSize: "3.0rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h3_secondary_lightest: {
    color: COLORS.SECONDARY_LIGHTEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "3.0rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h3_secondary_lighter: {
    color: COLORS.SECONDARY_LIGHTER,
    fontFamily: "Inter, sans-serif",
    fontSize: "3.0rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h3_secondary_light: {
    color: COLORS.SECONDARY_LIGHT,
    fontFamily: "Inter, sans-serif",
    fontSize: "3.0rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h3_secondary_dark: {
    color: COLORS.SECONDARY_DARK,
    fontFamily: "Inter, sans-serif",
    fontSize: "3.0rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h3_secondary_darker: {
    color: COLORS.SECONDARY_DARKER,
    fontFamily: "Inter, sans-serif",
    fontSize: "3.0rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h3_secondary_darkest: {
    color: COLORS.SECONDARY_DARKEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "3.0rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h3_accent1: {
    color: COLORS.ACCENT1,
    fontFamily: "Inter, sans-serif",
    fontSize: "3.0rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h3_accent1_lightest: {
    color: COLORS.ACCENT1_LIGHTEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "3.0rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h3_accent1_lighter: {
    color: COLORS.ACCENT1_LIGHTER,
    fontFamily: "Inter, sans-serif",
    fontSize: "3.0rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h3_accent1_light: {
    color: COLORS.ACCENT1_LIGHT,
    fontFamily: "Inter, sans-serif",
    fontSize: "3.0rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h3_accent1_dark: {
    color: COLORS.ACCENT1_DARK,
    fontFamily: "Inter, sans-serif",
    fontSize: "3.0rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h3_accent1_darker: {
    color: COLORS.ACCENT1_DARKER,
    fontFamily: "Inter, sans-serif",
    fontSize: "3.0rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h3_accent1_darkest: {
    color: COLORS.ACCENT1_DARKEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "3.0rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h3_accent2: {
    color: COLORS.ACCENT2,
    fontFamily: "Inter, sans-serif",
    fontSize: "3.0rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h3_accent2_lightest: {
    color: COLORS.ACCENT2_LIGHTEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "3.0rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h3_accent2_lighter: {
    color: COLORS.ACCENT2_LIGHTER,
    fontFamily: "Inter, sans-serif",
    fontSize: "3.0rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h3_accent2_light: {
    color: COLORS.ACCENT2_LIGHT,
    fontFamily: "Inter, sans-serif",
    fontSize: "3.0rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h3_accent2_dark: {
    color: COLORS.ACCENT2_DARK,
    fontFamily: "Inter, sans-serif",
    fontSize: "3.0rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h3_accent2_darker: {
    color: COLORS.ACCENT2_DARKER,
    fontFamily: "Inter, sans-serif",
    fontSize: "3.0rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h3_accent2_darkest: {
    color: COLORS.ACCENT2_DARKEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "3.0rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h3_accent3: {
    color: COLORS.ACCENT3,
    fontFamily: "Inter, sans-serif",
    fontSize: "3.0rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h3_accent3_lightest: {
    color: COLORS.ACCENT3_LIGHTEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "3.0rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h3_accent3_lighter: {
    color: COLORS.ACCENT3_LIGHTER,
    fontFamily: "Inter, sans-serif",
    fontSize: "3.0rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h3_accent3_light: {
    color: COLORS.ACCENT3_LIGHT,
    fontFamily: "Inter, sans-serif",
    fontSize: "3.0rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h3_accent3_dark: {
    color: COLORS.ACCENT3_DARK,
    fontFamily: "Inter, sans-serif",
    fontSize: "3.0rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h3_accent3_darker: {
    color: COLORS.ACCENT3_DARKER,
    fontFamily: "Inter, sans-serif",
    fontSize: "3.0rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h3_accent3_darkest: {
    color: COLORS.ACCENT3_DARKEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "3.0rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h3_accent4: {
    color: COLORS.ACCENT4,
    fontFamily: "Inter, sans-serif",
    fontSize: "3.0rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h3_accent4_lightest: {
    color: COLORS.ACCENT4_LIGHTEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "3.0rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h3_accent4_lighter: {
    color: COLORS.ACCENT4_LIGHTER,
    fontFamily: "Inter, sans-serif",
    fontSize: "3.0rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h3_accent4_light: {
    color: COLORS.ACCENT4_LIGHT,
    fontFamily: "Inter, sans-serif",
    fontSize: "3.0rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h3_accent4_dark: {
    color: COLORS.ACCENT4_DARK,
    fontFamily: "Inter, sans-serif",
    fontSize: "3.0rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h3_accent4_darker: {
    color: COLORS.ACCENT4_DARKER,
    fontFamily: "Inter, sans-serif",
    fontSize: "3.0rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h3_accent4_darkest: {
    color: COLORS.ACCENT4_DARKEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "3.0rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h3: {
    color: COLORS.BLACK,
    fontFamily: "Inter, sans-serif",
    fontSize: "3.0rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h4_serif_white: {
    color: COLORS.WHITE,
    fontFamily: "Merriweather, serif",
    fontSize: "2.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h4_serif_black: {
    color: COLORS.BLACK,
    fontFamily: "Merriweather, serif",
    fontSize: "2.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h4_serif_gray: {
    color: COLORS.GRAY,
    fontFamily: "Merriweather, serif",
    fontSize: "2.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h4_serif_green_2: {
    color: COLORS.GREEN_2,
    fontFamily: "Merriweather, serif",
    fontSize: "2.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h4_serif_dark_gray: {
    color: COLORS.DARK_GRAY,
    fontFamily: "Merriweather, serif",
    fontSize: "2.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h4_serif_dark_gray_2: {
    color: COLORS.DARK_GRAY_2,
    fontFamily: "Merriweather, serif",
    fontSize: "2.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h4_serif_error: {
    color: COLORS.ERROR,
    fontFamily: "Merriweather, serif",
    fontSize: "2.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h4_serif_warning: {
    color: COLORS.WARNING,
    fontFamily: "Merriweather, serif",
    fontSize: "2.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h4_serif_success: {
    color: COLORS.SUCCESS,
    fontFamily: "Merriweather, serif",
    fontSize: "2.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h4_serif_primary: {
    color: COLORS.PRIMARY,
    fontFamily: "Merriweather, serif",
    fontSize: "2.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h4_serif_primary_lightest: {
    color: COLORS.PRIMARY_LIGHTEST,
    fontFamily: "Merriweather, serif",
    fontSize: "2.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h4_serif_primary_lighter: {
    color: COLORS.PRIMARY_LIGHTER,
    fontFamily: "Merriweather, serif",
    fontSize: "2.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h4_serif_primary_light: {
    color: COLORS.PRIMARY_LIGHT,
    fontFamily: "Merriweather, serif",
    fontSize: "2.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h4_serif_primary_dark: {
    color: COLORS.PRIMARY_DARK,
    fontFamily: "Merriweather, serif",
    fontSize: "2.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h4_serif_primary_darker: {
    color: COLORS.PRIMARY_DARKER,
    fontFamily: "Merriweather, serif",
    fontSize: "2.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h4_serif_primary_darkest: {
    color: COLORS.PRIMARY_DARKEST,
    fontFamily: "Merriweather, serif",
    fontSize: "2.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h4_serif_secondary: {
    color: COLORS.SECONDARY,
    fontFamily: "Merriweather, serif",
    fontSize: "2.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h4_serif_secondary_lightest: {
    color: COLORS.SECONDARY_LIGHTEST,
    fontFamily: "Merriweather, serif",
    fontSize: "2.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h4_serif_secondary_lighter: {
    color: COLORS.SECONDARY_LIGHTER,
    fontFamily: "Merriweather, serif",
    fontSize: "2.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h4_serif_secondary_light: {
    color: COLORS.SECONDARY_LIGHT,
    fontFamily: "Merriweather, serif",
    fontSize: "2.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h4_serif_secondary_dark: {
    color: COLORS.SECONDARY_DARK,
    fontFamily: "Merriweather, serif",
    fontSize: "2.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h4_serif_secondary_darker: {
    color: COLORS.SECONDARY_DARKER,
    fontFamily: "Merriweather, serif",
    fontSize: "2.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h4_serif_secondary_darkest: {
    color: COLORS.SECONDARY_DARKEST,
    fontFamily: "Merriweather, serif",
    fontSize: "2.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h4_serif_accent1: {
    color: COLORS.ACCENT1,
    fontFamily: "Merriweather, serif",
    fontSize: "2.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h4_serif_accent1_lightest: {
    color: COLORS.ACCENT1_LIGHTEST,
    fontFamily: "Merriweather, serif",
    fontSize: "2.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h4_serif_accent1_lighter: {
    color: COLORS.ACCENT1_LIGHTER,
    fontFamily: "Merriweather, serif",
    fontSize: "2.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h4_serif_accent1_light: {
    color: COLORS.ACCENT1_LIGHT,
    fontFamily: "Merriweather, serif",
    fontSize: "2.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h4_serif_accent1_dark: {
    color: COLORS.ACCENT1_DARK,
    fontFamily: "Merriweather, serif",
    fontSize: "2.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h4_serif_accent1_darker: {
    color: COLORS.ACCENT1_DARKER,
    fontFamily: "Merriweather, serif",
    fontSize: "2.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h4_serif_accent1_darkest: {
    color: COLORS.ACCENT1_DARKEST,
    fontFamily: "Merriweather, serif",
    fontSize: "2.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h4_serif_accent2: {
    color: COLORS.ACCENT2,
    fontFamily: "Merriweather, serif",
    fontSize: "2.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h4_serif_accent2_lightest: {
    color: COLORS.ACCENT2_LIGHTEST,
    fontFamily: "Merriweather, serif",
    fontSize: "2.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h4_serif_accent2_lighter: {
    color: COLORS.ACCENT2_LIGHTER,
    fontFamily: "Merriweather, serif",
    fontSize: "2.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h4_serif_accent2_light: {
    color: COLORS.ACCENT2_LIGHT,
    fontFamily: "Merriweather, serif",
    fontSize: "2.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h4_serif_accent2_dark: {
    color: COLORS.ACCENT2_DARK,
    fontFamily: "Merriweather, serif",
    fontSize: "2.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h4_serif_accent2_darker: {
    color: COLORS.ACCENT2_DARKER,
    fontFamily: "Merriweather, serif",
    fontSize: "2.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h4_serif_accent2_darkest: {
    color: COLORS.ACCENT2_DARKEST,
    fontFamily: "Merriweather, serif",
    fontSize: "2.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h4_serif_accent3: {
    color: COLORS.ACCENT3,
    fontFamily: "Merriweather, serif",
    fontSize: "2.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h4_serif_accent3_lightest: {
    color: COLORS.ACCENT3_LIGHTEST,
    fontFamily: "Merriweather, serif",
    fontSize: "2.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h4_serif_accent3_lighter: {
    color: COLORS.ACCENT3_LIGHTER,
    fontFamily: "Merriweather, serif",
    fontSize: "2.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h4_serif_accent3_light: {
    color: COLORS.ACCENT3_LIGHT,
    fontFamily: "Merriweather, serif",
    fontSize: "2.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h4_serif_accent3_dark: {
    color: COLORS.ACCENT3_DARK,
    fontFamily: "Merriweather, serif",
    fontSize: "2.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h4_serif_accent3_darker: {
    color: COLORS.ACCENT3_DARKER,
    fontFamily: "Merriweather, serif",
    fontSize: "2.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h4_serif_accent3_darkest: {
    color: COLORS.ACCENT3_DARKEST,
    fontFamily: "Merriweather, serif",
    fontSize: "2.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h4_serif_accent4: {
    color: COLORS.ACCENT4,
    fontFamily: "Merriweather, serif",
    fontSize: "2.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h4_serif_accent4_lightest: {
    color: COLORS.ACCENT4_LIGHTEST,
    fontFamily: "Merriweather, serif",
    fontSize: "2.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h4_serif_accent4_lighter: {
    color: COLORS.ACCENT4_LIGHTER,
    fontFamily: "Merriweather, serif",
    fontSize: "2.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h4_serif_accent4_light: {
    color: COLORS.ACCENT4_LIGHT,
    fontFamily: "Merriweather, serif",
    fontSize: "2.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h4_serif_accent4_dark: {
    color: COLORS.ACCENT4_DARK,
    fontFamily: "Merriweather, serif",
    fontSize: "2.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h4_serif_accent4_darker: {
    color: COLORS.ACCENT4_DARKER,
    fontFamily: "Merriweather, serif",
    fontSize: "2.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h4_serif_accent4_darkest: {
    color: COLORS.ACCENT4_DARKEST,
    fontFamily: "Merriweather, serif",
    fontSize: "2.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h4_serif: {
    color: COLORS.BLACK,
    fontFamily: "Merriweather, serif",
    fontSize: "2.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h4_white: {
    color: COLORS.WHITE,
    fontFamily: "Inter, sans-serif",
    fontSize: "2.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h4_black: {
    color: COLORS.BLACK,
    fontFamily: "Inter, sans-serif",
    fontSize: "2.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h4_gray: {
    color: COLORS.GRAY,
    fontFamily: "Inter, sans-serif",
    fontSize: "2.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h4_green_2: {
    color: COLORS.GREEN_2,
    fontFamily: "Inter, sans-serif",
    fontSize: "2.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h4_dark_gray: {
    color: COLORS.DARK_GRAY,
    fontFamily: "Inter, sans-serif",
    fontSize: "2.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h4_dark_gray_2: {
    color: COLORS.DARK_GRAY_2,
    fontFamily: "Inter, sans-serif",
    fontSize: "2.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h4_error: {
    color: COLORS.ERROR,
    fontFamily: "Inter, sans-serif",
    fontSize: "2.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h4_warning: {
    color: COLORS.WARNING,
    fontFamily: "Inter, sans-serif",
    fontSize: "2.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h4_success: {
    color: COLORS.SUCCESS,
    fontFamily: "Inter, sans-serif",
    fontSize: "2.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h4_primary: {
    color: COLORS.PRIMARY,
    fontFamily: "Inter, sans-serif",
    fontSize: "2.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h4_primary_lightest: {
    color: COLORS.PRIMARY_LIGHTEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "2.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h4_primary_lighter: {
    color: COLORS.PRIMARY_LIGHTER,
    fontFamily: "Inter, sans-serif",
    fontSize: "2.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h4_primary_light: {
    color: COLORS.PRIMARY_LIGHT,
    fontFamily: "Inter, sans-serif",
    fontSize: "2.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h4_primary_dark: {
    color: COLORS.PRIMARY_DARK,
    fontFamily: "Inter, sans-serif",
    fontSize: "2.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h4_primary_darker: {
    color: COLORS.PRIMARY_DARKER,
    fontFamily: "Inter, sans-serif",
    fontSize: "2.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h4_primary_darkest: {
    color: COLORS.PRIMARY_DARKEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "2.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h4_secondary: {
    color: COLORS.SECONDARY,
    fontFamily: "Inter, sans-serif",
    fontSize: "2.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h4_secondary_lightest: {
    color: COLORS.SECONDARY_LIGHTEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "2.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h4_secondary_lighter: {
    color: COLORS.SECONDARY_LIGHTER,
    fontFamily: "Inter, sans-serif",
    fontSize: "2.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h4_secondary_light: {
    color: COLORS.SECONDARY_LIGHT,
    fontFamily: "Inter, sans-serif",
    fontSize: "2.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h4_secondary_dark: {
    color: COLORS.SECONDARY_DARK,
    fontFamily: "Inter, sans-serif",
    fontSize: "2.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h4_secondary_darker: {
    color: COLORS.SECONDARY_DARKER,
    fontFamily: "Inter, sans-serif",
    fontSize: "2.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h4_secondary_darkest: {
    color: COLORS.SECONDARY_DARKEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "2.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h4_accent1: {
    color: COLORS.ACCENT1,
    fontFamily: "Inter, sans-serif",
    fontSize: "2.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h4_accent1_lightest: {
    color: COLORS.ACCENT1_LIGHTEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "2.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h4_accent1_lighter: {
    color: COLORS.ACCENT1_LIGHTER,
    fontFamily: "Inter, sans-serif",
    fontSize: "2.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h4_accent1_light: {
    color: COLORS.ACCENT1_LIGHT,
    fontFamily: "Inter, sans-serif",
    fontSize: "2.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h4_accent1_dark: {
    color: COLORS.ACCENT1_DARK,
    fontFamily: "Inter, sans-serif",
    fontSize: "2.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h4_accent1_darker: {
    color: COLORS.ACCENT1_DARKER,
    fontFamily: "Inter, sans-serif",
    fontSize: "2.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h4_accent1_darkest: {
    color: COLORS.ACCENT1_DARKEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "2.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h4_accent2: {
    color: COLORS.ACCENT2,
    fontFamily: "Inter, sans-serif",
    fontSize: "2.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h4_accent2_lightest: {
    color: COLORS.ACCENT2_LIGHTEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "2.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h4_accent2_lighter: {
    color: COLORS.ACCENT2_LIGHTER,
    fontFamily: "Inter, sans-serif",
    fontSize: "2.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h4_accent2_light: {
    color: COLORS.ACCENT2_LIGHT,
    fontFamily: "Inter, sans-serif",
    fontSize: "2.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h4_accent2_dark: {
    color: COLORS.ACCENT2_DARK,
    fontFamily: "Inter, sans-serif",
    fontSize: "2.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h4_accent2_darker: {
    color: COLORS.ACCENT2_DARKER,
    fontFamily: "Inter, sans-serif",
    fontSize: "2.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h4_accent2_darkest: {
    color: COLORS.ACCENT2_DARKEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "2.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h4_accent3: {
    color: COLORS.ACCENT3,
    fontFamily: "Inter, sans-serif",
    fontSize: "2.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h4_accent3_lightest: {
    color: COLORS.ACCENT3_LIGHTEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "2.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h4_accent3_lighter: {
    color: COLORS.ACCENT3_LIGHTER,
    fontFamily: "Inter, sans-serif",
    fontSize: "2.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h4_accent3_light: {
    color: COLORS.ACCENT3_LIGHT,
    fontFamily: "Inter, sans-serif",
    fontSize: "2.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h4_accent3_dark: {
    color: COLORS.ACCENT3_DARK,
    fontFamily: "Inter, sans-serif",
    fontSize: "2.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h4_accent3_darker: {
    color: COLORS.ACCENT3_DARKER,
    fontFamily: "Inter, sans-serif",
    fontSize: "2.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h4_accent3_darkest: {
    color: COLORS.ACCENT3_DARKEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "2.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h4_accent4: {
    color: COLORS.ACCENT4,
    fontFamily: "Inter, sans-serif",
    fontSize: "2.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h4_accent4_lightest: {
    color: COLORS.ACCENT4_LIGHTEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "2.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h4_accent4_lighter: {
    color: COLORS.ACCENT4_LIGHTER,
    fontFamily: "Inter, sans-serif",
    fontSize: "2.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h4_accent4_light: {
    color: COLORS.ACCENT4_LIGHT,
    fontFamily: "Inter, sans-serif",
    fontSize: "2.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h4_accent4_dark: {
    color: COLORS.ACCENT4_DARK,
    fontFamily: "Inter, sans-serif",
    fontSize: "2.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h4_accent4_darker: {
    color: COLORS.ACCENT4_DARKER,
    fontFamily: "Inter, sans-serif",
    fontSize: "2.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h4_accent4_darkest: {
    color: COLORS.ACCENT4_DARKEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "2.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h4: {
    color: COLORS.BLACK,
    fontFamily: "Inter, sans-serif",
    fontSize: "2.125rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h5_serif_white: {
    color: COLORS.WHITE,
    fontFamily: "Merriweather, serif",
    fontSize: "1.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h5_serif_black: {
    color: COLORS.BLACK,
    fontFamily: "Merriweather, serif",
    fontSize: "1.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h5_serif_gray: {
    color: COLORS.GRAY,
    fontFamily: "Merriweather, serif",
    fontSize: "1.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h5_serif_green_2: {
    color: COLORS.GREEN_2,
    fontFamily: "Merriweather, serif",
    fontSize: "1.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h5_serif_dark_gray: {
    color: COLORS.DARK_GRAY,
    fontFamily: "Merriweather, serif",
    fontSize: "1.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h5_serif_dark_gray_2: {
    color: COLORS.DARK_GRAY_2,
    fontFamily: "Merriweather, serif",
    fontSize: "1.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h5_serif_error: {
    color: COLORS.ERROR,
    fontFamily: "Merriweather, serif",
    fontSize: "1.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h5_serif_warning: {
    color: COLORS.WARNING,
    fontFamily: "Merriweather, serif",
    fontSize: "1.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h5_serif_success: {
    color: COLORS.SUCCESS,
    fontFamily: "Merriweather, serif",
    fontSize: "1.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h5_serif_primary: {
    color: COLORS.PRIMARY,
    fontFamily: "Merriweather, serif",
    fontSize: "1.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h5_serif_primary_lightest: {
    color: COLORS.PRIMARY_LIGHTEST,
    fontFamily: "Merriweather, serif",
    fontSize: "1.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h5_serif_primary_lighter: {
    color: COLORS.PRIMARY_LIGHTER,
    fontFamily: "Merriweather, serif",
    fontSize: "1.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h5_serif_primary_light: {
    color: COLORS.PRIMARY_LIGHT,
    fontFamily: "Merriweather, serif",
    fontSize: "1.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h5_serif_primary_dark: {
    color: COLORS.PRIMARY_DARK,
    fontFamily: "Merriweather, serif",
    fontSize: "1.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h5_serif_primary_darker: {
    color: COLORS.PRIMARY_DARKER,
    fontFamily: "Merriweather, serif",
    fontSize: "1.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h5_serif_primary_darkest: {
    color: COLORS.PRIMARY_DARKEST,
    fontFamily: "Merriweather, serif",
    fontSize: "1.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h5_serif_secondary: {
    color: COLORS.SECONDARY,
    fontFamily: "Merriweather, serif",
    fontSize: "1.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h5_serif_secondary_lightest: {
    color: COLORS.SECONDARY_LIGHTEST,
    fontFamily: "Merriweather, serif",
    fontSize: "1.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h5_serif_secondary_lighter: {
    color: COLORS.SECONDARY_LIGHTER,
    fontFamily: "Merriweather, serif",
    fontSize: "1.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h5_serif_secondary_light: {
    color: COLORS.SECONDARY_LIGHT,
    fontFamily: "Merriweather, serif",
    fontSize: "1.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h5_serif_secondary_dark: {
    color: COLORS.SECONDARY_DARK,
    fontFamily: "Merriweather, serif",
    fontSize: "1.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h5_serif_secondary_darker: {
    color: COLORS.SECONDARY_DARKER,
    fontFamily: "Merriweather, serif",
    fontSize: "1.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h5_serif_secondary_darkest: {
    color: COLORS.SECONDARY_DARKEST,
    fontFamily: "Merriweather, serif",
    fontSize: "1.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h5_serif_accent1: {
    color: COLORS.ACCENT1,
    fontFamily: "Merriweather, serif",
    fontSize: "1.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h5_serif_accent1_lightest: {
    color: COLORS.ACCENT1_LIGHTEST,
    fontFamily: "Merriweather, serif",
    fontSize: "1.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h5_serif_accent1_lighter: {
    color: COLORS.ACCENT1_LIGHTER,
    fontFamily: "Merriweather, serif",
    fontSize: "1.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h5_serif_accent1_light: {
    color: COLORS.ACCENT1_LIGHT,
    fontFamily: "Merriweather, serif",
    fontSize: "1.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h5_serif_accent1_dark: {
    color: COLORS.ACCENT1_DARK,
    fontFamily: "Merriweather, serif",
    fontSize: "1.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h5_serif_accent1_darker: {
    color: COLORS.ACCENT1_DARKER,
    fontFamily: "Merriweather, serif",
    fontSize: "1.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h5_serif_accent1_darkest: {
    color: COLORS.ACCENT1_DARKEST,
    fontFamily: "Merriweather, serif",
    fontSize: "1.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h5_serif_accent2: {
    color: COLORS.ACCENT2,
    fontFamily: "Merriweather, serif",
    fontSize: "1.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h5_serif_accent2_lightest: {
    color: COLORS.ACCENT2_LIGHTEST,
    fontFamily: "Merriweather, serif",
    fontSize: "1.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h5_serif_accent2_lighter: {
    color: COLORS.ACCENT2_LIGHTER,
    fontFamily: "Merriweather, serif",
    fontSize: "1.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h5_serif_accent2_light: {
    color: COLORS.ACCENT2_LIGHT,
    fontFamily: "Merriweather, serif",
    fontSize: "1.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h5_serif_accent2_dark: {
    color: COLORS.ACCENT2_DARK,
    fontFamily: "Merriweather, serif",
    fontSize: "1.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h5_serif_accent2_darker: {
    color: COLORS.ACCENT2_DARKER,
    fontFamily: "Merriweather, serif",
    fontSize: "1.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h5_serif_accent2_darkest: {
    color: COLORS.ACCENT2_DARKEST,
    fontFamily: "Merriweather, serif",
    fontSize: "1.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h5_serif_accent3: {
    color: COLORS.ACCENT3,
    fontFamily: "Merriweather, serif",
    fontSize: "1.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h5_serif_accent3_lightest: {
    color: COLORS.ACCENT3_LIGHTEST,
    fontFamily: "Merriweather, serif",
    fontSize: "1.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h5_serif_accent3_lighter: {
    color: COLORS.ACCENT3_LIGHTER,
    fontFamily: "Merriweather, serif",
    fontSize: "1.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h5_serif_accent3_light: {
    color: COLORS.ACCENT3_LIGHT,
    fontFamily: "Merriweather, serif",
    fontSize: "1.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h5_serif_accent3_dark: {
    color: COLORS.ACCENT3_DARK,
    fontFamily: "Merriweather, serif",
    fontSize: "1.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h5_serif_accent3_darker: {
    color: COLORS.ACCENT3_DARKER,
    fontFamily: "Merriweather, serif",
    fontSize: "1.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h5_serif_accent3_darkest: {
    color: COLORS.ACCENT3_DARKEST,
    fontFamily: "Merriweather, serif",
    fontSize: "1.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h5_serif_accent4: {
    color: COLORS.ACCENT4,
    fontFamily: "Merriweather, serif",
    fontSize: "1.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h5_serif_accent4_lightest: {
    color: COLORS.ACCENT4_LIGHTEST,
    fontFamily: "Merriweather, serif",
    fontSize: "1.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h5_serif_accent4_lighter: {
    color: COLORS.ACCENT4_LIGHTER,
    fontFamily: "Merriweather, serif",
    fontSize: "1.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h5_serif_accent4_light: {
    color: COLORS.ACCENT4_LIGHT,
    fontFamily: "Merriweather, serif",
    fontSize: "1.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h5_serif_accent4_dark: {
    color: COLORS.ACCENT4_DARK,
    fontFamily: "Merriweather, serif",
    fontSize: "1.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h5_serif_accent4_darker: {
    color: COLORS.ACCENT4_DARKER,
    fontFamily: "Merriweather, serif",
    fontSize: "1.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h5_serif_accent4_darkest: {
    color: COLORS.ACCENT4_DARKEST,
    fontFamily: "Merriweather, serif",
    fontSize: "1.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h5_serif: {
    color: COLORS.BLACK,
    fontFamily: "Merriweather, serif",
    fontSize: "1.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h5_white: {
    color: COLORS.WHITE,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h5_black: {
    color: COLORS.BLACK,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h5_gray: {
    color: COLORS.GRAY,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h5_green_2: {
    color: COLORS.GREEN_2,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h5_dark_gray: {
    color: COLORS.DARK_GRAY,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h5_dark_gray_2: {
    color: COLORS.DARK_GRAY_2,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h5_error: {
    color: COLORS.ERROR,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h5_warning: {
    color: COLORS.WARNING,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h5_success: {
    color: COLORS.SUCCESS,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h5_primary: {
    color: COLORS.PRIMARY,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h5_primary_lightest: {
    color: COLORS.PRIMARY_LIGHTEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h5_primary_lighter: {
    color: COLORS.PRIMARY_LIGHTER,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h5_primary_light: {
    color: COLORS.PRIMARY_LIGHT,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h5_primary_dark: {
    color: COLORS.PRIMARY_DARK,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h5_primary_darker: {
    color: COLORS.PRIMARY_DARKER,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h5_primary_darkest: {
    color: COLORS.PRIMARY_DARKEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h5_secondary: {
    color: COLORS.SECONDARY,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h5_secondary_lightest: {
    color: COLORS.SECONDARY_LIGHTEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h5_secondary_lighter: {
    color: COLORS.SECONDARY_LIGHTER,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h5_secondary_light: {
    color: COLORS.SECONDARY_LIGHT,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h5_secondary_dark: {
    color: COLORS.SECONDARY_DARK,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h5_secondary_darker: {
    color: COLORS.SECONDARY_DARKER,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h5_secondary_darkest: {
    color: COLORS.SECONDARY_DARKEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h5_accent1: {
    color: COLORS.ACCENT1,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h5_accent1_lightest: {
    color: COLORS.ACCENT1_LIGHTEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h5_accent1_lighter: {
    color: COLORS.ACCENT1_LIGHTER,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h5_accent1_light: {
    color: COLORS.ACCENT1_LIGHT,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h5_accent1_dark: {
    color: COLORS.ACCENT1_DARK,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h5_accent1_darker: {
    color: COLORS.ACCENT1_DARKER,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h5_accent1_darkest: {
    color: COLORS.ACCENT1_DARKEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h5_accent2: {
    color: COLORS.ACCENT2,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h5_accent2_lightest: {
    color: COLORS.ACCENT2_LIGHTEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h5_accent2_lighter: {
    color: COLORS.ACCENT2_LIGHTER,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h5_accent2_light: {
    color: COLORS.ACCENT2_LIGHT,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h5_accent2_dark: {
    color: COLORS.ACCENT2_DARK,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h5_accent2_darker: {
    color: COLORS.ACCENT2_DARKER,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h5_accent2_darkest: {
    color: COLORS.ACCENT2_DARKEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h5_accent3: {
    color: COLORS.ACCENT3,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h5_accent3_lightest: {
    color: COLORS.ACCENT3_LIGHTEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h5_accent3_lighter: {
    color: COLORS.ACCENT3_LIGHTER,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h5_accent3_light: {
    color: COLORS.ACCENT3_LIGHT,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h5_accent3_dark: {
    color: COLORS.ACCENT3_DARK,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h5_accent3_darker: {
    color: COLORS.ACCENT3_DARKER,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h5_accent3_darkest: {
    color: COLORS.ACCENT3_DARKEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h5_accent4: {
    color: COLORS.ACCENT4,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h5_accent4_lightest: {
    color: COLORS.ACCENT4_LIGHTEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h5_accent4_lighter: {
    color: COLORS.ACCENT4_LIGHTER,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h5_accent4_light: {
    color: COLORS.ACCENT4_LIGHT,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h5_accent4_dark: {
    color: COLORS.ACCENT4_DARK,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h5_accent4_darker: {
    color: COLORS.ACCENT4_DARKER,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h5_accent4_darkest: {
    color: COLORS.ACCENT4_DARKEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h5: {
    color: COLORS.BLACK,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h6_serif_white: {
    color: COLORS.WHITE,
    fontFamily: "Merriweather, serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h6_serif_black: {
    color: COLORS.BLACK,
    fontFamily: "Merriweather, serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h6_serif_gray: {
    color: COLORS.GRAY,
    fontFamily: "Merriweather, serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h6_serif_green_2: {
    color: COLORS.GREEN_2,
    fontFamily: "Merriweather, serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h6_serif_dark_gray: {
    color: COLORS.DARK_GRAY,
    fontFamily: "Merriweather, serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h6_serif_dark_gray_2: {
    color: COLORS.DARK_GRAY_2,
    fontFamily: "Merriweather, serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h6_serif_error: {
    color: COLORS.ERROR,
    fontFamily: "Merriweather, serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h6_serif_warning: {
    color: COLORS.WARNING,
    fontFamily: "Merriweather, serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h6_serif_success: {
    color: COLORS.SUCCESS,
    fontFamily: "Merriweather, serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h6_serif_primary: {
    color: COLORS.PRIMARY,
    fontFamily: "Merriweather, serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h6_serif_primary_lightest: {
    color: COLORS.PRIMARY_LIGHTEST,
    fontFamily: "Merriweather, serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h6_serif_primary_lighter: {
    color: COLORS.PRIMARY_LIGHTER,
    fontFamily: "Merriweather, serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h6_serif_primary_light: {
    color: COLORS.PRIMARY_LIGHT,
    fontFamily: "Merriweather, serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h6_serif_primary_dark: {
    color: COLORS.PRIMARY_DARK,
    fontFamily: "Merriweather, serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h6_serif_primary_darker: {
    color: COLORS.PRIMARY_DARKER,
    fontFamily: "Merriweather, serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h6_serif_primary_darkest: {
    color: COLORS.PRIMARY_DARKEST,
    fontFamily: "Merriweather, serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h6_serif_secondary: {
    color: COLORS.SECONDARY,
    fontFamily: "Merriweather, serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h6_serif_secondary_lightest: {
    color: COLORS.SECONDARY_LIGHTEST,
    fontFamily: "Merriweather, serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h6_serif_secondary_lighter: {
    color: COLORS.SECONDARY_LIGHTER,
    fontFamily: "Merriweather, serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h6_serif_secondary_light: {
    color: COLORS.SECONDARY_LIGHT,
    fontFamily: "Merriweather, serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h6_serif_secondary_dark: {
    color: COLORS.SECONDARY_DARK,
    fontFamily: "Merriweather, serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h6_serif_secondary_darker: {
    color: COLORS.SECONDARY_DARKER,
    fontFamily: "Merriweather, serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h6_serif_secondary_darkest: {
    color: COLORS.SECONDARY_DARKEST,
    fontFamily: "Merriweather, serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h6_serif_accent1: {
    color: COLORS.ACCENT1,
    fontFamily: "Merriweather, serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h6_serif_accent1_lightest: {
    color: COLORS.ACCENT1_LIGHTEST,
    fontFamily: "Merriweather, serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h6_serif_accent1_lighter: {
    color: COLORS.ACCENT1_LIGHTER,
    fontFamily: "Merriweather, serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h6_serif_accent1_light: {
    color: COLORS.ACCENT1_LIGHT,
    fontFamily: "Merriweather, serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h6_serif_accent1_dark: {
    color: COLORS.ACCENT1_DARK,
    fontFamily: "Merriweather, serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h6_serif_accent1_darker: {
    color: COLORS.ACCENT1_DARKER,
    fontFamily: "Merriweather, serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h6_serif_accent1_darkest: {
    color: COLORS.ACCENT1_DARKEST,
    fontFamily: "Merriweather, serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h6_serif_accent2: {
    color: COLORS.ACCENT2,
    fontFamily: "Merriweather, serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h6_serif_accent2_lightest: {
    color: COLORS.ACCENT2_LIGHTEST,
    fontFamily: "Merriweather, serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h6_serif_accent2_lighter: {
    color: COLORS.ACCENT2_LIGHTER,
    fontFamily: "Merriweather, serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h6_serif_accent2_light: {
    color: COLORS.ACCENT2_LIGHT,
    fontFamily: "Merriweather, serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h6_serif_accent2_dark: {
    color: COLORS.ACCENT2_DARK,
    fontFamily: "Merriweather, serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h6_serif_accent2_darker: {
    color: COLORS.ACCENT2_DARKER,
    fontFamily: "Merriweather, serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h6_serif_accent2_darkest: {
    color: COLORS.ACCENT2_DARKEST,
    fontFamily: "Merriweather, serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h6_serif_accent3: {
    color: COLORS.ACCENT3,
    fontFamily: "Merriweather, serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h6_serif_accent3_lightest: {
    color: COLORS.ACCENT3_LIGHTEST,
    fontFamily: "Merriweather, serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h6_serif_accent3_lighter: {
    color: COLORS.ACCENT3_LIGHTER,
    fontFamily: "Merriweather, serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h6_serif_accent3_light: {
    color: COLORS.ACCENT3_LIGHT,
    fontFamily: "Merriweather, serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h6_serif_accent3_dark: {
    color: COLORS.ACCENT3_DARK,
    fontFamily: "Merriweather, serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h6_serif_accent3_darker: {
    color: COLORS.ACCENT3_DARKER,
    fontFamily: "Merriweather, serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h6_serif_accent3_darkest: {
    color: COLORS.ACCENT3_DARKEST,
    fontFamily: "Merriweather, serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h6_serif_accent4: {
    color: COLORS.ACCENT4,
    fontFamily: "Merriweather, serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h6_serif_accent4_lightest: {
    color: COLORS.ACCENT4_LIGHTEST,
    fontFamily: "Merriweather, serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h6_serif_accent4_lighter: {
    color: COLORS.ACCENT4_LIGHTER,
    fontFamily: "Merriweather, serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h6_serif_accent4_light: {
    color: COLORS.ACCENT4_LIGHT,
    fontFamily: "Merriweather, serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h6_serif_accent4_dark: {
    color: COLORS.ACCENT4_DARK,
    fontFamily: "Merriweather, serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h6_serif_accent4_darker: {
    color: COLORS.ACCENT4_DARKER,
    fontFamily: "Merriweather, serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h6_serif_accent4_darkest: {
    color: COLORS.ACCENT4_DARKEST,
    fontFamily: "Merriweather, serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h6_serif: {
    color: COLORS.BLACK,
    fontFamily: "Merriweather, serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h6_white: {
    color: COLORS.WHITE,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h6_black: {
    color: COLORS.BLACK,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h6_gray: {
    color: COLORS.GRAY,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h6_green_2: {
    color: COLORS.GREEN_2,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h6_dark_gray: {
    color: COLORS.DARK_GRAY,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h6_dark_gray_2: {
    color: COLORS.DARK_GRAY_2,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h6_error: {
    color: COLORS.ERROR,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h6_warning: {
    color: COLORS.WARNING,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h6_success: {
    color: COLORS.SUCCESS,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h6_primary: {
    color: COLORS.PRIMARY,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h6_primary_lightest: {
    color: COLORS.PRIMARY_LIGHTEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h6_primary_lighter: {
    color: COLORS.PRIMARY_LIGHTER,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h6_primary_light: {
    color: COLORS.PRIMARY_LIGHT,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h6_primary_dark: {
    color: COLORS.PRIMARY_DARK,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h6_primary_darker: {
    color: COLORS.PRIMARY_DARKER,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h6_primary_darkest: {
    color: COLORS.PRIMARY_DARKEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h6_secondary: {
    color: COLORS.SECONDARY,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h6_secondary_lightest: {
    color: COLORS.SECONDARY_LIGHTEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h6_secondary_lighter: {
    color: COLORS.SECONDARY_LIGHTER,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h6_secondary_light: {
    color: COLORS.SECONDARY_LIGHT,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h6_secondary_dark: {
    color: COLORS.SECONDARY_DARK,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h6_secondary_darker: {
    color: COLORS.SECONDARY_DARKER,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h6_secondary_darkest: {
    color: COLORS.SECONDARY_DARKEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h6_accent1: {
    color: COLORS.ACCENT1,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h6_accent1_lightest: {
    color: COLORS.ACCENT1_LIGHTEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h6_accent1_lighter: {
    color: COLORS.ACCENT1_LIGHTER,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h6_accent1_light: {
    color: COLORS.ACCENT1_LIGHT,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h6_accent1_dark: {
    color: COLORS.ACCENT1_DARK,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h6_accent1_darker: {
    color: COLORS.ACCENT1_DARKER,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h6_accent1_darkest: {
    color: COLORS.ACCENT1_DARKEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h6_accent2: {
    color: COLORS.ACCENT2,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h6_accent2_lightest: {
    color: COLORS.ACCENT2_LIGHTEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h6_accent2_lighter: {
    color: COLORS.ACCENT2_LIGHTER,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h6_accent2_light: {
    color: COLORS.ACCENT2_LIGHT,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h6_accent2_dark: {
    color: COLORS.ACCENT2_DARK,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h6_accent2_darker: {
    color: COLORS.ACCENT2_DARKER,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h6_accent2_darkest: {
    color: COLORS.ACCENT2_DARKEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h6_accent3: {
    color: COLORS.ACCENT3,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h6_accent3_lightest: {
    color: COLORS.ACCENT3_LIGHTEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h6_accent3_lighter: {
    color: COLORS.ACCENT3_LIGHTER,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h6_accent3_light: {
    color: COLORS.ACCENT3_LIGHT,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h6_accent3_dark: {
    color: COLORS.ACCENT3_DARK,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h6_accent3_darker: {
    color: COLORS.ACCENT3_DARKER,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h6_accent3_darkest: {
    color: COLORS.ACCENT3_DARKEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h6_accent4: {
    color: COLORS.ACCENT4,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h6_accent4_lightest: {
    color: COLORS.ACCENT4_LIGHTEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h6_accent4_lighter: {
    color: COLORS.ACCENT4_LIGHTER,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h6_accent4_light: {
    color: COLORS.ACCENT4_LIGHT,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h6_accent4_dark: {
    color: COLORS.ACCENT4_DARK,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h6_accent4_darker: {
    color: COLORS.ACCENT4_DARKER,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h6_accent4_darkest: {
    color: COLORS.ACCENT4_DARKEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  h6: {
    color: COLORS.BLACK,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "125%"
  },
  body_xl_serif_white: {
    color: COLORS.WHITE,
    fontFamily: "Merriweather, serif",
    fontSize: "1.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xl_serif_black: {
    color: COLORS.BLACK,
    fontFamily: "Merriweather, serif",
    fontSize: "1.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xl_serif_gray: {
    color: COLORS.GRAY,
    fontFamily: "Merriweather, serif",
    fontSize: "1.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xl_serif_green_2: {
    color: COLORS.GREEN_2,
    fontFamily: "Merriweather, serif",
    fontSize: "1.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xl_serif_dark_gray: {
    color: COLORS.DARK_GRAY,
    fontFamily: "Merriweather, serif",
    fontSize: "1.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xl_serif_dark_gray_2: {
    color: COLORS.DARK_GRAY_2,
    fontFamily: "Merriweather, serif",
    fontSize: "1.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xl_serif_error: {
    color: COLORS.ERROR,
    fontFamily: "Merriweather, serif",
    fontSize: "1.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xl_serif_warning: {
    color: COLORS.WARNING,
    fontFamily: "Merriweather, serif",
    fontSize: "1.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xl_serif_success: {
    color: COLORS.SUCCESS,
    fontFamily: "Merriweather, serif",
    fontSize: "1.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xl_serif_primary: {
    color: COLORS.PRIMARY,
    fontFamily: "Merriweather, serif",
    fontSize: "1.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xl_serif_primary_lightest: {
    color: COLORS.PRIMARY_LIGHTEST,
    fontFamily: "Merriweather, serif",
    fontSize: "1.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xl_serif_primary_lighter: {
    color: COLORS.PRIMARY_LIGHTER,
    fontFamily: "Merriweather, serif",
    fontSize: "1.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xl_serif_primary_light: {
    color: COLORS.PRIMARY_LIGHT,
    fontFamily: "Merriweather, serif",
    fontSize: "1.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xl_serif_primary_dark: {
    color: COLORS.PRIMARY_DARK,
    fontFamily: "Merriweather, serif",
    fontSize: "1.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xl_serif_primary_darker: {
    color: COLORS.PRIMARY_DARKER,
    fontFamily: "Merriweather, serif",
    fontSize: "1.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xl_serif_primary_darkest: {
    color: COLORS.PRIMARY_DARKEST,
    fontFamily: "Merriweather, serif",
    fontSize: "1.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xl_serif_secondary: {
    color: COLORS.SECONDARY,
    fontFamily: "Merriweather, serif",
    fontSize: "1.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xl_serif_secondary_lightest: {
    color: COLORS.SECONDARY_LIGHTEST,
    fontFamily: "Merriweather, serif",
    fontSize: "1.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xl_serif_secondary_lighter: {
    color: COLORS.SECONDARY_LIGHTER,
    fontFamily: "Merriweather, serif",
    fontSize: "1.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xl_serif_secondary_light: {
    color: COLORS.SECONDARY_LIGHT,
    fontFamily: "Merriweather, serif",
    fontSize: "1.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xl_serif_secondary_dark: {
    color: COLORS.SECONDARY_DARK,
    fontFamily: "Merriweather, serif",
    fontSize: "1.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xl_serif_secondary_darker: {
    color: COLORS.SECONDARY_DARKER,
    fontFamily: "Merriweather, serif",
    fontSize: "1.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xl_serif_secondary_darkest: {
    color: COLORS.SECONDARY_DARKEST,
    fontFamily: "Merriweather, serif",
    fontSize: "1.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xl_serif_accent1: {
    color: COLORS.ACCENT1,
    fontFamily: "Merriweather, serif",
    fontSize: "1.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xl_serif_accent1_lightest: {
    color: COLORS.ACCENT1_LIGHTEST,
    fontFamily: "Merriweather, serif",
    fontSize: "1.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xl_serif_accent1_lighter: {
    color: COLORS.ACCENT1_LIGHTER,
    fontFamily: "Merriweather, serif",
    fontSize: "1.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xl_serif_accent1_light: {
    color: COLORS.ACCENT1_LIGHT,
    fontFamily: "Merriweather, serif",
    fontSize: "1.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xl_serif_accent1_dark: {
    color: COLORS.ACCENT1_DARK,
    fontFamily: "Merriweather, serif",
    fontSize: "1.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xl_serif_accent1_darker: {
    color: COLORS.ACCENT1_DARKER,
    fontFamily: "Merriweather, serif",
    fontSize: "1.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xl_serif_accent1_darkest: {
    color: COLORS.ACCENT1_DARKEST,
    fontFamily: "Merriweather, serif",
    fontSize: "1.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xl_serif_accent2: {
    color: COLORS.ACCENT2,
    fontFamily: "Merriweather, serif",
    fontSize: "1.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xl_serif_accent2_lightest: {
    color: COLORS.ACCENT2_LIGHTEST,
    fontFamily: "Merriweather, serif",
    fontSize: "1.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xl_serif_accent2_lighter: {
    color: COLORS.ACCENT2_LIGHTER,
    fontFamily: "Merriweather, serif",
    fontSize: "1.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xl_serif_accent2_light: {
    color: COLORS.ACCENT2_LIGHT,
    fontFamily: "Merriweather, serif",
    fontSize: "1.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xl_serif_accent2_dark: {
    color: COLORS.ACCENT2_DARK,
    fontFamily: "Merriweather, serif",
    fontSize: "1.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xl_serif_accent2_darker: {
    color: COLORS.ACCENT2_DARKER,
    fontFamily: "Merriweather, serif",
    fontSize: "1.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xl_serif_accent2_darkest: {
    color: COLORS.ACCENT2_DARKEST,
    fontFamily: "Merriweather, serif",
    fontSize: "1.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xl_serif_accent3: {
    color: COLORS.ACCENT3,
    fontFamily: "Merriweather, serif",
    fontSize: "1.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xl_serif_accent3_lightest: {
    color: COLORS.ACCENT3_LIGHTEST,
    fontFamily: "Merriweather, serif",
    fontSize: "1.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xl_serif_accent3_lighter: {
    color: COLORS.ACCENT3_LIGHTER,
    fontFamily: "Merriweather, serif",
    fontSize: "1.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xl_serif_accent3_light: {
    color: COLORS.ACCENT3_LIGHT,
    fontFamily: "Merriweather, serif",
    fontSize: "1.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xl_serif_accent3_dark: {
    color: COLORS.ACCENT3_DARK,
    fontFamily: "Merriweather, serif",
    fontSize: "1.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xl_serif_accent3_darker: {
    color: COLORS.ACCENT3_DARKER,
    fontFamily: "Merriweather, serif",
    fontSize: "1.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xl_serif_accent3_darkest: {
    color: COLORS.ACCENT3_DARKEST,
    fontFamily: "Merriweather, serif",
    fontSize: "1.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xl_serif_accent4: {
    color: COLORS.ACCENT4,
    fontFamily: "Merriweather, serif",
    fontSize: "1.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xl_serif_accent4_lightest: {
    color: COLORS.ACCENT4_LIGHTEST,
    fontFamily: "Merriweather, serif",
    fontSize: "1.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xl_serif_accent4_lighter: {
    color: COLORS.ACCENT4_LIGHTER,
    fontFamily: "Merriweather, serif",
    fontSize: "1.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xl_serif_accent4_light: {
    color: COLORS.ACCENT4_LIGHT,
    fontFamily: "Merriweather, serif",
    fontSize: "1.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xl_serif_accent4_dark: {
    color: COLORS.ACCENT4_DARK,
    fontFamily: "Merriweather, serif",
    fontSize: "1.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xl_serif_accent4_darker: {
    color: COLORS.ACCENT4_DARKER,
    fontFamily: "Merriweather, serif",
    fontSize: "1.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xl_serif_accent4_darkest: {
    color: COLORS.ACCENT4_DARKEST,
    fontFamily: "Merriweather, serif",
    fontSize: "1.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xl_serif: {
    color: COLORS.BLACK,
    fontFamily: "Merriweather, serif",
    fontSize: "1.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xl_white: {
    color: COLORS.WHITE,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xl_black: {
    color: COLORS.BLACK,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xl_gray: {
    color: COLORS.GRAY,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xl_green_2: {
    color: COLORS.GREEN_2,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xl_dark_gray: {
    color: COLORS.DARK_GRAY,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xl_dark_gray_2: {
    color: COLORS.DARK_GRAY_2,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xl_error: {
    color: COLORS.ERROR,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xl_warning: {
    color: COLORS.WARNING,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xl_success: {
    color: COLORS.SUCCESS,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xl_primary: {
    color: COLORS.PRIMARY,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xl_primary_lightest: {
    color: COLORS.PRIMARY_LIGHTEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xl_primary_lighter: {
    color: COLORS.PRIMARY_LIGHTER,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xl_primary_light: {
    color: COLORS.PRIMARY_LIGHT,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xl_primary_dark: {
    color: COLORS.PRIMARY_DARK,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xl_primary_darker: {
    color: COLORS.PRIMARY_DARKER,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xl_primary_darkest: {
    color: COLORS.PRIMARY_DARKEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xl_secondary: {
    color: COLORS.SECONDARY,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xl_secondary_lightest: {
    color: COLORS.SECONDARY_LIGHTEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xl_secondary_lighter: {
    color: COLORS.SECONDARY_LIGHTER,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xl_secondary_light: {
    color: COLORS.SECONDARY_LIGHT,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xl_secondary_dark: {
    color: COLORS.SECONDARY_DARK,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xl_secondary_darker: {
    color: COLORS.SECONDARY_DARKER,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xl_secondary_darkest: {
    color: COLORS.SECONDARY_DARKEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xl_accent1: {
    color: COLORS.ACCENT1,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xl_accent1_lightest: {
    color: COLORS.ACCENT1_LIGHTEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xl_accent1_lighter: {
    color: COLORS.ACCENT1_LIGHTER,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xl_accent1_light: {
    color: COLORS.ACCENT1_LIGHT,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xl_accent1_dark: {
    color: COLORS.ACCENT1_DARK,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xl_accent1_darker: {
    color: COLORS.ACCENT1_DARKER,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xl_accent1_darkest: {
    color: COLORS.ACCENT1_DARKEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xl_accent2: {
    color: COLORS.ACCENT2,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xl_accent2_lightest: {
    color: COLORS.ACCENT2_LIGHTEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xl_accent2_lighter: {
    color: COLORS.ACCENT2_LIGHTER,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xl_accent2_light: {
    color: COLORS.ACCENT2_LIGHT,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xl_accent2_dark: {
    color: COLORS.ACCENT2_DARK,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xl_accent2_darker: {
    color: COLORS.ACCENT2_DARKER,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xl_accent2_darkest: {
    color: COLORS.ACCENT2_DARKEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xl_accent3: {
    color: COLORS.ACCENT3,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xl_accent3_lightest: {
    color: COLORS.ACCENT3_LIGHTEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xl_accent3_lighter: {
    color: COLORS.ACCENT3_LIGHTER,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xl_accent3_light: {
    color: COLORS.ACCENT3_LIGHT,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xl_accent3_dark: {
    color: COLORS.ACCENT3_DARK,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xl_accent3_darker: {
    color: COLORS.ACCENT3_DARKER,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xl_accent3_darkest: {
    color: COLORS.ACCENT3_DARKEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xl_accent4: {
    color: COLORS.ACCENT4,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xl_accent4_lightest: {
    color: COLORS.ACCENT4_LIGHTEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xl_accent4_lighter: {
    color: COLORS.ACCENT4_LIGHTER,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xl_accent4_light: {
    color: COLORS.ACCENT4_LIGHT,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xl_accent4_dark: {
    color: COLORS.ACCENT4_DARK,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xl_accent4_darker: {
    color: COLORS.ACCENT4_DARKER,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xl_accent4_darkest: {
    color: COLORS.ACCENT4_DARKEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xl: {
    color: COLORS.BLACK,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg2_serif_white: {
    color: COLORS.WHITE,
    fontFamily: "Merriweather, serif",
    fontSize: "1.688rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg2_serif_black: {
    color: COLORS.BLACK,
    fontFamily: "Merriweather, serif",
    fontSize: "1.688rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg2_serif_gray: {
    color: COLORS.GRAY,
    fontFamily: "Merriweather, serif",
    fontSize: "1.688rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg2_serif_green_2: {
    color: COLORS.GREEN_2,
    fontFamily: "Merriweather, serif",
    fontSize: "1.688rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg2_serif_dark_gray: {
    color: COLORS.DARK_GRAY,
    fontFamily: "Merriweather, serif",
    fontSize: "1.688rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg2_serif_dark_gray_2: {
    color: COLORS.DARK_GRAY_2,
    fontFamily: "Merriweather, serif",
    fontSize: "1.688rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg2_serif_error: {
    color: COLORS.ERROR,
    fontFamily: "Merriweather, serif",
    fontSize: "1.688rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg2_serif_warning: {
    color: COLORS.WARNING,
    fontFamily: "Merriweather, serif",
    fontSize: "1.688rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg2_serif_success: {
    color: COLORS.SUCCESS,
    fontFamily: "Merriweather, serif",
    fontSize: "1.688rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg2_serif_primary: {
    color: COLORS.PRIMARY,
    fontFamily: "Merriweather, serif",
    fontSize: "1.688rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg2_serif_primary_lightest: {
    color: COLORS.PRIMARY_LIGHTEST,
    fontFamily: "Merriweather, serif",
    fontSize: "1.688rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg2_serif_primary_lighter: {
    color: COLORS.PRIMARY_LIGHTER,
    fontFamily: "Merriweather, serif",
    fontSize: "1.688rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg2_serif_primary_light: {
    color: COLORS.PRIMARY_LIGHT,
    fontFamily: "Merriweather, serif",
    fontSize: "1.688rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg2_serif_primary_dark: {
    color: COLORS.PRIMARY_DARK,
    fontFamily: "Merriweather, serif",
    fontSize: "1.688rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg2_serif_primary_darker: {
    color: COLORS.PRIMARY_DARKER,
    fontFamily: "Merriweather, serif",
    fontSize: "1.688rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg2_serif_primary_darkest: {
    color: COLORS.PRIMARY_DARKEST,
    fontFamily: "Merriweather, serif",
    fontSize: "1.688rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg2_serif_secondary: {
    color: COLORS.SECONDARY,
    fontFamily: "Merriweather, serif",
    fontSize: "1.688rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg2_serif_secondary_lightest: {
    color: COLORS.SECONDARY_LIGHTEST,
    fontFamily: "Merriweather, serif",
    fontSize: "1.688rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg2_serif_secondary_lighter: {
    color: COLORS.SECONDARY_LIGHTER,
    fontFamily: "Merriweather, serif",
    fontSize: "1.688rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg2_serif_secondary_light: {
    color: COLORS.SECONDARY_LIGHT,
    fontFamily: "Merriweather, serif",
    fontSize: "1.688rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg2_serif_secondary_dark: {
    color: COLORS.SECONDARY_DARK,
    fontFamily: "Merriweather, serif",
    fontSize: "1.688rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg2_serif_secondary_darker: {
    color: COLORS.SECONDARY_DARKER,
    fontFamily: "Merriweather, serif",
    fontSize: "1.688rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg2_serif_secondary_darkest: {
    color: COLORS.SECONDARY_DARKEST,
    fontFamily: "Merriweather, serif",
    fontSize: "1.688rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg2_serif_accent1: {
    color: COLORS.ACCENT1,
    fontFamily: "Merriweather, serif",
    fontSize: "1.688rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg2_serif_accent1_lightest: {
    color: COLORS.ACCENT1_LIGHTEST,
    fontFamily: "Merriweather, serif",
    fontSize: "1.688rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg2_serif_accent1_lighter: {
    color: COLORS.ACCENT1_LIGHTER,
    fontFamily: "Merriweather, serif",
    fontSize: "1.688rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg2_serif_accent1_light: {
    color: COLORS.ACCENT1_LIGHT,
    fontFamily: "Merriweather, serif",
    fontSize: "1.688rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg2_serif_accent1_dark: {
    color: COLORS.ACCENT1_DARK,
    fontFamily: "Merriweather, serif",
    fontSize: "1.688rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg2_serif_accent1_darker: {
    color: COLORS.ACCENT1_DARKER,
    fontFamily: "Merriweather, serif",
    fontSize: "1.688rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg2_serif_accent1_darkest: {
    color: COLORS.ACCENT1_DARKEST,
    fontFamily: "Merriweather, serif",
    fontSize: "1.688rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg2_serif_accent2: {
    color: COLORS.ACCENT2,
    fontFamily: "Merriweather, serif",
    fontSize: "1.688rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg2_serif_accent2_lightest: {
    color: COLORS.ACCENT2_LIGHTEST,
    fontFamily: "Merriweather, serif",
    fontSize: "1.688rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg2_serif_accent2_lighter: {
    color: COLORS.ACCENT2_LIGHTER,
    fontFamily: "Merriweather, serif",
    fontSize: "1.688rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg2_serif_accent2_light: {
    color: COLORS.ACCENT2_LIGHT,
    fontFamily: "Merriweather, serif",
    fontSize: "1.688rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg2_serif_accent2_dark: {
    color: COLORS.ACCENT2_DARK,
    fontFamily: "Merriweather, serif",
    fontSize: "1.688rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg2_serif_accent2_darker: {
    color: COLORS.ACCENT2_DARKER,
    fontFamily: "Merriweather, serif",
    fontSize: "1.688rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg2_serif_accent2_darkest: {
    color: COLORS.ACCENT2_DARKEST,
    fontFamily: "Merriweather, serif",
    fontSize: "1.688rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg2_serif_accent3: {
    color: COLORS.ACCENT3,
    fontFamily: "Merriweather, serif",
    fontSize: "1.688rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg2_serif_accent3_lightest: {
    color: COLORS.ACCENT3_LIGHTEST,
    fontFamily: "Merriweather, serif",
    fontSize: "1.688rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg2_serif_accent3_lighter: {
    color: COLORS.ACCENT3_LIGHTER,
    fontFamily: "Merriweather, serif",
    fontSize: "1.688rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg2_serif_accent3_light: {
    color: COLORS.ACCENT3_LIGHT,
    fontFamily: "Merriweather, serif",
    fontSize: "1.688rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg2_serif_accent3_dark: {
    color: COLORS.ACCENT3_DARK,
    fontFamily: "Merriweather, serif",
    fontSize: "1.688rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg2_serif_accent3_darker: {
    color: COLORS.ACCENT3_DARKER,
    fontFamily: "Merriweather, serif",
    fontSize: "1.688rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg2_serif_accent3_darkest: {
    color: COLORS.ACCENT3_DARKEST,
    fontFamily: "Merriweather, serif",
    fontSize: "1.688rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg2_serif_accent4: {
    color: COLORS.ACCENT4,
    fontFamily: "Merriweather, serif",
    fontSize: "1.688rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg2_serif_accent4_lightest: {
    color: COLORS.ACCENT4_LIGHTEST,
    fontFamily: "Merriweather, serif",
    fontSize: "1.688rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg2_serif_accent4_lighter: {
    color: COLORS.ACCENT4_LIGHTER,
    fontFamily: "Merriweather, serif",
    fontSize: "1.688rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg2_serif_accent4_light: {
    color: COLORS.ACCENT4_LIGHT,
    fontFamily: "Merriweather, serif",
    fontSize: "1.688rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg2_serif_accent4_dark: {
    color: COLORS.ACCENT4_DARK,
    fontFamily: "Merriweather, serif",
    fontSize: "1.688rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg2_serif_accent4_darker: {
    color: COLORS.ACCENT4_DARKER,
    fontFamily: "Merriweather, serif",
    fontSize: "1.688rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg2_serif_accent4_darkest: {
    color: COLORS.ACCENT4_DARKEST,
    fontFamily: "Merriweather, serif",
    fontSize: "1.688rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg2_serif: {
    color: COLORS.BLACK,
    fontFamily: "Merriweather, serif",
    fontSize: "1.688rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg2_white: {
    color: COLORS.WHITE,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.688rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg2_black: {
    color: COLORS.BLACK,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.688rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg2_gray: {
    color: COLORS.GRAY,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.688rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg2_green_2: {
    color: COLORS.GREEN_2,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.688rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg2_dark_gray: {
    color: COLORS.DARK_GRAY,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.688rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg2_dark_gray_2: {
    color: COLORS.DARK_GRAY_2,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.688rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg2_error: {
    color: COLORS.ERROR,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.688rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg2_warning: {
    color: COLORS.WARNING,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.688rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg2_success: {
    color: COLORS.SUCCESS,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.688rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg2_primary: {
    color: COLORS.PRIMARY,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.688rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg2_primary_lightest: {
    color: COLORS.PRIMARY_LIGHTEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.688rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg2_primary_lighter: {
    color: COLORS.PRIMARY_LIGHTER,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.688rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg2_primary_light: {
    color: COLORS.PRIMARY_LIGHT,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.688rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg2_primary_dark: {
    color: COLORS.PRIMARY_DARK,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.688rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg2_primary_darker: {
    color: COLORS.PRIMARY_DARKER,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.688rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg2_primary_darkest: {
    color: COLORS.PRIMARY_DARKEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.688rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg2_secondary: {
    color: COLORS.SECONDARY,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.688rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg2_secondary_lightest: {
    color: COLORS.SECONDARY_LIGHTEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.688rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg2_secondary_lighter: {
    color: COLORS.SECONDARY_LIGHTER,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.688rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg2_secondary_light: {
    color: COLORS.SECONDARY_LIGHT,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.688rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg2_secondary_dark: {
    color: COLORS.SECONDARY_DARK,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.688rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg2_secondary_darker: {
    color: COLORS.SECONDARY_DARKER,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.688rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg2_secondary_darkest: {
    color: COLORS.SECONDARY_DARKEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.688rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg2_accent1: {
    color: COLORS.ACCENT1,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.688rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg2_accent1_lightest: {
    color: COLORS.ACCENT1_LIGHTEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.688rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg2_accent1_lighter: {
    color: COLORS.ACCENT1_LIGHTER,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.688rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg2_accent1_light: {
    color: COLORS.ACCENT1_LIGHT,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.688rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg2_accent1_dark: {
    color: COLORS.ACCENT1_DARK,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.688rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg2_accent1_darker: {
    color: COLORS.ACCENT1_DARKER,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.688rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg2_accent1_darkest: {
    color: COLORS.ACCENT1_DARKEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.688rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg2_accent2: {
    color: COLORS.ACCENT2,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.688rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg2_accent2_lightest: {
    color: COLORS.ACCENT2_LIGHTEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.688rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg2_accent2_lighter: {
    color: COLORS.ACCENT2_LIGHTER,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.688rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg2_accent2_light: {
    color: COLORS.ACCENT2_LIGHT,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.688rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg2_accent2_dark: {
    color: COLORS.ACCENT2_DARK,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.688rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg2_accent2_darker: {
    color: COLORS.ACCENT2_DARKER,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.688rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg2_accent2_darkest: {
    color: COLORS.ACCENT2_DARKEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.688rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg2_accent3: {
    color: COLORS.ACCENT3,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.688rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg2_accent3_lightest: {
    color: COLORS.ACCENT3_LIGHTEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.688rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg2_accent3_lighter: {
    color: COLORS.ACCENT3_LIGHTER,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.688rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg2_accent3_light: {
    color: COLORS.ACCENT3_LIGHT,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.688rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg2_accent3_dark: {
    color: COLORS.ACCENT3_DARK,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.688rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg2_accent3_darker: {
    color: COLORS.ACCENT3_DARKER,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.688rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg2_accent3_darkest: {
    color: COLORS.ACCENT3_DARKEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.688rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg2_accent4: {
    color: COLORS.ACCENT4,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.688rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg2_accent4_lightest: {
    color: COLORS.ACCENT4_LIGHTEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.688rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg2_accent4_lighter: {
    color: COLORS.ACCENT4_LIGHTER,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.688rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg2_accent4_light: {
    color: COLORS.ACCENT4_LIGHT,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.688rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg2_accent4_dark: {
    color: COLORS.ACCENT4_DARK,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.688rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg2_accent4_darker: {
    color: COLORS.ACCENT4_DARKER,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.688rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg2_accent4_darkest: {
    color: COLORS.ACCENT4_DARKEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.688rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg2: {
    color: COLORS.BLACK,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.688rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg1_serif_white: {
    color: COLORS.WHITE,
    fontFamily: "Merriweather, serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg1_serif_black: {
    color: COLORS.BLACK,
    fontFamily: "Merriweather, serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg1_serif_gray: {
    color: COLORS.GRAY,
    fontFamily: "Merriweather, serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg1_serif_green_2: {
    color: COLORS.GREEN_2,
    fontFamily: "Merriweather, serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg1_serif_dark_gray: {
    color: COLORS.DARK_GRAY,
    fontFamily: "Merriweather, serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg1_serif_dark_gray_2: {
    color: COLORS.DARK_GRAY_2,
    fontFamily: "Merriweather, serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg1_serif_error: {
    color: COLORS.ERROR,
    fontFamily: "Merriweather, serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg1_serif_warning: {
    color: COLORS.WARNING,
    fontFamily: "Merriweather, serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg1_serif_success: {
    color: COLORS.SUCCESS,
    fontFamily: "Merriweather, serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg1_serif_primary: {
    color: COLORS.PRIMARY,
    fontFamily: "Merriweather, serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg1_serif_primary_lightest: {
    color: COLORS.PRIMARY_LIGHTEST,
    fontFamily: "Merriweather, serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg1_serif_primary_lighter: {
    color: COLORS.PRIMARY_LIGHTER,
    fontFamily: "Merriweather, serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg1_serif_primary_light: {
    color: COLORS.PRIMARY_LIGHT,
    fontFamily: "Merriweather, serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg1_serif_primary_dark: {
    color: COLORS.PRIMARY_DARK,
    fontFamily: "Merriweather, serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg1_serif_primary_darker: {
    color: COLORS.PRIMARY_DARKER,
    fontFamily: "Merriweather, serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg1_serif_primary_darkest: {
    color: COLORS.PRIMARY_DARKEST,
    fontFamily: "Merriweather, serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg1_serif_secondary: {
    color: COLORS.SECONDARY,
    fontFamily: "Merriweather, serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg1_serif_secondary_lightest: {
    color: COLORS.SECONDARY_LIGHTEST,
    fontFamily: "Merriweather, serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg1_serif_secondary_lighter: {
    color: COLORS.SECONDARY_LIGHTER,
    fontFamily: "Merriweather, serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg1_serif_secondary_light: {
    color: COLORS.SECONDARY_LIGHT,
    fontFamily: "Merriweather, serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg1_serif_secondary_dark: {
    color: COLORS.SECONDARY_DARK,
    fontFamily: "Merriweather, serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg1_serif_secondary_darker: {
    color: COLORS.SECONDARY_DARKER,
    fontFamily: "Merriweather, serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg1_serif_secondary_darkest: {
    color: COLORS.SECONDARY_DARKEST,
    fontFamily: "Merriweather, serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg1_serif_accent1: {
    color: COLORS.ACCENT1,
    fontFamily: "Merriweather, serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg1_serif_accent1_lightest: {
    color: COLORS.ACCENT1_LIGHTEST,
    fontFamily: "Merriweather, serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg1_serif_accent1_lighter: {
    color: COLORS.ACCENT1_LIGHTER,
    fontFamily: "Merriweather, serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg1_serif_accent1_light: {
    color: COLORS.ACCENT1_LIGHT,
    fontFamily: "Merriweather, serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg1_serif_accent1_dark: {
    color: COLORS.ACCENT1_DARK,
    fontFamily: "Merriweather, serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg1_serif_accent1_darker: {
    color: COLORS.ACCENT1_DARKER,
    fontFamily: "Merriweather, serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg1_serif_accent1_darkest: {
    color: COLORS.ACCENT1_DARKEST,
    fontFamily: "Merriweather, serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg1_serif_accent2: {
    color: COLORS.ACCENT2,
    fontFamily: "Merriweather, serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg1_serif_accent2_lightest: {
    color: COLORS.ACCENT2_LIGHTEST,
    fontFamily: "Merriweather, serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg1_serif_accent2_lighter: {
    color: COLORS.ACCENT2_LIGHTER,
    fontFamily: "Merriweather, serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg1_serif_accent2_light: {
    color: COLORS.ACCENT2_LIGHT,
    fontFamily: "Merriweather, serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg1_serif_accent2_dark: {
    color: COLORS.ACCENT2_DARK,
    fontFamily: "Merriweather, serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg1_serif_accent2_darker: {
    color: COLORS.ACCENT2_DARKER,
    fontFamily: "Merriweather, serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg1_serif_accent2_darkest: {
    color: COLORS.ACCENT2_DARKEST,
    fontFamily: "Merriweather, serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg1_serif_accent3: {
    color: COLORS.ACCENT3,
    fontFamily: "Merriweather, serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg1_serif_accent3_lightest: {
    color: COLORS.ACCENT3_LIGHTEST,
    fontFamily: "Merriweather, serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg1_serif_accent3_lighter: {
    color: COLORS.ACCENT3_LIGHTER,
    fontFamily: "Merriweather, serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg1_serif_accent3_light: {
    color: COLORS.ACCENT3_LIGHT,
    fontFamily: "Merriweather, serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg1_serif_accent3_dark: {
    color: COLORS.ACCENT3_DARK,
    fontFamily: "Merriweather, serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg1_serif_accent3_darker: {
    color: COLORS.ACCENT3_DARKER,
    fontFamily: "Merriweather, serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg1_serif_accent3_darkest: {
    color: COLORS.ACCENT3_DARKEST,
    fontFamily: "Merriweather, serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg1_serif_accent4: {
    color: COLORS.ACCENT4,
    fontFamily: "Merriweather, serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg1_serif_accent4_lightest: {
    color: COLORS.ACCENT4_LIGHTEST,
    fontFamily: "Merriweather, serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg1_serif_accent4_lighter: {
    color: COLORS.ACCENT4_LIGHTER,
    fontFamily: "Merriweather, serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg1_serif_accent4_light: {
    color: COLORS.ACCENT4_LIGHT,
    fontFamily: "Merriweather, serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg1_serif_accent4_dark: {
    color: COLORS.ACCENT4_DARK,
    fontFamily: "Merriweather, serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg1_serif_accent4_darker: {
    color: COLORS.ACCENT4_DARKER,
    fontFamily: "Merriweather, serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg1_serif_accent4_darkest: {
    color: COLORS.ACCENT4_DARKEST,
    fontFamily: "Merriweather, serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg1_serif: {
    color: COLORS.BLACK,
    fontFamily: "Merriweather, serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg1_white: {
    color: COLORS.WHITE,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg1_black: {
    color: COLORS.BLACK,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg1_gray: {
    color: COLORS.GRAY,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg1_green_2: {
    color: COLORS.GREEN_2,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg1_dark_gray: {
    color: COLORS.DARK_GRAY,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg1_dark_gray_2: {
    color: COLORS.DARK_GRAY_2,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg1_error: {
    color: COLORS.ERROR,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg1_warning: {
    color: COLORS.WARNING,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg1_success: {
    color: COLORS.SUCCESS,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg1_primary: {
    color: COLORS.PRIMARY,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg1_primary_lightest: {
    color: COLORS.PRIMARY_LIGHTEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg1_primary_lighter: {
    color: COLORS.PRIMARY_LIGHTER,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg1_primary_light: {
    color: COLORS.PRIMARY_LIGHT,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg1_primary_dark: {
    color: COLORS.PRIMARY_DARK,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg1_primary_darker: {
    color: COLORS.PRIMARY_DARKER,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg1_primary_darkest: {
    color: COLORS.PRIMARY_DARKEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg1_secondary: {
    color: COLORS.SECONDARY,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg1_secondary_lightest: {
    color: COLORS.SECONDARY_LIGHTEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg1_secondary_lighter: {
    color: COLORS.SECONDARY_LIGHTER,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg1_secondary_light: {
    color: COLORS.SECONDARY_LIGHT,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg1_secondary_dark: {
    color: COLORS.SECONDARY_DARK,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg1_secondary_darker: {
    color: COLORS.SECONDARY_DARKER,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg1_secondary_darkest: {
    color: COLORS.SECONDARY_DARKEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg1_accent1: {
    color: COLORS.ACCENT1,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg1_accent1_lightest: {
    color: COLORS.ACCENT1_LIGHTEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg1_accent1_lighter: {
    color: COLORS.ACCENT1_LIGHTER,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg1_accent1_light: {
    color: COLORS.ACCENT1_LIGHT,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg1_accent1_dark: {
    color: COLORS.ACCENT1_DARK,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg1_accent1_darker: {
    color: COLORS.ACCENT1_DARKER,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg1_accent1_darkest: {
    color: COLORS.ACCENT1_DARKEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg1_accent2: {
    color: COLORS.ACCENT2,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg1_accent2_lightest: {
    color: COLORS.ACCENT2_LIGHTEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg1_accent2_lighter: {
    color: COLORS.ACCENT2_LIGHTER,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg1_accent2_light: {
    color: COLORS.ACCENT2_LIGHT,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg1_accent2_dark: {
    color: COLORS.ACCENT2_DARK,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg1_accent2_darker: {
    color: COLORS.ACCENT2_DARKER,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg1_accent2_darkest: {
    color: COLORS.ACCENT2_DARKEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg1_accent3: {
    color: COLORS.ACCENT3,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg1_accent3_lightest: {
    color: COLORS.ACCENT3_LIGHTEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg1_accent3_lighter: {
    color: COLORS.ACCENT3_LIGHTER,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg1_accent3_light: {
    color: COLORS.ACCENT3_LIGHT,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg1_accent3_dark: {
    color: COLORS.ACCENT3_DARK,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg1_accent3_darker: {
    color: COLORS.ACCENT3_DARKER,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg1_accent3_darkest: {
    color: COLORS.ACCENT3_DARKEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg1_accent4: {
    color: COLORS.ACCENT4,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg1_accent4_lightest: {
    color: COLORS.ACCENT4_LIGHTEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg1_accent4_lighter: {
    color: COLORS.ACCENT4_LIGHTER,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg1_accent4_light: {
    color: COLORS.ACCENT4_LIGHT,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg1_accent4_dark: {
    color: COLORS.ACCENT4_DARK,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg1_accent4_darker: {
    color: COLORS.ACCENT4_DARKER,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg1_accent4_darkest: {
    color: COLORS.ACCENT4_DARKEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg1: {
    color: COLORS.BLACK,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg_serif_white: {
    color: COLORS.WHITE,
    fontFamily: "Merriweather, serif",
    fontSize: "1.25rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg_serif_black: {
    color: COLORS.BLACK,
    fontFamily: "Merriweather, serif",
    fontSize: "1.25rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg_serif_gray: {
    color: COLORS.GRAY,
    fontFamily: "Merriweather, serif",
    fontSize: "1.25rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg_serif_green_2: {
    color: COLORS.GREEN_2,
    fontFamily: "Merriweather, serif",
    fontSize: "1.25rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg_serif_dark_gray: {
    color: COLORS.DARK_GRAY,
    fontFamily: "Merriweather, serif",
    fontSize: "1.25rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg_serif_dark_gray_2: {
    color: COLORS.DARK_GRAY_2,
    fontFamily: "Merriweather, serif",
    fontSize: "1.25rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg_serif_error: {
    color: COLORS.ERROR,
    fontFamily: "Merriweather, serif",
    fontSize: "1.25rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg_serif_warning: {
    color: COLORS.WARNING,
    fontFamily: "Merriweather, serif",
    fontSize: "1.25rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg_serif_success: {
    color: COLORS.SUCCESS,
    fontFamily: "Merriweather, serif",
    fontSize: "1.25rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg_serif_primary: {
    color: COLORS.PRIMARY,
    fontFamily: "Merriweather, serif",
    fontSize: "1.25rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg_serif_primary_lightest: {
    color: COLORS.PRIMARY_LIGHTEST,
    fontFamily: "Merriweather, serif",
    fontSize: "1.25rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg_serif_primary_lighter: {
    color: COLORS.PRIMARY_LIGHTER,
    fontFamily: "Merriweather, serif",
    fontSize: "1.25rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg_serif_primary_light: {
    color: COLORS.PRIMARY_LIGHT,
    fontFamily: "Merriweather, serif",
    fontSize: "1.25rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg_serif_primary_dark: {
    color: COLORS.PRIMARY_DARK,
    fontFamily: "Merriweather, serif",
    fontSize: "1.25rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg_serif_primary_darker: {
    color: COLORS.PRIMARY_DARKER,
    fontFamily: "Merriweather, serif",
    fontSize: "1.25rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg_serif_primary_darkest: {
    color: COLORS.PRIMARY_DARKEST,
    fontFamily: "Merriweather, serif",
    fontSize: "1.25rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg_serif_secondary: {
    color: COLORS.SECONDARY,
    fontFamily: "Merriweather, serif",
    fontSize: "1.25rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg_serif_secondary_lightest: {
    color: COLORS.SECONDARY_LIGHTEST,
    fontFamily: "Merriweather, serif",
    fontSize: "1.25rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg_serif_secondary_lighter: {
    color: COLORS.SECONDARY_LIGHTER,
    fontFamily: "Merriweather, serif",
    fontSize: "1.25rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg_serif_secondary_light: {
    color: COLORS.SECONDARY_LIGHT,
    fontFamily: "Merriweather, serif",
    fontSize: "1.25rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg_serif_secondary_dark: {
    color: COLORS.SECONDARY_DARK,
    fontFamily: "Merriweather, serif",
    fontSize: "1.25rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg_serif_secondary_darker: {
    color: COLORS.SECONDARY_DARKER,
    fontFamily: "Merriweather, serif",
    fontSize: "1.25rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg_serif_secondary_darkest: {
    color: COLORS.SECONDARY_DARKEST,
    fontFamily: "Merriweather, serif",
    fontSize: "1.25rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg_serif_accent1: {
    color: COLORS.ACCENT1,
    fontFamily: "Merriweather, serif",
    fontSize: "1.25rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg_serif_accent1_lightest: {
    color: COLORS.ACCENT1_LIGHTEST,
    fontFamily: "Merriweather, serif",
    fontSize: "1.25rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg_serif_accent1_lighter: {
    color: COLORS.ACCENT1_LIGHTER,
    fontFamily: "Merriweather, serif",
    fontSize: "1.25rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg_serif_accent1_light: {
    color: COLORS.ACCENT1_LIGHT,
    fontFamily: "Merriweather, serif",
    fontSize: "1.25rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg_serif_accent1_dark: {
    color: COLORS.ACCENT1_DARK,
    fontFamily: "Merriweather, serif",
    fontSize: "1.25rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg_serif_accent1_darker: {
    color: COLORS.ACCENT1_DARKER,
    fontFamily: "Merriweather, serif",
    fontSize: "1.25rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg_serif_accent1_darkest: {
    color: COLORS.ACCENT1_DARKEST,
    fontFamily: "Merriweather, serif",
    fontSize: "1.25rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg_serif_accent2: {
    color: COLORS.ACCENT2,
    fontFamily: "Merriweather, serif",
    fontSize: "1.25rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg_serif_accent2_lightest: {
    color: COLORS.ACCENT2_LIGHTEST,
    fontFamily: "Merriweather, serif",
    fontSize: "1.25rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg_serif_accent2_lighter: {
    color: COLORS.ACCENT2_LIGHTER,
    fontFamily: "Merriweather, serif",
    fontSize: "1.25rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg_serif_accent2_light: {
    color: COLORS.ACCENT2_LIGHT,
    fontFamily: "Merriweather, serif",
    fontSize: "1.25rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg_serif_accent2_dark: {
    color: COLORS.ACCENT2_DARK,
    fontFamily: "Merriweather, serif",
    fontSize: "1.25rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg_serif_accent2_darker: {
    color: COLORS.ACCENT2_DARKER,
    fontFamily: "Merriweather, serif",
    fontSize: "1.25rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg_serif_accent2_darkest: {
    color: COLORS.ACCENT2_DARKEST,
    fontFamily: "Merriweather, serif",
    fontSize: "1.25rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg_serif_accent3: {
    color: COLORS.ACCENT3,
    fontFamily: "Merriweather, serif",
    fontSize: "1.25rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg_serif_accent3_lightest: {
    color: COLORS.ACCENT3_LIGHTEST,
    fontFamily: "Merriweather, serif",
    fontSize: "1.25rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg_serif_accent3_lighter: {
    color: COLORS.ACCENT3_LIGHTER,
    fontFamily: "Merriweather, serif",
    fontSize: "1.25rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg_serif_accent3_light: {
    color: COLORS.ACCENT3_LIGHT,
    fontFamily: "Merriweather, serif",
    fontSize: "1.25rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg_serif_accent3_dark: {
    color: COLORS.ACCENT3_DARK,
    fontFamily: "Merriweather, serif",
    fontSize: "1.25rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg_serif_accent3_darker: {
    color: COLORS.ACCENT3_DARKER,
    fontFamily: "Merriweather, serif",
    fontSize: "1.25rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg_serif_accent3_darkest: {
    color: COLORS.ACCENT3_DARKEST,
    fontFamily: "Merriweather, serif",
    fontSize: "1.25rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg_serif_accent4: {
    color: COLORS.ACCENT4,
    fontFamily: "Merriweather, serif",
    fontSize: "1.25rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg_serif_accent4_lightest: {
    color: COLORS.ACCENT4_LIGHTEST,
    fontFamily: "Merriweather, serif",
    fontSize: "1.25rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg_serif_accent4_lighter: {
    color: COLORS.ACCENT4_LIGHTER,
    fontFamily: "Merriweather, serif",
    fontSize: "1.25rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg_serif_accent4_light: {
    color: COLORS.ACCENT4_LIGHT,
    fontFamily: "Merriweather, serif",
    fontSize: "1.25rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg_serif_accent4_dark: {
    color: COLORS.ACCENT4_DARK,
    fontFamily: "Merriweather, serif",
    fontSize: "1.25rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg_serif_accent4_darker: {
    color: COLORS.ACCENT4_DARKER,
    fontFamily: "Merriweather, serif",
    fontSize: "1.25rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg_serif_accent4_darkest: {
    color: COLORS.ACCENT4_DARKEST,
    fontFamily: "Merriweather, serif",
    fontSize: "1.25rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg_serif: {
    color: COLORS.BLACK,
    fontFamily: "Merriweather, serif",
    fontSize: "1.25rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg_white: {
    color: COLORS.WHITE,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.25rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg_black: {
    color: COLORS.BLACK,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.25rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg_gray: {
    color: COLORS.GRAY,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.25rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg_green_2: {
    color: COLORS.GREEN_2,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.25rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg_dark_gray: {
    color: COLORS.DARK_GRAY,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.25rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg_dark_gray_2: {
    color: COLORS.DARK_GRAY_2,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.25rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg_error: {
    color: COLORS.ERROR,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.25rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg_warning: {
    color: COLORS.WARNING,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.25rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg_success: {
    color: COLORS.SUCCESS,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.25rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg_primary: {
    color: COLORS.PRIMARY,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.25rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg_primary_lightest: {
    color: COLORS.PRIMARY_LIGHTEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.25rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg_primary_lighter: {
    color: COLORS.PRIMARY_LIGHTER,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.25rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg_primary_light: {
    color: COLORS.PRIMARY_LIGHT,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.25rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg_primary_dark: {
    color: COLORS.PRIMARY_DARK,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.25rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg_primary_darker: {
    color: COLORS.PRIMARY_DARKER,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.25rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg_primary_darkest: {
    color: COLORS.PRIMARY_DARKEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.25rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg_secondary: {
    color: COLORS.SECONDARY,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.25rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg_secondary_lightest: {
    color: COLORS.SECONDARY_LIGHTEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.25rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg_secondary_lighter: {
    color: COLORS.SECONDARY_LIGHTER,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.25rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg_secondary_light: {
    color: COLORS.SECONDARY_LIGHT,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.25rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg_secondary_dark: {
    color: COLORS.SECONDARY_DARK,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.25rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg_secondary_darker: {
    color: COLORS.SECONDARY_DARKER,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.25rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg_secondary_darkest: {
    color: COLORS.SECONDARY_DARKEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.25rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg_accent1: {
    color: COLORS.ACCENT1,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.25rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg_accent1_lightest: {
    color: COLORS.ACCENT1_LIGHTEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.25rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg_accent1_lighter: {
    color: COLORS.ACCENT1_LIGHTER,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.25rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg_accent1_light: {
    color: COLORS.ACCENT1_LIGHT,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.25rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg_accent1_dark: {
    color: COLORS.ACCENT1_DARK,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.25rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg_accent1_darker: {
    color: COLORS.ACCENT1_DARKER,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.25rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg_accent1_darkest: {
    color: COLORS.ACCENT1_DARKEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.25rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg_accent2: {
    color: COLORS.ACCENT2,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.25rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg_accent2_lightest: {
    color: COLORS.ACCENT2_LIGHTEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.25rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg_accent2_lighter: {
    color: COLORS.ACCENT2_LIGHTER,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.25rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg_accent2_light: {
    color: COLORS.ACCENT2_LIGHT,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.25rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg_accent2_dark: {
    color: COLORS.ACCENT2_DARK,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.25rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg_accent2_darker: {
    color: COLORS.ACCENT2_DARKER,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.25rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg_accent2_darkest: {
    color: COLORS.ACCENT2_DARKEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.25rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg_accent3: {
    color: COLORS.ACCENT3,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.25rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg_accent3_lightest: {
    color: COLORS.ACCENT3_LIGHTEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.25rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg_accent3_lighter: {
    color: COLORS.ACCENT3_LIGHTER,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.25rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg_accent3_light: {
    color: COLORS.ACCENT3_LIGHT,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.25rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg_accent3_dark: {
    color: COLORS.ACCENT3_DARK,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.25rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg_accent3_darker: {
    color: COLORS.ACCENT3_DARKER,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.25rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg_accent3_darkest: {
    color: COLORS.ACCENT3_DARKEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.25rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg_accent4: {
    color: COLORS.ACCENT4,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.25rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg_accent4_lightest: {
    color: COLORS.ACCENT4_LIGHTEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.25rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg_accent4_lighter: {
    color: COLORS.ACCENT4_LIGHTER,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.25rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg_accent4_light: {
    color: COLORS.ACCENT4_LIGHT,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.25rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg_accent4_dark: {
    color: COLORS.ACCENT4_DARK,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.25rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg_accent4_darker: {
    color: COLORS.ACCENT4_DARKER,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.25rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg_accent4_darkest: {
    color: COLORS.ACCENT4_DARKEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.25rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_lg: {
    color: COLORS.BLACK,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.25rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_serif_white: {
    color: COLORS.WHITE,
    fontFamily: "Merriweather, serif",
    fontSize: "1rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "150%"
  },
  body_serif_black: {
    color: COLORS.BLACK,
    fontFamily: "Merriweather, serif",
    fontSize: "1rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "150%"
  },
  body_serif_gray: {
    color: COLORS.GRAY,
    fontFamily: "Merriweather, serif",
    fontSize: "1rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "150%"
  },
  body_serif_green_2: {
    color: COLORS.GREEN_2,
    fontFamily: "Merriweather, serif",
    fontSize: "1rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "150%"
  },
  body_serif_dark_gray: {
    color: COLORS.DARK_GRAY,
    fontFamily: "Merriweather, serif",
    fontSize: "1rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "150%"
  },
  body_serif_dark_gray_2: {
    color: COLORS.DARK_GRAY_2,
    fontFamily: "Merriweather, serif",
    fontSize: "1rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "150%"
  },
  body_serif_error: {
    color: COLORS.ERROR,
    fontFamily: "Merriweather, serif",
    fontSize: "1rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "150%"
  },
  body_serif_warning: {
    color: COLORS.WARNING,
    fontFamily: "Merriweather, serif",
    fontSize: "1rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "150%"
  },
  body_serif_success: {
    color: COLORS.SUCCESS,
    fontFamily: "Merriweather, serif",
    fontSize: "1rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "150%"
  },
  body_serif_primary: {
    color: COLORS.PRIMARY,
    fontFamily: "Merriweather, serif",
    fontSize: "1rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "150%"
  },
  body_serif_primary_lightest: {
    color: COLORS.PRIMARY_LIGHTEST,
    fontFamily: "Merriweather, serif",
    fontSize: "1rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "150%"
  },
  body_serif_primary_lighter: {
    color: COLORS.PRIMARY_LIGHTER,
    fontFamily: "Merriweather, serif",
    fontSize: "1rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "150%"
  },
  body_serif_primary_light: {
    color: COLORS.PRIMARY_LIGHT,
    fontFamily: "Merriweather, serif",
    fontSize: "1rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "150%"
  },
  body_serif_primary_dark: {
    color: COLORS.PRIMARY_DARK,
    fontFamily: "Merriweather, serif",
    fontSize: "1rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "150%"
  },
  body_serif_primary_darker: {
    color: COLORS.PRIMARY_DARKER,
    fontFamily: "Merriweather, serif",
    fontSize: "1rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "150%"
  },
  body_serif_primary_darkest: {
    color: COLORS.PRIMARY_DARKEST,
    fontFamily: "Merriweather, serif",
    fontSize: "1rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "150%"
  },
  body_serif_secondary: {
    color: COLORS.SECONDARY,
    fontFamily: "Merriweather, serif",
    fontSize: "1rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "150%"
  },
  body_serif_secondary_lightest: {
    color: COLORS.SECONDARY_LIGHTEST,
    fontFamily: "Merriweather, serif",
    fontSize: "1rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "150%"
  },
  body_serif_secondary_lighter: {
    color: COLORS.SECONDARY_LIGHTER,
    fontFamily: "Merriweather, serif",
    fontSize: "1rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "150%"
  },
  body_serif_secondary_light: {
    color: COLORS.SECONDARY_LIGHT,
    fontFamily: "Merriweather, serif",
    fontSize: "1rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "150%"
  },
  body_serif_secondary_dark: {
    color: COLORS.SECONDARY_DARK,
    fontFamily: "Merriweather, serif",
    fontSize: "1rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "150%"
  },
  body_serif_secondary_darker: {
    color: COLORS.SECONDARY_DARKER,
    fontFamily: "Merriweather, serif",
    fontSize: "1rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "150%"
  },
  body_serif_secondary_darkest: {
    color: COLORS.SECONDARY_DARKEST,
    fontFamily: "Merriweather, serif",
    fontSize: "1rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "150%"
  },
  body_serif_accent1: {
    color: COLORS.ACCENT1,
    fontFamily: "Merriweather, serif",
    fontSize: "1rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "150%"
  },
  body_serif_accent1_lightest: {
    color: COLORS.ACCENT1_LIGHTEST,
    fontFamily: "Merriweather, serif",
    fontSize: "1rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "150%"
  },
  body_serif_accent1_lighter: {
    color: COLORS.ACCENT1_LIGHTER,
    fontFamily: "Merriweather, serif",
    fontSize: "1rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "150%"
  },
  body_serif_accent1_light: {
    color: COLORS.ACCENT1_LIGHT,
    fontFamily: "Merriweather, serif",
    fontSize: "1rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "150%"
  },
  body_serif_accent1_dark: {
    color: COLORS.ACCENT1_DARK,
    fontFamily: "Merriweather, serif",
    fontSize: "1rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "150%"
  },
  body_serif_accent1_darker: {
    color: COLORS.ACCENT1_DARKER,
    fontFamily: "Merriweather, serif",
    fontSize: "1rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "150%"
  },
  body_serif_accent1_darkest: {
    color: COLORS.ACCENT1_DARKEST,
    fontFamily: "Merriweather, serif",
    fontSize: "1rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "150%"
  },
  body_serif_accent2: {
    color: COLORS.ACCENT2,
    fontFamily: "Merriweather, serif",
    fontSize: "1rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "150%"
  },
  body_serif_accent2_lightest: {
    color: COLORS.ACCENT2_LIGHTEST,
    fontFamily: "Merriweather, serif",
    fontSize: "1rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "150%"
  },
  body_serif_accent2_lighter: {
    color: COLORS.ACCENT2_LIGHTER,
    fontFamily: "Merriweather, serif",
    fontSize: "1rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "150%"
  },
  body_serif_accent2_light: {
    color: COLORS.ACCENT2_LIGHT,
    fontFamily: "Merriweather, serif",
    fontSize: "1rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "150%"
  },
  body_serif_accent2_dark: {
    color: COLORS.ACCENT2_DARK,
    fontFamily: "Merriweather, serif",
    fontSize: "1rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "150%"
  },
  body_serif_accent2_darker: {
    color: COLORS.ACCENT2_DARKER,
    fontFamily: "Merriweather, serif",
    fontSize: "1rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "150%"
  },
  body_serif_accent2_darkest: {
    color: COLORS.ACCENT2_DARKEST,
    fontFamily: "Merriweather, serif",
    fontSize: "1rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "150%"
  },
  body_serif_accent3: {
    color: COLORS.ACCENT3,
    fontFamily: "Merriweather, serif",
    fontSize: "1rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "150%"
  },
  body_serif_accent3_lightest: {
    color: COLORS.ACCENT3_LIGHTEST,
    fontFamily: "Merriweather, serif",
    fontSize: "1rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "150%"
  },
  body_serif_accent3_lighter: {
    color: COLORS.ACCENT3_LIGHTER,
    fontFamily: "Merriweather, serif",
    fontSize: "1rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "150%"
  },
  body_serif_accent3_light: {
    color: COLORS.ACCENT3_LIGHT,
    fontFamily: "Merriweather, serif",
    fontSize: "1rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "150%"
  },
  body_serif_accent3_dark: {
    color: COLORS.ACCENT3_DARK,
    fontFamily: "Merriweather, serif",
    fontSize: "1rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "150%"
  },
  body_serif_accent3_darker: {
    color: COLORS.ACCENT3_DARKER,
    fontFamily: "Merriweather, serif",
    fontSize: "1rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "150%"
  },
  body_serif_accent3_darkest: {
    color: COLORS.ACCENT3_DARKEST,
    fontFamily: "Merriweather, serif",
    fontSize: "1rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "150%"
  },
  body_serif_accent4: {
    color: COLORS.ACCENT4,
    fontFamily: "Merriweather, serif",
    fontSize: "1rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "150%"
  },
  body_serif_accent4_lightest: {
    color: COLORS.ACCENT4_LIGHTEST,
    fontFamily: "Merriweather, serif",
    fontSize: "1rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "150%"
  },
  body_serif_accent4_lighter: {
    color: COLORS.ACCENT4_LIGHTER,
    fontFamily: "Merriweather, serif",
    fontSize: "1rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "150%"
  },
  body_serif_accent4_light: {
    color: COLORS.ACCENT4_LIGHT,
    fontFamily: "Merriweather, serif",
    fontSize: "1rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "150%"
  },
  body_serif_accent4_dark: {
    color: COLORS.ACCENT4_DARK,
    fontFamily: "Merriweather, serif",
    fontSize: "1rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "150%"
  },
  body_serif_accent4_darker: {
    color: COLORS.ACCENT4_DARKER,
    fontFamily: "Merriweather, serif",
    fontSize: "1rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "150%"
  },
  body_serif_accent4_darkest: {
    color: COLORS.ACCENT4_DARKEST,
    fontFamily: "Merriweather, serif",
    fontSize: "1rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "150%"
  },
  body_serif: {
    color: COLORS.BLACK,
    fontFamily: "Merriweather, serif",
    fontSize: "1rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "150%"
  },
  body_white: {
    color: COLORS.WHITE,
    fontFamily: "Inter, sans-serif",
    fontSize: "1rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "150%"
  },
  body_black: {
    color: COLORS.BLACK,
    fontFamily: "Inter, sans-serif",
    fontSize: "1rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "150%"
  },
  body_gray: {
    color: COLORS.GRAY,
    fontFamily: "Inter, sans-serif",
    fontSize: "1rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "150%"
  },
  body_green_2: {
    color: COLORS.GREEN_2,
    fontFamily: "Inter, sans-serif",
    fontSize: "1rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "150%"
  },
  body_dark_gray: {
    color: COLORS.DARK_GRAY,
    fontFamily: "Inter, sans-serif",
    fontSize: "1rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "150%"
  },
  body_dark_gray_2: {
    color: COLORS.DARK_GRAY_2,
    fontFamily: "Inter, sans-serif",
    fontSize: "1rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "150%"
  },
  body_error: {
    color: COLORS.ERROR,
    fontFamily: "Inter, sans-serif",
    fontSize: "1rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "150%"
  },
  body_warning: {
    color: COLORS.WARNING,
    fontFamily: "Inter, sans-serif",
    fontSize: "1rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "150%"
  },
  body_success: {
    color: COLORS.SUCCESS,
    fontFamily: "Inter, sans-serif",
    fontSize: "1rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "150%"
  },
  body_primary: {
    color: COLORS.PRIMARY,
    fontFamily: "Inter, sans-serif",
    fontSize: "1rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "150%"
  },
  body_primary_lightest: {
    color: COLORS.PRIMARY_LIGHTEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "1rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "150%"
  },
  body_primary_lighter: {
    color: COLORS.PRIMARY_LIGHTER,
    fontFamily: "Inter, sans-serif",
    fontSize: "1rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "150%"
  },
  body_primary_light: {
    color: COLORS.PRIMARY_LIGHT,
    fontFamily: "Inter, sans-serif",
    fontSize: "1rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "150%"
  },
  body_primary_dark: {
    color: COLORS.PRIMARY_DARK,
    fontFamily: "Inter, sans-serif",
    fontSize: "1rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "150%"
  },
  body_primary_darker: {
    color: COLORS.PRIMARY_DARKER,
    fontFamily: "Inter, sans-serif",
    fontSize: "1rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "150%"
  },
  body_primary_darkest: {
    color: COLORS.PRIMARY_DARKEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "1rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "150%"
  },
  body_secondary: {
    color: COLORS.SECONDARY,
    fontFamily: "Inter, sans-serif",
    fontSize: "1rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "150%"
  },
  body_secondary_lightest: {
    color: COLORS.SECONDARY_LIGHTEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "1rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "150%"
  },
  body_secondary_lighter: {
    color: COLORS.SECONDARY_LIGHTER,
    fontFamily: "Inter, sans-serif",
    fontSize: "1rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "150%"
  },
  body_secondary_light: {
    color: COLORS.SECONDARY_LIGHT,
    fontFamily: "Inter, sans-serif",
    fontSize: "1rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "150%"
  },
  body_secondary_dark: {
    color: COLORS.SECONDARY_DARK,
    fontFamily: "Inter, sans-serif",
    fontSize: "1rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "150%"
  },
  body_secondary_darker: {
    color: COLORS.SECONDARY_DARKER,
    fontFamily: "Inter, sans-serif",
    fontSize: "1rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "150%"
  },
  body_secondary_darkest: {
    color: COLORS.SECONDARY_DARKEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "1rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "150%"
  },
  body_accent1: {
    color: COLORS.ACCENT1,
    fontFamily: "Inter, sans-serif",
    fontSize: "1rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "150%"
  },
  body_accent1_lightest: {
    color: COLORS.ACCENT1_LIGHTEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "1rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "150%"
  },
  body_accent1_lighter: {
    color: COLORS.ACCENT1_LIGHTER,
    fontFamily: "Inter, sans-serif",
    fontSize: "1rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "150%"
  },
  body_accent1_light: {
    color: COLORS.ACCENT1_LIGHT,
    fontFamily: "Inter, sans-serif",
    fontSize: "1rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "150%"
  },
  body_accent1_dark: {
    color: COLORS.ACCENT1_DARK,
    fontFamily: "Inter, sans-serif",
    fontSize: "1rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "150%"
  },
  body_accent1_darker: {
    color: COLORS.ACCENT1_DARKER,
    fontFamily: "Inter, sans-serif",
    fontSize: "1rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "150%"
  },
  body_accent1_darkest: {
    color: COLORS.ACCENT1_DARKEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "1rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "150%"
  },
  body_accent2: {
    color: COLORS.ACCENT2,
    fontFamily: "Inter, sans-serif",
    fontSize: "1rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "150%"
  },
  body_accent2_lightest: {
    color: COLORS.ACCENT2_LIGHTEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "1rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "150%"
  },
  body_accent2_lighter: {
    color: COLORS.ACCENT2_LIGHTER,
    fontFamily: "Inter, sans-serif",
    fontSize: "1rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "150%"
  },
  body_accent2_light: {
    color: COLORS.ACCENT2_LIGHT,
    fontFamily: "Inter, sans-serif",
    fontSize: "1rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "150%"
  },
  body_accent2_dark: {
    color: COLORS.ACCENT2_DARK,
    fontFamily: "Inter, sans-serif",
    fontSize: "1rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "150%"
  },
  body_accent2_darker: {
    color: COLORS.ACCENT2_DARKER,
    fontFamily: "Inter, sans-serif",
    fontSize: "1rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "150%"
  },
  body_accent2_darkest: {
    color: COLORS.ACCENT2_DARKEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "1rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "150%"
  },
  body_accent3: {
    color: COLORS.ACCENT3,
    fontFamily: "Inter, sans-serif",
    fontSize: "1rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "150%"
  },
  body_accent3_lightest: {
    color: COLORS.ACCENT3_LIGHTEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "1rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "150%"
  },
  body_accent3_lighter: {
    color: COLORS.ACCENT3_LIGHTER,
    fontFamily: "Inter, sans-serif",
    fontSize: "1rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "150%"
  },
  body_accent3_light: {
    color: COLORS.ACCENT3_LIGHT,
    fontFamily: "Inter, sans-serif",
    fontSize: "1rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "150%"
  },
  body_accent3_dark: {
    color: COLORS.ACCENT3_DARK,
    fontFamily: "Inter, sans-serif",
    fontSize: "1rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "150%"
  },
  body_accent3_darker: {
    color: COLORS.ACCENT3_DARKER,
    fontFamily: "Inter, sans-serif",
    fontSize: "1rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "150%"
  },
  body_accent3_darkest: {
    color: COLORS.ACCENT3_DARKEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "1rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "150%"
  },
  body_accent4: {
    color: COLORS.ACCENT4,
    fontFamily: "Inter, sans-serif",
    fontSize: "1rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "150%"
  },
  body_accent4_lightest: {
    color: COLORS.ACCENT4_LIGHTEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "1rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "150%"
  },
  body_accent4_lighter: {
    color: COLORS.ACCENT4_LIGHTER,
    fontFamily: "Inter, sans-serif",
    fontSize: "1rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "150%"
  },
  body_accent4_light: {
    color: COLORS.ACCENT4_LIGHT,
    fontFamily: "Inter, sans-serif",
    fontSize: "1rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "150%"
  },
  body_accent4_dark: {
    color: COLORS.ACCENT4_DARK,
    fontFamily: "Inter, sans-serif",
    fontSize: "1rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "150%"
  },
  body_accent4_darker: {
    color: COLORS.ACCENT4_DARKER,
    fontFamily: "Inter, sans-serif",
    fontSize: "1rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "150%"
  },
  body_accent4_darkest: {
    color: COLORS.ACCENT4_DARKEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "1rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "150%"
  },
  body: {
    color: COLORS.BLACK,
    fontFamily: "Inter, sans-serif",
    fontSize: "1rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "150%"
  },
  body_sm_serif_white: {
    color: COLORS.WHITE,
    fontFamily: "Merriweather, serif",
    fontSize: "0.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_sm_serif_black: {
    color: COLORS.BLACK,
    fontFamily: "Merriweather, serif",
    fontSize: "0.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_sm_serif_gray: {
    color: COLORS.GRAY,
    fontFamily: "Merriweather, serif",
    fontSize: "0.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_sm_serif_green_2: {
    color: COLORS.GREEN_2,
    fontFamily: "Merriweather, serif",
    fontSize: "0.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_sm_serif_dark_gray: {
    color: COLORS.DARK_GRAY,
    fontFamily: "Merriweather, serif",
    fontSize: "0.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_sm_serif_dark_gray_2: {
    color: COLORS.DARK_GRAY_2,
    fontFamily: "Merriweather, serif",
    fontSize: "0.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_sm_serif_error: {
    color: COLORS.ERROR,
    fontFamily: "Merriweather, serif",
    fontSize: "0.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_sm_serif_warning: {
    color: COLORS.WARNING,
    fontFamily: "Merriweather, serif",
    fontSize: "0.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_sm_serif_success: {
    color: COLORS.SUCCESS,
    fontFamily: "Merriweather, serif",
    fontSize: "0.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_sm_serif_primary: {
    color: COLORS.PRIMARY,
    fontFamily: "Merriweather, serif",
    fontSize: "0.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_sm_serif_primary_lightest: {
    color: COLORS.PRIMARY_LIGHTEST,
    fontFamily: "Merriweather, serif",
    fontSize: "0.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_sm_serif_primary_lighter: {
    color: COLORS.PRIMARY_LIGHTER,
    fontFamily: "Merriweather, serif",
    fontSize: "0.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_sm_serif_primary_light: {
    color: COLORS.PRIMARY_LIGHT,
    fontFamily: "Merriweather, serif",
    fontSize: "0.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_sm_serif_primary_dark: {
    color: COLORS.PRIMARY_DARK,
    fontFamily: "Merriweather, serif",
    fontSize: "0.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_sm_serif_primary_darker: {
    color: COLORS.PRIMARY_DARKER,
    fontFamily: "Merriweather, serif",
    fontSize: "0.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_sm_serif_primary_darkest: {
    color: COLORS.PRIMARY_DARKEST,
    fontFamily: "Merriweather, serif",
    fontSize: "0.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_sm_serif_secondary: {
    color: COLORS.SECONDARY,
    fontFamily: "Merriweather, serif",
    fontSize: "0.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_sm_serif_secondary_lightest: {
    color: COLORS.SECONDARY_LIGHTEST,
    fontFamily: "Merriweather, serif",
    fontSize: "0.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_sm_serif_secondary_lighter: {
    color: COLORS.SECONDARY_LIGHTER,
    fontFamily: "Merriweather, serif",
    fontSize: "0.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_sm_serif_secondary_light: {
    color: COLORS.SECONDARY_LIGHT,
    fontFamily: "Merriweather, serif",
    fontSize: "0.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_sm_serif_secondary_dark: {
    color: COLORS.SECONDARY_DARK,
    fontFamily: "Merriweather, serif",
    fontSize: "0.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_sm_serif_secondary_darker: {
    color: COLORS.SECONDARY_DARKER,
    fontFamily: "Merriweather, serif",
    fontSize: "0.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_sm_serif_secondary_darkest: {
    color: COLORS.SECONDARY_DARKEST,
    fontFamily: "Merriweather, serif",
    fontSize: "0.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_sm_serif_accent1: {
    color: COLORS.ACCENT1,
    fontFamily: "Merriweather, serif",
    fontSize: "0.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_sm_serif_accent1_lightest: {
    color: COLORS.ACCENT1_LIGHTEST,
    fontFamily: "Merriweather, serif",
    fontSize: "0.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_sm_serif_accent1_lighter: {
    color: COLORS.ACCENT1_LIGHTER,
    fontFamily: "Merriweather, serif",
    fontSize: "0.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_sm_serif_accent1_light: {
    color: COLORS.ACCENT1_LIGHT,
    fontFamily: "Merriweather, serif",
    fontSize: "0.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_sm_serif_accent1_dark: {
    color: COLORS.ACCENT1_DARK,
    fontFamily: "Merriweather, serif",
    fontSize: "0.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_sm_serif_accent1_darker: {
    color: COLORS.ACCENT1_DARKER,
    fontFamily: "Merriweather, serif",
    fontSize: "0.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_sm_serif_accent1_darkest: {
    color: COLORS.ACCENT1_DARKEST,
    fontFamily: "Merriweather, serif",
    fontSize: "0.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_sm_serif_accent2: {
    color: COLORS.ACCENT2,
    fontFamily: "Merriweather, serif",
    fontSize: "0.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_sm_serif_accent2_lightest: {
    color: COLORS.ACCENT2_LIGHTEST,
    fontFamily: "Merriweather, serif",
    fontSize: "0.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_sm_serif_accent2_lighter: {
    color: COLORS.ACCENT2_LIGHTER,
    fontFamily: "Merriweather, serif",
    fontSize: "0.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_sm_serif_accent2_light: {
    color: COLORS.ACCENT2_LIGHT,
    fontFamily: "Merriweather, serif",
    fontSize: "0.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_sm_serif_accent2_dark: {
    color: COLORS.ACCENT2_DARK,
    fontFamily: "Merriweather, serif",
    fontSize: "0.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_sm_serif_accent2_darker: {
    color: COLORS.ACCENT2_DARKER,
    fontFamily: "Merriweather, serif",
    fontSize: "0.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_sm_serif_accent2_darkest: {
    color: COLORS.ACCENT2_DARKEST,
    fontFamily: "Merriweather, serif",
    fontSize: "0.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_sm_serif_accent3: {
    color: COLORS.ACCENT3,
    fontFamily: "Merriweather, serif",
    fontSize: "0.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_sm_serif_accent3_lightest: {
    color: COLORS.ACCENT3_LIGHTEST,
    fontFamily: "Merriweather, serif",
    fontSize: "0.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_sm_serif_accent3_lighter: {
    color: COLORS.ACCENT3_LIGHTER,
    fontFamily: "Merriweather, serif",
    fontSize: "0.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_sm_serif_accent3_light: {
    color: COLORS.ACCENT3_LIGHT,
    fontFamily: "Merriweather, serif",
    fontSize: "0.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_sm_serif_accent3_dark: {
    color: COLORS.ACCENT3_DARK,
    fontFamily: "Merriweather, serif",
    fontSize: "0.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_sm_serif_accent3_darker: {
    color: COLORS.ACCENT3_DARKER,
    fontFamily: "Merriweather, serif",
    fontSize: "0.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_sm_serif_accent3_darkest: {
    color: COLORS.ACCENT3_DARKEST,
    fontFamily: "Merriweather, serif",
    fontSize: "0.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_sm_serif_accent4: {
    color: COLORS.ACCENT4,
    fontFamily: "Merriweather, serif",
    fontSize: "0.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_sm_serif_accent4_lightest: {
    color: COLORS.ACCENT4_LIGHTEST,
    fontFamily: "Merriweather, serif",
    fontSize: "0.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_sm_serif_accent4_lighter: {
    color: COLORS.ACCENT4_LIGHTER,
    fontFamily: "Merriweather, serif",
    fontSize: "0.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_sm_serif_accent4_light: {
    color: COLORS.ACCENT4_LIGHT,
    fontFamily: "Merriweather, serif",
    fontSize: "0.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_sm_serif_accent4_dark: {
    color: COLORS.ACCENT4_DARK,
    fontFamily: "Merriweather, serif",
    fontSize: "0.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_sm_serif_accent4_darker: {
    color: COLORS.ACCENT4_DARKER,
    fontFamily: "Merriweather, serif",
    fontSize: "0.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_sm_serif_accent4_darkest: {
    color: COLORS.ACCENT4_DARKEST,
    fontFamily: "Merriweather, serif",
    fontSize: "0.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_sm_serif: {
    color: COLORS.BLACK,
    fontFamily: "Merriweather, serif",
    fontSize: "0.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_sm_white: {
    color: COLORS.WHITE,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_sm_black: {
    color: COLORS.BLACK,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_sm_gray: {
    color: COLORS.GRAY,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_sm_green_2: {
    color: COLORS.GREEN_2,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_sm_dark_gray: {
    color: COLORS.DARK_GRAY,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_sm_dark_gray_2: {
    color: COLORS.DARK_GRAY_2,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_sm_error: {
    color: COLORS.ERROR,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_sm_warning: {
    color: COLORS.WARNING,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_sm_success: {
    color: COLORS.SUCCESS,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_sm_primary: {
    color: COLORS.PRIMARY,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_sm_primary_lightest: {
    color: COLORS.PRIMARY_LIGHTEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_sm_primary_lighter: {
    color: COLORS.PRIMARY_LIGHTER,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_sm_primary_light: {
    color: COLORS.PRIMARY_LIGHT,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_sm_primary_dark: {
    color: COLORS.PRIMARY_DARK,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_sm_primary_darker: {
    color: COLORS.PRIMARY_DARKER,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_sm_primary_darkest: {
    color: COLORS.PRIMARY_DARKEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_sm_secondary: {
    color: COLORS.SECONDARY,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_sm_secondary_lightest: {
    color: COLORS.SECONDARY_LIGHTEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_sm_secondary_lighter: {
    color: COLORS.SECONDARY_LIGHTER,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_sm_secondary_light: {
    color: COLORS.SECONDARY_LIGHT,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_sm_secondary_dark: {
    color: COLORS.SECONDARY_DARK,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_sm_secondary_darker: {
    color: COLORS.SECONDARY_DARKER,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_sm_secondary_darkest: {
    color: COLORS.SECONDARY_DARKEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_sm_accent1: {
    color: COLORS.ACCENT1,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_sm_accent1_lightest: {
    color: COLORS.ACCENT1_LIGHTEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_sm_accent1_lighter: {
    color: COLORS.ACCENT1_LIGHTER,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_sm_accent1_light: {
    color: COLORS.ACCENT1_LIGHT,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_sm_accent1_dark: {
    color: COLORS.ACCENT1_DARK,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_sm_accent1_darker: {
    color: COLORS.ACCENT1_DARKER,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_sm_accent1_darkest: {
    color: COLORS.ACCENT1_DARKEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_sm_accent2: {
    color: COLORS.ACCENT2,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_sm_accent2_lightest: {
    color: COLORS.ACCENT2_LIGHTEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_sm_accent2_lighter: {
    color: COLORS.ACCENT2_LIGHTER,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_sm_accent2_light: {
    color: COLORS.ACCENT2_LIGHT,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_sm_accent2_dark: {
    color: COLORS.ACCENT2_DARK,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_sm_accent2_darker: {
    color: COLORS.ACCENT2_DARKER,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_sm_accent2_darkest: {
    color: COLORS.ACCENT2_DARKEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_sm_accent3: {
    color: COLORS.ACCENT3,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_sm_accent3_lightest: {
    color: COLORS.ACCENT3_LIGHTEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_sm_accent3_lighter: {
    color: COLORS.ACCENT3_LIGHTER,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_sm_accent3_light: {
    color: COLORS.ACCENT3_LIGHT,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_sm_accent3_dark: {
    color: COLORS.ACCENT3_DARK,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_sm_accent3_darker: {
    color: COLORS.ACCENT3_DARKER,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_sm_accent3_darkest: {
    color: COLORS.ACCENT3_DARKEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_sm_accent4: {
    color: COLORS.ACCENT4,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_sm_accent4_lightest: {
    color: COLORS.ACCENT4_LIGHTEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_sm_accent4_lighter: {
    color: COLORS.ACCENT4_LIGHTER,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_sm_accent4_light: {
    color: COLORS.ACCENT4_LIGHT,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_sm_accent4_dark: {
    color: COLORS.ACCENT4_DARK,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_sm_accent4_darker: {
    color: COLORS.ACCENT4_DARKER,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_sm_accent4_darkest: {
    color: COLORS.ACCENT4_DARKEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_sm: {
    color: COLORS.BLACK,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xs_serif_white: {
    color: COLORS.WHITE,
    fontFamily: "Merriweather, serif",
    fontSize: "0.75rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xs_serif_black: {
    color: COLORS.BLACK,
    fontFamily: "Merriweather, serif",
    fontSize: "0.75rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xs_serif_gray: {
    color: COLORS.GRAY,
    fontFamily: "Merriweather, serif",
    fontSize: "0.75rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xs_serif_green_2: {
    color: COLORS.GREEN_2,
    fontFamily: "Merriweather, serif",
    fontSize: "0.75rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xs_serif_dark_gray: {
    color: COLORS.DARK_GRAY,
    fontFamily: "Merriweather, serif",
    fontSize: "0.75rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xs_serif_dark_gray_2: {
    color: COLORS.DARK_GRAY_2,
    fontFamily: "Merriweather, serif",
    fontSize: "0.75rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xs_serif_error: {
    color: COLORS.ERROR,
    fontFamily: "Merriweather, serif",
    fontSize: "0.75rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xs_serif_warning: {
    color: COLORS.WARNING,
    fontFamily: "Merriweather, serif",
    fontSize: "0.75rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xs_serif_success: {
    color: COLORS.SUCCESS,
    fontFamily: "Merriweather, serif",
    fontSize: "0.75rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xs_serif_primary: {
    color: COLORS.PRIMARY,
    fontFamily: "Merriweather, serif",
    fontSize: "0.75rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xs_serif_primary_lightest: {
    color: COLORS.PRIMARY_LIGHTEST,
    fontFamily: "Merriweather, serif",
    fontSize: "0.75rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xs_serif_primary_lighter: {
    color: COLORS.PRIMARY_LIGHTER,
    fontFamily: "Merriweather, serif",
    fontSize: "0.75rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xs_serif_primary_light: {
    color: COLORS.PRIMARY_LIGHT,
    fontFamily: "Merriweather, serif",
    fontSize: "0.75rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xs_serif_primary_dark: {
    color: COLORS.PRIMARY_DARK,
    fontFamily: "Merriweather, serif",
    fontSize: "0.75rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xs_serif_primary_darker: {
    color: COLORS.PRIMARY_DARKER,
    fontFamily: "Merriweather, serif",
    fontSize: "0.75rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xs_serif_primary_darkest: {
    color: COLORS.PRIMARY_DARKEST,
    fontFamily: "Merriweather, serif",
    fontSize: "0.75rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xs_serif_secondary: {
    color: COLORS.SECONDARY,
    fontFamily: "Merriweather, serif",
    fontSize: "0.75rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xs_serif_secondary_lightest: {
    color: COLORS.SECONDARY_LIGHTEST,
    fontFamily: "Merriweather, serif",
    fontSize: "0.75rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xs_serif_secondary_lighter: {
    color: COLORS.SECONDARY_LIGHTER,
    fontFamily: "Merriweather, serif",
    fontSize: "0.75rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xs_serif_secondary_light: {
    color: COLORS.SECONDARY_LIGHT,
    fontFamily: "Merriweather, serif",
    fontSize: "0.75rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xs_serif_secondary_dark: {
    color: COLORS.SECONDARY_DARK,
    fontFamily: "Merriweather, serif",
    fontSize: "0.75rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xs_serif_secondary_darker: {
    color: COLORS.SECONDARY_DARKER,
    fontFamily: "Merriweather, serif",
    fontSize: "0.75rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xs_serif_secondary_darkest: {
    color: COLORS.SECONDARY_DARKEST,
    fontFamily: "Merriweather, serif",
    fontSize: "0.75rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xs_serif_accent1: {
    color: COLORS.ACCENT1,
    fontFamily: "Merriweather, serif",
    fontSize: "0.75rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xs_serif_accent1_lightest: {
    color: COLORS.ACCENT1_LIGHTEST,
    fontFamily: "Merriweather, serif",
    fontSize: "0.75rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xs_serif_accent1_lighter: {
    color: COLORS.ACCENT1_LIGHTER,
    fontFamily: "Merriweather, serif",
    fontSize: "0.75rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xs_serif_accent1_light: {
    color: COLORS.ACCENT1_LIGHT,
    fontFamily: "Merriweather, serif",
    fontSize: "0.75rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xs_serif_accent1_dark: {
    color: COLORS.ACCENT1_DARK,
    fontFamily: "Merriweather, serif",
    fontSize: "0.75rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xs_serif_accent1_darker: {
    color: COLORS.ACCENT1_DARKER,
    fontFamily: "Merriweather, serif",
    fontSize: "0.75rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xs_serif_accent1_darkest: {
    color: COLORS.ACCENT1_DARKEST,
    fontFamily: "Merriweather, serif",
    fontSize: "0.75rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xs_serif_accent2: {
    color: COLORS.ACCENT2,
    fontFamily: "Merriweather, serif",
    fontSize: "0.75rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xs_serif_accent2_lightest: {
    color: COLORS.ACCENT2_LIGHTEST,
    fontFamily: "Merriweather, serif",
    fontSize: "0.75rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xs_serif_accent2_lighter: {
    color: COLORS.ACCENT2_LIGHTER,
    fontFamily: "Merriweather, serif",
    fontSize: "0.75rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xs_serif_accent2_light: {
    color: COLORS.ACCENT2_LIGHT,
    fontFamily: "Merriweather, serif",
    fontSize: "0.75rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xs_serif_accent2_dark: {
    color: COLORS.ACCENT2_DARK,
    fontFamily: "Merriweather, serif",
    fontSize: "0.75rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xs_serif_accent2_darker: {
    color: COLORS.ACCENT2_DARKER,
    fontFamily: "Merriweather, serif",
    fontSize: "0.75rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xs_serif_accent2_darkest: {
    color: COLORS.ACCENT2_DARKEST,
    fontFamily: "Merriweather, serif",
    fontSize: "0.75rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xs_serif_accent3: {
    color: COLORS.ACCENT3,
    fontFamily: "Merriweather, serif",
    fontSize: "0.75rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xs_serif_accent3_lightest: {
    color: COLORS.ACCENT3_LIGHTEST,
    fontFamily: "Merriweather, serif",
    fontSize: "0.75rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xs_serif_accent3_lighter: {
    color: COLORS.ACCENT3_LIGHTER,
    fontFamily: "Merriweather, serif",
    fontSize: "0.75rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xs_serif_accent3_light: {
    color: COLORS.ACCENT3_LIGHT,
    fontFamily: "Merriweather, serif",
    fontSize: "0.75rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xs_serif_accent3_dark: {
    color: COLORS.ACCENT3_DARK,
    fontFamily: "Merriweather, serif",
    fontSize: "0.75rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xs_serif_accent3_darker: {
    color: COLORS.ACCENT3_DARKER,
    fontFamily: "Merriweather, serif",
    fontSize: "0.75rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xs_serif_accent3_darkest: {
    color: COLORS.ACCENT3_DARKEST,
    fontFamily: "Merriweather, serif",
    fontSize: "0.75rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xs_serif_accent4: {
    color: COLORS.ACCENT4,
    fontFamily: "Merriweather, serif",
    fontSize: "0.75rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xs_serif_accent4_lightest: {
    color: COLORS.ACCENT4_LIGHTEST,
    fontFamily: "Merriweather, serif",
    fontSize: "0.75rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xs_serif_accent4_lighter: {
    color: COLORS.ACCENT4_LIGHTER,
    fontFamily: "Merriweather, serif",
    fontSize: "0.75rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xs_serif_accent4_light: {
    color: COLORS.ACCENT4_LIGHT,
    fontFamily: "Merriweather, serif",
    fontSize: "0.75rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xs_serif_accent4_dark: {
    color: COLORS.ACCENT4_DARK,
    fontFamily: "Merriweather, serif",
    fontSize: "0.75rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xs_serif_accent4_darker: {
    color: COLORS.ACCENT4_DARKER,
    fontFamily: "Merriweather, serif",
    fontSize: "0.75rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xs_serif_accent4_darkest: {
    color: COLORS.ACCENT4_DARKEST,
    fontFamily: "Merriweather, serif",
    fontSize: "0.75rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xs_serif: {
    color: COLORS.BLACK,
    fontFamily: "Merriweather, serif",
    fontSize: "0.75rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xs_white: {
    color: COLORS.WHITE,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.75rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xs_black: {
    color: COLORS.BLACK,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.75rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xs_gray: {
    color: COLORS.GRAY,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.75rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xs_green_2: {
    color: COLORS.GREEN_2,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.75rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xs_dark_gray: {
    color: COLORS.DARK_GRAY,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.75rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xs_dark_gray_2: {
    color: COLORS.DARK_GRAY_2,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.75rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xs_error: {
    color: COLORS.ERROR,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.75rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xs_warning: {
    color: COLORS.WARNING,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.75rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xs_success: {
    color: COLORS.SUCCESS,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.75rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xs_primary: {
    color: COLORS.PRIMARY,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.75rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xs_primary_lightest: {
    color: COLORS.PRIMARY_LIGHTEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.75rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xs_primary_lighter: {
    color: COLORS.PRIMARY_LIGHTER,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.75rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xs_primary_light: {
    color: COLORS.PRIMARY_LIGHT,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.75rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xs_primary_dark: {
    color: COLORS.PRIMARY_DARK,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.75rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xs_primary_darker: {
    color: COLORS.PRIMARY_DARKER,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.75rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xs_primary_darkest: {
    color: COLORS.PRIMARY_DARKEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.75rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xs_secondary: {
    color: COLORS.SECONDARY,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.75rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xs_secondary_lightest: {
    color: COLORS.SECONDARY_LIGHTEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.75rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xs_secondary_lighter: {
    color: COLORS.SECONDARY_LIGHTER,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.75rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xs_secondary_light: {
    color: COLORS.SECONDARY_LIGHT,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.75rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xs_secondary_dark: {
    color: COLORS.SECONDARY_DARK,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.75rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xs_secondary_darker: {
    color: COLORS.SECONDARY_DARKER,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.75rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xs_secondary_darkest: {
    color: COLORS.SECONDARY_DARKEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.75rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xs_accent1: {
    color: COLORS.ACCENT1,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.75rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xs_accent1_lightest: {
    color: COLORS.ACCENT1_LIGHTEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.75rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xs_accent1_lighter: {
    color: COLORS.ACCENT1_LIGHTER,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.75rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xs_accent1_light: {
    color: COLORS.ACCENT1_LIGHT,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.75rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xs_accent1_dark: {
    color: COLORS.ACCENT1_DARK,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.75rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xs_accent1_darker: {
    color: COLORS.ACCENT1_DARKER,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.75rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xs_accent1_darkest: {
    color: COLORS.ACCENT1_DARKEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.75rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xs_accent2: {
    color: COLORS.ACCENT2,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.75rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xs_accent2_lightest: {
    color: COLORS.ACCENT2_LIGHTEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.75rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xs_accent2_lighter: {
    color: COLORS.ACCENT2_LIGHTER,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.75rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xs_accent2_light: {
    color: COLORS.ACCENT2_LIGHT,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.75rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xs_accent2_dark: {
    color: COLORS.ACCENT2_DARK,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.75rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xs_accent2_darker: {
    color: COLORS.ACCENT2_DARKER,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.75rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xs_accent2_darkest: {
    color: COLORS.ACCENT2_DARKEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.75rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xs_accent3: {
    color: COLORS.ACCENT3,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.75rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xs_accent3_lightest: {
    color: COLORS.ACCENT3_LIGHTEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.75rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xs_accent3_lighter: {
    color: COLORS.ACCENT3_LIGHTER,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.75rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xs_accent3_light: {
    color: COLORS.ACCENT3_LIGHT,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.75rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xs_accent3_dark: {
    color: COLORS.ACCENT3_DARK,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.75rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xs_accent3_darker: {
    color: COLORS.ACCENT3_DARKER,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.75rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xs_accent3_darkest: {
    color: COLORS.ACCENT3_DARKEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.75rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xs_accent4: {
    color: COLORS.ACCENT4,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.75rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xs_accent4_lightest: {
    color: COLORS.ACCENT4_LIGHTEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.75rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xs_accent4_lighter: {
    color: COLORS.ACCENT4_LIGHTER,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.75rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xs_accent4_light: {
    color: COLORS.ACCENT4_LIGHT,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.75rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xs_accent4_dark: {
    color: COLORS.ACCENT4_DARK,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.75rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xs_accent4_darker: {
    color: COLORS.ACCENT4_DARKER,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.75rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xs_accent4_darkest: {
    color: COLORS.ACCENT4_DARKEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.75rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_xs: {
    color: COLORS.BLACK,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.75rem",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xl_serif_white: {
    color: COLORS.WHITE,
    fontFamily: "Merriweather, serif",
    fontSize: "1.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xl_serif_black: {
    color: COLORS.BLACK,
    fontFamily: "Merriweather, serif",
    fontSize: "1.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xl_serif_gray: {
    color: COLORS.GRAY,
    fontFamily: "Merriweather, serif",
    fontSize: "1.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xl_serif_green_2: {
    color: COLORS.GREEN_2,
    fontFamily: "Merriweather, serif",
    fontSize: "1.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xl_serif_dark_gray: {
    color: COLORS.DARK_GRAY,
    fontFamily: "Merriweather, serif",
    fontSize: "1.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xl_serif_dark_gray_2: {
    color: COLORS.DARK_GRAY_2,
    fontFamily: "Merriweather, serif",
    fontSize: "1.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xl_serif_error: {
    color: COLORS.ERROR,
    fontFamily: "Merriweather, serif",
    fontSize: "1.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xl_serif_warning: {
    color: COLORS.WARNING,
    fontFamily: "Merriweather, serif",
    fontSize: "1.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xl_serif_success: {
    color: COLORS.SUCCESS,
    fontFamily: "Merriweather, serif",
    fontSize: "1.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xl_serif_primary: {
    color: COLORS.PRIMARY,
    fontFamily: "Merriweather, serif",
    fontSize: "1.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xl_serif_primary_lightest: {
    color: COLORS.PRIMARY_LIGHTEST,
    fontFamily: "Merriweather, serif",
    fontSize: "1.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xl_serif_primary_lighter: {
    color: COLORS.PRIMARY_LIGHTER,
    fontFamily: "Merriweather, serif",
    fontSize: "1.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xl_serif_primary_light: {
    color: COLORS.PRIMARY_LIGHT,
    fontFamily: "Merriweather, serif",
    fontSize: "1.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xl_serif_primary_dark: {
    color: COLORS.PRIMARY_DARK,
    fontFamily: "Merriweather, serif",
    fontSize: "1.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xl_serif_primary_darker: {
    color: COLORS.PRIMARY_DARKER,
    fontFamily: "Merriweather, serif",
    fontSize: "1.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xl_serif_primary_darkest: {
    color: COLORS.PRIMARY_DARKEST,
    fontFamily: "Merriweather, serif",
    fontSize: "1.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xl_serif_secondary: {
    color: COLORS.SECONDARY,
    fontFamily: "Merriweather, serif",
    fontSize: "1.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xl_serif_secondary_lightest: {
    color: COLORS.SECONDARY_LIGHTEST,
    fontFamily: "Merriweather, serif",
    fontSize: "1.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xl_serif_secondary_lighter: {
    color: COLORS.SECONDARY_LIGHTER,
    fontFamily: "Merriweather, serif",
    fontSize: "1.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xl_serif_secondary_light: {
    color: COLORS.SECONDARY_LIGHT,
    fontFamily: "Merriweather, serif",
    fontSize: "1.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xl_serif_secondary_dark: {
    color: COLORS.SECONDARY_DARK,
    fontFamily: "Merriweather, serif",
    fontSize: "1.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xl_serif_secondary_darker: {
    color: COLORS.SECONDARY_DARKER,
    fontFamily: "Merriweather, serif",
    fontSize: "1.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xl_serif_secondary_darkest: {
    color: COLORS.SECONDARY_DARKEST,
    fontFamily: "Merriweather, serif",
    fontSize: "1.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xl_serif_accent1: {
    color: COLORS.ACCENT1,
    fontFamily: "Merriweather, serif",
    fontSize: "1.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xl_serif_accent1_lightest: {
    color: COLORS.ACCENT1_LIGHTEST,
    fontFamily: "Merriweather, serif",
    fontSize: "1.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xl_serif_accent1_lighter: {
    color: COLORS.ACCENT1_LIGHTER,
    fontFamily: "Merriweather, serif",
    fontSize: "1.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xl_serif_accent1_light: {
    color: COLORS.ACCENT1_LIGHT,
    fontFamily: "Merriweather, serif",
    fontSize: "1.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xl_serif_accent1_dark: {
    color: COLORS.ACCENT1_DARK,
    fontFamily: "Merriweather, serif",
    fontSize: "1.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xl_serif_accent1_darker: {
    color: COLORS.ACCENT1_DARKER,
    fontFamily: "Merriweather, serif",
    fontSize: "1.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xl_serif_accent1_darkest: {
    color: COLORS.ACCENT1_DARKEST,
    fontFamily: "Merriweather, serif",
    fontSize: "1.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xl_serif_accent2: {
    color: COLORS.ACCENT2,
    fontFamily: "Merriweather, serif",
    fontSize: "1.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xl_serif_accent2_lightest: {
    color: COLORS.ACCENT2_LIGHTEST,
    fontFamily: "Merriweather, serif",
    fontSize: "1.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xl_serif_accent2_lighter: {
    color: COLORS.ACCENT2_LIGHTER,
    fontFamily: "Merriweather, serif",
    fontSize: "1.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xl_serif_accent2_light: {
    color: COLORS.ACCENT2_LIGHT,
    fontFamily: "Merriweather, serif",
    fontSize: "1.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xl_serif_accent2_dark: {
    color: COLORS.ACCENT2_DARK,
    fontFamily: "Merriweather, serif",
    fontSize: "1.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xl_serif_accent2_darker: {
    color: COLORS.ACCENT2_DARKER,
    fontFamily: "Merriweather, serif",
    fontSize: "1.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xl_serif_accent2_darkest: {
    color: COLORS.ACCENT2_DARKEST,
    fontFamily: "Merriweather, serif",
    fontSize: "1.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xl_serif_accent3: {
    color: COLORS.ACCENT3,
    fontFamily: "Merriweather, serif",
    fontSize: "1.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xl_serif_accent3_lightest: {
    color: COLORS.ACCENT3_LIGHTEST,
    fontFamily: "Merriweather, serif",
    fontSize: "1.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xl_serif_accent3_lighter: {
    color: COLORS.ACCENT3_LIGHTER,
    fontFamily: "Merriweather, serif",
    fontSize: "1.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xl_serif_accent3_light: {
    color: COLORS.ACCENT3_LIGHT,
    fontFamily: "Merriweather, serif",
    fontSize: "1.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xl_serif_accent3_dark: {
    color: COLORS.ACCENT3_DARK,
    fontFamily: "Merriweather, serif",
    fontSize: "1.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xl_serif_accent3_darker: {
    color: COLORS.ACCENT3_DARKER,
    fontFamily: "Merriweather, serif",
    fontSize: "1.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xl_serif_accent3_darkest: {
    color: COLORS.ACCENT3_DARKEST,
    fontFamily: "Merriweather, serif",
    fontSize: "1.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xl_serif_accent4: {
    color: COLORS.ACCENT4,
    fontFamily: "Merriweather, serif",
    fontSize: "1.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xl_serif_accent4_lightest: {
    color: COLORS.ACCENT4_LIGHTEST,
    fontFamily: "Merriweather, serif",
    fontSize: "1.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xl_serif_accent4_lighter: {
    color: COLORS.ACCENT4_LIGHTER,
    fontFamily: "Merriweather, serif",
    fontSize: "1.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xl_serif_accent4_light: {
    color: COLORS.ACCENT4_LIGHT,
    fontFamily: "Merriweather, serif",
    fontSize: "1.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xl_serif_accent4_dark: {
    color: COLORS.ACCENT4_DARK,
    fontFamily: "Merriweather, serif",
    fontSize: "1.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xl_serif_accent4_darker: {
    color: COLORS.ACCENT4_DARKER,
    fontFamily: "Merriweather, serif",
    fontSize: "1.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xl_serif_accent4_darkest: {
    color: COLORS.ACCENT4_DARKEST,
    fontFamily: "Merriweather, serif",
    fontSize: "1.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xl_serif: {
    color: COLORS.BLACK,
    fontFamily: "Merriweather, serif",
    fontSize: "1.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xl_white: {
    color: COLORS.WHITE,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xl_black: {
    color: COLORS.BLACK,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xl_gray: {
    color: COLORS.GRAY,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xl_green_2: {
    color: COLORS.GREEN_2,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xl_dark_gray: {
    color: COLORS.DARK_GRAY,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xl_dark_gray_2: {
    color: COLORS.DARK_GRAY_2,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xl_error: {
    color: COLORS.ERROR,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xl_warning: {
    color: COLORS.WARNING,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xl_success: {
    color: COLORS.SUCCESS,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xl_primary: {
    color: COLORS.PRIMARY,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xl_primary_lightest: {
    color: COLORS.PRIMARY_LIGHTEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xl_primary_lighter: {
    color: COLORS.PRIMARY_LIGHTER,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xl_primary_light: {
    color: COLORS.PRIMARY_LIGHT,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xl_primary_dark: {
    color: COLORS.PRIMARY_DARK,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xl_primary_darker: {
    color: COLORS.PRIMARY_DARKER,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xl_primary_darkest: {
    color: COLORS.PRIMARY_DARKEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xl_secondary: {
    color: COLORS.SECONDARY,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xl_secondary_lightest: {
    color: COLORS.SECONDARY_LIGHTEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xl_secondary_lighter: {
    color: COLORS.SECONDARY_LIGHTER,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xl_secondary_light: {
    color: COLORS.SECONDARY_LIGHT,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xl_secondary_dark: {
    color: COLORS.SECONDARY_DARK,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xl_secondary_darker: {
    color: COLORS.SECONDARY_DARKER,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xl_secondary_darkest: {
    color: COLORS.SECONDARY_DARKEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xl_accent1: {
    color: COLORS.ACCENT1,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xl_accent1_lightest: {
    color: COLORS.ACCENT1_LIGHTEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xl_accent1_lighter: {
    color: COLORS.ACCENT1_LIGHTER,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xl_accent1_light: {
    color: COLORS.ACCENT1_LIGHT,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xl_accent1_dark: {
    color: COLORS.ACCENT1_DARK,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xl_accent1_darker: {
    color: COLORS.ACCENT1_DARKER,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xl_accent1_darkest: {
    color: COLORS.ACCENT1_DARKEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xl_accent2: {
    color: COLORS.ACCENT2,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xl_accent2_lightest: {
    color: COLORS.ACCENT2_LIGHTEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xl_accent2_lighter: {
    color: COLORS.ACCENT2_LIGHTER,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xl_accent2_light: {
    color: COLORS.ACCENT2_LIGHT,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xl_accent2_dark: {
    color: COLORS.ACCENT2_DARK,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xl_accent2_darker: {
    color: COLORS.ACCENT2_DARKER,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xl_accent2_darkest: {
    color: COLORS.ACCENT2_DARKEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xl_accent3: {
    color: COLORS.ACCENT3,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xl_accent3_lightest: {
    color: COLORS.ACCENT3_LIGHTEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xl_accent3_lighter: {
    color: COLORS.ACCENT3_LIGHTER,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xl_accent3_light: {
    color: COLORS.ACCENT3_LIGHT,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xl_accent3_dark: {
    color: COLORS.ACCENT3_DARK,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xl_accent3_darker: {
    color: COLORS.ACCENT3_DARKER,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xl_accent3_darkest: {
    color: COLORS.ACCENT3_DARKEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xl_accent4: {
    color: COLORS.ACCENT4,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xl_accent4_lightest: {
    color: COLORS.ACCENT4_LIGHTEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xl_accent4_lighter: {
    color: COLORS.ACCENT4_LIGHTER,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xl_accent4_light: {
    color: COLORS.ACCENT4_LIGHT,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xl_accent4_dark: {
    color: COLORS.ACCENT4_DARK,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xl_accent4_darker: {
    color: COLORS.ACCENT4_DARKER,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xl_accent4_darkest: {
    color: COLORS.ACCENT4_DARKEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xl: {
    color: COLORS.BLACK,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg2_serif_white: {
    color: COLORS.WHITE,
    fontFamily: "Merriweather, serif",
    fontSize: "1.688rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg2_serif_black: {
    color: COLORS.BLACK,
    fontFamily: "Merriweather, serif",
    fontSize: "1.688rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg2_serif_gray: {
    color: COLORS.GRAY,
    fontFamily: "Merriweather, serif",
    fontSize: "1.688rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg2_serif_green_2: {
    color: COLORS.GREEN_2,
    fontFamily: "Merriweather, serif",
    fontSize: "1.688rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg2_serif_dark_gray: {
    color: COLORS.DARK_GRAY,
    fontFamily: "Merriweather, serif",
    fontSize: "1.688rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg2_serif_dark_gray_2: {
    color: COLORS.DARK_GRAY_2,
    fontFamily: "Merriweather, serif",
    fontSize: "1.688rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg2_serif_error: {
    color: COLORS.ERROR,
    fontFamily: "Merriweather, serif",
    fontSize: "1.688rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg2_serif_warning: {
    color: COLORS.WARNING,
    fontFamily: "Merriweather, serif",
    fontSize: "1.688rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg2_serif_success: {
    color: COLORS.SUCCESS,
    fontFamily: "Merriweather, serif",
    fontSize: "1.688rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg2_serif_primary: {
    color: COLORS.PRIMARY,
    fontFamily: "Merriweather, serif",
    fontSize: "1.688rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg2_serif_primary_lightest: {
    color: COLORS.PRIMARY_LIGHTEST,
    fontFamily: "Merriweather, serif",
    fontSize: "1.688rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg2_serif_primary_lighter: {
    color: COLORS.PRIMARY_LIGHTER,
    fontFamily: "Merriweather, serif",
    fontSize: "1.688rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg2_serif_primary_light: {
    color: COLORS.PRIMARY_LIGHT,
    fontFamily: "Merriweather, serif",
    fontSize: "1.688rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg2_serif_primary_dark: {
    color: COLORS.PRIMARY_DARK,
    fontFamily: "Merriweather, serif",
    fontSize: "1.688rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg2_serif_primary_darker: {
    color: COLORS.PRIMARY_DARKER,
    fontFamily: "Merriweather, serif",
    fontSize: "1.688rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg2_serif_primary_darkest: {
    color: COLORS.PRIMARY_DARKEST,
    fontFamily: "Merriweather, serif",
    fontSize: "1.688rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg2_serif_secondary: {
    color: COLORS.SECONDARY,
    fontFamily: "Merriweather, serif",
    fontSize: "1.688rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg2_serif_secondary_lightest: {
    color: COLORS.SECONDARY_LIGHTEST,
    fontFamily: "Merriweather, serif",
    fontSize: "1.688rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg2_serif_secondary_lighter: {
    color: COLORS.SECONDARY_LIGHTER,
    fontFamily: "Merriweather, serif",
    fontSize: "1.688rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg2_serif_secondary_light: {
    color: COLORS.SECONDARY_LIGHT,
    fontFamily: "Merriweather, serif",
    fontSize: "1.688rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg2_serif_secondary_dark: {
    color: COLORS.SECONDARY_DARK,
    fontFamily: "Merriweather, serif",
    fontSize: "1.688rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg2_serif_secondary_darker: {
    color: COLORS.SECONDARY_DARKER,
    fontFamily: "Merriweather, serif",
    fontSize: "1.688rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg2_serif_secondary_darkest: {
    color: COLORS.SECONDARY_DARKEST,
    fontFamily: "Merriweather, serif",
    fontSize: "1.688rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg2_serif_accent1: {
    color: COLORS.ACCENT1,
    fontFamily: "Merriweather, serif",
    fontSize: "1.688rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg2_serif_accent1_lightest: {
    color: COLORS.ACCENT1_LIGHTEST,
    fontFamily: "Merriweather, serif",
    fontSize: "1.688rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg2_serif_accent1_lighter: {
    color: COLORS.ACCENT1_LIGHTER,
    fontFamily: "Merriweather, serif",
    fontSize: "1.688rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg2_serif_accent1_light: {
    color: COLORS.ACCENT1_LIGHT,
    fontFamily: "Merriweather, serif",
    fontSize: "1.688rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg2_serif_accent1_dark: {
    color: COLORS.ACCENT1_DARK,
    fontFamily: "Merriweather, serif",
    fontSize: "1.688rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg2_serif_accent1_darker: {
    color: COLORS.ACCENT1_DARKER,
    fontFamily: "Merriweather, serif",
    fontSize: "1.688rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg2_serif_accent1_darkest: {
    color: COLORS.ACCENT1_DARKEST,
    fontFamily: "Merriweather, serif",
    fontSize: "1.688rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg2_serif_accent2: {
    color: COLORS.ACCENT2,
    fontFamily: "Merriweather, serif",
    fontSize: "1.688rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg2_serif_accent2_lightest: {
    color: COLORS.ACCENT2_LIGHTEST,
    fontFamily: "Merriweather, serif",
    fontSize: "1.688rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg2_serif_accent2_lighter: {
    color: COLORS.ACCENT2_LIGHTER,
    fontFamily: "Merriweather, serif",
    fontSize: "1.688rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg2_serif_accent2_light: {
    color: COLORS.ACCENT2_LIGHT,
    fontFamily: "Merriweather, serif",
    fontSize: "1.688rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg2_serif_accent2_dark: {
    color: COLORS.ACCENT2_DARK,
    fontFamily: "Merriweather, serif",
    fontSize: "1.688rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg2_serif_accent2_darker: {
    color: COLORS.ACCENT2_DARKER,
    fontFamily: "Merriweather, serif",
    fontSize: "1.688rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg2_serif_accent2_darkest: {
    color: COLORS.ACCENT2_DARKEST,
    fontFamily: "Merriweather, serif",
    fontSize: "1.688rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg2_serif_accent3: {
    color: COLORS.ACCENT3,
    fontFamily: "Merriweather, serif",
    fontSize: "1.688rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg2_serif_accent3_lightest: {
    color: COLORS.ACCENT3_LIGHTEST,
    fontFamily: "Merriweather, serif",
    fontSize: "1.688rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg2_serif_accent3_lighter: {
    color: COLORS.ACCENT3_LIGHTER,
    fontFamily: "Merriweather, serif",
    fontSize: "1.688rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg2_serif_accent3_light: {
    color: COLORS.ACCENT3_LIGHT,
    fontFamily: "Merriweather, serif",
    fontSize: "1.688rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg2_serif_accent3_dark: {
    color: COLORS.ACCENT3_DARK,
    fontFamily: "Merriweather, serif",
    fontSize: "1.688rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg2_serif_accent3_darker: {
    color: COLORS.ACCENT3_DARKER,
    fontFamily: "Merriweather, serif",
    fontSize: "1.688rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg2_serif_accent3_darkest: {
    color: COLORS.ACCENT3_DARKEST,
    fontFamily: "Merriweather, serif",
    fontSize: "1.688rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg2_serif_accent4: {
    color: COLORS.ACCENT4,
    fontFamily: "Merriweather, serif",
    fontSize: "1.688rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg2_serif_accent4_lightest: {
    color: COLORS.ACCENT4_LIGHTEST,
    fontFamily: "Merriweather, serif",
    fontSize: "1.688rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg2_serif_accent4_lighter: {
    color: COLORS.ACCENT4_LIGHTER,
    fontFamily: "Merriweather, serif",
    fontSize: "1.688rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg2_serif_accent4_light: {
    color: COLORS.ACCENT4_LIGHT,
    fontFamily: "Merriweather, serif",
    fontSize: "1.688rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg2_serif_accent4_dark: {
    color: COLORS.ACCENT4_DARK,
    fontFamily: "Merriweather, serif",
    fontSize: "1.688rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg2_serif_accent4_darker: {
    color: COLORS.ACCENT4_DARKER,
    fontFamily: "Merriweather, serif",
    fontSize: "1.688rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg2_serif_accent4_darkest: {
    color: COLORS.ACCENT4_DARKEST,
    fontFamily: "Merriweather, serif",
    fontSize: "1.688rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg2_serif: {
    color: COLORS.BLACK,
    fontFamily: "Merriweather, serif",
    fontSize: "1.688rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg2_white: {
    color: COLORS.WHITE,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.688rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg2_black: {
    color: COLORS.BLACK,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.688rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg2_gray: {
    color: COLORS.GRAY,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.688rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg2_green_2: {
    color: COLORS.GREEN_2,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.688rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg2_dark_gray: {
    color: COLORS.DARK_GRAY,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.688rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg2_dark_gray_2: {
    color: COLORS.DARK_GRAY_2,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.688rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg2_error: {
    color: COLORS.ERROR,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.688rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg2_warning: {
    color: COLORS.WARNING,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.688rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg2_success: {
    color: COLORS.SUCCESS,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.688rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg2_primary: {
    color: COLORS.PRIMARY,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.688rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg2_primary_lightest: {
    color: COLORS.PRIMARY_LIGHTEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.688rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg2_primary_lighter: {
    color: COLORS.PRIMARY_LIGHTER,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.688rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg2_primary_light: {
    color: COLORS.PRIMARY_LIGHT,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.688rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg2_primary_dark: {
    color: COLORS.PRIMARY_DARK,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.688rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg2_primary_darker: {
    color: COLORS.PRIMARY_DARKER,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.688rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg2_primary_darkest: {
    color: COLORS.PRIMARY_DARKEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.688rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg2_secondary: {
    color: COLORS.SECONDARY,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.688rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg2_secondary_lightest: {
    color: COLORS.SECONDARY_LIGHTEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.688rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg2_secondary_lighter: {
    color: COLORS.SECONDARY_LIGHTER,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.688rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg2_secondary_light: {
    color: COLORS.SECONDARY_LIGHT,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.688rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg2_secondary_dark: {
    color: COLORS.SECONDARY_DARK,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.688rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg2_secondary_darker: {
    color: COLORS.SECONDARY_DARKER,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.688rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg2_secondary_darkest: {
    color: COLORS.SECONDARY_DARKEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.688rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg2_accent1: {
    color: COLORS.ACCENT1,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.688rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg2_accent1_lightest: {
    color: COLORS.ACCENT1_LIGHTEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.688rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg2_accent1_lighter: {
    color: COLORS.ACCENT1_LIGHTER,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.688rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg2_accent1_light: {
    color: COLORS.ACCENT1_LIGHT,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.688rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg2_accent1_dark: {
    color: COLORS.ACCENT1_DARK,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.688rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg2_accent1_darker: {
    color: COLORS.ACCENT1_DARKER,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.688rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg2_accent1_darkest: {
    color: COLORS.ACCENT1_DARKEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.688rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg2_accent2: {
    color: COLORS.ACCENT2,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.688rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg2_accent2_lightest: {
    color: COLORS.ACCENT2_LIGHTEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.688rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg2_accent2_lighter: {
    color: COLORS.ACCENT2_LIGHTER,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.688rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg2_accent2_light: {
    color: COLORS.ACCENT2_LIGHT,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.688rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg2_accent2_dark: {
    color: COLORS.ACCENT2_DARK,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.688rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg2_accent2_darker: {
    color: COLORS.ACCENT2_DARKER,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.688rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg2_accent2_darkest: {
    color: COLORS.ACCENT2_DARKEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.688rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg2_accent3: {
    color: COLORS.ACCENT3,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.688rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg2_accent3_lightest: {
    color: COLORS.ACCENT3_LIGHTEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.688rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg2_accent3_lighter: {
    color: COLORS.ACCENT3_LIGHTER,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.688rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg2_accent3_light: {
    color: COLORS.ACCENT3_LIGHT,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.688rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg2_accent3_dark: {
    color: COLORS.ACCENT3_DARK,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.688rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg2_accent3_darker: {
    color: COLORS.ACCENT3_DARKER,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.688rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg2_accent3_darkest: {
    color: COLORS.ACCENT3_DARKEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.688rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg2_accent4: {
    color: COLORS.ACCENT4,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.688rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg2_accent4_lightest: {
    color: COLORS.ACCENT4_LIGHTEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.688rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg2_accent4_lighter: {
    color: COLORS.ACCENT4_LIGHTER,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.688rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg2_accent4_light: {
    color: COLORS.ACCENT4_LIGHT,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.688rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg2_accent4_dark: {
    color: COLORS.ACCENT4_DARK,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.688rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg2_accent4_darker: {
    color: COLORS.ACCENT4_DARKER,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.688rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg2_accent4_darkest: {
    color: COLORS.ACCENT4_DARKEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.688rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg2: {
    color: COLORS.BLACK,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.688rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg1_serif_white: {
    color: COLORS.WHITE,
    fontFamily: "Merriweather, serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg1_serif_black: {
    color: COLORS.BLACK,
    fontFamily: "Merriweather, serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg1_serif_gray: {
    color: COLORS.GRAY,
    fontFamily: "Merriweather, serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg1_serif_green_2: {
    color: COLORS.GREEN_2,
    fontFamily: "Merriweather, serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg1_serif_dark_gray: {
    color: COLORS.DARK_GRAY,
    fontFamily: "Merriweather, serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg1_serif_dark_gray_2: {
    color: COLORS.DARK_GRAY_2,
    fontFamily: "Merriweather, serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg1_serif_error: {
    color: COLORS.ERROR,
    fontFamily: "Merriweather, serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg1_serif_warning: {
    color: COLORS.WARNING,
    fontFamily: "Merriweather, serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg1_serif_success: {
    color: COLORS.SUCCESS,
    fontFamily: "Merriweather, serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg1_serif_primary: {
    color: COLORS.PRIMARY,
    fontFamily: "Merriweather, serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg1_serif_primary_lightest: {
    color: COLORS.PRIMARY_LIGHTEST,
    fontFamily: "Merriweather, serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg1_serif_primary_lighter: {
    color: COLORS.PRIMARY_LIGHTER,
    fontFamily: "Merriweather, serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg1_serif_primary_light: {
    color: COLORS.PRIMARY_LIGHT,
    fontFamily: "Merriweather, serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg1_serif_primary_dark: {
    color: COLORS.PRIMARY_DARK,
    fontFamily: "Merriweather, serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg1_serif_primary_darker: {
    color: COLORS.PRIMARY_DARKER,
    fontFamily: "Merriweather, serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg1_serif_primary_darkest: {
    color: COLORS.PRIMARY_DARKEST,
    fontFamily: "Merriweather, serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg1_serif_secondary: {
    color: COLORS.SECONDARY,
    fontFamily: "Merriweather, serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg1_serif_secondary_lightest: {
    color: COLORS.SECONDARY_LIGHTEST,
    fontFamily: "Merriweather, serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg1_serif_secondary_lighter: {
    color: COLORS.SECONDARY_LIGHTER,
    fontFamily: "Merriweather, serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg1_serif_secondary_light: {
    color: COLORS.SECONDARY_LIGHT,
    fontFamily: "Merriweather, serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg1_serif_secondary_dark: {
    color: COLORS.SECONDARY_DARK,
    fontFamily: "Merriweather, serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg1_serif_secondary_darker: {
    color: COLORS.SECONDARY_DARKER,
    fontFamily: "Merriweather, serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg1_serif_secondary_darkest: {
    color: COLORS.SECONDARY_DARKEST,
    fontFamily: "Merriweather, serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg1_serif_accent1: {
    color: COLORS.ACCENT1,
    fontFamily: "Merriweather, serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg1_serif_accent1_lightest: {
    color: COLORS.ACCENT1_LIGHTEST,
    fontFamily: "Merriweather, serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg1_serif_accent1_lighter: {
    color: COLORS.ACCENT1_LIGHTER,
    fontFamily: "Merriweather, serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg1_serif_accent1_light: {
    color: COLORS.ACCENT1_LIGHT,
    fontFamily: "Merriweather, serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg1_serif_accent1_dark: {
    color: COLORS.ACCENT1_DARK,
    fontFamily: "Merriweather, serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg1_serif_accent1_darker: {
    color: COLORS.ACCENT1_DARKER,
    fontFamily: "Merriweather, serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg1_serif_accent1_darkest: {
    color: COLORS.ACCENT1_DARKEST,
    fontFamily: "Merriweather, serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg1_serif_accent2: {
    color: COLORS.ACCENT2,
    fontFamily: "Merriweather, serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg1_serif_accent2_lightest: {
    color: COLORS.ACCENT2_LIGHTEST,
    fontFamily: "Merriweather, serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg1_serif_accent2_lighter: {
    color: COLORS.ACCENT2_LIGHTER,
    fontFamily: "Merriweather, serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg1_serif_accent2_light: {
    color: COLORS.ACCENT2_LIGHT,
    fontFamily: "Merriweather, serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg1_serif_accent2_dark: {
    color: COLORS.ACCENT2_DARK,
    fontFamily: "Merriweather, serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg1_serif_accent2_darker: {
    color: COLORS.ACCENT2_DARKER,
    fontFamily: "Merriweather, serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg1_serif_accent2_darkest: {
    color: COLORS.ACCENT2_DARKEST,
    fontFamily: "Merriweather, serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg1_serif_accent3: {
    color: COLORS.ACCENT3,
    fontFamily: "Merriweather, serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg1_serif_accent3_lightest: {
    color: COLORS.ACCENT3_LIGHTEST,
    fontFamily: "Merriweather, serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg1_serif_accent3_lighter: {
    color: COLORS.ACCENT3_LIGHTER,
    fontFamily: "Merriweather, serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg1_serif_accent3_light: {
    color: COLORS.ACCENT3_LIGHT,
    fontFamily: "Merriweather, serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg1_serif_accent3_dark: {
    color: COLORS.ACCENT3_DARK,
    fontFamily: "Merriweather, serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg1_serif_accent3_darker: {
    color: COLORS.ACCENT3_DARKER,
    fontFamily: "Merriweather, serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg1_serif_accent3_darkest: {
    color: COLORS.ACCENT3_DARKEST,
    fontFamily: "Merriweather, serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg1_serif_accent4: {
    color: COLORS.ACCENT4,
    fontFamily: "Merriweather, serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg1_serif_accent4_lightest: {
    color: COLORS.ACCENT4_LIGHTEST,
    fontFamily: "Merriweather, serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg1_serif_accent4_lighter: {
    color: COLORS.ACCENT4_LIGHTER,
    fontFamily: "Merriweather, serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg1_serif_accent4_light: {
    color: COLORS.ACCENT4_LIGHT,
    fontFamily: "Merriweather, serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg1_serif_accent4_dark: {
    color: COLORS.ACCENT4_DARK,
    fontFamily: "Merriweather, serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg1_serif_accent4_darker: {
    color: COLORS.ACCENT4_DARKER,
    fontFamily: "Merriweather, serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg1_serif_accent4_darkest: {
    color: COLORS.ACCENT4_DARKEST,
    fontFamily: "Merriweather, serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg1_serif: {
    color: COLORS.BLACK,
    fontFamily: "Merriweather, serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg1_white: {
    color: COLORS.WHITE,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg1_black: {
    color: COLORS.BLACK,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg1_gray: {
    color: COLORS.GRAY,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg1_green_2: {
    color: COLORS.GREEN_2,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg1_dark_gray: {
    color: COLORS.DARK_GRAY,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg1_dark_gray_2: {
    color: COLORS.DARK_GRAY_2,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg1_error: {
    color: COLORS.ERROR,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg1_warning: {
    color: COLORS.WARNING,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg1_success: {
    color: COLORS.SUCCESS,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg1_primary: {
    color: COLORS.PRIMARY,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg1_primary_lightest: {
    color: COLORS.PRIMARY_LIGHTEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg1_primary_lighter: {
    color: COLORS.PRIMARY_LIGHTER,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg1_primary_light: {
    color: COLORS.PRIMARY_LIGHT,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg1_primary_dark: {
    color: COLORS.PRIMARY_DARK,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg1_primary_darker: {
    color: COLORS.PRIMARY_DARKER,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg1_primary_darkest: {
    color: COLORS.PRIMARY_DARKEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg1_secondary: {
    color: COLORS.SECONDARY,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg1_secondary_lightest: {
    color: COLORS.SECONDARY_LIGHTEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg1_secondary_lighter: {
    color: COLORS.SECONDARY_LIGHTER,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg1_secondary_light: {
    color: COLORS.SECONDARY_LIGHT,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg1_secondary_dark: {
    color: COLORS.SECONDARY_DARK,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg1_secondary_darker: {
    color: COLORS.SECONDARY_DARKER,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg1_secondary_darkest: {
    color: COLORS.SECONDARY_DARKEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg1_accent1: {
    color: COLORS.ACCENT1,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg1_accent1_lightest: {
    color: COLORS.ACCENT1_LIGHTEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg1_accent1_lighter: {
    color: COLORS.ACCENT1_LIGHTER,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg1_accent1_light: {
    color: COLORS.ACCENT1_LIGHT,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg1_accent1_dark: {
    color: COLORS.ACCENT1_DARK,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg1_accent1_darker: {
    color: COLORS.ACCENT1_DARKER,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg1_accent1_darkest: {
    color: COLORS.ACCENT1_DARKEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg1_accent2: {
    color: COLORS.ACCENT2,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg1_accent2_lightest: {
    color: COLORS.ACCENT2_LIGHTEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg1_accent2_lighter: {
    color: COLORS.ACCENT2_LIGHTER,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg1_accent2_light: {
    color: COLORS.ACCENT2_LIGHT,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg1_accent2_dark: {
    color: COLORS.ACCENT2_DARK,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg1_accent2_darker: {
    color: COLORS.ACCENT2_DARKER,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg1_accent2_darkest: {
    color: COLORS.ACCENT2_DARKEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg1_accent3: {
    color: COLORS.ACCENT3,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg1_accent3_lightest: {
    color: COLORS.ACCENT3_LIGHTEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg1_accent3_lighter: {
    color: COLORS.ACCENT3_LIGHTER,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg1_accent3_light: {
    color: COLORS.ACCENT3_LIGHT,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg1_accent3_dark: {
    color: COLORS.ACCENT3_DARK,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg1_accent3_darker: {
    color: COLORS.ACCENT3_DARKER,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg1_accent3_darkest: {
    color: COLORS.ACCENT3_DARKEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg1_accent4: {
    color: COLORS.ACCENT4,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg1_accent4_lightest: {
    color: COLORS.ACCENT4_LIGHTEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg1_accent4_lighter: {
    color: COLORS.ACCENT4_LIGHTER,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg1_accent4_light: {
    color: COLORS.ACCENT4_LIGHT,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg1_accent4_dark: {
    color: COLORS.ACCENT4_DARK,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg1_accent4_darker: {
    color: COLORS.ACCENT4_DARKER,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg1_accent4_darkest: {
    color: COLORS.ACCENT4_DARKEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg1: {
    color: COLORS.BLACK,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.375rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg_serif_white: {
    color: COLORS.WHITE,
    fontFamily: "Merriweather, serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg_serif_black: {
    color: COLORS.BLACK,
    fontFamily: "Merriweather, serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg_serif_gray: {
    color: COLORS.GRAY,
    fontFamily: "Merriweather, serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg_serif_green_2: {
    color: COLORS.GREEN_2,
    fontFamily: "Merriweather, serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg_serif_dark_gray: {
    color: COLORS.DARK_GRAY,
    fontFamily: "Merriweather, serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg_serif_dark_gray_2: {
    color: COLORS.DARK_GRAY_2,
    fontFamily: "Merriweather, serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg_serif_error: {
    color: COLORS.ERROR,
    fontFamily: "Merriweather, serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg_serif_warning: {
    color: COLORS.WARNING,
    fontFamily: "Merriweather, serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg_serif_success: {
    color: COLORS.SUCCESS,
    fontFamily: "Merriweather, serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg_serif_primary: {
    color: COLORS.PRIMARY,
    fontFamily: "Merriweather, serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg_serif_primary_lightest: {
    color: COLORS.PRIMARY_LIGHTEST,
    fontFamily: "Merriweather, serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg_serif_primary_lighter: {
    color: COLORS.PRIMARY_LIGHTER,
    fontFamily: "Merriweather, serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg_serif_primary_light: {
    color: COLORS.PRIMARY_LIGHT,
    fontFamily: "Merriweather, serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg_serif_primary_dark: {
    color: COLORS.PRIMARY_DARK,
    fontFamily: "Merriweather, serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg_serif_primary_darker: {
    color: COLORS.PRIMARY_DARKER,
    fontFamily: "Merriweather, serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg_serif_primary_darkest: {
    color: COLORS.PRIMARY_DARKEST,
    fontFamily: "Merriweather, serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg_serif_secondary: {
    color: COLORS.SECONDARY,
    fontFamily: "Merriweather, serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg_serif_secondary_lightest: {
    color: COLORS.SECONDARY_LIGHTEST,
    fontFamily: "Merriweather, serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg_serif_secondary_lighter: {
    color: COLORS.SECONDARY_LIGHTER,
    fontFamily: "Merriweather, serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg_serif_secondary_light: {
    color: COLORS.SECONDARY_LIGHT,
    fontFamily: "Merriweather, serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg_serif_secondary_dark: {
    color: COLORS.SECONDARY_DARK,
    fontFamily: "Merriweather, serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg_serif_secondary_darker: {
    color: COLORS.SECONDARY_DARKER,
    fontFamily: "Merriweather, serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg_serif_secondary_darkest: {
    color: COLORS.SECONDARY_DARKEST,
    fontFamily: "Merriweather, serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg_serif_accent1: {
    color: COLORS.ACCENT1,
    fontFamily: "Merriweather, serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg_serif_accent1_lightest: {
    color: COLORS.ACCENT1_LIGHTEST,
    fontFamily: "Merriweather, serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg_serif_accent1_lighter: {
    color: COLORS.ACCENT1_LIGHTER,
    fontFamily: "Merriweather, serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg_serif_accent1_light: {
    color: COLORS.ACCENT1_LIGHT,
    fontFamily: "Merriweather, serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg_serif_accent1_dark: {
    color: COLORS.ACCENT1_DARK,
    fontFamily: "Merriweather, serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg_serif_accent1_darker: {
    color: COLORS.ACCENT1_DARKER,
    fontFamily: "Merriweather, serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg_serif_accent1_darkest: {
    color: COLORS.ACCENT1_DARKEST,
    fontFamily: "Merriweather, serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg_serif_accent2: {
    color: COLORS.ACCENT2,
    fontFamily: "Merriweather, serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg_serif_accent2_lightest: {
    color: COLORS.ACCENT2_LIGHTEST,
    fontFamily: "Merriweather, serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg_serif_accent2_lighter: {
    color: COLORS.ACCENT2_LIGHTER,
    fontFamily: "Merriweather, serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg_serif_accent2_light: {
    color: COLORS.ACCENT2_LIGHT,
    fontFamily: "Merriweather, serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg_serif_accent2_dark: {
    color: COLORS.ACCENT2_DARK,
    fontFamily: "Merriweather, serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg_serif_accent2_darker: {
    color: COLORS.ACCENT2_DARKER,
    fontFamily: "Merriweather, serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg_serif_accent2_darkest: {
    color: COLORS.ACCENT2_DARKEST,
    fontFamily: "Merriweather, serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg_serif_accent3: {
    color: COLORS.ACCENT3,
    fontFamily: "Merriweather, serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg_serif_accent3_lightest: {
    color: COLORS.ACCENT3_LIGHTEST,
    fontFamily: "Merriweather, serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg_serif_accent3_lighter: {
    color: COLORS.ACCENT3_LIGHTER,
    fontFamily: "Merriweather, serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg_serif_accent3_light: {
    color: COLORS.ACCENT3_LIGHT,
    fontFamily: "Merriweather, serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg_serif_accent3_dark: {
    color: COLORS.ACCENT3_DARK,
    fontFamily: "Merriweather, serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg_serif_accent3_darker: {
    color: COLORS.ACCENT3_DARKER,
    fontFamily: "Merriweather, serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg_serif_accent3_darkest: {
    color: COLORS.ACCENT3_DARKEST,
    fontFamily: "Merriweather, serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg_serif_accent4: {
    color: COLORS.ACCENT4,
    fontFamily: "Merriweather, serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg_serif_accent4_lightest: {
    color: COLORS.ACCENT4_LIGHTEST,
    fontFamily: "Merriweather, serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg_serif_accent4_lighter: {
    color: COLORS.ACCENT4_LIGHTER,
    fontFamily: "Merriweather, serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg_serif_accent4_light: {
    color: COLORS.ACCENT4_LIGHT,
    fontFamily: "Merriweather, serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg_serif_accent4_dark: {
    color: COLORS.ACCENT4_DARK,
    fontFamily: "Merriweather, serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg_serif_accent4_darker: {
    color: COLORS.ACCENT4_DARKER,
    fontFamily: "Merriweather, serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg_serif_accent4_darkest: {
    color: COLORS.ACCENT4_DARKEST,
    fontFamily: "Merriweather, serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg_serif: {
    color: COLORS.BLACK,
    fontFamily: "Merriweather, serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg_white: {
    color: COLORS.WHITE,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg_black: {
    color: COLORS.BLACK,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg_gray: {
    color: COLORS.GRAY,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg_green_2: {
    color: COLORS.GREEN_2,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg_dark_gray: {
    color: COLORS.DARK_GRAY,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg_dark_gray_2: {
    color: COLORS.DARK_GRAY_2,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg_error: {
    color: COLORS.ERROR,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg_warning: {
    color: COLORS.WARNING,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg_success: {
    color: COLORS.SUCCESS,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg_primary: {
    color: COLORS.PRIMARY,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg_primary_lightest: {
    color: COLORS.PRIMARY_LIGHTEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg_primary_lighter: {
    color: COLORS.PRIMARY_LIGHTER,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg_primary_light: {
    color: COLORS.PRIMARY_LIGHT,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg_primary_dark: {
    color: COLORS.PRIMARY_DARK,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg_primary_darker: {
    color: COLORS.PRIMARY_DARKER,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg_primary_darkest: {
    color: COLORS.PRIMARY_DARKEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg_secondary: {
    color: COLORS.SECONDARY,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg_secondary_lightest: {
    color: COLORS.SECONDARY_LIGHTEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg_secondary_lighter: {
    color: COLORS.SECONDARY_LIGHTER,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg_secondary_light: {
    color: COLORS.SECONDARY_LIGHT,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg_secondary_dark: {
    color: COLORS.SECONDARY_DARK,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg_secondary_darker: {
    color: COLORS.SECONDARY_DARKER,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg_secondary_darkest: {
    color: COLORS.SECONDARY_DARKEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg_accent1: {
    color: COLORS.ACCENT1,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg_accent1_lightest: {
    color: COLORS.ACCENT1_LIGHTEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg_accent1_lighter: {
    color: COLORS.ACCENT1_LIGHTER,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg_accent1_light: {
    color: COLORS.ACCENT1_LIGHT,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg_accent1_dark: {
    color: COLORS.ACCENT1_DARK,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg_accent1_darker: {
    color: COLORS.ACCENT1_DARKER,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg_accent1_darkest: {
    color: COLORS.ACCENT1_DARKEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg_accent2: {
    color: COLORS.ACCENT2,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg_accent2_lightest: {
    color: COLORS.ACCENT2_LIGHTEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg_accent2_lighter: {
    color: COLORS.ACCENT2_LIGHTER,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg_accent2_light: {
    color: COLORS.ACCENT2_LIGHT,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg_accent2_dark: {
    color: COLORS.ACCENT2_DARK,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg_accent2_darker: {
    color: COLORS.ACCENT2_DARKER,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg_accent2_darkest: {
    color: COLORS.ACCENT2_DARKEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg_accent3: {
    color: COLORS.ACCENT3,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg_accent3_lightest: {
    color: COLORS.ACCENT3_LIGHTEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg_accent3_lighter: {
    color: COLORS.ACCENT3_LIGHTER,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg_accent3_light: {
    color: COLORS.ACCENT3_LIGHT,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg_accent3_dark: {
    color: COLORS.ACCENT3_DARK,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg_accent3_darker: {
    color: COLORS.ACCENT3_DARKER,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg_accent3_darkest: {
    color: COLORS.ACCENT3_DARKEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg_accent4: {
    color: COLORS.ACCENT4,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg_accent4_lightest: {
    color: COLORS.ACCENT4_LIGHTEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg_accent4_lighter: {
    color: COLORS.ACCENT4_LIGHTER,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg_accent4_light: {
    color: COLORS.ACCENT4_LIGHT,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg_accent4_dark: {
    color: COLORS.ACCENT4_DARK,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg_accent4_darker: {
    color: COLORS.ACCENT4_DARKER,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg_accent4_darkest: {
    color: COLORS.ACCENT4_DARKEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_lg: {
    color: COLORS.BLACK,
    fontFamily: "Inter, sans-serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_serif_white: {
    color: COLORS.WHITE,
    fontFamily: "Merriweather, serif",
    fontSize: "1rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_serif_black: {
    color: COLORS.BLACK,
    fontFamily: "Merriweather, serif",
    fontSize: "1rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_serif_gray: {
    color: COLORS.GRAY,
    fontFamily: "Merriweather, serif",
    fontSize: "1rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_serif_green_2: {
    color: COLORS.GREEN_2,
    fontFamily: "Merriweather, serif",
    fontSize: "1rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_serif_dark_gray: {
    color: COLORS.DARK_GRAY,
    fontFamily: "Merriweather, serif",
    fontSize: "1rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_serif_dark_gray_2: {
    color: COLORS.DARK_GRAY_2,
    fontFamily: "Merriweather, serif",
    fontSize: "1rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_serif_error: {
    color: COLORS.ERROR,
    fontFamily: "Merriweather, serif",
    fontSize: "1rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_serif_warning: {
    color: COLORS.WARNING,
    fontFamily: "Merriweather, serif",
    fontSize: "1rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_serif_success: {
    color: COLORS.SUCCESS,
    fontFamily: "Merriweather, serif",
    fontSize: "1rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_serif_primary: {
    color: COLORS.PRIMARY,
    fontFamily: "Merriweather, serif",
    fontSize: "1rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_serif_primary_lightest: {
    color: COLORS.PRIMARY_LIGHTEST,
    fontFamily: "Merriweather, serif",
    fontSize: "1rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_serif_primary_lighter: {
    color: COLORS.PRIMARY_LIGHTER,
    fontFamily: "Merriweather, serif",
    fontSize: "1rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_serif_primary_light: {
    color: COLORS.PRIMARY_LIGHT,
    fontFamily: "Merriweather, serif",
    fontSize: "1rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_serif_primary_dark: {
    color: COLORS.PRIMARY_DARK,
    fontFamily: "Merriweather, serif",
    fontSize: "1rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_serif_primary_darker: {
    color: COLORS.PRIMARY_DARKER,
    fontFamily: "Merriweather, serif",
    fontSize: "1rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_serif_primary_darkest: {
    color: COLORS.PRIMARY_DARKEST,
    fontFamily: "Merriweather, serif",
    fontSize: "1rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_serif_secondary: {
    color: COLORS.SECONDARY,
    fontFamily: "Merriweather, serif",
    fontSize: "1rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_serif_secondary_lightest: {
    color: COLORS.SECONDARY_LIGHTEST,
    fontFamily: "Merriweather, serif",
    fontSize: "1rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_serif_secondary_lighter: {
    color: COLORS.SECONDARY_LIGHTER,
    fontFamily: "Merriweather, serif",
    fontSize: "1rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_serif_secondary_light: {
    color: COLORS.SECONDARY_LIGHT,
    fontFamily: "Merriweather, serif",
    fontSize: "1rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_serif_secondary_dark: {
    color: COLORS.SECONDARY_DARK,
    fontFamily: "Merriweather, serif",
    fontSize: "1rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_serif_secondary_darker: {
    color: COLORS.SECONDARY_DARKER,
    fontFamily: "Merriweather, serif",
    fontSize: "1rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_serif_secondary_darkest: {
    color: COLORS.SECONDARY_DARKEST,
    fontFamily: "Merriweather, serif",
    fontSize: "1rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_serif_accent1: {
    color: COLORS.ACCENT1,
    fontFamily: "Merriweather, serif",
    fontSize: "1rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_serif_accent1_lightest: {
    color: COLORS.ACCENT1_LIGHTEST,
    fontFamily: "Merriweather, serif",
    fontSize: "1rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_serif_accent1_lighter: {
    color: COLORS.ACCENT1_LIGHTER,
    fontFamily: "Merriweather, serif",
    fontSize: "1rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_serif_accent1_light: {
    color: COLORS.ACCENT1_LIGHT,
    fontFamily: "Merriweather, serif",
    fontSize: "1rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_serif_accent1_dark: {
    color: COLORS.ACCENT1_DARK,
    fontFamily: "Merriweather, serif",
    fontSize: "1rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_serif_accent1_darker: {
    color: COLORS.ACCENT1_DARKER,
    fontFamily: "Merriweather, serif",
    fontSize: "1rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_serif_accent1_darkest: {
    color: COLORS.ACCENT1_DARKEST,
    fontFamily: "Merriweather, serif",
    fontSize: "1rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_serif_accent2: {
    color: COLORS.ACCENT2,
    fontFamily: "Merriweather, serif",
    fontSize: "1rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_serif_accent2_lightest: {
    color: COLORS.ACCENT2_LIGHTEST,
    fontFamily: "Merriweather, serif",
    fontSize: "1rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_serif_accent2_lighter: {
    color: COLORS.ACCENT2_LIGHTER,
    fontFamily: "Merriweather, serif",
    fontSize: "1rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_serif_accent2_light: {
    color: COLORS.ACCENT2_LIGHT,
    fontFamily: "Merriweather, serif",
    fontSize: "1rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_serif_accent2_dark: {
    color: COLORS.ACCENT2_DARK,
    fontFamily: "Merriweather, serif",
    fontSize: "1rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_serif_accent2_darker: {
    color: COLORS.ACCENT2_DARKER,
    fontFamily: "Merriweather, serif",
    fontSize: "1rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_serif_accent2_darkest: {
    color: COLORS.ACCENT2_DARKEST,
    fontFamily: "Merriweather, serif",
    fontSize: "1rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_serif_accent3: {
    color: COLORS.ACCENT3,
    fontFamily: "Merriweather, serif",
    fontSize: "1rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_serif_accent3_lightest: {
    color: COLORS.ACCENT3_LIGHTEST,
    fontFamily: "Merriweather, serif",
    fontSize: "1rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_serif_accent3_lighter: {
    color: COLORS.ACCENT3_LIGHTER,
    fontFamily: "Merriweather, serif",
    fontSize: "1rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_serif_accent3_light: {
    color: COLORS.ACCENT3_LIGHT,
    fontFamily: "Merriweather, serif",
    fontSize: "1rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_serif_accent3_dark: {
    color: COLORS.ACCENT3_DARK,
    fontFamily: "Merriweather, serif",
    fontSize: "1rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_serif_accent3_darker: {
    color: COLORS.ACCENT3_DARKER,
    fontFamily: "Merriweather, serif",
    fontSize: "1rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_serif_accent3_darkest: {
    color: COLORS.ACCENT3_DARKEST,
    fontFamily: "Merriweather, serif",
    fontSize: "1rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_serif_accent4: {
    color: COLORS.ACCENT4,
    fontFamily: "Merriweather, serif",
    fontSize: "1rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_serif_accent4_lightest: {
    color: COLORS.ACCENT4_LIGHTEST,
    fontFamily: "Merriweather, serif",
    fontSize: "1rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_serif_accent4_lighter: {
    color: COLORS.ACCENT4_LIGHTER,
    fontFamily: "Merriweather, serif",
    fontSize: "1rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_serif_accent4_light: {
    color: COLORS.ACCENT4_LIGHT,
    fontFamily: "Merriweather, serif",
    fontSize: "1rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_serif_accent4_dark: {
    color: COLORS.ACCENT4_DARK,
    fontFamily: "Merriweather, serif",
    fontSize: "1rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_serif_accent4_darker: {
    color: COLORS.ACCENT4_DARKER,
    fontFamily: "Merriweather, serif",
    fontSize: "1rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_serif_accent4_darkest: {
    color: COLORS.ACCENT4_DARKEST,
    fontFamily: "Merriweather, serif",
    fontSize: "1rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_serif: {
    color: COLORS.BLACK,
    fontFamily: "Merriweather, serif",
    fontSize: "1rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_white: {
    color: COLORS.WHITE,
    fontFamily: "Inter, sans-serif",
    fontSize: "1rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_black: {
    color: COLORS.BLACK,
    fontFamily: "Inter, sans-serif",
    fontSize: "1rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_gray: {
    color: COLORS.GRAY,
    fontFamily: "Inter, sans-serif",
    fontSize: "1rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_green_2: {
    color: COLORS.GREEN_2,
    fontFamily: "Inter, sans-serif",
    fontSize: "1rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_dark_gray: {
    color: COLORS.DARK_GRAY,
    fontFamily: "Inter, sans-serif",
    fontSize: "1rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_dark_gray_2: {
    color: COLORS.DARK_GRAY_2,
    fontFamily: "Inter, sans-serif",
    fontSize: "1rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_error: {
    color: COLORS.ERROR,
    fontFamily: "Inter, sans-serif",
    fontSize: "1rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_warning: {
    color: COLORS.WARNING,
    fontFamily: "Inter, sans-serif",
    fontSize: "1rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_success: {
    color: COLORS.SUCCESS,
    fontFamily: "Inter, sans-serif",
    fontSize: "1rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_primary: {
    color: COLORS.PRIMARY,
    fontFamily: "Inter, sans-serif",
    fontSize: "1rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_primary_lightest: {
    color: COLORS.PRIMARY_LIGHTEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "1rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_primary_lighter: {
    color: COLORS.PRIMARY_LIGHTER,
    fontFamily: "Inter, sans-serif",
    fontSize: "1rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_primary_light: {
    color: COLORS.PRIMARY_LIGHT,
    fontFamily: "Inter, sans-serif",
    fontSize: "1rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_primary_dark: {
    color: COLORS.PRIMARY_DARK,
    fontFamily: "Inter, sans-serif",
    fontSize: "1rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_primary_darker: {
    color: COLORS.PRIMARY_DARKER,
    fontFamily: "Inter, sans-serif",
    fontSize: "1rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_primary_darkest: {
    color: COLORS.PRIMARY_DARKEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "1rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_secondary: {
    color: COLORS.SECONDARY,
    fontFamily: "Inter, sans-serif",
    fontSize: "1rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_secondary_lightest: {
    color: COLORS.SECONDARY_LIGHTEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "1rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_secondary_lighter: {
    color: COLORS.SECONDARY_LIGHTER,
    fontFamily: "Inter, sans-serif",
    fontSize: "1rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_secondary_light: {
    color: COLORS.SECONDARY_LIGHT,
    fontFamily: "Inter, sans-serif",
    fontSize: "1rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_secondary_dark: {
    color: COLORS.SECONDARY_DARK,
    fontFamily: "Inter, sans-serif",
    fontSize: "1rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_secondary_darker: {
    color: COLORS.SECONDARY_DARKER,
    fontFamily: "Inter, sans-serif",
    fontSize: "1rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_secondary_darkest: {
    color: COLORS.SECONDARY_DARKEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "1rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_accent1: {
    color: COLORS.ACCENT1,
    fontFamily: "Inter, sans-serif",
    fontSize: "1rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_accent1_lightest: {
    color: COLORS.ACCENT1_LIGHTEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "1rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_accent1_lighter: {
    color: COLORS.ACCENT1_LIGHTER,
    fontFamily: "Inter, sans-serif",
    fontSize: "1rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_accent1_light: {
    color: COLORS.ACCENT1_LIGHT,
    fontFamily: "Inter, sans-serif",
    fontSize: "1rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_accent1_dark: {
    color: COLORS.ACCENT1_DARK,
    fontFamily: "Inter, sans-serif",
    fontSize: "1rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_accent1_darker: {
    color: COLORS.ACCENT1_DARKER,
    fontFamily: "Inter, sans-serif",
    fontSize: "1rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_accent1_darkest: {
    color: COLORS.ACCENT1_DARKEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "1rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_accent2: {
    color: COLORS.ACCENT2,
    fontFamily: "Inter, sans-serif",
    fontSize: "1rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_accent2_lightest: {
    color: COLORS.ACCENT2_LIGHTEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "1rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_accent2_lighter: {
    color: COLORS.ACCENT2_LIGHTER,
    fontFamily: "Inter, sans-serif",
    fontSize: "1rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_accent2_light: {
    color: COLORS.ACCENT2_LIGHT,
    fontFamily: "Inter, sans-serif",
    fontSize: "1rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_accent2_dark: {
    color: COLORS.ACCENT2_DARK,
    fontFamily: "Inter, sans-serif",
    fontSize: "1rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_accent2_darker: {
    color: COLORS.ACCENT2_DARKER,
    fontFamily: "Inter, sans-serif",
    fontSize: "1rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_accent2_darkest: {
    color: COLORS.ACCENT2_DARKEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "1rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_accent3: {
    color: COLORS.ACCENT3,
    fontFamily: "Inter, sans-serif",
    fontSize: "1rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_accent3_lightest: {
    color: COLORS.ACCENT3_LIGHTEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "1rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_accent3_lighter: {
    color: COLORS.ACCENT3_LIGHTER,
    fontFamily: "Inter, sans-serif",
    fontSize: "1rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_accent3_light: {
    color: COLORS.ACCENT3_LIGHT,
    fontFamily: "Inter, sans-serif",
    fontSize: "1rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_accent3_dark: {
    color: COLORS.ACCENT3_DARK,
    fontFamily: "Inter, sans-serif",
    fontSize: "1rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_accent3_darker: {
    color: COLORS.ACCENT3_DARKER,
    fontFamily: "Inter, sans-serif",
    fontSize: "1rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_accent3_darkest: {
    color: COLORS.ACCENT3_DARKEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "1rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_accent4: {
    color: COLORS.ACCENT4,
    fontFamily: "Inter, sans-serif",
    fontSize: "1rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_accent4_lightest: {
    color: COLORS.ACCENT4_LIGHTEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "1rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_accent4_lighter: {
    color: COLORS.ACCENT4_LIGHTER,
    fontFamily: "Inter, sans-serif",
    fontSize: "1rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_accent4_light: {
    color: COLORS.ACCENT4_LIGHT,
    fontFamily: "Inter, sans-serif",
    fontSize: "1rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_accent4_dark: {
    color: COLORS.ACCENT4_DARK,
    fontFamily: "Inter, sans-serif",
    fontSize: "1rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_accent4_darker: {
    color: COLORS.ACCENT4_DARKER,
    fontFamily: "Inter, sans-serif",
    fontSize: "1rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_accent4_darkest: {
    color: COLORS.ACCENT4_DARKEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "1rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong: {
    color: COLORS.BLACK,
    fontFamily: "Inter, sans-serif",
    fontSize: "1rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_sm_serif_white: {
    color: COLORS.WHITE,
    fontFamily: "Merriweather, serif",
    fontSize: "0.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_sm_serif_black: {
    color: COLORS.BLACK,
    fontFamily: "Merriweather, serif",
    fontSize: "0.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_sm_serif_gray: {
    color: COLORS.GRAY,
    fontFamily: "Merriweather, serif",
    fontSize: "0.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_sm_serif_green_2: {
    color: COLORS.GREEN_2,
    fontFamily: "Merriweather, serif",
    fontSize: "0.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_sm_serif_dark_gray: {
    color: COLORS.DARK_GRAY,
    fontFamily: "Merriweather, serif",
    fontSize: "0.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_sm_serif_dark_gray_2: {
    color: COLORS.DARK_GRAY_2,
    fontFamily: "Merriweather, serif",
    fontSize: "0.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_sm_serif_error: {
    color: COLORS.ERROR,
    fontFamily: "Merriweather, serif",
    fontSize: "0.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_sm_serif_warning: {
    color: COLORS.WARNING,
    fontFamily: "Merriweather, serif",
    fontSize: "0.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_sm_serif_success: {
    color: COLORS.SUCCESS,
    fontFamily: "Merriweather, serif",
    fontSize: "0.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_sm_serif_primary: {
    color: COLORS.PRIMARY,
    fontFamily: "Merriweather, serif",
    fontSize: "0.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_sm_serif_primary_lightest: {
    color: COLORS.PRIMARY_LIGHTEST,
    fontFamily: "Merriweather, serif",
    fontSize: "0.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_sm_serif_primary_lighter: {
    color: COLORS.PRIMARY_LIGHTER,
    fontFamily: "Merriweather, serif",
    fontSize: "0.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_sm_serif_primary_light: {
    color: COLORS.PRIMARY_LIGHT,
    fontFamily: "Merriweather, serif",
    fontSize: "0.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_sm_serif_primary_dark: {
    color: COLORS.PRIMARY_DARK,
    fontFamily: "Merriweather, serif",
    fontSize: "0.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_sm_serif_primary_darker: {
    color: COLORS.PRIMARY_DARKER,
    fontFamily: "Merriweather, serif",
    fontSize: "0.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_sm_serif_primary_darkest: {
    color: COLORS.PRIMARY_DARKEST,
    fontFamily: "Merriweather, serif",
    fontSize: "0.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_sm_serif_secondary: {
    color: COLORS.SECONDARY,
    fontFamily: "Merriweather, serif",
    fontSize: "0.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_sm_serif_secondary_lightest: {
    color: COLORS.SECONDARY_LIGHTEST,
    fontFamily: "Merriweather, serif",
    fontSize: "0.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_sm_serif_secondary_lighter: {
    color: COLORS.SECONDARY_LIGHTER,
    fontFamily: "Merriweather, serif",
    fontSize: "0.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_sm_serif_secondary_light: {
    color: COLORS.SECONDARY_LIGHT,
    fontFamily: "Merriweather, serif",
    fontSize: "0.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_sm_serif_secondary_dark: {
    color: COLORS.SECONDARY_DARK,
    fontFamily: "Merriweather, serif",
    fontSize: "0.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_sm_serif_secondary_darker: {
    color: COLORS.SECONDARY_DARKER,
    fontFamily: "Merriweather, serif",
    fontSize: "0.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_sm_serif_secondary_darkest: {
    color: COLORS.SECONDARY_DARKEST,
    fontFamily: "Merriweather, serif",
    fontSize: "0.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_sm_serif_accent1: {
    color: COLORS.ACCENT1,
    fontFamily: "Merriweather, serif",
    fontSize: "0.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_sm_serif_accent1_lightest: {
    color: COLORS.ACCENT1_LIGHTEST,
    fontFamily: "Merriweather, serif",
    fontSize: "0.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_sm_serif_accent1_lighter: {
    color: COLORS.ACCENT1_LIGHTER,
    fontFamily: "Merriweather, serif",
    fontSize: "0.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_sm_serif_accent1_light: {
    color: COLORS.ACCENT1_LIGHT,
    fontFamily: "Merriweather, serif",
    fontSize: "0.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_sm_serif_accent1_dark: {
    color: COLORS.ACCENT1_DARK,
    fontFamily: "Merriweather, serif",
    fontSize: "0.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_sm_serif_accent1_darker: {
    color: COLORS.ACCENT1_DARKER,
    fontFamily: "Merriweather, serif",
    fontSize: "0.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_sm_serif_accent1_darkest: {
    color: COLORS.ACCENT1_DARKEST,
    fontFamily: "Merriweather, serif",
    fontSize: "0.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_sm_serif_accent2: {
    color: COLORS.ACCENT2,
    fontFamily: "Merriweather, serif",
    fontSize: "0.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_sm_serif_accent2_lightest: {
    color: COLORS.ACCENT2_LIGHTEST,
    fontFamily: "Merriweather, serif",
    fontSize: "0.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_sm_serif_accent2_lighter: {
    color: COLORS.ACCENT2_LIGHTER,
    fontFamily: "Merriweather, serif",
    fontSize: "0.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_sm_serif_accent2_light: {
    color: COLORS.ACCENT2_LIGHT,
    fontFamily: "Merriweather, serif",
    fontSize: "0.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_sm_serif_accent2_dark: {
    color: COLORS.ACCENT2_DARK,
    fontFamily: "Merriweather, serif",
    fontSize: "0.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_sm_serif_accent2_darker: {
    color: COLORS.ACCENT2_DARKER,
    fontFamily: "Merriweather, serif",
    fontSize: "0.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_sm_serif_accent2_darkest: {
    color: COLORS.ACCENT2_DARKEST,
    fontFamily: "Merriweather, serif",
    fontSize: "0.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_sm_serif_accent3: {
    color: COLORS.ACCENT3,
    fontFamily: "Merriweather, serif",
    fontSize: "0.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_sm_serif_accent3_lightest: {
    color: COLORS.ACCENT3_LIGHTEST,
    fontFamily: "Merriweather, serif",
    fontSize: "0.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_sm_serif_accent3_lighter: {
    color: COLORS.ACCENT3_LIGHTER,
    fontFamily: "Merriweather, serif",
    fontSize: "0.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_sm_serif_accent3_light: {
    color: COLORS.ACCENT3_LIGHT,
    fontFamily: "Merriweather, serif",
    fontSize: "0.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_sm_serif_accent3_dark: {
    color: COLORS.ACCENT3_DARK,
    fontFamily: "Merriweather, serif",
    fontSize: "0.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_sm_serif_accent3_darker: {
    color: COLORS.ACCENT3_DARKER,
    fontFamily: "Merriweather, serif",
    fontSize: "0.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_sm_serif_accent3_darkest: {
    color: COLORS.ACCENT3_DARKEST,
    fontFamily: "Merriweather, serif",
    fontSize: "0.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_sm_serif_accent4: {
    color: COLORS.ACCENT4,
    fontFamily: "Merriweather, serif",
    fontSize: "0.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_sm_serif_accent4_lightest: {
    color: COLORS.ACCENT4_LIGHTEST,
    fontFamily: "Merriweather, serif",
    fontSize: "0.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_sm_serif_accent4_lighter: {
    color: COLORS.ACCENT4_LIGHTER,
    fontFamily: "Merriweather, serif",
    fontSize: "0.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_sm_serif_accent4_light: {
    color: COLORS.ACCENT4_LIGHT,
    fontFamily: "Merriweather, serif",
    fontSize: "0.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_sm_serif_accent4_dark: {
    color: COLORS.ACCENT4_DARK,
    fontFamily: "Merriweather, serif",
    fontSize: "0.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_sm_serif_accent4_darker: {
    color: COLORS.ACCENT4_DARKER,
    fontFamily: "Merriweather, serif",
    fontSize: "0.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_sm_serif_accent4_darkest: {
    color: COLORS.ACCENT4_DARKEST,
    fontFamily: "Merriweather, serif",
    fontSize: "0.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_sm_serif: {
    color: COLORS.BLACK,
    fontFamily: "Merriweather, serif",
    fontSize: "0.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_sm_white: {
    color: COLORS.WHITE,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_sm_black: {
    color: COLORS.BLACK,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_sm_gray: {
    color: COLORS.GRAY,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_sm_green_2: {
    color: COLORS.GREEN_2,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_sm_dark_gray: {
    color: COLORS.DARK_GRAY,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_sm_dark_gray_2: {
    color: COLORS.DARK_GRAY_2,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_sm_error: {
    color: COLORS.ERROR,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_sm_warning: {
    color: COLORS.WARNING,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_sm_success: {
    color: COLORS.SUCCESS,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_sm_primary: {
    color: COLORS.PRIMARY,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_sm_primary_lightest: {
    color: COLORS.PRIMARY_LIGHTEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_sm_primary_lighter: {
    color: COLORS.PRIMARY_LIGHTER,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_sm_primary_light: {
    color: COLORS.PRIMARY_LIGHT,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_sm_primary_dark: {
    color: COLORS.PRIMARY_DARK,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_sm_primary_darker: {
    color: COLORS.PRIMARY_DARKER,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_sm_primary_darkest: {
    color: COLORS.PRIMARY_DARKEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_sm_secondary: {
    color: COLORS.SECONDARY,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_sm_secondary_lightest: {
    color: COLORS.SECONDARY_LIGHTEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_sm_secondary_lighter: {
    color: COLORS.SECONDARY_LIGHTER,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_sm_secondary_light: {
    color: COLORS.SECONDARY_LIGHT,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_sm_secondary_dark: {
    color: COLORS.SECONDARY_DARK,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_sm_secondary_darker: {
    color: COLORS.SECONDARY_DARKER,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_sm_secondary_darkest: {
    color: COLORS.SECONDARY_DARKEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_sm_accent1: {
    color: COLORS.ACCENT1,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_sm_accent1_lightest: {
    color: COLORS.ACCENT1_LIGHTEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_sm_accent1_lighter: {
    color: COLORS.ACCENT1_LIGHTER,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_sm_accent1_light: {
    color: COLORS.ACCENT1_LIGHT,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_sm_accent1_dark: {
    color: COLORS.ACCENT1_DARK,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_sm_accent1_darker: {
    color: COLORS.ACCENT1_DARKER,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_sm_accent1_darkest: {
    color: COLORS.ACCENT1_DARKEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_sm_accent2: {
    color: COLORS.ACCENT2,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_sm_accent2_lightest: {
    color: COLORS.ACCENT2_LIGHTEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_sm_accent2_lighter: {
    color: COLORS.ACCENT2_LIGHTER,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_sm_accent2_light: {
    color: COLORS.ACCENT2_LIGHT,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_sm_accent2_dark: {
    color: COLORS.ACCENT2_DARK,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_sm_accent2_darker: {
    color: COLORS.ACCENT2_DARKER,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_sm_accent2_darkest: {
    color: COLORS.ACCENT2_DARKEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_sm_accent3: {
    color: COLORS.ACCENT3,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_sm_accent3_lightest: {
    color: COLORS.ACCENT3_LIGHTEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_sm_accent3_lighter: {
    color: COLORS.ACCENT3_LIGHTER,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_sm_accent3_light: {
    color: COLORS.ACCENT3_LIGHT,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_sm_accent3_dark: {
    color: COLORS.ACCENT3_DARK,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_sm_accent3_darker: {
    color: COLORS.ACCENT3_DARKER,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_sm_accent3_darkest: {
    color: COLORS.ACCENT3_DARKEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_sm_accent4: {
    color: COLORS.ACCENT4,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_sm_accent4_lightest: {
    color: COLORS.ACCENT4_LIGHTEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_sm_accent4_lighter: {
    color: COLORS.ACCENT4_LIGHTER,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_sm_accent4_light: {
    color: COLORS.ACCENT4_LIGHT,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_sm_accent4_dark: {
    color: COLORS.ACCENT4_DARK,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_sm_accent4_darker: {
    color: COLORS.ACCENT4_DARKER,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_sm_accent4_darkest: {
    color: COLORS.ACCENT4_DARKEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_sm: {
    color: COLORS.BLACK,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xs_serif_white: {
    color: COLORS.WHITE,
    fontFamily: "Merriweather, serif",
    fontSize: "0.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xs_serif_black: {
    color: COLORS.BLACK,
    fontFamily: "Merriweather, serif",
    fontSize: "0.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xs_serif_gray: {
    color: COLORS.GRAY,
    fontFamily: "Merriweather, serif",
    fontSize: "0.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xs_serif_green_2: {
    color: COLORS.GREEN_2,
    fontFamily: "Merriweather, serif",
    fontSize: "0.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xs_serif_dark_gray: {
    color: COLORS.DARK_GRAY,
    fontFamily: "Merriweather, serif",
    fontSize: "0.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xs_serif_dark_gray_2: {
    color: COLORS.DARK_GRAY_2,
    fontFamily: "Merriweather, serif",
    fontSize: "0.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xs_serif_error: {
    color: COLORS.ERROR,
    fontFamily: "Merriweather, serif",
    fontSize: "0.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xs_serif_warning: {
    color: COLORS.WARNING,
    fontFamily: "Merriweather, serif",
    fontSize: "0.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xs_serif_success: {
    color: COLORS.SUCCESS,
    fontFamily: "Merriweather, serif",
    fontSize: "0.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xs_serif_primary: {
    color: COLORS.PRIMARY,
    fontFamily: "Merriweather, serif",
    fontSize: "0.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xs_serif_primary_lightest: {
    color: COLORS.PRIMARY_LIGHTEST,
    fontFamily: "Merriweather, serif",
    fontSize: "0.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xs_serif_primary_lighter: {
    color: COLORS.PRIMARY_LIGHTER,
    fontFamily: "Merriweather, serif",
    fontSize: "0.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xs_serif_primary_light: {
    color: COLORS.PRIMARY_LIGHT,
    fontFamily: "Merriweather, serif",
    fontSize: "0.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xs_serif_primary_dark: {
    color: COLORS.PRIMARY_DARK,
    fontFamily: "Merriweather, serif",
    fontSize: "0.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xs_serif_primary_darker: {
    color: COLORS.PRIMARY_DARKER,
    fontFamily: "Merriweather, serif",
    fontSize: "0.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xs_serif_primary_darkest: {
    color: COLORS.PRIMARY_DARKEST,
    fontFamily: "Merriweather, serif",
    fontSize: "0.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xs_serif_secondary: {
    color: COLORS.SECONDARY,
    fontFamily: "Merriweather, serif",
    fontSize: "0.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xs_serif_secondary_lightest: {
    color: COLORS.SECONDARY_LIGHTEST,
    fontFamily: "Merriweather, serif",
    fontSize: "0.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xs_serif_secondary_lighter: {
    color: COLORS.SECONDARY_LIGHTER,
    fontFamily: "Merriweather, serif",
    fontSize: "0.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xs_serif_secondary_light: {
    color: COLORS.SECONDARY_LIGHT,
    fontFamily: "Merriweather, serif",
    fontSize: "0.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xs_serif_secondary_dark: {
    color: COLORS.SECONDARY_DARK,
    fontFamily: "Merriweather, serif",
    fontSize: "0.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xs_serif_secondary_darker: {
    color: COLORS.SECONDARY_DARKER,
    fontFamily: "Merriweather, serif",
    fontSize: "0.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xs_serif_secondary_darkest: {
    color: COLORS.SECONDARY_DARKEST,
    fontFamily: "Merriweather, serif",
    fontSize: "0.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xs_serif_accent1: {
    color: COLORS.ACCENT1,
    fontFamily: "Merriweather, serif",
    fontSize: "0.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xs_serif_accent1_lightest: {
    color: COLORS.ACCENT1_LIGHTEST,
    fontFamily: "Merriweather, serif",
    fontSize: "0.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xs_serif_accent1_lighter: {
    color: COLORS.ACCENT1_LIGHTER,
    fontFamily: "Merriweather, serif",
    fontSize: "0.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xs_serif_accent1_light: {
    color: COLORS.ACCENT1_LIGHT,
    fontFamily: "Merriweather, serif",
    fontSize: "0.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xs_serif_accent1_dark: {
    color: COLORS.ACCENT1_DARK,
    fontFamily: "Merriweather, serif",
    fontSize: "0.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xs_serif_accent1_darker: {
    color: COLORS.ACCENT1_DARKER,
    fontFamily: "Merriweather, serif",
    fontSize: "0.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xs_serif_accent1_darkest: {
    color: COLORS.ACCENT1_DARKEST,
    fontFamily: "Merriweather, serif",
    fontSize: "0.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xs_serif_accent2: {
    color: COLORS.ACCENT2,
    fontFamily: "Merriweather, serif",
    fontSize: "0.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xs_serif_accent2_lightest: {
    color: COLORS.ACCENT2_LIGHTEST,
    fontFamily: "Merriweather, serif",
    fontSize: "0.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xs_serif_accent2_lighter: {
    color: COLORS.ACCENT2_LIGHTER,
    fontFamily: "Merriweather, serif",
    fontSize: "0.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xs_serif_accent2_light: {
    color: COLORS.ACCENT2_LIGHT,
    fontFamily: "Merriweather, serif",
    fontSize: "0.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xs_serif_accent2_dark: {
    color: COLORS.ACCENT2_DARK,
    fontFamily: "Merriweather, serif",
    fontSize: "0.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xs_serif_accent2_darker: {
    color: COLORS.ACCENT2_DARKER,
    fontFamily: "Merriweather, serif",
    fontSize: "0.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xs_serif_accent2_darkest: {
    color: COLORS.ACCENT2_DARKEST,
    fontFamily: "Merriweather, serif",
    fontSize: "0.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xs_serif_accent3: {
    color: COLORS.ACCENT3,
    fontFamily: "Merriweather, serif",
    fontSize: "0.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xs_serif_accent3_lightest: {
    color: COLORS.ACCENT3_LIGHTEST,
    fontFamily: "Merriweather, serif",
    fontSize: "0.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xs_serif_accent3_lighter: {
    color: COLORS.ACCENT3_LIGHTER,
    fontFamily: "Merriweather, serif",
    fontSize: "0.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xs_serif_accent3_light: {
    color: COLORS.ACCENT3_LIGHT,
    fontFamily: "Merriweather, serif",
    fontSize: "0.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xs_serif_accent3_dark: {
    color: COLORS.ACCENT3_DARK,
    fontFamily: "Merriweather, serif",
    fontSize: "0.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xs_serif_accent3_darker: {
    color: COLORS.ACCENT3_DARKER,
    fontFamily: "Merriweather, serif",
    fontSize: "0.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xs_serif_accent3_darkest: {
    color: COLORS.ACCENT3_DARKEST,
    fontFamily: "Merriweather, serif",
    fontSize: "0.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xs_serif_accent4: {
    color: COLORS.ACCENT4,
    fontFamily: "Merriweather, serif",
    fontSize: "0.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xs_serif_accent4_lightest: {
    color: COLORS.ACCENT4_LIGHTEST,
    fontFamily: "Merriweather, serif",
    fontSize: "0.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xs_serif_accent4_lighter: {
    color: COLORS.ACCENT4_LIGHTER,
    fontFamily: "Merriweather, serif",
    fontSize: "0.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xs_serif_accent4_light: {
    color: COLORS.ACCENT4_LIGHT,
    fontFamily: "Merriweather, serif",
    fontSize: "0.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xs_serif_accent4_dark: {
    color: COLORS.ACCENT4_DARK,
    fontFamily: "Merriweather, serif",
    fontSize: "0.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xs_serif_accent4_darker: {
    color: COLORS.ACCENT4_DARKER,
    fontFamily: "Merriweather, serif",
    fontSize: "0.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xs_serif_accent4_darkest: {
    color: COLORS.ACCENT4_DARKEST,
    fontFamily: "Merriweather, serif",
    fontSize: "0.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xs_serif: {
    color: COLORS.BLACK,
    fontFamily: "Merriweather, serif",
    fontSize: "0.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xs_white: {
    color: COLORS.WHITE,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xs_black: {
    color: COLORS.BLACK,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xs_gray: {
    color: COLORS.GRAY,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xs_green_2: {
    color: COLORS.GREEN_2,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xs_dark_gray: {
    color: COLORS.DARK_GRAY,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xs_dark_gray_2: {
    color: COLORS.DARK_GRAY_2,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xs_error: {
    color: COLORS.ERROR,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xs_warning: {
    color: COLORS.WARNING,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xs_success: {
    color: COLORS.SUCCESS,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xs_primary: {
    color: COLORS.PRIMARY,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xs_primary_lightest: {
    color: COLORS.PRIMARY_LIGHTEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xs_primary_lighter: {
    color: COLORS.PRIMARY_LIGHTER,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xs_primary_light: {
    color: COLORS.PRIMARY_LIGHT,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xs_primary_dark: {
    color: COLORS.PRIMARY_DARK,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xs_primary_darker: {
    color: COLORS.PRIMARY_DARKER,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xs_primary_darkest: {
    color: COLORS.PRIMARY_DARKEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xs_secondary: {
    color: COLORS.SECONDARY,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xs_secondary_lightest: {
    color: COLORS.SECONDARY_LIGHTEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xs_secondary_lighter: {
    color: COLORS.SECONDARY_LIGHTER,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xs_secondary_light: {
    color: COLORS.SECONDARY_LIGHT,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xs_secondary_dark: {
    color: COLORS.SECONDARY_DARK,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xs_secondary_darker: {
    color: COLORS.SECONDARY_DARKER,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xs_secondary_darkest: {
    color: COLORS.SECONDARY_DARKEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xs_accent1: {
    color: COLORS.ACCENT1,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xs_accent1_lightest: {
    color: COLORS.ACCENT1_LIGHTEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xs_accent1_lighter: {
    color: COLORS.ACCENT1_LIGHTER,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xs_accent1_light: {
    color: COLORS.ACCENT1_LIGHT,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xs_accent1_dark: {
    color: COLORS.ACCENT1_DARK,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xs_accent1_darker: {
    color: COLORS.ACCENT1_DARKER,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xs_accent1_darkest: {
    color: COLORS.ACCENT1_DARKEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xs_accent2: {
    color: COLORS.ACCENT2,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xs_accent2_lightest: {
    color: COLORS.ACCENT2_LIGHTEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xs_accent2_lighter: {
    color: COLORS.ACCENT2_LIGHTER,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xs_accent2_light: {
    color: COLORS.ACCENT2_LIGHT,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xs_accent2_dark: {
    color: COLORS.ACCENT2_DARK,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xs_accent2_darker: {
    color: COLORS.ACCENT2_DARKER,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xs_accent2_darkest: {
    color: COLORS.ACCENT2_DARKEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xs_accent3: {
    color: COLORS.ACCENT3,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xs_accent3_lightest: {
    color: COLORS.ACCENT3_LIGHTEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xs_accent3_lighter: {
    color: COLORS.ACCENT3_LIGHTER,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xs_accent3_light: {
    color: COLORS.ACCENT3_LIGHT,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xs_accent3_dark: {
    color: COLORS.ACCENT3_DARK,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xs_accent3_darker: {
    color: COLORS.ACCENT3_DARKER,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xs_accent3_darkest: {
    color: COLORS.ACCENT3_DARKEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xs_accent4: {
    color: COLORS.ACCENT4,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xs_accent4_lightest: {
    color: COLORS.ACCENT4_LIGHTEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xs_accent4_lighter: {
    color: COLORS.ACCENT4_LIGHTER,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xs_accent4_light: {
    color: COLORS.ACCENT4_LIGHT,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xs_accent4_dark: {
    color: COLORS.ACCENT4_DARK,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xs_accent4_darker: {
    color: COLORS.ACCENT4_DARKER,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xs_accent4_darkest: {
    color: COLORS.ACCENT4_DARKEST,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  },
  body_strong_xs: {
    color: COLORS.BLACK,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.75rem",
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "0px",
    lineHeight: "125%"
  }
}

export const TYPOGRAPHY_VARIANT_MAPPINGS = {
  h1_xl_serif_white: "h1",
  h1_xl_serif_black: "h1",
  h1_xl_serif_gray: "h1",
  h1_xl_serif_green_2: "h1",
  h1_xl_serif_dark_gray: "h1",
  h1_xl_serif_dark_gray_2: "h1",
  h1_xl_serif_error: "h1",
  h1_xl_serif_warning: "h1",
  h1_xl_serif_success: "h1",
  h1_xl_serif_primary: "h1",
  h1_xl_serif_primary_lightest: "h1",
  h1_xl_serif_primary_lighter: "h1",
  h1_xl_serif_primary_light: "h1",
  h1_xl_serif_primary_dark: "h1",
  h1_xl_serif_primary_darker: "h1",
  h1_xl_serif_primary_darkest: "h1",
  h1_xl_serif_secondary: "h1",
  h1_xl_serif_secondary_lightest: "h1",
  h1_xl_serif_secondary_lighter: "h1",
  h1_xl_serif_secondary_light: "h1",
  h1_xl_serif_secondary_dark: "h1",
  h1_xl_serif_secondary_darker: "h1",
  h1_xl_serif_secondary_darkest: "h1",
  h1_xl_serif_accent1: "h1",
  h1_xl_serif_accent1_lightest: "h1",
  h1_xl_serif_accent1_lighter: "h1",
  h1_xl_serif_accent1_light: "h1",
  h1_xl_serif_accent1_dark: "h1",
  h1_xl_serif_accent1_darker: "h1",
  h1_xl_serif_accent1_darkest: "h1",
  h1_xl_serif_accent2: "h1",
  h1_xl_serif_accent2_lightest: "h1",
  h1_xl_serif_accent2_lighter: "h1",
  h1_xl_serif_accent2_light: "h1",
  h1_xl_serif_accent2_dark: "h1",
  h1_xl_serif_accent2_darker: "h1",
  h1_xl_serif_accent2_darkest: "h1",
  h1_xl_serif_accent3: "h1",
  h1_xl_serif_accent3_lightest: "h1",
  h1_xl_serif_accent3_lighter: "h1",
  h1_xl_serif_accent3_light: "h1",
  h1_xl_serif_accent3_dark: "h1",
  h1_xl_serif_accent3_darker: "h1",
  h1_xl_serif_accent3_darkest: "h1",
  h1_xl_serif_accent4: "h1",
  h1_xl_serif_accent4_lightest: "h1",
  h1_xl_serif_accent4_lighter: "h1",
  h1_xl_serif_accent4_light: "h1",
  h1_xl_serif_accent4_dark: "h1",
  h1_xl_serif_accent4_darker: "h1",
  h1_xl_serif_accent4_darkest: "h1",
  h1_xl_serif: "h1",
  h1_xl_white: "h1",
  h1_xl_black: "h1",
  h1_xl_gray: "h1",
  h1_xl_green_2: "h1",
  h1_xl_dark_gray: "h1",
  h1_xl_dark_gray_2: "h1",
  h1_xl_error: "h1",
  h1_xl_warning: "h1",
  h1_xl_success: "h1",
  h1_xl_primary: "h1",
  h1_xl_primary_lightest: "h1",
  h1_xl_primary_lighter: "h1",
  h1_xl_primary_light: "h1",
  h1_xl_primary_dark: "h1",
  h1_xl_primary_darker: "h1",
  h1_xl_primary_darkest: "h1",
  h1_xl_secondary: "h1",
  h1_xl_secondary_lightest: "h1",
  h1_xl_secondary_lighter: "h1",
  h1_xl_secondary_light: "h1",
  h1_xl_secondary_dark: "h1",
  h1_xl_secondary_darker: "h1",
  h1_xl_secondary_darkest: "h1",
  h1_xl_accent1: "h1",
  h1_xl_accent1_lightest: "h1",
  h1_xl_accent1_lighter: "h1",
  h1_xl_accent1_light: "h1",
  h1_xl_accent1_dark: "h1",
  h1_xl_accent1_darker: "h1",
  h1_xl_accent1_darkest: "h1",
  h1_xl_accent2: "h1",
  h1_xl_accent2_lightest: "h1",
  h1_xl_accent2_lighter: "h1",
  h1_xl_accent2_light: "h1",
  h1_xl_accent2_dark: "h1",
  h1_xl_accent2_darker: "h1",
  h1_xl_accent2_darkest: "h1",
  h1_xl_accent3: "h1",
  h1_xl_accent3_lightest: "h1",
  h1_xl_accent3_lighter: "h1",
  h1_xl_accent3_light: "h1",
  h1_xl_accent3_dark: "h1",
  h1_xl_accent3_darker: "h1",
  h1_xl_accent3_darkest: "h1",
  h1_xl_accent4: "h1",
  h1_xl_accent4_lightest: "h1",
  h1_xl_accent4_lighter: "h1",
  h1_xl_accent4_light: "h1",
  h1_xl_accent4_dark: "h1",
  h1_xl_accent4_darker: "h1",
  h1_xl_accent4_darkest: "h1",
  h1_xl: "h1",
  h1_serif_white: "h1",
  h1_serif_black: "h1",
  h1_serif_gray: "h1",
  h1_serif_green_2: "h1",
  h1_serif_dark_gray: "h1",
  h1_serif_dark_gray_2: "h1",
  h1_serif_error: "h1",
  h1_serif_warning: "h1",
  h1_serif_success: "h1",
  h1_serif_primary: "h1",
  h1_serif_primary_lightest: "h1",
  h1_serif_primary_lighter: "h1",
  h1_serif_primary_light: "h1",
  h1_serif_primary_dark: "h1",
  h1_serif_primary_darker: "h1",
  h1_serif_primary_darkest: "h1",
  h1_serif_secondary: "h1",
  h1_serif_secondary_lightest: "h1",
  h1_serif_secondary_lighter: "h1",
  h1_serif_secondary_light: "h1",
  h1_serif_secondary_dark: "h1",
  h1_serif_secondary_darker: "h1",
  h1_serif_secondary_darkest: "h1",
  h1_serif_accent1: "h1",
  h1_serif_accent1_lightest: "h1",
  h1_serif_accent1_lighter: "h1",
  h1_serif_accent1_light: "h1",
  h1_serif_accent1_dark: "h1",
  h1_serif_accent1_darker: "h1",
  h1_serif_accent1_darkest: "h1",
  h1_serif_accent2: "h1",
  h1_serif_accent2_lightest: "h1",
  h1_serif_accent2_lighter: "h1",
  h1_serif_accent2_light: "h1",
  h1_serif_accent2_dark: "h1",
  h1_serif_accent2_darker: "h1",
  h1_serif_accent2_darkest: "h1",
  h1_serif_accent3: "h1",
  h1_serif_accent3_lightest: "h1",
  h1_serif_accent3_lighter: "h1",
  h1_serif_accent3_light: "h1",
  h1_serif_accent3_dark: "h1",
  h1_serif_accent3_darker: "h1",
  h1_serif_accent3_darkest: "h1",
  h1_serif_accent4: "h1",
  h1_serif_accent4_lightest: "h1",
  h1_serif_accent4_lighter: "h1",
  h1_serif_accent4_light: "h1",
  h1_serif_accent4_dark: "h1",
  h1_serif_accent4_darker: "h1",
  h1_serif_accent4_darkest: "h1",
  h1_serif: "h1",
  h1_white: "h1",
  h1_black: "h1",
  h1_gray: "h1",
  h1_green_2: "h1",
  h1_dark_gray: "h1",
  h1_dark_gray_2: "h1",
  h1_error: "h1",
  h1_warning: "h1",
  h1_success: "h1",
  h1_primary: "h1",
  h1_primary_lightest: "h1",
  h1_primary_lighter: "h1",
  h1_primary_light: "h1",
  h1_primary_dark: "h1",
  h1_primary_darker: "h1",
  h1_primary_darkest: "h1",
  h1_secondary: "h1",
  h1_secondary_lightest: "h1",
  h1_secondary_lighter: "h1",
  h1_secondary_light: "h1",
  h1_secondary_dark: "h1",
  h1_secondary_darker: "h1",
  h1_secondary_darkest: "h1",
  h1_accent1: "h1",
  h1_accent1_lightest: "h1",
  h1_accent1_lighter: "h1",
  h1_accent1_light: "h1",
  h1_accent1_dark: "h1",
  h1_accent1_darker: "h1",
  h1_accent1_darkest: "h1",
  h1_accent2: "h1",
  h1_accent2_lightest: "h1",
  h1_accent2_lighter: "h1",
  h1_accent2_light: "h1",
  h1_accent2_dark: "h1",
  h1_accent2_darker: "h1",
  h1_accent2_darkest: "h1",
  h1_accent3: "h1",
  h1_accent3_lightest: "h1",
  h1_accent3_lighter: "h1",
  h1_accent3_light: "h1",
  h1_accent3_dark: "h1",
  h1_accent3_darker: "h1",
  h1_accent3_darkest: "h1",
  h1_accent4: "h1",
  h1_accent4_lightest: "h1",
  h1_accent4_lighter: "h1",
  h1_accent4_light: "h1",
  h1_accent4_dark: "h1",
  h1_accent4_darker: "h1",
  h1_accent4_darkest: "h1",
  h1: "h1",
  h2_serif_white: "h2",
  h2_serif_black: "h2",
  h2_serif_gray: "h2",
  h2_serif_green_2: "h2",
  h2_serif_dark_gray: "h2",
  h2_serif_dark_gray_2: "h2",
  h2_serif_error: "h2",
  h2_serif_warning: "h2",
  h2_serif_success: "h2",
  h2_serif_primary: "h2",
  h2_serif_primary_lightest: "h2",
  h2_serif_primary_lighter: "h2",
  h2_serif_primary_light: "h2",
  h2_serif_primary_dark: "h2",
  h2_serif_primary_darker: "h2",
  h2_serif_primary_darkest: "h2",
  h2_serif_secondary: "h2",
  h2_serif_secondary_lightest: "h2",
  h2_serif_secondary_lighter: "h2",
  h2_serif_secondary_light: "h2",
  h2_serif_secondary_dark: "h2",
  h2_serif_secondary_darker: "h2",
  h2_serif_secondary_darkest: "h2",
  h2_serif_accent1: "h2",
  h2_serif_accent1_lightest: "h2",
  h2_serif_accent1_lighter: "h2",
  h2_serif_accent1_light: "h2",
  h2_serif_accent1_dark: "h2",
  h2_serif_accent1_darker: "h2",
  h2_serif_accent1_darkest: "h2",
  h2_serif_accent2: "h2",
  h2_serif_accent2_lightest: "h2",
  h2_serif_accent2_lighter: "h2",
  h2_serif_accent2_light: "h2",
  h2_serif_accent2_dark: "h2",
  h2_serif_accent2_darker: "h2",
  h2_serif_accent2_darkest: "h2",
  h2_serif_accent3: "h2",
  h2_serif_accent3_lightest: "h2",
  h2_serif_accent3_lighter: "h2",
  h2_serif_accent3_light: "h2",
  h2_serif_accent3_dark: "h2",
  h2_serif_accent3_darker: "h2",
  h2_serif_accent3_darkest: "h2",
  h2_serif_accent4: "h2",
  h2_serif_accent4_lightest: "h2",
  h2_serif_accent4_lighter: "h2",
  h2_serif_accent4_light: "h2",
  h2_serif_accent4_dark: "h2",
  h2_serif_accent4_darker: "h2",
  h2_serif_accent4_darkest: "h2",
  h2_serif: "h2",
  h2_white: "h2",
  h2_black: "h2",
  h2_gray: "h2",
  h2_green_2: "h2",
  h2_dark_gray: "h2",
  h2_dark_gray_2: "h2",
  h2_error: "h2",
  h2_warning: "h2",
  h2_success: "h2",
  h2_primary: "h2",
  h2_primary_lightest: "h2",
  h2_primary_lighter: "h2",
  h2_primary_light: "h2",
  h2_primary_dark: "h2",
  h2_primary_darker: "h2",
  h2_primary_darkest: "h2",
  h2_secondary: "h2",
  h2_secondary_lightest: "h2",
  h2_secondary_lighter: "h2",
  h2_secondary_light: "h2",
  h2_secondary_dark: "h2",
  h2_secondary_darker: "h2",
  h2_secondary_darkest: "h2",
  h2_accent1: "h2",
  h2_accent1_lightest: "h2",
  h2_accent1_lighter: "h2",
  h2_accent1_light: "h2",
  h2_accent1_dark: "h2",
  h2_accent1_darker: "h2",
  h2_accent1_darkest: "h2",
  h2_accent2: "h2",
  h2_accent2_lightest: "h2",
  h2_accent2_lighter: "h2",
  h2_accent2_light: "h2",
  h2_accent2_dark: "h2",
  h2_accent2_darker: "h2",
  h2_accent2_darkest: "h2",
  h2_accent3: "h2",
  h2_accent3_lightest: "h2",
  h2_accent3_lighter: "h2",
  h2_accent3_light: "h2",
  h2_accent3_dark: "h2",
  h2_accent3_darker: "h2",
  h2_accent3_darkest: "h2",
  h2_accent4: "h2",
  h2_accent4_lightest: "h2",
  h2_accent4_lighter: "h2",
  h2_accent4_light: "h2",
  h2_accent4_dark: "h2",
  h2_accent4_darker: "h2",
  h2_accent4_darkest: "h2",
  h2: "h2",
  h3_serif_white: "h3",
  h3_serif_black: "h3",
  h3_serif_gray: "h3",
  h3_serif_green_2: "h3",
  h3_serif_dark_gray: "h3",
  h3_serif_dark_gray_2: "h3",
  h3_serif_error: "h3",
  h3_serif_warning: "h3",
  h3_serif_success: "h3",
  h3_serif_primary: "h3",
  h3_serif_primary_lightest: "h3",
  h3_serif_primary_lighter: "h3",
  h3_serif_primary_light: "h3",
  h3_serif_primary_dark: "h3",
  h3_serif_primary_darker: "h3",
  h3_serif_primary_darkest: "h3",
  h3_serif_secondary: "h3",
  h3_serif_secondary_lightest: "h3",
  h3_serif_secondary_lighter: "h3",
  h3_serif_secondary_light: "h3",
  h3_serif_secondary_dark: "h3",
  h3_serif_secondary_darker: "h3",
  h3_serif_secondary_darkest: "h3",
  h3_serif_accent1: "h3",
  h3_serif_accent1_lightest: "h3",
  h3_serif_accent1_lighter: "h3",
  h3_serif_accent1_light: "h3",
  h3_serif_accent1_dark: "h3",
  h3_serif_accent1_darker: "h3",
  h3_serif_accent1_darkest: "h3",
  h3_serif_accent2: "h3",
  h3_serif_accent2_lightest: "h3",
  h3_serif_accent2_lighter: "h3",
  h3_serif_accent2_light: "h3",
  h3_serif_accent2_dark: "h3",
  h3_serif_accent2_darker: "h3",
  h3_serif_accent2_darkest: "h3",
  h3_serif_accent3: "h3",
  h3_serif_accent3_lightest: "h3",
  h3_serif_accent3_lighter: "h3",
  h3_serif_accent3_light: "h3",
  h3_serif_accent3_dark: "h3",
  h3_serif_accent3_darker: "h3",
  h3_serif_accent3_darkest: "h3",
  h3_serif_accent4: "h3",
  h3_serif_accent4_lightest: "h3",
  h3_serif_accent4_lighter: "h3",
  h3_serif_accent4_light: "h3",
  h3_serif_accent4_dark: "h3",
  h3_serif_accent4_darker: "h3",
  h3_serif_accent4_darkest: "h3",
  h3_serif: "h3",
  h3_white: "h3",
  h3_black: "h3",
  h3_gray: "h3",
  h3_green_2: "h3",
  h3_dark_gray: "h3",
  h3_dark_gray_2: "h3",
  h3_error: "h3",
  h3_warning: "h3",
  h3_success: "h3",
  h3_primary: "h3",
  h3_primary_lightest: "h3",
  h3_primary_lighter: "h3",
  h3_primary_light: "h3",
  h3_primary_dark: "h3",
  h3_primary_darker: "h3",
  h3_primary_darkest: "h3",
  h3_secondary: "h3",
  h3_secondary_lightest: "h3",
  h3_secondary_lighter: "h3",
  h3_secondary_light: "h3",
  h3_secondary_dark: "h3",
  h3_secondary_darker: "h3",
  h3_secondary_darkest: "h3",
  h3_accent1: "h3",
  h3_accent1_lightest: "h3",
  h3_accent1_lighter: "h3",
  h3_accent1_light: "h3",
  h3_accent1_dark: "h3",
  h3_accent1_darker: "h3",
  h3_accent1_darkest: "h3",
  h3_accent2: "h3",
  h3_accent2_lightest: "h3",
  h3_accent2_lighter: "h3",
  h3_accent2_light: "h3",
  h3_accent2_dark: "h3",
  h3_accent2_darker: "h3",
  h3_accent2_darkest: "h3",
  h3_accent3: "h3",
  h3_accent3_lightest: "h3",
  h3_accent3_lighter: "h3",
  h3_accent3_light: "h3",
  h3_accent3_dark: "h3",
  h3_accent3_darker: "h3",
  h3_accent3_darkest: "h3",
  h3_accent4: "h3",
  h3_accent4_lightest: "h3",
  h3_accent4_lighter: "h3",
  h3_accent4_light: "h3",
  h3_accent4_dark: "h3",
  h3_accent4_darker: "h3",
  h3_accent4_darkest: "h3",
  h3: "h3",
  h4_serif_white: "h4",
  h4_serif_black: "h4",
  h4_serif_gray: "h4",
  h4_serif_green_2: "h4",
  h4_serif_dark_gray: "h4",
  h4_serif_dark_gray_2: "h4",
  h4_serif_error: "h4",
  h4_serif_warning: "h4",
  h4_serif_success: "h4",
  h4_serif_primary: "h4",
  h4_serif_primary_lightest: "h4",
  h4_serif_primary_lighter: "h4",
  h4_serif_primary_light: "h4",
  h4_serif_primary_dark: "h4",
  h4_serif_primary_darker: "h4",
  h4_serif_primary_darkest: "h4",
  h4_serif_secondary: "h4",
  h4_serif_secondary_lightest: "h4",
  h4_serif_secondary_lighter: "h4",
  h4_serif_secondary_light: "h4",
  h4_serif_secondary_dark: "h4",
  h4_serif_secondary_darker: "h4",
  h4_serif_secondary_darkest: "h4",
  h4_serif_accent1: "h4",
  h4_serif_accent1_lightest: "h4",
  h4_serif_accent1_lighter: "h4",
  h4_serif_accent1_light: "h4",
  h4_serif_accent1_dark: "h4",
  h4_serif_accent1_darker: "h4",
  h4_serif_accent1_darkest: "h4",
  h4_serif_accent2: "h4",
  h4_serif_accent2_lightest: "h4",
  h4_serif_accent2_lighter: "h4",
  h4_serif_accent2_light: "h4",
  h4_serif_accent2_dark: "h4",
  h4_serif_accent2_darker: "h4",
  h4_serif_accent2_darkest: "h4",
  h4_serif_accent3: "h4",
  h4_serif_accent3_lightest: "h4",
  h4_serif_accent3_lighter: "h4",
  h4_serif_accent3_light: "h4",
  h4_serif_accent3_dark: "h4",
  h4_serif_accent3_darker: "h4",
  h4_serif_accent3_darkest: "h4",
  h4_serif_accent4: "h4",
  h4_serif_accent4_lightest: "h4",
  h4_serif_accent4_lighter: "h4",
  h4_serif_accent4_light: "h4",
  h4_serif_accent4_dark: "h4",
  h4_serif_accent4_darker: "h4",
  h4_serif_accent4_darkest: "h4",
  h4_serif: "h4",
  h4_white: "h4",
  h4_black: "h4",
  h4_gray: "h4",
  h4_green_2: "h4",
  h4_dark_gray: "h4",
  h4_dark_gray_2: "h4",
  h4_error: "h4",
  h4_warning: "h4",
  h4_success: "h4",
  h4_primary: "h4",
  h4_primary_lightest: "h4",
  h4_primary_lighter: "h4",
  h4_primary_light: "h4",
  h4_primary_dark: "h4",
  h4_primary_darker: "h4",
  h4_primary_darkest: "h4",
  h4_secondary: "h4",
  h4_secondary_lightest: "h4",
  h4_secondary_lighter: "h4",
  h4_secondary_light: "h4",
  h4_secondary_dark: "h4",
  h4_secondary_darker: "h4",
  h4_secondary_darkest: "h4",
  h4_accent1: "h4",
  h4_accent1_lightest: "h4",
  h4_accent1_lighter: "h4",
  h4_accent1_light: "h4",
  h4_accent1_dark: "h4",
  h4_accent1_darker: "h4",
  h4_accent1_darkest: "h4",
  h4_accent2: "h4",
  h4_accent2_lightest: "h4",
  h4_accent2_lighter: "h4",
  h4_accent2_light: "h4",
  h4_accent2_dark: "h4",
  h4_accent2_darker: "h4",
  h4_accent2_darkest: "h4",
  h4_accent3: "h4",
  h4_accent3_lightest: "h4",
  h4_accent3_lighter: "h4",
  h4_accent3_light: "h4",
  h4_accent3_dark: "h4",
  h4_accent3_darker: "h4",
  h4_accent3_darkest: "h4",
  h4_accent4: "h4",
  h4_accent4_lightest: "h4",
  h4_accent4_lighter: "h4",
  h4_accent4_light: "h4",
  h4_accent4_dark: "h4",
  h4_accent4_darker: "h4",
  h4_accent4_darkest: "h4",
  h4: "h4",
  h5_serif_white: "h5",
  h5_serif_black: "h5",
  h5_serif_gray: "h5",
  h5_serif_green_2: "h5",
  h5_serif_dark_gray: "h5",
  h5_serif_dark_gray_2: "h5",
  h5_serif_error: "h5",
  h5_serif_warning: "h5",
  h5_serif_success: "h5",
  h5_serif_primary: "h5",
  h5_serif_primary_lightest: "h5",
  h5_serif_primary_lighter: "h5",
  h5_serif_primary_light: "h5",
  h5_serif_primary_dark: "h5",
  h5_serif_primary_darker: "h5",
  h5_serif_primary_darkest: "h5",
  h5_serif_secondary: "h5",
  h5_serif_secondary_lightest: "h5",
  h5_serif_secondary_lighter: "h5",
  h5_serif_secondary_light: "h5",
  h5_serif_secondary_dark: "h5",
  h5_serif_secondary_darker: "h5",
  h5_serif_secondary_darkest: "h5",
  h5_serif_accent1: "h5",
  h5_serif_accent1_lightest: "h5",
  h5_serif_accent1_lighter: "h5",
  h5_serif_accent1_light: "h5",
  h5_serif_accent1_dark: "h5",
  h5_serif_accent1_darker: "h5",
  h5_serif_accent1_darkest: "h5",
  h5_serif_accent2: "h5",
  h5_serif_accent2_lightest: "h5",
  h5_serif_accent2_lighter: "h5",
  h5_serif_accent2_light: "h5",
  h5_serif_accent2_dark: "h5",
  h5_serif_accent2_darker: "h5",
  h5_serif_accent2_darkest: "h5",
  h5_serif_accent3: "h5",
  h5_serif_accent3_lightest: "h5",
  h5_serif_accent3_lighter: "h5",
  h5_serif_accent3_light: "h5",
  h5_serif_accent3_dark: "h5",
  h5_serif_accent3_darker: "h5",
  h5_serif_accent3_darkest: "h5",
  h5_serif_accent4: "h5",
  h5_serif_accent4_lightest: "h5",
  h5_serif_accent4_lighter: "h5",
  h5_serif_accent4_light: "h5",
  h5_serif_accent4_dark: "h5",
  h5_serif_accent4_darker: "h5",
  h5_serif_accent4_darkest: "h5",
  h5_serif: "h5",
  h5_white: "h5",
  h5_black: "h5",
  h5_gray: "h5",
  h5_green_2: "h5",
  h5_dark_gray: "h5",
  h5_dark_gray_2: "h5",
  h5_error: "h5",
  h5_warning: "h5",
  h5_success: "h5",
  h5_primary: "h5",
  h5_primary_lightest: "h5",
  h5_primary_lighter: "h5",
  h5_primary_light: "h5",
  h5_primary_dark: "h5",
  h5_primary_darker: "h5",
  h5_primary_darkest: "h5",
  h5_secondary: "h5",
  h5_secondary_lightest: "h5",
  h5_secondary_lighter: "h5",
  h5_secondary_light: "h5",
  h5_secondary_dark: "h5",
  h5_secondary_darker: "h5",
  h5_secondary_darkest: "h5",
  h5_accent1: "h5",
  h5_accent1_lightest: "h5",
  h5_accent1_lighter: "h5",
  h5_accent1_light: "h5",
  h5_accent1_dark: "h5",
  h5_accent1_darker: "h5",
  h5_accent1_darkest: "h5",
  h5_accent2: "h5",
  h5_accent2_lightest: "h5",
  h5_accent2_lighter: "h5",
  h5_accent2_light: "h5",
  h5_accent2_dark: "h5",
  h5_accent2_darker: "h5",
  h5_accent2_darkest: "h5",
  h5_accent3: "h5",
  h5_accent3_lightest: "h5",
  h5_accent3_lighter: "h5",
  h5_accent3_light: "h5",
  h5_accent3_dark: "h5",
  h5_accent3_darker: "h5",
  h5_accent3_darkest: "h5",
  h5_accent4: "h5",
  h5_accent4_lightest: "h5",
  h5_accent4_lighter: "h5",
  h5_accent4_light: "h5",
  h5_accent4_dark: "h5",
  h5_accent4_darker: "h5",
  h5_accent4_darkest: "h5",
  h5: "h5",
  h6_serif_white: "h6",
  h6_serif_black: "h6",
  h6_serif_gray: "h6",
  h6_serif_green_2: "h6",
  h6_serif_dark_gray: "h6",
  h6_serif_dark_gray_2: "h6",
  h6_serif_error: "h6",
  h6_serif_warning: "h6",
  h6_serif_success: "h6",
  h6_serif_primary: "h6",
  h6_serif_primary_lightest: "h6",
  h6_serif_primary_lighter: "h6",
  h6_serif_primary_light: "h6",
  h6_serif_primary_dark: "h6",
  h6_serif_primary_darker: "h6",
  h6_serif_primary_darkest: "h6",
  h6_serif_secondary: "h6",
  h6_serif_secondary_lightest: "h6",
  h6_serif_secondary_lighter: "h6",
  h6_serif_secondary_light: "h6",
  h6_serif_secondary_dark: "h6",
  h6_serif_secondary_darker: "h6",
  h6_serif_secondary_darkest: "h6",
  h6_serif_accent1: "h6",
  h6_serif_accent1_lightest: "h6",
  h6_serif_accent1_lighter: "h6",
  h6_serif_accent1_light: "h6",
  h6_serif_accent1_dark: "h6",
  h6_serif_accent1_darker: "h6",
  h6_serif_accent1_darkest: "h6",
  h6_serif_accent2: "h6",
  h6_serif_accent2_lightest: "h6",
  h6_serif_accent2_lighter: "h6",
  h6_serif_accent2_light: "h6",
  h6_serif_accent2_dark: "h6",
  h6_serif_accent2_darker: "h6",
  h6_serif_accent2_darkest: "h6",
  h6_serif_accent3: "h6",
  h6_serif_accent3_lightest: "h6",
  h6_serif_accent3_lighter: "h6",
  h6_serif_accent3_light: "h6",
  h6_serif_accent3_dark: "h6",
  h6_serif_accent3_darker: "h6",
  h6_serif_accent3_darkest: "h6",
  h6_serif_accent4: "h6",
  h6_serif_accent4_lightest: "h6",
  h6_serif_accent4_lighter: "h6",
  h6_serif_accent4_light: "h6",
  h6_serif_accent4_dark: "h6",
  h6_serif_accent4_darker: "h6",
  h6_serif_accent4_darkest: "h6",
  h6_serif: "h6",
  h6_white: "h6",
  h6_black: "h6",
  h6_gray: "h6",
  h6_green_2: "h6",
  h6_dark_gray: "h6",
  h6_dark_gray_2: "h6",
  h6_error: "h6",
  h6_warning: "h6",
  h6_success: "h6",
  h6_primary: "h6",
  h6_primary_lightest: "h6",
  h6_primary_lighter: "h6",
  h6_primary_light: "h6",
  h6_primary_dark: "h6",
  h6_primary_darker: "h6",
  h6_primary_darkest: "h6",
  h6_secondary: "h6",
  h6_secondary_lightest: "h6",
  h6_secondary_lighter: "h6",
  h6_secondary_light: "h6",
  h6_secondary_dark: "h6",
  h6_secondary_darker: "h6",
  h6_secondary_darkest: "h6",
  h6_accent1: "h6",
  h6_accent1_lightest: "h6",
  h6_accent1_lighter: "h6",
  h6_accent1_light: "h6",
  h6_accent1_dark: "h6",
  h6_accent1_darker: "h6",
  h6_accent1_darkest: "h6",
  h6_accent2: "h6",
  h6_accent2_lightest: "h6",
  h6_accent2_lighter: "h6",
  h6_accent2_light: "h6",
  h6_accent2_dark: "h6",
  h6_accent2_darker: "h6",
  h6_accent2_darkest: "h6",
  h6_accent3: "h6",
  h6_accent3_lightest: "h6",
  h6_accent3_lighter: "h6",
  h6_accent3_light: "h6",
  h6_accent3_dark: "h6",
  h6_accent3_darker: "h6",
  h6_accent3_darkest: "h6",
  h6_accent4: "h6",
  h6_accent4_lightest: "h6",
  h6_accent4_lighter: "h6",
  h6_accent4_light: "h6",
  h6_accent4_dark: "h6",
  h6_accent4_darker: "h6",
  h6_accent4_darkest: "h6",
  h6: "h6",
  body_xl_serif_white: "p",
  body_xl_serif_black: "p",
  body_xl_serif_gray: "p",
  body_xl_serif_green_2: "p",
  body_xl_serif_dark_gray: "p",
  body_xl_serif_dark_gray_2: "p",
  body_xl_serif_error: "p",
  body_xl_serif_warning: "p",
  body_xl_serif_success: "p",
  body_xl_serif_primary: "p",
  body_xl_serif_primary_lightest: "p",
  body_xl_serif_primary_lighter: "p",
  body_xl_serif_primary_light: "p",
  body_xl_serif_primary_dark: "p",
  body_xl_serif_primary_darker: "p",
  body_xl_serif_primary_darkest: "p",
  body_xl_serif_secondary: "p",
  body_xl_serif_secondary_lightest: "p",
  body_xl_serif_secondary_lighter: "p",
  body_xl_serif_secondary_light: "p",
  body_xl_serif_secondary_dark: "p",
  body_xl_serif_secondary_darker: "p",
  body_xl_serif_secondary_darkest: "p",
  body_xl_serif_accent1: "p",
  body_xl_serif_accent1_lightest: "p",
  body_xl_serif_accent1_lighter: "p",
  body_xl_serif_accent1_light: "p",
  body_xl_serif_accent1_dark: "p",
  body_xl_serif_accent1_darker: "p",
  body_xl_serif_accent1_darkest: "p",
  body_xl_serif_accent2: "p",
  body_xl_serif_accent2_lightest: "p",
  body_xl_serif_accent2_lighter: "p",
  body_xl_serif_accent2_light: "p",
  body_xl_serif_accent2_dark: "p",
  body_xl_serif_accent2_darker: "p",
  body_xl_serif_accent2_darkest: "p",
  body_xl_serif_accent3: "p",
  body_xl_serif_accent3_lightest: "p",
  body_xl_serif_accent3_lighter: "p",
  body_xl_serif_accent3_light: "p",
  body_xl_serif_accent3_dark: "p",
  body_xl_serif_accent3_darker: "p",
  body_xl_serif_accent3_darkest: "p",
  body_xl_serif_accent4: "p",
  body_xl_serif_accent4_lightest: "p",
  body_xl_serif_accent4_lighter: "p",
  body_xl_serif_accent4_light: "p",
  body_xl_serif_accent4_dark: "p",
  body_xl_serif_accent4_darker: "p",
  body_xl_serif_accent4_darkest: "p",
  body_xl_serif: "p",
  body_xl_white: "p",
  body_xl_black: "p",
  body_xl_gray: "p",
  body_xl_green_2: "p",
  body_xl_dark_gray: "p",
  body_xl_dark_gray_2: "p",
  body_xl_error: "p",
  body_xl_warning: "p",
  body_xl_success: "p",
  body_xl_primary: "p",
  body_xl_primary_lightest: "p",
  body_xl_primary_lighter: "p",
  body_xl_primary_light: "p",
  body_xl_primary_dark: "p",
  body_xl_primary_darker: "p",
  body_xl_primary_darkest: "p",
  body_xl_secondary: "p",
  body_xl_secondary_lightest: "p",
  body_xl_secondary_lighter: "p",
  body_xl_secondary_light: "p",
  body_xl_secondary_dark: "p",
  body_xl_secondary_darker: "p",
  body_xl_secondary_darkest: "p",
  body_xl_accent1: "p",
  body_xl_accent1_lightest: "p",
  body_xl_accent1_lighter: "p",
  body_xl_accent1_light: "p",
  body_xl_accent1_dark: "p",
  body_xl_accent1_darker: "p",
  body_xl_accent1_darkest: "p",
  body_xl_accent2: "p",
  body_xl_accent2_lightest: "p",
  body_xl_accent2_lighter: "p",
  body_xl_accent2_light: "p",
  body_xl_accent2_dark: "p",
  body_xl_accent2_darker: "p",
  body_xl_accent2_darkest: "p",
  body_xl_accent3: "p",
  body_xl_accent3_lightest: "p",
  body_xl_accent3_lighter: "p",
  body_xl_accent3_light: "p",
  body_xl_accent3_dark: "p",
  body_xl_accent3_darker: "p",
  body_xl_accent3_darkest: "p",
  body_xl_accent4: "p",
  body_xl_accent4_lightest: "p",
  body_xl_accent4_lighter: "p",
  body_xl_accent4_light: "p",
  body_xl_accent4_dark: "p",
  body_xl_accent4_darker: "p",
  body_xl_accent4_darkest: "p",
  body_xl: "p",
  body_lg2_serif_white: "p",
  body_lg2_serif_black: "p",
  body_lg2_serif_gray: "p",
  body_lg2_serif_green_2: "p",
  body_lg2_serif_dark_gray: "p",
  body_lg2_serif_dark_gray_2: "p",
  body_lg2_serif_error: "p",
  body_lg2_serif_warning: "p",
  body_lg2_serif_success: "p",
  body_lg2_serif_primary: "p",
  body_lg2_serif_primary_lightest: "p",
  body_lg2_serif_primary_lighter: "p",
  body_lg2_serif_primary_light: "p",
  body_lg2_serif_primary_dark: "p",
  body_lg2_serif_primary_darker: "p",
  body_lg2_serif_primary_darkest: "p",
  body_lg2_serif_secondary: "p",
  body_lg2_serif_secondary_lightest: "p",
  body_lg2_serif_secondary_lighter: "p",
  body_lg2_serif_secondary_light: "p",
  body_lg2_serif_secondary_dark: "p",
  body_lg2_serif_secondary_darker: "p",
  body_lg2_serif_secondary_darkest: "p",
  body_lg2_serif_accent1: "p",
  body_lg2_serif_accent1_lightest: "p",
  body_lg2_serif_accent1_lighter: "p",
  body_lg2_serif_accent1_light: "p",
  body_lg2_serif_accent1_dark: "p",
  body_lg2_serif_accent1_darker: "p",
  body_lg2_serif_accent1_darkest: "p",
  body_lg2_serif_accent2: "p",
  body_lg2_serif_accent2_lightest: "p",
  body_lg2_serif_accent2_lighter: "p",
  body_lg2_serif_accent2_light: "p",
  body_lg2_serif_accent2_dark: "p",
  body_lg2_serif_accent2_darker: "p",
  body_lg2_serif_accent2_darkest: "p",
  body_lg2_serif_accent3: "p",
  body_lg2_serif_accent3_lightest: "p",
  body_lg2_serif_accent3_lighter: "p",
  body_lg2_serif_accent3_light: "p",
  body_lg2_serif_accent3_dark: "p",
  body_lg2_serif_accent3_darker: "p",
  body_lg2_serif_accent3_darkest: "p",
  body_lg2_serif_accent4: "p",
  body_lg2_serif_accent4_lightest: "p",
  body_lg2_serif_accent4_lighter: "p",
  body_lg2_serif_accent4_light: "p",
  body_lg2_serif_accent4_dark: "p",
  body_lg2_serif_accent4_darker: "p",
  body_lg2_serif_accent4_darkest: "p",
  body_lg2_serif: "p",
  body_lg2_white: "p",
  body_lg2_black: "p",
  body_lg2_gray: "p",
  body_lg2_green_2: "p",
  body_lg2_dark_gray: "p",
  body_lg2_dark_gray_2: "p",
  body_lg2_error: "p",
  body_lg2_warning: "p",
  body_lg2_success: "p",
  body_lg2_primary: "p",
  body_lg2_primary_lightest: "p",
  body_lg2_primary_lighter: "p",
  body_lg2_primary_light: "p",
  body_lg2_primary_dark: "p",
  body_lg2_primary_darker: "p",
  body_lg2_primary_darkest: "p",
  body_lg2_secondary: "p",
  body_lg2_secondary_lightest: "p",
  body_lg2_secondary_lighter: "p",
  body_lg2_secondary_light: "p",
  body_lg2_secondary_dark: "p",
  body_lg2_secondary_darker: "p",
  body_lg2_secondary_darkest: "p",
  body_lg2_accent1: "p",
  body_lg2_accent1_lightest: "p",
  body_lg2_accent1_lighter: "p",
  body_lg2_accent1_light: "p",
  body_lg2_accent1_dark: "p",
  body_lg2_accent1_darker: "p",
  body_lg2_accent1_darkest: "p",
  body_lg2_accent2: "p",
  body_lg2_accent2_lightest: "p",
  body_lg2_accent2_lighter: "p",
  body_lg2_accent2_light: "p",
  body_lg2_accent2_dark: "p",
  body_lg2_accent2_darker: "p",
  body_lg2_accent2_darkest: "p",
  body_lg2_accent3: "p",
  body_lg2_accent3_lightest: "p",
  body_lg2_accent3_lighter: "p",
  body_lg2_accent3_light: "p",
  body_lg2_accent3_dark: "p",
  body_lg2_accent3_darker: "p",
  body_lg2_accent3_darkest: "p",
  body_lg2_accent4: "p",
  body_lg2_accent4_lightest: "p",
  body_lg2_accent4_lighter: "p",
  body_lg2_accent4_light: "p",
  body_lg2_accent4_dark: "p",
  body_lg2_accent4_darker: "p",
  body_lg2_accent4_darkest: "p",
  body_lg2: "p",
  body_lg1_serif_white: "p",
  body_lg1_serif_black: "p",
  body_lg1_serif_gray: "p",
  body_lg1_serif_green_2: "p",
  body_lg1_serif_dark_gray: "p",
  body_lg1_serif_dark_gray_2: "p",
  body_lg1_serif_error: "p",
  body_lg1_serif_warning: "p",
  body_lg1_serif_success: "p",
  body_lg1_serif_primary: "p",
  body_lg1_serif_primary_lightest: "p",
  body_lg1_serif_primary_lighter: "p",
  body_lg1_serif_primary_light: "p",
  body_lg1_serif_primary_dark: "p",
  body_lg1_serif_primary_darker: "p",
  body_lg1_serif_primary_darkest: "p",
  body_lg1_serif_secondary: "p",
  body_lg1_serif_secondary_lightest: "p",
  body_lg1_serif_secondary_lighter: "p",
  body_lg1_serif_secondary_light: "p",
  body_lg1_serif_secondary_dark: "p",
  body_lg1_serif_secondary_darker: "p",
  body_lg1_serif_secondary_darkest: "p",
  body_lg1_serif_accent1: "p",
  body_lg1_serif_accent1_lightest: "p",
  body_lg1_serif_accent1_lighter: "p",
  body_lg1_serif_accent1_light: "p",
  body_lg1_serif_accent1_dark: "p",
  body_lg1_serif_accent1_darker: "p",
  body_lg1_serif_accent1_darkest: "p",
  body_lg1_serif_accent2: "p",
  body_lg1_serif_accent2_lightest: "p",
  body_lg1_serif_accent2_lighter: "p",
  body_lg1_serif_accent2_light: "p",
  body_lg1_serif_accent2_dark: "p",
  body_lg1_serif_accent2_darker: "p",
  body_lg1_serif_accent2_darkest: "p",
  body_lg1_serif_accent3: "p",
  body_lg1_serif_accent3_lightest: "p",
  body_lg1_serif_accent3_lighter: "p",
  body_lg1_serif_accent3_light: "p",
  body_lg1_serif_accent3_dark: "p",
  body_lg1_serif_accent3_darker: "p",
  body_lg1_serif_accent3_darkest: "p",
  body_lg1_serif_accent4: "p",
  body_lg1_serif_accent4_lightest: "p",
  body_lg1_serif_accent4_lighter: "p",
  body_lg1_serif_accent4_light: "p",
  body_lg1_serif_accent4_dark: "p",
  body_lg1_serif_accent4_darker: "p",
  body_lg1_serif_accent4_darkest: "p",
  body_lg1_serif: "p",
  body_lg1_white: "p",
  body_lg1_black: "p",
  body_lg1_gray: "p",
  body_lg1_green_2: "p",
  body_lg1_dark_gray: "p",
  body_lg1_dark_gray_2: "p",
  body_lg1_error: "p",
  body_lg1_warning: "p",
  body_lg1_success: "p",
  body_lg1_primary: "p",
  body_lg1_primary_lightest: "p",
  body_lg1_primary_lighter: "p",
  body_lg1_primary_light: "p",
  body_lg1_primary_dark: "p",
  body_lg1_primary_darker: "p",
  body_lg1_primary_darkest: "p",
  body_lg1_secondary: "p",
  body_lg1_secondary_lightest: "p",
  body_lg1_secondary_lighter: "p",
  body_lg1_secondary_light: "p",
  body_lg1_secondary_dark: "p",
  body_lg1_secondary_darker: "p",
  body_lg1_secondary_darkest: "p",
  body_lg1_accent1: "p",
  body_lg1_accent1_lightest: "p",
  body_lg1_accent1_lighter: "p",
  body_lg1_accent1_light: "p",
  body_lg1_accent1_dark: "p",
  body_lg1_accent1_darker: "p",
  body_lg1_accent1_darkest: "p",
  body_lg1_accent2: "p",
  body_lg1_accent2_lightest: "p",
  body_lg1_accent2_lighter: "p",
  body_lg1_accent2_light: "p",
  body_lg1_accent2_dark: "p",
  body_lg1_accent2_darker: "p",
  body_lg1_accent2_darkest: "p",
  body_lg1_accent3: "p",
  body_lg1_accent3_lightest: "p",
  body_lg1_accent3_lighter: "p",
  body_lg1_accent3_light: "p",
  body_lg1_accent3_dark: "p",
  body_lg1_accent3_darker: "p",
  body_lg1_accent3_darkest: "p",
  body_lg1_accent4: "p",
  body_lg1_accent4_lightest: "p",
  body_lg1_accent4_lighter: "p",
  body_lg1_accent4_light: "p",
  body_lg1_accent4_dark: "p",
  body_lg1_accent4_darker: "p",
  body_lg1_accent4_darkest: "p",
  body_lg1: "p",
  body_lg_serif_white: "p",
  body_lg_serif_black: "p",
  body_lg_serif_gray: "p",
  body_lg_serif_green_2: "p",
  body_lg_serif_dark_gray: "p",
  body_lg_serif_dark_gray_2: "p",
  body_lg_serif_error: "p",
  body_lg_serif_warning: "p",
  body_lg_serif_success: "p",
  body_lg_serif_primary: "p",
  body_lg_serif_primary_lightest: "p",
  body_lg_serif_primary_lighter: "p",
  body_lg_serif_primary_light: "p",
  body_lg_serif_primary_dark: "p",
  body_lg_serif_primary_darker: "p",
  body_lg_serif_primary_darkest: "p",
  body_lg_serif_secondary: "p",
  body_lg_serif_secondary_lightest: "p",
  body_lg_serif_secondary_lighter: "p",
  body_lg_serif_secondary_light: "p",
  body_lg_serif_secondary_dark: "p",
  body_lg_serif_secondary_darker: "p",
  body_lg_serif_secondary_darkest: "p",
  body_lg_serif_accent1: "p",
  body_lg_serif_accent1_lightest: "p",
  body_lg_serif_accent1_lighter: "p",
  body_lg_serif_accent1_light: "p",
  body_lg_serif_accent1_dark: "p",
  body_lg_serif_accent1_darker: "p",
  body_lg_serif_accent1_darkest: "p",
  body_lg_serif_accent2: "p",
  body_lg_serif_accent2_lightest: "p",
  body_lg_serif_accent2_lighter: "p",
  body_lg_serif_accent2_light: "p",
  body_lg_serif_accent2_dark: "p",
  body_lg_serif_accent2_darker: "p",
  body_lg_serif_accent2_darkest: "p",
  body_lg_serif_accent3: "p",
  body_lg_serif_accent3_lightest: "p",
  body_lg_serif_accent3_lighter: "p",
  body_lg_serif_accent3_light: "p",
  body_lg_serif_accent3_dark: "p",
  body_lg_serif_accent3_darker: "p",
  body_lg_serif_accent3_darkest: "p",
  body_lg_serif_accent4: "p",
  body_lg_serif_accent4_lightest: "p",
  body_lg_serif_accent4_lighter: "p",
  body_lg_serif_accent4_light: "p",
  body_lg_serif_accent4_dark: "p",
  body_lg_serif_accent4_darker: "p",
  body_lg_serif_accent4_darkest: "p",
  body_lg_serif: "p",
  body_lg_white: "p",
  body_lg_black: "p",
  body_lg_gray: "p",
  body_lg_green_2: "p",
  body_lg_dark_gray: "p",
  body_lg_dark_gray_2: "p",
  body_lg_error: "p",
  body_lg_warning: "p",
  body_lg_success: "p",
  body_lg_primary: "p",
  body_lg_primary_lightest: "p",
  body_lg_primary_lighter: "p",
  body_lg_primary_light: "p",
  body_lg_primary_dark: "p",
  body_lg_primary_darker: "p",
  body_lg_primary_darkest: "p",
  body_lg_secondary: "p",
  body_lg_secondary_lightest: "p",
  body_lg_secondary_lighter: "p",
  body_lg_secondary_light: "p",
  body_lg_secondary_dark: "p",
  body_lg_secondary_darker: "p",
  body_lg_secondary_darkest: "p",
  body_lg_accent1: "p",
  body_lg_accent1_lightest: "p",
  body_lg_accent1_lighter: "p",
  body_lg_accent1_light: "p",
  body_lg_accent1_dark: "p",
  body_lg_accent1_darker: "p",
  body_lg_accent1_darkest: "p",
  body_lg_accent2: "p",
  body_lg_accent2_lightest: "p",
  body_lg_accent2_lighter: "p",
  body_lg_accent2_light: "p",
  body_lg_accent2_dark: "p",
  body_lg_accent2_darker: "p",
  body_lg_accent2_darkest: "p",
  body_lg_accent3: "p",
  body_lg_accent3_lightest: "p",
  body_lg_accent3_lighter: "p",
  body_lg_accent3_light: "p",
  body_lg_accent3_dark: "p",
  body_lg_accent3_darker: "p",
  body_lg_accent3_darkest: "p",
  body_lg_accent4: "p",
  body_lg_accent4_lightest: "p",
  body_lg_accent4_lighter: "p",
  body_lg_accent4_light: "p",
  body_lg_accent4_dark: "p",
  body_lg_accent4_darker: "p",
  body_lg_accent4_darkest: "p",
  body_lg: "p",
  body_serif_white: "p",
  body_serif_black: "p",
  body_serif_gray: "p",
  body_serif_green_2: "p",
  body_serif_dark_gray: "p",
  body_serif_dark_gray_2: "p",
  body_serif_error: "p",
  body_serif_warning: "p",
  body_serif_success: "p",
  body_serif_primary: "p",
  body_serif_primary_lightest: "p",
  body_serif_primary_lighter: "p",
  body_serif_primary_light: "p",
  body_serif_primary_dark: "p",
  body_serif_primary_darker: "p",
  body_serif_primary_darkest: "p",
  body_serif_secondary: "p",
  body_serif_secondary_lightest: "p",
  body_serif_secondary_lighter: "p",
  body_serif_secondary_light: "p",
  body_serif_secondary_dark: "p",
  body_serif_secondary_darker: "p",
  body_serif_secondary_darkest: "p",
  body_serif_accent1: "p",
  body_serif_accent1_lightest: "p",
  body_serif_accent1_lighter: "p",
  body_serif_accent1_light: "p",
  body_serif_accent1_dark: "p",
  body_serif_accent1_darker: "p",
  body_serif_accent1_darkest: "p",
  body_serif_accent2: "p",
  body_serif_accent2_lightest: "p",
  body_serif_accent2_lighter: "p",
  body_serif_accent2_light: "p",
  body_serif_accent2_dark: "p",
  body_serif_accent2_darker: "p",
  body_serif_accent2_darkest: "p",
  body_serif_accent3: "p",
  body_serif_accent3_lightest: "p",
  body_serif_accent3_lighter: "p",
  body_serif_accent3_light: "p",
  body_serif_accent3_dark: "p",
  body_serif_accent3_darker: "p",
  body_serif_accent3_darkest: "p",
  body_serif_accent4: "p",
  body_serif_accent4_lightest: "p",
  body_serif_accent4_lighter: "p",
  body_serif_accent4_light: "p",
  body_serif_accent4_dark: "p",
  body_serif_accent4_darker: "p",
  body_serif_accent4_darkest: "p",
  body_serif: "p",
  body_white: "p",
  body_black: "p",
  body_gray: "p",
  body_green_2: "p",
  body_dark_gray: "p",
  body_dark_gray_2: "p",
  body_error: "p",
  body_warning: "p",
  body_success: "p",
  body_primary: "p",
  body_primary_lightest: "p",
  body_primary_lighter: "p",
  body_primary_light: "p",
  body_primary_dark: "p",
  body_primary_darker: "p",
  body_primary_darkest: "p",
  body_secondary: "p",
  body_secondary_lightest: "p",
  body_secondary_lighter: "p",
  body_secondary_light: "p",
  body_secondary_dark: "p",
  body_secondary_darker: "p",
  body_secondary_darkest: "p",
  body_accent1: "p",
  body_accent1_lightest: "p",
  body_accent1_lighter: "p",
  body_accent1_light: "p",
  body_accent1_dark: "p",
  body_accent1_darker: "p",
  body_accent1_darkest: "p",
  body_accent2: "p",
  body_accent2_lightest: "p",
  body_accent2_lighter: "p",
  body_accent2_light: "p",
  body_accent2_dark: "p",
  body_accent2_darker: "p",
  body_accent2_darkest: "p",
  body_accent3: "p",
  body_accent3_lightest: "p",
  body_accent3_lighter: "p",
  body_accent3_light: "p",
  body_accent3_dark: "p",
  body_accent3_darker: "p",
  body_accent3_darkest: "p",
  body_accent4: "p",
  body_accent4_lightest: "p",
  body_accent4_lighter: "p",
  body_accent4_light: "p",
  body_accent4_dark: "p",
  body_accent4_darker: "p",
  body_accent4_darkest: "p",
  body: "p",
  body_sm_serif_white: "p",
  body_sm_serif_black: "p",
  body_sm_serif_gray: "p",
  body_sm_serif_green_2: "p",
  body_sm_serif_dark_gray: "p",
  body_sm_serif_dark_gray_2: "p",
  body_sm_serif_error: "p",
  body_sm_serif_warning: "p",
  body_sm_serif_success: "p",
  body_sm_serif_primary: "p",
  body_sm_serif_primary_lightest: "p",
  body_sm_serif_primary_lighter: "p",
  body_sm_serif_primary_light: "p",
  body_sm_serif_primary_dark: "p",
  body_sm_serif_primary_darker: "p",
  body_sm_serif_primary_darkest: "p",
  body_sm_serif_secondary: "p",
  body_sm_serif_secondary_lightest: "p",
  body_sm_serif_secondary_lighter: "p",
  body_sm_serif_secondary_light: "p",
  body_sm_serif_secondary_dark: "p",
  body_sm_serif_secondary_darker: "p",
  body_sm_serif_secondary_darkest: "p",
  body_sm_serif_accent1: "p",
  body_sm_serif_accent1_lightest: "p",
  body_sm_serif_accent1_lighter: "p",
  body_sm_serif_accent1_light: "p",
  body_sm_serif_accent1_dark: "p",
  body_sm_serif_accent1_darker: "p",
  body_sm_serif_accent1_darkest: "p",
  body_sm_serif_accent2: "p",
  body_sm_serif_accent2_lightest: "p",
  body_sm_serif_accent2_lighter: "p",
  body_sm_serif_accent2_light: "p",
  body_sm_serif_accent2_dark: "p",
  body_sm_serif_accent2_darker: "p",
  body_sm_serif_accent2_darkest: "p",
  body_sm_serif_accent3: "p",
  body_sm_serif_accent3_lightest: "p",
  body_sm_serif_accent3_lighter: "p",
  body_sm_serif_accent3_light: "p",
  body_sm_serif_accent3_dark: "p",
  body_sm_serif_accent3_darker: "p",
  body_sm_serif_accent3_darkest: "p",
  body_sm_serif_accent4: "p",
  body_sm_serif_accent4_lightest: "p",
  body_sm_serif_accent4_lighter: "p",
  body_sm_serif_accent4_light: "p",
  body_sm_serif_accent4_dark: "p",
  body_sm_serif_accent4_darker: "p",
  body_sm_serif_accent4_darkest: "p",
  body_sm_serif: "p",
  body_sm_white: "p",
  body_sm_black: "p",
  body_sm_gray: "p",
  body_sm_green_2: "p",
  body_sm_dark_gray: "p",
  body_sm_dark_gray_2: "p",
  body_sm_error: "p",
  body_sm_warning: "p",
  body_sm_success: "p",
  body_sm_primary: "p",
  body_sm_primary_lightest: "p",
  body_sm_primary_lighter: "p",
  body_sm_primary_light: "p",
  body_sm_primary_dark: "p",
  body_sm_primary_darker: "p",
  body_sm_primary_darkest: "p",
  body_sm_secondary: "p",
  body_sm_secondary_lightest: "p",
  body_sm_secondary_lighter: "p",
  body_sm_secondary_light: "p",
  body_sm_secondary_dark: "p",
  body_sm_secondary_darker: "p",
  body_sm_secondary_darkest: "p",
  body_sm_accent1: "p",
  body_sm_accent1_lightest: "p",
  body_sm_accent1_lighter: "p",
  body_sm_accent1_light: "p",
  body_sm_accent1_dark: "p",
  body_sm_accent1_darker: "p",
  body_sm_accent1_darkest: "p",
  body_sm_accent2: "p",
  body_sm_accent2_lightest: "p",
  body_sm_accent2_lighter: "p",
  body_sm_accent2_light: "p",
  body_sm_accent2_dark: "p",
  body_sm_accent2_darker: "p",
  body_sm_accent2_darkest: "p",
  body_sm_accent3: "p",
  body_sm_accent3_lightest: "p",
  body_sm_accent3_lighter: "p",
  body_sm_accent3_light: "p",
  body_sm_accent3_dark: "p",
  body_sm_accent3_darker: "p",
  body_sm_accent3_darkest: "p",
  body_sm_accent4: "p",
  body_sm_accent4_lightest: "p",
  body_sm_accent4_lighter: "p",
  body_sm_accent4_light: "p",
  body_sm_accent4_dark: "p",
  body_sm_accent4_darker: "p",
  body_sm_accent4_darkest: "p",
  body_sm: "p",
  body_xs_serif_white: "p",
  body_xs_serif_black: "p",
  body_xs_serif_gray: "p",
  body_xs_serif_green_2: "p",
  body_xs_serif_dark_gray: "p",
  body_xs_serif_dark_gray_2: "p",
  body_xs_serif_error: "p",
  body_xs_serif_warning: "p",
  body_xs_serif_success: "p",
  body_xs_serif_primary: "p",
  body_xs_serif_primary_lightest: "p",
  body_xs_serif_primary_lighter: "p",
  body_xs_serif_primary_light: "p",
  body_xs_serif_primary_dark: "p",
  body_xs_serif_primary_darker: "p",
  body_xs_serif_primary_darkest: "p",
  body_xs_serif_secondary: "p",
  body_xs_serif_secondary_lightest: "p",
  body_xs_serif_secondary_lighter: "p",
  body_xs_serif_secondary_light: "p",
  body_xs_serif_secondary_dark: "p",
  body_xs_serif_secondary_darker: "p",
  body_xs_serif_secondary_darkest: "p",
  body_xs_serif_accent1: "p",
  body_xs_serif_accent1_lightest: "p",
  body_xs_serif_accent1_lighter: "p",
  body_xs_serif_accent1_light: "p",
  body_xs_serif_accent1_dark: "p",
  body_xs_serif_accent1_darker: "p",
  body_xs_serif_accent1_darkest: "p",
  body_xs_serif_accent2: "p",
  body_xs_serif_accent2_lightest: "p",
  body_xs_serif_accent2_lighter: "p",
  body_xs_serif_accent2_light: "p",
  body_xs_serif_accent2_dark: "p",
  body_xs_serif_accent2_darker: "p",
  body_xs_serif_accent2_darkest: "p",
  body_xs_serif_accent3: "p",
  body_xs_serif_accent3_lightest: "p",
  body_xs_serif_accent3_lighter: "p",
  body_xs_serif_accent3_light: "p",
  body_xs_serif_accent3_dark: "p",
  body_xs_serif_accent3_darker: "p",
  body_xs_serif_accent3_darkest: "p",
  body_xs_serif_accent4: "p",
  body_xs_serif_accent4_lightest: "p",
  body_xs_serif_accent4_lighter: "p",
  body_xs_serif_accent4_light: "p",
  body_xs_serif_accent4_dark: "p",
  body_xs_serif_accent4_darker: "p",
  body_xs_serif_accent4_darkest: "p",
  body_xs_serif: "p",
  body_xs_white: "p",
  body_xs_black: "p",
  body_xs_gray: "p",
  body_xs_green_2: "p",
  body_xs_dark_gray: "p",
  body_xs_dark_gray_2: "p",
  body_xs_error: "p",
  body_xs_warning: "p",
  body_xs_success: "p",
  body_xs_primary: "p",
  body_xs_primary_lightest: "p",
  body_xs_primary_lighter: "p",
  body_xs_primary_light: "p",
  body_xs_primary_dark: "p",
  body_xs_primary_darker: "p",
  body_xs_primary_darkest: "p",
  body_xs_secondary: "p",
  body_xs_secondary_lightest: "p",
  body_xs_secondary_lighter: "p",
  body_xs_secondary_light: "p",
  body_xs_secondary_dark: "p",
  body_xs_secondary_darker: "p",
  body_xs_secondary_darkest: "p",
  body_xs_accent1: "p",
  body_xs_accent1_lightest: "p",
  body_xs_accent1_lighter: "p",
  body_xs_accent1_light: "p",
  body_xs_accent1_dark: "p",
  body_xs_accent1_darker: "p",
  body_xs_accent1_darkest: "p",
  body_xs_accent2: "p",
  body_xs_accent2_lightest: "p",
  body_xs_accent2_lighter: "p",
  body_xs_accent2_light: "p",
  body_xs_accent2_dark: "p",
  body_xs_accent2_darker: "p",
  body_xs_accent2_darkest: "p",
  body_xs_accent3: "p",
  body_xs_accent3_lightest: "p",
  body_xs_accent3_lighter: "p",
  body_xs_accent3_light: "p",
  body_xs_accent3_dark: "p",
  body_xs_accent3_darker: "p",
  body_xs_accent3_darkest: "p",
  body_xs_accent4: "p",
  body_xs_accent4_lightest: "p",
  body_xs_accent4_lighter: "p",
  body_xs_accent4_light: "p",
  body_xs_accent4_dark: "p",
  body_xs_accent4_darker: "p",
  body_xs_accent4_darkest: "p",
  body_xs: "p",
  body_strong_xl_serif_white: "p",
  body_strong_xl_serif_black: "p",
  body_strong_xl_serif_gray: "p",
  body_strong_xl_serif_green_2: "p",
  body_strong_xl_serif_dark_gray: "p",
  body_strong_xl_serif_dark_gray_2: "p",
  body_strong_xl_serif_error: "p",
  body_strong_xl_serif_warning: "p",
  body_strong_xl_serif_success: "p",
  body_strong_xl_serif_primary: "p",
  body_strong_xl_serif_primary_lightest: "p",
  body_strong_xl_serif_primary_lighter: "p",
  body_strong_xl_serif_primary_light: "p",
  body_strong_xl_serif_primary_dark: "p",
  body_strong_xl_serif_primary_darker: "p",
  body_strong_xl_serif_primary_darkest: "p",
  body_strong_xl_serif_secondary: "p",
  body_strong_xl_serif_secondary_lightest: "p",
  body_strong_xl_serif_secondary_lighter: "p",
  body_strong_xl_serif_secondary_light: "p",
  body_strong_xl_serif_secondary_dark: "p",
  body_strong_xl_serif_secondary_darker: "p",
  body_strong_xl_serif_secondary_darkest: "p",
  body_strong_xl_serif_accent1: "p",
  body_strong_xl_serif_accent1_lightest: "p",
  body_strong_xl_serif_accent1_lighter: "p",
  body_strong_xl_serif_accent1_light: "p",
  body_strong_xl_serif_accent1_dark: "p",
  body_strong_xl_serif_accent1_darker: "p",
  body_strong_xl_serif_accent1_darkest: "p",
  body_strong_xl_serif_accent2: "p",
  body_strong_xl_serif_accent2_lightest: "p",
  body_strong_xl_serif_accent2_lighter: "p",
  body_strong_xl_serif_accent2_light: "p",
  body_strong_xl_serif_accent2_dark: "p",
  body_strong_xl_serif_accent2_darker: "p",
  body_strong_xl_serif_accent2_darkest: "p",
  body_strong_xl_serif_accent3: "p",
  body_strong_xl_serif_accent3_lightest: "p",
  body_strong_xl_serif_accent3_lighter: "p",
  body_strong_xl_serif_accent3_light: "p",
  body_strong_xl_serif_accent3_dark: "p",
  body_strong_xl_serif_accent3_darker: "p",
  body_strong_xl_serif_accent3_darkest: "p",
  body_strong_xl_serif_accent4: "p",
  body_strong_xl_serif_accent4_lightest: "p",
  body_strong_xl_serif_accent4_lighter: "p",
  body_strong_xl_serif_accent4_light: "p",
  body_strong_xl_serif_accent4_dark: "p",
  body_strong_xl_serif_accent4_darker: "p",
  body_strong_xl_serif_accent4_darkest: "p",
  body_strong_xl_serif: "p",
  body_strong_xl_white: "p",
  body_strong_xl_black: "p",
  body_strong_xl_gray: "p",
  body_strong_xl_green_2: "p",
  body_strong_xl_dark_gray: "p",
  body_strong_xl_dark_gray_2: "p",
  body_strong_xl_error: "p",
  body_strong_xl_warning: "p",
  body_strong_xl_success: "p",
  body_strong_xl_primary: "p",
  body_strong_xl_primary_lightest: "p",
  body_strong_xl_primary_lighter: "p",
  body_strong_xl_primary_light: "p",
  body_strong_xl_primary_dark: "p",
  body_strong_xl_primary_darker: "p",
  body_strong_xl_primary_darkest: "p",
  body_strong_xl_secondary: "p",
  body_strong_xl_secondary_lightest: "p",
  body_strong_xl_secondary_lighter: "p",
  body_strong_xl_secondary_light: "p",
  body_strong_xl_secondary_dark: "p",
  body_strong_xl_secondary_darker: "p",
  body_strong_xl_secondary_darkest: "p",
  body_strong_xl_accent1: "p",
  body_strong_xl_accent1_lightest: "p",
  body_strong_xl_accent1_lighter: "p",
  body_strong_xl_accent1_light: "p",
  body_strong_xl_accent1_dark: "p",
  body_strong_xl_accent1_darker: "p",
  body_strong_xl_accent1_darkest: "p",
  body_strong_xl_accent2: "p",
  body_strong_xl_accent2_lightest: "p",
  body_strong_xl_accent2_lighter: "p",
  body_strong_xl_accent2_light: "p",
  body_strong_xl_accent2_dark: "p",
  body_strong_xl_accent2_darker: "p",
  body_strong_xl_accent2_darkest: "p",
  body_strong_xl_accent3: "p",
  body_strong_xl_accent3_lightest: "p",
  body_strong_xl_accent3_lighter: "p",
  body_strong_xl_accent3_light: "p",
  body_strong_xl_accent3_dark: "p",
  body_strong_xl_accent3_darker: "p",
  body_strong_xl_accent3_darkest: "p",
  body_strong_xl_accent4: "p",
  body_strong_xl_accent4_lightest: "p",
  body_strong_xl_accent4_lighter: "p",
  body_strong_xl_accent4_light: "p",
  body_strong_xl_accent4_dark: "p",
  body_strong_xl_accent4_darker: "p",
  body_strong_xl_accent4_darkest: "p",
  body_strong_xl: "p",
  body_strong_lg2_serif_white: "p",
  body_strong_lg2_serif_black: "p",
  body_strong_lg2_serif_gray: "p",
  body_strong_lg2_serif_green_2: "p",
  body_strong_lg2_serif_dark_gray: "p",
  body_strong_lg2_serif_dark_gray_2: "p",
  body_strong_lg2_serif_error: "p",
  body_strong_lg2_serif_warning: "p",
  body_strong_lg2_serif_success: "p",
  body_strong_lg2_serif_primary: "p",
  body_strong_lg2_serif_primary_lightest: "p",
  body_strong_lg2_serif_primary_lighter: "p",
  body_strong_lg2_serif_primary_light: "p",
  body_strong_lg2_serif_primary_dark: "p",
  body_strong_lg2_serif_primary_darker: "p",
  body_strong_lg2_serif_primary_darkest: "p",
  body_strong_lg2_serif_secondary: "p",
  body_strong_lg2_serif_secondary_lightest: "p",
  body_strong_lg2_serif_secondary_lighter: "p",
  body_strong_lg2_serif_secondary_light: "p",
  body_strong_lg2_serif_secondary_dark: "p",
  body_strong_lg2_serif_secondary_darker: "p",
  body_strong_lg2_serif_secondary_darkest: "p",
  body_strong_lg2_serif_accent1: "p",
  body_strong_lg2_serif_accent1_lightest: "p",
  body_strong_lg2_serif_accent1_lighter: "p",
  body_strong_lg2_serif_accent1_light: "p",
  body_strong_lg2_serif_accent1_dark: "p",
  body_strong_lg2_serif_accent1_darker: "p",
  body_strong_lg2_serif_accent1_darkest: "p",
  body_strong_lg2_serif_accent2: "p",
  body_strong_lg2_serif_accent2_lightest: "p",
  body_strong_lg2_serif_accent2_lighter: "p",
  body_strong_lg2_serif_accent2_light: "p",
  body_strong_lg2_serif_accent2_dark: "p",
  body_strong_lg2_serif_accent2_darker: "p",
  body_strong_lg2_serif_accent2_darkest: "p",
  body_strong_lg2_serif_accent3: "p",
  body_strong_lg2_serif_accent3_lightest: "p",
  body_strong_lg2_serif_accent3_lighter: "p",
  body_strong_lg2_serif_accent3_light: "p",
  body_strong_lg2_serif_accent3_dark: "p",
  body_strong_lg2_serif_accent3_darker: "p",
  body_strong_lg2_serif_accent3_darkest: "p",
  body_strong_lg2_serif_accent4: "p",
  body_strong_lg2_serif_accent4_lightest: "p",
  body_strong_lg2_serif_accent4_lighter: "p",
  body_strong_lg2_serif_accent4_light: "p",
  body_strong_lg2_serif_accent4_dark: "p",
  body_strong_lg2_serif_accent4_darker: "p",
  body_strong_lg2_serif_accent4_darkest: "p",
  body_strong_lg2_serif: "p",
  body_strong_lg2_white: "p",
  body_strong_lg2_black: "p",
  body_strong_lg2_gray: "p",
  body_strong_lg2_green_2: "p",
  body_strong_lg2_dark_gray: "p",
  body_strong_lg2_dark_gray_2: "p",
  body_strong_lg2_error: "p",
  body_strong_lg2_warning: "p",
  body_strong_lg2_success: "p",
  body_strong_lg2_primary: "p",
  body_strong_lg2_primary_lightest: "p",
  body_strong_lg2_primary_lighter: "p",
  body_strong_lg2_primary_light: "p",
  body_strong_lg2_primary_dark: "p",
  body_strong_lg2_primary_darker: "p",
  body_strong_lg2_primary_darkest: "p",
  body_strong_lg2_secondary: "p",
  body_strong_lg2_secondary_lightest: "p",
  body_strong_lg2_secondary_lighter: "p",
  body_strong_lg2_secondary_light: "p",
  body_strong_lg2_secondary_dark: "p",
  body_strong_lg2_secondary_darker: "p",
  body_strong_lg2_secondary_darkest: "p",
  body_strong_lg2_accent1: "p",
  body_strong_lg2_accent1_lightest: "p",
  body_strong_lg2_accent1_lighter: "p",
  body_strong_lg2_accent1_light: "p",
  body_strong_lg2_accent1_dark: "p",
  body_strong_lg2_accent1_darker: "p",
  body_strong_lg2_accent1_darkest: "p",
  body_strong_lg2_accent2: "p",
  body_strong_lg2_accent2_lightest: "p",
  body_strong_lg2_accent2_lighter: "p",
  body_strong_lg2_accent2_light: "p",
  body_strong_lg2_accent2_dark: "p",
  body_strong_lg2_accent2_darker: "p",
  body_strong_lg2_accent2_darkest: "p",
  body_strong_lg2_accent3: "p",
  body_strong_lg2_accent3_lightest: "p",
  body_strong_lg2_accent3_lighter: "p",
  body_strong_lg2_accent3_light: "p",
  body_strong_lg2_accent3_dark: "p",
  body_strong_lg2_accent3_darker: "p",
  body_strong_lg2_accent3_darkest: "p",
  body_strong_lg2_accent4: "p",
  body_strong_lg2_accent4_lightest: "p",
  body_strong_lg2_accent4_lighter: "p",
  body_strong_lg2_accent4_light: "p",
  body_strong_lg2_accent4_dark: "p",
  body_strong_lg2_accent4_darker: "p",
  body_strong_lg2_accent4_darkest: "p",
  body_strong_lg2: "p",
  body_strong_lg1_serif_white: "p",
  body_strong_lg1_serif_black: "p",
  body_strong_lg1_serif_gray: "p",
  body_strong_lg1_serif_green_2: "p",
  body_strong_lg1_serif_dark_gray: "p",
  body_strong_lg1_serif_dark_gray_2: "p",
  body_strong_lg1_serif_error: "p",
  body_strong_lg1_serif_warning: "p",
  body_strong_lg1_serif_success: "p",
  body_strong_lg1_serif_primary: "p",
  body_strong_lg1_serif_primary_lightest: "p",
  body_strong_lg1_serif_primary_lighter: "p",
  body_strong_lg1_serif_primary_light: "p",
  body_strong_lg1_serif_primary_dark: "p",
  body_strong_lg1_serif_primary_darker: "p",
  body_strong_lg1_serif_primary_darkest: "p",
  body_strong_lg1_serif_secondary: "p",
  body_strong_lg1_serif_secondary_lightest: "p",
  body_strong_lg1_serif_secondary_lighter: "p",
  body_strong_lg1_serif_secondary_light: "p",
  body_strong_lg1_serif_secondary_dark: "p",
  body_strong_lg1_serif_secondary_darker: "p",
  body_strong_lg1_serif_secondary_darkest: "p",
  body_strong_lg1_serif_accent1: "p",
  body_strong_lg1_serif_accent1_lightest: "p",
  body_strong_lg1_serif_accent1_lighter: "p",
  body_strong_lg1_serif_accent1_light: "p",
  body_strong_lg1_serif_accent1_dark: "p",
  body_strong_lg1_serif_accent1_darker: "p",
  body_strong_lg1_serif_accent1_darkest: "p",
  body_strong_lg1_serif_accent2: "p",
  body_strong_lg1_serif_accent2_lightest: "p",
  body_strong_lg1_serif_accent2_lighter: "p",
  body_strong_lg1_serif_accent2_light: "p",
  body_strong_lg1_serif_accent2_dark: "p",
  body_strong_lg1_serif_accent2_darker: "p",
  body_strong_lg1_serif_accent2_darkest: "p",
  body_strong_lg1_serif_accent3: "p",
  body_strong_lg1_serif_accent3_lightest: "p",
  body_strong_lg1_serif_accent3_lighter: "p",
  body_strong_lg1_serif_accent3_light: "p",
  body_strong_lg1_serif_accent3_dark: "p",
  body_strong_lg1_serif_accent3_darker: "p",
  body_strong_lg1_serif_accent3_darkest: "p",
  body_strong_lg1_serif_accent4: "p",
  body_strong_lg1_serif_accent4_lightest: "p",
  body_strong_lg1_serif_accent4_lighter: "p",
  body_strong_lg1_serif_accent4_light: "p",
  body_strong_lg1_serif_accent4_dark: "p",
  body_strong_lg1_serif_accent4_darker: "p",
  body_strong_lg1_serif_accent4_darkest: "p",
  body_strong_lg1_serif: "p",
  body_strong_lg1_white: "p",
  body_strong_lg1_black: "p",
  body_strong_lg1_gray: "p",
  body_strong_lg1_green_2: "p",
  body_strong_lg1_dark_gray: "p",
  body_strong_lg1_dark_gray_2: "p",
  body_strong_lg1_error: "p",
  body_strong_lg1_warning: "p",
  body_strong_lg1_success: "p",
  body_strong_lg1_primary: "p",
  body_strong_lg1_primary_lightest: "p",
  body_strong_lg1_primary_lighter: "p",
  body_strong_lg1_primary_light: "p",
  body_strong_lg1_primary_dark: "p",
  body_strong_lg1_primary_darker: "p",
  body_strong_lg1_primary_darkest: "p",
  body_strong_lg1_secondary: "p",
  body_strong_lg1_secondary_lightest: "p",
  body_strong_lg1_secondary_lighter: "p",
  body_strong_lg1_secondary_light: "p",
  body_strong_lg1_secondary_dark: "p",
  body_strong_lg1_secondary_darker: "p",
  body_strong_lg1_secondary_darkest: "p",
  body_strong_lg1_accent1: "p",
  body_strong_lg1_accent1_lightest: "p",
  body_strong_lg1_accent1_lighter: "p",
  body_strong_lg1_accent1_light: "p",
  body_strong_lg1_accent1_dark: "p",
  body_strong_lg1_accent1_darker: "p",
  body_strong_lg1_accent1_darkest: "p",
  body_strong_lg1_accent2: "p",
  body_strong_lg1_accent2_lightest: "p",
  body_strong_lg1_accent2_lighter: "p",
  body_strong_lg1_accent2_light: "p",
  body_strong_lg1_accent2_dark: "p",
  body_strong_lg1_accent2_darker: "p",
  body_strong_lg1_accent2_darkest: "p",
  body_strong_lg1_accent3: "p",
  body_strong_lg1_accent3_lightest: "p",
  body_strong_lg1_accent3_lighter: "p",
  body_strong_lg1_accent3_light: "p",
  body_strong_lg1_accent3_dark: "p",
  body_strong_lg1_accent3_darker: "p",
  body_strong_lg1_accent3_darkest: "p",
  body_strong_lg1_accent4: "p",
  body_strong_lg1_accent4_lightest: "p",
  body_strong_lg1_accent4_lighter: "p",
  body_strong_lg1_accent4_light: "p",
  body_strong_lg1_accent4_dark: "p",
  body_strong_lg1_accent4_darker: "p",
  body_strong_lg1_accent4_darkest: "p",
  body_strong_lg1: "p",
  body_strong_lg_serif_white: "p",
  body_strong_lg_serif_black: "p",
  body_strong_lg_serif_gray: "p",
  body_strong_lg_serif_green_2: "p",
  body_strong_lg_serif_dark_gray: "p",
  body_strong_lg_serif_dark_gray_2: "p",
  body_strong_lg_serif_error: "p",
  body_strong_lg_serif_warning: "p",
  body_strong_lg_serif_success: "p",
  body_strong_lg_serif_primary: "p",
  body_strong_lg_serif_primary_lightest: "p",
  body_strong_lg_serif_primary_lighter: "p",
  body_strong_lg_serif_primary_light: "p",
  body_strong_lg_serif_primary_dark: "p",
  body_strong_lg_serif_primary_darker: "p",
  body_strong_lg_serif_primary_darkest: "p",
  body_strong_lg_serif_secondary: "p",
  body_strong_lg_serif_secondary_lightest: "p",
  body_strong_lg_serif_secondary_lighter: "p",
  body_strong_lg_serif_secondary_light: "p",
  body_strong_lg_serif_secondary_dark: "p",
  body_strong_lg_serif_secondary_darker: "p",
  body_strong_lg_serif_secondary_darkest: "p",
  body_strong_lg_serif_accent1: "p",
  body_strong_lg_serif_accent1_lightest: "p",
  body_strong_lg_serif_accent1_lighter: "p",
  body_strong_lg_serif_accent1_light: "p",
  body_strong_lg_serif_accent1_dark: "p",
  body_strong_lg_serif_accent1_darker: "p",
  body_strong_lg_serif_accent1_darkest: "p",
  body_strong_lg_serif_accent2: "p",
  body_strong_lg_serif_accent2_lightest: "p",
  body_strong_lg_serif_accent2_lighter: "p",
  body_strong_lg_serif_accent2_light: "p",
  body_strong_lg_serif_accent2_dark: "p",
  body_strong_lg_serif_accent2_darker: "p",
  body_strong_lg_serif_accent2_darkest: "p",
  body_strong_lg_serif_accent3: "p",
  body_strong_lg_serif_accent3_lightest: "p",
  body_strong_lg_serif_accent3_lighter: "p",
  body_strong_lg_serif_accent3_light: "p",
  body_strong_lg_serif_accent3_dark: "p",
  body_strong_lg_serif_accent3_darker: "p",
  body_strong_lg_serif_accent3_darkest: "p",
  body_strong_lg_serif_accent4: "p",
  body_strong_lg_serif_accent4_lightest: "p",
  body_strong_lg_serif_accent4_lighter: "p",
  body_strong_lg_serif_accent4_light: "p",
  body_strong_lg_serif_accent4_dark: "p",
  body_strong_lg_serif_accent4_darker: "p",
  body_strong_lg_serif_accent4_darkest: "p",
  body_strong_lg_serif: "p",
  body_strong_lg_white: "p",
  body_strong_lg_black: "p",
  body_strong_lg_gray: "p",
  body_strong_lg_green_2: "p",
  body_strong_lg_dark_gray: "p",
  body_strong_lg_dark_gray_2: "p",
  body_strong_lg_error: "p",
  body_strong_lg_warning: "p",
  body_strong_lg_success: "p",
  body_strong_lg_primary: "p",
  body_strong_lg_primary_lightest: "p",
  body_strong_lg_primary_lighter: "p",
  body_strong_lg_primary_light: "p",
  body_strong_lg_primary_dark: "p",
  body_strong_lg_primary_darker: "p",
  body_strong_lg_primary_darkest: "p",
  body_strong_lg_secondary: "p",
  body_strong_lg_secondary_lightest: "p",
  body_strong_lg_secondary_lighter: "p",
  body_strong_lg_secondary_light: "p",
  body_strong_lg_secondary_dark: "p",
  body_strong_lg_secondary_darker: "p",
  body_strong_lg_secondary_darkest: "p",
  body_strong_lg_accent1: "p",
  body_strong_lg_accent1_lightest: "p",
  body_strong_lg_accent1_lighter: "p",
  body_strong_lg_accent1_light: "p",
  body_strong_lg_accent1_dark: "p",
  body_strong_lg_accent1_darker: "p",
  body_strong_lg_accent1_darkest: "p",
  body_strong_lg_accent2: "p",
  body_strong_lg_accent2_lightest: "p",
  body_strong_lg_accent2_lighter: "p",
  body_strong_lg_accent2_light: "p",
  body_strong_lg_accent2_dark: "p",
  body_strong_lg_accent2_darker: "p",
  body_strong_lg_accent2_darkest: "p",
  body_strong_lg_accent3: "p",
  body_strong_lg_accent3_lightest: "p",
  body_strong_lg_accent3_lighter: "p",
  body_strong_lg_accent3_light: "p",
  body_strong_lg_accent3_dark: "p",
  body_strong_lg_accent3_darker: "p",
  body_strong_lg_accent3_darkest: "p",
  body_strong_lg_accent4: "p",
  body_strong_lg_accent4_lightest: "p",
  body_strong_lg_accent4_lighter: "p",
  body_strong_lg_accent4_light: "p",
  body_strong_lg_accent4_dark: "p",
  body_strong_lg_accent4_darker: "p",
  body_strong_lg_accent4_darkest: "p",
  body_strong_lg: "p",
  body_strong_serif_white: "p",
  body_strong_serif_black: "p",
  body_strong_serif_gray: "p",
  body_strong_serif_green_2: "p",
  body_strong_serif_dark_gray: "p",
  body_strong_serif_dark_gray_2: "p",
  body_strong_serif_error: "p",
  body_strong_serif_warning: "p",
  body_strong_serif_success: "p",
  body_strong_serif_primary: "p",
  body_strong_serif_primary_lightest: "p",
  body_strong_serif_primary_lighter: "p",
  body_strong_serif_primary_light: "p",
  body_strong_serif_primary_dark: "p",
  body_strong_serif_primary_darker: "p",
  body_strong_serif_primary_darkest: "p",
  body_strong_serif_secondary: "p",
  body_strong_serif_secondary_lightest: "p",
  body_strong_serif_secondary_lighter: "p",
  body_strong_serif_secondary_light: "p",
  body_strong_serif_secondary_dark: "p",
  body_strong_serif_secondary_darker: "p",
  body_strong_serif_secondary_darkest: "p",
  body_strong_serif_accent1: "p",
  body_strong_serif_accent1_lightest: "p",
  body_strong_serif_accent1_lighter: "p",
  body_strong_serif_accent1_light: "p",
  body_strong_serif_accent1_dark: "p",
  body_strong_serif_accent1_darker: "p",
  body_strong_serif_accent1_darkest: "p",
  body_strong_serif_accent2: "p",
  body_strong_serif_accent2_lightest: "p",
  body_strong_serif_accent2_lighter: "p",
  body_strong_serif_accent2_light: "p",
  body_strong_serif_accent2_dark: "p",
  body_strong_serif_accent2_darker: "p",
  body_strong_serif_accent2_darkest: "p",
  body_strong_serif_accent3: "p",
  body_strong_serif_accent3_lightest: "p",
  body_strong_serif_accent3_lighter: "p",
  body_strong_serif_accent3_light: "p",
  body_strong_serif_accent3_dark: "p",
  body_strong_serif_accent3_darker: "p",
  body_strong_serif_accent3_darkest: "p",
  body_strong_serif_accent4: "p",
  body_strong_serif_accent4_lightest: "p",
  body_strong_serif_accent4_lighter: "p",
  body_strong_serif_accent4_light: "p",
  body_strong_serif_accent4_dark: "p",
  body_strong_serif_accent4_darker: "p",
  body_strong_serif_accent4_darkest: "p",
  body_strong_serif: "p",
  body_strong_white: "p",
  body_strong_black: "p",
  body_strong_gray: "p",
  body_strong_green_2: "p",
  body_strong_dark_gray: "p",
  body_strong_dark_gray_2: "p",
  body_strong_error: "p",
  body_strong_warning: "p",
  body_strong_success: "p",
  body_strong_primary: "p",
  body_strong_primary_lightest: "p",
  body_strong_primary_lighter: "p",
  body_strong_primary_light: "p",
  body_strong_primary_dark: "p",
  body_strong_primary_darker: "p",
  body_strong_primary_darkest: "p",
  body_strong_secondary: "p",
  body_strong_secondary_lightest: "p",
  body_strong_secondary_lighter: "p",
  body_strong_secondary_light: "p",
  body_strong_secondary_dark: "p",
  body_strong_secondary_darker: "p",
  body_strong_secondary_darkest: "p",
  body_strong_accent1: "p",
  body_strong_accent1_lightest: "p",
  body_strong_accent1_lighter: "p",
  body_strong_accent1_light: "p",
  body_strong_accent1_dark: "p",
  body_strong_accent1_darker: "p",
  body_strong_accent1_darkest: "p",
  body_strong_accent2: "p",
  body_strong_accent2_lightest: "p",
  body_strong_accent2_lighter: "p",
  body_strong_accent2_light: "p",
  body_strong_accent2_dark: "p",
  body_strong_accent2_darker: "p",
  body_strong_accent2_darkest: "p",
  body_strong_accent3: "p",
  body_strong_accent3_lightest: "p",
  body_strong_accent3_lighter: "p",
  body_strong_accent3_light: "p",
  body_strong_accent3_dark: "p",
  body_strong_accent3_darker: "p",
  body_strong_accent3_darkest: "p",
  body_strong_accent4: "p",
  body_strong_accent4_lightest: "p",
  body_strong_accent4_lighter: "p",
  body_strong_accent4_light: "p",
  body_strong_accent4_dark: "p",
  body_strong_accent4_darker: "p",
  body_strong_accent4_darkest: "p",
  body_strong: "p",
  body_strong_sm_serif_white: "p",
  body_strong_sm_serif_black: "p",
  body_strong_sm_serif_gray: "p",
  body_strong_sm_serif_green_2: "p",
  body_strong_sm_serif_dark_gray: "p",
  body_strong_sm_serif_dark_gray_2: "p",
  body_strong_sm_serif_error: "p",
  body_strong_sm_serif_warning: "p",
  body_strong_sm_serif_success: "p",
  body_strong_sm_serif_primary: "p",
  body_strong_sm_serif_primary_lightest: "p",
  body_strong_sm_serif_primary_lighter: "p",
  body_strong_sm_serif_primary_light: "p",
  body_strong_sm_serif_primary_dark: "p",
  body_strong_sm_serif_primary_darker: "p",
  body_strong_sm_serif_primary_darkest: "p",
  body_strong_sm_serif_secondary: "p",
  body_strong_sm_serif_secondary_lightest: "p",
  body_strong_sm_serif_secondary_lighter: "p",
  body_strong_sm_serif_secondary_light: "p",
  body_strong_sm_serif_secondary_dark: "p",
  body_strong_sm_serif_secondary_darker: "p",
  body_strong_sm_serif_secondary_darkest: "p",
  body_strong_sm_serif_accent1: "p",
  body_strong_sm_serif_accent1_lightest: "p",
  body_strong_sm_serif_accent1_lighter: "p",
  body_strong_sm_serif_accent1_light: "p",
  body_strong_sm_serif_accent1_dark: "p",
  body_strong_sm_serif_accent1_darker: "p",
  body_strong_sm_serif_accent1_darkest: "p",
  body_strong_sm_serif_accent2: "p",
  body_strong_sm_serif_accent2_lightest: "p",
  body_strong_sm_serif_accent2_lighter: "p",
  body_strong_sm_serif_accent2_light: "p",
  body_strong_sm_serif_accent2_dark: "p",
  body_strong_sm_serif_accent2_darker: "p",
  body_strong_sm_serif_accent2_darkest: "p",
  body_strong_sm_serif_accent3: "p",
  body_strong_sm_serif_accent3_lightest: "p",
  body_strong_sm_serif_accent3_lighter: "p",
  body_strong_sm_serif_accent3_light: "p",
  body_strong_sm_serif_accent3_dark: "p",
  body_strong_sm_serif_accent3_darker: "p",
  body_strong_sm_serif_accent3_darkest: "p",
  body_strong_sm_serif_accent4: "p",
  body_strong_sm_serif_accent4_lightest: "p",
  body_strong_sm_serif_accent4_lighter: "p",
  body_strong_sm_serif_accent4_light: "p",
  body_strong_sm_serif_accent4_dark: "p",
  body_strong_sm_serif_accent4_darker: "p",
  body_strong_sm_serif_accent4_darkest: "p",
  body_strong_sm_serif: "p",
  body_strong_sm_white: "p",
  body_strong_sm_black: "p",
  body_strong_sm_gray: "p",
  body_strong_sm_green_2: "p",
  body_strong_sm_dark_gray: "p",
  body_strong_sm_dark_gray_2: "p",
  body_strong_sm_error: "p",
  body_strong_sm_warning: "p",
  body_strong_sm_success: "p",
  body_strong_sm_primary: "p",
  body_strong_sm_primary_lightest: "p",
  body_strong_sm_primary_lighter: "p",
  body_strong_sm_primary_light: "p",
  body_strong_sm_primary_dark: "p",
  body_strong_sm_primary_darker: "p",
  body_strong_sm_primary_darkest: "p",
  body_strong_sm_secondary: "p",
  body_strong_sm_secondary_lightest: "p",
  body_strong_sm_secondary_lighter: "p",
  body_strong_sm_secondary_light: "p",
  body_strong_sm_secondary_dark: "p",
  body_strong_sm_secondary_darker: "p",
  body_strong_sm_secondary_darkest: "p",
  body_strong_sm_accent1: "p",
  body_strong_sm_accent1_lightest: "p",
  body_strong_sm_accent1_lighter: "p",
  body_strong_sm_accent1_light: "p",
  body_strong_sm_accent1_dark: "p",
  body_strong_sm_accent1_darker: "p",
  body_strong_sm_accent1_darkest: "p",
  body_strong_sm_accent2: "p",
  body_strong_sm_accent2_lightest: "p",
  body_strong_sm_accent2_lighter: "p",
  body_strong_sm_accent2_light: "p",
  body_strong_sm_accent2_dark: "p",
  body_strong_sm_accent2_darker: "p",
  body_strong_sm_accent2_darkest: "p",
  body_strong_sm_accent3: "p",
  body_strong_sm_accent3_lightest: "p",
  body_strong_sm_accent3_lighter: "p",
  body_strong_sm_accent3_light: "p",
  body_strong_sm_accent3_dark: "p",
  body_strong_sm_accent3_darker: "p",
  body_strong_sm_accent3_darkest: "p",
  body_strong_sm_accent4: "p",
  body_strong_sm_accent4_lightest: "p",
  body_strong_sm_accent4_lighter: "p",
  body_strong_sm_accent4_light: "p",
  body_strong_sm_accent4_dark: "p",
  body_strong_sm_accent4_darker: "p",
  body_strong_sm_accent4_darkest: "p",
  body_strong_sm: "p",
  body_strong_xs_serif_white: "p",
  body_strong_xs_serif_black: "p",
  body_strong_xs_serif_gray: "p",
  body_strong_xs_serif_green_2: "p",
  body_strong_xs_serif_dark_gray: "p",
  body_strong_xs_serif_dark_gray_2: "p",
  body_strong_xs_serif_error: "p",
  body_strong_xs_serif_warning: "p",
  body_strong_xs_serif_success: "p",
  body_strong_xs_serif_primary: "p",
  body_strong_xs_serif_primary_lightest: "p",
  body_strong_xs_serif_primary_lighter: "p",
  body_strong_xs_serif_primary_light: "p",
  body_strong_xs_serif_primary_dark: "p",
  body_strong_xs_serif_primary_darker: "p",
  body_strong_xs_serif_primary_darkest: "p",
  body_strong_xs_serif_secondary: "p",
  body_strong_xs_serif_secondary_lightest: "p",
  body_strong_xs_serif_secondary_lighter: "p",
  body_strong_xs_serif_secondary_light: "p",
  body_strong_xs_serif_secondary_dark: "p",
  body_strong_xs_serif_secondary_darker: "p",
  body_strong_xs_serif_secondary_darkest: "p",
  body_strong_xs_serif_accent1: "p",
  body_strong_xs_serif_accent1_lightest: "p",
  body_strong_xs_serif_accent1_lighter: "p",
  body_strong_xs_serif_accent1_light: "p",
  body_strong_xs_serif_accent1_dark: "p",
  body_strong_xs_serif_accent1_darker: "p",
  body_strong_xs_serif_accent1_darkest: "p",
  body_strong_xs_serif_accent2: "p",
  body_strong_xs_serif_accent2_lightest: "p",
  body_strong_xs_serif_accent2_lighter: "p",
  body_strong_xs_serif_accent2_light: "p",
  body_strong_xs_serif_accent2_dark: "p",
  body_strong_xs_serif_accent2_darker: "p",
  body_strong_xs_serif_accent2_darkest: "p",
  body_strong_xs_serif_accent3: "p",
  body_strong_xs_serif_accent3_lightest: "p",
  body_strong_xs_serif_accent3_lighter: "p",
  body_strong_xs_serif_accent3_light: "p",
  body_strong_xs_serif_accent3_dark: "p",
  body_strong_xs_serif_accent3_darker: "p",
  body_strong_xs_serif_accent3_darkest: "p",
  body_strong_xs_serif_accent4: "p",
  body_strong_xs_serif_accent4_lightest: "p",
  body_strong_xs_serif_accent4_lighter: "p",
  body_strong_xs_serif_accent4_light: "p",
  body_strong_xs_serif_accent4_dark: "p",
  body_strong_xs_serif_accent4_darker: "p",
  body_strong_xs_serif_accent4_darkest: "p",
  body_strong_xs_serif: "p",
  body_strong_xs_white: "p",
  body_strong_xs_black: "p",
  body_strong_xs_gray: "p",
  body_strong_xs_green_2: "p",
  body_strong_xs_dark_gray: "p",
  body_strong_xs_dark_gray_2: "p",
  body_strong_xs_error: "p",
  body_strong_xs_warning: "p",
  body_strong_xs_success: "p",
  body_strong_xs_primary: "p",
  body_strong_xs_primary_lightest: "p",
  body_strong_xs_primary_lighter: "p",
  body_strong_xs_primary_light: "p",
  body_strong_xs_primary_dark: "p",
  body_strong_xs_primary_darker: "p",
  body_strong_xs_primary_darkest: "p",
  body_strong_xs_secondary: "p",
  body_strong_xs_secondary_lightest: "p",
  body_strong_xs_secondary_lighter: "p",
  body_strong_xs_secondary_light: "p",
  body_strong_xs_secondary_dark: "p",
  body_strong_xs_secondary_darker: "p",
  body_strong_xs_secondary_darkest: "p",
  body_strong_xs_accent1: "p",
  body_strong_xs_accent1_lightest: "p",
  body_strong_xs_accent1_lighter: "p",
  body_strong_xs_accent1_light: "p",
  body_strong_xs_accent1_dark: "p",
  body_strong_xs_accent1_darker: "p",
  body_strong_xs_accent1_darkest: "p",
  body_strong_xs_accent2: "p",
  body_strong_xs_accent2_lightest: "p",
  body_strong_xs_accent2_lighter: "p",
  body_strong_xs_accent2_light: "p",
  body_strong_xs_accent2_dark: "p",
  body_strong_xs_accent2_darker: "p",
  body_strong_xs_accent2_darkest: "p",
  body_strong_xs_accent3: "p",
  body_strong_xs_accent3_lightest: "p",
  body_strong_xs_accent3_lighter: "p",
  body_strong_xs_accent3_light: "p",
  body_strong_xs_accent3_dark: "p",
  body_strong_xs_accent3_darker: "p",
  body_strong_xs_accent3_darkest: "p",
  body_strong_xs_accent4: "p",
  body_strong_xs_accent4_lightest: "p",
  body_strong_xs_accent4_lighter: "p",
  body_strong_xs_accent4_light: "p",
  body_strong_xs_accent4_dark: "p",
  body_strong_xs_accent4_darker: "p",
  body_strong_xs_accent4_darkest: "p",
  body_strong_xs: "p"
}

type TypographyCustomVariants =
  'h1_xl_serif_white' |
  'h1_xl_serif_black' |
  'h1_xl_serif_gray' |
  'h1_xl_serif_green_2' |
  'h1_xl_serif_dark_gray' |
  'h1_xl_serif_dark_gray_2' |
  'h1_xl_serif_error' |
  'h1_xl_serif_warning' |
  'h1_xl_serif_success' |
  'h1_xl_serif_primary' |
  'h1_xl_serif_primary_lightest' |
  'h1_xl_serif_primary_lighter' |
  'h1_xl_serif_primary_light' |
  'h1_xl_serif_primary_dark' |
  'h1_xl_serif_primary_darker' |
  'h1_xl_serif_primary_darkest' |
  'h1_xl_serif_secondary' |
  'h1_xl_serif_secondary_lightest' |
  'h1_xl_serif_secondary_lighter' |
  'h1_xl_serif_secondary_light' |
  'h1_xl_serif_secondary_dark' |
  'h1_xl_serif_secondary_darker' |
  'h1_xl_serif_secondary_darkest' |
  'h1_xl_serif_accent1' |
  'h1_xl_serif_accent1_lightest' |
  'h1_xl_serif_accent1_lighter' |
  'h1_xl_serif_accent1_light' |
  'h1_xl_serif_accent1_dark' |
  'h1_xl_serif_accent1_darker' |
  'h1_xl_serif_accent1_darkest' |
  'h1_xl_serif_accent2' |
  'h1_xl_serif_accent2_lightest' |
  'h1_xl_serif_accent2_lighter' |
  'h1_xl_serif_accent2_light' |
  'h1_xl_serif_accent2_dark' |
  'h1_xl_serif_accent2_darker' |
  'h1_xl_serif_accent2_darkest' |
  'h1_xl_serif_accent3' |
  'h1_xl_serif_accent3_lightest' |
  'h1_xl_serif_accent3_lighter' |
  'h1_xl_serif_accent3_light' |
  'h1_xl_serif_accent3_dark' |
  'h1_xl_serif_accent3_darker' |
  'h1_xl_serif_accent3_darkest' |
  'h1_xl_serif_accent4' |
  'h1_xl_serif_accent4_lightest' |
  'h1_xl_serif_accent4_lighter' |
  'h1_xl_serif_accent4_light' |
  'h1_xl_serif_accent4_dark' |
  'h1_xl_serif_accent4_darker' |
  'h1_xl_serif_accent4_darkest' |
  'h1_xl_serif' |
  'h1_xl_white' |
  'h1_xl_black' |
  'h1_xl_gray' |
  'h1_xl_green_2' |
  'h1_xl_dark_gray' |
  'h1_xl_dark_gray_2' |
  'h1_xl_error' |
  'h1_xl_warning' |
  'h1_xl_success' |
  'h1_xl_primary' |
  'h1_xl_primary_lightest' |
  'h1_xl_primary_lighter' |
  'h1_xl_primary_light' |
  'h1_xl_primary_dark' |
  'h1_xl_primary_darker' |
  'h1_xl_primary_darkest' |
  'h1_xl_secondary' |
  'h1_xl_secondary_lightest' |
  'h1_xl_secondary_lighter' |
  'h1_xl_secondary_light' |
  'h1_xl_secondary_dark' |
  'h1_xl_secondary_darker' |
  'h1_xl_secondary_darkest' |
  'h1_xl_accent1' |
  'h1_xl_accent1_lightest' |
  'h1_xl_accent1_lighter' |
  'h1_xl_accent1_light' |
  'h1_xl_accent1_dark' |
  'h1_xl_accent1_darker' |
  'h1_xl_accent1_darkest' |
  'h1_xl_accent2' |
  'h1_xl_accent2_lightest' |
  'h1_xl_accent2_lighter' |
  'h1_xl_accent2_light' |
  'h1_xl_accent2_dark' |
  'h1_xl_accent2_darker' |
  'h1_xl_accent2_darkest' |
  'h1_xl_accent3' |
  'h1_xl_accent3_lightest' |
  'h1_xl_accent3_lighter' |
  'h1_xl_accent3_light' |
  'h1_xl_accent3_dark' |
  'h1_xl_accent3_darker' |
  'h1_xl_accent3_darkest' |
  'h1_xl_accent4' |
  'h1_xl_accent4_lightest' |
  'h1_xl_accent4_lighter' |
  'h1_xl_accent4_light' |
  'h1_xl_accent4_dark' |
  'h1_xl_accent4_darker' |
  'h1_xl_accent4_darkest' |
  'h1_xl' |
  'h1_serif_white' |
  'h1_serif_black' |
  'h1_serif_gray' |
  'h1_serif_green_2' |
  'h1_serif_dark_gray' |
  'h1_serif_dark_gray_2' |
  'h1_serif_error' |
  'h1_serif_warning' |
  'h1_serif_success' |
  'h1_serif_primary' |
  'h1_serif_primary_lightest' |
  'h1_serif_primary_lighter' |
  'h1_serif_primary_light' |
  'h1_serif_primary_dark' |
  'h1_serif_primary_darker' |
  'h1_serif_primary_darkest' |
  'h1_serif_secondary' |
  'h1_serif_secondary_lightest' |
  'h1_serif_secondary_lighter' |
  'h1_serif_secondary_light' |
  'h1_serif_secondary_dark' |
  'h1_serif_secondary_darker' |
  'h1_serif_secondary_darkest' |
  'h1_serif_accent1' |
  'h1_serif_accent1_lightest' |
  'h1_serif_accent1_lighter' |
  'h1_serif_accent1_light' |
  'h1_serif_accent1_dark' |
  'h1_serif_accent1_darker' |
  'h1_serif_accent1_darkest' |
  'h1_serif_accent2' |
  'h1_serif_accent2_lightest' |
  'h1_serif_accent2_lighter' |
  'h1_serif_accent2_light' |
  'h1_serif_accent2_dark' |
  'h1_serif_accent2_darker' |
  'h1_serif_accent2_darkest' |
  'h1_serif_accent3' |
  'h1_serif_accent3_lightest' |
  'h1_serif_accent3_lighter' |
  'h1_serif_accent3_light' |
  'h1_serif_accent3_dark' |
  'h1_serif_accent3_darker' |
  'h1_serif_accent3_darkest' |
  'h1_serif_accent4' |
  'h1_serif_accent4_lightest' |
  'h1_serif_accent4_lighter' |
  'h1_serif_accent4_light' |
  'h1_serif_accent4_dark' |
  'h1_serif_accent4_darker' |
  'h1_serif_accent4_darkest' |
  'h1_serif' |
  'h1_white' |
  'h1_black' |
  'h1_gray' |
  'h1_green_2' |
  'h1_dark_gray' |
  'h1_dark_gray_2' |
  'h1_error' |
  'h1_warning' |
  'h1_success' |
  'h1_primary' |
  'h1_primary_lightest' |
  'h1_primary_lighter' |
  'h1_primary_light' |
  'h1_primary_dark' |
  'h1_primary_darker' |
  'h1_primary_darkest' |
  'h1_secondary' |
  'h1_secondary_lightest' |
  'h1_secondary_lighter' |
  'h1_secondary_light' |
  'h1_secondary_dark' |
  'h1_secondary_darker' |
  'h1_secondary_darkest' |
  'h1_accent1' |
  'h1_accent1_lightest' |
  'h1_accent1_lighter' |
  'h1_accent1_light' |
  'h1_accent1_dark' |
  'h1_accent1_darker' |
  'h1_accent1_darkest' |
  'h1_accent2' |
  'h1_accent2_lightest' |
  'h1_accent2_lighter' |
  'h1_accent2_light' |
  'h1_accent2_dark' |
  'h1_accent2_darker' |
  'h1_accent2_darkest' |
  'h1_accent3' |
  'h1_accent3_lightest' |
  'h1_accent3_lighter' |
  'h1_accent3_light' |
  'h1_accent3_dark' |
  'h1_accent3_darker' |
  'h1_accent3_darkest' |
  'h1_accent4' |
  'h1_accent4_lightest' |
  'h1_accent4_lighter' |
  'h1_accent4_light' |
  'h1_accent4_dark' |
  'h1_accent4_darker' |
  'h1_accent4_darkest' |
  'h1' |
  'h2_serif_white' |
  'h2_serif_black' |
  'h2_serif_gray' |
  'h2_serif_green_2' |
  'h2_serif_dark_gray' |
  'h2_serif_dark_gray_2' |
  'h2_serif_error' |
  'h2_serif_warning' |
  'h2_serif_success' |
  'h2_serif_primary' |
  'h2_serif_primary_lightest' |
  'h2_serif_primary_lighter' |
  'h2_serif_primary_light' |
  'h2_serif_primary_dark' |
  'h2_serif_primary_darker' |
  'h2_serif_primary_darkest' |
  'h2_serif_secondary' |
  'h2_serif_secondary_lightest' |
  'h2_serif_secondary_lighter' |
  'h2_serif_secondary_light' |
  'h2_serif_secondary_dark' |
  'h2_serif_secondary_darker' |
  'h2_serif_secondary_darkest' |
  'h2_serif_accent1' |
  'h2_serif_accent1_lightest' |
  'h2_serif_accent1_lighter' |
  'h2_serif_accent1_light' |
  'h2_serif_accent1_dark' |
  'h2_serif_accent1_darker' |
  'h2_serif_accent1_darkest' |
  'h2_serif_accent2' |
  'h2_serif_accent2_lightest' |
  'h2_serif_accent2_lighter' |
  'h2_serif_accent2_light' |
  'h2_serif_accent2_dark' |
  'h2_serif_accent2_darker' |
  'h2_serif_accent2_darkest' |
  'h2_serif_accent3' |
  'h2_serif_accent3_lightest' |
  'h2_serif_accent3_lighter' |
  'h2_serif_accent3_light' |
  'h2_serif_accent3_dark' |
  'h2_serif_accent3_darker' |
  'h2_serif_accent3_darkest' |
  'h2_serif_accent4' |
  'h2_serif_accent4_lightest' |
  'h2_serif_accent4_lighter' |
  'h2_serif_accent4_light' |
  'h2_serif_accent4_dark' |
  'h2_serif_accent4_darker' |
  'h2_serif_accent4_darkest' |
  'h2_serif' |
  'h2_white' |
  'h2_black' |
  'h2_gray' |
  'h2_green_2' |
  'h2_dark_gray' |
  'h2_dark_gray_2' |
  'h2_error' |
  'h2_warning' |
  'h2_success' |
  'h2_primary' |
  'h2_primary_lightest' |
  'h2_primary_lighter' |
  'h2_primary_light' |
  'h2_primary_dark' |
  'h2_primary_darker' |
  'h2_primary_darkest' |
  'h2_secondary' |
  'h2_secondary_lightest' |
  'h2_secondary_lighter' |
  'h2_secondary_light' |
  'h2_secondary_dark' |
  'h2_secondary_darker' |
  'h2_secondary_darkest' |
  'h2_accent1' |
  'h2_accent1_lightest' |
  'h2_accent1_lighter' |
  'h2_accent1_light' |
  'h2_accent1_dark' |
  'h2_accent1_darker' |
  'h2_accent1_darkest' |
  'h2_accent2' |
  'h2_accent2_lightest' |
  'h2_accent2_lighter' |
  'h2_accent2_light' |
  'h2_accent2_dark' |
  'h2_accent2_darker' |
  'h2_accent2_darkest' |
  'h2_accent3' |
  'h2_accent3_lightest' |
  'h2_accent3_lighter' |
  'h2_accent3_light' |
  'h2_accent3_dark' |
  'h2_accent3_darker' |
  'h2_accent3_darkest' |
  'h2_accent4' |
  'h2_accent4_lightest' |
  'h2_accent4_lighter' |
  'h2_accent4_light' |
  'h2_accent4_dark' |
  'h2_accent4_darker' |
  'h2_accent4_darkest' |
  'h2' |
  'h3_serif_white' |
  'h3_serif_black' |
  'h3_serif_gray' |
  'h3_serif_green_2' |
  'h3_serif_dark_gray' |
  'h3_serif_dark_gray_2' |
  'h3_serif_error' |
  'h3_serif_warning' |
  'h3_serif_success' |
  'h3_serif_primary' |
  'h3_serif_primary_lightest' |
  'h3_serif_primary_lighter' |
  'h3_serif_primary_light' |
  'h3_serif_primary_dark' |
  'h3_serif_primary_darker' |
  'h3_serif_primary_darkest' |
  'h3_serif_secondary' |
  'h3_serif_secondary_lightest' |
  'h3_serif_secondary_lighter' |
  'h3_serif_secondary_light' |
  'h3_serif_secondary_dark' |
  'h3_serif_secondary_darker' |
  'h3_serif_secondary_darkest' |
  'h3_serif_accent1' |
  'h3_serif_accent1_lightest' |
  'h3_serif_accent1_lighter' |
  'h3_serif_accent1_light' |
  'h3_serif_accent1_dark' |
  'h3_serif_accent1_darker' |
  'h3_serif_accent1_darkest' |
  'h3_serif_accent2' |
  'h3_serif_accent2_lightest' |
  'h3_serif_accent2_lighter' |
  'h3_serif_accent2_light' |
  'h3_serif_accent2_dark' |
  'h3_serif_accent2_darker' |
  'h3_serif_accent2_darkest' |
  'h3_serif_accent3' |
  'h3_serif_accent3_lightest' |
  'h3_serif_accent3_lighter' |
  'h3_serif_accent3_light' |
  'h3_serif_accent3_dark' |
  'h3_serif_accent3_darker' |
  'h3_serif_accent3_darkest' |
  'h3_serif_accent4' |
  'h3_serif_accent4_lightest' |
  'h3_serif_accent4_lighter' |
  'h3_serif_accent4_light' |
  'h3_serif_accent4_dark' |
  'h3_serif_accent4_darker' |
  'h3_serif_accent4_darkest' |
  'h3_serif' |
  'h3_white' |
  'h3_black' |
  'h3_gray' |
  'h3_green_2' |
  'h3_dark_gray' |
  'h3_dark_gray_2' |
  'h3_error' |
  'h3_warning' |
  'h3_success' |
  'h3_primary' |
  'h3_primary_lightest' |
  'h3_primary_lighter' |
  'h3_primary_light' |
  'h3_primary_dark' |
  'h3_primary_darker' |
  'h3_primary_darkest' |
  'h3_secondary' |
  'h3_secondary_lightest' |
  'h3_secondary_lighter' |
  'h3_secondary_light' |
  'h3_secondary_dark' |
  'h3_secondary_darker' |
  'h3_secondary_darkest' |
  'h3_accent1' |
  'h3_accent1_lightest' |
  'h3_accent1_lighter' |
  'h3_accent1_light' |
  'h3_accent1_dark' |
  'h3_accent1_darker' |
  'h3_accent1_darkest' |
  'h3_accent2' |
  'h3_accent2_lightest' |
  'h3_accent2_lighter' |
  'h3_accent2_light' |
  'h3_accent2_dark' |
  'h3_accent2_darker' |
  'h3_accent2_darkest' |
  'h3_accent3' |
  'h3_accent3_lightest' |
  'h3_accent3_lighter' |
  'h3_accent3_light' |
  'h3_accent3_dark' |
  'h3_accent3_darker' |
  'h3_accent3_darkest' |
  'h3_accent4' |
  'h3_accent4_lightest' |
  'h3_accent4_lighter' |
  'h3_accent4_light' |
  'h3_accent4_dark' |
  'h3_accent4_darker' |
  'h3_accent4_darkest' |
  'h3' |
  'h4_serif_white' |
  'h4_serif_black' |
  'h4_serif_gray' |
  'h4_serif_green_2' |
  'h4_serif_dark_gray' |
  'h4_serif_dark_gray_2' |
  'h4_serif_error' |
  'h4_serif_warning' |
  'h4_serif_success' |
  'h4_serif_primary' |
  'h4_serif_primary_lightest' |
  'h4_serif_primary_lighter' |
  'h4_serif_primary_light' |
  'h4_serif_primary_dark' |
  'h4_serif_primary_darker' |
  'h4_serif_primary_darkest' |
  'h4_serif_secondary' |
  'h4_serif_secondary_lightest' |
  'h4_serif_secondary_lighter' |
  'h4_serif_secondary_light' |
  'h4_serif_secondary_dark' |
  'h4_serif_secondary_darker' |
  'h4_serif_secondary_darkest' |
  'h4_serif_accent1' |
  'h4_serif_accent1_lightest' |
  'h4_serif_accent1_lighter' |
  'h4_serif_accent1_light' |
  'h4_serif_accent1_dark' |
  'h4_serif_accent1_darker' |
  'h4_serif_accent1_darkest' |
  'h4_serif_accent2' |
  'h4_serif_accent2_lightest' |
  'h4_serif_accent2_lighter' |
  'h4_serif_accent2_light' |
  'h4_serif_accent2_dark' |
  'h4_serif_accent2_darker' |
  'h4_serif_accent2_darkest' |
  'h4_serif_accent3' |
  'h4_serif_accent3_lightest' |
  'h4_serif_accent3_lighter' |
  'h4_serif_accent3_light' |
  'h4_serif_accent3_dark' |
  'h4_serif_accent3_darker' |
  'h4_serif_accent3_darkest' |
  'h4_serif_accent4' |
  'h4_serif_accent4_lightest' |
  'h4_serif_accent4_lighter' |
  'h4_serif_accent4_light' |
  'h4_serif_accent4_dark' |
  'h4_serif_accent4_darker' |
  'h4_serif_accent4_darkest' |
  'h4_serif' |
  'h4_white' |
  'h4_black' |
  'h4_gray' |
  'h4_green_2' |
  'h4_dark_gray' |
  'h4_dark_gray_2' |
  'h4_error' |
  'h4_warning' |
  'h4_success' |
  'h4_primary' |
  'h4_primary_lightest' |
  'h4_primary_lighter' |
  'h4_primary_light' |
  'h4_primary_dark' |
  'h4_primary_darker' |
  'h4_primary_darkest' |
  'h4_secondary' |
  'h4_secondary_lightest' |
  'h4_secondary_lighter' |
  'h4_secondary_light' |
  'h4_secondary_dark' |
  'h4_secondary_darker' |
  'h4_secondary_darkest' |
  'h4_accent1' |
  'h4_accent1_lightest' |
  'h4_accent1_lighter' |
  'h4_accent1_light' |
  'h4_accent1_dark' |
  'h4_accent1_darker' |
  'h4_accent1_darkest' |
  'h4_accent2' |
  'h4_accent2_lightest' |
  'h4_accent2_lighter' |
  'h4_accent2_light' |
  'h4_accent2_dark' |
  'h4_accent2_darker' |
  'h4_accent2_darkest' |
  'h4_accent3' |
  'h4_accent3_lightest' |
  'h4_accent3_lighter' |
  'h4_accent3_light' |
  'h4_accent3_dark' |
  'h4_accent3_darker' |
  'h4_accent3_darkest' |
  'h4_accent4' |
  'h4_accent4_lightest' |
  'h4_accent4_lighter' |
  'h4_accent4_light' |
  'h4_accent4_dark' |
  'h4_accent4_darker' |
  'h4_accent4_darkest' |
  'h4' |
  'h5_serif_white' |
  'h5_serif_black' |
  'h5_serif_gray' |
  'h5_serif_green_2' |
  'h5_serif_dark_gray' |
  'h5_serif_dark_gray_2' |
  'h5_serif_error' |
  'h5_serif_warning' |
  'h5_serif_success' |
  'h5_serif_primary' |
  'h5_serif_primary_lightest' |
  'h5_serif_primary_lighter' |
  'h5_serif_primary_light' |
  'h5_serif_primary_dark' |
  'h5_serif_primary_darker' |
  'h5_serif_primary_darkest' |
  'h5_serif_secondary' |
  'h5_serif_secondary_lightest' |
  'h5_serif_secondary_lighter' |
  'h5_serif_secondary_light' |
  'h5_serif_secondary_dark' |
  'h5_serif_secondary_darker' |
  'h5_serif_secondary_darkest' |
  'h5_serif_accent1' |
  'h5_serif_accent1_lightest' |
  'h5_serif_accent1_lighter' |
  'h5_serif_accent1_light' |
  'h5_serif_accent1_dark' |
  'h5_serif_accent1_darker' |
  'h5_serif_accent1_darkest' |
  'h5_serif_accent2' |
  'h5_serif_accent2_lightest' |
  'h5_serif_accent2_lighter' |
  'h5_serif_accent2_light' |
  'h5_serif_accent2_dark' |
  'h5_serif_accent2_darker' |
  'h5_serif_accent2_darkest' |
  'h5_serif_accent3' |
  'h5_serif_accent3_lightest' |
  'h5_serif_accent3_lighter' |
  'h5_serif_accent3_light' |
  'h5_serif_accent3_dark' |
  'h5_serif_accent3_darker' |
  'h5_serif_accent3_darkest' |
  'h5_serif_accent4' |
  'h5_serif_accent4_lightest' |
  'h5_serif_accent4_lighter' |
  'h5_serif_accent4_light' |
  'h5_serif_accent4_dark' |
  'h5_serif_accent4_darker' |
  'h5_serif_accent4_darkest' |
  'h5_serif' |
  'h5_white' |
  'h5_black' |
  'h5_gray' |
  'h5_green_2' |
  'h5_dark_gray' |
  'h5_dark_gray_2' |
  'h5_error' |
  'h5_warning' |
  'h5_success' |
  'h5_primary' |
  'h5_primary_lightest' |
  'h5_primary_lighter' |
  'h5_primary_light' |
  'h5_primary_dark' |
  'h5_primary_darker' |
  'h5_primary_darkest' |
  'h5_secondary' |
  'h5_secondary_lightest' |
  'h5_secondary_lighter' |
  'h5_secondary_light' |
  'h5_secondary_dark' |
  'h5_secondary_darker' |
  'h5_secondary_darkest' |
  'h5_accent1' |
  'h5_accent1_lightest' |
  'h5_accent1_lighter' |
  'h5_accent1_light' |
  'h5_accent1_dark' |
  'h5_accent1_darker' |
  'h5_accent1_darkest' |
  'h5_accent2' |
  'h5_accent2_lightest' |
  'h5_accent2_lighter' |
  'h5_accent2_light' |
  'h5_accent2_dark' |
  'h5_accent2_darker' |
  'h5_accent2_darkest' |
  'h5_accent3' |
  'h5_accent3_lightest' |
  'h5_accent3_lighter' |
  'h5_accent3_light' |
  'h5_accent3_dark' |
  'h5_accent3_darker' |
  'h5_accent3_darkest' |
  'h5_accent4' |
  'h5_accent4_lightest' |
  'h5_accent4_lighter' |
  'h5_accent4_light' |
  'h5_accent4_dark' |
  'h5_accent4_darker' |
  'h5_accent4_darkest' |
  'h5' |
  'h6_serif_white' |
  'h6_serif_black' |
  'h6_serif_gray' |
  'h6_serif_green_2' |
  'h6_serif_dark_gray' |
  'h6_serif_dark_gray_2' |
  'h6_serif_error' |
  'h6_serif_warning' |
  'h6_serif_success' |
  'h6_serif_primary' |
  'h6_serif_primary_lightest' |
  'h6_serif_primary_lighter' |
  'h6_serif_primary_light' |
  'h6_serif_primary_dark' |
  'h6_serif_primary_darker' |
  'h6_serif_primary_darkest' |
  'h6_serif_secondary' |
  'h6_serif_secondary_lightest' |
  'h6_serif_secondary_lighter' |
  'h6_serif_secondary_light' |
  'h6_serif_secondary_dark' |
  'h6_serif_secondary_darker' |
  'h6_serif_secondary_darkest' |
  'h6_serif_accent1' |
  'h6_serif_accent1_lightest' |
  'h6_serif_accent1_lighter' |
  'h6_serif_accent1_light' |
  'h6_serif_accent1_dark' |
  'h6_serif_accent1_darker' |
  'h6_serif_accent1_darkest' |
  'h6_serif_accent2' |
  'h6_serif_accent2_lightest' |
  'h6_serif_accent2_lighter' |
  'h6_serif_accent2_light' |
  'h6_serif_accent2_dark' |
  'h6_serif_accent2_darker' |
  'h6_serif_accent2_darkest' |
  'h6_serif_accent3' |
  'h6_serif_accent3_lightest' |
  'h6_serif_accent3_lighter' |
  'h6_serif_accent3_light' |
  'h6_serif_accent3_dark' |
  'h6_serif_accent3_darker' |
  'h6_serif_accent3_darkest' |
  'h6_serif_accent4' |
  'h6_serif_accent4_lightest' |
  'h6_serif_accent4_lighter' |
  'h6_serif_accent4_light' |
  'h6_serif_accent4_dark' |
  'h6_serif_accent4_darker' |
  'h6_serif_accent4_darkest' |
  'h6_serif' |
  'h6_white' |
  'h6_black' |
  'h6_gray' |
  'h6_green_2' |
  'h6_dark_gray' |
  'h6_dark_gray_2' |
  'h6_error' |
  'h6_warning' |
  'h6_success' |
  'h6_primary' |
  'h6_primary_lightest' |
  'h6_primary_lighter' |
  'h6_primary_light' |
  'h6_primary_dark' |
  'h6_primary_darker' |
  'h6_primary_darkest' |
  'h6_secondary' |
  'h6_secondary_lightest' |
  'h6_secondary_lighter' |
  'h6_secondary_light' |
  'h6_secondary_dark' |
  'h6_secondary_darker' |
  'h6_secondary_darkest' |
  'h6_accent1' |
  'h6_accent1_lightest' |
  'h6_accent1_lighter' |
  'h6_accent1_light' |
  'h6_accent1_dark' |
  'h6_accent1_darker' |
  'h6_accent1_darkest' |
  'h6_accent2' |
  'h6_accent2_lightest' |
  'h6_accent2_lighter' |
  'h6_accent2_light' |
  'h6_accent2_dark' |
  'h6_accent2_darker' |
  'h6_accent2_darkest' |
  'h6_accent3' |
  'h6_accent3_lightest' |
  'h6_accent3_lighter' |
  'h6_accent3_light' |
  'h6_accent3_dark' |
  'h6_accent3_darker' |
  'h6_accent3_darkest' |
  'h6_accent4' |
  'h6_accent4_lightest' |
  'h6_accent4_lighter' |
  'h6_accent4_light' |
  'h6_accent4_dark' |
  'h6_accent4_darker' |
  'h6_accent4_darkest' |
  'h6' |
  'body_xl_serif_white' |
  'body_xl_serif_black' |
  'body_xl_serif_gray' |
  'body_xl_serif_green_2' |
  'body_xl_serif_dark_gray' |
  'body_xl_serif_dark_gray_2' |
  'body_xl_serif_error' |
  'body_xl_serif_warning' |
  'body_xl_serif_success' |
  'body_xl_serif_primary' |
  'body_xl_serif_primary_lightest' |
  'body_xl_serif_primary_lighter' |
  'body_xl_serif_primary_light' |
  'body_xl_serif_primary_dark' |
  'body_xl_serif_primary_darker' |
  'body_xl_serif_primary_darkest' |
  'body_xl_serif_secondary' |
  'body_xl_serif_secondary_lightest' |
  'body_xl_serif_secondary_lighter' |
  'body_xl_serif_secondary_light' |
  'body_xl_serif_secondary_dark' |
  'body_xl_serif_secondary_darker' |
  'body_xl_serif_secondary_darkest' |
  'body_xl_serif_accent1' |
  'body_xl_serif_accent1_lightest' |
  'body_xl_serif_accent1_lighter' |
  'body_xl_serif_accent1_light' |
  'body_xl_serif_accent1_dark' |
  'body_xl_serif_accent1_darker' |
  'body_xl_serif_accent1_darkest' |
  'body_xl_serif_accent2' |
  'body_xl_serif_accent2_lightest' |
  'body_xl_serif_accent2_lighter' |
  'body_xl_serif_accent2_light' |
  'body_xl_serif_accent2_dark' |
  'body_xl_serif_accent2_darker' |
  'body_xl_serif_accent2_darkest' |
  'body_xl_serif_accent3' |
  'body_xl_serif_accent3_lightest' |
  'body_xl_serif_accent3_lighter' |
  'body_xl_serif_accent3_light' |
  'body_xl_serif_accent3_dark' |
  'body_xl_serif_accent3_darker' |
  'body_xl_serif_accent3_darkest' |
  'body_xl_serif_accent4' |
  'body_xl_serif_accent4_lightest' |
  'body_xl_serif_accent4_lighter' |
  'body_xl_serif_accent4_light' |
  'body_xl_serif_accent4_dark' |
  'body_xl_serif_accent4_darker' |
  'body_xl_serif_accent4_darkest' |
  'body_xl_serif' |
  'body_xl_white' |
  'body_xl_black' |
  'body_xl_gray' |
  'body_xl_green_2' |
  'body_xl_dark_gray' |
  'body_xl_dark_gray_2' |
  'body_xl_error' |
  'body_xl_warning' |
  'body_xl_success' |
  'body_xl_primary' |
  'body_xl_primary_lightest' |
  'body_xl_primary_lighter' |
  'body_xl_primary_light' |
  'body_xl_primary_dark' |
  'body_xl_primary_darker' |
  'body_xl_primary_darkest' |
  'body_xl_secondary' |
  'body_xl_secondary_lightest' |
  'body_xl_secondary_lighter' |
  'body_xl_secondary_light' |
  'body_xl_secondary_dark' |
  'body_xl_secondary_darker' |
  'body_xl_secondary_darkest' |
  'body_xl_accent1' |
  'body_xl_accent1_lightest' |
  'body_xl_accent1_lighter' |
  'body_xl_accent1_light' |
  'body_xl_accent1_dark' |
  'body_xl_accent1_darker' |
  'body_xl_accent1_darkest' |
  'body_xl_accent2' |
  'body_xl_accent2_lightest' |
  'body_xl_accent2_lighter' |
  'body_xl_accent2_light' |
  'body_xl_accent2_dark' |
  'body_xl_accent2_darker' |
  'body_xl_accent2_darkest' |
  'body_xl_accent3' |
  'body_xl_accent3_lightest' |
  'body_xl_accent3_lighter' |
  'body_xl_accent3_light' |
  'body_xl_accent3_dark' |
  'body_xl_accent3_darker' |
  'body_xl_accent3_darkest' |
  'body_xl_accent4' |
  'body_xl_accent4_lightest' |
  'body_xl_accent4_lighter' |
  'body_xl_accent4_light' |
  'body_xl_accent4_dark' |
  'body_xl_accent4_darker' |
  'body_xl_accent4_darkest' |
  'body_xl' |
  'body_lg2_serif_white' |
  'body_lg2_serif_black' |
  'body_lg2_serif_gray' |
  'body_lg2_serif_green_2' |
  'body_lg2_serif_dark_gray' |
  'body_lg2_serif_dark_gray_2' |
  'body_lg2_serif_error' |
  'body_lg2_serif_warning' |
  'body_lg2_serif_success' |
  'body_lg2_serif_primary' |
  'body_lg2_serif_primary_lightest' |
  'body_lg2_serif_primary_lighter' |
  'body_lg2_serif_primary_light' |
  'body_lg2_serif_primary_dark' |
  'body_lg2_serif_primary_darker' |
  'body_lg2_serif_primary_darkest' |
  'body_lg2_serif_secondary' |
  'body_lg2_serif_secondary_lightest' |
  'body_lg2_serif_secondary_lighter' |
  'body_lg2_serif_secondary_light' |
  'body_lg2_serif_secondary_dark' |
  'body_lg2_serif_secondary_darker' |
  'body_lg2_serif_secondary_darkest' |
  'body_lg2_serif_accent1' |
  'body_lg2_serif_accent1_lightest' |
  'body_lg2_serif_accent1_lighter' |
  'body_lg2_serif_accent1_light' |
  'body_lg2_serif_accent1_dark' |
  'body_lg2_serif_accent1_darker' |
  'body_lg2_serif_accent1_darkest' |
  'body_lg2_serif_accent2' |
  'body_lg2_serif_accent2_lightest' |
  'body_lg2_serif_accent2_lighter' |
  'body_lg2_serif_accent2_light' |
  'body_lg2_serif_accent2_dark' |
  'body_lg2_serif_accent2_darker' |
  'body_lg2_serif_accent2_darkest' |
  'body_lg2_serif_accent3' |
  'body_lg2_serif_accent3_lightest' |
  'body_lg2_serif_accent3_lighter' |
  'body_lg2_serif_accent3_light' |
  'body_lg2_serif_accent3_dark' |
  'body_lg2_serif_accent3_darker' |
  'body_lg2_serif_accent3_darkest' |
  'body_lg2_serif_accent4' |
  'body_lg2_serif_accent4_lightest' |
  'body_lg2_serif_accent4_lighter' |
  'body_lg2_serif_accent4_light' |
  'body_lg2_serif_accent4_dark' |
  'body_lg2_serif_accent4_darker' |
  'body_lg2_serif_accent4_darkest' |
  'body_lg2_serif' |
  'body_lg2_white' |
  'body_lg2_black' |
  'body_lg2_gray' |
  'body_lg2_green_2' |
  'body_lg2_dark_gray' |
  'body_lg2_dark_gray_2' |
  'body_lg2_error' |
  'body_lg2_warning' |
  'body_lg2_success' |
  'body_lg2_primary' |
  'body_lg2_primary_lightest' |
  'body_lg2_primary_lighter' |
  'body_lg2_primary_light' |
  'body_lg2_primary_dark' |
  'body_lg2_primary_darker' |
  'body_lg2_primary_darkest' |
  'body_lg2_secondary' |
  'body_lg2_secondary_lightest' |
  'body_lg2_secondary_lighter' |
  'body_lg2_secondary_light' |
  'body_lg2_secondary_dark' |
  'body_lg2_secondary_darker' |
  'body_lg2_secondary_darkest' |
  'body_lg2_accent1' |
  'body_lg2_accent1_lightest' |
  'body_lg2_accent1_lighter' |
  'body_lg2_accent1_light' |
  'body_lg2_accent1_dark' |
  'body_lg2_accent1_darker' |
  'body_lg2_accent1_darkest' |
  'body_lg2_accent2' |
  'body_lg2_accent2_lightest' |
  'body_lg2_accent2_lighter' |
  'body_lg2_accent2_light' |
  'body_lg2_accent2_dark' |
  'body_lg2_accent2_darker' |
  'body_lg2_accent2_darkest' |
  'body_lg2_accent3' |
  'body_lg2_accent3_lightest' |
  'body_lg2_accent3_lighter' |
  'body_lg2_accent3_light' |
  'body_lg2_accent3_dark' |
  'body_lg2_accent3_darker' |
  'body_lg2_accent3_darkest' |
  'body_lg2_accent4' |
  'body_lg2_accent4_lightest' |
  'body_lg2_accent4_lighter' |
  'body_lg2_accent4_light' |
  'body_lg2_accent4_dark' |
  'body_lg2_accent4_darker' |
  'body_lg2_accent4_darkest' |
  'body_lg2' |
  'body_lg1_serif_white' |
  'body_lg1_serif_black' |
  'body_lg1_serif_gray' |
  'body_lg1_serif_green_2' |
  'body_lg1_serif_dark_gray' |
  'body_lg1_serif_dark_gray_2' |
  'body_lg1_serif_error' |
  'body_lg1_serif_warning' |
  'body_lg1_serif_success' |
  'body_lg1_serif_primary' |
  'body_lg1_serif_primary_lightest' |
  'body_lg1_serif_primary_lighter' |
  'body_lg1_serif_primary_light' |
  'body_lg1_serif_primary_dark' |
  'body_lg1_serif_primary_darker' |
  'body_lg1_serif_primary_darkest' |
  'body_lg1_serif_secondary' |
  'body_lg1_serif_secondary_lightest' |
  'body_lg1_serif_secondary_lighter' |
  'body_lg1_serif_secondary_light' |
  'body_lg1_serif_secondary_dark' |
  'body_lg1_serif_secondary_darker' |
  'body_lg1_serif_secondary_darkest' |
  'body_lg1_serif_accent1' |
  'body_lg1_serif_accent1_lightest' |
  'body_lg1_serif_accent1_lighter' |
  'body_lg1_serif_accent1_light' |
  'body_lg1_serif_accent1_dark' |
  'body_lg1_serif_accent1_darker' |
  'body_lg1_serif_accent1_darkest' |
  'body_lg1_serif_accent2' |
  'body_lg1_serif_accent2_lightest' |
  'body_lg1_serif_accent2_lighter' |
  'body_lg1_serif_accent2_light' |
  'body_lg1_serif_accent2_dark' |
  'body_lg1_serif_accent2_darker' |
  'body_lg1_serif_accent2_darkest' |
  'body_lg1_serif_accent3' |
  'body_lg1_serif_accent3_lightest' |
  'body_lg1_serif_accent3_lighter' |
  'body_lg1_serif_accent3_light' |
  'body_lg1_serif_accent3_dark' |
  'body_lg1_serif_accent3_darker' |
  'body_lg1_serif_accent3_darkest' |
  'body_lg1_serif_accent4' |
  'body_lg1_serif_accent4_lightest' |
  'body_lg1_serif_accent4_lighter' |
  'body_lg1_serif_accent4_light' |
  'body_lg1_serif_accent4_dark' |
  'body_lg1_serif_accent4_darker' |
  'body_lg1_serif_accent4_darkest' |
  'body_lg1_serif' |
  'body_lg1_white' |
  'body_lg1_black' |
  'body_lg1_gray' |
  'body_lg1_green_2' |
  'body_lg1_dark_gray' |
  'body_lg1_dark_gray_2' |
  'body_lg1_error' |
  'body_lg1_warning' |
  'body_lg1_success' |
  'body_lg1_primary' |
  'body_lg1_primary_lightest' |
  'body_lg1_primary_lighter' |
  'body_lg1_primary_light' |
  'body_lg1_primary_dark' |
  'body_lg1_primary_darker' |
  'body_lg1_primary_darkest' |
  'body_lg1_secondary' |
  'body_lg1_secondary_lightest' |
  'body_lg1_secondary_lighter' |
  'body_lg1_secondary_light' |
  'body_lg1_secondary_dark' |
  'body_lg1_secondary_darker' |
  'body_lg1_secondary_darkest' |
  'body_lg1_accent1' |
  'body_lg1_accent1_lightest' |
  'body_lg1_accent1_lighter' |
  'body_lg1_accent1_light' |
  'body_lg1_accent1_dark' |
  'body_lg1_accent1_darker' |
  'body_lg1_accent1_darkest' |
  'body_lg1_accent2' |
  'body_lg1_accent2_lightest' |
  'body_lg1_accent2_lighter' |
  'body_lg1_accent2_light' |
  'body_lg1_accent2_dark' |
  'body_lg1_accent2_darker' |
  'body_lg1_accent2_darkest' |
  'body_lg1_accent3' |
  'body_lg1_accent3_lightest' |
  'body_lg1_accent3_lighter' |
  'body_lg1_accent3_light' |
  'body_lg1_accent3_dark' |
  'body_lg1_accent3_darker' |
  'body_lg1_accent3_darkest' |
  'body_lg1_accent4' |
  'body_lg1_accent4_lightest' |
  'body_lg1_accent4_lighter' |
  'body_lg1_accent4_light' |
  'body_lg1_accent4_dark' |
  'body_lg1_accent4_darker' |
  'body_lg1_accent4_darkest' |
  'body_lg1' |
  'body_lg_serif_white' |
  'body_lg_serif_black' |
  'body_lg_serif_gray' |
  'body_lg_serif_green_2' |
  'body_lg_serif_dark_gray' |
  'body_lg_serif_dark_gray_2' |
  'body_lg_serif_error' |
  'body_lg_serif_warning' |
  'body_lg_serif_success' |
  'body_lg_serif_primary' |
  'body_lg_serif_primary_lightest' |
  'body_lg_serif_primary_lighter' |
  'body_lg_serif_primary_light' |
  'body_lg_serif_primary_dark' |
  'body_lg_serif_primary_darker' |
  'body_lg_serif_primary_darkest' |
  'body_lg_serif_secondary' |
  'body_lg_serif_secondary_lightest' |
  'body_lg_serif_secondary_lighter' |
  'body_lg_serif_secondary_light' |
  'body_lg_serif_secondary_dark' |
  'body_lg_serif_secondary_darker' |
  'body_lg_serif_secondary_darkest' |
  'body_lg_serif_accent1' |
  'body_lg_serif_accent1_lightest' |
  'body_lg_serif_accent1_lighter' |
  'body_lg_serif_accent1_light' |
  'body_lg_serif_accent1_dark' |
  'body_lg_serif_accent1_darker' |
  'body_lg_serif_accent1_darkest' |
  'body_lg_serif_accent2' |
  'body_lg_serif_accent2_lightest' |
  'body_lg_serif_accent2_lighter' |
  'body_lg_serif_accent2_light' |
  'body_lg_serif_accent2_dark' |
  'body_lg_serif_accent2_darker' |
  'body_lg_serif_accent2_darkest' |
  'body_lg_serif_accent3' |
  'body_lg_serif_accent3_lightest' |
  'body_lg_serif_accent3_lighter' |
  'body_lg_serif_accent3_light' |
  'body_lg_serif_accent3_dark' |
  'body_lg_serif_accent3_darker' |
  'body_lg_serif_accent3_darkest' |
  'body_lg_serif_accent4' |
  'body_lg_serif_accent4_lightest' |
  'body_lg_serif_accent4_lighter' |
  'body_lg_serif_accent4_light' |
  'body_lg_serif_accent4_dark' |
  'body_lg_serif_accent4_darker' |
  'body_lg_serif_accent4_darkest' |
  'body_lg_serif' |
  'body_lg_white' |
  'body_lg_black' |
  'body_lg_gray' |
  'body_lg_green_2' |
  'body_lg_dark_gray' |
  'body_lg_dark_gray_2' |
  'body_lg_error' |
  'body_lg_warning' |
  'body_lg_success' |
  'body_lg_primary' |
  'body_lg_primary_lightest' |
  'body_lg_primary_lighter' |
  'body_lg_primary_light' |
  'body_lg_primary_dark' |
  'body_lg_primary_darker' |
  'body_lg_primary_darkest' |
  'body_lg_secondary' |
  'body_lg_secondary_lightest' |
  'body_lg_secondary_lighter' |
  'body_lg_secondary_light' |
  'body_lg_secondary_dark' |
  'body_lg_secondary_darker' |
  'body_lg_secondary_darkest' |
  'body_lg_accent1' |
  'body_lg_accent1_lightest' |
  'body_lg_accent1_lighter' |
  'body_lg_accent1_light' |
  'body_lg_accent1_dark' |
  'body_lg_accent1_darker' |
  'body_lg_accent1_darkest' |
  'body_lg_accent2' |
  'body_lg_accent2_lightest' |
  'body_lg_accent2_lighter' |
  'body_lg_accent2_light' |
  'body_lg_accent2_dark' |
  'body_lg_accent2_darker' |
  'body_lg_accent2_darkest' |
  'body_lg_accent3' |
  'body_lg_accent3_lightest' |
  'body_lg_accent3_lighter' |
  'body_lg_accent3_light' |
  'body_lg_accent3_dark' |
  'body_lg_accent3_darker' |
  'body_lg_accent3_darkest' |
  'body_lg_accent4' |
  'body_lg_accent4_lightest' |
  'body_lg_accent4_lighter' |
  'body_lg_accent4_light' |
  'body_lg_accent4_dark' |
  'body_lg_accent4_darker' |
  'body_lg_accent4_darkest' |
  'body_lg' |
  'body_serif_white' |
  'body_serif_black' |
  'body_serif_gray' |
  'body_serif_green_2' |
  'body_serif_dark_gray' |
  'body_serif_dark_gray_2' |
  'body_serif_error' |
  'body_serif_warning' |
  'body_serif_success' |
  'body_serif_primary' |
  'body_serif_primary_lightest' |
  'body_serif_primary_lighter' |
  'body_serif_primary_light' |
  'body_serif_primary_dark' |
  'body_serif_primary_darker' |
  'body_serif_primary_darkest' |
  'body_serif_secondary' |
  'body_serif_secondary_lightest' |
  'body_serif_secondary_lighter' |
  'body_serif_secondary_light' |
  'body_serif_secondary_dark' |
  'body_serif_secondary_darker' |
  'body_serif_secondary_darkest' |
  'body_serif_accent1' |
  'body_serif_accent1_lightest' |
  'body_serif_accent1_lighter' |
  'body_serif_accent1_light' |
  'body_serif_accent1_dark' |
  'body_serif_accent1_darker' |
  'body_serif_accent1_darkest' |
  'body_serif_accent2' |
  'body_serif_accent2_lightest' |
  'body_serif_accent2_lighter' |
  'body_serif_accent2_light' |
  'body_serif_accent2_dark' |
  'body_serif_accent2_darker' |
  'body_serif_accent2_darkest' |
  'body_serif_accent3' |
  'body_serif_accent3_lightest' |
  'body_serif_accent3_lighter' |
  'body_serif_accent3_light' |
  'body_serif_accent3_dark' |
  'body_serif_accent3_darker' |
  'body_serif_accent3_darkest' |
  'body_serif_accent4' |
  'body_serif_accent4_lightest' |
  'body_serif_accent4_lighter' |
  'body_serif_accent4_light' |
  'body_serif_accent4_dark' |
  'body_serif_accent4_darker' |
  'body_serif_accent4_darkest' |
  'body_serif' |
  'body_white' |
  'body_black' |
  'body_gray' |
  'body_green_2' |
  'body_dark_gray' |
  'body_dark_gray_2' |
  'body_error' |
  'body_warning' |
  'body_success' |
  'body_primary' |
  'body_primary_lightest' |
  'body_primary_lighter' |
  'body_primary_light' |
  'body_primary_dark' |
  'body_primary_darker' |
  'body_primary_darkest' |
  'body_secondary' |
  'body_secondary_lightest' |
  'body_secondary_lighter' |
  'body_secondary_light' |
  'body_secondary_dark' |
  'body_secondary_darker' |
  'body_secondary_darkest' |
  'body_accent1' |
  'body_accent1_lightest' |
  'body_accent1_lighter' |
  'body_accent1_light' |
  'body_accent1_dark' |
  'body_accent1_darker' |
  'body_accent1_darkest' |
  'body_accent2' |
  'body_accent2_lightest' |
  'body_accent2_lighter' |
  'body_accent2_light' |
  'body_accent2_dark' |
  'body_accent2_darker' |
  'body_accent2_darkest' |
  'body_accent3' |
  'body_accent3_lightest' |
  'body_accent3_lighter' |
  'body_accent3_light' |
  'body_accent3_dark' |
  'body_accent3_darker' |
  'body_accent3_darkest' |
  'body_accent4' |
  'body_accent4_lightest' |
  'body_accent4_lighter' |
  'body_accent4_light' |
  'body_accent4_dark' |
  'body_accent4_darker' |
  'body_accent4_darkest' |
  'body' |
  'body_sm_serif_white' |
  'body_sm_serif_black' |
  'body_sm_serif_gray' |
  'body_sm_serif_green_2' |
  'body_sm_serif_dark_gray' |
  'body_sm_serif_dark_gray_2' |
  'body_sm_serif_error' |
  'body_sm_serif_warning' |
  'body_sm_serif_success' |
  'body_sm_serif_primary' |
  'body_sm_serif_primary_lightest' |
  'body_sm_serif_primary_lighter' |
  'body_sm_serif_primary_light' |
  'body_sm_serif_primary_dark' |
  'body_sm_serif_primary_darker' |
  'body_sm_serif_primary_darkest' |
  'body_sm_serif_secondary' |
  'body_sm_serif_secondary_lightest' |
  'body_sm_serif_secondary_lighter' |
  'body_sm_serif_secondary_light' |
  'body_sm_serif_secondary_dark' |
  'body_sm_serif_secondary_darker' |
  'body_sm_serif_secondary_darkest' |
  'body_sm_serif_accent1' |
  'body_sm_serif_accent1_lightest' |
  'body_sm_serif_accent1_lighter' |
  'body_sm_serif_accent1_light' |
  'body_sm_serif_accent1_dark' |
  'body_sm_serif_accent1_darker' |
  'body_sm_serif_accent1_darkest' |
  'body_sm_serif_accent2' |
  'body_sm_serif_accent2_lightest' |
  'body_sm_serif_accent2_lighter' |
  'body_sm_serif_accent2_light' |
  'body_sm_serif_accent2_dark' |
  'body_sm_serif_accent2_darker' |
  'body_sm_serif_accent2_darkest' |
  'body_sm_serif_accent3' |
  'body_sm_serif_accent3_lightest' |
  'body_sm_serif_accent3_lighter' |
  'body_sm_serif_accent3_light' |
  'body_sm_serif_accent3_dark' |
  'body_sm_serif_accent3_darker' |
  'body_sm_serif_accent3_darkest' |
  'body_sm_serif_accent4' |
  'body_sm_serif_accent4_lightest' |
  'body_sm_serif_accent4_lighter' |
  'body_sm_serif_accent4_light' |
  'body_sm_serif_accent4_dark' |
  'body_sm_serif_accent4_darker' |
  'body_sm_serif_accent4_darkest' |
  'body_sm_serif' |
  'body_sm_white' |
  'body_sm_black' |
  'body_sm_gray' |
  'body_sm_green_2' |
  'body_sm_dark_gray' |
  'body_sm_dark_gray_2' |
  'body_sm_error' |
  'body_sm_warning' |
  'body_sm_success' |
  'body_sm_primary' |
  'body_sm_primary_lightest' |
  'body_sm_primary_lighter' |
  'body_sm_primary_light' |
  'body_sm_primary_dark' |
  'body_sm_primary_darker' |
  'body_sm_primary_darkest' |
  'body_sm_secondary' |
  'body_sm_secondary_lightest' |
  'body_sm_secondary_lighter' |
  'body_sm_secondary_light' |
  'body_sm_secondary_dark' |
  'body_sm_secondary_darker' |
  'body_sm_secondary_darkest' |
  'body_sm_accent1' |
  'body_sm_accent1_lightest' |
  'body_sm_accent1_lighter' |
  'body_sm_accent1_light' |
  'body_sm_accent1_dark' |
  'body_sm_accent1_darker' |
  'body_sm_accent1_darkest' |
  'body_sm_accent2' |
  'body_sm_accent2_lightest' |
  'body_sm_accent2_lighter' |
  'body_sm_accent2_light' |
  'body_sm_accent2_dark' |
  'body_sm_accent2_darker' |
  'body_sm_accent2_darkest' |
  'body_sm_accent3' |
  'body_sm_accent3_lightest' |
  'body_sm_accent3_lighter' |
  'body_sm_accent3_light' |
  'body_sm_accent3_dark' |
  'body_sm_accent3_darker' |
  'body_sm_accent3_darkest' |
  'body_sm_accent4' |
  'body_sm_accent4_lightest' |
  'body_sm_accent4_lighter' |
  'body_sm_accent4_light' |
  'body_sm_accent4_dark' |
  'body_sm_accent4_darker' |
  'body_sm_accent4_darkest' |
  'body_sm' |
  'body_xs_serif_white' |
  'body_xs_serif_black' |
  'body_xs_serif_gray' |
  'body_xs_serif_green_2' |
  'body_xs_serif_dark_gray' |
  'body_xs_serif_dark_gray_2' |
  'body_xs_serif_error' |
  'body_xs_serif_warning' |
  'body_xs_serif_success' |
  'body_xs_serif_primary' |
  'body_xs_serif_primary_lightest' |
  'body_xs_serif_primary_lighter' |
  'body_xs_serif_primary_light' |
  'body_xs_serif_primary_dark' |
  'body_xs_serif_primary_darker' |
  'body_xs_serif_primary_darkest' |
  'body_xs_serif_secondary' |
  'body_xs_serif_secondary_lightest' |
  'body_xs_serif_secondary_lighter' |
  'body_xs_serif_secondary_light' |
  'body_xs_serif_secondary_dark' |
  'body_xs_serif_secondary_darker' |
  'body_xs_serif_secondary_darkest' |
  'body_xs_serif_accent1' |
  'body_xs_serif_accent1_lightest' |
  'body_xs_serif_accent1_lighter' |
  'body_xs_serif_accent1_light' |
  'body_xs_serif_accent1_dark' |
  'body_xs_serif_accent1_darker' |
  'body_xs_serif_accent1_darkest' |
  'body_xs_serif_accent2' |
  'body_xs_serif_accent2_lightest' |
  'body_xs_serif_accent2_lighter' |
  'body_xs_serif_accent2_light' |
  'body_xs_serif_accent2_dark' |
  'body_xs_serif_accent2_darker' |
  'body_xs_serif_accent2_darkest' |
  'body_xs_serif_accent3' |
  'body_xs_serif_accent3_lightest' |
  'body_xs_serif_accent3_lighter' |
  'body_xs_serif_accent3_light' |
  'body_xs_serif_accent3_dark' |
  'body_xs_serif_accent3_darker' |
  'body_xs_serif_accent3_darkest' |
  'body_xs_serif_accent4' |
  'body_xs_serif_accent4_lightest' |
  'body_xs_serif_accent4_lighter' |
  'body_xs_serif_accent4_light' |
  'body_xs_serif_accent4_dark' |
  'body_xs_serif_accent4_darker' |
  'body_xs_serif_accent4_darkest' |
  'body_xs_serif' |
  'body_xs_white' |
  'body_xs_black' |
  'body_xs_gray' |
  'body_xs_green_2' |
  'body_xs_dark_gray' |
  'body_xs_dark_gray_2' |
  'body_xs_error' |
  'body_xs_warning' |
  'body_xs_success' |
  'body_xs_primary' |
  'body_xs_primary_lightest' |
  'body_xs_primary_lighter' |
  'body_xs_primary_light' |
  'body_xs_primary_dark' |
  'body_xs_primary_darker' |
  'body_xs_primary_darkest' |
  'body_xs_secondary' |
  'body_xs_secondary_lightest' |
  'body_xs_secondary_lighter' |
  'body_xs_secondary_light' |
  'body_xs_secondary_dark' |
  'body_xs_secondary_darker' |
  'body_xs_secondary_darkest' |
  'body_xs_accent1' |
  'body_xs_accent1_lightest' |
  'body_xs_accent1_lighter' |
  'body_xs_accent1_light' |
  'body_xs_accent1_dark' |
  'body_xs_accent1_darker' |
  'body_xs_accent1_darkest' |
  'body_xs_accent2' |
  'body_xs_accent2_lightest' |
  'body_xs_accent2_lighter' |
  'body_xs_accent2_light' |
  'body_xs_accent2_dark' |
  'body_xs_accent2_darker' |
  'body_xs_accent2_darkest' |
  'body_xs_accent3' |
  'body_xs_accent3_lightest' |
  'body_xs_accent3_lighter' |
  'body_xs_accent3_light' |
  'body_xs_accent3_dark' |
  'body_xs_accent3_darker' |
  'body_xs_accent3_darkest' |
  'body_xs_accent4' |
  'body_xs_accent4_lightest' |
  'body_xs_accent4_lighter' |
  'body_xs_accent4_light' |
  'body_xs_accent4_dark' |
  'body_xs_accent4_darker' |
  'body_xs_accent4_darkest' |
  'body_xs' |
  'body_strong_xl_serif_white' |
  'body_strong_xl_serif_black' |
  'body_strong_xl_serif_gray' |
  'body_strong_xl_serif_green_2' |
  'body_strong_xl_serif_dark_gray' |
  'body_strong_xl_serif_dark_gray_2' |
  'body_strong_xl_serif_error' |
  'body_strong_xl_serif_warning' |
  'body_strong_xl_serif_success' |
  'body_strong_xl_serif_primary' |
  'body_strong_xl_serif_primary_lightest' |
  'body_strong_xl_serif_primary_lighter' |
  'body_strong_xl_serif_primary_light' |
  'body_strong_xl_serif_primary_dark' |
  'body_strong_xl_serif_primary_darker' |
  'body_strong_xl_serif_primary_darkest' |
  'body_strong_xl_serif_secondary' |
  'body_strong_xl_serif_secondary_lightest' |
  'body_strong_xl_serif_secondary_lighter' |
  'body_strong_xl_serif_secondary_light' |
  'body_strong_xl_serif_secondary_dark' |
  'body_strong_xl_serif_secondary_darker' |
  'body_strong_xl_serif_secondary_darkest' |
  'body_strong_xl_serif_accent1' |
  'body_strong_xl_serif_accent1_lightest' |
  'body_strong_xl_serif_accent1_lighter' |
  'body_strong_xl_serif_accent1_light' |
  'body_strong_xl_serif_accent1_dark' |
  'body_strong_xl_serif_accent1_darker' |
  'body_strong_xl_serif_accent1_darkest' |
  'body_strong_xl_serif_accent2' |
  'body_strong_xl_serif_accent2_lightest' |
  'body_strong_xl_serif_accent2_lighter' |
  'body_strong_xl_serif_accent2_light' |
  'body_strong_xl_serif_accent2_dark' |
  'body_strong_xl_serif_accent2_darker' |
  'body_strong_xl_serif_accent2_darkest' |
  'body_strong_xl_serif_accent3' |
  'body_strong_xl_serif_accent3_lightest' |
  'body_strong_xl_serif_accent3_lighter' |
  'body_strong_xl_serif_accent3_light' |
  'body_strong_xl_serif_accent3_dark' |
  'body_strong_xl_serif_accent3_darker' |
  'body_strong_xl_serif_accent3_darkest' |
  'body_strong_xl_serif_accent4' |
  'body_strong_xl_serif_accent4_lightest' |
  'body_strong_xl_serif_accent4_lighter' |
  'body_strong_xl_serif_accent4_light' |
  'body_strong_xl_serif_accent4_dark' |
  'body_strong_xl_serif_accent4_darker' |
  'body_strong_xl_serif_accent4_darkest' |
  'body_strong_xl_serif' |
  'body_strong_xl_white' |
  'body_strong_xl_black' |
  'body_strong_xl_gray' |
  'body_strong_xl_green_2' |
  'body_strong_xl_dark_gray' |
  'body_strong_xl_dark_gray_2' |
  'body_strong_xl_error' |
  'body_strong_xl_warning' |
  'body_strong_xl_success' |
  'body_strong_xl_primary' |
  'body_strong_xl_primary_lightest' |
  'body_strong_xl_primary_lighter' |
  'body_strong_xl_primary_light' |
  'body_strong_xl_primary_dark' |
  'body_strong_xl_primary_darker' |
  'body_strong_xl_primary_darkest' |
  'body_strong_xl_secondary' |
  'body_strong_xl_secondary_lightest' |
  'body_strong_xl_secondary_lighter' |
  'body_strong_xl_secondary_light' |
  'body_strong_xl_secondary_dark' |
  'body_strong_xl_secondary_darker' |
  'body_strong_xl_secondary_darkest' |
  'body_strong_xl_accent1' |
  'body_strong_xl_accent1_lightest' |
  'body_strong_xl_accent1_lighter' |
  'body_strong_xl_accent1_light' |
  'body_strong_xl_accent1_dark' |
  'body_strong_xl_accent1_darker' |
  'body_strong_xl_accent1_darkest' |
  'body_strong_xl_accent2' |
  'body_strong_xl_accent2_lightest' |
  'body_strong_xl_accent2_lighter' |
  'body_strong_xl_accent2_light' |
  'body_strong_xl_accent2_dark' |
  'body_strong_xl_accent2_darker' |
  'body_strong_xl_accent2_darkest' |
  'body_strong_xl_accent3' |
  'body_strong_xl_accent3_lightest' |
  'body_strong_xl_accent3_lighter' |
  'body_strong_xl_accent3_light' |
  'body_strong_xl_accent3_dark' |
  'body_strong_xl_accent3_darker' |
  'body_strong_xl_accent3_darkest' |
  'body_strong_xl_accent4' |
  'body_strong_xl_accent4_lightest' |
  'body_strong_xl_accent4_lighter' |
  'body_strong_xl_accent4_light' |
  'body_strong_xl_accent4_dark' |
  'body_strong_xl_accent4_darker' |
  'body_strong_xl_accent4_darkest' |
  'body_strong_xl' |
  'body_strong_lg2_serif_white' |
  'body_strong_lg2_serif_black' |
  'body_strong_lg2_serif_gray' |
  'body_strong_lg2_serif_green_2' |
  'body_strong_lg2_serif_dark_gray' |
  'body_strong_lg2_serif_dark_gray_2' |
  'body_strong_lg2_serif_error' |
  'body_strong_lg2_serif_warning' |
  'body_strong_lg2_serif_success' |
  'body_strong_lg2_serif_primary' |
  'body_strong_lg2_serif_primary_lightest' |
  'body_strong_lg2_serif_primary_lighter' |
  'body_strong_lg2_serif_primary_light' |
  'body_strong_lg2_serif_primary_dark' |
  'body_strong_lg2_serif_primary_darker' |
  'body_strong_lg2_serif_primary_darkest' |
  'body_strong_lg2_serif_secondary' |
  'body_strong_lg2_serif_secondary_lightest' |
  'body_strong_lg2_serif_secondary_lighter' |
  'body_strong_lg2_serif_secondary_light' |
  'body_strong_lg2_serif_secondary_dark' |
  'body_strong_lg2_serif_secondary_darker' |
  'body_strong_lg2_serif_secondary_darkest' |
  'body_strong_lg2_serif_accent1' |
  'body_strong_lg2_serif_accent1_lightest' |
  'body_strong_lg2_serif_accent1_lighter' |
  'body_strong_lg2_serif_accent1_light' |
  'body_strong_lg2_serif_accent1_dark' |
  'body_strong_lg2_serif_accent1_darker' |
  'body_strong_lg2_serif_accent1_darkest' |
  'body_strong_lg2_serif_accent2' |
  'body_strong_lg2_serif_accent2_lightest' |
  'body_strong_lg2_serif_accent2_lighter' |
  'body_strong_lg2_serif_accent2_light' |
  'body_strong_lg2_serif_accent2_dark' |
  'body_strong_lg2_serif_accent2_darker' |
  'body_strong_lg2_serif_accent2_darkest' |
  'body_strong_lg2_serif_accent3' |
  'body_strong_lg2_serif_accent3_lightest' |
  'body_strong_lg2_serif_accent3_lighter' |
  'body_strong_lg2_serif_accent3_light' |
  'body_strong_lg2_serif_accent3_dark' |
  'body_strong_lg2_serif_accent3_darker' |
  'body_strong_lg2_serif_accent3_darkest' |
  'body_strong_lg2_serif_accent4' |
  'body_strong_lg2_serif_accent4_lightest' |
  'body_strong_lg2_serif_accent4_lighter' |
  'body_strong_lg2_serif_accent4_light' |
  'body_strong_lg2_serif_accent4_dark' |
  'body_strong_lg2_serif_accent4_darker' |
  'body_strong_lg2_serif_accent4_darkest' |
  'body_strong_lg2_serif' |
  'body_strong_lg2_white' |
  'body_strong_lg2_black' |
  'body_strong_lg2_gray' |
  'body_strong_lg2_green_2' |
  'body_strong_lg2_dark_gray' |
  'body_strong_lg2_dark_gray_2' |
  'body_strong_lg2_error' |
  'body_strong_lg2_warning' |
  'body_strong_lg2_success' |
  'body_strong_lg2_primary' |
  'body_strong_lg2_primary_lightest' |
  'body_strong_lg2_primary_lighter' |
  'body_strong_lg2_primary_light' |
  'body_strong_lg2_primary_dark' |
  'body_strong_lg2_primary_darker' |
  'body_strong_lg2_primary_darkest' |
  'body_strong_lg2_secondary' |
  'body_strong_lg2_secondary_lightest' |
  'body_strong_lg2_secondary_lighter' |
  'body_strong_lg2_secondary_light' |
  'body_strong_lg2_secondary_dark' |
  'body_strong_lg2_secondary_darker' |
  'body_strong_lg2_secondary_darkest' |
  'body_strong_lg2_accent1' |
  'body_strong_lg2_accent1_lightest' |
  'body_strong_lg2_accent1_lighter' |
  'body_strong_lg2_accent1_light' |
  'body_strong_lg2_accent1_dark' |
  'body_strong_lg2_accent1_darker' |
  'body_strong_lg2_accent1_darkest' |
  'body_strong_lg2_accent2' |
  'body_strong_lg2_accent2_lightest' |
  'body_strong_lg2_accent2_lighter' |
  'body_strong_lg2_accent2_light' |
  'body_strong_lg2_accent2_dark' |
  'body_strong_lg2_accent2_darker' |
  'body_strong_lg2_accent2_darkest' |
  'body_strong_lg2_accent3' |
  'body_strong_lg2_accent3_lightest' |
  'body_strong_lg2_accent3_lighter' |
  'body_strong_lg2_accent3_light' |
  'body_strong_lg2_accent3_dark' |
  'body_strong_lg2_accent3_darker' |
  'body_strong_lg2_accent3_darkest' |
  'body_strong_lg2_accent4' |
  'body_strong_lg2_accent4_lightest' |
  'body_strong_lg2_accent4_lighter' |
  'body_strong_lg2_accent4_light' |
  'body_strong_lg2_accent4_dark' |
  'body_strong_lg2_accent4_darker' |
  'body_strong_lg2_accent4_darkest' |
  'body_strong_lg2' |
  'body_strong_lg1_serif_white' |
  'body_strong_lg1_serif_black' |
  'body_strong_lg1_serif_gray' |
  'body_strong_lg1_serif_green_2' |
  'body_strong_lg1_serif_dark_gray' |
  'body_strong_lg1_serif_dark_gray_2' |
  'body_strong_lg1_serif_error' |
  'body_strong_lg1_serif_warning' |
  'body_strong_lg1_serif_success' |
  'body_strong_lg1_serif_primary' |
  'body_strong_lg1_serif_primary_lightest' |
  'body_strong_lg1_serif_primary_lighter' |
  'body_strong_lg1_serif_primary_light' |
  'body_strong_lg1_serif_primary_dark' |
  'body_strong_lg1_serif_primary_darker' |
  'body_strong_lg1_serif_primary_darkest' |
  'body_strong_lg1_serif_secondary' |
  'body_strong_lg1_serif_secondary_lightest' |
  'body_strong_lg1_serif_secondary_lighter' |
  'body_strong_lg1_serif_secondary_light' |
  'body_strong_lg1_serif_secondary_dark' |
  'body_strong_lg1_serif_secondary_darker' |
  'body_strong_lg1_serif_secondary_darkest' |
  'body_strong_lg1_serif_accent1' |
  'body_strong_lg1_serif_accent1_lightest' |
  'body_strong_lg1_serif_accent1_lighter' |
  'body_strong_lg1_serif_accent1_light' |
  'body_strong_lg1_serif_accent1_dark' |
  'body_strong_lg1_serif_accent1_darker' |
  'body_strong_lg1_serif_accent1_darkest' |
  'body_strong_lg1_serif_accent2' |
  'body_strong_lg1_serif_accent2_lightest' |
  'body_strong_lg1_serif_accent2_lighter' |
  'body_strong_lg1_serif_accent2_light' |
  'body_strong_lg1_serif_accent2_dark' |
  'body_strong_lg1_serif_accent2_darker' |
  'body_strong_lg1_serif_accent2_darkest' |
  'body_strong_lg1_serif_accent3' |
  'body_strong_lg1_serif_accent3_lightest' |
  'body_strong_lg1_serif_accent3_lighter' |
  'body_strong_lg1_serif_accent3_light' |
  'body_strong_lg1_serif_accent3_dark' |
  'body_strong_lg1_serif_accent3_darker' |
  'body_strong_lg1_serif_accent3_darkest' |
  'body_strong_lg1_serif_accent4' |
  'body_strong_lg1_serif_accent4_lightest' |
  'body_strong_lg1_serif_accent4_lighter' |
  'body_strong_lg1_serif_accent4_light' |
  'body_strong_lg1_serif_accent4_dark' |
  'body_strong_lg1_serif_accent4_darker' |
  'body_strong_lg1_serif_accent4_darkest' |
  'body_strong_lg1_serif' |
  'body_strong_lg1_white' |
  'body_strong_lg1_black' |
  'body_strong_lg1_gray' |
  'body_strong_lg1_green_2' |
  'body_strong_lg1_dark_gray' |
  'body_strong_lg1_dark_gray_2' |
  'body_strong_lg1_error' |
  'body_strong_lg1_warning' |
  'body_strong_lg1_success' |
  'body_strong_lg1_primary' |
  'body_strong_lg1_primary_lightest' |
  'body_strong_lg1_primary_lighter' |
  'body_strong_lg1_primary_light' |
  'body_strong_lg1_primary_dark' |
  'body_strong_lg1_primary_darker' |
  'body_strong_lg1_primary_darkest' |
  'body_strong_lg1_secondary' |
  'body_strong_lg1_secondary_lightest' |
  'body_strong_lg1_secondary_lighter' |
  'body_strong_lg1_secondary_light' |
  'body_strong_lg1_secondary_dark' |
  'body_strong_lg1_secondary_darker' |
  'body_strong_lg1_secondary_darkest' |
  'body_strong_lg1_accent1' |
  'body_strong_lg1_accent1_lightest' |
  'body_strong_lg1_accent1_lighter' |
  'body_strong_lg1_accent1_light' |
  'body_strong_lg1_accent1_dark' |
  'body_strong_lg1_accent1_darker' |
  'body_strong_lg1_accent1_darkest' |
  'body_strong_lg1_accent2' |
  'body_strong_lg1_accent2_lightest' |
  'body_strong_lg1_accent2_lighter' |
  'body_strong_lg1_accent2_light' |
  'body_strong_lg1_accent2_dark' |
  'body_strong_lg1_accent2_darker' |
  'body_strong_lg1_accent2_darkest' |
  'body_strong_lg1_accent3' |
  'body_strong_lg1_accent3_lightest' |
  'body_strong_lg1_accent3_lighter' |
  'body_strong_lg1_accent3_light' |
  'body_strong_lg1_accent3_dark' |
  'body_strong_lg1_accent3_darker' |
  'body_strong_lg1_accent3_darkest' |
  'body_strong_lg1_accent4' |
  'body_strong_lg1_accent4_lightest' |
  'body_strong_lg1_accent4_lighter' |
  'body_strong_lg1_accent4_light' |
  'body_strong_lg1_accent4_dark' |
  'body_strong_lg1_accent4_darker' |
  'body_strong_lg1_accent4_darkest' |
  'body_strong_lg1' |
  'body_strong_lg_serif_white' |
  'body_strong_lg_serif_black' |
  'body_strong_lg_serif_gray' |
  'body_strong_lg_serif_green_2' |
  'body_strong_lg_serif_dark_gray' |
  'body_strong_lg_serif_dark_gray_2' |
  'body_strong_lg_serif_error' |
  'body_strong_lg_serif_warning' |
  'body_strong_lg_serif_success' |
  'body_strong_lg_serif_primary' |
  'body_strong_lg_serif_primary_lightest' |
  'body_strong_lg_serif_primary_lighter' |
  'body_strong_lg_serif_primary_light' |
  'body_strong_lg_serif_primary_dark' |
  'body_strong_lg_serif_primary_darker' |
  'body_strong_lg_serif_primary_darkest' |
  'body_strong_lg_serif_secondary' |
  'body_strong_lg_serif_secondary_lightest' |
  'body_strong_lg_serif_secondary_lighter' |
  'body_strong_lg_serif_secondary_light' |
  'body_strong_lg_serif_secondary_dark' |
  'body_strong_lg_serif_secondary_darker' |
  'body_strong_lg_serif_secondary_darkest' |
  'body_strong_lg_serif_accent1' |
  'body_strong_lg_serif_accent1_lightest' |
  'body_strong_lg_serif_accent1_lighter' |
  'body_strong_lg_serif_accent1_light' |
  'body_strong_lg_serif_accent1_dark' |
  'body_strong_lg_serif_accent1_darker' |
  'body_strong_lg_serif_accent1_darkest' |
  'body_strong_lg_serif_accent2' |
  'body_strong_lg_serif_accent2_lightest' |
  'body_strong_lg_serif_accent2_lighter' |
  'body_strong_lg_serif_accent2_light' |
  'body_strong_lg_serif_accent2_dark' |
  'body_strong_lg_serif_accent2_darker' |
  'body_strong_lg_serif_accent2_darkest' |
  'body_strong_lg_serif_accent3' |
  'body_strong_lg_serif_accent3_lightest' |
  'body_strong_lg_serif_accent3_lighter' |
  'body_strong_lg_serif_accent3_light' |
  'body_strong_lg_serif_accent3_dark' |
  'body_strong_lg_serif_accent3_darker' |
  'body_strong_lg_serif_accent3_darkest' |
  'body_strong_lg_serif_accent4' |
  'body_strong_lg_serif_accent4_lightest' |
  'body_strong_lg_serif_accent4_lighter' |
  'body_strong_lg_serif_accent4_light' |
  'body_strong_lg_serif_accent4_dark' |
  'body_strong_lg_serif_accent4_darker' |
  'body_strong_lg_serif_accent4_darkest' |
  'body_strong_lg_serif' |
  'body_strong_lg_white' |
  'body_strong_lg_black' |
  'body_strong_lg_gray' |
  'body_strong_lg_green_2' |
  'body_strong_lg_dark_gray' |
  'body_strong_lg_dark_gray_2' |
  'body_strong_lg_error' |
  'body_strong_lg_warning' |
  'body_strong_lg_success' |
  'body_strong_lg_primary' |
  'body_strong_lg_primary_lightest' |
  'body_strong_lg_primary_lighter' |
  'body_strong_lg_primary_light' |
  'body_strong_lg_primary_dark' |
  'body_strong_lg_primary_darker' |
  'body_strong_lg_primary_darkest' |
  'body_strong_lg_secondary' |
  'body_strong_lg_secondary_lightest' |
  'body_strong_lg_secondary_lighter' |
  'body_strong_lg_secondary_light' |
  'body_strong_lg_secondary_dark' |
  'body_strong_lg_secondary_darker' |
  'body_strong_lg_secondary_darkest' |
  'body_strong_lg_accent1' |
  'body_strong_lg_accent1_lightest' |
  'body_strong_lg_accent1_lighter' |
  'body_strong_lg_accent1_light' |
  'body_strong_lg_accent1_dark' |
  'body_strong_lg_accent1_darker' |
  'body_strong_lg_accent1_darkest' |
  'body_strong_lg_accent2' |
  'body_strong_lg_accent2_lightest' |
  'body_strong_lg_accent2_lighter' |
  'body_strong_lg_accent2_light' |
  'body_strong_lg_accent2_dark' |
  'body_strong_lg_accent2_darker' |
  'body_strong_lg_accent2_darkest' |
  'body_strong_lg_accent3' |
  'body_strong_lg_accent3_lightest' |
  'body_strong_lg_accent3_lighter' |
  'body_strong_lg_accent3_light' |
  'body_strong_lg_accent3_dark' |
  'body_strong_lg_accent3_darker' |
  'body_strong_lg_accent3_darkest' |
  'body_strong_lg_accent4' |
  'body_strong_lg_accent4_lightest' |
  'body_strong_lg_accent4_lighter' |
  'body_strong_lg_accent4_light' |
  'body_strong_lg_accent4_dark' |
  'body_strong_lg_accent4_darker' |
  'body_strong_lg_accent4_darkest' |
  'body_strong_lg' |
  'body_strong_serif_white' |
  'body_strong_serif_black' |
  'body_strong_serif_gray' |
  'body_strong_serif_green_2' |
  'body_strong_serif_dark_gray' |
  'body_strong_serif_dark_gray_2' |
  'body_strong_serif_error' |
  'body_strong_serif_warning' |
  'body_strong_serif_success' |
  'body_strong_serif_primary' |
  'body_strong_serif_primary_lightest' |
  'body_strong_serif_primary_lighter' |
  'body_strong_serif_primary_light' |
  'body_strong_serif_primary_dark' |
  'body_strong_serif_primary_darker' |
  'body_strong_serif_primary_darkest' |
  'body_strong_serif_secondary' |
  'body_strong_serif_secondary_lightest' |
  'body_strong_serif_secondary_lighter' |
  'body_strong_serif_secondary_light' |
  'body_strong_serif_secondary_dark' |
  'body_strong_serif_secondary_darker' |
  'body_strong_serif_secondary_darkest' |
  'body_strong_serif_accent1' |
  'body_strong_serif_accent1_lightest' |
  'body_strong_serif_accent1_lighter' |
  'body_strong_serif_accent1_light' |
  'body_strong_serif_accent1_dark' |
  'body_strong_serif_accent1_darker' |
  'body_strong_serif_accent1_darkest' |
  'body_strong_serif_accent2' |
  'body_strong_serif_accent2_lightest' |
  'body_strong_serif_accent2_lighter' |
  'body_strong_serif_accent2_light' |
  'body_strong_serif_accent2_dark' |
  'body_strong_serif_accent2_darker' |
  'body_strong_serif_accent2_darkest' |
  'body_strong_serif_accent3' |
  'body_strong_serif_accent3_lightest' |
  'body_strong_serif_accent3_lighter' |
  'body_strong_serif_accent3_light' |
  'body_strong_serif_accent3_dark' |
  'body_strong_serif_accent3_darker' |
  'body_strong_serif_accent3_darkest' |
  'body_strong_serif_accent4' |
  'body_strong_serif_accent4_lightest' |
  'body_strong_serif_accent4_lighter' |
  'body_strong_serif_accent4_light' |
  'body_strong_serif_accent4_dark' |
  'body_strong_serif_accent4_darker' |
  'body_strong_serif_accent4_darkest' |
  'body_strong_serif' |
  'body_strong_white' |
  'body_strong_black' |
  'body_strong_gray' |
  'body_strong_green_2' |
  'body_strong_dark_gray' |
  'body_strong_dark_gray_2' |
  'body_strong_error' |
  'body_strong_warning' |
  'body_strong_success' |
  'body_strong_primary' |
  'body_strong_primary_lightest' |
  'body_strong_primary_lighter' |
  'body_strong_primary_light' |
  'body_strong_primary_dark' |
  'body_strong_primary_darker' |
  'body_strong_primary_darkest' |
  'body_strong_secondary' |
  'body_strong_secondary_lightest' |
  'body_strong_secondary_lighter' |
  'body_strong_secondary_light' |
  'body_strong_secondary_dark' |
  'body_strong_secondary_darker' |
  'body_strong_secondary_darkest' |
  'body_strong_accent1' |
  'body_strong_accent1_lightest' |
  'body_strong_accent1_lighter' |
  'body_strong_accent1_light' |
  'body_strong_accent1_dark' |
  'body_strong_accent1_darker' |
  'body_strong_accent1_darkest' |
  'body_strong_accent2' |
  'body_strong_accent2_lightest' |
  'body_strong_accent2_lighter' |
  'body_strong_accent2_light' |
  'body_strong_accent2_dark' |
  'body_strong_accent2_darker' |
  'body_strong_accent2_darkest' |
  'body_strong_accent3' |
  'body_strong_accent3_lightest' |
  'body_strong_accent3_lighter' |
  'body_strong_accent3_light' |
  'body_strong_accent3_dark' |
  'body_strong_accent3_darker' |
  'body_strong_accent3_darkest' |
  'body_strong_accent4' |
  'body_strong_accent4_lightest' |
  'body_strong_accent4_lighter' |
  'body_strong_accent4_light' |
  'body_strong_accent4_dark' |
  'body_strong_accent4_darker' |
  'body_strong_accent4_darkest' |
  'body_strong' |
  'body_strong_sm_serif_white' |
  'body_strong_sm_serif_black' |
  'body_strong_sm_serif_gray' |
  'body_strong_sm_serif_green_2' |
  'body_strong_sm_serif_dark_gray' |
  'body_strong_sm_serif_dark_gray_2' |
  'body_strong_sm_serif_error' |
  'body_strong_sm_serif_warning' |
  'body_strong_sm_serif_success' |
  'body_strong_sm_serif_primary' |
  'body_strong_sm_serif_primary_lightest' |
  'body_strong_sm_serif_primary_lighter' |
  'body_strong_sm_serif_primary_light' |
  'body_strong_sm_serif_primary_dark' |
  'body_strong_sm_serif_primary_darker' |
  'body_strong_sm_serif_primary_darkest' |
  'body_strong_sm_serif_secondary' |
  'body_strong_sm_serif_secondary_lightest' |
  'body_strong_sm_serif_secondary_lighter' |
  'body_strong_sm_serif_secondary_light' |
  'body_strong_sm_serif_secondary_dark' |
  'body_strong_sm_serif_secondary_darker' |
  'body_strong_sm_serif_secondary_darkest' |
  'body_strong_sm_serif_accent1' |
  'body_strong_sm_serif_accent1_lightest' |
  'body_strong_sm_serif_accent1_lighter' |
  'body_strong_sm_serif_accent1_light' |
  'body_strong_sm_serif_accent1_dark' |
  'body_strong_sm_serif_accent1_darker' |
  'body_strong_sm_serif_accent1_darkest' |
  'body_strong_sm_serif_accent2' |
  'body_strong_sm_serif_accent2_lightest' |
  'body_strong_sm_serif_accent2_lighter' |
  'body_strong_sm_serif_accent2_light' |
  'body_strong_sm_serif_accent2_dark' |
  'body_strong_sm_serif_accent2_darker' |
  'body_strong_sm_serif_accent2_darkest' |
  'body_strong_sm_serif_accent3' |
  'body_strong_sm_serif_accent3_lightest' |
  'body_strong_sm_serif_accent3_lighter' |
  'body_strong_sm_serif_accent3_light' |
  'body_strong_sm_serif_accent3_dark' |
  'body_strong_sm_serif_accent3_darker' |
  'body_strong_sm_serif_accent3_darkest' |
  'body_strong_sm_serif_accent4' |
  'body_strong_sm_serif_accent4_lightest' |
  'body_strong_sm_serif_accent4_lighter' |
  'body_strong_sm_serif_accent4_light' |
  'body_strong_sm_serif_accent4_dark' |
  'body_strong_sm_serif_accent4_darker' |
  'body_strong_sm_serif_accent4_darkest' |
  'body_strong_sm_serif' |
  'body_strong_sm_white' |
  'body_strong_sm_black' |
  'body_strong_sm_gray' |
  'body_strong_sm_green_2' |
  'body_strong_sm_dark_gray' |
  'body_strong_sm_dark_gray_2' |
  'body_strong_sm_error' |
  'body_strong_sm_warning' |
  'body_strong_sm_success' |
  'body_strong_sm_primary' |
  'body_strong_sm_primary_lightest' |
  'body_strong_sm_primary_lighter' |
  'body_strong_sm_primary_light' |
  'body_strong_sm_primary_dark' |
  'body_strong_sm_primary_darker' |
  'body_strong_sm_primary_darkest' |
  'body_strong_sm_secondary' |
  'body_strong_sm_secondary_lightest' |
  'body_strong_sm_secondary_lighter' |
  'body_strong_sm_secondary_light' |
  'body_strong_sm_secondary_dark' |
  'body_strong_sm_secondary_darker' |
  'body_strong_sm_secondary_darkest' |
  'body_strong_sm_accent1' |
  'body_strong_sm_accent1_lightest' |
  'body_strong_sm_accent1_lighter' |
  'body_strong_sm_accent1_light' |
  'body_strong_sm_accent1_dark' |
  'body_strong_sm_accent1_darker' |
  'body_strong_sm_accent1_darkest' |
  'body_strong_sm_accent2' |
  'body_strong_sm_accent2_lightest' |
  'body_strong_sm_accent2_lighter' |
  'body_strong_sm_accent2_light' |
  'body_strong_sm_accent2_dark' |
  'body_strong_sm_accent2_darker' |
  'body_strong_sm_accent2_darkest' |
  'body_strong_sm_accent3' |
  'body_strong_sm_accent3_lightest' |
  'body_strong_sm_accent3_lighter' |
  'body_strong_sm_accent3_light' |
  'body_strong_sm_accent3_dark' |
  'body_strong_sm_accent3_darker' |
  'body_strong_sm_accent3_darkest' |
  'body_strong_sm_accent4' |
  'body_strong_sm_accent4_lightest' |
  'body_strong_sm_accent4_lighter' |
  'body_strong_sm_accent4_light' |
  'body_strong_sm_accent4_dark' |
  'body_strong_sm_accent4_darker' |
  'body_strong_sm_accent4_darkest' |
  'body_strong_sm' |
  'body_strong_xs_serif_white' |
  'body_strong_xs_serif_black' |
  'body_strong_xs_serif_gray' |
  'body_strong_xs_serif_green_2' |
  'body_strong_xs_serif_dark_gray' |
  'body_strong_xs_serif_dark_gray_2' |
  'body_strong_xs_serif_error' |
  'body_strong_xs_serif_warning' |
  'body_strong_xs_serif_success' |
  'body_strong_xs_serif_primary' |
  'body_strong_xs_serif_primary_lightest' |
  'body_strong_xs_serif_primary_lighter' |
  'body_strong_xs_serif_primary_light' |
  'body_strong_xs_serif_primary_dark' |
  'body_strong_xs_serif_primary_darker' |
  'body_strong_xs_serif_primary_darkest' |
  'body_strong_xs_serif_secondary' |
  'body_strong_xs_serif_secondary_lightest' |
  'body_strong_xs_serif_secondary_lighter' |
  'body_strong_xs_serif_secondary_light' |
  'body_strong_xs_serif_secondary_dark' |
  'body_strong_xs_serif_secondary_darker' |
  'body_strong_xs_serif_secondary_darkest' |
  'body_strong_xs_serif_accent1' |
  'body_strong_xs_serif_accent1_lightest' |
  'body_strong_xs_serif_accent1_lighter' |
  'body_strong_xs_serif_accent1_light' |
  'body_strong_xs_serif_accent1_dark' |
  'body_strong_xs_serif_accent1_darker' |
  'body_strong_xs_serif_accent1_darkest' |
  'body_strong_xs_serif_accent2' |
  'body_strong_xs_serif_accent2_lightest' |
  'body_strong_xs_serif_accent2_lighter' |
  'body_strong_xs_serif_accent2_light' |
  'body_strong_xs_serif_accent2_dark' |
  'body_strong_xs_serif_accent2_darker' |
  'body_strong_xs_serif_accent2_darkest' |
  'body_strong_xs_serif_accent3' |
  'body_strong_xs_serif_accent3_lightest' |
  'body_strong_xs_serif_accent3_lighter' |
  'body_strong_xs_serif_accent3_light' |
  'body_strong_xs_serif_accent3_dark' |
  'body_strong_xs_serif_accent3_darker' |
  'body_strong_xs_serif_accent3_darkest' |
  'body_strong_xs_serif_accent4' |
  'body_strong_xs_serif_accent4_lightest' |
  'body_strong_xs_serif_accent4_lighter' |
  'body_strong_xs_serif_accent4_light' |
  'body_strong_xs_serif_accent4_dark' |
  'body_strong_xs_serif_accent4_darker' |
  'body_strong_xs_serif_accent4_darkest' |
  'body_strong_xs_serif' |
  'body_strong_xs_white' |
  'body_strong_xs_black' |
  'body_strong_xs_gray' |
  'body_strong_xs_green_2' |
  'body_strong_xs_dark_gray' |
  'body_strong_xs_dark_gray_2' |
  'body_strong_xs_error' |
  'body_strong_xs_warning' |
  'body_strong_xs_success' |
  'body_strong_xs_primary' |
  'body_strong_xs_primary_lightest' |
  'body_strong_xs_primary_lighter' |
  'body_strong_xs_primary_light' |
  'body_strong_xs_primary_dark' |
  'body_strong_xs_primary_darker' |
  'body_strong_xs_primary_darkest' |
  'body_strong_xs_secondary' |
  'body_strong_xs_secondary_lightest' |
  'body_strong_xs_secondary_lighter' |
  'body_strong_xs_secondary_light' |
  'body_strong_xs_secondary_dark' |
  'body_strong_xs_secondary_darker' |
  'body_strong_xs_secondary_darkest' |
  'body_strong_xs_accent1' |
  'body_strong_xs_accent1_lightest' |
  'body_strong_xs_accent1_lighter' |
  'body_strong_xs_accent1_light' |
  'body_strong_xs_accent1_dark' |
  'body_strong_xs_accent1_darker' |
  'body_strong_xs_accent1_darkest' |
  'body_strong_xs_accent2' |
  'body_strong_xs_accent2_lightest' |
  'body_strong_xs_accent2_lighter' |
  'body_strong_xs_accent2_light' |
  'body_strong_xs_accent2_dark' |
  'body_strong_xs_accent2_darker' |
  'body_strong_xs_accent2_darkest' |
  'body_strong_xs_accent3' |
  'body_strong_xs_accent3_lightest' |
  'body_strong_xs_accent3_lighter' |
  'body_strong_xs_accent3_light' |
  'body_strong_xs_accent3_dark' |
  'body_strong_xs_accent3_darker' |
  'body_strong_xs_accent3_darkest' |
  'body_strong_xs_accent4' |
  'body_strong_xs_accent4_lightest' |
  'body_strong_xs_accent4_lighter' |
  'body_strong_xs_accent4_light' |
  'body_strong_xs_accent4_dark' |
  'body_strong_xs_accent4_darker' |
  'body_strong_xs_accent4_darkest' |
  'body_strong_xs';

export type TypographyCustomVariantTypes = Record<TypographyCustomVariants, true>;