import { Box, Grid, Typography } from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import GLButton from "components/v3/sharedComponents/GLButton";
import HelpModal from "./HelpModal";
import { COLORS } from "components/v3/Theme/colors";
import ClearPageCornerArtLayout from "components/v3/layouts/ClearPageCornerArtLayout";

const RegistrationInstructions: React.FC = () => {
  const { loginWithRedirect } = useAuth0();
  const [contactModalOpen, setContactModalOpen] = useState<boolean>(false);
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const possibleAccessCode: string | null = searchParams.get("accessCode");

  return (
    <ClearPageCornerArtLayout>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        sx={{
          height: "100%",
          paddingBottom: "64px"
        }}
      >
        <HelpModal
          isOpen={contactModalOpen}
          onClose={() => setContactModalOpen(false)}
        />
        <Grid item xs={11}>
          <Typography
            variant="h2_primary"
            sx={{
              marginBottom: "20px",
              textAlign: "center",
              marginTop: "90px"
            }}
          >
            Welcome to Genomic Life!
          </Typography>
        </Grid>
        <Grid item xs={11}>
          <Typography
            variant="h6"
            sx={{
              textAlign: "center"
            }}
          >
            Before you continue, please have the following information ready:
          </Typography>
        </Grid>
        <Grid item container xs={12} justifyContent="center">
          <Grid
            item
            xs={11}
            sm={8}
            md={6}
            lg={5}
            sx={{
              backgroundColor: "#FFFFFF",
              borderRadius: "16px",
              padding: "35px 15px 10px 45px",
              margin: "40px 0",
              boxShadow: "0px 0px 11px 0px #0000000D"
            }}
          >
            <Grid item xs={11} sx={{ marginBottom: "40px" }}>
              <Grid item sx={{ marginBottom: "10px" }}>
                <Typography variant="h5">
                  1. Look for your access code
                </Typography>
              </Grid>
              <Grid
                item
                sx={{
                  paddingLeft: "26px",
                  "& p": {
                    lineHeight: "16.8px",
                    marginBottom: "15px"
                  }
                }}
              >
                <Typography variant="body">
                  You should have received an access code. Please check your
                  Genomic Life Welcome Email for it. The welcome email would
                  have been sent to the email address provided during
                  enrollment.
                </Typography>
                <Typography
                  sx={{
                    color: COLORS.SUCCESS
                  }}
                >
                  Don't have your access code?{" "}
                  <Box
                    sx={{
                      textDecoration: "underline",
                      cursor: "pointer",
                      display: "inline"
                    }}
                    onClick={() => setContactModalOpen(true)}
                  >
                    Reach out!
                  </Box>
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={11} sx={{ marginBottom: "40px" }}>
              <Grid item sx={{ marginBottom: "10px" }}>
                <Typography variant="h5">2. Keep your email ready</Typography>
              </Grid>
              <Grid
                item
                sx={{
                  paddingLeft: "26px",
                  "& p": {
                    lineHeight: "16.8px",
                    marginBottom: "15px"
                  }
                }}
              >
                <Typography variant="body">
                  For your security and privacy, we’ll need to authenticate via
                  email.
                </Typography>
              </Grid>
              <Grid
                item
                sx={{
                  paddingLeft: "26px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "row",
                  marginTop: "30px",
                  marginBottom: "20px"
                }}
              >
                <Box
                  sx={{
                    float: "left",
                    width: "25%",
                    paddingTop: "0px",
                    marginRight: "20px",
                    height: "1px",
                    borderBottom: "1px solid #828282"
                  }}
                />
                <Typography
                  sx={{
                    paddingTop: "2px",
                    textAlign: "center",
                    color: "#828282",
                    width: "290px"
                  }}
                >
                  Please make sure that
                </Typography>
                <Box
                  sx={{
                    float: "right",
                    width: "25%",
                    paddingTop: "0px",
                    marginLeft: "20px",
                    height: "1px",
                    borderBottom: "1px solid #828282"
                  }}
                />
              </Grid>
              <Grid item sx={{ paddingLeft: "26px" }}>
                <Typography variant="body">
                  You are enrolled in a Genomic Life membership. This was
                  offered to you by your employer at open enrollment.
                </Typography>
                {possibleAccessCode && (
                  <Typography>{possibleAccessCode}</Typography>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <GLButton
          buttonType="primary"
          text={"Continue"}
          onClick={() =>
            loginWithRedirect({
              authorizationParams: {
                screen_hint: "signup"
              }
            })
          }
        />
      </Grid>
    </ClearPageCornerArtLayout>
  );
};

export default RegistrationInstructions;
