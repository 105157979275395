import React, { Fragment } from "react";
import { Grid, Typography, Button } from "@mui/material";
import { Close } from "@mui/icons-material";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { PATHS } from "../../../../../../constants/pathConstants";
import UnSavedChangeDialogStyles from "./UnSavedChangeDialog.styles";
import Translate from "../../../../../../translate/Translate";
import { updateConnection } from "../../../../../../store/reducers/connection/ConnectionsInformationSlice";

const UnSavedChangeDialog = (props) => {
  const { handleClose, connection, firstTime } = props;
  const classes = UnSavedChangeDialogStyles();
  const history = useHistory();
  const dispatchReducer = useDispatch();

  const deleteConnectionHandler = () => {
    history.push(PATHS.ACCOUNT_MANAGEMENT_CONNECTIONS, {
      //state: { type: "delete" },
    });
  };
  const saveChangesHandler = () => {
    dispatchReducer(updateConnection(connection));
    history.push(PATHS.ACCOUNT_MANAGEMENT_CONNECTIONS, {
      type: "edit"
    });
  };

  return (
    <Fragment>
      <Grid container className={classes.modalContainer} id="modal-container">
        <Grid container className={classes.modalTitleContainer}>
          <Grid item xs={10}>
            <Typography variant="deprecated_h5" className={classes.title}>
              <Translate text="Are you sure you want to leave without saving changes?" />
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Close onClick={handleClose} className={classes.icon} />
          </Grid>
        </Grid>
        <Grid item className={classes.modalBodyContainer}>
          <Grid item className={classes.boxInfo}>
            <Typography variant="subtitle" className={classes.deleteInfo}>
              <Translate
                text={`${
                  firstTime
                    ? "By leaving this page the connection’s information will be deleted and lost forever."
                    : "You’ve made some changes on this page and haven’t saved the information."
                }`}
              />
            </Typography>
          </Grid>
        </Grid>
        <Grid container className={classes.modalFooterContainer}>
          <Button
            id="confirm"
            onClick={firstTime ? deleteConnectionHandler : handleClose}
            className={classes.removeBtn}
          >
            <Translate
              text={`${firstTime ? "DELETE CONNECTION" : "STAY ON PAGE"}`}
            />
          </Button>
          <Button
            id="confirm"
            color="secondary"
            variant="contained"
            onClick={firstTime ? handleClose : saveChangesHandler}
          >
            <Translate
              text={`${firstTime ? "STAY ON PAGE" : "SAVE & LEAVE"}`}
            />
          </Button>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default UnSavedChangeDialog;
