import { useCallback, useState } from "react";
import { Box } from "@mui/material";
import ReactPlayer, { VimeoPlayerProps } from "react-player/vimeo";
import ModalWrapper from "components/v2/sharedComponents/Modals/ModalComponents/ModalWrapper";

type VideoProps = {
  modal?: boolean;
  url: string;
} & VimeoPlayerProps;

const Video: React.FC<React.PropsWithChildren<VideoProps>> = ({
  modal = false,
  url,
  ...remainingProps
}) => {
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);
  const [, /*isPlaying,*/ setIsPlaying] = useState(false);
  const handleCloseVideoModal = useCallback(() => {
    setIsVideoModalOpen(false);
    setIsPlaying(false);
  }, []);
  const handleVideoClick = useCallback(() => {
    setIsVideoModalOpen(!isVideoModalOpen);
    setIsPlaying(isVideoModalOpen);
  }, [isVideoModalOpen]);
  const handleVideoPause = useCallback(() => {
    setIsPlaying(false);
  }, [isVideoModalOpen]);
  const videoComponent = (
    <ReactPlayer
      controls
      onPause={handleVideoPause}
      url={url}
      {...remainingProps}
    />
  );

  if (!modal) {
    return videoComponent;
  }

  return (
    <>
      {!isVideoModalOpen && (
        <Box
          sx={{ cursor: "pointer", height: "100%", width: "100%" }}
          onClick={handleVideoClick}
        >
          <Box height="100%" width="100%" sx={{ pointerEvents: "none" }}>
            {videoComponent}
          </Box>
        </Box>
      )}
      <ModalWrapper
        isOpen={isVideoModalOpen}
        onClickOutsideModal={() => {
          handleCloseVideoModal();
        }}
      >
        {videoComponent}
      </ModalWrapper>
    </>
  );
};

export default Video;
