import { Box, Typography } from "@mui/material";
// import { useTranslation } from "react-i18next";
import { COLORS } from "components/v3/Theme/colors";
import QuestionImg from "assets/images/Question.svg";
import SupportMapImg from "assets/images/SupportMap.svg";
import TodoListArrowImg from "assets/images/TodoListArrow.svg";

const OverviewSection = () => {
  // const { t } = useTranslation();

  return (
    <Box
      sx={{
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "column",
        gap: {
          xs: "15px",
          md: "30px"
        },
        paddingX: {
          xs: "20px",
          md: "60px"
        },
        paddingY: {
          xs: "30px",
          md: 0
        },
        position: "relative"
      }}
    >
      <Box
        sx={{
          display: "flex",
          flex: 1,
          flexDirection: "column",
          gap: "25px"
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "15px"
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "5px"
            }}
          >
            <Typography
              sx={{
                background: `linear-gradient(to right, ${COLORS.ACCENT3}, ${COLORS.PRIMARY})`,
                typography: {
                  xs: "body_xs_serif",
                  md: "h6_serif"
                },
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent"
              }}
            >
              Guiding you to the actions and resources that matter
            </Typography>
            <Typography
              id="getting_started"
              sx={{ typography: { xs: "h5_black", md: "h4_black" } }}
            >
              How to get started
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: {
                xs: "column",
                md: "row"
              },
              gap: {
                xs: "15px",
                md: 8
              }
            }}
          >
            <Box
              sx={{
                display: "flex",
                flex: 1,
                flexDirection: {
                  xs: "row",
                  md: "column"
                },
                gap: {
                  xs: "15px",
                  md: 4
                }
              }}
            >
              <>
                <Box
                  component="img"
                  sx={{
                    height: {
                      xs: "auto",
                      md: "125px"
                    },
                    objectFit: "contain",
                    width: {
                      xs: "65px",
                      md: "124px"
                    }
                  }}
                  alt=""
                  src={QuestionImg}
                />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: {
                      xs: "15px",
                      md: 2
                    }
                  }}
                >
                  <Typography variant="body_strong_black">
                    {"Answer a few questions"}
                  </Typography>
                  <Typography variant="body_sm_dark_gray">
                    {
                      "Provide information about your health history so we can tailor your program."
                    }
                  </Typography>
                </Box>
              </>
            </Box>
            <Box
              sx={{
                display: "flex",
                flex: 1,
                flexDirection: {
                  xs: "row",
                  md: "column"
                },
                gap: {
                  xs: "15px",
                  md: 4
                }
              }}
            >
              <>
                <Box
                  component="img"
                  sx={{
                    height: {
                      xs: "auto",
                      md: "125px"
                    },
                    objectFit: "contain",
                    width: {
                      xs: "65px",
                      md: "124px"
                    }
                  }}
                  alt=""
                  src={TodoListArrowImg}
                />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: {
                      xs: "15px",
                      md: 2
                    }
                  }}
                >
                  <Typography variant="body_strong_black">
                    {"Receive recommendations"}
                  </Typography>
                  <Typography variant="body_sm_dark_gray">
                    {
                      "Choose from easy-to-follow next steps for access to valuable services and resources."
                    }
                  </Typography>
                </Box>
              </>
            </Box>
            <Box
              sx={{
                display: "flex",
                flex: 1,
                flexDirection: {
                  xs: "row",
                  md: "column"
                },
                gap: {
                  xs: "15px",
                  md: 4
                }
              }}
            >
              <Box
                component="img"
                sx={{
                  height: {
                    xs: "auto",
                    md: "125px"
                  },
                  objectFit: "contain",
                  width: {
                    xs: "65px",
                    md: "124px"
                  }
                }}
                alt=""
                src={SupportMapImg}
              />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: {
                    xs: "15px",
                    md: 2
                  }
                }}
              >
                <>
                  <Typography variant="body_strong_black">
                    {"Ongoing support"}
                  </Typography>
                  <Typography variant="body_sm_dark_gray">
                    {
                      "We’re with you every step of the way, providing the guidance and support you need."
                    }
                  </Typography>
                </>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default OverviewSection;
