import React, { useState } from "react";
import { useSelector } from "react-redux";
import { selectUserPersonalInformation } from "../../../../store/reducers/user/UserAccountSlice";
import PageTitle from "../../../../utils/PageTitle";
import ModalComponent from "../../../sharedComponents/ModalComponent";
import MustBeAdultModal from "./MustBeAdultModal";
import PersonalInfoForm from "components/v3/forms/PersonalInfoForm";
import { Grid } from "@mui/material";

const OrderKitMemberInformation = (props) => {
  const { handleNext } = props;

  const [mustBeAdultModelIsOpen, setMustBeAdultModelIsOpen] = useState(false);
  PageTitle("Member Information");

  const memberInformation = useSelector(selectUserPersonalInformation);

  return (
    <div>
      <Grid container justifyContent={"center"}>
        <Grid item xs={12} sm={10}>
          <PersonalInfoForm
            onSubmitted={() => handleNext()}
            memberData={memberInformation}
          />
        </Grid>
      </Grid>

      <ModalComponent
        handleClose={() => {
          setMustBeAdultModelIsOpen(false);
        }}
        open={mustBeAdultModelIsOpen}
        body={
          <MustBeAdultModal
            handleClose={() => {
              setMustBeAdultModelIsOpen(false);
            }}
          />
        }
      />
    </div>
  );
};

export default OrderKitMemberInformation;
