import { makeStyles } from "@mui/styles";

const BreadcrumbStyles = makeStyles((theme) => ({
  breadcrumbContainer: {
    [theme.breakpoints.down("lg")]: {},
    [theme.breakpoints.up("md")]: {}
  },
  breadcrumbLink: {
    marginTop: "1rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    cursor: `pointer`,
    textDecoration: `none`,
    [theme.breakpoints.down("lg")]: {},
    [theme.breakpoints.between("md", "xl")]: {},
    [theme.breakpoints.up("lg")]: {}
  },
  breadcrumbText: {
    color: theme.palette.primary.link,
    marginTop: 4,
    marginRight: "1rem"
  },
  breadcrumbIcon: {
    color: theme.palette.primary.link,
    marginleft: "1rem",
    fontSize: 25
  }
}));

export default BreadcrumbStyles;
