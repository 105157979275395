import { makeStyles } from "@mui/styles";
import {
  COLOR_CONSTANTS,
  FLEX_CONSTANTS,
  GENERIC_CONSTANTS
} from "../../../constants/StyleConstants";

const ProductCardStyles = makeStyles((theme) => ({
  container: {
    display: FLEX_CONSTANTS.FLEX,
    flexDirection: FLEX_CONSTANTS.COLUMN,
    justifyContent: FLEX_CONSTANTS.FLEX_START
  },

  content: {
    display: FLEX_CONSTANTS.FLEX,
    flexDirection: FLEX_CONSTANTS.COLUMN,
    alignItems: FLEX_CONSTANTS.CENTER,
    justifyContent: FLEX_CONSTANTS.FLEX_START,
    marginTop: theme.paddings.lg,
    height: 240
  },
  bottomCardContainer: {},

  mainButton: {
    display: FLEX_CONSTANTS.FLEX,
    justifyContent: FLEX_CONSTANTS.CENTER,
    alignItems: FLEX_CONSTANTS.CENTER,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.white,
    borderRadius: "0 0 10px 10px",
    height: 50,
    cursor: GENERIC_CONSTANTS.POINTER,
    "&:hover": {
      opacity: 0.3
    }
  },
  buttonText: {
    ...theme.typography.deprecated_subtitle1,
    fontWeight: 400
  },
  mainButtonLink: {
    textDecoration: GENERIC_CONSTANTS.NONE
  },
  cardBody: {
    minWidth: 294,
    maxWidth: 294,
    minHeight: 310,
    maxHeight: 310,
    borderRadius: 10,
    backgroundColor: theme.palette.primary.white,
    border: theme.card.border,
    boxShadow: `0px 4px 10px rgba(0, 0, 0, 0.14)`
  },

  disabled: {
    color: theme.palette.secondary.gray_button,
    cursor: "default"
  },
  enabled: {
    color: theme.palette.primary.main,
    cursor: GENERIC_CONSTANTS.POINTER
  },
  image: {
    marginTop: 17
  },
  textCentered: {
    marginTop: 15,
    paddingLeft: 10,
    paddingRight: 10
  },
  cardLink: {
    ...theme.typography.deprecated_subtitle1,
    textDecoration: GENERIC_CONSTANTS.NONE,
    color: theme.palette.info.main,
    "&:hover": {
      textDecoration: GENERIC_CONSTANTS.NONE
    },
    marginTop: 5
  },
  checkIcon: {
    position: "absolute",
    marginTop: 10,
    marginLeft: 15
  },
  recommendedChip: {
    height: 0,
    position: "relative",
    top: -16,
    "& .MuiChip-root": {
      backgroundColor: COLOR_CONSTANTS.GENOMIC_LIFE_PRIMARY,
      color: "white"
    }
  },
  notRecommendedChip: {
    height: 0,
    position: "relative",
    top: -16,
    "& .MuiChip-root": {
      backgroundColor: COLOR_CONSTANTS.COLOR_GRAY_30,
      color: "black"
    }
  },
  buttonActionContainer: {
    cursor: GENERIC_CONSTANTS.POINTER
  },
  cardSelectedBody: {
    borderColor: COLOR_CONSTANTS.GENOMIC_LIFE_GREEN,
    boxShadow: `0px 4px 10px rgba(109, 176, 39, 0.5)`
  },
  descriptionTextContainer: {
    height: 100,
    textAlign: "center"
  },
  descriptionText: {
    ...theme.typography.deprecated_body2,
    color: COLOR_CONSTANTS.GENOMIC_LIFE_LIGHT_GRAY
  },
  infoButtonContainer: {
    marginTop: 22
  },
  boldChip: {
    ...theme.typography.deprecated_body2,
    fontWeight: 700
  }
}));

export default ProductCardStyles;
