import React from "react";
import { Grid, Typography } from "@mui/material";
import { PATHS } from "../../../../constants/pathConstants";
import Translate from "../../../../translate/Translate";
import Layout from "../../../layout/Layout";
import BackTo from "../../../sharedComponents/BackTo";
import CenteredContent from "../../../sharedComponents/CenteredContent";
import OptionCard from "../../../sharedComponents/OptionCard";
import SecurityMessage from "../../../sharedComponents/SecurityMessage";
import AccountManagementPrivacySecurityStyles from "./AccountManagementPrivacySecurity.styles";
import PageTitle from "../../../../utils/PageTitle";

const AccountManagementPrivacySecurityPage = (props) => {
  const classes = AccountManagementPrivacySecurityStyles();
  const optionCardElements = [
    {
      id: "res-pass",
      option: "Reset Password",
      redirectTo: PATHS.ACCOUNT_MANAGEMENT_RESET_PASSWORD
    },
    {
      id: "terms-serv",
      option: "Terms of Service",
      redirectTo: PATHS.ACCOUNT_MANAGEMENT_AGREEMENTS
    },
    {
      id: "mfa",
      option: "Multi-Factor Authentication",
      redirectTo: PATHS.ACCOUNT_MANAGEMENT_MFA
    }
  ];

  PageTitle("Privacy & Security");

  return (
    <Layout headerType="primary">
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={12} className={classes.backToContainer}>
          <BackTo text="Account Management" backTo={PATHS.ACCOUNT_MANAGEMENT} />
        </Grid>
        <Grid item xs={12}>
          <CenteredContent className={classes.privacyPolicyTitle}>
            <Typography variant="deprecated_h3">
              <Translate text="Privacy & Security" />
            </Typography>
          </CenteredContent>
        </Grid>

        <Grid item xs={12}>
          {optionCardElements.map((element) => (
            <OptionCard
              text={element.option}
              key={element.id}
              redirectTo={element.redirectTo}
            />
          ))}
        </Grid>
      </Grid>

      <CenteredContent className={classes.secureInformationContainer}>
        <SecurityMessage
          title="Your information is safe with us"
          desc="Genomic Life uses SSL encryption to protect your information."
        />
      </CenteredContent>
    </Layout>
  );
};

export default AccountManagementPrivacySecurityPage;
