import {
  COLOR_CONSTANTS,
  FONT_CONSTANTS
} from "../../constants/StyleConstants";
export const themeTypography = {
  deprecated_h1: {
    fontFamily: [FONT_CONSTANTS.FONT_FAMILY_DOMAIN_DISPLAY_SEMIBOLD].join(","),
    fontStyle: `normal`,
    fontSize: 60,
    lineHeight: `79px`,
    letterSpacing: `-1.5px`,
    color: COLOR_CONSTANTS.COLOR_TITLES
  },
  deprecated_h2: {
    fontFamily: [FONT_CONSTANTS.FONT_FAMILY_DOMAIN_DISPLAY_SEMIBOLD].join(","),
    fontSize: 34,
    fontStyle: `normal`,
    lineHeight: `45px`,
    letterSpacing: `0`,
    color: COLOR_CONSTANTS.COLOR_TITLES
  },
  deprecated_h3: {
    fontFamily: [FONT_CONSTANTS.FONT_FAMILY_DOMAIN_DISPLAY_SEMIBOLD].join(","),
    fontSize: 24,
    fontStyle: `normal`,
    lineHeight: `31px`,
    letterSpacing: `0`,
    color: COLOR_CONSTANTS.COLOR_TITLES
  },
  deprecated_h4: {
    fontFamily: [FONT_CONSTANTS.FONT_AVENIRLT_PRO_MEDIUM].join(","),
    fontStyle: `normal`,
    fontSize: 34,
    lineHeight: `41px`,
    letterSpacing: `0.25px`
  },
  deprecated_h5: {
    fontFamily: [FONT_CONSTANTS.FONT_AVENIRLT_PRO_MEDIUM].join(","),
    fontSize: 24,
    fontStyle: `normal`,
    lineHeight: `32px`,
    letterSpacing: `0`
  },
  deprecated_h5HeadlineBlack: {
    fontFamily: [FONT_CONSTANTS.FONT_AVENIRLT_PRO_BLACK].join(","),
    fontSize: 24,
    fontWeight: 800,
    lineHeight: `32px`,
    letterSpacing: `0`
  },
  deprecated_h6: {
    fontFamily: [FONT_CONSTANTS.FONT_AVENIRLT_PRO_MEDIUM].join(","),
    fontSize: 20,
    fontStyle: `normal`,
    lineHeight: `28px`,
    letterSpacing: `0.15px`
  },
  deprecated_h6HeadlineBlack: {
    fontFamily: [FONT_CONSTANTS.FONT_AVENIRLT_PRO_BLACK].join(","),
    fontSize: 20,
    fontWeight: 800,
    lineHeight: `31px`,
    letterSpacing: `0.15px`
  },
  deprecated_h6HeadlineHeavy: {
    fontFamily: [FONT_CONSTANTS.FONT_AVENIRLT_PRO_BLACK].join(","),
    fontStyle: `normal`,
    fontWeight: "bold",
    fontSize: 20,
    lineHeight: `28px`,
    letterSpacing: `0.15px`
  },
  deprecated_body1: {
    fontFamily: [FONT_CONSTANTS.FONT_AVENIRLT_PRO_BOOK].join(","),
    fontSize: 14,
    fontStyle: `normal`,
    lineHeight: `24px`,
    letterSpacing: `1%`
  },
  deprecated_body1Heavy: {
    fontFamily: [FONT_CONSTANTS.FONT_AVENIRLT_PRO_BOOK].join(","),
    fontWeight: 700,
    fontSize: 14,
    fontStyle: `normal`,
    lineHeight: `24px`,
    letterSpacing: `1%`
  },
  deprecated_bodyBlack: {
    fontFamily: [FONT_CONSTANTS.FONT_AVENIRLT_PRO_BLACK].join(","),
    fontWeight: 800,
    fontSize: 14,
    fontStyle: `normal`,
    lineHeight: `24px`,
    letterSpacing: `1%`
  },
  deprecated_body2: {
    fontFamily: [FONT_CONSTANTS.FONT_AVENIRLT_PRO_BOOK].join(","),
    fontSize: 12,
    fontStyle: `normal`,
    lineHeight: `20px`,
    letterSpacing: `0.5%`
  },
  deprecated_subtitleRegular: {
    fontFamily: [FONT_CONSTANTS.FONT_AVENIRLT_PRO_ROMAN].join(","),
    fontStyle: `normal`,
    fontWeight: `normal`,
    fontSize: 16,
    lineHeight: `24px`
  },
  deprecated_subtitleHeavy: {
    fontFamily: [FONT_CONSTANTS.FONT_AVENIRLT_PRO_HEAVY].join(","),
    fontStyle: `normal`,
    fontWeight: `700`,
    fontSize: 16,
    lineHeight: `24px`
  },
  deprecated_subtitle1: {
    fontFamily: [FONT_CONSTANTS.FONT_AVENIRLT_PRO_ROMAN].join(","),
    fontStyle: `normal`,
    fontWeight: `normal`,
    fontSize: 16,
    lineHeight: `24px`,
    letterSpacing: `0.1px`
  },
  deprecated_subtitle2: {
    fontFamily: [FONT_CONSTANTS.FONT_AVENIRLT_PRO_ROMAN].join(","),
    fontSize: 14,
    fontStyle: `normal`,
    lineHeight: `17px`
  },
  deprecated_surveyNormal: {
    fontFamily: [FONT_CONSTANTS.FONT_AVENIRLT_PRO_ROMAN].join(","),
    fontSize: 14,
    fontStyle: `normal`,
    lineHeight: `24px`,
    letterSpacing: "1%"
  },
  deprecated_surveyHeavy: {
    fontFamily: [FONT_CONSTANTS.FONT_AVENIRLT_PRO_HEAVY].join(","),
    fontSize: 14,
    fontStyle: `normal`,
    lineHeight: `24px`,
    letterSpacing: "1%"
  }
};
