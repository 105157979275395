import React, { useState, useEffect } from "react";
import { Grid, Typography, Box, Chip, Avatar } from "@mui/material";
import CenteredContent from "../CenteredContent";
import OptionCardStyles from "./OptionCard.styles";
import {
  KeyboardArrowDown,
  KeyboardArrowRight,
  KeyboardArrowUp
} from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";
import AddCircleIcon from "../../../assets/images/AddCircleIcon.svg";
import { useHistory } from "react-router";
import Translate from "../../../translate/Translate";
import OptionComponent from "../OptionComponent";
//Icons
import OutlineCheck from "../../../assets/images/OutlineCheck.svg";
import CheckCirleIcon from "../../../assets/images/CheckCirleIcon.svg";
import MailIcon from "@mui/icons-material/Mail";
import PrintIcon from "@mui/icons-material/Print";
import PillImageButton from "../PillImageButton";
import { useDispatch } from "react-redux";
import {
  setConnectionId,
  setUpdateConnection
} from "../../../store/reducers/connection/ConnectionsInformationSlice";
const OptionCard = (props) => {
  const {
    icon,
    text,
    subText,
    redirectTo,
    secondaryFunction,
    redirectState,
    chip,
    avatar,
    nameInitial,
    add,
    bigCard,
    isTransmitCard,
    contactInformation,
    parentHandler,
    isFaxInModes,
    isEmailInModes,
    connectionId
  } = props;

  const classes = OptionCardStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isFaxSelected, setIsFaxSelected] = useState(isFaxInModes);
  const [isEmailSelected, setIsEmailSelected] = useState(isEmailInModes);

  useEffect(() => {
    setIsFaxSelected(isFaxInModes);
    setIsEmailSelected(isEmailInModes);
  }, [isFaxInModes, isEmailInModes]);

  const collapseHandler = () => {
    setIsCollapsed(!isCollapsed);
  };
  const stateHandler = (handlerFunction, handlerState, type) => {
    if (handlerState) {
      parentHandler &&
        parentHandler(contactInformation.connectionId, [type], "REMOVE");
    } else {
      parentHandler &&
        parentHandler(contactInformation.connectionId, [type], "ADD");
    }
    handlerFunction(!handlerState);
  };

  const calculateColor = (name) => {
    if ("AaBbCcDdEeFf".includes(name.charAt(0))) {
      return { backgroundColor: "#C3E19B" };
    } else if ("GgHhIiJjKkLl".includes(name.charAt(0))) {
      return { backgroundColor: "#FF9E9E" };
    } else if ("MmNnOoPpQqRr".includes(name.charAt(0))) {
      return { backgroundColor: "#FFD957" };
    } else if ("SsTtWwXxYyZz".includes(name.charAt(0))) {
      return { backgroundColor: "#9FC5EC" };
    } else {
      return { backgroundColor: "purple" };
    }
  };

  const renderArrow = () => {
    if (isTransmitCard && secondaryFunction) {
      return (
        <Grid
          container
          alignContent="center"
          justifyContent="center"
          className={`editConnectionButton ${classes.editButton}`}
          onClick={() => secondaryFunction()}
        >
          <EditIcon color="primary" />
        </Grid>
      );
    }
    if (isTransmitCard && isCollapsed) {
      return <KeyboardArrowUp className={classes.iconFontSize} />;
    }
    if (isTransmitCard) {
      return <KeyboardArrowDown className={classes.iconFontSize} />;
    }
    return <KeyboardArrowRight className={classes.iconFontSize} />;
  };

  let optionCardValue = bigCard
    ? classes.optionBigCardContainer
    : classes.optionCardContainer;

  let xsValue = chip ? 2 : 1;
  let KeyboardAndCircleElement = add ? (
    <img src={AddCircleIcon} alt="Add Circle" />
  ) : (
    renderArrow()
  );

  const renderFaxOption = () => {
    return contactInformation.faxNumber ? (
      <OptionComponent
        onClick={() => stateHandler(setIsFaxSelected, isFaxSelected, "FAX")}
        isChecked={isFaxSelected}
        option={contactInformation.faxNumber}
        answer={
          <Translate
            text={
              contactInformation.faxNumber
                ? `Fax: ${contactInformation.faxNumber}`
                : null
            }
          />
        }
        icon={
          !isFaxSelected ? (
            <img alt="check Icon" src={OutlineCheck} />
          ) : (
            <img src={CheckCirleIcon} alt="check Icon" />
          )
        }
      />
    ) : null;
  };

  const renderEmailOption = () => {
    // This is false because we are not currently offering email as an option
    return false ? (
      <OptionComponent
        onClick={() =>
          stateHandler(setIsEmailSelected, isEmailSelected, "EMAIL")
        }
        isChecked={isEmailSelected}
        option={contactInformation.email}
        answer={<Translate text={contactInformation.email} />}
        icon={
          !isEmailSelected ? (
            <img alt="check Icon" src={OutlineCheck} />
          ) : (
            <img src={CheckCirleIcon} alt="check Icon" />
          )
        }
      />
    ) : null;
  };

  const renderContactInformation = () => {
    if (isTransmitCard && contactInformation && !isCollapsed) {
      return (
        <Grid
          item
          xs={12}
          style={{ display: "flex", flex: 1, marginBottom: 30 }}
        >
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Grid
              item
              xs={9}
              md={10}
              style={{ display: "flex", flex: 1, width: "90%" }}
            >
              {renderFaxOption()}
            </Grid>
            <Grid
              item
              xs={12}
              style={{ display: "flex", flex: 1, width: "90%" }}
            >
              {renderEmailOption()}
            </Grid>
          </Grid>
        </Grid>
      );
    }
    return null;
  };

  const renderContactButtonHandler = () => {
    if (isTransmitCard && contactInformation) {
      return (
        <div className={classes.contactIconContainer}>
          <Grid item xs={12}>
            <Grid item xs={12} md={6}>
              <Grid container style={{ marginTop: -40 }}>
                {false ? (
                  <Grid
                    item
                    xs={6}
                    onClick={() =>
                      stateHandler(setIsFaxSelected, isFaxSelected, "FAX")
                    }
                  >
                    <PillImageButton
                      isSelected={isFaxSelected}
                      icon={PrintIcon}
                    />
                  </Grid>
                ) : null}
                {false ? (
                  <Grid
                    item
                    xs={6}
                    onClick={() =>
                      stateHandler(setIsEmailSelected, isEmailSelected, "EMAIL")
                    }
                  >
                    <PillImageButton
                      isSelected={isEmailSelected}
                      icon={MailIcon}
                    />
                  </Grid>
                ) : null}
              </Grid>
            </Grid>
          </Grid>
        </div>
      );
    }
    return null;
  };

  const chipElement = chip ? (
    <Box className={classes.chipCard}>
      <Chip
        className={classes.chip}
        label={
          <Typography className={classes.chipCardText}>
            <Translate text="New!" />
          </Typography>
        }
      />
    </Box>
  ) : (
    KeyboardAndCircleElement
  );

  const renderIconSection = () => {
    return (
      <Grid
        item
        xs={12}
        className={`${
          redirectTo ? classes.clickableElement : null
        } ${optionCardValue}`}
        name={text}
        onClick={
          typeof redirectTo === "function"
            ? redirectTo
            : () =>
                history.push(redirectTo, {
                  account: true,
                  redirectState: redirectState ? redirectState : ""
                })
        }
      >
        <Grid item xs={3}>
          <CenteredContent className={classes.optionCardIconContainer}>
            {icon}
          </CenteredContent>
        </Grid>

        <Grid item xs={7} className={classes.optionCardTitleContainer}>
          <Typography
            align="left"
            variant="deprecated_subtitle2"
            className={classes.optionCardTitle}
          >
            <Translate text={text} />
          </Typography>
        </Grid>
        <Grid item xs={xsValue} className={classes.icon}>
          {chipElement}
        </Grid>
      </Grid>
    );
  };
  const renderAvatarSection = () => {
    return (
      <Grid
        item
        xs={12}
        className={`${
          redirectTo ? classes.clickableElement : null
        } ${optionCardValue}`}
        name={"Info"}
      >
        <Grid container direction="column" style={{ paddingTop: 15 }}>
          <Grid item xs={12}>
            <Grid container direction="row" alignItems="center">
              <Grid item xs={3} md={2}>
                <CenteredContent className={classes.optionCardIconContainer}>
                  <Avatar style={calculateColor(text)}>{nameInitial}</Avatar>
                </CenteredContent>
              </Grid>

              <Grid
                item
                xs={5}
                md={8}
                className={classes.optionCardTitleContainer}
                onClick={
                  typeof redirectTo === "function"
                    ? redirectTo
                    : () => {
                        dispatch(
                          setUpdateConnection({
                            status: true,
                            connectionId
                          })
                        );
                        dispatch(setConnectionId(connectionId));
                        history.push(redirectTo, {
                          account: true,
                          redirectState: redirectState ? redirectState : ""
                        });
                      }
                }
              >
                {renderContactButtonHandler()}
                <div
                  onClick={
                    isTransmitCard && secondaryFunction
                      ? () => {
                          secondaryFunction();
                        }
                      : null
                  }
                  style={isTransmitCard ? { paddingTop: 40 } : null}
                >
                  <Typography
                    align="left"
                    variant="deprecated_subtitle2"
                    className={classes.optionCardTitle}
                  >
                    <Translate text={text} />
                  </Typography>
                  <Typography
                    align="left"
                    variant="deprecated_subtitle2"
                    className={classes.optionCardSubTitle}
                  >
                    <Translate text={subText} />
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={2} md={xsValue} className={classes.icon}>
                <Grid
                  container
                  alignItems="flex-end"
                  onClick={isTransmitCard ? null : () => collapseHandler()}
                >
                  {renderArrow()}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {renderContactInformation()}
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid container justifyContent="center" alignItems="center" id={text}>
      {icon ? (
        renderIconSection()
      ) : avatar ? (
        renderAvatarSection()
      ) : (
        <Grid
          item
          xs={12}
          className={`${
            redirectTo ? classes.clickableElement : null
          } ${optionCardValue}`}
          onClick={redirectTo ? () => history.push(redirectTo) : null}
          name={text}
        >
          <Grid item xs={11} className={classes.optionCardNoIconTitleContainer}>
            <Typography
              align="left"
              variant="deprecated_subtitle2"
              className={classes.optionCardNotIconTitle}
            >
              <Translate text={text} />
            </Typography>
          </Grid>
          <Grid item xs={1} className={classes.secondIcon}>
            {renderArrow()}
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default OptionCard;
