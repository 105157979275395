import React from "react";
import GLSelect, { GLSelectProps } from "../GLSelect";
import { US_STATES } from "constants/generalConstants";

type GLSelectWithoutOptions = Omit<GLSelectProps, "options">;

const GLStateSelect: React.FC<GLSelectWithoutOptions> = ({
  label,
  id,
  tooltip,
  onChange,
  value,
  defaultValue,
  required,
  disabled
}) => {
  return (
    <GLSelect
      label={label}
      id={id}
      tooltip={tooltip}
      value={value}
      options={US_STATES}
      defaultValue={defaultValue}
      required={required}
      disabled={disabled}
      onChange={onChange}
    />
  );
};

export default GLStateSelect;
