import React, { useState, useEffect } from "react";
// MUI
import {
  Grid,
  Typography,
  MenuItem,
  InputLabel,
  Checkbox,
  Button,
  Divider,
  FormControlLabel,
  FormHelperText,
  TextField
} from "@mui/material";
// MUI-React-Validation
import {
  TextValidator,
  ValidatorForm,
  SelectValidator
} from "react-material-ui-form-validator";
import Translate from "../../../../../translate/Translate";
// import { inputStyle } from "./../../../../sharedComponents/FormHelper";
//Icons
import BlueTrashIcon from "../../../../../assets/images/BlueTrashIcon.svg";
import ConnectionDetailsPageStyles from "./ConnectionDetailsPage.styles";
import { LABELS } from "../../../../../constants/labelConstants";
import {
  validateAndDispatchUSNumber,
  isBlankOrNull
} from "../../../../../utils/utils";
import ReactInputMask from "react-input-mask";
import { useSelector } from "react-redux";
import { selectConnectionId } from "../../../../../store/reducers/connection/ConnectionsInformationSlice";
import { disableEnterKey } from "../../../../sharedComponents/FormHelper";

const ConnectionDetailForm = (props) => {
  const {
    formTitle,
    type,
    changeVariantValue,
    handleClick,
    handleTextChange,
    handleCheckboxChange,
    isNotMajorValidator,
    isPrimaryCare,
    errorValidForAuthorization,
    reverseButtons,
    deleteConnectionHandler,
    canDelete,
    isTransmissionSelection,
    handleShowModal,
    modalText,
    state,
    isSubmitDisabled
  } = props;
  const classes = ConnectionDetailsPageStyles();
  const hasValidSex = ["M", "F", "U", "I"].includes(state.sexAssignedAtBirth);
  const [phoneInputError, setPhoneNumberInputError] = useState(false);
  const [faxNumberInputError, setFaxNumberInputError] = useState(false);
  const connectionId = useSelector(selectConnectionId);
  ValidatorForm.addValidationRule("isValidUsPhoneNumber", (value) => {
    if (isBlankOrNull(value)) {
      return true;
    }
    if (value) {
      return validateAndDispatchUSNumber(value, handleTextChange, "phone");
    }
  });

  ValidatorForm.addValidationRule("isValidEmail", (value) => {
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    if (isBlankOrNull(value)) {
      return true;
    }
    return String(value)
      .toLowerCase()
      .match(emailPattern)
      ? true
      : false;
  });

  useEffect(() => {
    if (state && state.phone) {
      validateAndDispatchUSNumber(state.phone, handleTextChange, "phone")
        ? setPhoneNumberInputError(false)
        : setPhoneNumberInputError(true);
    }
    if (state && state.faxNumber) {
      validateAndDispatchUSNumber(
        state.faxNumber,
        handleTextChange,
        "faxNumber"
      )
        ? setFaxNumberInputError(false)
        : setFaxNumberInputError(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.phone, state.faxNumber]);

  const hasValidGender = [
    "",
    "male",
    "female",
    "transgender - male to female",
    "transgender - female to male",
    "agender",
    "genderqueer/gender-fluid/gender nonconforming",
    "other"
  ].includes(state.gender);
  const hasValidPronoun = [
    "",
    "he/him/his",
    "she/her/hers",
    "they/them/their"
  ].includes(state.pronouns);

  const hasValidSexValue = hasValidSex ? state.sexAssignedAtBirth : "";
  const hasValidGenderValue = hasValidGender ? state.gender : "";
  const hasValidPronounValue = hasValidPronoun ? state.pronouns : "";
  const legalGuardianError = isNotMajorValidator ? classes.textError : "";
  const legalGuardianLabelErrorElememt =
    isNotMajorValidator && !state.isLegalGuardian ? (
      <FormHelperText className={classes.textError}>
        <Translate text="Legal guardians are required for connections under 18." />
      </FormHelperText>
    ) : null;

  const roleMenuHandler = () => {
    const items = [
      // { name: "Legal spouse", value: "Legal spouse" },
      // { name: "Child", value: "Child" },
      // { name: "Care team", value: "Care team" },
      // { name: "Guardian", value: "Guardian" },
      { name: "Primary care provider", value: "Primary care provider" },
      { name: "Practitioner", value: "Practitioner" }
    ];
    const filteredArray = items.filter((item) => {
      return (
        item.name === "Practitioner" || item.name === "Primary care provider"
      );
    });
    const array = isTransmissionSelection ? filteredArray : items;
    return array.map((item) => {
      return (
        <MenuItem name={item.name} value={item.value} key={item.name}>
          <Translate text={item.value} />
        </MenuItem>
      );
    });
  };

  const deleteRenderHandler = () => {
    if (canDelete && deleteConnectionHandler) {
      return (
        <Button
          className={classes.deleteBtn}
          type="button"
          onClick={deleteConnectionHandler}
          startIcon={<img src={BlueTrashIcon} alt="Trash Icon" />}
          disabled={!connectionId}
        >
          <Translate text="DELETE CONNECTION" />
        </Button>
      );
    }
    return null;
  };
  return (
    <Grid item xs={12}>
      <Grid
        className={classes.titleContainer}
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        {type && type === "edit" ? (
          <Grid item xs={6} lg={6} className={classes.names}>
            <Typography className={classes.textConnect}>CONNECTION</Typography>
            <Typography
              variant={changeVariantValue}
            >{`${state.firstName} ${state.lastName}`}</Typography>
          </Grid>
        ) : (
          <Typography
            className={classes.formTitle}
            variant={changeVariantValue}
          >
            {formTitle}
          </Typography>
        )}
      </Grid>
      <ValidatorForm
        onSubmit={handleClick}
        onError={() => {
          return false;
        }}
      >
        <Grid
          container
          direction="row"
          justifyContent="center"
          className={classes.boxContainer}
        >
          <Grid item xs={8} lg={6} className={classes.titleContainer}>
            <Grid container spacing={2} className={classes.section}>
              <Grid item xs={12} md={6}>
                <InputLabel size="small" margin="dense"></InputLabel>
                <SelectValidator
                  variant="outlined"
                  label={<Translate text="Relationship to you" />}
                  size="small"
                  fullWidth={true}
                  margin="dense"
                  id="relationship"
                  name="relationship"
                  value={state.relationship}
                  onChange={(e) => handleTextChange(e)}
                  validators={["required"]}
                  errorMessages={[<Translate text="this field is required" />]}
                >
                  {roleMenuHandler()}
                </SelectValidator>
              </Grid>
              {state.relationship === "Care team" ? (
                <Grid item xs={12} md={6}>
                  <TextValidator
                    label={<Translate text="Specialization (optional)" />}
                    variant="outlined"
                    size="small"
                    fullWidth={true}
                    margin="dense"
                    id="specialization"
                    name="specialization"
                    value={state.specialization}
                    onChange={(e) => handleTextChange(e)}
                    inputStyle
                  />
                </Grid>
              ) : null}
            </Grid>
            <Grid item className={classes.section}>
              <Typography variant="deprecated_h6" gutterBottom>
                <Translate text="Personal information" />
              </Typography>
              <Divider variant="fullWidth" className={classes.divider} />
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <TextValidator
                    label={<Translate text="First name" />}
                    variant="outlined"
                    size="small"
                    fullWidth={true}
                    margin="dense"
                    id="firstName"
                    name="firstName"
                    value={state.firstName}
                    validators={[
                      "required",
                      "matchRegexp:^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z ]*)*$"
                    ]}
                    errorMessages={[
                      <Translate text="this field is required" />,
                      <Translate text="First Name must contain only A-Z or a-z" />
                    ]}
                    onChange={(e) => handleTextChange(e)}
                    inputStyle
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextValidator
                    label={<Translate text="Middle initial (optional)" />}
                    variant="outlined"
                    size="small"
                    fullWidth={true}
                    margin="dense"
                    id="middleName"
                    name="middleName"
                    value={state.middleName}
                    validators={[
                      "matchRegexp:^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z ]*)*$"
                    ]}
                    errorMessages={[
                      <Translate text="First Name must contain only A-Z or a-z" />
                    ]}
                    onChange={(e) => handleTextChange(e)}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <TextValidator
                    label={<Translate text="Last name" />}
                    variant="outlined"
                    size="small"
                    fullWidth={true}
                    margin="dense"
                    id="lastName"
                    name="lastName"
                    value={state.lastName}
                    validators={[
                      "required",
                      "matchRegexp:^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z ]*)*$"
                    ]}
                    errorMessages={[
                      <Translate text="this field is required" />,
                      <Translate text="First Name must contain only A-Z or a-z" />
                    ]}
                    onChange={(e) => handleTextChange(e)}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextValidator
                    label={<Translate text="Preferred name (optional)" />}
                    variant="outlined"
                    size="small"
                    fullWidth={true}
                    margin="dense"
                    id="preferredName"
                    name="preferredName"
                    value={state.preferredName}
                    validators={[
                      "matchRegexp:^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z ]*)*$"
                    ]}
                    errorMessages={[
                      <Translate text="First Name must contain only A-Z or a-z" />
                    ]}
                    onChange={(e) => handleTextChange(e)}
                  />
                </Grid>
              </Grid>
            </Grid>
            {state.relationship === "Legal spouse" ||
            state.relationship === "Child" ? (
              <Grid item xs={12} className={classes.section}>
                <Typography variant="deprecated_h6" gutterBottom>
                  <Translate text="Contact" />
                </Typography>
                <Typography variant="deprecated_body1" gutterBottom>
                  <Translate text="No spam, we promise. We use this information for verification purposes only." />
                </Typography>
                <Divider variant="fullWidth" className={classes.divider} />
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <TextValidator
                      label={<Translate text="Email (optional)" />}
                      variant="outlined"
                      size="small"
                      fullWidth={true}
                      margin="dense"
                      id="email"
                      name="email"
                      value={state.email}
                      onChange={(e) => handleTextChange(e)}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextValidator
                      label={<Translate text="Phone Number (optional)" />}
                      variant="outlined"
                      size="small"
                      fullWidth={true}
                      margin="dense"
                      id="phone"
                      name="phone"
                      value={state.phone}
                      error={phoneInputError}
                      helperText={phoneInputError && LABELS.LABEL_VALID_NUMBER}
                      onChange={(e) => handleTextChange(e)}
                    />
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              <Grid item xs={12} className={classes.section}>
                <Typography variant="deprecated_h6" gutterBottom>
                  <Translate text="Contact" />
                </Typography>
                <Typography variant="deprecated_body1" gutterBottom>
                  <Translate text="No spam, we promise. We use this information for verification purposes only." />
                </Typography>
                <Divider variant="fullWidth" className={classes.divider} />
                <Grid container spacing={2}>
                  <Grid item xs={12} md={3}>
                    <ReactInputMask
                      mask="+1(999)999-9999"
                      value={state.faxNumber}
                      disabled={false}
                      alwaysShowMask={true}
                      onChange={(e) => {
                        handleTextChange(e);
                      }}
                    >
                      {() => (
                        <TextField
                          label={<Translate text="Fax Number" />}
                          variant="outlined"
                          size="small"
                          fullWidth={true}
                          margin="dense"
                          id="faxNumber"
                          name="faxNumber"
                          value={state.faxNumber}
                          error={faxNumberInputError}
                          helperText={
                            faxNumberInputError && LABELS.LABEL_VALID_NUMBER
                          }
                          required
                        />
                      )}
                    </ReactInputMask>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <ReactInputMask
                      mask="+1(999)999-9999"
                      value={state.phone}
                      disabled={false}
                      alwaysShowMask={true}
                      onChange={(e) => {
                        handleTextChange(e);
                      }}
                    >
                      {() => (
                        <TextField
                          label={<Translate text="Phone Number (Optional)" />}
                          variant="outlined"
                          size="small"
                          fullWidth={true}
                          margin="dense"
                          id="phone"
                          name="phone"
                          value={state.phone}
                          error={phoneInputError}
                          helperText={
                            phoneInputError && LABELS.LABEL_VALID_NUMBER
                          }
                        />
                      )}
                    </ReactInputMask>
                  </Grid>
                  <Grid item xs={12} md={5}>
                    <TextValidator
                      label={<Translate text="Email (optional)" />}
                      variant="outlined"
                      size="small"
                      fullWidth={true}
                      margin="dense"
                      id="email"
                      name="email"
                      value={state.email}
                      onChange={(e) => handleTextChange(e)}
                      validators={["isValidEmail"]}
                      errorMessages={[LABELS.LABEL_VALID_EMAIL]}
                    />
                  </Grid>
                </Grid>
              </Grid>
            )}
            {state.relationship === "Legal spouse" ||
            state.relationship === "Child" ? (
              <Grid item xs={12} className={classes.section}>
                <Typography variant="deprecated_h6" gutterBottom>
                  <span>{<Translate text="Sex & gender identity" />}</span>
                </Typography>
                <Divider variant="fullWidth" className={classes.divider} />
                <Grid container spacing={2}>
                  <Grid item xs={12} md={4}>
                    <InputLabel size="small" margin="dense"></InputLabel>
                    <SelectValidator
                      id="sexAssignedAtBirth"
                      variant="outlined"
                      label={<Translate text="Sex assigned at birth" />}
                      size="small"
                      fullWidth={true}
                      margin="dense"
                      name="sexAssignedAtBirth"
                      value={hasValidSexValue}
                      onChange={(e) => handleTextChange(e)}
                      validators={["required"]}
                      errorMessages={[
                        <Translate text="this field is required" />
                      ]}
                    >
                      <MenuItem name="male" value={"M"}>
                        <Translate text="Male" />
                      </MenuItem>
                      <MenuItem name="female" value={"F"}>
                        <Translate text="Female" />
                      </MenuItem>
                      <MenuItem name="intersex" value={"I"}>
                        <Translate text="Intersex" />
                      </MenuItem>
                      <MenuItem name="unknown" value={"U"}>
                        <Translate text="Unknown" />
                      </MenuItem>
                    </SelectValidator>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <InputLabel size="small" margin="dense"></InputLabel>
                    <SelectValidator
                      variant="outlined"
                      label={<Translate text="Gender (optional)" />}
                      size="small"
                      fullWidth={true}
                      margin="dense"
                      id="gender"
                      name="gender"
                      value={hasValidGenderValue}
                      onChange={(e) => handleTextChange(e)}
                    >
                      <MenuItem name="empty" value={""}>
                        <Translate text="I prefer not to say" />
                      </MenuItem>
                      <MenuItem name="male" value={"male"}>
                        <Translate text="Male" />
                      </MenuItem>
                      <MenuItem name="female" value={"female"}>
                        <Translate text="Female" />
                      </MenuItem>
                      <MenuItem
                        name="transgender-maleToFemale"
                        value={"transgender - male to female"}
                      >
                        <Translate text="Transgender - male to female" />
                      </MenuItem>
                      <MenuItem
                        name="transgender-femaleToMale"
                        value={"transgender - female to male"}
                      >
                        <Translate text="Transgender - female to male" />
                      </MenuItem>
                      <MenuItem name="agender" value={"agender"}>
                        <Translate text="Agender" />
                      </MenuItem>
                      <MenuItem
                        name="genderqueer"
                        value={"genderqueer/gender-fluid/gender nonconforming"}
                      >
                        <Translate text="Genderqueer/gender-fluid/gender nonconforming" />
                      </MenuItem>
                      <MenuItem name="other" value={"other"}>
                        <Translate text="Other" />
                      </MenuItem>
                    </SelectValidator>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <InputLabel size="small" margin="dense"></InputLabel>
                    <SelectValidator
                      id="pronouns"
                      name="pronouns"
                      variant="outlined"
                      label={<Translate text="Pronouns (Optional)" />}
                      size="small"
                      fullWidth={true}
                      margin="dense"
                      value={hasValidPronounValue}
                      onChange={(e) => handleTextChange(e)}
                    >
                      <MenuItem name="empty" value={""}>
                        <Translate text="I prefer not to say" />
                      </MenuItem>
                      <MenuItem name="him" value={"he/him/his"}>
                        <Translate text="He/Him/His" />
                      </MenuItem>
                      <MenuItem name="her" value={"she/her/hers"}>
                        <Translate text="She/Her/Hers" />
                      </MenuItem>
                      <MenuItem name="they" value={"they/them/their"}>
                        <Translate text="They/Them/Their" />
                      </MenuItem>
                    </SelectValidator>
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              <Grid item xs={12} className={classes.section}>
                <Typography variant="deprecated_h6" gutterBottom>
                  <span>{<Translate text="Address" />}</span>
                </Typography>
                <Divider variant="fullWidth" className={classes.divider} />
                <Grid container spacing={1}>
                  <Grid item xs={12} md={12}>
                    <TextValidator
                      label={<Translate text="Hospital or Organization" />}
                      variant="outlined"
                      size="small"
                      fullWidth={true}
                      margin="dense"
                      id="hospitalOrOrganization"
                      name="hospitalOrOrganization"
                      value={state.hospitalOrOrganization}
                      onChange={(e) => handleTextChange(e)}
                      validators={["required"]}
                      errorMessages={[
                        <Translate text="this field is required" />
                      ]}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextValidator
                      label={<Translate text="Address 1" />}
                      variant="outlined"
                      size="small"
                      fullWidth={true}
                      margin="dense"
                      id="address1"
                      name="address1"
                      value={state.address1}
                      onChange={(e) => handleTextChange(e)}
                      validators={["required"]}
                      errorMessages={[
                        <Translate text="this field is required" />
                      ]}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextValidator
                      label={<Translate text="Address 2 (optional)" />}
                      variant="outlined"
                      size="small"
                      fullWidth={true}
                      margin="dense"
                      id="address2"
                      name="address2"
                      value={state.address2}
                      onChange={(e) => handleTextChange(e)}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextValidator
                      label={<Translate text="City" />}
                      variant="outlined"
                      size="small"
                      fullWidth={true}
                      margin="dense"
                      id="addressCity"
                      name="addressCity"
                      value={state.addressCity}
                      onChange={(e) => handleTextChange(e)}
                      validators={["required"]}
                      errorMessages={[
                        <Translate text="this field is required" />
                      ]}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextValidator
                      label={<Translate text="State" />}
                      variant="outlined"
                      size="small"
                      fullWidth={true}
                      margin="dense"
                      id="addressState"
                      name="addressState"
                      value={state.addressState}
                      onChange={(e) => handleTextChange(e)}
                      validators={["required"]}
                      errorMessages={[
                        <Translate text="this field is required" />
                      ]}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextValidator
                      label={<Translate text="Zip/Postal code" />}
                      variant="outlined"
                      size="small"
                      fullWidth={true}
                      margin="dense"
                      id="addressZip"
                      name="addressZip"
                      value={state.addressZip}
                      onChange={(e) => handleTextChange(e)}
                      validators={["required"]}
                      errorMessages={[
                        <Translate text="this field is required" />
                      ]}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextValidator
                      label={<Translate text="Country" />}
                      variant="outlined"
                      size="small"
                      fullWidth={true}
                      margin="dense"
                      id="country"
                      name="country"
                      value={state.addressCountry}
                      onKeyPress={disableEnterKey}
                      disabled={true}
                    />
                  </Grid>
                </Grid>
              </Grid>
            )}
            {state.relationship === "Legal spouse" ||
            state.relationship === "Child" ? (
              <Grid item xs={12} className={classes.section}>
                <Typography
                  variant="deprecated_h6"
                  gutterBottom
                  className={classes.geneticText}
                >
                  <Translate text="Date of birth" />
                </Typography>
                <Typography variant="deprecated_body1" gutterBottom>
                  <Translate text="Genetic screening is available to adults 18+ only." />
                </Typography>
                <Divider variant="fullWidth" className={classes.divider} />
                <Grid item xs={12} md={5}>
                  <TextValidator
                    label={<Translate text="Date of Birth" />}
                    variant="outlined"
                    fullWidth={true}
                    margin="dense"
                    id="dateOfBirth"
                    name="dateOfBirth"
                    onChange={(e) => handleTextChange(e)}
                    value={state.dateOfBirth}
                    validators={[
                      "required",
                      "matchRegexp:^((0?[1-9]|1[012])[- /.](0?[1-9]|[12][0-9]|3[01])[- /.](19|20)?[0-9]{2})*$"
                    ]}
                    errorMessages={[
                      <Translate text="this field is required" />,
                      <Translate text="The date format should be MM/DD/YYYY" />
                    ]}
                  />
                </Grid>
                <Grid container alignItems="center" wrap="nowrap">
                  <Grid className={classes.checkboxContainer}>
                    <FormControlLabel
                      value={state.isLegalGuardian}
                      control={
                        <Checkbox
                          size="small"
                          color="secondary"
                          name={"isLegalGuardian"}
                          id={"isLegalGuardian"}
                          onChange={(e) => handleCheckboxChange(e)}
                          checked={state.isLegalGuardian}
                          className={legalGuardianError}
                        />
                      }
                      label="I am this member’s legal guardian."
                      labelPlacement="end"
                    />
                    {legalGuardianLabelErrorElememt}
                  </Grid>
                </Grid>
              </Grid>
            ) : null}
            <Grid item xs={12} className={classes.section}>
              <Typography variant="deprecated_h6" gutterBottom>
                <span>{<Translate text="Member connection" />}</span>
              </Typography>
              <Divider variant="fullWidth" className={classes.divider} />
              {(state.relationship === "Legal spouse" ||
                state.relationship === "Child") && (
                <Grid className={classes.checkboxContainer}>
                  <FormControlLabel
                    value={state.coverUnderMembershipPlan}
                    control={
                      <Checkbox
                        size="small"
                        color="secondary"
                        name="coverUnderMembershipPlan"
                        id="coverUnderMembershipPlan"
                        onChange={(e) => handleCheckboxChange(e)}
                        checked={state.coverUnderMembershipPlan}
                      />
                    }
                    label="I want to cover this person under my membership plan."
                    labelPlacement="end"
                  />
                </Grid>
              )}
              <Grid className={classes.checkboxContainer}>
                <FormControlLabel
                  value={state.authorizeReleaseOfConfidentialHealth}
                  control={
                    <Checkbox
                      size="small"
                      color="secondary"
                      name="authorizeReleaseOfConfidentialHealth"
                      id="authorizeReleaseOfConfidentialHealth"
                      onChange={(e) => handleCheckboxChange(e)}
                      checked={state.authorizeReleaseOfConfidentialHealth}
                      className={errorValidForAuthorization}
                    />
                  }
                  label={`I authorize the release of confidential health information about me. You may release a copy of my medical records, or a summary or narrative of my protected health information to this family member connection.`}
                  disabled={isNotMajorValidator}
                  labelPlacement="end"
                />
                {isPrimaryCare && handleShowModal ? (
                  <Grid
                    container
                    alignContent="center"
                    className={classes.modalContainer}
                    onClick={handleShowModal}
                  >
                    <Typography className={classes.modalLink}>
                      <Translate text={modalText} />
                    </Typography>
                  </Grid>
                ) : null}
                {isPrimaryCare &&
                !state.authorizeReleaseOfConfidentialHealth ? (
                  <FormHelperText className={classes.textError}>
                    <Translate text="Review the statement above and check the box to authorize care team members added to your connections." />
                  </FormHelperText>
                ) : null}
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            direction={reverseButtons}
            justifyContent="space-around"
            alignItems="center"
            className={classes.boxButtons}
          >
            {deleteRenderHandler()}
            <Button
              variant="contained"
              color="secondary"
              type="submit"
              id="submit-member-information"
              disabled={
                isSubmitDisabled ||
                !state.authorizeReleaseOfConfidentialHealth ||
                faxNumberInputError ||
                !state.faxNumber ||
                state.faxNumber.length < 10
                  ? true
                  : false
              }
            >
              <Translate text="Save" />
            </Button>
          </Grid>
        </Grid>
      </ValidatorForm>
    </Grid>
  );
};

export default ConnectionDetailForm;
