import React, { ReactNode, useEffect, useState } from "react";
import {
  TextField,
  MenuItem,
  Box,
  Typography,
  IconButton,
  keyframes
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"; // Custom down arrow
import GLTooltip from "components/v3/sharedComponents/GLTooltip";
import { COLORS } from "components/v3/Theme/colors";
import { Option } from "constants/generalConstants";

// There is a lot of bizwack in here around validation to support requests
//  from design. They want the field not to light up invalid until a user
//  has focused and defocused it, but they still want the save button
//  disabled. So the validity we report to the form that drives the button
//  disable is more strict than the validity the field itself shows, since
//  it may not show invalid if you are working on it or have not focused it before.
//  It's brittle. Be careful with it.

interface GLSelectBaseProps {
  label: string;
  tooltip?: string | ReactNode;
  options: Option[];
  value?: string;
  defaultValue?: string;
  required?: boolean;
}

// When `disabled` is false or not set, `id` and `onChange` are required
interface GLSelectEnabledProps extends GLSelectBaseProps {
  disabled?: false;
  id: string;
  onChange: (data: {
    e: React.ChangeEvent<HTMLInputElement>;
    isInvalid: boolean;
  }) => void;
}

// When `disabled` is true, `id` and `onChange` are optional
interface GLSelectDisabledProps extends GLSelectBaseProps {
  disabled: true;
  id?: string;
  onChange?: (data: {
    e: React.ChangeEvent<HTMLInputElement>;
    isInvalid: boolean;
  }) => void;
}

export type GLSelectProps = GLSelectEnabledProps | GLSelectDisabledProps;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const GLSelect: React.FC<GLSelectProps> = ({
  label,
  id,
  tooltip,
  options,
  onChange,
  value,
  defaultValue,
  required,
  disabled
}) => {
  const [selectedValue, setSelectedValue] = useState(value || "");
  const [hasFocus, setHasFocus] = useState(false);
  const [hasReceivedFocus, setHasReceivedFocus] = useState(false);

  useEffect(() => {
    if (onChange) {
      onChange({
        e: { target: { name: id, value: selectedValue } } as React.ChangeEvent<
          HTMLInputElement
        >,
        isInvalid: required && (!selectedValue || selectedValue === "")
      });
    }
  }, [hasReceivedFocus]); // eslint-disable-line

  const renderOptions = () => {
    return options.map((option) => (
      <MenuItem key={option.value} value={option.value}>
        {option.label}
      </MenuItem>
    ));
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue(e.target.value);
    if (onChange) {
      onChange({
        e,
        isInvalid: !!calculatePossibleHelperText(e.target.value)
      });
    }
  };

  const calculatePossibleHelperText = (
    valueToValidate: string
  ): string | null => {
    if (required && !valueToValidate) {
      return "This field is required";
    }
    return null;
  };

  const shouldBeShowingErrorState =
    !hasFocus &&
    ((selectedValue && !hasReceivedFocus) || hasReceivedFocus) &&
    !!calculatePossibleHelperText(selectedValue);

  return (
    <Box sx={{ width: "100%" }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          mb: 1,
          height: "20px"
        }}
      >
        <Typography variant="body_strong_black">{label}</Typography>

        {!required && (
          <Typography
            variant="body_xs_dark_gray"
            sx={{
              marginLeft: "12px",
              marginTop: "3px",
              fontWeight: 400,
              fontStyle: "italic"
            }}
          >
            (Optional)
          </Typography>
        )}

        {tooltip && (
          <GLTooltip title={tooltip}>
            <IconButton size="small" sx={{ ml: 1 }}>
              <InfoOutlinedIcon fontSize="small" />
            </IconButton>
          </GLTooltip>
        )}
      </Box>

      <TextField
        fullWidth
        select
        id={id}
        name={id}
        value={selectedValue}
        defaultValue={defaultValue}
        variant="outlined"
        required={required}
        disabled={disabled}
        onChange={handleChange}
        error={shouldBeShowingErrorState}
        helperText={
          shouldBeShowingErrorState
            ? calculatePossibleHelperText(selectedValue)
            : null
        }
        onFocus={() => {
          setHasReceivedFocus(true);
          setHasFocus(true);
        }}
        onBlur={() => {
          setHasFocus(false);
        }}
        sx={{
          "& .MuiOutlinedInput-root": {
            borderRadius: "8px",
            borderColor: COLORS.PRIMARY_LIGHTER,
            position: "relative",
            transition: "border-color 0.3s ease",
            "& fieldset": {
              borderColor: COLORS.PRIMARY_LIGHTER,
              transition: "border-color 0.3s ease"
            },
            "&:hover fieldset": {
              borderColor: COLORS.PRIMARY,
              transition: "border-color 0.3s ease"
            },
            "&.Mui-focused fieldset": {
              borderColor: COLORS.PRIMARY_DARK,
              transition: "border-color 0.3s ease"
            }
          },
          "& .MuiSelect-select": {
            padding: "15px 14px 10px 14px",
            display: "flex",
            alignItems: "center",
            maskImage: `linear-gradient(to right, rgba(0,0,0,1) 70%, rgba(0,0,0,0) 83%)`,
            maskSize: "100%",
            maskRepeat: "no-repeat"
          },
          "& .MuiOutlinedInput-input": {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            fontSize: "16px"
          },
          "& .MuiOutlinedInput-root:before": {
            content: '""',
            height: "60%",
            width: "1px",
            backgroundColor: COLORS.PRIMARY_LIGHTER,
            position: "absolute",
            right: "40px",
            top: "20%"
          },
          "& .MuiSelect-icon": {
            top: "auto",
            right: "5px",
            fontSize: "32px",
            backgroundColor: COLORS.WHITE,
            color: COLORS.DARK_GRAY,
            fontWeight: 200
          },
          "& .MuiFormHelperText-root": {
            position: "relative",
            width: "fit-content",
            top: "-13px",
            left: "14px",
            height: "13px",
            marginTop: 0,
            marginBottom: "-13px",
            paddingLeft: "5px",
            paddingRight: "5px",
            backgroundColor: COLORS.WHITE,
            opacity: 0,
            animation: `${fadeIn} 0.3s forwards`
          }
        }}
        SelectProps={{
          IconComponent: KeyboardArrowDownIcon
        }}
      >
        {renderOptions()}
      </TextField>
    </Box>
  );
};

export default GLSelect;
