import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MemberApiGETProfileResponse } from "apis/v3/memberApi";

export interface UserPersonalInformation {
  glMemberId?: string;
  prefix?: string;
  suffix?: string;
  firstName?: string;
  middleName?: string;
  lastName?: string;
  preferredName?: string;
  dob?: string;
  sexAssignedAtBirth?: string;
  phone?: string;
  email?: string;
  gender?: string;
  pronouns?: string;
  preferredContactMechanism?: "phone" | "email" | "either";
  preferredContactTime?: "morning" | "afternoon" | "either";
  addressLine1?: string;
  addressLine2?: string;
  city?: string;
  state?: string;
  postalCode?: string;
  country?: string;
  isMemberActive?: boolean | null;
  glProgramId?: string;
}

interface SpouseInformation {
  spouseAccessCode: string | null;
}

interface UserSettings {
  emailNotifications?: boolean;
  phoneNotifications?: boolean;
  desktopNotifications?: boolean;
}

interface UserAccountState {
  greatInitialApiScrapeIsHappening: boolean;
  greatInitialApiScrapeIsComplete: boolean;
  userPersonalInformation: UserPersonalInformation;
  spouseInformation?: SpouseInformation;
  userSettings?: UserSettings;
  hasActiveCancerDiagnosis: boolean | null;
}

function combineUserObjectsWithoutClearingExistingValues(
  existingObject: UserPersonalInformation,
  objectToMergeWithoutReplacements: Partial<UserPersonalInformation>
): UserPersonalInformation {
  Object.keys(objectToMergeWithoutReplacements).forEach(
    (key: keyof UserPersonalInformation) => {
      if (
        !(
          ["glMemberId", "firstName", "lastName"].includes(key) &&
          !objectToMergeWithoutReplacements[
            key as keyof UserPersonalInformation
          ]
        )
      ) {
        //@ts-ignore TS mad because it cant guarantee that these keys exist
        //  on UserPersonalInformation
        existingObject[key] = objectToMergeWithoutReplacements[key];
      }
    }
  );
  return existingObject;
}

export const INITIAL_STATE: UserAccountState = {
  greatInitialApiScrapeIsHappening: false,
  greatInitialApiScrapeIsComplete: false,
  userPersonalInformation: {
    glMemberId: "",
    prefix: "",
    suffix: "",
    firstName: "",
    middleName: "",
    lastName: "",
    preferredName: "",
    dob: "",
    sexAssignedAtBirth: "",
    phone: "",
    email: "",
    gender: "",
    pronouns: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    state: "",
    postalCode: "",
    country: "",
    isMemberActive: null
  },
  spouseInformation: {
    spouseAccessCode: null
  },
  userSettings: {
    emailNotifications: false,
    phoneNotifications: false,
    desktopNotifications: false
  },
  hasActiveCancerDiagnosis: null
};

export const UserAccountSlice = createSlice({
  name: "userAccount",
  initialState: INITIAL_STATE,
  reducers: {
    setGreatInitialApiScrapeIsHappening(
      state,
      { payload }: PayloadAction<boolean>
    ) {
      state.greatInitialApiScrapeIsHappening = payload;
    },
    setGreatInitialApiScrapeIsComplete(
      state,
      { payload }: PayloadAction<boolean>
    ) {
      state.greatInitialApiScrapeIsComplete = payload;
    },
    updateFieldsInUserPersonalInformation(
      state,
      {
        payload
      }: PayloadAction<
        Partial<UserPersonalInformation> | Partial<MemberApiGETProfileResponse>
      >
    ) {
      // Back end nests address fields under an address key. We just want it flat.
      // If it's nested, pull it into root, then remove the address field key
      const hasAddress = (
        payload:
          | Partial<UserPersonalInformation>
          | Partial<MemberApiGETProfileResponse>
      ): payload is Partial<MemberApiGETProfileResponse> => {
        return "address" in payload;
      };
      const updatedFields = (() => {
        if (!hasAddress(payload)) {
          // If payload does not have `address`, return it as is.
          return payload;
        }

        // Flatten the address fields into the payload.
        const { address, ...theRestOfTheFields } = payload;
        return { ...theRestOfTheFields, ...address };
      })();

      state.userPersonalInformation = {
        ...combineUserObjectsWithoutClearingExistingValues(
          state.userPersonalInformation,
          updatedFields
        ),
        preferredName: payload.preferredName ?? payload.firstName
      };
    },
    setUserPersonalInformation(
      state,
      { payload }: PayloadAction<MemberApiGETProfileResponse>
    ) {
      // Back end nests address fields under an address key. We just want it flat.
      // Pull nested address fields into root, then remove the address field key
      const { address, ...payloadWithoutNestedAddressKey } = {
        ...payload,
        ...payload.address
      };
      state.userPersonalInformation = {
        ...payloadWithoutNestedAddressKey,
        preferredName: payload.preferredName ?? payload.firstName // Set preferredName if present, else copy from firstName
      };
    },
    setSpouseInformation(state, { payload }: PayloadAction<SpouseInformation>) {
      state.spouseInformation = payload;
    },
    setNotificationSettings(state, { payload }: PayloadAction<UserSettings>) {
      state.userSettings.emailNotifications = payload.emailNotifications;
      state.userSettings.phoneNotifications = payload.phoneNotifications;
      state.userSettings.desktopNotifications = payload.desktopNotifications;
    },
    setHasActiveCancerDiagnosis(
      state,
      { payload }: PayloadAction<boolean | null>
    ) {
      state.hasActiveCancerDiagnosis = payload;
    }
  }
});

export const {
  setGreatInitialApiScrapeIsHappening,
  setGreatInitialApiScrapeIsComplete,
  updateFieldsInUserPersonalInformation,
  setUserPersonalInformation,
  setSpouseInformation,
  setNotificationSettings,
  setHasActiveCancerDiagnosis
} = UserAccountSlice.actions;

export default UserAccountSlice.reducer;

export const selectGreatInitialApiScrapeIsHappening = (state: {
  user: UserAccountState;
}) => state.user.greatInitialApiScrapeIsHappening;
export const selectGreatInitialApiScrapeIsComplete = (state: {
  user: UserAccountState;
}) => state.user.greatInitialApiScrapeIsComplete;
export const selectUserPersonalInformation = (state: {
  user: UserAccountState;
}): UserPersonalInformation => state.user.userPersonalInformation;
export const selectSpouseInformation = (state: { user: UserAccountState }) =>
  state.user.spouseInformation || {};
export const selectUserSettings = (state: { user: UserAccountState }) =>
  state.user.userSettings ? state.user.userSettings : null;
export const selectHasActiveCancerDiagnosis = (state: {
  user: UserAccountState;
}) => state.user.hasActiveCancerDiagnosis;
