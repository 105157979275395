import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import { KeyboardArrowLeft } from "@mui/icons-material";
import React from "react";
import { Link } from "react-router-dom";
import BreadcrumbStyles from "./Breadcrumb.styles";
import Translate from "../../../translate/Translate";

/**
 * @description This is a reusable component able to redirect
 * to another page.
 * Props:
 * text: this is the text showed on the Link item
 * redirectTo: the route to redirect
 * @param {text, redirectTo} props
 * @returns redirect to another url
 */
const Breadcrumb = (props) => {
  const { text, redirectTo } = props;
  const classes = BreadcrumbStyles();
  const theme = useTheme();
  const changeVariant = useMediaQuery(theme.breakpoints.up("sm"));
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="flex-start"
      className={classes.breadcrumbContainer}
      name="breadcrumb"
    >
      <Link to={redirectTo} className={classes.breadcrumbLink} name={text}>
        <KeyboardArrowLeft className={classes.breadcrumbIcon} />
        <Typography
          variant={changeVariant ? "deprecated_subtitle1" : "deprecated_body1"}
          className={classes.breadcrumbText}
        >
          <Translate text={text} />
        </Typography>
      </Link>
    </Box>
  );
};

export default Breadcrumb;
