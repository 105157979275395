import React, { ReactNode } from "react";
import { Grid, Modal, Typography, Box } from "@mui/material";
import { Close } from "@mui/icons-material";
import { COLORS } from "components/v3/Theme/colors";

interface NoButtonsInfoDialogProps {
  isOpen: boolean;
  onClose: () => void;
  headerText?: string;
  children: ReactNode;
}

const NoButtonsInfoDialog: React.FC<NoButtonsInfoDialogProps> = ({
  isOpen,
  onClose,
  headerText,
  children
}) => {
  return (
    <Modal
      id="modal-message"
      open={isOpen}
      onClose={onClose}
      closeAfterTransition
      disableAutoFocus={true}
      sx={{
        outline: "0",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        overflow: "auto",
        "& .MuiModal-backdrop": {
          backgroundColor: `${COLORS.PRIMARY_DARKER}80`
        },
        "&.fade": {
          transition: "opacity 0.3s ease",
          opacity: isOpen ? 1 : 0
        }
      }}
    >
      <Grid
        item
        xs={11}
        sm={8}
        md={6}
        lg={4}
        sx={{
          borderRadius: "16px",
          padding: 3,
          backgroundColor: COLORS.WHITE,
          boxShadow: 5,
          maxHeight: "95vh",
          overflowY: "auto",
          position: "relative",
          maxWidth: "600px",
          width: "100%"
        }}
      >
        <Box
          onClick={onClose}
          sx={{
            position: "absolute",
            top: 16,
            right: 16,
            cursor: "pointer",
            color: COLORS.GRAY
          }}
        >
          <Close />
        </Box>

        <Grid
          container
          justifyContent="center"
          sx={{
            mb: 2,
            textAlign: "center"
          }}
        >
          <Typography variant="h4_primary">{headerText}</Typography>
        </Grid>

        {/* Modal Content */}
        <Grid container>{children}</Grid>
      </Grid>
    </Modal>
  );
};

export default NoButtonsInfoDialog;
