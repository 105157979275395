import { Grid, Typography } from "@mui/material";
import React from "react";
import ToDoListCardContainerStyles from "./ToDoListCardContainer.styles";

const ToDoListCardContainer = ({ icon, title, buttons, body, id }) => {
  const classes = ToDoListCardContainerStyles();
  return (
    <Grid item xs={12} className={`${classes.cardItem} to-do-card`} id={id}>
      <Grid container direction="row" className={classes.cardContainer}>
        <Grid item>
          <img src={icon} alt={title} className={classes.icon} />
        </Grid>
        <Grid item className={classes.cardContentsContainer}>
          <Typography className={`${classes.header} to-do-card-header`}>
            {title}
          </Typography>
          <div className={`${classes.body} to-do-card-body`}>
            <Typography variant="deprecated_body1">{body}</Typography>
          </div>
          <Grid item className={classes.buttonList}>
            {buttons}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ToDoListCardContainer;
