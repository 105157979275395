import React, { useState } from "react";
import GLTextField, { GLTextFieldProps } from "../GLTextField";
import { validateUSPhoneNumber } from "../FormUtils";

interface PhoneChangeEvent {
  e: React.ChangeEvent<HTMLInputElement>;
  isInvalid: boolean;
}

const GLPhoneField: React.FC<GLTextFieldProps> = ({
  label,
  id,
  onChange,
  tooltip,
  value,
  defaultValue,
  required,
  disabled,
  placeholder
}) => {
  const [valueUserTyped, setValueUserTyped] = useState(
    value
      ? value.replace(/^\+1/, "").replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3")
      : ""
  );

  const handleChange = (event: PhoneChangeEvent) => {
    setValueUserTyped(event.e.target.value);
    if (!event.isInvalid) {
      event.e.target.value = `+1${event.e.target.value.replace(/[^0-9]/g, "")}`;
      onChange(event);
    }
  };

  return (
    <GLTextField
      label={label}
      id={id}
      tooltip={tooltip}
      value={valueUserTyped}
      defaultValue={defaultValue}
      placeholder={placeholder}
      required={required}
      disabled={disabled}
      onChange={handleChange}
      additionalValidation={validateUSPhoneNumber}
    />
  );
};

export default GLPhoneField;
