import React, { useState } from "react";
import { Grid, Typography } from "@mui/material";
import Layout from "../../../layout/Layout";
import SecurityMessage from "../../../sharedComponents/SecurityMessage";
import Translate from "../../../../translate/Translate";
import { useDispatch } from "react-redux";
import { PATHS } from "../../../../constants/pathConstants";
import AccountManagementMFAPageStyles from "./AccountManagementMFAPage.styles";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import GLButton from "../../../v2/sharedComponents/GLButton";
import GLPhoneNumberField from "../../../v2/Forms/FormComponents/GLPhoneNumberField";
import GLTextField from "../../../v2/Forms/FormComponents/GLTextField";
import { openGLSnackbar } from "../../../../store/reducers/snackbar/openGLSnackbarSlice";
import Mixpanel from "../../../../utils/mixpanel";
import LockIcon from "@mui/icons-material/Lock";
import PhoneIcon from "@mui/icons-material/PhonelinkRing";
import ErasePhoneIcon from "@mui/icons-material/PhonelinkErase";
import { patchMemberMfaEnforcedStatus } from "../../../../apis/membersApi";

const ContentBoxFrame = (props) => {
  const classes = AccountManagementMFAPageStyles();
  const { upperContent, lowerContent } = props;
  return (
    <Grid
      className={classes.boxFrame}
      container
      xs={11}
      md={6}
      justifyContent="center"
      alignItems="center"
      direction="column"
    >
      <Grid
        item
        className={[classes.contentBoxSection, classes.contentBoxSectionTop]}
      >
        {upperContent}
      </Grid>
      <Grid item className={classes.contentBoxSection}>
        {lowerContent}
      </Grid>
    </Grid>
  );
};

const AccountManagementMFAPage = (props) => {
  const classes = AccountManagementMFAPageStyles();
  const { getAccessTokenWithPopup } = useAuth0();
  const dispatch = useDispatch();
  const [mfaAccessToken, setMfaAccessToken] = useState(null);
  const [authenticationFactorList, setAuthenticationFactorList] = useState(
    null
  );
  const [textMessageFormIsOpen, setTextMessageFormIsOpen] = useState(false);
  const [phoneFieldInfo, setPhoneNumberFieldInfo] = useState({
    e: { target: { value: "" } },
    isInvalid: false
  });
  const [
    oobCodeForPhoneNumberValidation,
    setOobCodeForPhoneNumberValidation
  ] = useState("");
  const [bindingCode, setBindingCode] = useState("");
  const { REACT_APP_AUTH0_CLIENT_ID } = process.env;
  const { REACT_APP_AUTH0_DOMAIN } = process.env;

  // I'm keeping these api calls in here for now to help renforce that they
  //  are do and use separate authentication tokens from the rest of the
  //  site. If the MFA or admin APIs need to be called from somewhere else
  //  in the future, I'll abstract them out then.

  // This needs to be triggered by a button click to prevent
  //  popup blockers from blocking the re-auth for the page
  // Other options require navigating away and being redirected back
  //  which are rough to weave through the auth scheme of the rest
  //  of the site. It can be done if we want, but it will take more time.
  // I think this is less jarring and suspicious than a redirect anyway.
  const getMfaAccessToken = () => {
    try {
      return getAccessTokenWithPopup({
        authorizationParams: {
          audience: `https://${REACT_APP_AUTH0_DOMAIN}/mfa/`,
          scope: "enroll read:authenticators remove:authenticators",
          redirect_uri: `${window.location.origin}/account/management/mfa`
        }
      })
        .then((resp) => {
          Mixpanel.track("MFA addition process started");
          setMfaAccessToken(resp);
          return resp;
        })
        .catch((error) => {
          Mixpanel.track("Error getting list of MFA authentication factors", {
            error: error
          });
          handleAPICallError();
        });
    } catch (error) {
      Mixpanel.track("Error processing MFA token retrieval", {
        error: error
      });
      handleAPICallError();
    }
  };

  const getListOfActiveMfaMechanisms = (token = mfaAccessToken) => {
    try {
      const options = {
        method: "GET",
        url: `https://${REACT_APP_AUTH0_DOMAIN}/mfa/authenticators`,
        headers: { authorization: `Bearer ${token}` }
      };
      axios
        .request(options)
        .then((response) => {
          setAuthenticationFactorList(
            response.data.filter((factor) => {
              return factor.oob_channel !== "email" && factor.active !== false;
            })
          );
        })
        .catch((error) => {
          Mixpanel.track("Error getting list of MFA authentication factors", {
            error: error
          });
          handleAPICallError();
        });
    } catch (error) {
      Mixpanel.track("Error processing list of MFA authentication factors", {
        error: error
      });
      handleAPICallError();
    }
  };

  const sendPhoneNumberForValidationText = () => {
    try {
      var options = {
        method: "POST",
        url: `https://${REACT_APP_AUTH0_DOMAIN}/mfa/associate`,
        headers: {
          authorization: `Bearer ${mfaAccessToken}`,
          "content-type": "application/json"
        },
        data: {
          authenticator_types: ["oob"],
          oob_channels: ["sms"],
          phone_number: phoneFieldInfo.e.target.value.replace(/\D/g, "")
        }
      };
      axios
        .request(options)
        .then((response) => {
          setOobCodeForPhoneNumberValidation(response.data.oob_code);
        })
        .catch(() => {
          Mixpanel.track("MFA enrollment phone number rejected");
          dispatch(
            openGLSnackbar({
              variant: "error",
              subText:
                "Hmm, that phone number wasn't accepted. Let's try again."
            })
          );
          resetFormState();
        });
    } catch (error) {
      Mixpanel.track("Error enrolling phone number for MFA validation", {
        error: error
      });
      handleAPICallError();
    }
  };

  const returnConfirmationCodeFromUserToAuth0 = () => {
    try {
      var options = {
        method: "POST",
        url: `https://${REACT_APP_AUTH0_DOMAIN}/oauth/token`,
        headers: {
          authorization: `Bearer ${mfaAccessToken}`,
          "content-type": "application/x-www-form-urlencoded"
        },
        data: new URLSearchParams({
          grant_type: "http://auth0.com/oauth/grant-type/mfa-oob",
          client_id: REACT_APP_AUTH0_CLIENT_ID,
          mfa_token: mfaAccessToken,
          oob_code: oobCodeForPhoneNumberValidation,
          binding_code: bindingCode
        })
      };
      axios
        .request(options)
        .then((response) => {
          patchMemberMfaEnforcedStatus(true);
          Mixpanel.track("MFA factor added");
          getListOfActiveMfaMechanisms();
        })
        .then(() => {
          resetFormState();
        })
        .catch(() => {
          Mixpanel.track("MFA enrollment token rejected");
          dispatch(
            openGLSnackbar({
              variant: "error",
              subText:
                "Hmm, that code wasn't accepted. Let's try again. Make sure to wait for a new one!"
            })
          );
          resetFormState();
        });
    } catch (error) {
      Mixpanel.track(
        "Error returning binding code to activate phone number for MFA",
        {
          error: error
        }
      );
      handleAPICallError();
    }
  };

  const removeMfaMechanism = (mfaDeviceId) => {
    try {
      var options = {
        method: "DELETE",
        url: `https://${REACT_APP_AUTH0_DOMAIN}/mfa/authenticators/${mfaDeviceId}`,
        headers: { authorization: `Bearer ${mfaAccessToken}` }
      };
      axios
        .request(options)
        .then((response) => {
          patchMemberMfaEnforcedStatus(false);
          Mixpanel.track("MFA factor removed");
          getListOfActiveMfaMechanisms();
        })
        .catch((error) => {
          Mixpanel.track("Error removing authentication factors", {
            error: error
          });
          handleAPICallError();
        });
    } catch (error) {
      Mixpanel.track("Error processing removal of authentication factor", {
        error: error
      });
      handleAPICallError();
    }
  };

  const resetFormState = () => {
    setTextMessageFormIsOpen(false);
    setBindingCode("");
    setOobCodeForPhoneNumberValidation("");
    setPhoneNumberFieldInfo({ e: { target: { value: "" } }, isInvalid: false });
  };

  const handleAPICallError = () => {
    dispatch(
      openGLSnackbar({
        variant: "error",
        subText:
          "We're so sorry, but something went wrong. Please reach out to us!"
      })
    );
    resetFormState();
  };

  // These if statements progress in reverse order of the form's
  //  process. If we have an oob code, it means we're ready to get the
  //  confirmation 'binding' code from the user. If not, but the form
  //  is open, the user has started the process. Etc. Sort of a super
  //  lightweight stepwise form.
  const renderTextMessageForm = () => {
    if (oobCodeForPhoneNumberValidation) {
      return renderVerificationInputForm();
    } else if (textMessageFormIsOpen) {
      return renderPhoneInputForm();
    } else if (mfaAccessToken) {
      return renderInitialFormState();
    } else {
      return renderEntryPage();
    }
  };

  const renderEntryPage = () => {
    const upperContent = (
      <Grid item>
        <Typography className={classes.title} variant="deprecated_h6">
          <Translate text="Additional security measures" />
        </Typography>
        <Typography className={classes.subtext}>
          <Translate
            text="Multi-factor Authentication (MFA) is an additional step at login to help secure your
        personal information."
          />
        </Typography>
        <GLButton
          loadingSpinnerWhenClicked
          color="primary"
          className={classes.continueButton}
          startIcon={<LockIcon />}
          onClick={() => {
            getMfaAccessToken().then((token) => {
              getListOfActiveMfaMechanisms(token);
            });
          }}
        >
          CONTINUE TO MFA SETTINGS
        </GLButton>
      </Grid>
    );
    const lowerContent = (
      <Grid item>
        <Typography
          className={[classes.title, classes.titleSmall]}
          variant="deprecated_h6"
        >
          <Translate text="What is it?" />
        </Typography>
        <Typography className={classes.subtext}>
          <Translate
            text="It is a multi-step account login process that requires users to 
          enter more information than just a password. It prevents bad-actors from accessing 
          your account even if they’ve acquired your log in credentials."
          />
        </Typography>
        <Typography
          className={[classes.title, classes.titleGap, classes.titleSmall]}
          variant="deprecated_h6"
        >
          <Translate text="What do I need to do?" />
        </Typography>
        <Typography className={classes.subtext}>
          <Translate
            text="To continue, just click on the button above and follow the 
          prompts provided. Please have your phone handy and be ready to receive a code 
          that you will need to enter."
          />
        </Typography>
        <Typography
          className={[classes.title, classes.titleGap, classes.titleSmall]}
          variant="deprecated_h6"
        >
          <Translate text="What if I want to turn it off/on?" />
        </Typography>
        <Typography className={classes.subtext}>
          <Translate text="You can come back to this page to turn this on or off at any time." />
        </Typography>
      </Grid>
    );
    return (
      <ContentBoxFrame
        upperContent={upperContent}
        lowerContent={lowerContent}
      />
    );
  };

  const renderInitialFormState = () => {
    const hasAFactorEnabled =
      authenticationFactorList && authenticationFactorList.length > 0;

    const factors = hasAFactorEnabled
      ? authenticationFactorList.map((factor) => {
          return (
            <Grid
              item
              container
              xs={12}
              justifyContent="center"
              alignItems="center"
              key={factor.id}
              className={classes.factorList}
            >
              <Grid item xs={12}>
                <Typography className={classes.subtext}>
                  <Translate text="You have text authentication on for" />
                </Typography>
                <Typography className={classes.phone}>
                  <Translate text={factor.name} />
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <GLButton
                  loadingSpinnerWhenClicked
                  className={classes.continueButton}
                  startIcon={<ErasePhoneIcon />}
                  onClick={() => {
                    removeMfaMechanism(factor.id);
                  }}
                >
                  turn text authentication off
                </GLButton>
              </Grid>
            </Grid>
          );
        })
      : null;

    const addButton = !hasAFactorEnabled ? (
      <GLButton
        className={classes.continueButton}
        onClick={handleOpenTextMessageForm}
      >
        <PhoneIcon /> Add text message verification
      </GLButton>
    ) : null;
    const upperContent = (
      <Grid item>
        <Typography className={classes.title} variant="deprecated_h6">
          <Translate text="Your authentication settings" />
        </Typography>
        <Typography className={classes.subtext}>
          <Translate
            text="Multi-factor Authentication (MFA) is an additional step at log in to help secure your
        personal information."
          />
        </Typography>
      </Grid>
    );
    const renderLowerContent = (
      <>
        <Typography
          className={
            hasAFactorEnabled ? classes.successSubtext : classes.warnSubtext
          }
        >
          <Translate
            text={`Text message verification is turned ${
              hasAFactorEnabled ? "ON" : "OFF"
            }`}
          />
        </Typography>
        {factors}
        {addButton}
      </>
    );

    return (
      <ContentBoxFrame
        upperContent={upperContent}
        lowerContent={renderLowerContent}
      />
    );
  };

  const renderPhoneInputForm = () => {
    const upperContent = (
      <Grid item>
        <Typography className={classes.title} variant="deprecated_h6">
          <Translate text="Text message verification" />
        </Typography>
        <Typography className={classes.subtext}>
          <Translate
            text="Please enter a valid phone number below. We only
          allow US numbers at this time. This will be the phone number we send
          the code to, so please keep this phone handy now and any time you log in.
          When you press continue, you will recieve a verification code via text message
          at this number within a few minutes."
          />
        </Typography>
        <div className={classes.textInputContainer}>
          <GLPhoneNumberField
            md={6}
            required={true}
            label={"Phone number"}
            value={phoneFieldInfo.e.target.value}
            id="phone"
            onChange={(changeEvent) => {
              setPhoneNumberFieldInfo(changeEvent);
            }}
          />
        </div>
        <GLButton
          loadingSpinnerWhenClicked
          color="primary"
          className={classes.continueButton}
          onClick={sendPhoneNumberForValidationText}
          disabled={phoneFieldInfo.isInvalid}
        >
          Continue
        </GLButton>
      </Grid>
    );
    const renderLowerContent = (
      <>
        <Typography
          className={[classes.title, classes.titleSmall]}
          variant="deprecated_h6"
        >
          <Translate text="What do we need this phone number for?" />
        </Typography>
        <Typography className={classes.subtext}>
          <Translate
            text="We will only use this phone number to authenticate
          your identity when you log in. We will not use this phone number for anything
          except authentication unless you also have the same phone number set as your
          contact phone number under your account settings. Changing that number will not
          change this number."
          />
        </Typography>
      </>
    );

    return (
      <ContentBoxFrame
        upperContent={upperContent}
        lowerContent={renderLowerContent}
      />
    );
  };

  const renderVerificationInputForm = () => {
    const upperContent = (
      <Grid item>
        <Typography className={classes.title} variant="deprecated_h6">
          <Translate text="Verify phone number" />
        </Typography>
        <Typography className={classes.subtext}>
          <Translate text="Please enter the code we just sent to your phone number" />
        </Typography>
        <Typography className={classes.subtext}>
          <Translate text="Do not refresh or leave this page, it will start the process over" />
        </Typography>
        <Grid
          item
          container
          xs={12}
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={4} className={classes.validationCodeInputContainer}>
            <GLTextField
              id="bindingCode"
              name="bindingCode"
              variant="outlined"
              label={"Code"}
              value={bindingCode}
              onChange={(eventInfo) => setBindingCode(eventInfo.e.target.value)}
              required
            />
          </Grid>
        </Grid>
        <GLButton
          loadingSpinnerWhenClicked
          color="primary"
          className={classes.continueButton}
          onClick={returnConfirmationCodeFromUserToAuth0}
          disabled={phoneFieldInfo.isInvalid}
        >
          Continue
        </GLButton>
      </Grid>
    );

    const renderLowerContent = (
      <>
        <Typography
          className={[classes.title, classes.titleSmall]}
          variant="deprecated_h6"
        >
          <Translate text="What do we need this phone number for?" />
        </Typography>
        <Typography className={classes.subtext}>
          <Translate
            text="We will only use this phone number to authenticate
          your identity when you log in. We will not use this phone number for anything
          except authentication unless you also have the same phone number set as your
          contact phone number under your account settings. Changing that number will not
          change this number."
          />
        </Typography>
      </>
    );

    return (
      <ContentBoxFrame
        upperContent={upperContent}
        lowerContent={renderLowerContent}
      />
    );
  };

  const handleOpenTextMessageForm = () => {
    setTextMessageFormIsOpen(true);
  };

  return (
    <Layout
      headerType="integrated"
      headerText="Multi-Factor Authentication"
      goBackPath={PATHS.ACCOUNT_MANAGEMENT_PRIVACY_AND_SECURITY}
      goBackTitle={"Privacy & Security"}
    >
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        {renderTextMessageForm()}

        <Grid>
          <Grid item xs={12}>
            <SecurityMessage
              title="Your information is safe with us"
              desc="Genomic Life uses SSL encryption to protect your information."
            />
          </Grid>
        </Grid>
      </Grid>
    </Layout>
  );
};
export default AccountManagementMFAPage;
