import { makeStyles } from "@mui/styles";
import {
  COLOR_CONSTANTS,
  FONT_CONSTANTS
} from "../../../../constants/StyleConstants";

const CMSDrivenPageTemplateStyles = makeStyles((theme) => ({
  contentWrapper: {
    [theme.breakpoints.down("md")]: {
      width: "768px"
    },
    [theme.breakpoints.between("sm", 833)]: {
      width: "640px"
    },
    [theme.breakpoints.up(833)]: {
      width: "1280px"
    },
    color: theme.palette.primary.font_blue,
    "& h1": {
      fontFamily: FONT_CONSTANTS.FONT_AVENIRLT_PRO_HEAVY
    },
    "& .content-title": {
      marginBottom: 15,
      fontWeight: 700,
      fontSize: "20px",
      lineHeight: "28px",
      paddingRight: "40px",
      "& a": {
        textDecoration: "none",
        cursor: "pointer",
        color: COLOR_CONSTANTS.GENOMIC_LIFE_SECONDARY
      }
    },
    "& .content-summary": {
      paddingRight: "40px",
      fontSize: "14px",
      fontWeight: 400,
      color: COLOR_CONSTANTS.GENOMIC_LIFE_LIGHT_GRAY,
      marginBottom: "35px"
    },
    "& .content-hr": {
      marginRight: "40px",
      backgroundColor: COLOR_CONSTANTS.COLOR_GRAY_30,
      marginBottom: "35px",
      border: "none",
      height: "1px"
    }
  }
}));

export default CMSDrivenPageTemplateStyles;
