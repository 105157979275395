import {
  selectHasAcceptedCurrentPrivacyPolicy,
  selectHasAcceptedCurrentTermsAndConditions,
  selectUserHasAcceptedAllCurrentAgreements
} from "../../../../../store/reducers/termsOfService/TermsOfServiceSlice";
import { useSelector } from "react-redux";
import { useState } from "react";
import PageTitle from "../../../../../utils/PageTitle";
import StepperComponent from "../OnboardingStepper";
import OnboardingStepperLayout from "../SideMenuLayout";
import TermsSheet from "./TermsSheet";
import { useHistory } from "react-router-dom";
import { PATHS } from "constants/pathConstants";

export const TERMS_DOCUMENTS_NAMES: Record<number, string> = {
  5: "Terms & Conditions",
  6: "Privacy Policy",
  7: "HIPAA Authorization"
};

export const DOCUMENT_KEYS_BY_NAME: Record<string, string> = {
  "Terms & Conditions": "termsAndConditions",
  "Privacy Policy": "privacyPolicy",
  "HIPAA Authorization": "hipaaAuthorization"
};

const TermsPage = () => {
  PageTitle("Terms of Service");
  const history = useHistory();

  const allAgreementsCurrent = useSelector(
    selectUserHasAcceptedAllCurrentAgreements
  );

  if (allAgreementsCurrent) {
    history.push(PATHS.ONBOARDING_PROFILE);
  }

  const termsAndConditionsComplete = useSelector(
    selectHasAcceptedCurrentTermsAndConditions
  );
  const privacyPolicyComplete = useSelector(
    selectHasAcceptedCurrentPrivacyPolicy
  );

  const firstUnagreedDocStepIndex = privacyPolicyComplete
    ? 7
    : termsAndConditionsComplete
    ? 6
    : 5;

  const [currentStepIndex, serCurrentStepIndex] = useState(
    firstUnagreedDocStepIndex
  );

  const handleClickContinue = () => {
    if (currentStepIndex < 7) {
      serCurrentStepIndex(currentStepIndex + 1);
    } else {
      history.push(PATHS.ONBOARDING_PROFILE);
    }
  };

  return (
    <OnboardingStepperLayout
      onboardingStepper={
        <StepperComponent activeStepIndex={currentStepIndex} />
      }
    >
      <TermsSheet
        onClickContinue={handleClickContinue}
        termsDocumentName={TERMS_DOCUMENTS_NAMES[currentStepIndex]}
        termsDocumentKey={
          DOCUMENT_KEYS_BY_NAME[TERMS_DOCUMENTS_NAMES[currentStepIndex]]
        }
      />
    </OnboardingStepperLayout>
  );
};

export default TermsPage;
