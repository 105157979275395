import React from "react";
import { Grid, Typography } from "@mui/material";
import HeroCardGridSytles from "./HeroCardGrid.styles";
import ContentResolver from "../ContentResolver";
import ImageWithFooterCard from "../ImageWithFooterCard";

const CardGrid = (props) => {
  const { material } = props;
  const { cards, href, title } = props.material;

  const classes = HeroCardGridSytles();

  const renderCards = () => {
    return material.cards.map((card, index) => {
      return (
        <Grid item xs={12} sm={6} lg={3} key={index}>
          <ImageWithFooterCard {...card} />
        </Grid>
      );
    });
  };

  const maybeHeader = title ? (
    <Typography variant="deprecated_h5" className={classes.headline}>
      <ContentResolver content={title} />
    </Typography>
  ) : null;

  const maybeHref = href ? (
    <Typography>
      <a href={href.route} className={classes.linkContainer}>
        <ContentResolver content={href.title} />
      </a>
    </Typography>
  ) : null;

  return (
    <Grid container className={classes.container} justifyContent="center">
      <Grid item xs={12}>
        {maybeHeader || maybeHref ? (
          <div className={classes.headerContainer}>
            <div>{maybeHeader}</div>
            <div>{maybeHref}</div>
          </div>
        ) : null}
        <Grid container spacing={2}>
          {renderCards()}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CardGrid;
