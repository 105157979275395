import { Box, useMediaQuery, useTheme } from "@mui/material";
import { COLORS } from "components/v3/Theme/colors";
import PageTitle from "utils/PageTitle";
import StepperComponent from "../OnboardingStepper";
import OnboardingStepperLayout from "../SideMenuLayout";
import HelpfulResourcesSection from "./HelpfulResourcesSection";
import IntroductionSection from "./IntroductionSection";
import NextStepSection from "./NextStepSection";
import OverviewSection from "./OverviewSection";
import VideoSection from "./VideoSection";
import { useState } from "react";
import GhsOnlyModal from "components/v3/sharedComponents/modals/GhsOnlyModal";
import { useSelector } from "react-redux";
import { selectUserPersonalInformation } from "store/reducers/user/UserAccountSlice";

const GettingStartedPage = () => {
  PageTitle("Welcome");

  const theme = useTheme();
  const userInformation = useSelector(selectUserPersonalInformation);
  const screenWidthIsMdOrSmaller = useMediaQuery(theme.breakpoints.down("lg"));
  const [showGHSOnlyModal, setShowGHSOnlyModal] = useState(
    userInformation.glProgramId === "Genetic Screen"
  );

  return (
    <OnboardingStepperLayout
      onboardingStepper={<StepperComponent activeStepIndex={0} />}
    >
      {!screenWidthIsMdOrSmaller && (
        <NextStepSection
          sx={{
            paddingBottom: 8
          }}
        />
      )}
      {screenWidthIsMdOrSmaller && (
        <Box
          sx={{
            boxSizing: "border-box",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "30px",
            paddingTop: "60px"
          }}
        >
          <IntroductionSection />
          <NextStepSection
            buttonSx={{ width: "100%" }}
            sx={{ justifyContent: "center" }}
          />
        </Box>
      )}
      <Box
        sx={{
          boxSizing: "border-box",
          display: "flex",
          flexDirection: "column",
          alignItems: {
            xs: "center",
            md: "flex-start"
          },
          backgroundColor: COLORS.WHITE,
          borderRadius: 8,
          gap: {
            xs: "30px",
            md: 8
          },
          marginTop: {
            xs: "30px",
            md: "0"
          },
          paddingTop: {
            xs: "30px",
            md: "60px"
          }
        }}
      >
        <GhsOnlyModal
          isOpen={showGHSOnlyModal}
          memberName={userInformation.preferredName}
          onClose={() => setShowGHSOnlyModal(false)}
        />
        <VideoSection />
        <OverviewSection />
        <HelpfulResourcesSection />
        <NextStepSection
          buttonSx={{
            justifyContent: "center",
            width: { xs: "100%", md: "fit-content" }
          }}
        />
      </Box>
    </OnboardingStepperLayout>
  );
};

export default GettingStartedPage;
