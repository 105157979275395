import React from "react";
import { Grid, Typography } from "@mui/material";
import NoButtonsInfoDialogStyles from "./NoButtonsInfoDialog.styles";
import { Close } from "@mui/icons-material";
import ModalWrapper from "../ModalWrapper";

const NoButtonsInfoDialog = (props) => {
  const { isOpen, onClose, headerText, children } = props;
  const classes = NoButtonsInfoDialogStyles();

  return (
    <ModalWrapper isOpen={isOpen} onClickOutsideModal={onClose}>
      <>
        <Grid container className={classes.modalTitleContainer}>
          <Grid item xs={10}>
            <Typography variant="deprecated_h4" className={classes.title}>
              {headerText}
            </Typography>
          </Grid>
          <Grid item xs={2} className={classes.iconContainer}>
            <Close onClick={onClose} className={classes.icon} />
          </Grid>
        </Grid>
        <Grid container className={classes.modalBodyContainer}>
          {children}
        </Grid>
      </>
    </ModalWrapper>
  );
};

export default NoButtonsInfoDialog;
