import { makeStyles } from "@mui/styles";

const FormModalWrapperStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  modalContainer: {
    borderRadius: 15,
    padding: "25px 25px 10px 25px",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    position: "relative",
    outline: "none"
  },
  maskOverlay: {
    zIndex: 10000,
    position: "absolute",
    top: "70px",
    transition:
      "height 0.25s, opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important"
  },
  formContainer: {
    ...theme.scrollPrimaryColors,
    minHeight: "120px",
    marginBottom: "20px",
    marginTop: "20px"
  },
  modalTitleContainer: {
    marginBottom: "40px"
  },
  footerContainer: {
    position: "relative"
  },
  footerFader: {
    position: "absolute",
    bottom: "0px"
  },
  title: {
    ...theme.typography.deprecated_subtitleHeavy,
    fontSize: 24,
    paddingLeft: theme.paddings.md,
    color: theme.palette.primary.blue_font
  },
  icon: {
    cursor: `pointer`,
    color: theme.palette.primary.black
  },
  confirmationMessage: {
    marginTop: theme.paddings.xs
  },
  actionErrorMessage: {
    color: theme.palette.error.main
  }
}));

export default FormModalWrapperStyles;
