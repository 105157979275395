import React from "react";
import { Box, Typography } from "@mui/material";
import ClearPageCornerArtLayout from "components/v3/layouts/ClearPageCornerArtLayout";
import LoadingAnimation from "components/v3/sharedComponents/LoadingAnimation";

export interface LoadingPageProps {
  message: string;
}

const LoadingPage: React.FC<LoadingPageProps> = ({ message }) => {
  const maybeMessage = message && (
    <Typography variant="body_strong_xl_black" mt={"10px"}>
      {message}
    </Typography>
  );

  return (
    <ClearPageCornerArtLayout>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          height: "100vh",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column"
        }}
      >
        <LoadingAnimation />
        {maybeMessage}
      </Box>
    </ClearPageCornerArtLayout>
  );
};

export default LoadingPage;
