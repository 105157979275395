import { Grid, Typography } from "@mui/material";
import React from "react";
import Translate from "../../../../translate/Translate";
import CheckboxForm from "../../../sharedComponents/Survey/CheckboxForm";
import ReportSelectionStepStyles from "./ReportSelectionStep.styles";

const ReportSelectionStep = (props) => {
  const { availableReports, reportsHandler } = props;
  const classes = ReportSelectionStepStyles();
  const handleCounter = () => {
    let selectedOptions = 0;
    if (availableReports && availableReports.length > 0) {
      const allSelected = availableReports.find((option) => {
        return option.id === "all";
      });
      if (allSelected && allSelected.isChecked) {
        return availableReports.length - 1;
      }
      availableReports.forEach((option) => {
        if (option.isChecked) {
          selectedOptions++;
        }
      });
    }
    return selectedOptions;
  };
  return (
    <Grid item xs={12}>
      <Grid container direction="column">
        <Grid
          container
          direction="column"
          alignContent="center"
          alignItems="center"
        >
          <Grid item xs={11} sm={8} md={5}>
            <Grid
              container
              alignContent="flex-start"
              alignItems="flex-start"
              className={classes.questionContainer}
            >
              <Typography
                variant="deprecated_h5"
                className={classes.questionText}
              >
                <Translate text="Select the result report(s) you want to share." />
              </Typography>
            </Grid>
            <CheckboxForm
              options={availableReports}
              selectedOptions={availableReports}
              selectOptionHandler={reportsHandler}
              isMutuallyExclusive={false}
              index={0}
              allOption={"all"}
            />
            <Grid container className={classes.counterContainer}>
              <Typography variant="deprecated_body1">
                {handleCounter()}{" "}
                <Translate
                  text={`report${
                    handleCounter() > 1 || handleCounter() === 0 ? "s" : ""
                  } ha${
                    handleCounter() > 1 || handleCounter() === 0 ? "ve" : "s"
                  } been selected`}
                />
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default ReportSelectionStep;
