import React from "react";
import { Grid } from "@mui/material";
import LinkBoxGridStyles from "./LinkChildren.styles";
import ContentResolver from "../ContentResolver";
import GutterCard from "../GutterCard";
import ResourceLink from "../ResourceLink";

const LinkChildren = (props) => {
  const { children } = props.page;
  const { gutters, pages } = props;
  const classes = LinkBoxGridStyles();

  const renderChildren = () => {
    return Object.entries(children || {}).map(([index, child]) => {
      return (
        <Grid
          item
          xs={12}
          md={12}
          key={index}
          className={classes.linkBoxContainer}
        >
          <div className={classes.linkBox}>
            <ResourceLink link={pages[child.ref]} />
            <div>
              <small>
                <ContentResolver content={pages[child.ref].summary} />
              </small>
            </div>
          </div>
        </Grid>
      );
    });
  };

  return (
    <Grid container className={classes.container}>
      <Grid item sm={12} md={8} lg={9}>
        <Grid container spacing={2}>
          {renderChildren(children)}
        </Grid>
      </Grid>
      <Grid item sm={12} md={4} lg={3}>
        <GutterCard material={gutters} />
      </Grid>
    </Grid>
  );
};

export default LinkChildren;
