import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface AgreementState {
  acceptedDate: string | null;
  acceptedVersion: string | null;
  agreementHtml: string | null;
  latestVersion: string | null;
}

interface TermsOfServiceState {
  hipaaAuthorization: AgreementState;
  privacyPolicy: AgreementState;
  termsAndConditions: AgreementState;
}

interface Agreement {
  name: string;
  latestVersion: string | null;
  acceptedVersion: string | null;
  acceptedDate: string | null;
}

interface AgreementsPayload {
  data: {
    agreements: Agreement[];
  };
}

interface HtmlResponsePayload {
  data: {
    value: {
      name: string;
      text: string;
      version: string;
    }[];
  }[];
}

const initialState: TermsOfServiceState = {
  hipaaAuthorization: {
    acceptedDate: null,
    acceptedVersion: null,
    agreementHtml: null,
    latestVersion: null
  },
  privacyPolicy: {
    acceptedDate: null,
    acceptedVersion: null,
    agreementHtml: null,
    latestVersion: null
  },
  termsAndConditions: {
    acceptedDate: null,
    acceptedVersion: null,
    agreementHtml: null,
    latestVersion: null
  }
};

export const TermsOfServiceSlice = createSlice({
  name: "termsOfService",
  initialState,
  reducers: {
    setUserAgreementVersionAcceptanceStates(
      state,
      { payload }: PayloadAction<AgreementsPayload>
    ) {
      if (payload.data.agreements) {
        const privacy = payload.data.agreements.find(
          (agreement) => agreement.name === "privacyPolicy"
        );
        const terms = payload.data.agreements.find(
          (agreement) => agreement.name === "termsConditions"
        );
        const hipaa = payload.data.agreements.find(
          (agreement) => agreement.name === "HIPAA"
        );

        if (privacy) {
          state.privacyPolicy.latestVersion = privacy.latestVersion;
          state.privacyPolicy.acceptedVersion = privacy.acceptedVersion;
          state.privacyPolicy.acceptedDate = privacy.acceptedDate;
        }
        if (terms) {
          state.termsAndConditions.latestVersion = terms.latestVersion;
          state.termsAndConditions.acceptedVersion = terms.acceptedVersion;
          state.termsAndConditions.acceptedDate = terms.acceptedDate;
        }
        if (hipaa) {
          state.hipaaAuthorization.latestVersion = hipaa.latestVersion;
          state.hipaaAuthorization.acceptedVersion = hipaa.acceptedVersion;
          state.hipaaAuthorization.acceptedDate = hipaa.acceptedDate;
        }
      }
    },
    setUserAgreementHtmlFromApiReponse(
      state,
      { payload }: PayloadAction<HtmlResponsePayload>
    ) {
      const htmlByKey = payload.data[0].value.reduce<Record<string, string>>(
        (resultStructure, docHtmlStructure) => {
          resultStructure[docHtmlStructure.name] = docHtmlStructure.text;
          return resultStructure;
        },
        {}
      );
      const versionByKey = payload.data[0].value.reduce<Record<string, string>>(
        (resultStructure, docHtmlStructure) => {
          resultStructure[docHtmlStructure.name] = docHtmlStructure.version;
          return resultStructure;
        },
        {}
      );
      state.privacyPolicy.agreementHtml = htmlByKey.policy;
      state.privacyPolicy.latestVersion = versionByKey.policy;
      state.termsAndConditions.agreementHtml = htmlByKey.terms;
      state.termsAndConditions.latestVersion = versionByKey.terms;
      state.hipaaAuthorization.agreementHtml = htmlByKey.hipaa;
      state.hipaaAuthorization.latestVersion = versionByKey.hipaa;
    },

    reset: () => initialState
  }
});

export const {
  setUserAgreementVersionAcceptanceStates,
  setUserAgreementHtmlFromApiReponse,
  reset
} = TermsOfServiceSlice.actions;

export const selectUserAgreementData = (state: {
  termsOfService: TermsOfServiceState;
}) => state.termsOfService;

export const selectHasAcceptedCurrentPrivacyPolicy = (state: {
  termsOfService: TermsOfServiceState;
}) =>
  state.termsOfService.privacyPolicy.latestVersion &&
  state.termsOfService.privacyPolicy.latestVersion ===
    state.termsOfService.privacyPolicy.acceptedVersion;

export const selectHasAcceptedCurrentTermsAndConditions = (state: {
  termsOfService: TermsOfServiceState;
}) =>
  state.termsOfService.termsAndConditions.latestVersion &&
  state.termsOfService.termsAndConditions.latestVersion ===
    state.termsOfService.termsAndConditions.acceptedVersion;

export const selectHasAcceptedCurrentHipaaAuthorization = (state: {
  termsOfService: TermsOfServiceState;
}) =>
  state.termsOfService.hipaaAuthorization.latestVersion &&
  state.termsOfService.hipaaAuthorization.latestVersion ===
    state.termsOfService.hipaaAuthorization.acceptedVersion;

export const selectUserHasAcceptedAllCurrentAgreements = (state: {
  termsOfService: TermsOfServiceState;
}) =>
  state.termsOfService.hipaaAuthorization.latestVersion &&
  state.termsOfService.hipaaAuthorization.latestVersion ===
    state.termsOfService.hipaaAuthorization.acceptedVersion &&
  state.termsOfService.termsAndConditions.latestVersion &&
  state.termsOfService.termsAndConditions.latestVersion ===
    state.termsOfService.termsAndConditions.acceptedVersion &&
  state.termsOfService.privacyPolicy.latestVersion &&
  state.termsOfService.privacyPolicy.latestVersion ===
    state.termsOfService.privacyPolicy.acceptedVersion;

export const selectUserHasNeverAcceptedSomeAgreements = (state: {
  termsOfService: TermsOfServiceState;
}) => {
  return (
    !state.termsOfService.hipaaAuthorization.acceptedVersion ||
    state.termsOfService.hipaaAuthorization.acceptedVersion === "0.0.0" ||
    !state.termsOfService.termsAndConditions.acceptedVersion ||
    state.termsOfService.termsAndConditions.acceptedVersion === "0.0.0" ||
    !state.termsOfService.privacyPolicy.acceptedVersion ||
    state.termsOfService.privacyPolicy.acceptedVersion === "0.0.0"
  );
};

export default TermsOfServiceSlice.reducer;
