import React, { type PropsWithChildren, useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ArrowForward } from "@mui/icons-material";
import GLButton from "components/v3/sharedComponents/GLButton/index";
import GLLogo from "assets/images/GL-Logo.svg";
import WelcomeDesktopAccentImg from "assets/images/WelcomeDesktopAccent.svg";
import WelcomeMobileAccentImg from "assets/images/WelcomeMobileAccent.svg";
import { useHistory } from "react-router-dom";
import { PATHS } from "constants/pathConstants";
import { motion, AnimatePresence } from "framer-motion";

const TitleText: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <Typography
      sx={{
        background: "linear-gradient(90deg, #5FC2A6 0.21%, #284A95 99.88%)",
        backgroundClip: "text",
        typography: {
          xs: "body_strong_lg1_serif",
          xsm: "body_serif_strong_lg2_serif",
          md: "h2_serif"
        },
        WebkitTextFillColor: "transparent",
        whiteSpace: "nowrap",
        zIndex: 1
      }}
      
    >
      {children}
    </Typography>
  );
};

const TitleAnimate = ({ duration = 5000, titles }: { duration?: number; titles: string[] }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % titles.length);
    }, duration);

    return () => clearInterval(intervalId);
  }, [titles.length, duration]);

  return (
    <Box
      sx={{
        position: "relative",
        width: "100%",
        height: {
          xs: "30px",
          md: "73px"
        },
        zIndex: "1"
      }}
    >
      {/* @ts-ignore */}
      <AnimatePresence>
        {titles.map((title, index) =>
          index === currentIndex ? (
            <motion.div
              key={title}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.8, ease: "easeInOut" }}
              style={{
                position: "absolute",
                width: "100%",
                height: "auto",
              }}
            >
              <TitleText>{title}</TitleText>
            </motion.div>
          ) : null
        )}

      </AnimatePresence>
    </Box>
  );
};


const TitleSection = () => {
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <Box
      component="section"
      sx={{
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        paddingBottom: "75px",
        paddingTop: "100px",
        paddingX: {
          xs: "16px",
          md: 8
        }
      }}
    >
      <Box
        component="img"
        sx={{
          height: 44,
          width: 185
        }}
        alt={t("LOGO_ALT_TEXT")}
        src={GLLogo}
      />
      <Box
        sx={{
          boxSizing: "border-box",
          display: "flex",
          flexDirection: "column",
          gap: "28px",
          paddingTop: {
            xs: "30px",
            md: "70px"
          },
          width: "100%"
        }}
      >
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            position: "relative",
            textAlign: "center"
          }}
        >
          <Typography
            fontWeight="600"
            sx={{
              typography: {
                xs: "body_strong_lg1",
                xsm: "body_strong_lg2",
                md: "h2"
              },
              zIndex: 1
            }}
          >
            A life empowered by
          </Typography>
          <TitleAnimate
            duration={3000}
            titles={[
              "Precision Health Guidance.",
              "Cancer Navigation.",
              "DNA Screenings."
            ]}
          />
          <Box
            sx={{
              display: {
                xs: "none",
                md: "block"
              }
            }}
          >
            <Box
              component="img"
              sx={{
                bottom: -7,
                position: "absolute",
                left: "50%",
                transform: "translateX(-50%)",
                zIndex: 0
              }}
              src={WelcomeDesktopAccentImg}
              alt=""
            />
          </Box>
          <Box
            sx={{
              display: {
                xs: "block",
                md: "none"
              }
            }}
          >
            <Box
              component="img"
              sx={{
                bottom: -5,
                position: "absolute",
                left: "50%",
                transform: "translateX(-50%)",
                zIndex: 0
              }}
              src={WelcomeMobileAccentImg}
              alt=""
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center"
          }}
        >
          <GLButton
            buttonType="primary"
            text="Activate Your Account"
            //@ts-ignore
            onClick={() => history.push(PATHS.ONBOARDING_REGISTRATION_INSTRUCTIONS)}
            rightIcon={(
              <ArrowForward />
            )}
          ></GLButton>
        </Box>
      </Box>
    </Box>
  );
};

export default TitleSection;
