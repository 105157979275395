import React from "react";
import { Typography, Box } from "@mui/material";
import { COLORS } from "components/v3/Theme/colors";

export interface SubStepType {
  label: string;
  stepIndex: number;
  onClick?: () => void;
}

interface SubStepProps {
  subStep: SubStepType;
  activeStepIndex: number;
}

const SubStep: React.FC<SubStepProps> = ({ subStep, activeStepIndex }) => {
  const isActive = subStep.stepIndex === activeStepIndex;
  const isComplete =
    activeStepIndex >= subStep.stepIndex || activeStepIndex === 0;
  const color = isComplete ? COLORS.ACCENT2 : COLORS.DARK_GRAY;

  return (
    <Typography
      variant="body1"
      color={color}
      fontWeight={isActive ? "bold" : "normal"}
      onClick={subStep.onClick}
      sx={{ cursor: subStep.onClick ? "pointer" : "default" }}
    >
      <Box
        sx={{
          width: "25px",
          height: "25px",
          position: "relative",
          left: "-64.5px",
          top: "23px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        <Box
          sx={{
            backgroundColor: isActive ? COLORS.ACCENT2_LIGHTEST : "transparent",
            borderRadius: "50%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            animation: isActive ? "smallPulse 3s infinite ease-in-out" : "none",
            "@keyframes smallPulse": {
              "0%": { width: "25px", height: "25px" },
              "50%": { width: "15px", height: "15px" },
              "100%": { width: "25px", height: "25px" }
            }
          }}
        >
          <Box
            sx={{
              width: "9px",
              height: "9px",
              backgroundColor: color,
              borderRadius: "50%"
            }}
          />
        </Box>
      </Box>
      {subStep.label}
    </Typography>
  );
};

export default SubStep;
