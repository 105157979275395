import { Grid, Typography } from "@mui/material";
import { Person } from "@mui/icons-material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../../../layout/Layout";
import PageTitle from "../../../../utils/PageTitle";
import OptionCard from "../../../sharedComponents/OptionCard";
import ConnectionsPageStyles from "./ConnectionsPage.styles";
import Translate from "../../../../translate/Translate";
import CenteredContent from "../../../sharedComponents/CenteredContent";
import { selectConnections } from "../../../../store/reducers/connection/ConnectionsInformationSlice";
import ConnectionForm from "../../../v2/Forms/ConnectionForm";
import { openGLSnackbar } from "../../../../store/reducers/snackbar/openGLSnackbarSlice";
import InactiveMembership from "../../../LandingPage/InactiveMembership";
import { selectUserPersonalInformation } from "../../../../store/reducers/user/UserAccountSlice";
import useFetchConnectionList from "../../../../hooks/useFetchConnectionList";

const ConnectionsPage = (props) => {
  const { goBackPath, goBackTitle } = props;
  PageTitle("Connections");
  const classes = ConnectionsPageStyles();
  const connections = useSelector(selectConnections);
  const dispatch = useDispatch();
  const isMemberActive = useSelector(selectUserPersonalInformation)
    .isMemberActive;

  useFetchConnectionList();

  const [
    idOfConnectionCurrentlyBeingEdited,
    setIdOfConnectionCurrentlyBeingEdited
  ] = useState(null);
  const [
    connectionIsCurrentlyBeingEdited,
    setConnectionIsCurrentlyBeingEdited
  ] = useState(false);

  const handleSaveOrUpdateConnection = () => {
    idOfConnectionCurrentlyBeingEdited
      ? dispatch(
          openGLSnackbar({
            variant: "success",
            header: false,
            subText: "Changes to this connection have been successfully saved."
          })
        )
      : dispatch(
          openGLSnackbar({
            variant: "success",
            header: false,
            subText: "We’ve added a new connection to your profile!"
          })
        );
    setIdOfConnectionCurrentlyBeingEdited(null);
    setConnectionIsCurrentlyBeingEdited(false);
  };

  const renderConnectionFormIfOpen = () => {
    return connectionIsCurrentlyBeingEdited ? (
      <ConnectionForm
        connectionId={idOfConnectionCurrentlyBeingEdited}
        onSaved={handleSaveOrUpdateConnection}
        onDeleted={() => {
          dispatch(
            openGLSnackbar({
              variant: "info",
              header: false,
              subText: "You’ve removed a connection from your community."
            })
          );
          setIdOfConnectionCurrentlyBeingEdited(null);
          setConnectionIsCurrentlyBeingEdited(false);
        }}
        onCancelled={() => {
          setIdOfConnectionCurrentlyBeingEdited(null);
          setConnectionIsCurrentlyBeingEdited(false);
        }}
      />
    ) : null;
  };

  return (
    <Layout
      headerType="integrated"
      headerText="Connections"
      goBackPath={goBackPath}
      goBackTitle={goBackTitle}
      hasNoFooter
    >
      {/*<ConnectionTypesModal
        open={open}
        connections={connectionTypes}
        handleClose={handleClose}
      />*/}

      {renderConnectionFormIfOpen()}

      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={12} className={classes.boxTitles}>
          <Grid item className={classes.connectionTitle}>
            <Grid container justifyContent="center" alignItems="center">
              <Typography variant="deprecated_h5">
                <Translate text="Build your community of connections" />
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} className={classes.connectionSubtitle}>
            <CenteredContent>
              <Typography variant="deprecated_body1">
                <Translate text="Add, remove, and edit family members and care team member connections." />
              </Typography>
            </CenteredContent>
          </Grid>
        </Grid>
        {isMemberActive === false && <InactiveMembership />}

        <Grid item xs={12}>
          <Grid container justifyContent="center" alignItems="center">
            <Grid item className={classes.addConCard}>
              <OptionCard
                text={"Add a connection"}
                icon={
                  <Person
                    style={{ color: "#E2ECF8" }}
                    className={classes.iconFontSize}
                  />
                }
                redirectTo={() => {
                  setIdOfConnectionCurrentlyBeingEdited(null);
                  setConnectionIsCurrentlyBeingEdited(true);
                }}
                add
                bigCard
              />
            </Grid>
            {connections &&
              connections.length > 0 &&
              connections.map((connection) => (
                <OptionCard
                  text={`${connection.firstName} ${connection.lastName}`}
                  nameInitial={`${connection.firstName.charAt(
                    0
                  )}${connection.lastName.charAt(0)}`}
                  subText={connection.relationship}
                  key={connection.connectionId}
                  redirectTo={() => {
                    setIdOfConnectionCurrentlyBeingEdited(
                      connection.connectionId
                    );
                    setConnectionIsCurrentlyBeingEdited(true);
                  }}
                  connectionId={connection.connectionId}
                  avatar
                  bigCard
                />
              ))}
          </Grid>
        </Grid>
      </Grid>
    </Layout>
  );
};

export default ConnectionsPage;
