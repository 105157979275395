import React from "react";
import GLTextField, { GLTextFieldProps } from "../GLTextField";
import { validateEmailString } from "../../../../../utils/utils";

const GLEmailField: React.FC<GLTextFieldProps> = ({
  label,
  id,
  onChange,
  tooltip,
  value,
  defaultValue,
  required,
  disabled,
  placeholder
}) => {
  const stripSpaces = (
    stringToStripOfSpaces: string | null | undefined
  ): string => {
    return typeof stringToStripOfSpaces === "string" &&
      stringToStripOfSpaces?.length > 0
      ? stringToStripOfSpaces.split(" ").join("")
      : "";
  };

  const emailValidator = (newValue: string): string | undefined => {
    const valueToValidate = stripSpaces(newValue);

    if (valueToValidate?.length > 0 && !validateEmailString(valueToValidate)) {
      return "Please enter a valid email address";
    }

    return null;
  };

  return (
    <GLTextField
      label={label}
      id={id}
      tooltip={tooltip}
      value={value}
      defaultValue={defaultValue}
      placeholder={placeholder}
      required={required}
      disabled={disabled}
      onChange={onChange}
      additionalValidation={emailValidator}
    />
  );
};

export default GLEmailField;
