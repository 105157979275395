import {
  Accordion as MuiAccordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography
} from "@mui/material";
import { withStyles } from "@mui/styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { COLORS } from "components/v3/Theme/colors";
import { useState } from "react";
import HelpModal from "../RegistrationInstructions/HelpModal";
import { Link } from "react-router-dom";
const Accordion = withStyles({
  root: {
    background: "none",
    boxShadow: "none",
    "&.Mui-expanded": {
      "&::before": {
        opacity: 1
      }
    }
  },
  gutters: {
    "&::before": {
      backgroundColor: COLORS.GREEN_2
    }
  }
})(MuiAccordion);

const AccordionSection = () => {
  const [contactModalOpen, setContactModalOpen] = useState<boolean>(false);

  return (
    <Box
      component="section"
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "30px",
        paddingTop: {
          xs: "30px",
          md: 0
        },
        paddingX: {
          xs: "20px",
          md: 8
        }
      }}
    >
      <HelpModal
        isOpen={contactModalOpen}
        onClose={() => setContactModalOpen(false)}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "10px"
        }}
      >
        <Typography sx={{ typography: { xs: "h4_black", md: "h5_black" } }}>
          Answers to common questions
        </Typography>
        <Typography sx={{ typography: { xs: "body_sm", md: "body" } }}>
          Explore answers to common questions about getting started with Genomic
          Life's benefit.
        </Typography>
      </Box>
      <Box>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{ paddingX: 0, paddingY: 1 }}
          >
            <Typography variant="body_strong_black">
              What do I need to activate my account?
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              paddingBottom: 1,
              paddingTop: 0,
              paddingX: 0
            }}
          >
            <Typography sx={{ typography: { xs: "body_small", md: "body" } }}>
              To activate your account, you'll need the personal access code
              that was emailed to the email address your employer provided to
              Genomic Life. Not sure what your access code is or can't find that
              email? No worries,{" "}
              <Box
                sx={{
                  textDecoration: "underline",
                  cursor: "pointer",
                  display: "inline"
                }}
                onClick={() => setContactModalOpen(true)}
              >
                click here
              </Box>{" "}
              to learn how to contact our navigation team for assistance.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{ paddingX: 0, paddingY: 1 }}
          >
            <Typography variant="body_strong_black">
              What does Genomic Life do?
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
              paddingBottom: 1,
              paddingTop: 0,
              paddingX: 0
            }}
          >
            <Typography sx={{ typography: { xs: "body_sm", md: "body" } }}>
              Genomic Life is a genomics-based healthcare navigation company. We
              offer precision health guidance, DNA screenings, and cancer
              navigation to help protect your health and help catch disease
              early, inform choices, and provide access to tailored guidance
              that works best for you.
            </Typography>
            <Typography sx={{ typography: { xs: "body_sm", md: "body" } }}>
              We are not a medical company or a health insurance company. We
              partner with the best resources in genomic medicine including a
              variety of genetic testing laboratory partners, precision medicine
              partners, and genetics experts as well as your existing healthcare
              team to help you navigate the healthcare system.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{ paddingX: 0, paddingY: 1 }}
          >
            <Typography variant="body_strong_black">
              How is my data and privacy secured?
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
              paddingBottom: 1,
              paddingTop: 0,
              paddingX: 0
            }}
          >
            <Typography
              sx={{
                typography: { xs: "body_small_italic", md: "body_italic" }
              }}
            >
              Your Security & Privacy, Our Commitment
            </Typography>
            <Typography sx={{ typography: { xs: "body_sm", md: "body" } }}>
              Genomic Life does not sell, share, rent, or trade information
              other than as disclosed within our policy. Except as described in
              the privacy policy, Genomic Life will, consistent with its legal
              obligations, not use or disclose information that identifies
              members without your written authorization.
            </Typography>
            <Typography sx={{ typography: { xs: "body_sm", md: "body" } }}>
              At Genomic Life, we prioritize data and privacy, and our program
              fully complies with all applicable state and federal laws
              regarding security, privacy, and data protections. If you would
              like to learn more, you can{" "}
              <a
                target="_blank"
                rel="noreferrer"
                href="https://genomiclife.com/about/policies/"
              >
                read our policies.
              </a>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{ paddingX: 0, paddingY: 1 }}
          >
            <Typography variant="body_strong_black">
              How do I get the most out of my benefit?
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              paddingBottom: 1,
              paddingTop: 0,
              paddingX: 0
            }}
          >
            <Typography sx={{ typography: { xs: "body_sm", md: "body" } }}>
              Everyone's health journey is unique. To connect you to the right
              services and resources, we will ask you a few questions to help us
              understand your wellness goals, personal health, and family
              history. Activating your account should only take a few minutes.
              Once your account is activated, we will help guide you to get the
              most out of your Genomic Life benefit by providing you with a
              series of simple-to-follow health actions to help you optimize
              your health, request DNA screenings, connect with a member of our
              cancer navigation team, and much more. You set the pace, and we
              are here to support and guide you.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{ paddingX: 0, paddingY: 1 }}
          >
            <Typography variant="body_strong_black">
              What if I have other questions?
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              paddingBottom: 1,
              paddingTop: 0,
              paddingX: 0
            }}
          >
            <Typography sx={{ typography: { xs: "body_sm", md: "body" } }}>
              Click here to access a more comprehensive FAQ or connect with a
              member of our navigation team.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Box>
    </Box>
  );
};

export default AccordionSection;
