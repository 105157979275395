import React from "react";
import { Chip, Grid, Typography, Checkbox } from "@mui/material";
import Translate from "../../../translate/Translate";
import BriefInformationCardStyles from "./BriefInformationCard.styles";

const BriefInformationCard = (props) => {
  const {
    cardContainerStyles,
    title,
    titleStyles,
    text,
    textStyle,
    hasCheckBox,
    checkBoxValue,
    checkBoxAction,
    chipType,
    chipText,
    chipSize,
    chipBold,
    chipStyles,
    titleType,
    hasIcon,
    icon
  } = props;
  const classes = BriefInformationCardStyles(props);

  const SIZE_VARIANTS = {
    extraSmall: classes.extraSmall,
    small: classes.chip,
    medium: classes.chip
  };

  const titleStylesHandler = () => {
    const titleStyle = titleStyles ? titleStyles : classes.cardTitle;
    switch (titleType) {
      case "success":
        return `${classes.cardTitleSuccess} ${titleStyle}`;
      case "info":
        return `${classes.cardTitleInfo} ${titleStyle}`;
      case "error":
        return `${classes.cardTitleError} ${titleStyle}`;
      case "warning":
        return `${classes.cardTitleWarning} ${titleStyle}`;
      default:
        return titleStyle;
    }
  };

  const chipColorHandler = () => {
    switch (chipType) {
      case "info":
        return [
          chipSize ? SIZE_VARIANTS[chipSize] : classes.chip,
          classes.chipInfo
        ];
      case "success":
        return [
          chipSize ? SIZE_VARIANTS[chipSize] : classes.chip,
          classes.chipSuccess
        ];
      case "error":
        return [
          chipSize ? SIZE_VARIANTS[chipSize] : classes.chip,
          classes.chipError
        ];
      case "systemError":
        return [
          chipSize ? SIZE_VARIANTS[chipSize] : classes.chip,
          classes.chipErrorDarker
        ];
      case "warning":
        return [
          chipSize ? SIZE_VARIANTS[chipSize] : classes.chip,
          classes.chipWarning
        ];
      case "primary":
      default:
        return classes.chip;
    }
  };
  return (
    <Grid
      container
      xs={12}
      className={
        cardContainerStyles
          ? [classes.cardContainer, cardContainerStyles]
          : classes.cardContainer
      }
    >
      <Grid container item xs={hasIcon && icon ? 11 : 12}>
        <Grid
          item
          container
          alignItems="flex-start"
          justifyContent="center"
          xs={12}
          sm={text || chipText ? 4 : 12}
        >
          <Grid
            container
            item
            className={classes.titleContainer}
            alignItems="center"
            xs={12}
          >
            {hasCheckBox ? (
              <Checkbox
                size="small"
                color="secondary"
                name={"briefInfo-checkbox"}
                id={"briefInfo-checkbox"}
                checked={checkBoxValue}
                onChange={checkBoxAction}
              />
            ) : null}
            <div className={hasCheckBox ? classes.checkboxText : null}>
              <Typography
                variant={"deprecated_h2"}
                className={titleStylesHandler()}
              >
                <Translate text={title} />
              </Typography>
            </div>
          </Grid>
        </Grid>
        {text || chipText ? (
          <Grid
            item
            container
            className={classes.descriptionContainer}
            xs={12}
            sm={7}
          >
            {chipText ? (
              <Grid item xs={12} className={classes.chipContainer}>
                <Chip
                  label={
                    <Typography
                      variant="deprecated_subtitle2"
                      className={`${chipStyles} ${chipBold && classes.bold}`}
                    >
                      <Translate text={chipText} />
                    </Typography>
                  }
                  className={chipColorHandler()}
                  size={chipSize}
                />
              </Grid>
            ) : null}
            <Grid item xs={12} className={classes.descriptionContainer}>
              {typeof text === "string" ? (
                <Typography
                  variant="deprecated_subtitleRegular"
                  className={textStyle ? textStyle : classes.cardDescription}
                >
                  <Translate text={text} />
                </Typography>
              ) : (
                text
              )}
            </Grid>
          </Grid>
        ) : null}
      </Grid>
      {hasIcon && icon ? (
        <Grid
          item
          container
          alignItems="flex-start"
          justifyContent="center"
          xs={1}
        >
          <div className={classes.iconContainer}>{icon}</div>
        </Grid>
      ) : null}
    </Grid>
  );
};

export default BriefInformationCard;
