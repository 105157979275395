import PageTitle from "../../../../../utils/PageTitle";
import StepperComponent from "../OnboardingStepper";
import OnboardingStepperLayout from "../SideMenuLayout";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { PATHS } from "constants/pathConstants";
import ProfileForm, {
  ProfileFormFields
} from "components/v3/forms/ProfileForm";

const ProfilePage = () => {
  PageTitle("Your Profile");
  const history = useHistory();
  const [formFieldValues, setFormFieldValues] = useState<ProfileFormFields>(
    {} as ProfileFormFields
  );

  const handleFormFieldsUpdated = (formFields: ProfileFormFields) => {
    setFormFieldValues(formFields);
  };

  const handleSubmitted = () => {
    history.push(PATHS.LANDING_PAGE);
  };

  return (
    <OnboardingStepperLayout
      onboardingStepper={
        <StepperComponent
          activeStepIndex={8}
          newPreferredName={formFieldValues.preferredName}
        />
      }
    >
      <ProfileForm
        onFormFieldsUpdated={handleFormFieldsUpdated}
        onSubmitted={handleSubmitted}
      />
    </OnboardingStepperLayout>
  );
};

export default ProfilePage;
