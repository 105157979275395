import { Avatar, Box, Grid, Typography } from "@mui/material";
import React from "react";
import RequestPasswordResetEmailSuccessStyles from "./RequestPasswordResetEmailSuccess.styles";
import DoneIcon from "@mui/icons-material/Done";
import { Link } from "react-router-dom";
import logo from "../../../assets/images/GL-Logo.svg";
import Translate from "../../../translate/Translate";
import { PATHS } from "../../../constants/pathConstants";
import PageTitle from "../../../utils/PageTitle";

const RequestPasswordResetEmailSuccessPage = () => {
  const classes = RequestPasswordResetEmailSuccessStyles();

  PageTitle("Forgot Password Success");

  return (
    <Grid container justifyContent="center" alignItems="center">
      <Grid item xs={12}>
        <Box display="flex" justifyContent="center" alignItems="center">
          <img src={logo} alt="Genomic Life" className={classes.img} />
        </Box>
      </Grid>
      <Grid item xs={12} justifyContent="center">
        <Box display="flex" justifyContent="center" alignItems="center">
          <Avatar className={classes.avatar}>
            <DoneIcon className={classes.icon} />
          </Avatar>
        </Box>
      </Grid>
      <Grid item xs={9} sm={6} lg={4}>
        <Typography
          variant="deprecated_h3"
          component="h3"
          name="success-message-text"
          className={classes.successMessage}
        >
          <Translate text="Check your inbox for a temporary password reset email." />
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Box display="flex" justifyContent="center">
          <Link to={PATHS.LOGIN} className={classes.BackToLogin}>
            <Translate text="BACK TO LOGIN" />
          </Link>
        </Box>
      </Grid>
    </Grid>
  );
};

export default RequestPasswordResetEmailSuccessPage;
