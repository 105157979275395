import React from "react";
import { Box, Typography } from "@mui/material";
import { COLORS } from "components/v3/Theme/colors";
import HeaderLogo from "../../../../../assets/images/GL-Logo.svg";
import { useSelector } from "react-redux";
import { selectUserPersonalInformation } from "store/reducers/user/UserAccountSlice";
import Stepper from "components/v3/sharedComponents/Stepper";
import FadingTypography from "components/v3/sharedComponents/FadingTypography";

export interface OnboardingStepperProps {
  activeStepIndex: number;
  newPreferredName?: string;
}

const OnboardingStepper: React.FC<OnboardingStepperProps> = ({
  activeStepIndex,
  newPreferredName
}) => {
  const memberInformation = useSelector(selectUserPersonalInformation);
  const nameToDisplay = newPreferredName
    ? newPreferredName
    : memberInformation.preferredName
    ? memberInformation.preferredName
    : memberInformation.firstName;

  const steps = [
    {
      stepIndex: 0,
      longName: "Welcome",
      shortName: "Welcome",
      subSteps: [
        {
          label: "Being proactive",
          stepIndex: 1,
          onClick: () => {
            document
              .getElementById("being_proactive")
              ?.scrollIntoView({ behavior: "smooth" });
          }
        },
        {
          label: "How to get started",
          stepIndex: 2,
          onClick: () => {
            document
              .getElementById("getting_started")
              ?.scrollIntoView({ behavior: "smooth" });
          }
        },
        {
          label: "Want to learn more?",
          stepIndex: 3,
          onClick: () => {
            document
              .getElementById("learn_more")
              ?.scrollIntoView({ behavior: "smooth" });
          }
        }
      ]
    },
    {
      stepIndex: 4,
      longName: "Terms of Use",
      shortName: "Legal",
      subSteps: [
        { label: "Terms & Conditions", stepIndex: 5 },
        { label: "Privacy Policy", stepIndex: 6 },
        { label: "HIPAA Authorization", stepIndex: 7 }
      ]
    },
    {
      stepIndex: 8,
      longName: "Your Profile",
      shortName: "Profile",
      subSteps: []
    }
  ];

  const renderedName = nameToDisplay ? (
    <Box sx={{ display: "flex", gap: "5px" }}>
      <Typography variant="h4_black">Hi</Typography>
      <FadingTypography variant="h4_black">{nameToDisplay},</FadingTypography>
    </Box>
  ) : (
    <Typography variant="h4_black">Hi.</Typography>
  );

  const renderGreeting = () => {
    return (
      <>
        <Box sx={{ width: "175px", marginTop: "35px" }}>
          <img
            src={HeaderLogo}
            alt={`Genomic life logo`}
            style={{
              maxWidth: "100%",
              height: "auto",
              paddingLeft: "50px"
            }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            width: "100%"
          }}
        >
          {renderedName}
          <Typography
            variant="h5_serif"
            sx={{
              background: `linear-gradient(to right, ${COLORS.ACCENT3}, ${COLORS.PRIMARY})`,
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent"
            }}
          >
            We're so glad you're here!
          </Typography>
        </Box>
      </>
    );
  };

  return (
    <Stepper
      activeStepIndex={activeStepIndex}
      greeting={renderGreeting()}
      steps={steps}
    />
  );
};

export default OnboardingStepper;
