export interface Option {
  value: string;
  label: string;
  id?: string;
}

export const US_STATES: Option[] = [
  { label: "Alabama", id: "AL", value: "AL" },
  { label: "Alaska", id: "AK", value: "AK" },
  { label: "Arizona", id: "AZ", value: "AZ" },
  { label: "Arkansas", id: "AR", value: "AR" },
  { label: "California", id: "CA", value: "CA" },
  { label: "Colorado", id: "CO", value: "CO" },
  { label: "Connecticut", id: "CT", value: "CT" },
  { label: "Delaware", id: "DE", value: "DE" },
  { label: "District of Columbia", id: "DC", value: "DC" },
  { label: "Florida", id: "FL", value: "FL" },
  { label: "Georgia", id: "GA", value: "GA" },
  { label: "Hawaii", id: "HI", value: "HI" },
  { label: "Idaho", id: "ID", value: "ID" },
  { label: "Illinois", id: "IL", value: "IL" },
  { label: "Indiana", id: "IN", value: "IN" },
  { label: "Iowa", id: "IA", value: "IA" },
  { label: "Kansas", id: "KS", value: "KS" },
  { label: "Kentucky", id: "KY", value: "KY" },
  { label: "Louisiana", id: "LA", value: "LA" },
  { label: "Maine", id: "ME", value: "ME" },
  { label: "Maryland", id: "MD", value: "MD" },
  { label: "Massachusetts", id: "MA", value: "MA" },
  { label: "Michigan", id: "MI", value: "MI" },
  { label: "Minnesota", id: "MN", value: "MN" },
  { label: "Mississippi", id: "MS", value: "MS" },
  { label: "Missouri", id: "MO", value: "MO" },
  { label: "Montana", id: "MT", value: "MT" },
  { label: "Nebraska", id: "NE", value: "NE" },
  { label: "Nevada", id: "NV", value: "NV" },
  { label: "New Hampshire", id: "NH", value: "NH" },
  { label: "New Jersey", id: "NJ", value: "NJ" },
  { label: "New Mexico", id: "NM", value: "NM" },
  { label: "New York", id: "NY", value: "NY" },
  { label: "North Carolina", id: "NC", value: "NC" },
  { label: "North Dakota", id: "ND", value: "ND" },
  { label: "Ohio", id: "OH", value: "OH" },
  { label: "Oklahoma", id: "OK", value: "OK" },
  { label: "Oregon", id: "OR", value: "OR" },
  { label: "Pennsylvania", id: "PA", value: "PA" },
  { label: "Rhode Island", id: "RI", value: "RI" },
  { label: "South Carolina", id: "SC", value: "SC" },
  { label: "South Dakota", id: "SD", value: "SD" },
  { label: "Tennessee", id: "TN", value: "TN" },
  { label: "Texas", id: "TX", value: "TX" },
  { label: "Utah", id: "UT", value: "UT" },
  { label: "Vermont", id: "VT", value: "VT" },
  { label: "Virginia", id: "VA", value: "VA" },
  { label: "Washington", id: "WA", value: "WA" },
  { label: "West Virginia", id: "WV", value: "WV" },
  { label: "Wisconsin", id: "WI", value: "WI" },
  { label: "Wyoming", id: "WY", value: "WY" }
];

export const PRONOUNS: Option[] = [
  { value: "", label: "I prefer not to say" },
  { value: "he/him/his", label: "He/Him/His" },
  { value: "she/her/hers", label: "She/Her/Hers" },
  { value: "they/them/theirs", label: "They/Them/Theirs" }
];
export const SEXES: Option[] = [
  { value: "M", label: "Male" },
  { value: "F", label: "Female" }
];

export const GENDERS: Option[] = [
  { value: "", label: "I prefer not to say" },
  { value: "male", label: "Male" },
  { value: "female", label: "Female" },
  {
    value: "transgender - male to female",
    label: "Transgender - male to female"
  },
  {
    value: "transgender - female to male",
    label: "Transgender - female to male"
  },
  { value: "agender", label: "Agender" },
  {
    value: "genderqueer/gender-fluid/gender nonconforming",
    label: "Genderqueer/gender-fluid/gender nonconforming"
  },
  { value: "other", label: "Other" }
];

export const PREFERRED_CONTACT_METHODS: Option[] = [
  { value: "phone", label: "Phone" },
  { value: "email", label: "Email" },
  { value: "either", label: "It doesn't matter" }
];

export const PREFERRED_CONTACT_TIMES: Option[] = [
  { value: "morning", label: "Morning" },
  { value: "afternoon", label: "Afternoon" },
  { value: "either", label: "It doesn't matter" }
];

export const ACTIVE_STATUSES = [
  "ACTIVECURRENT",
  "ACTIVELOA",
  "PENDINGTERMINATION",
  "PENDINGCANCELLATION",
  "PENDINGLOA"
];
