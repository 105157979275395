export const PATHS = {
  //Dashboard
  LANDING_PAGE: "/",

  // Authentication
  API_SCRAPE: "/apiLoad",
  LOGIN: "/login",
  LOGIN_CALLBACK: "/loginCallback",
  LOGGED_OUT: "/loggedOut",
  LOG_OUT: "/logout",
  REQUEST_PASSWORD_EMAIL: "/platform",
  REQUEST_PASSWORD_EMAIL_FAILURE: "/forgot-password/failure",
  REQUEST_PASSWORD_EMAIL_SUCCESS: "/forgot-password/success",

  // Onboarding
  ONBOARDING_WELCOME: "/welcome",
  ONBOARDING_REGISTRATION_INSTRUCTIONS: "/onboarding/registrationInstructions",
  ONBOARDING_GETTING_STARTED: "/onboarding/gettingStarted",
  ONBOARDING_TERMS: "/onboarding/terms",
  ONBOARDING_PROFILE: "/onboarding/profile",

  // Account Management
  ACCOUNT: "/account",
  ACCOUNT_MANAGEMENT: "/account/management",
  ACCOUNT_MANAGEMENT_CONNECTIONS: "/account/management/connections",
  ACCOUNT_MANAGEMENT_CONNECTIONS_CONNECTION_DETAILS:
    "/account/management/connections/connectionDetails",
  ACCOUNT_MANAGEMENT_MEMBER_INFORMATION:
    "/account/management/memberInformation",
  ACCOUNT_MANAGEMENT_MFA: "/account/management/mfa",
  ACCOUNT_MANAGEMENT_NOTIFICATIONS_SETTINGS:
    "/account/management/notificationSettings",
  ACCOUNT_MANAGEMENT_PAYMENT_INFORMATION:
    "/account/management/paymentInformation",
  ACCOUNT_MANAGEMENT_PRIVACY_AND_SECURITY:
    "/account/management/privacyAndSecurity",
  ACCOUNT_MANAGEMENT_UPDATE_AGREEMENTS: "/account/management/updateTerms",
  ACCOUNT_MANAGEMENT_PRIVACY_POLICY: "/account/management/privacyPolicy",
  ACCOUNT_MANAGEMENT_TERMS_AND_CONDITIONS:
    "/account/management/termsAndConditions",
  ACCOUNT_MANAGEMENT_AGREEMENTS: "/account/management/termsOfService",
  ACCOUNT_MANAGEMENT_HIPAA: "/account/management/hipaaAuthorization",
  ACCOUNT_MANAGEMENT_RESET_PASSWORD: "/account/management/resetPassword",

  // Survey Pages
  CANCER_SURVEY: "/survey/cancerDiagnosisSurvey",
  GETTING_STARTED_SURVEY: "/survey/gettingStarted",
  ORDER_KIT_INFORMED_CONSENT_SURVEY: "/survey/orderKitInformedConsent",

  // Kit Ordering
  ORDER_KIT: "/orderKit",
  ORDER_KIT_CONFIRMATION: "/orderKit/confirmation",
  ORDER_KIT_SELECTION: "/orderKit/info",

  // Report and Results
  REPORT_SHARING_CONCLUSION: "/reports/transmitionSuccessful",
  REPORT_SHARING_SURVEY: "/reports/survey",
  TEST_RESULTS_PAGE: "/testResults",
  VIEW_TEST_PAGE: "/testResults/test",

  // CMS Controlled Pages
  HELP_CENTER: "/content/help-center*",

  // Redirects to CMS to support legacy links in the wild
  CANCER_AND_GENOMICS: "/cancerAndGenomics",
  CANCER_GUARDIAN_RESOURCE: "/cancerGuardianResource",
  PROGRAM_INFORMATION: "/programInformation",
  RESOURCE_CENTER: "/resource-center",
  WHY_GENOMICS: "/whyGenomics",
  CANCER_RESOURCE_LIBRARY: "/content/cancer-resource-library*",

  // Utility pages
  MAINTENANCE: "/maintenance"
};
