import { makeStyles } from "@mui/styles";
import { COLOR_CONSTANTS } from "../../../../../constants/StyleConstants";

const GutterCardStyles = makeStyles((theme) => ({
  container: {
    border: "1px solid #C4C4C4",
    borderRadius: 10,
    padding: "10px 20px 10px",
    fontSize: "1rem",
    color: COLOR_CONSTANTS.GENOMIC_LIFE_SECONDARY,
    "& h2": {
      ...theme.typography.deprecated_h6HeadlineBlack
    },
    marginBottom: "5rem"
  },
  childrenList: {
    lineHeight: "1rem",
    fontWeight: "normal",
    paddingLeft: "1rem",
    fontSize: "1rem",
    marginTop: ".15rem",
    "& li": {
      marginTop: ".3rem"
    }
  },
  link: {
    color: COLOR_CONSTANTS.COLOR_BLACK_75,
    fontWeight: "normal",
    fontSize: ".9rem",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline"
    }
  },
  parentLink: {
    fontWeight: 700,
    fontSize: "1rem",
    textDecoration: "none"
  }
}));

export default GutterCardStyles;
