import React from "react";
import Modal from "@mui/material/Modal";
import ModalComponentStyles from "./ModalComponent.styles";

const ModalComponent = (props) => {
  const { handleClose, open, body } = props;
  const classes = ModalComponentStyles();

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="title-modal"
        aria-describedby="simple-modal-description"
        className={classes.modal}
        closeAfterTransition
      >
        <>{body}</>
      </Modal>
    </div>
  );
};

export default ModalComponent;
