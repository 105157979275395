import React from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectConnections } from "../../../../../store/reducers/connection/ConnectionsInformationSlice";
import { Grid, Typography, Button, Chip } from "@mui/material";
import TransmissionConclusionPageStyles from "./TransmissionConclusionPage.styles";
import WorkflowConclusionTemplate from "../../../../v2/sharedComponents/WorkflowConclusionTemplate";
import transmitionSuccessImage from "../../../../../assets/images/transmitionSuccess.svg";
import { useHistory } from "react-router-dom";
import Translate from "../../../../../translate/Translate";
import { PATHS } from "../../../../../constants/pathConstants";
import Layout from "../../../../layout/Layout";
import ChipRowInfoCard from "../../../../v2/sharedComponents/ChipRowInfoCard";
import { convertToDateFormat, scrollToTop } from "../../../../../utils/utils";
import PrintIcon from "@mui/icons-material/Print";
import PillImageButton from "../../../../sharedComponents/PillImageButton";

const TransmissionConclusionPage = (props) => {
  const classes = TransmissionConclusionPageStyles();
  const history = useHistory();
  const { state } = useLocation();
  const availableConnections = useSelector(selectConnections);

  scrollToTop();

  const renderDateForCard = () => {
    const timeOfRender = convertToDateFormat(null, "MM/DD/yyyy hh:mm A");
    return (
      <Grid
        container
        justifyContent="center"
        alignContent="center"
        className={classes.datePosition}
      >
        <Typography
          variant="deprecated_body1"
          className={`${classes.textDescription} ${classes.dateText}`}
        >
          {timeOfRender}
        </Typography>
      </Grid>
    );
  };

  const queuedChipRender = () => {
    return (
      <Grid item xs={12}>
        <Grid container justifyContent="flex-start" alignContent="center">
          <Chip
            label={
              <Typography
                variant="deprecated_subtitle2"
                className={classes.boldText}
              >
                <Translate text={"Report Queued"} />
              </Typography>
            }
            className={classes.chipQueuedColor}
          />
          <div className={classes.pillContainer}>
            <PillImageButton
              iconOverrideStyle={classes.printIcon}
              icon={PrintIcon}
            />
          </div>
        </Grid>
      </Grid>
    );
  };

  const renderDescriptionSection = (connectionInformation) => {
    const { firstName, lastName, faxNumber } = connectionInformation;
    return (
      <Grid item xs={11} className={classes.container}>
        <Typography
          variant="deprecated_body1"
          className={classes.textDescription}
        >
          <Translate text="Your Genetic Health Screen report is queued to be sent to" />
          <span className={classes.boldText}>
            {" "}
            {firstName} {lastName}
          </span>
          <Translate text=", Fax" />{" "}
          <span className={classes.boldText}>{faxNumber}</span>.
        </Typography>
      </Grid>
    );
  };

  const transmitionCardsRenderer = (recipients) => {
    return recipients.map((recipientInfo) => {
      return (
        <ChipRowInfoCard
          key={recipientInfo.connectionId}
          id={recipientInfo.connectionId}
          leftThirdContent={renderDateForCard()}
          chipRowElements={queuedChipRender()}
          chipRowDescription={renderDescriptionSection(recipientInfo)}
        />
      );
    });
  };
  if (!state) {
    history.goBack();
  }

  if (state) {
    const { recipientIds } = state;
    const recipients = availableConnections.filter((connection) => {
      return recipientIds.includes(connection.connectionId);
    });
    return (
      <Layout headerType="primary">
        <WorkflowConclusionTemplate
          headerImage={transmitionSuccessImage}
          mainHeaderText="Your report will be sent within the next 24 hours!"
          headerImageAlt="Transmition success image"
        >
          <Grid item xs={11}>
            <Grid container justifyContent="center" alignContent="center">
              <Grid item xs={12}>
                <Grid container className={classes.container}>
                  <Typography
                    variant="deprecated_h6"
                    className={`${classes.subheader} ${classes.subheaderHeavy}`}
                  >
                    <Translate
                      id="headerMessage"
                      text="Your care team connections will receive a secure fax from us shortly."
                    />
                  </Typography>
                  <Typography
                    variant="deprecated_h6"
                    className={classes.subheader}
                  >
                    <Translate
                      id="subheaderMessage"
                      text="We will send you a follow up email and notify you when the transmission process is complete."
                    />
                  </Typography>
                </Grid>
                <Grid
                  className={classes.container}
                  container
                  justifyContent="center"
                  alignContent="center"
                >
                  <Grid className={classes.container} item xs={11} md={8}>
                    {transmitionCardsRenderer(recipients)}
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="center"
                  alignContent="center"
                  className={classes.buttonContainer}
                >
                  <Typography
                    variant="deprecated_h6"
                    className={classes.subheader}
                  >
                    <Button
                      onClick={() => history.push(PATHS.LANDING_PAGE)}
                      color="primary"
                      variant="text"
                    >
                      <Translate text="Back to my dashboard" />
                    </Button>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </WorkflowConclusionTemplate>
      </Layout>
    );
  }
  return null;
};
export default TransmissionConclusionPage;
